import { ReactElement } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { asinRecommendationSelectors } from 'modules/asin-recommendation/store/selectors';
import { Styles } from 'common/types';
import { Product } from 'common/ui/product';

const styles: Styles = {
  container: {
    width: '100%',
    borderBottom: '1px solid #E1EFF5',
    padding: '15px 0',
    boxSizing: 'border-box',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    padding: 1,
  },
  title: { display: 'flex', justifyContent: 'space-between', width: '95%', mb: 1 },
  fieldTitle: {
    fontSize: '14px',
    color: '#58717F',
    lineHeight: '15px',
    letterSpacing: '1px',
  },
  url: { ':hover': { textDecoration: 'underline', cursor: 'pointer' } },
  variationsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 2,
    maxHeight: 300,
    overflowY: 'scroll',
    p: 1,
  },
};

export function VariationsList(): ReactElement {
  const variations = useSelector(asinRecommendationSelectors.selectVariations);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.title}>
        <Typography sx={styles.fieldTitle}>{variations?.data?.asins_variations?.length} VARIATIONS</Typography>
      </Box>
      {variations?.loading ? (
        <Box sx={styles.loadingContainer}>
          <CircularProgress size={14} />
          Retrieving variations...
        </Box>
      ) : (
        <Box sx={styles.variationsContainer}>
          {variations?.data?.asins_variations?.map(asin => (
            <Product product={asin?.extended_product} />
          ))}
        </Box>
      )}
    </Box>
  );
}
