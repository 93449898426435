/* eslint-disable import/extensions */
import { Box, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Styles } from 'common/types';
import { StatusLabel, StatsFilter, StatsTableContainer } from './components';
import { data, scheme, StatsDataRow, StatsRow, STATUS } from './mock';

const styles: Styles = {
  boxHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  typoDashboard: { color: scheme.baseDark, fontSize: 32 },
  typoVisited: { margin: '5px 0 5px 0', color: scheme.baseDarkGrey, fontSize: 14 },
};

const generateStatusLabel = (enabled: string) => {
  switch (enabled) {
    case STATUS.ENABLED:
      return <StatusLabel enabled />;
    case STATUS.DISABLED:
      return <StatusLabel enabled={false} />;
    default:
      return <StatusLabel />;
  }
};

const createRow = (data: StatsDataRow): StatsRow => {
  return {
    keyword: data.keyword,
    status: generateStatusLabel(data.status),
    impressions: data.impressions,
    clicks: data.clicks,
    cost: data.cost,
    averageCpc: data.averageCpc,
    addToCards: data.addToCards,
    conversion: data.conversion,
    revenue: data.revenue,
    roas: data.roas,
    acos: data.acos,
  };
};

const createRows = (data: StatsDataRow[]): StatsRow[] => {
  const rows: StatsRow[] = [];

  data.forEach(d => {
    rows.push(createRow(d));
  });

  return rows;
};

export function Stats(): ReactElement {
  return (
    <>
      <Box>
        <Box sx={styles.boxHeader}>
          <Box>
            <Typography variant="h4" sx={styles.typoDashboard}>
              Dashboard
            </Typography>
            <Typography paragraph sx={styles.typoVisited}>
              Last visited at 09:00AM
            </Typography>
          </Box>
          <StatsFilter />
        </Box>
      </Box>
      <Box>{/* <CardsContainer /> */}</Box>
      <StatsTableContainer rows={createRows(data)} />
    </>
  );
}
