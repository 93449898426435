export enum PERMISSIONS {
  GET_STATISTICS = 'carrick-dashboard.statistics.read',
  CREATE_PUBLISHERS = 'carrick-dashboard.publishers.create',
  GET_PUBLISHERS = 'carrick-dashboard.publishers.read',
  GET_PUBLISHER = 'carrick-dashboard.publisher.read',
  EDIT_PUBLISHER = 'carrick-dashboard.publisher.write',
  GET_TAGS = 'carrick-dashboard.tags.read',
  GET_URLS = 'carrick-dashboard.urls.read',
  GET_CONTENT_OVERVIEW = 'carrick-dashboard.content-overview.read',
  GET_USER_SETTINGS = 'carrick-dashboard.user-settings.read',
  READ_LAST_PRODUCT_PULL = 'carrick-dashboard.last-product-pull.read',
}
