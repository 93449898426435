import { Box, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';
import { ReactElement } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';
import { IProduct } from 'common/ui/product/types';

interface StylesProps {
  inStock: boolean;
  image: string;
}

const getStyles = ({ inStock, image }: StylesProps): Styles => ({
  container: {
    backgroundColor: 'transparent',
    borderRadius: '8px',
    padding: theme.spacing(0.5, 2),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    marginTop: 1,
    cursor: 'pointer',
    border: inStock ? '1px solid #C2DEEB' : '1px solid #F5BEBC',
    maxWidth: '400px',

    ':first-child': {
      marginTop: 0,
    },
  },
  title: {
    fontSize: 14,
    minWidth: '130px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  alertIcon: { marginLeft: 1, color: '#F65147' },
  inStockIcon: { marginLeft: 1, color: '#62D294' },
  starIcon: { color: '#FFD645' },
  image: { width: 64, minWidth: 64, height: 64, backgroundImage: `url(${image})`, backgroundSize: 'contain' },
  textWrapper: { display: 'flex', flexDirection: 'row' },
  textContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 2,
    flexGrow: 1,
  },
  titleContainer: { display: 'flex', flexDirection: 'column' },
  asin: { color: '#6F8490', fontSize: '12px' },
  reviewsText: { fontSize: 10, ml: 1, lineHeight: 1, color: '#6F8490' },
  reviewsTitleText: { color: '#214254', fontSize: 10, opacity: 0.65 },
  ratingContent: { display: 'flex', alignItems: 'center' },
  avgReviewsText: { lineHeight: 1 },
});

const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#F65147',
    boxShadow: theme.shadows[1],
    padding: 10,
    fontSize: 11,
    borderRadius: '12px',
  },
}));

interface Props {
  product: IProduct;
  showInStock: boolean;
}

export function Product({ product, showInStock }: Props): ReactElement {
  const styles = getStyles({
    inStock: product.in_stock === undefined ? true : Boolean(product.in_stock),
    image: product.image ?? product.img_medium,
  });

  const renderInStockIcon = () => {
    if (product.in_stock) return <CheckCircleIcon sx={styles.inStockIcon} fontSize="small" />;
    return (
      <ErrorTooltip title="Out of Stock">
        <ErrorIcon sx={styles.alertIcon} fontSize="small" />
      </ErrorTooltip>
    );
  };

  const handleOpenProduct = () => {
    window.open(product.amazon_link);
  };

  return (
    <Box sx={styles.container} onClick={handleOpenProduct}>
      <Box sx={styles.image} />
      <Box sx={styles.textContent}>
        <Box sx={styles.textWrapper}>
          <Box sx={styles.titleContainer}>
            <Tooltip placement="top" title={product?.title}>
              <Typography sx={styles.title}>
                {product?.short_title
                  ? `${product?.short_title.slice(0, 15)}...`
                  : `${product?.title?.slice(0, 15)}...`}
              </Typography>
            </Tooltip>
            <Typography sx={styles.asin}>{product.asin}</Typography>
          </Box>
          <Box sx={styles.ratingContent}>
            {product.avg_review ? <StarIcon sx={styles.starIcon} /> : null}
            <Typography sx={styles.avgReviewsText}>{product.avg_review ?? ''}</Typography>
            <Typography sx={styles.reviewsText}>
              {product.review_count ? ` (${product.review_count} reviews)` : ''}
            </Typography>
          </Box>
        </Box>
        {showInStock && renderInStockIcon()}
      </Box>
    </Box>
  );
}
