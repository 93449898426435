import { Dispatch, ReactElement, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, Box, TextField, Tooltip, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { asinFinderActions } from 'modules/asin-finder/store/actions';

const styles: Styles = {
  name: {
    cursor: 'pointer',
    fontSize: 14,
    mr: '10px',
    width: '90%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  select: { width: '100%' },
  option: { display: 'flex', alignItems: 'center' },
};

interface Props {
  selectedBrand: string | number;
  setSelectedBrand: Dispatch<string | number>;
  size?: 'small' | 'medium';
  disabled?: boolean;
  brandParents: Array<{
    brandparent: string;
    brandparentid: number;
  }>;
}

export function BrandParentsSelect({
  selectedBrand,
  setSelectedBrand,
  size = 'small',
  disabled = false,
  brandParents,
}: Props): ReactElement {
  const dispatch = useDispatch();

  const autocompleteOptions = useMemo(() => {
    const options: Array<{ id: number | string; label: string; client_id?: string }> = (brandParents ?? []).map(
      brand => ({
        label: brand?.brandparent,
        id: brand?.brandparentid as number,
      })
    );

    return options;
  }, [brandParents]);

  const handleSelectedBrandChange = (_event: unknown, value: { id: number | string; label: string }) => {
    setSelectedBrand(value?.id ?? null);
  };

  useEffect(() => {
    dispatch(asinFinderActions.getBrandParents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      options={autocompleteOptions}
      disabled={disabled}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={handleSelectedBrandChange as any}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value={autocompleteOptions.find(o => o?.id === selectedBrand) as any}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderOption={(props, option) => (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <Box {...(props as any)} sx={styles.option} key={option.id}>
          <Tooltip title={option.label} placement="top" disableHoverListener={option.label.length <= 23}>
            <Typography sx={styles.name}>{option.label}</Typography>
          </Tooltip>
        </Box>
      )}
      sx={styles.select}
      renderInput={params => (
        <TextField {...params} label="Brand Parents" InputLabelProps={{ shrink: true }} size={size} />
      )}
    />
  );
}
