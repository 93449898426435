import { Action } from 'common/interfaces/root';
import { publishersSettingsActionTypes as T } from '../actions';

const initState: PublishersBrandCommissionsModule.PublisherBrandCommissionsStore = {
  loading: true,
  error: null,
  data: [],
};

export default (
  state: PublishersBrandCommissionsModule.PublisherBrandCommissionsStore = initState,
  { type, payload }: Action
): PublishersBrandCommissionsModule.PublisherBrandCommissionsStore => {
  switch (type) {
    case T.GET_PUBLISHER_BRAND_COMMISSIONS: {
      return { ...state, loading: true };
    }
    case T.GET_PUBLISHER_BRAND_COMMISSIONS_SUCCESS: {
      return { ...state, loading: false, data: payload };
    }
    case T.GET_PUBLISHER_BRAND_COMMISSIONS_ERROR: {
      return { ...state, loading: false, error: payload };
    }
    default: {
      return state;
    }
  }
};
