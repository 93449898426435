import { ReactElement, useEffect, useState } from 'react';
import ReactJoyride, { ACTIONS, CallBackProps, StoreHelpers } from 'react-joyride';
import { LC_ONBOARDING_FINISHED } from 'common/constants/lc';
import { usePublisher } from 'common/hooks';
import { useNavigate } from 'react-router-dom';
import { OnboardingModal } from './onboarding-modal';
import { getOnboardingSteps } from './constants';
import { WelcomeModal } from './welcome-modal';
import { CustomTooltip } from './custom-tooltip';

interface Props {
  handleChangeTourStart: (value: boolean) => void;
  tourRunning: boolean;
  handleOnboardingGainzClose: () => void;
  children: ReactElement;
}

export function Onboarding({
  handleChangeTourStart,
  tourRunning,
  handleOnboardingGainzClose,
  children,
}: Props): ReactElement {
  const publisher = usePublisher();
  const navigate = useNavigate();

  const [onboardingModalOpen, setOnboardingModalOpen] = useState<boolean>(false);
  const [welcomeModalOpen, setWelcomeModalOpen] = useState<boolean>(false);
  const [helpers, setHelpers] = useState<StoreHelpers>();

  const handleOnboardingModalClose = () => {
    setOnboardingModalOpen(false);
  };

  const handleWelcomeModalClose = () => {
    setWelcomeModalOpen(false);
  };

  const handleTourStart = () => {
    handleChangeTourStart(true);
  };

  const handleTourCallback = (props: CallBackProps) => {
    if (props.action !== ACTIONS.RESET) return;
    handleChangeTourStart(false);
    setWelcomeModalOpen(true);
    localStorage.setItem(LC_ONBOARDING_FINISHED, '1');
  };

  const handleGainzTourCallback = (props: CallBackProps) => {
    if (props.action !== ACTIONS.RESET) return;
    navigate('/affiliate-gainz/recommend');
    handleChangeTourStart(false);
    handleOnboardingGainzClose();
    localStorage.setItem(LC_ONBOARDING_FINISHED, '1');
  };

  useEffect(() => {
    if (!publisher || publisher?.gainz_active) return;
    setOnboardingModalOpen(localStorage.getItem(LC_ONBOARDING_FINISHED) !== '1');
  }, [publisher]);

  return (
    <>
      <ReactJoyride
        steps={getOnboardingSteps(publisher?.gainz_active)}
        // eslint-disable-next-line react/no-unstable-nested-components
        tooltipComponent={props => <CustomTooltip {...props} helpers={helpers} />}
        run={tourRunning}
        callback={!publisher?.gainz_active ? handleTourCallback : handleGainzTourCallback}
        locale={{ skip: 'Skip tour', last: 'Finish' }}
        floaterProps={{ styles: { arrow: { length: 8, spread: 10 } } }}
        getHelpers={helpers => setHelpers(helpers)}
        hideCloseButton
        hideBackButton
        styles={{
          options: { zIndex: 9999, width: 600 },
          tooltip: { borderRadius: '10px' },
          tooltipTitle: { textAlign: 'start', fontSize: 18, fontWeight: 600 },
          tooltipContent: { textAlign: 'start', lineHeight: '26px', fontSize: 14, paddingLeft: 0 },
          tooltipFooter: { display: 'flex', gap: 4, justifyContent: 'flex-end' },
          buttonSkip: {
            width: 120,
            border: '1px solid #C2DEEB',
            borderRadius: '4px',
            color: '#6F8490',
            fontSize: 12,
            height: 30,
          },
          buttonNext: { background: '#4288F0', borderRadius: '4px', height: 30, fontSize: 12, width: 105 },
          tooltipFooterSpacer: { flex: 0 },
        }}
        continuous
        showSkipButton
      />
      <OnboardingModal
        open={onboardingModalOpen}
        onClose={handleOnboardingModalClose}
        handleTourStart={handleTourStart}
      />
      <WelcomeModal open={welcomeModalOpen} onClose={handleWelcomeModalClose} />
      {children}
    </>
  );
}
