import { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Styles } from 'common/types';
import { ReactComponent as SuccessUrlIcon } from 'common/svg/success-url.svg';
import { ReactComponent as PendingUrlIcon } from 'common/svg/pending-url.svg';
import ErrorIcon from '@mui/icons-material/Error';
import { contentOverviewActions } from 'modules/content-overview/store/actions';
import { selectGlobalFilters } from 'common/store/selectors';
import { theme } from 'common/constants/theme';

const getStyles = ({ isDemoMode }: { isDemoMode: boolean }): Styles => ({
  link: {
    cursor: 'pointer',
    filter: isDemoMode ? 'blur(3px)' : 0,
  },
  success: {
    backgroundColor: '#E7F8EF',
    minWidth: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
    ml: 2,
  },
  pending: {
    backgroundColor: '#FEF9ED',
    minWidth: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
    ml: 2,
  },
  delete: {
    backgroundColor: 'transparent',
    minWidth: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#DDE8ED',
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    minWidth: '20px',
    height: '20px',
  },
  lastScraped: {
    minWidth: '200px',
    fontSize: '14px',
  },
  blurCell: {
    filter: isDemoMode ? 'blur(3px)' : 0,
  },
});

interface Props {
  page: ContentOverviewModule.PageEntry;
  handleUpdatePage: (message: string) => void;
  isDemoMode?: boolean;
}

export function PagesTableRow({ page, handleUpdatePage, isDemoMode }: Props): ReactElement {
  const styles = getStyles({ isDemoMode });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filters = useSelector(selectGlobalFilters);

  const status = useMemo(() => {
    switch (page.scraping_status) {
      case 'in-progress':
        return { color: '#FEF9ED', icon: PendingUrlIcon };
      case 'pending':
        if (page.scrap_priority_status === 100) {
          // Url requested to scrap
          return { color: '#FEF9ED', icon: PendingUrlIcon };
        }
        switch (page.last_scraped_result) {
          case 'success':
            return { color: '#E7F8EF', icon: SuccessUrlIcon };
          case 'failed':
            return { color: theme.palette.error.light, icon: ErrorIcon };
          default:
            return { color: '#FEF9ED', icon: PendingUrlIcon };
        }
      default:
        return { color: theme.palette.primary.light };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.last_scraped_result, page.scraping_status]);

  const handleOpenUrl = () => {
    window.open(page.url);
  };

  const handleOverview = () => {
    navigate(`/content-overview/replace?search=${page.url}`);
  };

  const handleDeleteUrl = () => {
    dispatch(
      contentOverviewActions.deleteUrl(
        {
          publisher_id: String(filters?.publisher_id),
          url_id: String(page.id),
        },
        () => handleUpdatePage('Url was deleted!')
      )
    );
  };

  const handleRescrapeUrl = () => {
    dispatch(
      contentOverviewActions.postUrlsRescrap(filters?.publisher_id, 'urls', String(page.id), () =>
        handleUpdatePage('Url was rescraped!')
      )
    );
  };

  return (
    <TableRow>
      <TableCell sx={styles.link} onClick={handleOpenUrl}>
        {page.url ?? '-'}
      </TableCell>
      <TableCell sx={styles.blurCell}>{page.title ?? '-'}</TableCell>
      <TableCell sx={styles.blurCell}>{page.description ?? '-'}</TableCell>
      <TableCell>
        <Typography sx={styles.lastScraped}>
          {page?.last_scraped_at
            ? `${moment(page?.last_scraped_at).format('DD MMM, YY')} at ${moment(page?.last_scraped_at).format(
                'hh:mm'
              )}`
            : ''}
        </Typography>
      </TableCell>
      <TableCell>
        <Box sx={styles.buttonWrapper}>
          <Box sx={styles.delete} onClick={handleRescrapeUrl}>
            <RefreshIcon sx={styles.icon} />
          </Box>
          <Box sx={styles.delete} onClick={handleDeleteUrl}>
            <DeleteIcon sx={styles.icon} />
          </Box>
          <Box sx={{ ...styles.success, backgroundColor: status.color }} onClick={handleOverview}>
            {status.icon && <status.icon />}
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
}
