import { presetsApi } from 'common/services/presets-api.service';
import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import { Action } from 'common/interfaces/root';
import { presetsActions } from '../actions/presets';

export function* getPresetsSaga(): SagaIterator {
  try {
    const [networksResponse, publishersResponse] = yield all([
      call(presetsApi.getAffiliateNetworks),
      call(presetsApi.getPublishers),
    ]);

    yield put(
      presetsActions.getFiltersPresetsSuccess({
        publishers: publishersResponse?.data?.publishers,
        affiliateNetworks: networksResponse?.data?.affiliate_network_types,
      })
    );
  } catch (err) {
    yield put(presetsActions.getFiltersPresetsError(err as Error));
  }
}

export function* getTimeInfo({ payload }: Action) {
  try {
    const { data } = yield call(presetsApi.getTimeInfo, payload);

    yield put(presetsActions.getTimeInfoSuccess(data));
  } catch (err) {
    yield put(presetsActions.getTimeInfoError(err as Error));
  }
}
