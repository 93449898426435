import { TableCell, TableRow } from '@mui/material';
import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { attributionActions as actions } from 'modules/attribution/store/actions';
import { TableRowActions } from 'common/ui/table-row-actions';

interface Props {
  attr: Attribution.AmazonAttrData;
}

export function AttributionRow({ attr }: Props): ReactElement {
  const dispatch = useDispatch();

  const handleDeleteRow = () => {
    dispatch(actions.deleteAmazonAttrItem(attr.id));
  };

  return (
    <TableRow>
      <TableCell>{attr?.asin ?? ''}</TableCell>
      <TableCell>{attr?.attribution_publisher ?? ''}</TableCell>
      <TableCell>{attr?.tags ?? ''}</TableCell>
      <TableCell>{attr?.publisher_name ?? ''}</TableCell>
      <TableCell align="right">
        <TableRowActions show={['delete']} onDelete={handleDeleteRow} />
      </TableCell>
    </TableRow>
  );
}
