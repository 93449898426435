import { Box, IconButton, Popover, TableCell, TableRow, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import LaunchIcon from '@mui/icons-material/Launch';
import { Styles } from 'common/types';
import { ReactElement, useState } from 'react';
import { OOSIcon } from 'modules/revenue-by-page/components';
import { TableCellList } from './table-cell-list';

const styles: Styles = {
  title: { display: 'flex', alignItems: 'center', textTransform: 'capitalize' },
  popover: {
    pointerEvents: 'none',
  },
  popoverBox: {
    padding: 1,
  },
  adFeeBox: {
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    ':hover': { cursor: 'pointer' },
    marginLeft: 1,
  },
  mobileFeesBox: { marginBottom: 2 },
};

interface Props {
  row: RevenueByPage.TableRow;
}

export function RevenueTableRow({ row }: Props): ReactElement {
  const [tagsAnchorEl, setTagsAnchorEl] = useState<HTMLElement | null>(null);
  const [feesAnchorEl, setFeesAnchorEl] = useState<SVGSVGElement | null>(null);
  const [shippedAchorEl, setShippedAchorEl] = useState<HTMLElement | null>(null);

  const money = new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' });
  const number = new Intl.NumberFormat();

  const tagsOpen = Boolean(tagsAnchorEl);
  const feesOpen = Boolean(feesAnchorEl);
  const shippedOpen = Boolean(shippedAchorEl);

  const tags = new Set(row.tags?.split(', '));

  const handleClose = () => {
    setTagsAnchorEl(null);
    setShippedAchorEl(null);
  };

  const handleFeesClose = () => {
    setFeesAnchorEl(null);
  };

  const handleTitleClick = () => {
    window.open(row.link, '_blank');
  };

  // * Need API updates before enabling in prod
  // useEffect(() => {
  //   setAsins({ loading: true, error: null, data: null });
  //   getArticleAsins({ publisher_id: filters.publisher_id, article_id: +row.article_id }, setAsins);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <TableRow>
      <TableCell>
        <Box sx={styles.title}>
          {row.article_title?.trim()?.split(' ').pop()?.split('-').join(' ')}
          <IconButton onClick={handleTitleClick}>
            <LaunchIcon fontSize="small" />
          </IconButton>
        </Box>
      </TableCell>
      {(tags.size > 1 && (
        <TableCellList
          onClick={e => setTagsAnchorEl(e.currentTarget)}
          tags={tags}
          open={tagsOpen}
          anchor={tagsAnchorEl}
          handleClose={handleClose}
          row={row}
          isTag
        />
      )) || <TableCell>{row.tags?.split(', ')[0]}</TableCell>}
      <TableCellList
        onClick={e => setShippedAchorEl(e.currentTarget)}
        open={shippedOpen}
        anchor={shippedAchorEl}
        handleClose={handleClose}
        row={row}
      />
      <TableCell>{row.visits || ''}</TableCell>
      <TableCell>{number.format(row.clicks)}</TableCell>
      <TableCell>{`${(row.conversion ?? 0).toFixed(2) ?? 0}%`}</TableCell>
      <TableCell>{money.format(parseFloat(row.total_revenue))}</TableCell>
      <TableCell>
        <Box sx={styles.adFeeBox}>
          {money.format(parseFloat(row.total_ad_fees))}
          <InfoIcon
            fontSize="small"
            onMouseEnter={e => setFeesAnchorEl(e.currentTarget)}
            onMouseLeave={handleFeesClose}
            sx={styles.infoIcon}
          />
        </Box>
        <Popover
          sx={styles.popover}
          open={feesOpen}
          anchorEl={feesAnchorEl}
          onClose={handleFeesClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box sx={styles.popoverBox}>
            <Box sx={styles.mobileFeesBox}>
              <Typography variant="h6">Mobile ad fees:</Typography>
              <Typography>{money.format(parseFloat(row.mobile_ad_fees))}</Typography>
            </Box>
            <Box>
              <Typography variant="h6">Desktop ad fees:</Typography>
              <Typography>{money.format(parseFloat(row.desktop_ad_fees))}</Typography>
            </Box>
          </Box>
        </Popover>
      </TableCell>

      {/* // * Need API updates before enabling in prod
          // * Missing data needs provided by scraping article URLs
      {asins.loading && (
        <TableCell>
          <CircularProgress />
        </TableCell>
      )}
      {!asins.loading && (
        <>
          {asins.data?.length > 1 && (
            <TableCellList
              onClick={e => setAsinsAnchorEl(e.currentTarget)}
              open={asinsOpen}
              anchor={asinsAnchorEl}
              handleClose={handleClose}
              row={row}
              asins={asins.data}
              isAsin
            />
          )}
          {asins.data?.length === 1 && <TableCell>{asins.data[0].asin}</TableCell>}
          {asins.data?.length === 0 && <TableCell>...</TableCell>}
        </>
      )} */}
      <TableCell>{row.oos_count > 0 && <OOSIcon />}</TableCell>
    </TableRow>
  );
}
