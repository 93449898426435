import { Box, Button, CircularProgress, IconButton, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { Modal } from 'common/ui/modal';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { selectBrandParents, selectBrands, selectGenerateReportLoading } from 'modules/asin-finder/store/selectors';
import { BrandSelect } from './brand-select';
import { BrandParentsSelect } from './brand-parents-select';

const styles: Styles = {
  fieldWrapper: { display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 },
  button: { maxWidth: 100, width: '100%', marginLeft: 1, height: '37px', ':first-child': { marginLeft: 0 } },
  buttons: { marginTop: 2, display: 'flex', justifyContent: 'flex-end' },
  input: { width: '100%', marginTop: 2 },
  brandList: { marginTop: 1, width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' },
  brandItem: { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
};

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (values: AsinFinder.GenerateAosData) => void;
  defaultAsins?: Array<string>;
}

export function AosModal({ open, onClose, onConfirm, defaultAsins }: Props): ReactElement {
  const [selectedBrand, setSelectedBrand] = useState<number | string>(null);
  const [brandList, setBrandList] = useState<Array<number>>([]);
  const [selectedBrandsParent, setSelectedBrandsParent] = useState<number | string>(null);
  const [brandsParentList, setBrandsParentList] = useState<Array<number>>([]);
  const [asins, setAsins] = useState<Array<string>>([]);

  const brands = useSelector(selectBrands);
  const brandParents = useSelector(selectBrandParents);
  const loading = useSelector(selectGenerateReportLoading);

  const handleChangeAsins = (e: ChangeEvent<HTMLInputElement>) => {
    const set = new Set(e.target.value?.split(/(?:,| |, |\n)+/));
    setAsins(Array.from(set));
  };

  const handleConfirm = () => {
    onConfirm({
      ...(brandList?.length || selectedBrand
        ? { brands_ids: [...brandList, ...(selectedBrand ? [Number(selectedBrand)] : [])] }
        : {}),
      ...(brandsParentList?.length || selectedBrandsParent
        ? { brands_parents_ids: [...brandsParentList, ...(selectedBrandsParent ? [Number(selectedBrandsParent)] : [])] }
        : {}),
      ...(asins[0] !== '' && asins?.length ? { asins } : {}),
    });
    onClose();
  };

  const handleAddBrandParentToList = () => {
    if (selectedBrandsParent && !brandsParentList.find(pub => pub === selectedBrandsParent)) {
      setBrandsParentList([...brandsParentList, selectedBrandsParent as number]);
    }
  };

  const handleAddBrandToList = () => {
    if (selectedBrand && !brandList.find(pub => pub === selectedBrand)) {
      setBrandList([...brandList, selectedBrand as number]);
    }
  };

  const handleRemoveBrandParent = (value: number) => {
    setBrandsParentList(brandsParentList.filter(br => br !== value));
  };

  const handleRemoveBrand = (value: number) => {
    setBrandList(brandList.filter(br => br !== value));
  };

  useEffect(() => {
    if (open) {
      setAsins(defaultAsins);
    }
  }, [defaultAsins, open]);

  useEffect(() => {
    if (brandsParentList.length) {
      setSelectedBrandsParent(null);
    }
  }, [brandsParentList]);

  useEffect(() => {
    if (brandList.length) {
      setSelectedBrand(null);
    }
  }, [brandList]);

  return (
    <Modal open={open} onClose={onClose} maxWidth="xs" title="Confirm action">
      <Box sx={styles.fieldWrapper}>
        <BrandParentsSelect
          selectedBrand={selectedBrandsParent}
          setSelectedBrand={setSelectedBrandsParent}
          brandParents={brandParents?.filter(item => !brandList?.includes(item.brandparentid))}
        />
        <IconButton onClick={handleAddBrandParentToList}>
          <AddIcon />
        </IconButton>
      </Box>
      <Box sx={styles.brandList}>
        {Boolean(brandList?.length) && <Typography>Brand Parents:</Typography>}
        {brandsParentList.map((item: number) => (
          <Box sx={styles.brandItem}>
            <Typography key={item}>{brandParents?.find(pub => pub.brandparentid === item)?.brandparent}</Typography>
            <IconButton onClick={() => handleRemoveBrandParent(item)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        ))}
      </Box>
      <Box sx={styles.fieldWrapper}>
        <BrandSelect
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          brandParents={brands?.filter(item => !brandList?.includes(item.brandid))}
        />
        <IconButton onClick={handleAddBrandToList}>
          <AddIcon />
        </IconButton>
      </Box>
      <Box sx={styles.brandList}>
        {Boolean(brandList?.length) && <Typography>Brands:</Typography>}
        {brandList.map((item: number) => (
          <Box sx={styles.brandItem}>
            <Typography key={item}>{brands?.find(pub => pub.brandid === item)?.brand}</Typography>
            <IconButton onClick={() => handleRemoveBrand(item)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        ))}
      </Box>
      <TextField
        multiline
        maxRows={4}
        size="small"
        color="primary"
        label="ASIN"
        sx={styles.input}
        value={asins}
        InputLabelProps={{ shrink: true }}
        onChange={handleChangeAsins}
      />
      <Box sx={styles.buttons}>
        <Button sx={styles.button} color="primary" variant="contained" disabled={loading} onClick={handleConfirm}>
          {!loading ? 'Generate' : <CircularProgress size={14} color="inherit" />}
        </Button>
        <Button color="info" variant="outlined" sx={styles.button} onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}
