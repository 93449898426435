import { State } from 'common/store/reducers';

const asins = (state: State) => state.affiliateRateCard.asins;
const customRate = (state: State) => state.affiliateRateCard.customRate;
const defaultRate = (state: State) => state.affiliateRateCard.defaultRate;
const filters = (state: State) => state.affiliateRateCard.filters;

export const affiliateRateCardSelectors = {
  asins,
  customRate,
  defaultRate,
  filters,
};
