import { MouseEvent, ReactElement, useMemo, useState } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import RocketIcon from '@mui/icons-material/Rocket';
import { ReactComponent as AttributionIcon } from 'common/svg/maverickx-logomark-c.svg';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useSelector } from 'react-redux';
import { asinRecommendationSelectors } from 'modules/asin-recommendation/store/selectors';
import { usePermission } from 'common/hooks';
import { PERMISSIONS } from 'common/constants/permissions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { TagsPopover } from './account-popover';
import { PlacementsList } from './placements-list';
import { VariationsList } from './variations-list';

interface StylesProps {
  image: string;
  inStock: boolean;
}

const getStyles = ({ image, inStock }: StylesProps): Styles => ({
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    alignItems: 'center',
    padding: '24px',
    boxSizing: 'border-box',
    height: 'fit-content',
  },
  image: {
    width: 120,
    minWidth: 120,
    height: 120,
    backgroundImage: `url(${image})`,
    backgroundSize: 'contain',
    cursor: 'pointer',
  },
  read: { color: '#4288F0', display: 'flex', alignItems: 'center', cursor: 'pointer', width: 'fit-content' },
  readIcon: { marginLeft: 1 },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    margin: '25px 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentBar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #E1EFF5',
  },
  field: {
    width: '210px',
    padding: '15px 0',
    boxSizing: 'border-box',
  },
  fieldTitle: {
    fontSize: '14px',
    color: '#58717F',
    lineHeight: '15px',
    letterSpacing: '1px',
  },
  fieldValue: {
    fontSize: '16px',
    color: '#060F14',
    lineHeight: '23px',
  },
  fieldValueBlue: {
    fontSize: '16px',
    color: '#4288F0',
    lineHeight: '23px',
  },
  urlContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: 'lightgray',
    padding: 1,
    width: '95%',
  },
  url: { ':hover': { textDecoration: 'underline', cursor: 'pointer' } },
  noneContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    padding: 1,
  },
  articlesArea: {
    width: '100%',
    borderBottom: '1px solid #E1EFF5',
    padding: '15px 0',
    boxSizing: 'border-box',
  },
  stockStatus: {
    maxWidth: inStock ? '100px' : '130px',
    borderRadius: '10px',
    padding: '5px 10px',
    boxSizing: 'border-box',
    color: inStock ? '#62d295' : '#214254',
    backgroundColor: inStock ? '#62d29530' : '#21425430',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  srIcon: { fontSize: 15, color: 'purple' },
  typeValue: { display: 'flex', justifyContent: 'flex-start', gap: 2 },
  generatBttn: { mt: 1, color: '#ffffff', backgroundColor: '#a3a3a3', ':hover': { color: '#363636' } },
  tagsWrapper: {
    mt: 1,
    width: '100%',
    borderBottom: '1px solid #E1EFF5',
    padding: '10px 0',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  partialTagLink: {
    ':hover': {
      textDecorationLine: 'underline',
      cursor: 'pointer',
    },
  },
  tagItem: {
    color: '#4288F0',
    padding: '2px 0',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
});

interface Props {
  open: boolean;
  onClose: () => void;
}

export function RecommendModal({ open, onClose }: Props): ReactElement {
  const adminView = usePermission(PERMISSIONS.GET_PUBLISHERS);

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [tags, setTags] = useState<Array<string>>([]);

  const { selected } = useSelector(asinRecommendationSelectors.selectGrid);

  const styles = getStyles({
    image: selected?.extended_product?.image ?? selected?.extended_product?.img_medium,
    inStock: selected?.in_stock,
  });

  const shortTitle = useMemo(() => {
    return selected?.extended_product?.title?.length > 80
      ? `${selected?.extended_product?.title.slice(0, 80)}...`
      : selected?.extended_product?.title;
  }, [selected?.extended_product?.title]);

  const handleAddNewTag = (value: string) => {
    setTags([...tags, value]);
  };

  const handleAccountPopoverOpen = (e: MouseEvent<HTMLElement>) => {
    setAnchorElement(e.currentTarget);
  };

  const handleAccountPopoverClose = () => {
    setAnchorElement(null);
  };

  const handleOpenPage = () => {
    window.open(selected?.extended_product?.amazon_link);
  };

  const handleUrlClick = (url: string) => {
    window.open(url, '_blank');
  };

  const urls = useMemo(() => {
    const splitted = selected?.urls?.split(',') ?? [];
    return splitted.map(url => {
      const path = new URL(url).pathname ?? '-';
      return { url, path };
    });
  }, [selected?.urls]);

  return (
    <Modal open={open} onClose={onClose} maxWidth="md" title={shortTitle}>
      <Typography sx={styles.read} onClick={handleOpenPage}>
        Read more <ArrowRightAltIcon sx={styles.readIcon} color="inherit" />
      </Typography>
      <Box sx={styles.wrapper}>
        <Box sx={styles.imageContainer}>
          <Box sx={styles.image} onClick={handleOpenPage} />
          {selected?.type !== 'sr_products' && (
            <>
              <Button color="inherit" variant="contained" sx={styles.generatBttn} onClick={handleAccountPopoverOpen}>
                Generate attribution tags
              </Button>
              <TagsPopover
                onClose={handleAccountPopoverClose}
                anchorElement={anchorElement}
                asin={selected?.asin}
                handleAddNewTag={handleAddNewTag}
              />
            </>
          )}
        </Box>
        <Box sx={styles.content}>
          <Box sx={styles.contentBar}>
            <Box sx={styles.field}>
              <Typography sx={styles.fieldTitle}>ASIN</Typography>
              <Typography sx={styles.fieldValue}>{selected?.asin}</Typography>
            </Box>
            <Box sx={styles.field}>
              <Typography sx={styles.fieldTitle}>BRAND</Typography>
              <Typography sx={styles.fieldValueBlue}>{selected?.brand}</Typography>
            </Box>
            <Box sx={styles.field}>
              <Typography sx={styles.fieldTitle}>COMMISSION</Typography>
              <Typography sx={styles.fieldValue}>
                {selected?.affiliate_rate ? `${selected?.affiliate_rate}%` : '-'}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.contentBar}>
            <Box sx={styles.field}>
              <Typography sx={styles.fieldTitle}>CATEGORY</Typography>
              <Typography sx={styles.fieldValueBlue}>{selected?.parent_category}</Typography>
            </Box>
            <Box sx={styles.field}>
              <Typography sx={styles.fieldTitle}>BEST SELLER RANK</Typography>
              <Typography sx={styles.fieldValue}>-</Typography>
            </Box>
            <Box sx={styles.field}>
              <Typography sx={styles.fieldTitle}>AVAILABILITY</Typography>
              <Typography sx={styles.fieldValue}>
                <Box sx={styles.stockStatus}>{selected?.in_stock ? 'In Stock' : 'Out of Stock'}</Box>
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.contentBar}>
            <Box sx={styles.field}>
              <Typography sx={styles.fieldTitle}>RATING</Typography>
              <Typography sx={styles.fieldValue}>{selected?.extended_product?.avg_review || '-'}</Typography>
            </Box>
            <Box sx={styles.field}>
              <Typography sx={styles.fieldTitle}># OF REVIEWS</Typography>
              <Typography sx={styles.fieldValue}>{selected?.extended_product?.review_count || '-'}</Typography>
            </Box>
            {adminView && (
              <Box sx={styles.field}>
                <Typography sx={styles.fieldTitle}>TYPE</Typography>
                <Box sx={styles.typeValue}>
                  <Typography sx={styles.fieldValue}>
                    {selected?.type === 'sr_products' ? 'SR' : 'Attribution'}
                  </Typography>
                  <Typography>
                    {selected?.type === 'sr_products' ? (
                      <RocketIcon sx={styles.srIcon} />
                    ) : (
                      <AttributionIcon width="15px" height="15px" />
                    )}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          {Boolean(tags?.length) && (
            <Box sx={styles.tagsWrapper}>
              <Typography sx={styles.fieldTitle}>TAGS</Typography>
              {tags?.map(tag => {
                const productLink = selected?.asin_link;
                const fullLink = `${productLink}?${tag}`;

                return (
                  <Box key={tag} sx={styles.tagItem}>
                    <IconButton onClick={() => navigator.clipboard.writeText(fullLink)}>
                      <ContentCopyIcon color="primary" fontSize="small" />
                    </IconButton>
                    <Typography
                      sx={styles.partialTagLink}
                      onClick={() => navigator.clipboard.writeText(productLink)}
                      noWrap
                    >
                      {productLink}
                    </Typography>
                    <Typography sx={styles.partialTagLink} onClick={() => navigator.clipboard.writeText(tag)} noWrap>
                      {tag?.length > 40 ? `?${tag.slice(0, 40)}...` : `?${tag}`}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
          {selected?.asin_status === 'In Article' && urls?.length !== 0 && (
            <Box sx={styles.articlesArea}>
              <Typography sx={styles.fieldTitle}>IN ARTICLES</Typography>
              <Box>
                {urls?.length === 0 ? (
                  <Box sx={styles.noneContainer} />
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {urls?.map(url => (
                      <Box sx={styles.urlContainer}>
                        <Box sx={styles.url} key={url.url} onClick={() => handleUrlClick(url.url)}>
                          {url.path}
                        </Box>
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            </Box>
          )}
          {selected?.asin_status === 'Not In Article' && selected.in_stock && (
            <PlacementsList handleUrlClick={handleUrlClick} />
          )}
          {selected?.variations && <VariationsList />}
        </Box>
      </Box>
    </Modal>
  );
}
