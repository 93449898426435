const MAIN_PUBLISHER_SETTINGS_MODULE = '[publisher-settings:main]';
const PUBLISHER_TAGS_SETTINGS_MODULE = '[publisher-settings:tags]';
const PUBLISHER_URLS_SETTINGS_MODULE = '[publisher-settings:urls]';
const PUBLISHER_AFFILIATE_SETTINGS_MODULE = '[publisher-settings:affiliate]';
const PUBLISHER_TEAM_SETTINGS_MODULE = '[publisher-settings:team]';
const PUBLISHER_INTEGRATIONS_SETTINGS_MODULE = '[publisher-settings:integrations]';
const PUBLISHER_INTEGRATIONS_RATE_CARDS_MODULE = '[publisher-settings:rate-cards]';
const EMBED_MODULE = '[publisher-settings:embed]';
const PUBLISHER_BRAND_COMMISSIONS_MODULE = '[publisher-settings:brand-commissions]';

const GET_MAIN_PUBLISHER_INFO = `${MAIN_PUBLISHER_SETTINGS_MODULE} GET_MAIN_PUBLISHER_INFO`;
const GET_MAIN_PUBLISHER_INFO_SUCCESS = `${MAIN_PUBLISHER_SETTINGS_MODULE} GET_MAIN_PUBLISHER_INFO_SUCCESS`;
const GET_MAIN_PUBLISHER_INFO_ERROR = `${MAIN_PUBLISHER_SETTINGS_MODULE} GET_MAIN_PUBLISHER_INFO_ERROR`;

const PATCH_MAIN_PUBLISHER_CATEGORIES = `${MAIN_PUBLISHER_SETTINGS_MODULE} PATCH_MAIN_PUBLISHER_CATEGORIES`;
const PATCH_MAIN_PUBLISHER_CATEGORIES_SUCCESS = `${MAIN_PUBLISHER_SETTINGS_MODULE} PATCH_MAIN_PUBLISHER_CATEGORIES_SUCCESS`;
const PATCH_MAIN_PUBLISHER_CATEGORIES_ERROR = `${MAIN_PUBLISHER_SETTINGS_MODULE} PATCH_MAIN_PUBLISHER_CATEGORIES_ERROR`;

const PATCH_MAIN_PUBLISHER_INFO = `${MAIN_PUBLISHER_SETTINGS_MODULE} PATCH_MAIN_PUBLISHER_INFO`;
const PATCH_MAIN_PUBLISHER_INFO_SUCCESS = `${MAIN_PUBLISHER_SETTINGS_MODULE} PATCH_MAIN_PUBLISHER_INFO_SUCCESS`;
const PATCH_MAIN_PUBLISHER_INFO_ERROR = `${MAIN_PUBLISHER_SETTINGS_MODULE} PATCH_MAIN_PUBLISHER_INFO_ERROR`;

const MAIN_CHANGE_PUBLISHER_CRAWLER = `${MAIN_PUBLISHER_SETTINGS_MODULE} MAIN_CHANGE_PUBLISHER_CRAWLER`;

const GET_PUBLISHER_TAGS = `${PUBLISHER_TAGS_SETTINGS_MODULE} GET_PUBLISHER_TAGS`;
const GET_PUBLISHER_TAGS_SUCCESS = `${PUBLISHER_TAGS_SETTINGS_MODULE} GET_PUBLISHER_TAGS_SUCCESS`;
const GET_PUBLISHER_TAGS_ERROR = `${PUBLISHER_TAGS_SETTINGS_MODULE} GET_PUBLISHER_TAGS_ERROR`;

const CHANGE_PUBLISHER_TAGS_PAGE = `${PUBLISHER_TAGS_SETTINGS_MODULE} CHANGE_PUBLISHER_TAGS_PAGE`;
const ADD_PUBLISHER_TAG = `${PUBLISHER_TAGS_SETTINGS_MODULE} ADD_PUBLISHER_TAG`;
const PATCH_DEFAULT_TAG = `${PUBLISHER_TAGS_SETTINGS_MODULE} PATCH_DEFAULT_TAG`;

const SET_TRAFFIC_TYPE = `${PUBLISHER_TAGS_SETTINGS_MODULE} SET_TRAFFIC_TYPE`;
const SET_USED = `${PUBLISHER_TAGS_SETTINGS_MODULE} SET_USED`;
const RESET_FILTERS = `${PUBLISHER_TAGS_SETTINGS_MODULE} RESET_FILTERS`;

const DELETE_PUBLISHER_TAG = `${PUBLISHER_TAGS_SETTINGS_MODULE} DELETE_PUBLISHER_TAG`;
const DELETE_PUBLISHER_TAG_SUCCESS = `${PUBLISHER_TAGS_SETTINGS_MODULE} DELETE_PUBLISHER_TAG_SUCCESS`;
const DELETE_PUBLISHER_TAG_ERROR = `${PUBLISHER_TAGS_SETTINGS_MODULE} DELETE_PUBLISHER_TAG_ERROR`;

const GET_PUBLISHER_URLS = `${PUBLISHER_URLS_SETTINGS_MODULE} GET_PUBLISHER_URLS`;
const GET_PUBLISHER_URLS_SUCCESS = `${PUBLISHER_URLS_SETTINGS_MODULE} GET_PUBLISHER_URLS_SUCCESS`;
const GET_PUBLISHER_URLS_ERROR = `${PUBLISHER_URLS_SETTINGS_MODULE} GET_PUBLISHER_URLS_ERROR`;

const CHANGE_PUBLISHER_URLS_PAGE = `${PUBLISHER_URLS_SETTINGS_MODULE} CHANGE_PUBLISHER_URLS_PAGE`;

const GET_PUBLISHER_AFFILIATE = `${PUBLISHER_AFFILIATE_SETTINGS_MODULE} GET_PUBLISHER_AFFILIATE`;
const GET_PUBLISHER_AFFILIATE_SUCCESS = `${PUBLISHER_AFFILIATE_SETTINGS_MODULE} GET_PUBLISHER_AFFILIATE_SUCCESS`;
const GET_PUBLISHER_AFFILIATE_ERROR = `${PUBLISHER_AFFILIATE_SETTINGS_MODULE} GET_PUBLISHER_AFFILIATE_ERROR`;

const PATCH_PUBLISHER_AFFILIATE = `${PUBLISHER_AFFILIATE_SETTINGS_MODULE} PATCH_PUBLISHER_AFFILIATE`;
const PATCH_PUBLISHER_AFFILIATE_SUCCESS = `${PUBLISHER_AFFILIATE_SETTINGS_MODULE} PATCH_PUBLISHER_AFFILIATE_SUCCESS`;
const PATCH_PUBLISHER_AFFILIATE_ERROR = `${PUBLISHER_AFFILIATE_SETTINGS_MODULE} PATCH_PUBLISHER_AFFILIATE_ERROR`;

const GET_PUBLISHER_TEAM = `${PUBLISHER_TEAM_SETTINGS_MODULE} GET_PUBLISHER_TEAM`;
const GET_PUBLISHER_TEAM_SUCCESS = `${PUBLISHER_TEAM_SETTINGS_MODULE} GET_PUBLISHER_TEAM_SUCCESS`;
const GET_PUBLISHER_TEAM_ERROR = `${PUBLISHER_TEAM_SETTINGS_MODULE} GET_PUBLISHER_TEAM_ERROR`;

const DELETE_TEAM_MEMBER = `${PUBLISHER_TEAM_SETTINGS_MODULE} DELETE_TEAM_MEMBER`;
const DELETE_TEAM_MEMBER_SUCCESS = `${PUBLISHER_TEAM_SETTINGS_MODULE} DELETE_TEAM_MEMBER_SUCCESS`;
const DELETE_TEAM_MEMBER_ERROR = `${PUBLISHER_TEAM_SETTINGS_MODULE} DELETE_TEAM_MEMBER_ERROR`;

const GET_WORDPRESS_PLUGIN = `${PUBLISHER_TEAM_SETTINGS_MODULE} GET_WORDPRESS_PLUGIN`;
const GET_WORDPRESS_PLUGIN_SUCCESS = `${PUBLISHER_TEAM_SETTINGS_MODULE} GET_WORDPRESS_PLUGIN_SUCCESS`;
const GET_WORDPRESS_PLUGIN_ERROR = `${PUBLISHER_TEAM_SETTINGS_MODULE} GET_WORDPRESS_PLUGIN_ERROR`;

const GET_INTEGRATION_LINK = `${PUBLISHER_INTEGRATIONS_SETTINGS_MODULE} GET_INTEGRATION_LINK`;
const GET_INTEGRATION_LINK_ERROR = `${PUBLISHER_INTEGRATIONS_SETTINGS_MODULE} GET_INTEGRATION_LINK_ERROR`;

const GET_INTEGRATIONS_LIST = `${PUBLISHER_INTEGRATIONS_SETTINGS_MODULE} GET_INTEGRATIONS_LIST`;
const GET_INTEGRATIONS_LIST_SUCCESS = `${PUBLISHER_INTEGRATIONS_SETTINGS_MODULE} GET_INTEGRATIONS_LIST_SUCCESS`;
const GET_INTEGRATIONS_LIST_ERROR = `${PUBLISHER_INTEGRATIONS_SETTINGS_MODULE} GET_INTEGRATIONS_LIST_ERROR`;

const DELETE_INTEGRATION_FROM_LIST = `${PUBLISHER_INTEGRATIONS_SETTINGS_MODULE} DELETE_INTEGRATION_FROM_LIST`;
const DELETE_INTEGRATION_FROM_LIST_SUCCESS = `${PUBLISHER_INTEGRATIONS_SETTINGS_MODULE} DELETE_INTEGRATION_FROM_LIST_SUCCESS`;
const DELETE_INTEGRATION_FROM_LIST_ERROR = `${PUBLISHER_INTEGRATIONS_SETTINGS_MODULE} DELETE_INTEGRATION_FROM_LIST_ERROR`;

const GET_RATE_CARDS = `${PUBLISHER_INTEGRATIONS_RATE_CARDS_MODULE} GET_RATE_CARDS`;
const GET_RATE_CARDS_SUCCESS = `${PUBLISHER_INTEGRATIONS_RATE_CARDS_MODULE} GET_RATE_CARDS_SUCCESS`;
const GET_RATE_CARDS_ERROR = `${PUBLISHER_INTEGRATIONS_RATE_CARDS_MODULE} GET_RATE_CARDS_ERROR`;
const SET_RATE_CARDS_SELECTED = `${PUBLISHER_INTEGRATIONS_RATE_CARDS_MODULE} SET_RATE_CARDS_SELECTED`;

const PATCH_RATE_CARDS = `${PUBLISHER_INTEGRATIONS_RATE_CARDS_MODULE} PATCH_RATE_CARDS`;
const PATCH_RATE_CARDS_SUCCESS = `${PUBLISHER_INTEGRATIONS_RATE_CARDS_MODULE} PATCH_RATE_CARDS_SUCCESS`;
const PATCH_RATE_CARDS_ERROR = `${PUBLISHER_INTEGRATIONS_RATE_CARDS_MODULE} PATCH_RATE_CARDS_ERROR`;

const POST_AFFILIATE_REPORT = `${PUBLISHER_AFFILIATE_SETTINGS_MODULE} POST_AFFILIATE_REPORT`;
const POST_AFFILIATE_REPORT_SUCCESS = `${PUBLISHER_AFFILIATE_SETTINGS_MODULE} POST_AFFILIATE_REPORT_SUCCESS`;
const POST_AFFILIATE_REPORT_ERROR = `${PUBLISHER_AFFILIATE_SETTINGS_MODULE} POST_AFFILIATE_REPORT_ERROR`;

const GET_AFFILIATE_REPORT_DOWNLOAD = `${PUBLISHER_AFFILIATE_SETTINGS_MODULE} GET_AFFILIATE_REPORT_DOWNLOAD`;
const GET_AFFILIATE_REPORT_DOWNLOAD_SUCCESS = `${PUBLISHER_AFFILIATE_SETTINGS_MODULE} GET_AFFILIATE_REPORT_DOWNLOAD_SUCCESS`;
const GET_AFFILIATE_REPORT_DOWNLOAD_ERROR = `${PUBLISHER_AFFILIATE_SETTINGS_MODULE} GET_AFFILIATE_REPORT_DOWNLOAD_ERROR`;

const GET_AFFILIATE_REPORT_HISTORY = `${PUBLISHER_AFFILIATE_SETTINGS_MODULE} GET_AFFILIATE_REPORT_HISTORY`;
const GET_AFFILIATE_REPORT_HISTORY_SUCCESS = `${PUBLISHER_AFFILIATE_SETTINGS_MODULE} GET_AFFILIATE_REPORT_HISTORY_SUCCESS`;
const GET_AFFILIATE_REPORT_HISTORY_ERROR = `${PUBLISHER_AFFILIATE_SETTINGS_MODULE} GET_AFFILIATE_REPORT_HISTORY_ERROR`;

const SEND_INVITATION = `${PUBLISHER_TEAM_SETTINGS_MODULE} SEND_INVITATION`;
const SEND_INVITATION_SUCCESS = `${PUBLISHER_TEAM_SETTINGS_MODULE} SEND_INVITATION_SUCCESS`;
const SEND_INVITATION_ERROR = `${PUBLISHER_TEAM_SETTINGS_MODULE} SEND_INVITATION_ERROR`;

const GET_PENDING_INVITATIONS = `${PUBLISHER_TEAM_SETTINGS_MODULE} GET_PENDING_INVITATIONS`;
const GET_PENDING_INVITATIONS_SUCCESS = `${PUBLISHER_TEAM_SETTINGS_MODULE} GET_PENDING_INVITATIONS_SUCCESS`;
const GET_PENDING_INVITATIONS_ERROR = `${PUBLISHER_TEAM_SETTINGS_MODULE} GET_PENDING_INVITATIONS_ERROR`;

const GENERATE_ATTR_TAG = `${EMBED_MODULE} GENERATE_ATTR_TAG`;
const GENERATE_ATTR_TAG_SUCCESS = `${EMBED_MODULE} GENERATE_ATTR_TAG_SUCCESS`;
const GENERATE_ATTR_TAG_ERROR = `${EMBED_MODULE} GENERATE_ATTR_TAG_ERROR`;

const GET_PUBLISHER_GROUPS = `${MAIN_PUBLISHER_SETTINGS_MODULE} GET_PUBLISHER_GROUPS`;
const GET_PUBLISHER_GROUPS_SUCCESS = `${MAIN_PUBLISHER_SETTINGS_MODULE} GET_PUBLISHER_GROUPS_SUCCESS`;
const GET_PUBLISHER_GROUPS_ERROR = `${MAIN_PUBLISHER_SETTINGS_MODULE} GET_PUBLISHER_GROUPS_ERROR`;

const GET_TEAM = `${PUBLISHER_TEAM_SETTINGS_MODULE} GET_TEAM`;
const GET_TEAM_SUCCESS = `${PUBLISHER_TEAM_SETTINGS_MODULE} GET_TEAM_SUCCESS`;
const GET_TEAM_ERROR = `${PUBLISHER_TEAM_SETTINGS_MODULE} GET_TEAM_ERROR`;

const GET_CLIENTS = `${PUBLISHER_TEAM_SETTINGS_MODULE} GET_CLIENTS`;
const GET_CLIENTS_SUCCESS = `${PUBLISHER_TEAM_SETTINGS_MODULE} GET_CLIENTS_SUCCESS`;
const GET_CLIENTS_ERROR = `${PUBLISHER_TEAM_SETTINGS_MODULE} GET_CLIENTS_ERROR`;

const PATCH_TEAM_USER = `${PUBLISHER_TEAM_SETTINGS_MODULE} PATCH_TEAM_USER`;
const PATCH_TEAM_USER_SUCCESS = `${PUBLISHER_TEAM_SETTINGS_MODULE} PATCH_TEAM_USER_SUCCESS`;
const PATCH_TEAM_USER_ERROR = `${PUBLISHER_TEAM_SETTINGS_MODULE} PATCH_TEAM_USER_ERROR`;

const CANCEL_TEAM_USER = `${PUBLISHER_TEAM_SETTINGS_MODULE} CANCEL_TEAM_USER`;
const CANCEL_TEAM_USER_SUCCESS = `${PUBLISHER_TEAM_SETTINGS_MODULE} CANCEL_TEAM_USER_SUCCESS`;
const CANCEL_TEAM_USER_ERROR = `${PUBLISHER_TEAM_SETTINGS_MODULE} CANCEL_TEAM_USER_ERROR`;

const RESEND_TEAM_USER = `${PUBLISHER_TEAM_SETTINGS_MODULE} RESEND_TEAM_USER`;
const RESEND_TEAM_USER_SUCCESS = `${PUBLISHER_TEAM_SETTINGS_MODULE} RESEND_TEAM_USER_SUCCESS`;
const RESEND_TEAM_USER_ERROR = `${PUBLISHER_TEAM_SETTINGS_MODULE} RESEND_TEAM_USER_ERROR`;

const GET_PUBLISHER_BRAND_COMMISSIONS = `${PUBLISHER_BRAND_COMMISSIONS_MODULE} GET_BRAND_COMMISSIONS`;
const GET_PUBLISHER_BRAND_COMMISSIONS_SUCCESS = `${PUBLISHER_BRAND_COMMISSIONS_MODULE} GET_BRAND_COMMISSIONS_SUCCESS`;
const GET_PUBLISHER_BRAND_COMMISSIONS_ERROR = `${PUBLISHER_BRAND_COMMISSIONS_MODULE} GET_BRAND_COMMISSIONS_ERROR`;

export const publishersSettingsActionTypes = {
  GET_MAIN_PUBLISHER_INFO,
  GET_MAIN_PUBLISHER_INFO_SUCCESS,
  GET_MAIN_PUBLISHER_INFO_ERROR,
  PATCH_MAIN_PUBLISHER_INFO,
  PATCH_MAIN_PUBLISHER_INFO_SUCCESS,
  PATCH_MAIN_PUBLISHER_INFO_ERROR,
  PATCH_MAIN_PUBLISHER_CATEGORIES,
  PATCH_MAIN_PUBLISHER_CATEGORIES_SUCCESS,
  PATCH_MAIN_PUBLISHER_CATEGORIES_ERROR,
  MAIN_CHANGE_PUBLISHER_CRAWLER,
  GET_PUBLISHER_TAGS,
  GET_PUBLISHER_TAGS_SUCCESS,
  GET_PUBLISHER_TAGS_ERROR,
  CHANGE_PUBLISHER_TAGS_PAGE,
  ADD_PUBLISHER_TAG,
  PATCH_DEFAULT_TAG,
  SET_TRAFFIC_TYPE,
  SET_USED,
  RESET_FILTERS,
  DELETE_PUBLISHER_TAG,
  DELETE_PUBLISHER_TAG_SUCCESS,
  DELETE_PUBLISHER_TAG_ERROR,
  GET_PUBLISHER_URLS,
  GET_PUBLISHER_URLS_SUCCESS,
  GET_PUBLISHER_URLS_ERROR,
  CHANGE_PUBLISHER_URLS_PAGE,
  GET_PUBLISHER_AFFILIATE,
  GET_PUBLISHER_AFFILIATE_SUCCESS,
  GET_PUBLISHER_AFFILIATE_ERROR,
  PATCH_PUBLISHER_AFFILIATE,
  PATCH_PUBLISHER_AFFILIATE_SUCCESS,
  PATCH_PUBLISHER_AFFILIATE_ERROR,
  GET_PUBLISHER_TEAM,
  GET_PUBLISHER_TEAM_SUCCESS,
  GET_PUBLISHER_TEAM_ERROR,
  GET_WORDPRESS_PLUGIN,
  GET_WORDPRESS_PLUGIN_SUCCESS,
  GET_WORDPRESS_PLUGIN_ERROR,
  GET_INTEGRATION_LINK,
  GET_INTEGRATION_LINK_ERROR,
  GET_INTEGRATIONS_LIST,
  GET_INTEGRATIONS_LIST_SUCCESS,
  GET_INTEGRATIONS_LIST_ERROR,
  DELETE_INTEGRATION_FROM_LIST,
  DELETE_INTEGRATION_FROM_LIST_SUCCESS,
  DELETE_INTEGRATION_FROM_LIST_ERROR,
  GET_RATE_CARDS,
  GET_RATE_CARDS_ERROR,
  GET_RATE_CARDS_SUCCESS,
  SET_RATE_CARDS_SELECTED,
  PATCH_RATE_CARDS,
  PATCH_RATE_CARDS_SUCCESS,
  PATCH_RATE_CARDS_ERROR,
  POST_AFFILIATE_REPORT,
  POST_AFFILIATE_REPORT_SUCCESS,
  POST_AFFILIATE_REPORT_ERROR,
  GET_AFFILIATE_REPORT_HISTORY,
  GET_AFFILIATE_REPORT_HISTORY_SUCCESS,
  GET_AFFILIATE_REPORT_HISTORY_ERROR,
  GET_AFFILIATE_REPORT_DOWNLOAD,
  GET_AFFILIATE_REPORT_DOWNLOAD_SUCCESS,
  GET_AFFILIATE_REPORT_DOWNLOAD_ERROR,
  SEND_INVITATION,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_ERROR,
  DELETE_TEAM_MEMBER,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_ERROR,
  GENERATE_ATTR_TAG,
  GENERATE_ATTR_TAG_SUCCESS,
  GENERATE_ATTR_TAG_ERROR,
  GET_PENDING_INVITATIONS,
  GET_PENDING_INVITATIONS_SUCCESS,
  GET_PENDING_INVITATIONS_ERROR,
  GET_PUBLISHER_GROUPS,
  GET_PUBLISHER_GROUPS_SUCCESS,
  GET_PUBLISHER_GROUPS_ERROR,
  GET_TEAM,
  GET_TEAM_SUCCESS,
  GET_TEAM_ERROR,
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_ERROR,
  PATCH_TEAM_USER,
  PATCH_TEAM_USER_SUCCESS,
  PATCH_TEAM_USER_ERROR,
  CANCEL_TEAM_USER,
  CANCEL_TEAM_USER_SUCCESS,
  CANCEL_TEAM_USER_ERROR,
  RESEND_TEAM_USER,
  RESEND_TEAM_USER_SUCCESS,
  RESEND_TEAM_USER_ERROR,
  GET_PUBLISHER_BRAND_COMMISSIONS,
  GET_PUBLISHER_BRAND_COMMISSIONS_SUCCESS,
  GET_PUBLISHER_BRAND_COMMISSIONS_ERROR,
};
