import { datePickerComparatorActions } from 'common/store/actions/date-picker-comparator';
import { selectDatePickerComparator } from 'common/store/selectors';
import { ReactElement } from 'react';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';

export function DatePickerComparator(): ReactElement {
  const dispatch = useDispatch();

  const { dateRanges } = useSelector(selectDatePickerComparator);

  const changeDateRanges = (item: RangeKeyDict) => {
    dispatch(
      datePickerComparatorActions.changeDateRanges({
        ...dateRanges,
        ...item,
      })
    );
  };

  return <DateRangePicker onChange={changeDateRanges} ranges={[dateRanges?.range1, dateRanges?.range2]} />;
}
