import { Box, Button, FormControl, MenuItem, Select, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReactElement } from 'react';
import { Styles } from 'common/types';

const styles: Styles = {
  boxContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '70px' },
  scheme: { color: '#739AAC' },
  button: { minWidth: '32px', height: '32px', borderRadius: '25%' },
  buttonScheme: { minWidth: '32px', height: '32px', borderRadius: '25%', color: '#739AAC' },
  buttonPage: { minWidth: '32px', height: '32px', borderRadius: '25%', padding: '0', borderColor: '#C2DEEB' },
  boxSelect: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  typoShow: { color: '#739AAC', marginRight: 1 },
  select: { borderRadius: '8px', borderColor: '#C2DEEB' },
};

export function StatsTablePagination(): ReactElement {
  return (
    <Box sx={styles.boxContainer}>
      <Typography sx={styles.scheme}>1 - 10 of 250</Typography>
      <Box>
        <Button sx={styles.button} size="small" variant="contained">
          1
        </Button>
        <Button sx={styles.buttonScheme} variant="text">
          2
        </Button>
        <Button sx={styles.buttonScheme} variant="text">
          3
        </Button>
        <Button sx={styles.buttonPage} variant="outlined">
          <ChevronRightIcon sx={styles.scheme} />
        </Button>
      </Box>
      <Box sx={styles.boxSelect}>
        <Typography sx={styles.typoShow}>Show</Typography>
        <FormControl>
          <Select sx={styles.select} size="small" value="10">
            <MenuItem>
              <Typography sx={styles.scheme}>10</Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
