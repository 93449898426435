import { Box, CircularProgress, Paper, SxProps, TableContainer, TableContainerProps } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement } from 'react';

interface StylesProps {
  loading: boolean;
  round: boolean;
  minHeight?: number | string;
  maxHeight?: number | string;
}

const getStyles = ({ loading, round, minHeight, maxHeight }: StylesProps): Styles => ({
  loadingContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    zIndex: 10,
  },
  withBlur: {
    filter: loading ? 'blur(1px)' : 'none',
    overflowX: 'scroll',
    position: 'relative',
    height: '100%',
    maxHeight: 'inherit',
  },
  table: {
    minHeight: minHeight || 240,
    maxHeight,
    height: maxHeight ? '100%' : 'unset',
    position: 'relative',
    borderRadius: round ? '20px' : 0,
    boxShadow: 'none',
  },
});

export interface CustomTableContainerProps extends TableContainerProps {
  loading?: boolean;
  round?: boolean;
  minHeight?: number | string;
  maxHeight?: number | string;
}

export function TableContainerWithLoading({
  loading = false,
  sx,
  children,
  round = true,
  minHeight,
  maxHeight,
  ...props
}: CustomTableContainerProps): ReactElement {
  const styles = getStyles({ loading, round, minHeight, maxHeight });

  return (
    <TableContainer component={Paper} sx={{ ...styles.table, ...sx } as SxProps} {...props}>
      <Box sx={styles.withBlur}>{children}</Box>
      {loading && (
        <Box sx={styles.loadingContainer}>
          <CircularProgress color="primary" />
        </Box>
      )}
    </TableContainer>
  );
}
