import { Action } from 'common/interfaces/root';
import { publishersSettingsActionTypes as T } from '../actions';

const initState: AffiliateReportModule.AffiliateReportState = {
  loading: true,
  error: null,
  data: '',
};

export default (
  state: AffiliateReportModule.AffiliateReportState = initState,
  { type, payload }: Action
): AffiliateReportModule.AffiliateReportState => {
  switch (type) {
    case T.POST_AFFILIATE_REPORT:
      return { ...state, loading: true };

    case T.POST_AFFILIATE_REPORT_SUCCESS:
      return { ...state, loading: false, data: payload };

    case T.POST_AFFILIATE_REPORT_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
