import { Action } from 'common/interfaces/root';
import { mavPlusActionTypes as T } from '../actions';

export interface ExportStoreTypes {
  loading: boolean;
  error: Error | null;
  link: string;
}

const initState: ExportStoreTypes = {
  loading: false,
  error: null,
  link: '',
};

export default (state: ExportStoreTypes = initState, { type, payload }: Action): ExportStoreTypes => {
  switch (type) {
    case T.GET_PUB_MAV_PERFORMANCE_EXPORTS:
      return { ...state, loading: true, link: '', error: null };

    case T.GET_PUB_MAV_PERFORMANCE_EXPORTS_SUCCESS:
      return { ...state, loading: false, link: payload };

    case T.GET_PUB_MAV_PERFORMANCE_EXPORTS_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
