import { Action } from 'common/interfaces/root';
import moment from 'moment';
import { gainzRevenueActionTypes as TYPES } from '../actions/action-types';

const initialState: AffiliateGainzModule.GainzRevenueModule.FiltersState = {
  search: '',
  from: moment(),
  to: moment(),
  limit: 10,
  offset: 0,
};

export default (
  state: AffiliateGainzModule.GainzRevenueModule.FiltersState = initialState,
  { type, payload }: Action
): AffiliateGainzModule.GainzRevenueModule.FiltersState => {
  switch (type) {
    case TYPES.FILTERS.SET_SEARCH:
      return { ...state, search: payload };
    case TYPES.FILTERS.SET_FROM:
      return { ...state, from: payload };
    case TYPES.FILTERS.SET_TO:
      return { ...state, to: payload };
    case TYPES.FILTERS.SET_LIMIT:
      return { ...state, limit: payload };
    case TYPES.FILTERS.SET_OFFSET:
      return { ...state, offset: payload };

    default:
      return state;
  }
};
