import { Action } from '../../../../common/interfaces/root';
import { asinsConversionsBoostTypes as TYPES } from '../actions/action-types';

export const uploadInitState: AsinsConversionsBoostModule.UploadFileStore = {
  file: null,
  loading: false,
  result: {
    inserted: 0,
    duplicates: [],
    withErrors: [],
  },
  error: null,
};

export default (
  state: AsinsConversionsBoostModule.UploadFileStore = uploadInitState,
  { type, payload }: Action
): AsinsConversionsBoostModule.UploadFileStore => {
  switch (type) {
    case TYPES.UPLOAD_FILE.UPLOAD_FILE:
      return { ...state, loading: true, file: payload, error: null };
    case TYPES.UPLOAD_FILE.UPLOAD_FILE_SUCCESS:
      return { ...state, loading: false, result: payload, error: null };
    case TYPES.UPLOAD_FILE.UPLOAD_FILE_ERROR:
      return { result: {}, loading: false, error: payload };
    default:
      return state;
  }
};
