import { Box, Grid } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement } from 'react';
import { CardContainer } from 'modules/stats/components';
import { CardProps } from '../card-container/card-container';

const styles: Styles = {
  container: { marginTop: 3 },
};

export interface CardsProps {
  cards: CardProps[];
}

export function CardsContainer({ cards }: CardsProps): ReactElement {
  return (
    <Box sx={styles.container}>
      <Grid container spacing={4}>
        {cards.map(card => (
          <Grid key={cards.indexOf(card)} item xs={2}>
            <CardContainer key={cards.indexOf(card)} {...card} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
