import { Modal } from 'common/ui/modal';
import { ReactElement } from 'react';
import { TagsModalForm } from './tags-modal-form/tags-modal-form';

interface Props {
  open: boolean;
  onClose: () => void;
  handleRefreshTable: () => void;
}

export function TagsModal({ open, onClose, handleRefreshTable }: Props): ReactElement {
  return (
    <Modal open={open} title="Add tag" maxWidth="sm" onClose={onClose}>
      <TagsModalForm isModalOpen={open} handleModalClose={onClose} handleRefreshTable={handleRefreshTable} />
    </Modal>
  );
}
