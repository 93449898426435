import { asinsConversionsBoostTypes as TYPES } from './action-types';

const getConversionsBoost = (payload: AsinsConversionsBoostModule.GetConversionsBoostParams) => ({
  type: TYPES.CONVERSIONS_BOOST.GET_CONVERSIONS_BOOST,
  payload,
});

const getConversionsBoostSuccess = (payload: Array<AsinsConversionsBoostModule.ConversionBoost>) => ({
  type: TYPES.CONVERSIONS_BOOST.GET_CONVERSIONS_BOOST_SUCCESS,
  payload,
});

const getConversionsBoostError = (payload: Error) => ({
  type: TYPES.CONVERSIONS_BOOST.GET_CONVERSIONS_BOOST_ERROR,
  payload,
});

const changeConversionsBoostPage = (page: number) => ({
  type: TYPES.CONVERSIONS_BOOST.CHANGE_CONVERSIONS_BOOST_PAGE,
  payload: page,
});

const postConversionBoost = (payload: AsinsConversionsBoostModule.ConversionBoostForm, onSuccess: () => void) => ({
  type: TYPES.FORM.POST_CONVERSION_BOOST,
  payload,
  meta: { onSuccess },
});

const postConversionBoostSuccess = () => ({
  type: TYPES.FORM.POST_CONVERSION_BOOST_SUCCESS,
});

const postConversionBoostError = (payload: Error) => ({
  type: TYPES.FORM.POST_CONVERSION_BOOST_ERROR,
  payload,
});

const editConversionBoost = (payload: Partial<AsinsConversionsBoostModule.ConversionBoost>, onSuccess: () => void) => ({
  type: TYPES.FORM.EDIT_CONVERSION_BOOST,
  payload,
  meta: { onSuccess },
});

const editConversionBoostSuccess = () => ({
  type: TYPES.FORM.EDIT_CONVERSION_BOOST_SUCCESS,
});

const editConversionBoostError = (payload: Error) => ({
  type: TYPES.FORM.EDIT_CONVERSION_BOOST_ERROR,
  payload,
});

const deleteConversionBoost = (payload: number, onSuccess: () => void) => ({
  type: TYPES.FORM.DELETE_CONVERSION_BOOST,
  payload,
  meta: { onSuccess },
});

const deleteConversionBoostSuccess = () => ({
  type: TYPES.FORM.DELETE_CONVERSION_BOOST_SUCCESS,
});

const deleteConversionBoostError = (payload: Error) => ({
  type: TYPES.FORM.DELETE_CONVERSION_BOOST_ERROR,
  payload,
});

export const conversionsBoostActions = {
  getConversionsBoost,
  getConversionsBoostSuccess,
  getConversionsBoostError,
  changeConversionsBoostPage,
  postConversionBoost,
  postConversionBoostSuccess,
  postConversionBoostError,
  editConversionBoost,
  editConversionBoostSuccess,
  editConversionBoostError,
  deleteConversionBoost,
  deleteConversionBoostSuccess,
  deleteConversionBoostError,
};
