import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Styles } from 'common/types';
import { paper } from 'common/constants/colors';
import { ReactElement } from 'react';

const styles: Styles = {
  container: {
    borderRadius: '20px',
    backgroundColor: paper,
    padding: 2,
    height: '770px',
  },
  tableRow: { height: '68px' },
};

const formatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const headers = ['', 'Category', 'Revenue (GMV)', 'Ad Fees', 'Items Shipped'];

interface Props {
  categories: Overview.Performance.Performance[];
}

export function CategoryTable({ categories }: Props): ReactElement {
  return (
    <Box sx={styles.container}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <TableCell key={headers.indexOf(header)}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {categories?.map((row, index) => (
              <TableRow key={categories.indexOf(row)} sx={styles.tableRow}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.category}</TableCell>
                <TableCell>{formatter.format(Number(row.revenue))}</TableCell>
                <TableCell>{formatter.format(Number(row.ad_fees))}</TableCell>
                <TableCell>{row.items_shipped}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
