import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import { asinFinderApiService } from 'modules/asin-finder/services/asin-finder-api-service';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { asinFinderActions, asinFinderActionTypes } from '../actions';

function* getAsinsList({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(asinFinderApiService.getAsinsList, payload);
    const newVariations = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const value of payload.search) {
      newVariations.push({
        asin: value,
        variations: null,
      });
    }
    yield put(asinFinderActions.getAsinsListSuccess(data.urls, data.totalCount, newVariations));
  } catch (err) {
    yield put(asinFinderActions.getAsinsListError(err as Error));
  }
}

function* downloadAsinsCsv({ payload }: Action): SagaIterator {
  try {
    yield call(asinFinderApiService.downloadAsinsCsv, payload);
  } catch (err) {
    //
  }
}

function* generateReport({ payload }: Action) {
  try {
    const { data } = yield call(asinFinderApiService.generateReport, payload);
    yield put(asinFinderActions.generateReportSuccess(data.execution_id));
  } catch (err) {
    yield put(asinFinderActions.generateReportError(err as Error));
  }
}

function* openReportLink({ payload, meta }: Action) {
  try {
    const { data } = yield call(asinFinderApiService.openReportLink, payload);
    if (data.link.includes('https:')) {
      window.location.href = data.link;
      meta.onStopTimer();
      yield put(asinFinderActions.successReportLink());
    }
  } catch (err) {
    //
  }
}

function* getBrandParents() {
  try {
    const { data } = yield call(asinFinderApiService.getBrandParents);
    yield put(asinFinderActions.getBrandParentsSuccess(data));
  } catch (err) {
    yield put(asinFinderActions.getBrandParentsError(err as Error));
  }
}

function* getBrands() {
  try {
    const { data } = yield call(asinFinderApiService.getBrands);
    yield put(asinFinderActions.getBrandsSuccess(data));
  } catch (err) {
    yield put(asinFinderActions.getBrandsError(err as Error));
  }
}

export function* asinFinderSaga(): SagaIterator {
  yield all([
    takeEvery(asinFinderActionTypes.GET_ASINS_LIST, getAsinsList),
    takeEvery(asinFinderActionTypes.DOWNLOAD_ASINS_CSV, downloadAsinsCsv),
    takeEvery(asinFinderActionTypes.GENERATE_REPORT, generateReport),
    takeEvery(asinFinderActionTypes.GET_BRAND_PARENTS, getBrandParents),
    takeEvery(asinFinderActionTypes.GET_BRANDS, getBrands),
    takeLatest(asinFinderActionTypes.OPEN_REPORT_LINK, openReportLink),
  ]);
}
