import { Box, Button, CircularProgress } from '@mui/material';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import { contentOverviewActions } from 'modules/content-overview/store/actions';
import {
  selectRecommendationAsins,
  selectRecommendationsData,
  selectRecommendationsLoading,
} from 'modules/content-overview/store/selectors';
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { SearchInput } from 'common/ui/search-input';
import { IProduct } from 'common/ui/product/types';
import { Product } from './product';

const styles: Styles = {
  button: { maxWidth: 100, width: '100%', marginLeft: 1, ':first-child': { marginLeft: 0 } },
  buttons: { marginTop: 2, display: 'flex', justifyContent: 'flex-end' },
  spinnerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '200px',
  },
  showMore: {
    display: 'block',
    margin: '10px 0 0 20px',
    color: '#4288F0',
    fontSize: 10,
    cursor: 'pointer',
    width: 'fit-content',
  },
  searchForm: {
    p: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '5px',
  },
  searchIcon: {
    p: '10px',
  },
  searchInput: {
    ml: 1,
    flex: 1,
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  isNewRecommend: boolean;
  handleGetReplacements: () => void;
  productAsin: string;
}

export function RecommendationsModal({
  open,
  onClose,
  isNewRecommend,
  handleGetReplacements,
  productAsin,
}: Props): ReactElement {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState<string>('');

  const rows = useSelector(selectRecommendationsData);
  const asins = useSelector(selectRecommendationAsins);
  const loading = useSelector(selectRecommendationsLoading);
  const globalFilters = useSelector(selectGlobalFilters);

  const debouncedSearch = useDebouncedCallback((value: string) => {
    dispatch(
      contentOverviewActions.getProductRecommendations({
        publisherId: globalFilters.publisher_id,
        asins,
        search: value,
      })
    );
  }, 500);

  const handleChangeSearch = (search: string) => {
    setSearchValue(search);
    debouncedSearch(search);
  };

  const handleClickRow = (row: { product: IProduct; score: number }) => {
    if (isNewRecommend) {
      dispatch(
        contentOverviewActions.patchProductRecommendations(
          {
            id: globalFilters?.publisher_id,
            product_asin: productAsin,
            replacement_asin: row?.product?.asin,
            score: row?.score,
          },
          handleGetReplacements
        )
      );
    } else {
      dispatch(
        contentOverviewActions.postProductRecommendations(
          {
            id: globalFilters?.publisher_id,
            product_asin: productAsin,
            replacement_asin: row?.product?.asin,
            score: row?.score,
          },
          handleGetReplacements
        )
      );
    }
    onClose();
    setSearchValue('');
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="xs" title="Product Replacement Recommendation">
      <Box sx={{ marginBottom: '16px' }}>
        <SearchInput search={searchValue} handleSearchChange={handleChangeSearch} endAdornment primaryBg fullWidth />
      </Box>
      {!loading ? (
        rows?.map(row => (
          <Box onClick={() => handleClickRow(row)}>
            <Product key={row?.product?.asin} product={row?.product} />
          </Box>
        ))
      ) : (
        <Box sx={styles.spinnerWrapper}>
          <CircularProgress color="primary" />
        </Box>
      )}
      <Box sx={styles.buttons}>
        <Button color="error" sx={styles.button} variant="contained">
          Submit
        </Button>
        <Button color="info" variant="outlined" sx={styles.button} onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}
