import { MenuItem, TextField } from '@mui/material';
import { Styles } from 'common/types';
import { ChangeEvent, ReactElement } from 'react';
import { Click } from '../types';

const styles: Styles = {
  field: { width: '70%' },
};

const transactionClicks = [
  {
    title: 'All',
    value: Click.All,
  },
  {
    title: 'With clicks',
    value: Click.Yes,
  },
  {
    title: 'Without clicks',
    value: Click.No,
  },
];

interface Props {
  clicksEnabled: boolean;
  transactionClick: Click;
  onChange: (transactionClick: Click) => void;
}

export function TransactionClick({ clicksEnabled, transactionClick, onChange }: Props): ReactElement {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value as Click);
  };

  return (
    <TextField
      disabled={!clicksEnabled}
      select
      color="primary"
      sx={styles.field}
      onChange={handleChange}
      value={transactionClick}
      size="small"
      label="Clicks"
    >
      {transactionClicks.map(source => (
        <MenuItem key={source.value} value={source.value}>
          {source.title}
        </MenuItem>
      ))}
    </TextField>
  );
}
