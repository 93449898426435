import { MouseEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import toast from 'react-hot-toast';

import { URL_MANAGEMENT_NAV } from '../../../../settings/constants';
import {
  selectCrawlerSchedule,
  selectCrawlingLimitPageAmount,
  selectManagementFilters,
  selectPagesLimitPageAmount,
  selectSitemapScrapingSchedule,
  selectSitemapsLimitPageAmount,
  selectUrlsScrapingSchedule,
} from '../../../store/selectors';
import { selectGlobalFilters } from '../../../../../common/store/selectors';
import { UrlFilter } from '../../../services/content-overview.service.types';
import {
  contentOverviewActions,
  crawlingActions,
  managementFiltersActions,
  pagesActions,
  sitemapsActions,
} from '../../../store/actions';
import { userSettingsActions } from '../../../../user-settings/store/actions';
import { publishersSettingsActions } from '../../../../settings/store/actions';
import * as managementHelpers from '../../../helpers/management.helpers';

export const useManagementsHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams<{ nav: URL_MANAGEMENT_NAV; id: string }>();

  const { limit: pagesLimit, page: pagesPage, amount: pagesAmount } = useSelector(selectPagesLimitPageAmount);
  const { limit: sitemapsLimit, page: sitemapsPage } = useSelector(selectSitemapsLimitPageAmount);
  const { limit: crawlingLimit, page: crawlingPage } = useSelector(selectCrawlingLimitPageAmount);
  const selectCrawlerScheduleSelector = useSelector(selectCrawlerSchedule);
  const selectUrlsScrapingScheduleSelector = useSelector(selectUrlsScrapingSchedule);
  const selectSitemapScrapingScheduleSelector = useSelector(selectSitemapScrapingSchedule);
  const globalFilters = useSelector(selectGlobalFilters);
  const managementFilters = useSelector(selectManagementFilters);

  const [uploadsBtnWidth, setUploadsBtnWidth] = useState<number>(0);
  const [uploadsAnchor, setUploadsAnchor] = useState<HTMLButtonElement | null>(null);
  const [urlManagementAnchor, setUrlManagementAnchor] = useState<HTMLButtonElement | null>(null);
  const [isAddSourceModalOpen, setIsAddSourceModalOpen] = useState<boolean>(false);
  const [withAsins, setWithAsins] = useState<number>(1);
  const [urlsFilter, setUrlsFilter] = useState<number>(UrlFilter.ALL);
  const [addedFounded, setAddedFounded] = useState<number>(1);
  const [isClickFilter, setisClickFilter] = useState<boolean>(false);
  const [isConfirmDeleteBatchedModalVisible, setIsConfirmDeleteBatchedModalVisible] = useState(false);

  const debouncedPages = useDebouncedCallback(() => {
    dispatch(
      pagesActions.get({
        limit: pagesLimit,
        offset: pagesLimit * pagesPage,
        publisherId: globalFilters.publisher_id,
        search: managementFilters?.search,
        urls_filter: urlsFilter,
      })
    );
  }, 300);

  const debouncedSitemaps = useDebouncedCallback(() => {
    dispatch(
      sitemapsActions.get({
        limit: sitemapsLimit,
        offset: sitemapsLimit * sitemapsPage,
        publisherId: globalFilters.publisher_id,
        search: managementFilters?.search,
      })
    );
  }, 300);

  const debouncedCrawling = useDebouncedCallback(() => {
    dispatch(
      crawlingActions.get({
        limit: crawlingLimit,
        offset: crawlingLimit * crawlingPage,
        publisherId: globalFilters.publisher_id,
        search: managementFilters?.search,
        with_asins: withAsins,
        added_founded: addedFounded,
      })
    );
  }, 300);

  const debouncedCrawlingSearch = useDebouncedCallback(() => {
    dispatch(
      crawlingActions.get({
        limit: crawlingLimit,
        offset: 0,
        publisherId: globalFilters.publisher_id,
        search: managementFilters?.search,
        with_asins: withAsins,
        added_founded: addedFounded,
      })
    );
    if (!managementFilters?.search.length) dispatch(crawlingActions.changePage(1));
  }, 300);

  const handleUploadsPopoverClose = () => {
    setUploadsAnchor(null);
  };

  const handleUploadsPopoverOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setUploadsAnchor(e.currentTarget);
  };

  const handleUrlManagementPopoverClose = () => {
    setUrlManagementAnchor(null);
  };

  const handleUrlManagementPopoverOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setUrlManagementAnchor(e.currentTarget);
  };

  const handleAddSourceModalOpen = () => {
    setIsAddSourceModalOpen(true);
  };

  const handleAddSourceModalClose = () => {
    setIsAddSourceModalOpen(false);
  };

  const toStartChange = () => {
    dispatch(crawlingActions.canLoadMore(true));
    dispatch(crawlingActions.getReset());
    dispatch(crawlingActions.changePage(0));
  };

  const handleSearchChange = (search: string) => {
    if (params.nav === URL_MANAGEMENT_NAV.sitemaps) {
      dispatch(sitemapsActions.changePage(0));
    } else if (params.nav === URL_MANAGEMENT_NAV.urls) {
      dispatch(pagesActions.changePage(0));
    }

    toStartChange();
    dispatch(managementFiltersActions.changeSearch(search));
  };

  const handleWithAsinsChange = (value: number) => {
    setisClickFilter(true);
    toStartChange();
    setWithAsins(value);
  };

  const handleAddedFoundedChange = (value: number) => {
    setisClickFilter(true);
    toStartChange();
    setAddedFounded(value);
  };

  const handleUpdatePage = (message: string) => {
    toast.success(message);
    setIsConfirmDeleteBatchedModalVisible(false);
    dispatch(
      pagesActions.get({
        limit: pagesLimit,
        offset: pagesLimit * pagesPage,
        publisherId: globalFilters.publisher_id,
        search: managementFilters?.search,
        urls_filter: urlsFilter,
      })
    );
  };

  useEffect(() => {
    if (params.nav !== URL_MANAGEMENT_NAV.urls) return;
    debouncedPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesLimit, pagesPage, globalFilters.publisher_id, managementFilters?.search, urlsFilter, params.nav]);

  useEffect(() => {
    if (params.nav !== URL_MANAGEMENT_NAV.sitemaps) return;
    debouncedSitemaps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitemapsLimit, sitemapsPage, globalFilters.publisher_id, managementFilters?.search, params.nav]);

  useEffect(() => {
    if (params.nav !== URL_MANAGEMENT_NAV.crawler) return;
    debouncedCrawling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crawlingLimit, globalFilters.publisher_id, params.nav]);

  useEffect(() => {
    if (params.nav !== URL_MANAGEMENT_NAV.crawler) return;
    if (isClickFilter && !managementFilters?.search.length) {
      setisClickFilter(false);
      return;
    }
    debouncedCrawlingSearch();
    setisClickFilter(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managementFilters?.search, withAsins, addedFounded, params.nav]);

  useEffect(() => {
    dispatch(userSettingsActions.getUserSettingsInfo());
    dispatch(
      pagesActions.get({
        limit: pagesLimit,
        offset: pagesLimit * pagesPage,
        publisherId: globalFilters.publisher_id,
        search: managementFilters?.search,
      })
    );
    dispatch(
      sitemapsActions.get({
        limit: sitemapsLimit,
        offset: sitemapsLimit * sitemapsPage,
        publisherId: globalFilters.publisher_id,
        search: managementFilters?.search,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(publishersSettingsActions.getMainPublisherInfo(String(globalFilters.publisher_id)));
    dispatch(contentOverviewActions.getCrawlingStatus({ publisherId: globalFilters.publisher_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilters.publisher_id]);

  const handleRescrapTab = () => {
    const tabDispatchValue = managementHelpers.getTabDispatchValue(params.nav);

    switch (params.nav) {
      case URL_MANAGEMENT_NAV.sitemaps:
        dispatch(
          contentOverviewActions.postRescrapTab(globalFilters.publisher_id, tabDispatchValue, () => debouncedSitemaps())
        );
        break;
      case URL_MANAGEMENT_NAV.urls:
        dispatch(
          contentOverviewActions.postRescrapTab(globalFilters.publisher_id, tabDispatchValue, () => debouncedPages())
        );
        break;
      case URL_MANAGEMENT_NAV.crawler:
        dispatch(
          contentOverviewActions.postRescrapTab(globalFilters.publisher_id, tabDispatchValue, () => {
            toast.success('Urls transfer started successfully');
          })
        );
        break;
      default:
        break;
    }
  };

  const handleChangePath = (e: SyntheticEvent<Element, Event>, value: URL_MANAGEMENT_NAV) => {
    navigate(`/content-overview/management/${value}`);
  };

  useEffect(() => {
    const foundCurrentStep = Object.values(URL_MANAGEMENT_NAV).includes(params.nav as URL_MANAGEMENT_NAV);

    handleSearchChange('');

    if (foundCurrentStep) return;

    navigate(`/content-overview/management/${URL_MANAGEMENT_NAV.sitemaps}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.nav, navigate]);

  const dispatchScheduleByTab = useCallback(
    (schedule: string) => {
      // eslint-disable-next-line default-case
      switch (params.nav) {
        case URL_MANAGEMENT_NAV.crawler:
          dispatch(contentOverviewActions.setCrawlerSchedule(schedule));
          break;
        case URL_MANAGEMENT_NAV.urls:
          dispatch(contentOverviewActions.setUrlsScrapingSchedule(schedule));
          break;
        case URL_MANAGEMENT_NAV.sitemaps:
          dispatch(contentOverviewActions.setSitemapScrapingSchedule(schedule));
          break;
      }
    },
    [dispatch, params.nav]
  );

  useEffect(() => {
    if (params.nav) {
      const tab = managementHelpers.getTabDispatchValue(params.nav);
      const { publisher_id } = globalFilters;
      if (tab) {
        dispatch(
          contentOverviewActions.getScheduleByTab(
            {
              tab,
              publisher_id,
            },
            dispatchScheduleByTab
          )
        );
      }
    }
  }, [params.nav, dispatch, dispatchScheduleByTab, globalFilters]);

  const handleBatchDelete = () => {
    dispatch(
      contentOverviewActions.deleteFilteredUrls(
        {
          publisher_id: String(managementFilters?.publisher_id),
          search: managementFilters?.search,
          urlsFilter,
        },
        () => handleUpdatePage('Urls was deleted')
      )
    );
  };

  const onUpdateSchedule = (value: string) => {
    const tabDispatchValue = managementHelpers.getTabDispatchValue(params.nav);
    if (!tabDispatchValue) return;

    const updateSchedule = (tab: string, onSucessTab: string) =>
      dispatch(
        contentOverviewActions.patchUpdateSchedule(globalFilters.publisher_id, tab, value, (schedule: string) => {
          dispatchScheduleByTab(schedule);
          toast.success(`${onSucessTab} schedule updated successfully`);
        })
      );

    const capitalizedTab = tabDispatchValue.charAt(0).toUpperCase() + tabDispatchValue.slice(1);
    updateSchedule(tabDispatchValue, capitalizedTab);
  };

  const scheduleTabValue = useMemo(() => {
    switch (params.nav) {
      case URL_MANAGEMENT_NAV.urls:
        return selectUrlsScrapingScheduleSelector;
      case URL_MANAGEMENT_NAV.sitemaps:
        return selectSitemapScrapingScheduleSelector;
      case URL_MANAGEMENT_NAV.crawler:
        return selectCrawlerScheduleSelector;
      default:
        return '';
    }
  }, [
    params.nav,
    selectCrawlerScheduleSelector,
    selectUrlsScrapingScheduleSelector,
    selectSitemapScrapingScheduleSelector,
  ]);
  return {
    params,
    uploadsBtnWidth,
    scheduleTabValue,
    uploadsAnchor,
    urlManagementAnchor,
    isAddSourceModalOpen,
    isConfirmDeleteBatchedModalVisible,
    pagesAmount,
    debouncedSitemaps,
    withAsins,
    addedFounded,
    managementFilters,
    urlsFilter,
    setIsConfirmDeleteBatchedModalVisible,
    handleSearchChange,
    handleUpdatePage,
    setUrlsFilter,
    handleUploadsPopoverOpen,
    handleUrlManagementPopoverClose,
    handleUrlManagementPopoverOpen,
    handleAddSourceModalOpen,
    handleAddSourceModalClose,
    handleAddedFoundedChange,
    handleWithAsinsChange,
    handleRescrapTab,
    handleUploadsPopoverClose,
    onUpdateSchedule,
    handleBatchDelete,
    handleChangePath,
    setUploadsBtnWidth,
  };
};
