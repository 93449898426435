import { asinsConversionsBoostTypes as TYPES } from './action-types';

const fileUpload = (file: File, onSuccess: () => void) => ({
  type: TYPES.UPLOAD_FILE.UPLOAD_FILE,
  payload: file,
  meta: { onSuccess },
});

const uploadFileSuccess = (payload: AsinsConversionsBoostModule.UploadFileResults) => ({
  type: TYPES.UPLOAD_FILE.UPLOAD_FILE_SUCCESS,
  payload,
});

const uploadFileError = (payload: Error) => ({
  type: TYPES.UPLOAD_FILE.UPLOAD_FILE_ERROR,
  payload,
});

export const uploadActions = {
  fileUpload,
  uploadFileSuccess,
  uploadFileError,
};
