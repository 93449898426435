import { Box, Typography } from '@mui/material';
import { Portal } from '../../common/ui/portal';
import { Styles } from '../../common/types';
import HeaderImg from '../../common/svg/header-img.png';
import { UploadReportForm, RecoverReportsForm } from './components';

const styles: Styles = {
  headTitle: {
    color: '#ffffff',
    fontWeight: 600,
  },
  head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    background: `url(${HeaderImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#4288F0',
    padding: '28px',
    boxSizing: 'border-box',
    color: '#ffffff',
    fontWeight: 600,
  },

  main: {
    display: 'flex',
    alignItems: 'stretch',
    width: '100%',
    height: '100%',
    gap: '30px',
  },
  sectionTitle: {
    fontWeight: 400,
    fontSize: '18px',
  },
  section: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: 'calc(100%/3)',
    height: '500px',
  },
  sectionMain: {
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: '15px',
    borderRadius: '10px',
    backgroundColor: 'white',
    padding: '20px',
    margin: '20px 0',
    zIndex: 2,
  },
};

export function AmazonAffiliateReports() {
  return (
    <Box sx={{ backgroundColor: '#F4F9FC' }}>
      <Portal id="header-page">
        <Box sx={styles.head}>
          <Typography sx={styles.headTitle}>Amazon Affiliate Reports</Typography>
        </Box>
      </Portal>

      <Box sx={styles.main}>
        <Box sx={styles.section}>
          <Typography sx={styles.sectionTitle} variant="h3">
            Upload report
          </Typography>
          <Box sx={styles.sectionMain}>
            <UploadReportForm />
          </Box>
        </Box>

        <Box sx={styles.section}>
          <Typography sx={styles.sectionTitle} variant="h3">
            Recover reports
          </Typography>
          <Box sx={styles.sectionMain}>
            <RecoverReportsForm />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
