import { ReactElement, useEffect, useState } from 'react';
import { TableRow, TableCell, InputBase, Typography, Box } from '@mui/material';
import { Download as DownloadIcon, Edit as EditIcon } from '@mui/icons-material';
import { Styles } from 'common/types';
import { useDispatch } from 'react-redux';
import { publishersTagsActions } from 'modules/publishers-affiliate-tags/store/actions/publisher-tags-action';

interface PublisherTagsRowProps {
  publisherTags: PublishersAffiliateTagsModule.PublisherTags;
  onTagsImport: (publisher: PublishersAffiliateTagsModule.ExtendedPublisherTags) => void;
  handleRefreshTable: () => void;
}

const styles: Styles = {
  container: {
    width: '10%',
  },
  input: {
    width: '100%',
    height: '30px',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '30px',
  },
  icon: {
    cursor: 'pointer',
    marginLeft: 1,
  },
};

export function PublisherTagsRow({
  publisherTags,
  onTagsImport,
  handleRefreshTable,
}: PublisherTagsRowProps): ReactElement {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [tag, setTag] = useState(publisherTags.tag ? publisherTags.tag : '');

  const allUsed = parseInt(publisherTags.paid_used as string, 10) + parseInt(publisherTags.organic_used as string, 10);
  const usedPercent = ((allUsed * 100) / parseInt(publisherTags.tags_count as string, 10)).toFixed(2);
  const freePercent = (
    (parseInt(publisherTags.free_tags as string, 10) * 100) /
    parseInt(publisherTags.tags_count as string, 10)
  ).toFixed(2);

  const handleEditClick = () => {
    setIsEditing(true);
    setTag(publisherTags.tag || '');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTag(e.target.value);
  };

  const handleCellClick = () => {
    if (!isEditing) {
      setIsEditing(true);
    }
  };

  const handleTagUpdate = (newTag: string) => {
    const trimmedTag = newTag.trim();
    const currentTag = publisherTags.tag || '';

    if (trimmedTag !== currentTag && trimmedTag !== '') {
      dispatch(publishersTagsActions.editPublishersTags({ tag: trimmedTag }, publisherTags.id, handleRefreshTable));
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleTagUpdate(tag);
    }
  };

  const handleBlur = () => {
    handleTagUpdate(tag);
  };

  useEffect(() => {
    setTag(publisherTags.tag || '');
  }, [publisherTags]);

  return (
    <TableRow>
      <TableCell>{publisherTags.publisher_name}</TableCell>
      <TableCell>{publisherTags.publisher_slug}</TableCell>
      <TableCell onClick={handleCellClick} sx={styles.container}>
        {isEditing ? (
          <InputBase
            value={tag}
            onChange={handleInputChange}
            fullWidth
            autoFocus
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            sx={styles.input}
          />
        ) : (
          <Box sx={styles.textContainer} onClick={handleEditClick}>
            <Typography>{publisherTags.tag}</Typography>
            <EditIcon sx={styles.icon} fontSize="small" color="action" />
          </Box>
        )}
      </TableCell>
      <TableCell>{publisherTags.tags_count}</TableCell>
      <TableCell>{publisherTags.paid_used}</TableCell>
      <TableCell>{publisherTags.organic_used}</TableCell>
      <TableCell>{publisherTags.free_tags}</TableCell>
      <TableCell>{`${usedPercent}%`}</TableCell>
      <TableCell>{`${freePercent}%`}</TableCell>
      <TableCell>
        <DownloadIcon
          sx={{ cursor: 'pointer' }}
          fontSize="small"
          color="action"
          onClick={() => onTagsImport({ ...publisherTags, free_percent: freePercent, used_percent: usedPercent })}
        />
      </TableCell>
    </TableRow>
  );
}
