import { ReactElement, useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Portal } from 'common/ui/portal';
import { Styles } from 'common/types';
import HeaderImg from 'common/svg/header-img.png';
import { useSelector } from 'react-redux';
import { selectGlobalFilters } from 'common/store/selectors';
import toast from 'react-hot-toast';
import { pubxReportsService } from './service/pubx-reports-service';

const styles: Styles = {
  headTitle: {
    color: '#ffffff',
    fontWeight: 600,
  },
  head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    background: `url(${HeaderImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#4288F0',
    padding: '28px',
    boxSizing: 'border-box',
    color: '#ffffff',
    fontWeight: 600,
  },
  btnContainer: { display: 'flex', alignItems: 'center', gap: 2 },
};

export function PubXReports(): ReactElement {
  const [loading, setLoading] = useState<boolean>(false);

  const { publisher_id } = useSelector(selectGlobalFilters);

  const handleGetReportStatus = async (job_id: number, count: number) => {
    if (count <= 10) {
      try {
        await pubxReportsService.getReportStatus({ job_id, publisher_id }).then(res => {
          if (res.data.status === 'success') {
            window.open(res.data.link, 'blank');
            setLoading(false);
          } else {
            // eslint-disable-next-line no-return-assign
            setTimeout(() => handleGetReportStatus(job_id, (count += 1)), 1000);
          }
        });
      } catch (error) {
        toast.error('Download failed');
        setLoading(false);
      }
    } else {
      toast.error('Request timed out.  Please try again later');
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    setLoading(true);

    try {
      await pubxReportsService.getReport({ publisher_id }).then(async res => {
        handleGetReportStatus(res.data.job_id, 0);
      });
    } catch (error) {
      toast.error('Download failed');
      setLoading(false);
    }
  };

  return (
    <>
      <Portal id="header-page">
        <Box sx={styles.head}>
          <Typography sx={styles.headTitle}>PubX Reports</Typography>
        </Box>
      </Portal>
      <Box sx={styles.btnContainer}>
        <Button variant="contained" onClick={handleDownload}>
          Download Report
        </Button>
        {loading && <CircularProgress size={32} />}
      </Box>
    </>
  );
}
