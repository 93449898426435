import { URL_MANAGEMENT_NAV } from '../../settings/constants';

export const getTabDispatchValue = (tab: string): string | null => {
  switch (tab) {
    case URL_MANAGEMENT_NAV.sitemaps:
      return 'sitemap';
    case URL_MANAGEMENT_NAV.urls:
      return 'urls';
    case URL_MANAGEMENT_NAV.crawler:
      return 'crawler';
    default:
      return null;
  }
};

export const getRescrapValue = (tab: string): string => {
  switch (tab) {
    case URL_MANAGEMENT_NAV.sitemaps:
      return 'Rescrape Sitemap';
    case URL_MANAGEMENT_NAV.urls:
      return 'Rescrape URLs';
    case URL_MANAGEMENT_NAV.crawler:
      return 'Add All';
    default:
      return '';
  }
};
