import { Action } from 'common/interfaces/root';
import moment from 'moment';
import { datePickerComparatorActionTypes as TYPES } from '../actions/date-picker-comparator';

const initialState: CommonModule.DatePickerComparatorState = {
  isCompare: false,
  dateRanges: {
    range1: {
      startDate: moment().toDate(),
      endDate: moment().toDate(),
    },
    range2: {
      startDate: moment().subtract(1, 'days').startOf('day').toDate(),
      endDate: moment().subtract(1, 'days').endOf('day').toDate(),
    },
  },
};

export default (
  state: CommonModule.DatePickerComparatorState = initialState,
  { type, payload }: Action
): CommonModule.DatePickerComparatorState => {
  switch (type) {
    case TYPES.CHANGE_IS_COMPARE:
      return { ...state, isCompare: payload };
    case TYPES.CHANGE_DATE_RANGES:
      return { ...state, dateRanges: payload };
    default:
      return state;
  }
};
