import { ChangeEvent, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, TextField } from '@mui/material';
import { selectPagesPerformanceFilters } from '../../../../store/selectors';
import { pagesPerformanceFiltersActions } from '../../../../store/actions';
import { Styles } from '../../../../../../common/types';
import { ContentType } from './content-type-filter.types';

const exportLimitOptions = [
  { title: 'All', value: ContentType.ALL },
  { title: 'Single product', value: ContentType.SINGLE_REVIEW_PRODUCT },
  { title: 'Brand review', value: ContentType.BRAND_REVIEW },
  { title: 'Review guide', value: ContentType.REVIEW_GUIDE },
];

const styles: Styles = {
  container: { width: '70%' },
};

export function ContentTypeFilter(): ReactElement {
  const dispatch = useDispatch();

  const filters = useSelector(selectPagesPerformanceFilters);

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(pagesPerformanceFiltersActions.changeContentType(e.target.value as ContentType));
  };

  return (
    <TextField
      select
      color="primary"
      sx={styles.container}
      onChange={handleStatusChange}
      value={filters.article_type}
      size="small"
      label="Content type"
    >
      {exportLimitOptions.map(source => (
        <MenuItem key={source.value} value={source.value}>
          {source.title}
        </MenuItem>
      ))}
    </TextField>
  );
}
