import { useWindowSize } from 'common/hooks';
import { ReactElement, useState } from 'react';
import { CustomTableContainerProps, TableContainerWithLoading } from './table-container-with-loading';

export function TableContainerWithLoadingGrow({ children, ...props }: CustomTableContainerProps): ReactElement {
  useWindowSize();

  const [tableHeight, setTableHeight] = useState<number>(0);

  return (
    <div style={{ flexGrow: 1 }} ref={ref => setTableHeight(ref?.clientHeight)}>
      <TableContainerWithLoading {...props} maxHeight={tableHeight}>
        {children}
      </TableContainerWithLoading>
    </div>
  );
}
