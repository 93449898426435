const MODULE = '[global:filters]';

export const getFiltersActionTypes = (module: string) => ({
  CHANGE_RANGE: `${module} CHANGE_RANGE`,
  CHANGE_PUBLISHER: `${module} CHANGE_PUBLISHER`,
  CHANGE_SEARCH: `${module} CHANGE_SEARCH`,
  CHANGE_IS_PAID: `${module} CHANGE_IS_PAID`,
  CHANGE_USED: `${module} CHANGE_USED`,
  CHANGE_AFFILIATE_NETWORK_TYPE: `${module} CHANGE_AFFILIATE_NETWORK_TYPE`,
  CHANGE_DATE_BY: `${module} CHANGE_DATE_BY`,
  CHANGE_TRANSACTION_CLICK: `${module} CHANGE_TRANSACTION_CLICK`,
  CHANGE_IN_STOCK: `${module} CHANGE_IN_STOCK`,
  CHANGE_OOS_RANGE: `${module} CHANGE_OOS_RANGE`,
  CHANGE_OOS_STATUS: `${module} CHANGE_OOS_STATUS`,
  CHANGE_EXPORT_LIMIT: `${module} CHANGE_EXPORT_LIMIT`,
  CHANGE_PUBLISHER_GROUP: `${module} CHANGE_PUBLISHER_GROUP`,
  CHANGE_CONTENT_TYPE: `${module} CHANGE_CONTENT_TYPE`,
  CHANGE_SEARCH_TYPE: `${module} CHANGE_SEARCH_TYPE`,
  CHANGE_SATURATION_OPERATOR: `${module} CHANGE_SATURATION_OPERATOR`,
  CHANGE_SATURATION_VALUE: `${module} CHANGE_SATURATION_VALUE`,
  RESET_FILTERS: `${module} RESET_FILTERS`,
});

export const filtersActionTypes = getFiltersActionTypes(MODULE);
