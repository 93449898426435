import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ReactElement, useEffect } from 'react';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { Styles } from 'common/types';
import { useDispatch, useSelector } from 'react-redux';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { selectGlobalFilters } from 'common/store/selectors';
import { selectPublisherIntegrationsInfo, selectPublisherIntegrationsLoading } from 'modules/settings/store/selectors';
import { IntegrationItem } from './integration-item';

const getStyles = (): Styles => ({
  table: { marginTop: 0 },
  cell: { color: '#21425480', fontSize: '10px' },
  bttnWrapper: { display: 'flex', justifyContent: 'flex-start', mb: 0.5 },
});

export function IntegrationsSettings(): ReactElement {
  const dispatch = useDispatch();
  const styles = getStyles();

  const filters = useSelector(selectGlobalFilters);
  const loading = useSelector(selectPublisherIntegrationsLoading);
  const rows = useSelector(selectPublisherIntegrationsInfo);

  const handleCreateIntegrate = () => {
    dispatch(publishersSettingsActions.getIntegrationLink(filters?.publisher_id, 'google'));
  };

  const handleRefreshList = () => {
    dispatch(publishersSettingsActions.getIntegrationsList(filters?.publisher_id));
  };

  useEffect(() => {
    dispatch(publishersSettingsActions.getIntegrationsList(filters?.publisher_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={styles.bttnWrapper}>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={() => handleCreateIntegrate()}>
          Google
        </Button>
      </Box>
      <TableContainerWithLoading sx={styles.table} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.cell}>Type</TableCell>
              <TableCell sx={styles.cell}>Created at</TableCell>
              <TableCell sx={styles.cell}>Name</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map(row => (
              <IntegrationItem row={row} handleRefreshList={handleRefreshList} />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
    </>
  );
}
