import { affiliateRateCardActionTypes as TYPES } from './types';

const getRecommendedAsins = (payload: AffiliateRateCardModule.GetAsinsParams) => ({
  type: TYPES.ASINS.GET_RECOMMENDED_ASINS,
  payload,
});

const getRecommendedAsinsSuccess = (payload: AffiliateRateCardModule.AffiliateRateCards) => ({
  type: TYPES.ASINS.GET_RECOMMENDED_ASINS_SUCCESS,
  payload,
});

const getRecommendedAsinsError = (payload: Error) => ({
  type: TYPES.ASINS.GET_RECOMMENDED_ASINS_ERROR,
  payload,
});

const getSRDefaultRate = (payload: { publisher_id: number }) => ({
  type: TYPES.RATES.DEFAULT.GET_SR_DEFAULT,
  payload,
});

const getSRDefaultRateSuccess = (payload: string) => ({
  type: TYPES.RATES.DEFAULT.GET_SR_DEFAULT_SUCCESS,
  payload,
});

const getSRDefaultRateError = (payload: Error) => ({
  type: TYPES.RATES.DEFAULT.GET_SR_DEFAULT_ERROR,
  payload,
});

const getABDefaultRate = (payload: { publisher_id: number }) => ({
  type: TYPES.RATES.DEFAULT.GET_AB_DEFAULT,
  payload,
});

const getABDefaultRateSuccess = (payload: string) => ({
  type: TYPES.RATES.DEFAULT.GET_AB_DEFAULT_SUCCESS,
  payload,
});

const getABDefaultRateError = (payload: Error) => ({
  type: TYPES.RATES.DEFAULT.GET_AB_DEFAULT_ERROR,
  payload,
});

const setOpen = (open: boolean) => ({
  type: TYPES.FILTERS.SET_OPEN,
  payload: open,
});

const setSearch = (search: string) => ({
  type: TYPES.FILTERS.SET_SEARCH,
  payload: search,
});

const setAll = (all: boolean) => ({
  type: TYPES.FILTERS.SET_ALL,
  payload: all,
});

const setSelected = (selected: AffiliateRateCardModule.Asin[]) => ({
  type: TYPES.FILTERS.SET_SELECTED,
  payload: selected,
});

const setRate = (rate: string) => ({
  type: TYPES.FILTERS.SET_RATE,
  payload: rate,
});

const setLimit = (limit: number) => ({
  type: TYPES.FILTERS.SET_LIMIT,
  payload: limit,
});

const setOffset = (offset: number) => ({
  type: TYPES.FILTERS.SET_OFFSET,
  payload: offset,
});

const setFilterError = (error: string) => ({
  type: TYPES.FILTERS.SET_FILTER_ERROR,
  payload: error,
});

const reset = () => ({
  type: TYPES.FILTERS.RESET,
});

const setSRDefaultRate = (payload: string) => ({
  type: TYPES.RATES.DEFAULT.SET_SR_DEFAULT,
  payload,
});

const setABDefaultRate = (payload: string) => ({
  type: TYPES.RATES.DEFAULT.SET_AB_DEFAULT,
  payload,
});

const patchSRDefaultRate = (payload: AffiliateRateCardModule.PatchSRDefaultRateParams) => ({
  type: TYPES.RATES.DEFAULT.PATCH_SR_DEFAULT,
  payload,
});

const patchSRDefaultRateSuccess = (payload: string) => ({
  type: TYPES.RATES.DEFAULT.PATCH_SR_DEFAULT_SUCCESS,
  payload,
});

const patchSRDefaultRateError = (payload: Error) => ({
  type: TYPES.RATES.DEFAULT.PATCH_SR_DEFAULT_ERROR,
  payload,
});

const patchABDefaultRate = (payload: AffiliateRateCardModule.PatchABDefaultRateParams) => ({
  type: TYPES.RATES.DEFAULT.PATCH_AB_DEFAULT,
  payload,
});

const patchABDefaultRateSuccess = (payload: string) => ({
  type: TYPES.RATES.DEFAULT.PATCH_AB_DEFAULT_SUCCESS,
  payload,
});

const patchABDefaultRateError = (payload: Error) => ({
  type: TYPES.RATES.DEFAULT.PATCH_AB_DEFAULT_ERROR,
  payload,
});

const setCustomRate = (payload: AffiliateRateCardModule.CustomRate) => ({
  type: TYPES.RATES.CUSTOM.SET_CUSTOM_RATE,
  payload,
});

const patchCustomRate = (payload: AffiliateRateCardModule.CustomRate) => ({
  type: TYPES.RATES.CUSTOM.PATCH_CUSTOM_RATE,
  payload,
});

const patchCustomRateSuccess = (payload: string) => ({
  type: TYPES.RATES.CUSTOM.PATCH_CUSTOM_RATE_SUCCESS,
  payload,
});

const patchCustomRateError = (payload: Error) => ({
  type: TYPES.RATES.CUSTOM.PATCH_CUSTOM_RATE_ERROR,
  payload,
});

export const affiliateRateCardsActions = {
  asins: {
    getRecommendedAsins,
    getRecommendedAsinsSuccess,
    getRecommendedAsinsError,
  },
  rates: {
    defualt: {
      getSRDefaultRate,
      getSRDefaultRateSuccess,
      getSRDefaultRateError,
      getABDefaultRate,
      getABDefaultRateSuccess,
      getABDefaultRateError,
      setSRDefaultRate,
      setABDefaultRate,
      patchSRDefaultRate,
      patchSRDefaultRateSuccess,
      patchSRDefaultRateError,
      patchABDefaultRate,
      patchABDefaultRateSuccess,
      patchABDefaultRateError,
    },
    custom: {
      setCustomRate,
      patchCustomRate,
      patchCustomRateSuccess,
      patchCustomRateError,
    },
  },
  filters: {
    setOpen,
    setSearch,
    setAll,
    setSelected,
    setRate,
    setLimit,
    setOffset,
    setFilterError,
    reset,
  },
};
