import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import { urlsApi } from 'modules/urls/services/urls.service';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { urlsActions, tagsActionTypes as T } from '../actions';

function* getUrlsSaga({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(urlsApi.getUrls, payload);

    yield put(urlsActions.getUrlsSuccess(data.urls, data.urls_count));
  } catch (err) {
    yield put(urlsActions.getUrlsError(err as Error));
  }
}

export function* urlsSaga(): SagaIterator {
  yield all([takeLatest(T.GET_URLS, getUrlsSaga)]);
}
