import { SagaIterator } from '@redux-saga/types';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import { Action } from 'common/interfaces/root';
import { selectGlobalFilters } from 'common/store/selectors';
import { contentOverviewApi } from 'modules/content-overview/services/content-overview.service';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  contentOverviewActions,
  contentOverviewActionTypes,
  crawlingActions,
  crawlingActionTypes,
  pagesActions,
  pagesActionTypes,
  pagesPerformanceActions,
  pagesPerformanceActionTypes,
  productReplacementsActions,
  productReplacementsActionTypes,
  replacementRecomendationsActions,
  replacementRecomendationsActionTypes,
  sitemapsActions,
  sitemapsActionsTypes,
} from '../actions';
import { selectSitemapsLimitPageAmount, selectPagesLimitPageAmount } from '../selectors';
import { IProduct } from '../../../../common/ui/product/types';

const getAsinOrderPerPage = (
  asinsPerPage: Record<string, number> | ContentOverviewModule.PagePerformanceAsinsOrder,
  targetAsin: string
) => {
  const asins = Object.keys(asinsPerPage);

  const match = asins.some(asin => asin === targetAsin);
  const asinData = asinsPerPage[targetAsin];

  if (match && (typeof asinData === 'number' || typeof asinData === 'string')) {
    return Number(asinData);
  }

  if (match && typeof asinData === 'object') {
    return Number(asinData.order);
  }

  return null;
};

function* getPages({ payload }: Action) {
  try {
    const { data } = yield call(contentOverviewApi.getPages, payload);
    yield put(pagesActions.getSuccess(data.urls, data.urls_count));
  } catch (err) {
    yield put(pagesActions.getError(err as Error));
  }
}

function* getPerformancePages({ payload }: Action) {
  try {
    const { data } = yield call(contentOverviewApi.getPagesPerformance, payload);
    data.articles_info.articles = data.articles_info.articles.map((article: ContentOverviewModule.PagePerformance) => {
      if (article.asins_page_order && Object.keys(article.asins_page_order).length) {
        const asinsPerPage = article.asins_page_order;
        article.products.entries = article.products.entries.map((product: IProduct) => {
          product.pageOrder = getAsinOrderPerPage(asinsPerPage, product.asin);
          return product;
        });
      }
      return article;
    });

    yield put(pagesPerformanceActions.getSuccess(data.articles_info.articles, data.articles_info.articles_count));
  } catch (err) {
    yield put(pagesPerformanceActions.getError(err as Error));
  }
}

function* updatePerformancePages({ payload }: Action) {
  yield pagesPerformanceActions.getSuccess(payload.articles, payload.articles_count);
}

function* deleteUrl({ payload, meta }: Action) {
  try {
    yield call(contentOverviewApi.deleteUrl, payload);
    yield put(contentOverviewActions.deleteUrlSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(contentOverviewActions.deleteUrlError(err as Error));
  }
}

function* deleteFilteredUrls({ payload, meta }: Action) {
  try {
    yield call(contentOverviewApi.deleteFilteredUrl, payload);
    yield put(contentOverviewActions.deleteUrlSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(contentOverviewActions.deleteUrlError(err as Error));
  }
}

function* getProductReplacements({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(contentOverviewApi.getReplacements, payload);
    data.urls = data.urls.map((url: ContentOverviewModule.PageWithProducts) => {
      if (url.asins_page_order && Object.keys(url.asins_page_order).length) {
        const asinsPerPage = url.asins_page_order;
        url.products.entries = url.products.entries.map((product: IProduct) => {
          product.pageOrder = getAsinOrderPerPage(asinsPerPage, product.asin);
          return product;
        });
      }
      return url;
    });
    yield put(productReplacementsActions.getSuccess(data.urls, data.total));

    if (data.oos_asins && Object.keys(data.oos_asins)?.length) {
      const globalFilters = yield select(selectGlobalFilters);
      yield put(
        replacementRecomendationsActions.get({
          ids: data.oos_asins,
          publisherId: globalFilters.publisher_id,
        })
      );
    }
  } catch (err) {
    yield put(productReplacementsActions.getError(err as Error));
  }
}

function* getSitemaps({ payload }: Action) {
  try {
    const { data } = yield call(contentOverviewApi.getSitemaps, payload);
    yield put(sitemapsActions.getSuccess(data.sitemaps, data.sitemaps_count));
  } catch (err) {
    yield put(sitemapsActions.getError(err as Error));
  }
}

function* deleteSitemap({ payload, meta }: Action) {
  try {
    yield call(contentOverviewApi.deleteSitemap, payload);
    yield put(contentOverviewActions.deleteSitemapSuccess());
    meta?.onSuccess();
  } catch (err) {
    yield put(contentOverviewActions.deleteSitemapError(err as Error));
  }
}

function* createSitemaps({ payload }: Action): SagaIterator {
  try {
    yield call(contentOverviewApi.createSitemaps, payload.publisherId, payload.sitemaps, payload.sitemap_list);

    const { limit, page } = yield select(selectSitemapsLimitPageAmount);
    const globalFilters = yield select(selectGlobalFilters);

    yield put(
      sitemapsActions.get({
        limit,
        offset: limit * page,
        publisherId: globalFilters.publisher_id,
      })
    );
  } catch (err) {
    yield put(contentOverviewActions.createSitemapsError(err as Error));
  }
}

function* refreshPages(): SagaIterator {
  const { limit, page } = yield select(selectPagesLimitPageAmount);
  const globalFilters = yield select(selectGlobalFilters);

  yield put(
    pagesActions.get({
      limit,
      offset: limit * page,
      publisherId: globalFilters.publisher_id,
    })
  );
}

function* createPages({ payload }: Action): SagaIterator {
  try {
    yield call(contentOverviewApi.createPages, payload.publisherId, payload.pages);
    yield call(refreshPages);
  } catch (err) {
    yield put(contentOverviewActions.createPagesError(err as Error));
  }
}

function* importPages({ payload }: Action): SagaIterator {
  try {
    yield call(contentOverviewApi.importPages, payload.publisherId, payload.file);
    yield call(refreshPages);
    yield put(contentOverviewActions.importPagesSuccess());
  } catch (err) {
    yield put(contentOverviewActions.importPagesError(err as Error));
  }
}

function* getReplacementRecommendations({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(contentOverviewApi.getReplacementRecommendations, payload.publisherId, payload.ids);
    yield put(
      replacementRecomendationsActions.getSuccess(
        data.map((entry: ContentOverviewModule.ReplacementSuggestionsResponseEntry) => ({
          ...entry.suggestions[0],
          out_of_stock_asin: entry.product.asin,
          saved_replacements: entry?.saved_replacements[0] ?? null,
        }))
      )
    );
  } catch (err) {
    yield put(replacementRecomendationsActions.getError(err as Error));
  }
}

function* generateReport({ payload }: Action) {
  try {
    const { data } = yield call(contentOverviewApi.generateReport, payload);
    yield put(contentOverviewActions.generateReportSuccess(data.execution_id));
  } catch (err) {
    yield put(contentOverviewActions.generateReportError(err as Error));
  }
}

function* openReportLink({ payload, meta }: Action) {
  try {
    const { data } = yield call(contentOverviewApi.openReportLink, payload);
    if (data.link.includes('https:')) {
      window.location.href = data.link;
      meta.onStopTimer();
      yield put(contentOverviewActions.successReportLink());
    }
  } catch (err) {
    //
  }
}

function* getProductsStats({ payload }: Action) {
  try {
    const { data } = yield call(contentOverviewApi.getProductsStats, payload);
    yield put(contentOverviewActions.getProductsStatsSuccess(data));
  } catch (err) {
    yield put(contentOverviewActions.getProductsStatsError(err as Error));
  }
}

function* getCrawledURLs({ payload }: Action) {
  try {
    const { data } = yield call(contentOverviewApi.getCrawledURLs, payload);
    if (!data.isEnd) {
      yield put(crawlingActions.getLazySuccess(data.urls, data.total_count));
    } else {
      yield put(crawlingActions.canLoadMore(false));
    }
  } catch (err) {
    yield put(crawlingActions.getError(err as Error));
  }
}

function* getCrawlingStatus({ payload }: Action) {
  try {
    const { data } = yield call(contentOverviewApi.getCrawlingStatus, payload);
    yield put(contentOverviewActions.getCrawlingStatusSuccess(data.status));
  } catch (err) {
    yield put(contentOverviewActions.getCrawlingStatusError(err as Error));
  }
}

function* postCrawling({ payload }: Action): SagaIterator {
  try {
    yield call(contentOverviewApi.postCrawling, payload.publisherId, payload.domain);

    const filters = yield select(selectGlobalFilters);

    yield put(contentOverviewActions.getCrawlingStatus({ publisherId: filters.publisher_id }));
  } catch (err) {
    yield put(contentOverviewActions.postCrawlingError(err as Error));
  }
}

function* syncPage({ payload }: Action): SagaIterator {
  try {
    yield put(crawlingActions.canLoadMore(false));
    yield call(contentOverviewApi.createPages, payload.publisherId, [payload.page], payload.asins, payload.updated_at);
    yield put(contentOverviewActions.syncPageSuccess());
    yield put(crawlingActions.canLoadMore(true));
  } catch (err) {
    yield put(contentOverviewActions.syncPageError(err as Error));
  }
}

function* getProductRecommendations({ payload }: Action): SagaIterator {
  try {
    if (payload.search?.length >= 0) {
      const { data } = yield call(contentOverviewApi.getProductRecommendationsSearch, payload);
      yield put(
        contentOverviewActions.getProductRecommendationsSuccess(
          data.map((item: { asin: string }) => ({ product: item, product_asin: item.asin })),
          payload.asins
        )
      );
    } else {
      const { data } = yield call(contentOverviewApi.getProductRecommendations, payload);
      yield put(contentOverviewActions.getProductRecommendationsSuccess(data[0]?.suggestions, payload.asins));
    }
  } catch (err) {
    yield put(contentOverviewActions.getProductRecommendationsError(err as Error));
  }
}

function* postProductRecommendations({ payload, meta }: Action): SagaIterator {
  try {
    yield call(contentOverviewApi.postProductRecommendations, payload);
    yield put(contentOverviewActions.postProductRecommendationsSuccess());
    meta?.onSuccess();
  } catch (err) {
    yield put(contentOverviewActions.postProductRecommendationsError(err as Error));
  }
}

function* patchProductRecommendations({ payload, meta }: Action): SagaIterator {
  try {
    yield call(contentOverviewApi.patchProductRecommendations, payload);
    yield put(contentOverviewActions.patchProductRecommendationsSuccess());
    meta?.onSuccess();
  } catch (err) {
    yield put(contentOverviewActions.patchProductRecommendationsError(err as Error));
  }
}

function* postRescrapTab({ payload, meta }: Action): SagaIterator {
  try {
    yield call(contentOverviewApi.postRescrapTab, payload);
    meta?.onSuccess();
  } catch (err) {
    //
  }
}

function* postUrlsRescrap({ payload, meta }: Action): SagaIterator {
  try {
    yield call(contentOverviewApi.postUrlsRescrap, payload);
    meta.onSuccess();
  } catch (err) {
    //
  }
}

function* patchUpdateSchedule({ payload, meta }: Action): SagaIterator {
  try {
    yield call(contentOverviewApi.patchUpdateSchedule, payload);
    const selectValue = payload.schedule;
    meta.onSuccess(selectValue);
  } catch (err) {
    const message: string = err instanceof Error ? err.message : (err as string);
    toast.error(message);
  }
}

function* getScheduleByTab({ payload, meta }: Action): SagaIterator {
  try {
    const response = yield call(contentOverviewApi.getTabScrapingSchedule, payload.data);
    meta.onSuccess(response?.data.data.schedule);
  } catch (err) {
    const message: string = err instanceof Error ? err.message : (err as string);
    toast.error(message);
  }
}

function* requestToSwapOOS({ payload, meta }: Action): SagaIterator {
  try {
    const response = yield call(contentOverviewApi.requestToSwapOOS, payload.data);
    meta?.onSuccess(response.data);
  } catch (err) {
    if (err instanceof AxiosError) {
      toast.error(err.response.data?.message);
    } else {
      const message: string = err instanceof Error ? err.message : (err as string);
      toast.error(message);
    }

    meta.onError(err);
  }
}

export function* contentOverviewSaga(): SagaIterator {
  yield all([
    takeEvery(pagesActionTypes.GET, getPages),
    takeEvery(contentOverviewActionTypes.DELETE_URL, deleteUrl),
    takeEvery(contentOverviewActionTypes.DELETE_FILTERED_URLS, deleteFilteredUrls),
    takeEvery(productReplacementsActionTypes.GET, getProductReplacements),
    takeEvery(sitemapsActionsTypes.GET, getSitemaps),
    takeEvery(contentOverviewActionTypes.CREATE_SITEMAPS, createSitemaps),
    takeEvery(contentOverviewActionTypes.DELETE_SITEMAPS, deleteSitemap),
    takeEvery(contentOverviewActionTypes.CREATE_PAGES, createPages),
    takeEvery(contentOverviewActionTypes.IMPORT_PAGES, importPages),
    takeLatest(replacementRecomendationsActionTypes.GET, getReplacementRecommendations),
    takeEvery(contentOverviewActionTypes.GENERATE_REPORT, generateReport),
    takeLatest(contentOverviewActionTypes.OPEN_REPORT_LINK, openReportLink),
    takeEvery(contentOverviewActionTypes.GET_PRODUCTS_STATS, getProductsStats),
    takeEvery(crawlingActionTypes.GET, getCrawledURLs),
    takeEvery(contentOverviewActionTypes.POST_CRAWLING, postCrawling),
    takeEvery(contentOverviewActionTypes.GET_CRAWLING_STATUS, getCrawlingStatus),
    takeEvery(contentOverviewActionTypes.SYNC_PAGE, syncPage),
    takeEvery(contentOverviewActionTypes.GET_PRODUCT_RECOMMENDATIONS, getProductRecommendations),
    takeEvery(contentOverviewActionTypes.POST_PRODUCT_RECOMMENDATIONS, postProductRecommendations),
    takeEvery(contentOverviewActionTypes.PATCH_PRODUCT_RECOMMENDATIONS, patchProductRecommendations),
    takeEvery(contentOverviewActionTypes.POST_RESCRAP_TAB, postRescrapTab),
    takeEvery(contentOverviewActionTypes.POST_URLS_RESCRAP, postUrlsRescrap),
    takeEvery(pagesPerformanceActionTypes.GET, getPerformancePages),
    takeEvery(contentOverviewActionTypes.PATCH_UPDATE_SCHEDULE, patchUpdateSchedule),
    takeEvery(contentOverviewActionTypes.GET_TAB_SCHEDULE, getScheduleByTab),
    takeEvery(contentOverviewActionTypes.REQUEST_TO_SWAP_OOS, requestToSwapOOS),
    takeEvery(contentOverviewActionTypes.UPDATE_PAGE_PERFORMANCE_PAGES, updatePerformancePages),
  ]);
}
