import { parseFilters } from 'common/utils/parse-filters';
import { ApiService } from '../../../common/services';

class AsinsConversionsBoostService extends ApiService {
  getConversionsBoost = (filters: AsinsConversionsBoostModule.GetConversionsBoostParams) => {
    return this.get('/api/conversions-boost/list', { params: parseFilters(filters) });
  };

  postConversionBoost = (payload: AsinsConversionsBoostModule.ConversionBoost) => {
    return this.post('/api/conversions-boost/single', payload);
  };

  editConversionBoost = (payload: AsinsConversionsBoostModule.EditConversionBoostParams) => {
    return this.put(`/api/conversions-boost/single/${payload.id}`, { boost: payload.boost });
  };

  deleteConversionBoost = (id: number) => {
    return this.delete(`/api/conversions-boost/single/${id}`);
  };

  getExport = (filters: AsinsConversionsBoostModule.GetConversionsBoostParams) => {
    return this.get('/api/conversions-boost/export', { params: parseFilters(filters) });
  };

  uploadFile = (file: File) => {
    return this.postFormData('/api/conversions-boost/upload', { file });
  };

  getBrands = () => {
    return this.get('/api/brands?no_limit=true');
  };
}

export const asinsConversionsBoostService = new AsinsConversionsBoostService(process.env.REACT_APP_API_URL);
