import { MenuItem, TextField, Button, Box } from '@mui/material';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import { asinsConversionsBoostSelectors } from 'modules/asins-conversions-boost/store/selectors';
import { ReactElement, useState, useEffect, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { selectPublisherBrandCommissions } from '../../store/selectors';

const styles: Styles = {
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  },
  button: {
    maxWidth: '80px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
  },
};

export interface ModalFormState {
  brand_id: string;
  commission_rate: string;
}

interface AddBrandCommissionModalProps {
  isShowModal: boolean;
  onClose: () => void;
  onFormSubmit: (formData: ModalFormState) => void;
  onCancelButtonClick: () => void;
  actionType: 'add' | 'edit';
  initialState?: ModalFormState;
}

export function AddBrandCommissionModal({
  isShowModal,
  onClose,
  onFormSubmit,
  onCancelButtonClick,
  actionType,
  initialState,
}: AddBrandCommissionModalProps): ReactElement {
  const brandsState = useSelector(asinsConversionsBoostSelectors.selectBrands);
  const data = useSelector(selectPublisherBrandCommissions);

  const [formValues, setFormValues] = useState<ModalFormState>({
    brand_id: initialState.brand_id,
    commission_rate: initialState.commission_rate,
  });
  const [errors, setErrors] = useState<Partial<ModalFormState>>({});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validate = (brand_id?: number, commission_rate?: number) => {
    if (!brand_id) {
      setErrors({
        brand_id: 'Brand is required',
      });
      return false;
    }

    if (!commission_rate) {
      setErrors({
        commission_rate: 'Commission is required',
      });
      return false;
    }

    if (!commission_rate || commission_rate < 0 || commission_rate > 1) {
      setErrors({
        commission_rate: 'Commission must be a number between 0 and 1',
      });
      return false;
    }

    return true;
  };

  useEffect(() => {
    setFormValues({
      brand_id: initialState.brand_id || '',
      commission_rate: initialState.commission_rate || '',
    });
    setErrors({
      brand_id: '',
      commission_rate: '',
    });
  }, [initialState]);

  return (
    <Modal
      open={isShowModal}
      onClose={onClose}
      title={`${actionType.charAt(0).toUpperCase() + actionType.slice(1)} brand commission`}
      maxWidth="xs"
    >
      <Box sx={styles.formContainer}>
        <TextField
          select
          label="Select Brand"
          name="brand_id"
          value={formValues.brand_id}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          size="small"
          error={Boolean(errors.brand_id)}
          helperText={errors.brand_id}
        >
          {((): ReactNode => {
            if (!brandsState.loading && brandsState.brands && brandsState.brands.length > 0) {
              if (actionType === 'add') {
                return brandsState.brands.map(b => (
                  <MenuItem key={b?.id} value={b?.id} selected={b?.id === +formValues.brand_id}>
                    {b?.name}
                  </MenuItem>
                ));
              }
              const currentPublisherBrandsIds = data.map(d => d.brand_id);
              const currentPublishersBrands = brandsState.brands.filter(b =>
                currentPublisherBrandsIds.find(id => id === b.id)
              );
              if (currentPublishersBrands && currentPublishersBrands.length > 0) {
                return currentPublishersBrands.map(b => {
                  return (
                    <MenuItem key={b.id} value={b.id} selected={b.id === +formValues.brand_id}>
                      {b.name}
                    </MenuItem>
                  );
                });
              }
              return null;
            }
            return <MenuItem>Loading</MenuItem>;
          })()}
        </TextField>
        <TextField
          label="Commission"
          name="commission_rate"
          type="number"
          value={formValues.commission_rate}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          size="small"
          error={Boolean(errors.commission_rate)}
          helperText={errors.commission_rate}
        />
        <Box sx={styles.buttonContainer}>
          <Button
            onClick={() => {
              if (validate(+formValues.brand_id, +formValues.commission_rate)) {
                onFormSubmit({
                  brand_id: formValues.brand_id,
                  commission_rate: formValues.commission_rate,
                });
              }
            }}
            variant="contained"
            sx={styles.button}
          >
            Submit
          </Button>
          <Button onClick={onCancelButtonClick} variant="outlined" sx={styles.button}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
