import { combineReducers } from 'redux';
import asins from './asins';
import customRate from './custom-rate';
import defaultRate from './default-rate';
import filters from './filters';

export const affiliateRateCardReducers = combineReducers({
  asins,
  customRate,
  defaultRate,
  filters,
});
