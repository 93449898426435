import { TableCell, IconButton, Popover, List, ListItemText, Box, ListItem, ListItemIcon } from '@mui/material';
import { Styles } from 'common/types';
import LaunchIcon from '@mui/icons-material/Launch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { MouseEvent, ReactElement } from 'react';

const styles: Styles = {
  list: { width: 200 },
  articleAsinList: { width: 300 },
  imgSm: { height: '35px' },
};

const link = 'https://www.amazon.com/dp/';

interface Props {
  onClick: (e: MouseEvent<HTMLElement>) => void;
  tags?: Set<string>;
  asins?: RevenueByPage.Asin[];
  open: boolean;
  anchor: HTMLElement;
  handleClose: () => void;
  row: RevenueByPage.TableRow;
  isAsin?: boolean;
  isTag?: boolean;
}

export function TableCellList({
  onClick,
  tags,
  asins,
  open,
  anchor,
  handleClose,
  row,
  isAsin,
  isTag,
}: Props): ReactElement {
  const displayAsin = (): string => {
    if (asins.length === 1) return asins[0].asin;
    if (asins.length > 1) return `${asins[0].asin}...`;
    return '...';
  };

  const displayTag = () => {
    if (tags.size === 0) return 'Tags not found';
    if (tags.size === 1) return [...tags][0];
    if (tags.size > 1) return `${[...tags][0]}...`;
    return '...';
  };

  return (
    <TableCell>
      {isAsin && (
        <Box onClick={onClick}>
          {displayAsin()}
          <IconButton>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
        </Box>
      )}
      {isTag && (
        <Box onClick={onClick}>
          {displayTag()}
          <IconButton>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
        </Box>
      )}
      {!isTag && !isAsin && (
        <Box onClick={onClick}>
          {row.total_shipments}
          <IconButton>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
        </Box>
      )}
      <Popover
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {isAsin && (
          <List sx={styles.articleAsinList}>
            {asins?.map(asin => (
              <ListItem key={asin.id}>
                <ListItemIcon>
                  <Box component="img" src={asin.image} alt="" sx={styles.imgSm} />
                </ListItemIcon>
                <ListItemText primary={asin.asin} />
                <ListItemIcon>
                  <IconButton onClick={() => window.open(`${asin.link}`, '_blank')}>
                    <LaunchIcon fontSize="small" />
                  </IconButton>
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        )}
        {isTag && (
          <List sx={styles.list}>
            {[...tags].map(tag => (
              <ListItem key={tag}>
                <ListItemText primary={tag} />
              </ListItem>
            ))}
          </List>
        )}
        {!isAsin && !isTag && (
          <List sx={styles.list}>
            {[...new Set(row.asins.split(', '))].map(asin => (
              <ListItem key={asin}>
                <ListItemText primary={asin} />
                <ListItemIcon>
                  <IconButton onClick={() => window.open(`${link}${asin}`, '_blank')}>
                    <LaunchIcon fontSize="small" />
                  </IconButton>
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        )}
      </Popover>
    </TableCell>
  );
}
