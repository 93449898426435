import { Action } from 'common/interfaces/root';

export interface DataStore<D> {
  loading: boolean;
  canLoadMore: boolean;
  error: Error | null;
  data: Array<D>;
  page?: number;
  limit?: number;
  amount?: number;
}

interface ReducerOptions {
  limit?: number;
}

const getInitState = <D>(limit = 100): DataStore<D> => ({
  loading: false,
  canLoadMore: true,
  error: null,
  data: [],
  page: 0,
  amount: 0,
  limit,
});

export const getDataListReducer =
  <D>(actionTypes: Record<string, string>, options?: ReducerOptions) =>
  (state: DataStore<D> = getInitState<D>(options?.limit), { type, payload }: Action): DataStore<D> => {
    switch (type) {
      case actionTypes.GET:
        return { ...state, loading: true };

      case actionTypes.GET_SUCCESS:
        return { ...state, loading: false, data: payload.data, amount: payload.amount };

      case actionTypes.GET_LAZY_SUCCESS:
        return { ...state, loading: false, data: [...state.data, ...payload.data], amount: payload.amount };

      case actionTypes.RESET:
        return { ...state, data: [] };

      case actionTypes.CAN_LOAD_MORE:
        return { ...state, loading: false, canLoadMore: payload };

      case actionTypes.GET_ERROR:
        return { ...state, loading: false, error: payload };

      case actionTypes.CHANGE_PAGE:
        return { ...state, page: payload };

      case actionTypes.CHANGE_LIMIT:
        return { ...state, limit: payload };

      case actionTypes.DELETE_SUCCESS:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { ...state, data: state.data.filter(entry => (entry as any)[payload.field] !== payload.id) };

      default:
        return state;
    }
  };
