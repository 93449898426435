import { ReactNode, ReactPortal, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { nanoid } from 'nanoid';

interface Props {
  children: ReactNode;
  id?: string;
}

export function Portal({ children, id }: Props): ReactPortal | null {
  const el = useRef<HTMLDivElement>(document.createElement('div'));

  useEffect(() => {
    let mount = document.getElementById(id || '');
    let destroyMount = false;
    if (!mount) {
      mount = document.createElement('div');
      mount.setAttribute('id', `portal-${nanoid()}`);
      mount.setAttribute('role', 'portal');
      document.body.appendChild(mount);

      destroyMount = true;
    }

    mount?.appendChild(el.current);

    return () => {
      if (destroyMount) {
        mount?.remove();
      } else {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        mount?.removeChild(el.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return createPortal(children, el.current);
}
