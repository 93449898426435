import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import { parentPublisherSettingsService } from 'modules/parent-publisher-settings/services/parent-publisher-settings.service';
import toast from 'react-hot-toast';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { parentPublisherSettingsActions, parentPublisherSettingsActionTypes as TYPES } from '../actions';

function* getPublisherAccountsSaga(): SagaIterator {
  try {
    const { data } = yield call(parentPublisherSettingsService.getPublisherAccounts);

    yield put(parentPublisherSettingsActions.getPublisherAccountsSuccess(data));
  } catch (error) {
    yield put(parentPublisherSettingsActions.getPublisherAccountsError(error as Error));
  }
}

function* getPublisherAccountsParentsSaga({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(parentPublisherSettingsService.getPublisherAccountsParents, payload);

    yield put(parentPublisherSettingsActions.getPublisherAccountsParentsSuccess(data));
  } catch (error) {
    yield put(parentPublisherSettingsActions.getPublisherAccountsParentsError(error as Error));
  }
}

function* postPublisherAccountsParentsSaga({ payload }: Action): SagaIterator {
  try {
    yield call(parentPublisherSettingsService.postPublisherAccountParents, payload);

    yield put(parentPublisherSettingsActions.postPublisherAccountsParentsSuccess('Entry created'));
    toast.success('Entry created');
    yield put(parentPublisherSettingsActions.getPublisherAccountsParents({ limit: 100, offset: 0 }));
  } catch (error) {
    yield put(parentPublisherSettingsActions.postPublisherAccountsParentsError(error as Error));
  }
}

function* patchPublisherAccountsParentsSaga({ payload }: Action): SagaIterator {
  try {
    yield call(parentPublisherSettingsService.patchPublisherAccountParents, payload);
    yield put(parentPublisherSettingsActions.patchPublisherAccountsParentsSuccess('Entry updated'));
    toast.success('Entry updated');
    yield put(parentPublisherSettingsActions.getPublisherAccountsParents({ limit: 100, offset: 0 }));
  } catch (error) {
    yield put(parentPublisherSettingsActions.patchPublisherAccountsParentsError(error as Error));
  }
}

function* deletePublisherAccountsParentsSaga({ payload }: Action): SagaIterator {
  try {
    yield call(parentPublisherSettingsService.deletePublisherAccountParents, payload);

    yield put(parentPublisherSettingsActions.deletePublisherAccountsParentsSuccess('Entry deleted'));
    toast.success('Entry deleted');
    yield put(parentPublisherSettingsActions.getPublisherAccountsParents({ limit: 100, offset: 0 }));
  } catch (error) {
    yield put(parentPublisherSettingsActions.deletePublisherAccountsParentsError(error as Error));
  }
}

export function* parentPublisherSettingsSaga(): SagaIterator {
  yield all([
    takeLatest(TYPES.GET_PUBLISHER_ACCOUNTS, getPublisherAccountsSaga),
    takeLatest(TYPES.GET_PUBLISHER_ACCOUNTS_PARENTS, getPublisherAccountsParentsSaga),
    takeLatest(TYPES.POST_PUBLISHER_ACCOUNTS_PARENTS, postPublisherAccountsParentsSaga),
    takeLatest(TYPES.PATCH_PUBLISHER_ACCOUNTS_PARENTS, patchPublisherAccountsParentsSaga),
    takeLatest(TYPES.DELETE_PUBLISHER_ACCOUNTS_PARENTS, deletePublisherAccountsParentsSaga),
  ]);
}
