import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Styles } from 'common/types';
import { Box, CircularProgress, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { UserSettingsContent } from './components';
import { userSettingsActions } from './store/actions';
import { selectUserData, selectUserDataLoading } from './store/selectors';

const getStyles = (): Styles => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  headerArrow: {
    marginRight: '5px',
  },
  spinnerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '200px',
  },
});

const validationSchema = Yup.object({
  fullname: Yup.string().min(1),
  currentPassword: Yup.string().min(8),
  newPassword: Yup.string().matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number'
  ),
  newPasswordConfirm: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

export function UserSettings(): ReactElement {
  const dispatch = useDispatch();

  const styles = getStyles();
  const user = useSelector(selectUserData);
  const loading = useSelector(selectUserDataLoading);

  const getInitialValues = (): UserSettingsModule.UserSettingsForm => ({
    avatar: '',
    fullname: user?.name ?? '',
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  });

  const handleSubmit = (values: UserSettingsModule.UserSettingsForm) => {
    dispatch(userSettingsActions.patchUserSettingsInfo(values));
  };

  useEffect(() => {
    dispatch(userSettingsActions.getUserSettingsInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!loading && (
        <Box sx={styles.header}>
          <KeyboardArrowLeftIcon sx={styles.headerArrow} />
          <Typography variant="h4">{user?.name}</Typography>
        </Box>
      )}
      {loading ? (
        <Box sx={styles.spinnerWrapper}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box>
          <Formik initialValues={getInitialValues()} onSubmit={handleSubmit} validationSchema={validationSchema}>
            <UserSettingsContent />
          </Formik>
        </Box>
      )}
    </>
  );
}
