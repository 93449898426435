import { ReactElement } from 'react';
import { Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material';
import { Styles } from 'common/types';

const styles: Styles = {
  clientTitle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '250px',
  },
};

interface Props {
  item: PublishersSettingsModule.ClientItem;
  inviteClientsIds: number[];
  handleChangeInviteClientsIds: (checked: boolean, ids: Array<number>) => void;
}

export function ClientItem({ item, inviteClientsIds, handleChangeInviteClientsIds }: Props): ReactElement {
  return (
    <FormControlLabel
      label={
        <Tooltip title={item?.name}>
          <Typography sx={styles.clientTitle}>{item?.name}</Typography>
        </Tooltip>
      }
      control={
        <Checkbox
          checked={inviteClientsIds?.filter(cl => Number(cl) === Number(item?.id))?.length > 0}
          onChange={(event, checked) => handleChangeInviteClientsIds(checked, [item?.id])}
        />
      }
    />
  );
}
