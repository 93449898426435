import { Action } from 'common/interfaces/root';
import { asinsConversionsBoostTypes as TYPES } from '../actions/action-types';

const initialState: AsinsConversionsBoostModule.FiltersStore = {
  asins: [],
  publisher_id: null,
  brand_id: null,
  boost: null,
  compare_operator: '=',
  limit: 50,
  offset: 0,
};

export default (
  state: AsinsConversionsBoostModule.FiltersStore = initialState,
  { type, payload }: Action
): AsinsConversionsBoostModule.FiltersStore => {
  switch (type) {
    case TYPES.FILTERS.SET_LIMIT:
      return { ...state, limit: payload };
    case TYPES.FILTERS.SET_OFFSET:
      return { ...state, offset: payload };
    case TYPES.FILTERS.SET_FILTERS:
      const { asins, publisher_id, brand_id, boost, compare_operator } = payload;
      return {
        ...state,
        asins,
        publisher_id,
        brand_id,
        boost,
        compare_operator,
      };
    case TYPES.FILTERS.RESET_FILTERS:
      return initialState;
    default:
      return state;
  }
};
