import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import { ChangeEvent, ReactElement } from 'react';

export interface SelectOption {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

interface Props {
  options: Array<SelectOption>;
}

export function SelectField({ options, onChange, name, ...props }: Props & TextFieldProps): ReactElement {
  const [field, meta] = useField<string>(name);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    onChange?.(e);
  };

  return (
    <TextField
      {...props}
      {...field}
      error={meta.error && meta.touched}
      helperText={meta.touched ? meta.error : ''}
      onChange={handleChange}
      select
    >
      {options.map(option => (
        <MenuItem key={options.indexOf(option)} value={option.value}>
          {option.title}
        </MenuItem>
      ))}
    </TextField>
  );
}
