import { TableCell, TableRow } from '@mui/material';
import { ReactElement } from 'react';
import moment from 'moment';
import { TableRowActions } from 'common/ui/table-row-actions';
import { useDispatch, useSelector } from 'react-redux';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { selectGlobalFilters } from 'common/store/selectors';

interface Props {
  row: PublishersSettingsModule.IntegrationFormData;
  handleRefreshList: () => void;
}

export function IntegrationItem({ row, handleRefreshList }: Props): ReactElement {
  const dispatch = useDispatch();

  const filters = useSelector(selectGlobalFilters);

  const handleDeleteRow = () => {
    dispatch(publishersSettingsActions.deleteIntegrationFromList(filters?.publisher_id, 'google', handleRefreshList));
  };

  return (
    <TableRow>
      <TableCell>{row?.provider}</TableCell>
      <TableCell>
        {row?.created_at
          ? `${moment(row?.created_at).format('DD MMM, YY')} at ${moment(row?.created_at).format('hh:mm')}`
          : ''}
      </TableCell>
      <TableCell>{row?.name}</TableCell>
      <TableCell>
        <TableRowActions show={['delete']} onDelete={handleDeleteRow} />
      </TableCell>
    </TableRow>
  );
}
