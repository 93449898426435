import { Box, Tooltip, Typography, TypographyProps } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Styles } from 'common/types';
import { SystemStyleObject } from '@mui/system';

const styles: Styles = {
  container: { display: 'flex', alignItems: 'center' },
  icon: { ':hover': { cursor: 'pointer' }, height: 12 },
};

interface Props {
  title: string;
  tooltip: string;
  delay?: number;
  typographyProps?: TypographyProps;
  sx?: SystemStyleObject;
}

export function TitleTooltip({ title, tooltip, delay = 400, typographyProps, sx }: Props) {
  return (
    <Box sx={[styles.container, sx]}>
      <Typography {...typographyProps}>{title}</Typography>
      <Tooltip title={tooltip} enterDelay={delay}>
        <HelpOutlineIcon sx={styles.icon} />
      </Tooltip>
    </Box>
  );
}
