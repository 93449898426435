import { ReactElement } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalFilters } from 'common/store/selectors';
import { Modal } from 'common/ui/modal';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { selectPublisherInfo } from 'modules/settings/store/selectors';
import { DefaultTagContent } from './default-tag-content';

const initialValues: PublishersSettingsModule.DefaultTagForm = {
  default_tag: '',
};

interface Props {
  open: boolean;
  onClose: () => void;
}

export function DefaultTagModal({ open, onClose }: Props): ReactElement {
  const dispatch = useDispatch();

  const global = useSelector(selectGlobalFilters);
  const publisher = useSelector(selectPublisherInfo);

  const validationSchema = yup.object({
    default_tag: yup
      .string()
      .notOneOf([publisher?.default_tag], 'Value already in use')
      .required('Please enter a new value'),
  });

  const onSumbitSuccess = () => {
    toast.success('Default tag updated');
    window.location.reload();
  };

  const onSumbitError = () => {
    toast.error('Failed to update');
  };

  const handleSubmit = (values: PublishersSettingsModule.DefaultTagForm) => {
    dispatch(
      publishersSettingsActions.patchDefaultTag(
        {
          publisher_id: global.publisher_id,
          default_tag: values.default_tag,
        },
        onSumbitSuccess,
        onSumbitError
      )
    );
  };

  return (
    <Modal open={open} title="Default Tag" maxWidth="sm" onClose={onClose}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        <DefaultTagContent defaultTag={publisher?.default_tag || ''} handleClose={onClose} />
      </Formik>
    </Modal>
  );
}
