const ASINS_CONVERSIONS_BOOST_MODULE = '[asins-conversions-boost]';

const GET_EXPORT = `${ASINS_CONVERSIONS_BOOST_MODULE} GET_EXPORT`;
const GET_EXPORT_SUCCESS = `${ASINS_CONVERSIONS_BOOST_MODULE} GET_EXPORT_SUCCESS`;
const GET_EXPORT_ERROR = `${ASINS_CONVERSIONS_BOOST_MODULE} GET_EXPORT_ERROR`;
const RESET_EXPORT = `${ASINS_CONVERSIONS_BOOST_MODULE} RESET_EXPORT`;

const UPLOAD_FILE = `${ASINS_CONVERSIONS_BOOST_MODULE} UPLOAD_FILE`;
const UPLOAD_FILE_SUCCESS = `${ASINS_CONVERSIONS_BOOST_MODULE} UPLOAD_FILE_SUCCESS`;
const UPLOAD_FILE_ERROR = `${ASINS_CONVERSIONS_BOOST_MODULE} UPLOAD_FILE_ERROR`;

const POST_CONVERSION_BOOST = `${ASINS_CONVERSIONS_BOOST_MODULE} POST_CONVERSION_BOOST`;
const POST_CONVERSION_BOOST_SUCCESS = `${ASINS_CONVERSIONS_BOOST_MODULE} POST_CONVERSION_BOOST_SUCCESS`;
const POST_CONVERSION_BOOST_ERROR = `${ASINS_CONVERSIONS_BOOST_MODULE} POST_CONVERSION_BOOST_ERROR`;

const GET_CONVERSIONS_BOOST = `${ASINS_CONVERSIONS_BOOST_MODULE} GET_CONVERSIONS_BOOST`;
const GET_CONVERSIONS_BOOST_SUCCESS = `${ASINS_CONVERSIONS_BOOST_MODULE} GET_CONVERSIONS_BOOST_SUCCESS`;
const GET_CONVERSIONS_BOOST_ERROR = `${ASINS_CONVERSIONS_BOOST_MODULE} GET_CONVERSIONS_BOOST_ERROR`;

const CHANGE_CONVERSIONS_BOOST_PAGE = `${ASINS_CONVERSIONS_BOOST_MODULE} CHANGE_CONVERSIONS_BOOST_PAGE`;

const EDIT_CONVERSION_BOOST = `${ASINS_CONVERSIONS_BOOST_MODULE} EDIT_CONVERSION_BOOST`;
const EDIT_CONVERSION_BOOST_SUCCESS = `${ASINS_CONVERSIONS_BOOST_MODULE} EDIT_CONVERSION_BOOST_SUCCESS`;
const EDIT_CONVERSION_BOOST_ERROR = `${ASINS_CONVERSIONS_BOOST_MODULE} EDIT_CONVERSION_BOOST_ERROR`;

const DELETE_CONVERSION_BOOST = `${ASINS_CONVERSIONS_BOOST_MODULE} DELETE_CONVERSION_BOOST`;
const DELETE_CONVERSION_BOOST_SUCCESS = `${ASINS_CONVERSIONS_BOOST_MODULE} DELETE_CONVERSION_BOOST_SUCCESS`;
const DELETE_CONVERSION_BOOST_ERROR = `${ASINS_CONVERSIONS_BOOST_MODULE} DELETE_CONVERSION_BOOST_ERROR`;

const SET_BRANDS = `${ASINS_CONVERSIONS_BOOST_MODULE} SET_BRANDS`;
const GET_BRANDS = `${ASINS_CONVERSIONS_BOOST_MODULE} GET_BRANDS`;
const GET_BRANDS_SUCCESS = `${ASINS_CONVERSIONS_BOOST_MODULE} GET_BRANDS_SUCCESS`;
const GET_BRANDS_ERROR = `${ASINS_CONVERSIONS_BOOST_MODULE} GET_BRANDS_ERROR`;

const SET_LIMIT = `${ASINS_CONVERSIONS_BOOST_MODULE} SET_LIMIT`;
const SET_OFFSET = `${ASINS_CONVERSIONS_BOOST_MODULE} SET_OFFSET`;

const SET_FILTERS = `${ASINS_CONVERSIONS_BOOST_MODULE} SET_FILTERS`;
const RESET_FILTERS = `${ASINS_CONVERSIONS_BOOST_MODULE} RESET_FILTERS`;

export const asinsConversionsBoostTypes = {
  EXPORT: {
    GET_EXPORT,
    GET_EXPORT_SUCCESS,
    GET_EXPORT_ERROR,
    RESET_EXPORT,
  },
  UPLOAD_FILE: {
    UPLOAD_FILE,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_ERROR,
  },
  CONVERSIONS_BOOST: {
    GET_CONVERSIONS_BOOST,
    GET_CONVERSIONS_BOOST_SUCCESS,
    GET_CONVERSIONS_BOOST_ERROR,
    CHANGE_CONVERSIONS_BOOST_PAGE,
  },
  BRANDS: {
    SET_BRANDS,
    GET_BRANDS,
    GET_BRANDS_SUCCESS,
    GET_BRANDS_ERROR,
  },
  FORM: {
    POST_CONVERSION_BOOST,
    POST_CONVERSION_BOOST_SUCCESS,
    POST_CONVERSION_BOOST_ERROR,
    EDIT_CONVERSION_BOOST,
    EDIT_CONVERSION_BOOST_SUCCESS,
    EDIT_CONVERSION_BOOST_ERROR,
    DELETE_CONVERSION_BOOST,
    DELETE_CONVERSION_BOOST_SUCCESS,
    DELETE_CONVERSION_BOOST_ERROR,
  },
  FILTERS: {
    SET_LIMIT,
    SET_OFFSET,
    SET_FILTERS,
    RESET_FILTERS,
  },
};
