import { Action } from 'common/interfaces/root';
import { publishersSettingsActionTypes as T } from '../actions';

const initState: TagsModule.TagsFiltersState = {
  traffic_type: 'all',
  used: 'all',
};

export default (
  state: TagsModule.TagsFiltersState = initState,
  { type, payload }: Action
): TagsModule.TagsFiltersState => {
  switch (type) {
    case T.SET_TRAFFIC_TYPE:
      return { ...state, traffic_type: payload };
    case T.SET_USED:
      return { ...state, used: payload };

    case T.RESET_FILTERS:
      return initState;

    default:
      return state;
  }
};
