import { State } from 'common/store/reducers';

export const selectCurrentTagsPage = (state: State) => state.tags.list.page;

export const selectTags = (state: State) => state.tags.list.data;

export const selectTagsLoading = (state: State) => state.tags.list.loading;

export const selectTagsFilters = (state: State) => state.tags.filters;

export const selectTagsAmount = (state: State) => state.tags.list.amount;
