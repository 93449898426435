import { combineReducers } from 'redux';
import recommendedAsins from './asins';
import recommendedAsinsFilters from './filters';
import recommendedAsinsExport from './export';
import recommendedAsinsCategories from './categories';
import placements from './placements';
import featuredAsins from './featured-asins';

export const recommendedAsinsReducer = combineReducers({
  recommendedAsinsFilters,
  recommendedAsins,
  recommendedAsinsExport,
  recommendedAsinsCategories,
  placements,
  featuredAsins,
});
