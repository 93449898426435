import { Box, CircularProgress, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ReactElement } from 'react';
import { paper, text } from 'common/constants/colors';
import { Styles } from 'common/types';
import { scheme } from 'modules/stats/mock';
import { TitleTooltip } from 'common/ui/title-tooltip';

const styles: Styles = {
  card: {
    width: '100%',
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: '0 2px 5px rgb(0 0 0 / 5%)',
    borderRadius: '20px',
    backgroundColor: paper,
    padding: 3,
    boxSizing: 'border-box',
  },
  cardTitle: { fontSize: 14, fontWeight: 400, marginTop: 3 },
  cardValue: { color: text, fontSize: 24, fontWeight: 400 },
  progress: { marginTop: 1 },
  iconWrapper: {
    width: '100%',
    minWidth: 36,
    minHeight: 36,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
  },
  typoTitle: { color: scheme.baseDarkGrey },
  typoValue: { color: scheme.baseDark, height: 32 },
  boxIcon: {
    minWidth: 36,
    minHeight: 36,
    borderRadius: '12px',
    backgroundColor: scheme.cardIconFill,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export interface CardProps {
  title: string;
  value: string | number;
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  tip?: string;
}

export function CardContainer({ Icon, tip, title, value, loading }: CardProps): ReactElement {
  return (
    <Box sx={styles.card}>
      <Box sx={styles.iconWrapper}>
        <Box>
          {tip ? (
            <TitleTooltip title={title} tooltip={tip} typographyProps={{ sx: styles.typoTitle }} />
          ) : (
            <Typography sx={styles.typoTitle}>{title}</Typography>
          )}
          {loading ? (
            <CircularProgress size={30} color="primary" />
          ) : (
            <Typography variant="h5" sx={styles.typoValue}>
              {value}
            </Typography>
          )}
        </Box>
        {Icon && (
          <Box sx={styles.boxIcon}>
            <Icon fontSize="small" />
          </Box>
        )}
      </Box>
    </Box>
  );
}
