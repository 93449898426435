import { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, OutlinedInput, Typography } from '@mui/material';
import { Field } from 'common/ui/field';
import { getStyles as getSettingStyles } from 'modules/user-settings/components/user-settings-content/user-settings-content';
import { Styles } from 'common/types';
import { theme } from 'common/constants/theme';
import { useFormikContext } from 'formik';
import { selectUserData, selectUserPatchError } from 'modules/user-settings/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { userSettingsActions } from 'modules/user-settings/store/actions';

interface StylesProps {
  image: string;
}

export const getStyles = ({ image }: StylesProps): Styles => ({
  avatar: {
    backgroundImage: `url(${image})`,
    borderRadius: '50%',
    backgroundSize: 'cover',
    width: 65,
    height: 65,
    backgroundColor: theme.palette.primary.light,
  },
  buttons: { display: 'flex', justifyContent: 'flex-start' },
  field: { marginTop: 0 },
  hiddenInput: { display: 'none' },
  errorText: { color: 'red' },
  accordion: {
    border: 'none',
  },
  accordionElements: {
    paddingLeft: 0,
    '&.MuiAccordionSummary-root': {
      paddingRight: 0,
      '&.Mui-focusVisible': {
        backgroundColor: 'transparent',
      },
    },
    '&.MuiAccordionDetails-root': {
      padding: 0,
    },
  },
});

export function GeneralUserSettings(): ReactElement {
  const dispatch = useDispatch();

  const inputRef = useRef<HTMLInputElement>(null);
  const { setFieldValue, submitForm, errors } = useFormikContext<UserSettingsModule.UserSettingsForm>();

  const [isPreUpload, setIsPreUpload] = useState<boolean>(false);
  const [preUpload, setPreUpload] = useState<string>('');

  const data = useSelector(selectUserData);
  const patchError = useSelector(selectUserPatchError);

  const styles = getStyles({ image: isPreUpload ? preUpload : data.avatar });
  const settingsStyles = getSettingStyles();

  const [passwordSectionLabel, setPasswordSectionLabel] = useState<'Update password' | 'Current password'>(
    'Update password'
  );

  const handleAvatarChange = (value: File) => {
    setIsPreUpload(true);
    setFieldValue('avatar', value);
  };

  const handleChangeAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileReader = new FileReader();

    fileReader.onload = () => {
      setPreUpload(fileReader.result as string);
    };

    fileReader.readAsDataURL(file);
    e.target.value = '';
    handleAvatarChange(file);
  };

  const handleDeleteAvatar = () => {
    setPreUpload(null);
    handleAvatarChange(null);
  };

  const handleClickInput = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  useEffect(() => {
    if (preUpload || preUpload === null) {
      submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preUpload]);

  return (
    <>
      <Typography sx={settingsStyles.title}>User Settings</Typography>
      <Box sx={settingsStyles.fieldWrapper}>
        <Typography sx={settingsStyles.titleField}>Your avatar</Typography>
        <Box sx={settingsStyles.inputWrapper}>
          <Box sx={styles.avatar} />
          <Box sx={styles.hiddenInput}>
            <input accept="image/*" type="file" ref={inputRef} onChange={handleChangeAvatar} />
          </Box>
          <Box sx={styles.buttons}>
            <Button color="primary" onClick={handleClickInput} sx={settingsStyles.button} variant="contained">
              Change
            </Button>
            <Button variant="outlined" color="error" sx={settingsStyles.button} onClick={handleDeleteAvatar}>
              Delete
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={settingsStyles.fieldWrapper}>
        <Typography sx={settingsStyles.titleField}>Full Name</Typography>
        <Box sx={settingsStyles.inputWrapper}>
          <Field component={OutlinedInput} fullWidth name="fullname" sx={styles.field} />
        </Box>
      </Box>
      <Accordion
        elevation={0}
        disableGutters
        sx={styles.accordion}
        onChange={(e, expanded) => {
          setPasswordSectionLabel(expanded ? 'Current password' : 'Update password');
        }}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" sx={styles.accordionElements}>
          <Box sx={{ ...settingsStyles.fieldWrapper, padding: '8px 0' }}>
            <Typography sx={settingsStyles.titleField}>{passwordSectionLabel}</Typography>
            <Box sx={settingsStyles.inputWrapper}>
              <Field
                component={OutlinedInput}
                fullWidth
                type="currentPassword"
                name="currentPassword"
                sx={styles.field}
                onChange={() => dispatch(userSettingsActions.patchUserSettingsInfoError(null))}
              />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={styles.accordionElements}>
          <Box sx={settingsStyles.fieldWrapper}>
            <Typography sx={settingsStyles.titleField}>New password</Typography>
            {errors.newPassword && <Typography sx={styles.errorText}>{errors.newPassword}</Typography>}
            <Box sx={settingsStyles.inputWrapper}>
              <Field component={OutlinedInput} fullWidth type="newPassword" name="newPassword" sx={styles.field} />
            </Box>
          </Box>
          <Box sx={settingsStyles.fieldWrapper}>
            <Typography sx={settingsStyles.titleField}>Confirm new password</Typography>
            {errors.newPasswordConfirm && <Typography sx={styles.errorText}>{errors.newPasswordConfirm}</Typography>}
            <Box sx={settingsStyles.inputWrapper}>
              <Field
                component={OutlinedInput}
                fullWidth
                type="newPasswordConfirm"
                name="newPasswordConfirm"
                sx={styles.field}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      {patchError && (
        <Typography sx={styles.errorText}>
          {(patchError as AxiosError<{ message: string }>).response.data.message}
        </Typography>
      )}
    </>
  );
}
