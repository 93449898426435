import { Action } from 'common/interfaces/root';
import { publishersSettingsActionTypes as T } from '../actions';

const initState: UrlsModule.UrlsListStore = {
  loading: true,
  error: null,
  data: null,
  page: 0,
  amount: 0,
};

export default (state: UrlsModule.UrlsListStore = initState, { type, payload }: Action): UrlsModule.UrlsListStore => {
  switch (type) {
    case T.GET_PUBLISHER_URLS:
      return { ...state, loading: true };

    case T.GET_PUBLISHER_URLS_SUCCESS:
      return { ...state, loading: false, data: payload.urls, amount: payload.count };

    case T.GET_PUBLISHER_URLS_ERROR:
      return { ...state, loading: false, error: payload };

    case T.CHANGE_PUBLISHER_URLS_PAGE:
      return { ...state, page: payload };

    default:
      return state;
  }
};
