import { ReactElement, useEffect } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { useNavigate, useParams } from 'react-router-dom';
import { JOBS_NAV } from './constants';
import { Crawling } from './components';

export const getStyles = (): Styles => ({
  content: {
    marginTop: '50px',
    backgroundColor: '#ffffff',
    width: '100%',
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 5%)',
    boxSizing: 'border-box',
  },
  steps: {
    padding: '28px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    width: '100%',
    boxSizing: 'border-box',
    padding: '20px 0 0 0',
    ':first-child': { padding: 0 },
  },
  tabs: {
    borderBottom: '1px solid #C2DEEB40',
  },
  tab: {
    boxSizing: 'border-box',
    padding: '25px 16px',
    ':first-child': { borderRadius: '10px 0 0 0' },
  },
});

export function Jobs(): ReactElement {
  const styles = getStyles();
  const navigate = useNavigate();
  const { nav } = useParams<{ nav: string }>();

  const handleChangePath = (e: React.SyntheticEvent<Element, Event>, value: JOBS_NAV) => {
    navigate(`/jobs/${value}`);
  };

  const handleStepRender = () => {
    switch (nav) {
      case JOBS_NAV.crawling:
        return <Crawling />;

      default:
        return <Crawling />;
    }
  };

  useEffect(() => {
    const foundCurrentStep = Object.values(JOBS_NAV).includes(nav as JOBS_NAV);

    if (foundCurrentStep) return;

    navigate(`/jobs/${JOBS_NAV.crawling}`);
  }, [nav, navigate]);

  return (
    <>
      <Typography variant="h4">Jobs</Typography>
      <Box sx={styles.content}>
        <Tabs sx={styles.tabs} value={nav} onChange={handleChangePath}>
          <Tab sx={styles.tab} label="Crawling" value={JOBS_NAV.crawling} />
        </Tabs>
        <Box sx={styles.steps}>{handleStepRender()}</Box>
      </Box>
    </>
  );
}
