import { Box, Button, CircularProgress, MenuItem, TextField, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseFilters } from 'common/ui/base-filters';
import { filtersActions } from 'common/store/actions/filters';
import { Moment } from 'moment';
import { useDebouncedCallback } from 'use-debounce';
import { Portal } from 'common/ui/portal';
import HeaderImg from 'common/svg/header-img.png';
import { MavPlusTable } from './components';
import {
  selectPubMavPerformanceExportLoading,
  selectPubMaverickTableLimit,
  selectPubMaverickTablePage,
} from './store/selectors';
import { mavPlusActions, pubMaverickTableAction } from './store/actions';
import { MavBreakdowns, dailyOptions, sourceOptions, stockOptions } from './constants';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', height: '100%' },
  headTitle: {
    color: '#ffffff',
    fontWeight: 600,
  },
  head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: `url(${HeaderImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#4288F0',
    padding: '28px',
    boxSizing: 'border-box',
    color: '#ffffff',
    fontWeight: 600,
  },
  filters: { display: 'flex', alignItems: 'center', gap: 1, flexGrow: 1, justifyContent: 'flex-end' },
  exportBttn: { marginLeft: 1 },
  select: {
    width: 150,
    marginRight: 1,
    color: '#fff',
    '&:before': {
      borderColor: '#fff',
    },
    '&:after': {
      borderColor: '#fff',
    },
    '& .MuiInputBase-input': {
      color: '#fff',
    },
    '& .MuiSelect-icon': {
      color: '#fff',
    },
    '& .MuiFormLabel-root': {
      color: '#fff',
    },
    '& .MuiInputLabel-root.Mui-focused': { color: '#fff' },
    '& .MuiFormControl-root': {
      color: '#fff',
    },
    '& .MuiOutlinedInput-notchedOutline ': { borderColor: '#fff' },
  },
};

export function MavPlus(): ReactElement {
  const dispatch = useDispatch();

  const globalFilters = useSelector(selectGlobalFilters);
  const page = useSelector(selectPubMaverickTablePage);
  const limit = useSelector(selectPubMaverickTableLimit);
  const pubMavPerformanceExportLoading = useSelector(selectPubMavPerformanceExportLoading);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [breakdown, setBreakdown] = useState<string>(MavBreakdowns.ASIN);
  const [selectedDaily, setSelectedDaily] = useState<number>(1);
  const [inStockFilter, setInStockFilter] = useState<number>(-1);
  const [adOnlySourceFilter, setAdOnlySourceFilter] = useState<number>(-1);

  const dispatchGetPubMavPerformanceSummary = () => {
    dispatch(
      mavPlusActions.getPubMavPerformanceSummary({
        start_date: globalFilters.date_from,
        end_date: globalFilters.date_to,
        limit,
        offset: limit * page,
        breakdown,
        ...(breakdown === MavBreakdowns.ASIN ? { publisher_id: globalFilters.publisher_id } : {}),
        ...(breakdown === MavBreakdowns.ASIN ? { daily: Boolean(selectedDaily) } : {}),
        ...(inStockFilter !== -1 && { in_stock: inStockFilter ? 'true' : 'false' }),
        ...(adOnlySourceFilter !== -1 && { ad_only: adOnlySourceFilter ? 'true' : 'false' }),
      })
    );
  };

  const handlePerformanceExportPopoverOpen = () => {
    dispatch(
      mavPlusActions.getPubMavPerformanceExport({
        start_date: globalFilters.date_from,
        end_date: globalFilters.date_to,
        limit,
        offset: limit * page,
        breakdown,
        ...(breakdown === MavBreakdowns.ASIN ? { publisher_id: globalFilters.publisher_id } : {}),
        ...(breakdown === MavBreakdowns.ASIN ? { daily: Boolean(selectedDaily) } : {}),
        ...(inStockFilter !== -1 && { in_stock: inStockFilter ? 'true' : 'false' }),
        ...(adOnlySourceFilter !== -1 && { ad_only: adOnlySourceFilter ? 'true' : 'false' }),
      })
    );
  };

  const handleRangeChange = (from: Moment, to: Moment) => {
    dispatch(filtersActions.changeRange(from, to));
  };

  const handleSelectedDailyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedDaily(Number(e.target.value));
  };

  const handleChangeInStock = (e: ChangeEvent<HTMLInputElement>) => {
    setInStockFilter(Number(e.target.value));
  };

  const handleChangeAdOnlyFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setAdOnlySourceFilter(Number(e.target.value));
  };

  const debouncedPubMavPerformance = useDebouncedCallback(() => {
    dispatch(
      pubMaverickTableAction.get({
        start_date: globalFilters.date_from,
        end_date: globalFilters.date_to,
        limit,
        offset: limit * page,
        breakdown,
        ...(breakdown === MavBreakdowns.ASIN ? { publisher_id: globalFilters.publisher_id } : {}),
        ...(breakdown === MavBreakdowns.ASIN ? { daily: Boolean(selectedDaily) } : {}),
        ...(inStockFilter !== -1 && { in_stock: inStockFilter ? 'true' : 'false' }),
        ...(adOnlySourceFilter !== -1 && { ad_only: adOnlySourceFilter ? 'true' : 'false' }),
      })
    );
  }, 500);

  useEffect(() => {
    debouncedPubMavPerformance();
    dispatchGetPubMavPerformanceSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    globalFilters.date_from,
    globalFilters.date_to,
    limit,
    page,
    breakdown,
    selectedDaily,
    inStockFilter,
    adOnlySourceFilter,
  ]);

  // useEffect(() => {
  //   debouncedPubMavPerformance();
  //   dispatchGetPubMavPerformanceSummary();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Box sx={styles.container}>
      <Portal id="header-page">
        <Box sx={styles.head}>
          <Typography sx={styles.headTitle}>Mav+</Typography>
          <Box sx={styles.filters}>
            {breakdown === MavBreakdowns.ASIN ? (
              <>
                <TextField
                  sx={styles.select}
                  size="small"
                  label="Aggregate"
                  disabled={breakdown !== MavBreakdowns.ASIN}
                  value={selectedDaily ?? ''}
                  onChange={handleSelectedDailyChange}
                  focused={false}
                  select
                >
                  {dailyOptions.map(o => (
                    <MenuItem key={o.value} value={o.value}>
                      {o.title}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  size="small"
                  label="In stock"
                  value={inStockFilter}
                  onChange={handleChangeInStock}
                  sx={styles.select}
                  focused={false}
                  select
                >
                  {stockOptions.map(o => (
                    <MenuItem key={o.value} value={o.value}>
                      {o.title}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  size="small"
                  label="Source"
                  value={adOnlySourceFilter}
                  onChange={handleChangeAdOnlyFilter}
                  sx={styles.select}
                  select
                >
                  {sourceOptions.map(o => (
                    <MenuItem key={o.value} value={o.value}>
                      {o.title}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            ) : null}
            <BaseFilters
              show={['date']}
              handleRangeChange={handleRangeChange}
              date={{ from: globalFilters.date_from, to: globalFilters.date_to }}
            />
          </Box>
          <Button sx={styles.exportBttn} variant="contained" onClick={handlePerformanceExportPopoverOpen}>
            {pubMavPerformanceExportLoading ? (
              <CircularProgress size={20} sx={styles.exportLoading} />
            ) : (
              <FileDownloadIcon fontSize="small" />
            )}
            <Typography sx={styles.exportText}>Export CSV</Typography>
          </Button>
        </Box>
      </Portal>
      <MavPlusTable breakdown={breakdown} selectedDaily={selectedDaily} />
    </Box>
  );
}
