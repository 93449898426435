import { ApiService } from 'common/services';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { parseFilters } from 'common/utils/parse-filters';

class RevenueByPageApiService extends ApiService {
  getArticles = (filters: IStatisticsQueryString) => {
    return this.get('/api/statistics/revenue', { params: parseFilters(filters) });
  };

  getAsins = ({ publisher_id, article_id }: { publisher_id: number; article_id: number }) => {
    return this.get(`/api/publishers/${publisher_id}/urls/${article_id}/asins`);
  };

  getTop10Pages = (filters: IStatisticsQueryString) => {
    return this.get('/api/statistics/revenue/top-charts', { params: parseFilters(filters) });
  };

  exportArticles = (filters: IStatisticsQueryString) => {
    return this.get('/api/export/statistics/revenue', { params: parseFilters(filters) });
  };

  exportTop10Pages = (filters: IStatisticsQueryString) => {
    return this.get('/api/export/statistics/revenue/top-charts', { params: parseFilters(filters) });
  };

  getExportStatus = (id: string) => {
    return this.get(`/api/export/${id}/status`);
  };
}

export const revenueByPageApi = new RevenueByPageApiService(process.env.REACT_APP_API_URL);
