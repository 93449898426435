import { Box, CircularProgress, Typography } from '@mui/material';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { SearchInput } from 'common/ui/search-input';
import { contentOverviewActions } from 'modules/content-overview/store/actions';
import {
  selectRecommendationsData,
  selectRecommendationAsins,
  selectRecommendationsLoading,
} from 'modules/content-overview/store/selectors';
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { ProductSuggestion } from './components/product';

const styles: Styles = {
  button: { maxWidth: 100, width: '100%', marginLeft: 1, ':first-child': { marginLeft: 0 } },
  buttons: { marginTop: 2, display: 'flex', justifyContent: 'flex-end' },
  spinnerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '200px',
  },
  showMore: {
    display: 'block',
    margin: '10px 0 0 20px',
    color: '#4288F0',
    fontSize: 10,
    cursor: 'pointer',
    width: 'fit-content',
  },
  searchForm: {
    p: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '5px',
  },
  searchIcon: {
    p: '10px',
  },
  searchInput: {
    ml: 1,
    flex: 1,
  },
};

export function SearchSuggestions(): ReactElement {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState<string>('');

  const rows = useSelector(selectRecommendationsData);
  const asins = useSelector(selectRecommendationAsins);
  const loading = useSelector(selectRecommendationsLoading);
  const globalFilters = useSelector(selectGlobalFilters);

  const debouncedSearch = useDebouncedCallback((value: string) => {
    dispatch(
      contentOverviewActions.getProductRecommendations({
        publisherId: globalFilters.publisher_id,
        asins,
        search: value,
      })
    );
  }, 500);

  const handleChangeSearch = (search: string) => {
    setSearchValue(search);
    debouncedSearch(search);
  };

  return (
    <>
      <Box sx={styles.head}>
        <Typography variant="h4">ASIN suggestion</Typography>
      </Box>
      <Box sx={{ margin: '16px auto', width: '40%' }}>
        <SearchInput search={searchValue} handleSearchChange={handleChangeSearch} fullWidth />
      </Box>
      {!loading && searchValue ? (
        <>
          <Box sx={{ margin: '16px auto', width: 'fit-content' }}>
            <Typography variant="h6">Suggested Products for Description</Typography>
          </Box>
          {rows?.map(row => (
            <Box sx={{ marginBottom: '16px' }}>
              <ProductSuggestion key={row?.product?.asin} product={row?.product} />
            </Box>
          ))}
        </>
      ) : (
        searchValue && (
          <Box sx={styles.spinnerWrapper}>
            <CircularProgress color="primary" />
          </Box>
        )
      )}
    </>
  );
}
