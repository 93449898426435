import { useAuth0 } from '@auth0/auth0-react';
import { authApi } from 'common/services/auth-api.service';
import { userService } from 'common/services/user.service';
import { getReduxStore } from 'common/store';
import { ReactElement, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { LoadingContainer } from 'root/layout/loading-container';
import { UnknownUserContainer } from 'root/layout/unknown-user-container';

interface Props {
  children: ReactElement;
}

export function InternalAuthProvider({ children }: Props): JSX.Element {
  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, user } = useAuth0();

  const [tokenLoading, setTokenLoading] = useState<boolean>(true);
  const [permissionsLoading, setPermissionsLoading] = useState<boolean>(true);
  const [isUnknownUser, setIsUnknownUser] = useState<boolean>(false);

  useEffect(() => {
    if (!user) return;

    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        localStorage.setItem('token', accessToken);
        setTokenLoading(false);
      } catch (e) {
        loginWithRedirect({ redirectUri: window.location.origin });
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, loginWithRedirect, user]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) loginWithRedirect({ redirectUri: window.location.origin });
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    if (isLoading || !isAuthenticated || tokenLoading) return;

    const initialize = async () => {
      try {
        const { data } = await authApi.getMe();
        userService.setPermissions(data.permissions);
        userService.setUser(data.user);
        setPermissionsLoading(false);
      } catch (e) {
        setIsUnknownUser(true);
        setPermissionsLoading(false);
      }
    };
    initialize();
  }, [isAuthenticated, isLoading, tokenLoading]);

  if (isUnknownUser && !isLoading) return <UnknownUserContainer />;

  if (tokenLoading || permissionsLoading || isLoading) return <LoadingContainer />;

  return <Provider store={getReduxStore()}>{children}</Provider>;
}
