import { Autocomplete, Box, Button, Chip, MenuItem, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import { ChangeEvent, ReactElement, SyntheticEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { asinRecommendationActions } from 'modules/asin-recommendation/store/actions/actions';
import { asinRecommendationSelectors } from 'modules/asin-recommendation/store/selectors';
import { usePermission } from 'common/hooks';
import { PERMISSIONS } from 'common/constants/permissions';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 1.5 },
  fieldContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  minMaxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '70%',
    gap: 1.5,
  },
  field: { width: '70%' },
  btnContainer: { display: 'flex', justifyContent: 'flex-end', gap: 1.5 },
};

interface Props {
  isOpen: boolean;
  type?: 'grid' | 'list';
  handleApply: () => void;
  handleReset: () => void;
  onClose: () => void;
}

export function FiltersModal({ isOpen, type, onClose, handleApply, handleReset }: Props): ReactElement {
  const dispatch = useDispatch();

  const admin = usePermission(PERMISSIONS.GET_PUBLISHERS);

  const categories = useSelector(asinRecommendationSelectors.selectCategories);
  const brands = useSelector(asinRecommendationSelectors.selectBrands);
  const types = useSelector(asinRecommendationSelectors.selectTypes);
  const filters = useSelector(asinRecommendationSelectors.selectFilters);

  const handleCategoryChange = (event: SyntheticEvent<Element, Event>, value: string[]) => {
    dispatch(asinRecommendationActions.filters.setCategory(value));
  };

  const handleBrandChange = (event: SyntheticEvent<Element, Event>, value: string[]) => {
    dispatch(asinRecommendationActions.filters.setBrand(value));
  };

  const handleTypeChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(asinRecommendationActions.filters.setType(e.target.value));
  };

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(asinRecommendationActions.filters.setStatus(e.target.value));
  };

  const handleSeasonalityChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(asinRecommendationActions.filters.setSeasonality(e.target.value));
  };

  const handleInStockChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(asinRecommendationActions.filters.setInStock(e.target.value));
  };

  const handlePriceMinChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(asinRecommendationActions.filters.setPriceMin(e.target.value));
  };

  const handlePriceMaxChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(asinRecommendationActions.filters.setPriceMax(e.target.value));
  };

  const handleEarnMinChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(asinRecommendationActions.filters.setEarnMin(e.target.value));
  };

  const handleEarnMaxChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(asinRecommendationActions.filters.setEarnMax(e.target.value));
  };

  useEffect(() => {
    dispatch(asinRecommendationActions.filters.resetFilters());
    handleApply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Modal open={isOpen} onClose={onClose} title="Filters" maxWidth="sm">
      <Box sx={styles.container}>
        <Box sx={styles.fieldContainer}>
          <Typography>Categories</Typography>
          <Autocomplete
            sx={styles.field}
            multiple
            options={categories?.data}
            size="small"
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} size="small" />
              ))
            }
            renderInput={params => (
              <TextField {...params} variant="outlined" label="Categories" placeholder="Categories" />
            )}
            onChange={handleCategoryChange}
            value={filters.category}
          />
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Brand</Typography>
          <Autocomplete
            sx={styles.field}
            multiple
            options={brands?.data}
            size="small"
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} size="small" />
              ))
            }
            renderInput={params => <TextField {...params} variant="outlined" label="Brands" placeholder="Brands" />}
            onChange={handleBrandChange}
            value={filters.brand}
          />
        </Box>
        {admin && (
          <Box sx={styles.fieldContainer}>
            <Typography>Product Type</Typography>
            <TextField
              select
              color="primary"
              sx={styles.field}
              value={filters.type}
              size="small"
              label="Types"
              onChange={handleTypeChange}
            >
              <MenuItem value="all">All</MenuItem>
              {types?.data?.map(type => (
                <MenuItem key={type} value={type}>
                  {type === 'sr_products' ? 'SR' : 'Attribution'}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
        <Box sx={styles.fieldContainer}>
          <Typography>Status</Typography>
          <TextField
            select
            color="primary"
            sx={styles.field}
            value={filters.status}
            size="small"
            label="Status"
            onChange={handleStatusChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="active">In article</MenuItem>
            <MenuItem value="inactive">Not in article</MenuItem>
          </TextField>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Seasonality</Typography>
          <TextField
            select
            color="primary"
            sx={styles.field}
            value={filters.seasons}
            size="small"
            label="Seasonality"
            onChange={handleSeasonalityChange}
          >
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="current_month">Current Month</MenuItem>
            <MenuItem value="next_month">Next Month</MenuItem>
            <MenuItem value="next_three_months">Next Three Months</MenuItem>
            <MenuItem value="next_six_months">Next Six Months</MenuItem>
          </TextField>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>In Stock</Typography>
          <TextField
            select
            color="primary"
            sx={styles.field}
            value={filters.in_stock}
            size="small"
            label="In Stock"
            onChange={handleInStockChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="true">In Stock</MenuItem>
            <MenuItem value="false">Out of Stock</MenuItem>
          </TextField>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Product Price</Typography>
          <Box sx={styles.minMaxContainer}>
            <TextField
              label="Product price min %"
              type="number"
              size="small"
              value={filters.price_min || 0}
              InputLabelProps={{ shrink: true }}
              onChange={handlePriceMinChange}
            />
            <TextField
              label="Product price max %"
              type="number"
              size="small"
              value={filters.price_max || 0}
              InputLabelProps={{ shrink: true }}
              onChange={handlePriceMaxChange}
            />
          </Box>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Opportunity Earn %</Typography>
          <Box sx={styles.minMaxContainer}>
            <TextField
              label="Opportunity earn min %"
              type="number"
              size="small"
              value={filters.earn_min || 0}
              InputLabelProps={{ shrink: true }}
              onChange={handleEarnMinChange}
            />
            <TextField
              label="Opportunity earn max %"
              type="number"
              size="small"
              value={filters.earn_max || 0}
              InputLabelProps={{ shrink: true }}
              onChange={handleEarnMaxChange}
            />
          </Box>
        </Box>
        <Box sx={styles.btnContainer}>
          <Button variant="outlined" onClick={handleReset}>
            Reset
          </Button>
          <Button variant="contained" onClick={handleApply}>
            Apply
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
