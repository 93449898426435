const PUBLISHERS_BRAND_COMMISSIONS_MODULE = '[publishers:brand-commissions]';

const GET_PUBLISHER_BRAND_COMMISSIONS = `${PUBLISHERS_BRAND_COMMISSIONS_MODULE} GET_PUBLISHER_INFO`;
const GET_PUBLISHER_BRAND_COMMISSIONS_SUCCESS = `${PUBLISHERS_BRAND_COMMISSIONS_MODULE} GET_PUBLISHER_INFO_SUCCESS`;
const GET_PUBLISHER_BRAND_COMMISSIONS_ERROR = `${PUBLISHERS_BRAND_COMMISSIONS_MODULE} GET_PUBLISHER_INFO_ERROR`;

const ADD_PUBLISHER_BRAND_COMMISSION = `${PUBLISHERS_BRAND_COMMISSIONS_MODULE} ADD_BRAND_COMMISSION`;
const ADD_PUBLISHER_BRAND_COMMISSION_SUCCESS = `${PUBLISHERS_BRAND_COMMISSIONS_MODULE} ADD_BRAND_COMMISSION_SUCCESS`;
const ADD_PUBLISHER_BRAND_COMMISSION_ERROR = `${PUBLISHERS_BRAND_COMMISSIONS_MODULE} ADD_BRAND_COMMISSION_ERROR`;

const EDIT_PUBLISHER_BRAND_COMMISSION = `${PUBLISHERS_BRAND_COMMISSIONS_MODULE} EDIT_BRAND_COMMISSION`;
const EDIT_PUBLISHER_BRAND_COMMISSION_SUCCESS = `${PUBLISHERS_BRAND_COMMISSIONS_MODULE} EDIT_BRAND_COMMISSION_SUCCESS`;
const EDIT_PUBLISHER_BRAND_COMMISSION_ERROR = `${PUBLISHERS_BRAND_COMMISSIONS_MODULE} EDIT_BRAND_COMMISSION_ERROR`;

const DELETE_PUBLISHER_BRAND_COMMISSION = `${PUBLISHERS_BRAND_COMMISSIONS_MODULE} DELETE_BRAND_COMMISSION`;
const DELETE_PUBLISHER_BRAND_COMMISSION_SUCCESS = `${PUBLISHERS_BRAND_COMMISSIONS_MODULE} DELETE_BRAND_COMMISSION_SUCCESS`;
const DELETE_PUBLISHER_BRAND_COMMISSION_ERROR = `${PUBLISHERS_BRAND_COMMISSIONS_MODULE} DELETE_BRAND_COMMISSION_ERROR`;

export const publisherBrandCommissionsActionTypes = {
  GET_PUBLISHER_BRAND_COMMISSIONS,
  GET_PUBLISHER_BRAND_COMMISSIONS_SUCCESS,
  GET_PUBLISHER_BRAND_COMMISSIONS_ERROR,
  ADD_PUBLISHER_BRAND_COMMISSION,
  ADD_PUBLISHER_BRAND_COMMISSION_SUCCESS,
  ADD_PUBLISHER_BRAND_COMMISSION_ERROR,
  EDIT_PUBLISHER_BRAND_COMMISSION,
  EDIT_PUBLISHER_BRAND_COMMISSION_SUCCESS,
  EDIT_PUBLISHER_BRAND_COMMISSION_ERROR,
  DELETE_PUBLISHER_BRAND_COMMISSION,
  DELETE_PUBLISHER_BRAND_COMMISSION_SUCCESS,
  DELETE_PUBLISHER_BRAND_COMMISSION_ERROR,
};
