import { Action } from 'common/interfaces/root';
import { actionTypes } from '../actions/action-types';

const initialState: Conversions.Exp.ExportState = {
  loading: false,
  error: null,
  data: null,
};

export default (
  state: Conversions.Exp.ExportState = initialState,
  { type, payload }: Action
): Conversions.Exp.ExportState => {
  switch (type) {
    case actionTypes.export.GET_EXPORT:
      return { ...state, loading: true };
    case actionTypes.export.GET_EXPORT_SUCCESS:
      return { ...state, loading: false, data: payload };
    case actionTypes.export.GET_EXPORT_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
