import { Action } from 'common/interfaces/root';
import { contentOverviewActionTypes as T } from '../actions';

const initState: ContentOverviewModule.ReportStore = {
  execution_id: null,
  loading: false,
  isGenerating: false,
  error: null,
};

export default (
  state: ContentOverviewModule.ReportStore = initState,
  { type, payload }: Action
): ContentOverviewModule.ReportStore => {
  switch (type) {
    case T.GENERATE_REPORT:
      return { ...state, isGenerating: true, loading: true };

    case T.GENERATE_REPORT_SUCCESS:
      return { ...state, isGenerating: false, execution_id: payload };

    case T.GENERATE_REPORT_ERROR:
      return { ...state, isGenerating: false, loading: false, error: payload };

    case T.SUCCESS_REPORT_LINK:
      return { ...state, loading: false };

    case T.REMOVE_EXECUTION_ID:
      return { ...state, isGenerating: false, execution_id: null };

    default:
      return state;
  }
};
