import { actionTypes } from './action-types';

const getReports = (params: Conversions.Reports.ReportsParams) => ({
  type: actionTypes.report.GET_REPORTS,
  payload: params,
});

const getReportsSuccess = (data: Conversions.Reports.ReportsData[]) => ({
  type: actionTypes.report.GET_REPORTS_SUCCESS,
  payload: data,
});

const getReportsError = (error: Error) => ({
  type: actionTypes.report.GET_REPORTS_ERROR,
  payload: error,
});

export const reportsActions = {
  getReports,
  getReportsSuccess,
  getReportsError,
};
