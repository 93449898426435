import { ReactElement } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { StatsRow } from 'modules/stats/mock';

interface Props {
  row: StatsRow;
}

export function StatsTableRow({ row }: Props): ReactElement {
  return (
    <TableRow>
      <TableCell>{row.keyword}</TableCell>
      <TableCell>{row.status}</TableCell>
      <TableCell>{row.impressions}</TableCell>
      <TableCell>{row.clicks}</TableCell>
      <TableCell>{row.cost}</TableCell>
      <TableCell>{row.averageCpc}</TableCell>
      <TableCell>{row.addToCards}</TableCell>
      <TableCell>{row.conversion}</TableCell>
      <TableCell>{row.revenue}</TableCell>
      <TableCell>{row.roas}</TableCell>
      <TableCell>{row.acos}</TableCell>
    </TableRow>
  );
}
