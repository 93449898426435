import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import { asinRecommendationService } from 'modules/asin-recommendation/service/asin-recommendation-service';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { asinRecommendationActionTypes } from '../actions/action-types';
import { asinRecommendationActions } from '../actions/actions';

function* getAsinsSaga({ payload }: Action) {
  try {
    const { data } = yield call(asinRecommendationService.getAsins, payload);

    yield put(asinRecommendationActions.asins.getAsinsSuccess(data));
  } catch (error) {
    yield put(asinRecommendationActions.asins.getAsinsError(error as Error));
  }
}

function* getFeaturedAsinsSaga({ payload }: Action) {
  try {
    const { data } = yield call(asinRecommendationService.getAsins, payload);

    yield put(asinRecommendationActions.featuredAsins.getFeaturedAsinsSuccess(data));
  } catch (error) {
    yield put(asinRecommendationActions.featuredAsins.getFeaturedAsinsError(error as Error));
  }
}

function* getPlacementsSaga({ payload }: Action) {
  try {
    const { data } = yield call(asinRecommendationService.getPlacements, payload);

    yield put(asinRecommendationActions.placements.getPlacementsSuccess(data));
  } catch (error) {
    yield put(asinRecommendationActions.placements.getPlacementsError(error as Error));
  }
}

function* getAsinRecommendationExportSaga({ payload }: Action) {
  try {
    const { data } = yield call(asinRecommendationService.getAsinRecommendationExport, payload);
    window.open(data.link);
    yield put(asinRecommendationActions.export.getExportSuccess(data.link));
  } catch (error) {
    const msg: string = error instanceof AxiosError ? error.response?.data?.message : (error as string);
    toast.error(msg);

    yield put(asinRecommendationActions.export.getExportError(error as Error));
  }
}

function* getCategoriesSaga() {
  try {
    const { data } = yield call(asinRecommendationService.getCategories);

    yield put(asinRecommendationActions.categories.getCategoriesSuccess(data));
  } catch (error) {
    yield put(asinRecommendationActions.categories.getCategoriesError(error as Error));
  }
}

function* getBrandsSaga() {
  try {
    const { data } = yield call(asinRecommendationService.getBrands);

    yield put(asinRecommendationActions.brands.getBrandsSuccess(data));
  } catch (error) {
    yield put(asinRecommendationActions.brands.getBrandsError(error as Error));
  }
}

function* getTypesSaga() {
  try {
    const { data } = yield call(asinRecommendationService.getTypes);

    yield put(asinRecommendationActions.types.getTypesSuccess(data));
  } catch (error) {
    yield put(asinRecommendationActions.types.getTypesError(error as Error));
  }
}

function* getVariationsSaga({ payload }: Action) {
  try {
    const { data } = yield call(asinRecommendationService.getVariations, payload);

    yield put(asinRecommendationActions.variations.getVariationsSuccess(data));
  } catch (error) {
    yield put(asinRecommendationActions.variations.getVariationsError(error as Error));
  }
}

function* generateAttrTag({ payload, meta }: Action) {
  try {
    const { data } = yield call(asinRecommendationService.generateTag, payload.publisherId, payload.asin, payload.url);
    if (!data.tag) throw new Error();
    navigator.clipboard.writeText(data.tag);
    meta?.onSuccess(data.tag);
    toast.success('Attribution tag copied to the clipboard!');
    yield put(asinRecommendationActions.attrTag.generateAttrTagSuccess());
  } catch (err) {
    toast.error('ASIN doesn’t exist');
    yield put(asinRecommendationActions.attrTag.generateAttrTagError());
  }
}

export function* asinRecommendationSaga(): SagaIterator {
  yield all([
    takeLatest(asinRecommendationActionTypes.ASINS.GET_ASINS, getAsinsSaga),
    takeLatest(asinRecommendationActionTypes.ASINS.GET_FEATURED_ASINS, getFeaturedAsinsSaga),
    takeLatest(asinRecommendationActionTypes.PLACEMENTS.GET_PLACEMENTS, getPlacementsSaga),
    takeLatest(asinRecommendationActionTypes.EXPORT.GET_EXPORT, getAsinRecommendationExportSaga),
    takeLatest(asinRecommendationActionTypes.CATEGORIES.GET_CATEGORIES, getCategoriesSaga),
    takeLatest(asinRecommendationActionTypes.BRANDS.GET_BRANDS, getBrandsSaga),
    takeLatest(asinRecommendationActionTypes.TYPES.GET_TYPES, getTypesSaga),
    takeLatest(asinRecommendationActionTypes.VARIATIONS.GET_VARIATIONS, getVariationsSaga),
    takeLatest(asinRecommendationActionTypes.ATTR_TAG.GENERATE_ATTR_TAG, generateAttrTag),
  ]);
}
