import { ReactElement } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import { selectPublisherInfo, selectPublisherInfoLoading } from 'modules/settings/store/selectors';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { Styles } from 'common/types';
import { selectGlobalFilters } from 'common/store/selectors';
import { MainSettingsContent } from './main-settings-content';

const getStyles = (): Styles => ({
  spinnerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '200px',
  },
});

const validationSchema = Yup.object({
  name: Yup.string().required().max(255),
  domain: Yup.string().max(100),
});

const validateCategories = (pubCategories: string[], newCategories: string[]): boolean => {
  if (pubCategories.length === 0 && newCategories.length === 0) return true;
  if (pubCategories.length !== newCategories.length) return false;

  let isEqual = true;

  pubCategories.forEach(cat => {
    isEqual = newCategories.includes(cat);
  });

  return isEqual;
};

export function MainSettings(): ReactElement {
  const dispatch = useDispatch();
  const styles = getStyles();

  const publisher = useSelector(selectPublisherInfo);
  const loading = useSelector(selectPublisherInfoLoading);
  const filters = useSelector(selectGlobalFilters);

  const id = String(filters?.publisher_id);

  const getInitialValues = (): PublishersSettingsModule.MainPublisherSettingsForm => ({
    logo: publisher?.logo ?? '',
    name: publisher?.name ?? '',
    timezone: publisher?.timezone ?? '',
    domain: publisher?.domain ?? '',
    publisher_categories: publisher?.publisher_categories ?? [],
  });

  const handleRefreshInfo = () => {
    dispatch(publishersSettingsActions.getMainPublisherInfo(id));
  };

  const handleSubmit = (values: PublishersSettingsModule.MainPublisherSettingsForm) => {
    const isNameChanged = publisher?.name !== values.name;
    const isDomainChanged = publisher?.domain !== values.domain;

    dispatch(
      publishersSettingsActions.patchMainPublisherInfo(
        { ...values, id, isNameChanged, isDomainChanged },
        handleRefreshInfo
      )
    );

    if (!validateCategories(publisher?.publisher_categories, values.publisher_categories))
      dispatch(
        publishersSettingsActions.patchMainPublisherCategories(
          { publisher_categories: values.publisher_categories, id },
          handleRefreshInfo
        )
      );
  };

  return loading ? (
    <Box sx={styles.spinnerWrapper}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <Formik initialValues={getInitialValues()} onSubmit={handleSubmit} validationSchema={validationSchema}>
      <MainSettingsContent />
    </Formik>
  );
}
