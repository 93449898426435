import { MenuItem, TextField } from '@mui/material';
import { selectFiltersPresets } from 'common/store/selectors';
import { Styles } from 'common/types';
import { ChangeEvent, ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';

const styles: Styles = {
  field: { width: '70%' },
};

const NARRATIVE_NETWORK = 4;

interface Props {
  affiliateNetworkType: number | string;
  onChange: (id: number | string) => void;
}

export function AffiliateNetworkTypePicker({ affiliateNetworkType, onChange }: Props): ReactElement {
  const filtersPresets = useSelector(selectFiltersPresets);

  const options = useMemo(
    () =>
      [
        { title: 'Combined', value: -1 },
        ...(filtersPresets?.affiliateNetworks || []).map(network => ({
          title: network.name,
          value: network.id,
        })),
      ].filter(option => Number(option.value) !== NARRATIVE_NETWORK),
    [filtersPresets]
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <TextField
      select
      color="primary"
      sx={styles.field}
      onChange={handleChange}
      value={affiliateNetworkType}
      size="small"
      label="Network type"
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.title}
        </MenuItem>
      ))}
    </TextField>
  );
}
