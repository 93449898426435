import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';
import { Moment } from 'moment';
import { Styles } from 'common/types';
import { Portal } from 'common/ui/portal';
import HeaderImg from 'common/svg/header-img.png';
import { SearchInput } from 'common/ui/search-input';
import { FilterButton } from 'common/ui/filter-button';
import { BaseFilters } from 'common/ui/base-filters';
import { DownloadButton } from 'common/ui/download-button';
import { selectGlobalFilters } from 'common/store/selectors';
import { filtersActions } from 'common/store/actions/filters';

const styles: Styles = {
  head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: `url(${HeaderImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#4288F0',
    padding: '28px',
    boxSizing: 'border-box',
    color: '#ffffff',
    fontWeight: 600,
  },
  headTitle: {
    color: '#ffffff',
    fontWeight: 600,
  },
  filtersContainer: { display: 'flex', alignItems: 'center', gap: 1.5 },
};

interface BannerOptions {
  search?: boolean;
  filters?: boolean;
  dateRange?: boolean;
  exp?: boolean;
}

interface Props {
  title?: string;
  options?: BannerOptions;
  exportLoading?: boolean;
  searchValue?: string;
  handleFiltersOpen?: () => void;
  handleExport?: () => void;
  handleSearchChange?: (search: string) => void;
}

export function Banner({
  title,
  options,
  exportLoading,
  searchValue,
  handleFiltersOpen,
  handleExport,
  handleSearchChange,
}: Props): ReactElement {
  const dispatch = useDispatch();

  const globalFilters = useSelector(selectGlobalFilters);

  const handleGlobalSearchChange = (value: string) => {
    if (handleSearchChange) {
      handleSearchChange(value);
    } else {
      dispatch(filtersActions.changeSearch(value));
    }
  };

  const handleRangeChange = (from: Moment, to: Moment) => {
    dispatch(filtersActions.changeRange(from, to));
  };

  return (
    <Portal id="header-page">
      <Box sx={styles.head}>
        <Typography sx={styles.headTitle}>{title}</Typography>
        <Box sx={styles.filtersContainer}>
          {options?.search && (
            <SearchInput search={searchValue || globalFilters.search} handleSearchChange={handleGlobalSearchChange} />
          )}
          {options?.filters && <FilterButton handleFiltersOpen={handleFiltersOpen} color="light" />}
          {options?.dateRange && (
            <BaseFilters
              date={{ from: globalFilters.date_from, to: globalFilters.date_to }}
              handleRangeChange={handleRangeChange}
              show={['date']}
            />
          )}
          {options?.exp && <DownloadButton handleDownload={handleExport} loading={exportLoading} color="light" />}
        </Box>
      </Box>
    </Portal>
  );
}
