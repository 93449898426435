import { Box } from '@mui/material';
import { MENU_WIDTH_XL } from 'common/constants/sizes';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from './menu';

interface StylesProps {
  menuOpen: boolean;
}

const getStyles = ({ menuOpen }: StylesProps): Styles => ({
  container: { display: 'flex', height: '100%', backgroundColor: theme.palette.background.default },
  content: { flexGrow: 1, boxSizing: 'border-box', paddingLeft: menuOpen ? `${MENU_WIDTH_XL}px` : 0 },
  headerContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    boxSizing: 'border-box',
    transition: 'padding-left 0.225s ease-in-out',
    width: '100%',
  },
  contentContainer: {
    minHeight: 'calc(100vh - 80px)',
    padding: '30px 40px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  loadingContainer: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props {
  children: ReactNode;
}

export function Layout({ children }: Props): ReactElement {
  const location = useLocation();

  const topAnchorRef = useRef<HTMLDivElement>();

  const [menuOpen, setMenuOpen] = useState<boolean>(true);

  const styles = getStyles({ menuOpen });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleMenu = () => {
    setMenuOpen(menuOpen => !menuOpen);
  };

  useEffect(() => {
    if (!topAnchorRef.current) return;

    setTimeout(() => {
      topAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
    });
  }, [topAnchorRef, location.pathname]);

  return (
    <Box sx={styles.container}>
      <Menu open={menuOpen} />
      <Box sx={styles.content}>
        <div id="header-page" />
        <Box sx={styles.contentContainer}>{children}</Box>
      </Box>
    </Box>
  );
}
