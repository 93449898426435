import { Action } from 'common/interfaces/root';
import { asinRecommendationActionTypes as TYPES } from '../actions/action-types';

const initialState: AsinRecommendationModule.State.TableState = {
  limit: 20,
  offset: 0,
  product_sort: null,
  price_sort: null,
};

export default (
  state: AsinRecommendationModule.State.TableState = initialState,
  { type, payload }: Action
): AsinRecommendationModule.State.TableState => {
  switch (type) {
    case TYPES.TABLE.SET_LIMIT:
      return { ...state, limit: payload };
    case TYPES.TABLE.SET_OFFSET:
      return { ...state, offset: payload };
    case TYPES.TABLE.SET_PRODUCT_SORT:
      return { ...state, product_sort: payload };
    case TYPES.TABLE.SET_PRICE_SORT:
      return { ...state, price_sort: payload };

    case TYPES.TABLE.RESET_TABLE:
      return initialState;

    default:
      return state;
  }
};
