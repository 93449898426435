import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Box, CircularProgress } from '@mui/material';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { selectPublisherInfo, selectPublisherInfoLoading } from 'modules/settings/store/selectors';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { EmbedSettingsContent } from './embed-settings-content';

const getStyles = (): Styles => ({
  spinnerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '200px',
  },
});

export function EmbedSettings(): ReactElement {
  const styles = getStyles();
  const dispatch = useDispatch();

  const publisher = useSelector(selectPublisherInfo);
  const loading = useSelector(selectPublisherInfoLoading);
  const filters = useSelector(selectGlobalFilters);

  const id = String(filters?.publisher_id);

  const getInitialValues = (): PublishersSettingsModule.EmbedSettingsForm => ({
    embed: publisher?.embed ?? '',
    is_tracking_active: publisher?.is_tracking_active ?? true,
    tagging_networks: publisher?.tagging_networks ?? [],
    is_amazon_attribution: publisher?.is_amazon_attribution,
    attribution_path_regex: publisher?.attribution_path_regex ?? '',
    affiliate_path_regex: publisher?.affiliate_path_regex ?? '',
  });

  const handleRefreshInfo = () => {
    dispatch(publishersSettingsActions.getMainPublisherInfo(id));
  };

  const handleSubmit = (values: PublishersSettingsModule.EmbedSettingsForm) => {
    dispatch(
      publishersSettingsActions.patchMainPublisherInfo(
        {
          is_tracking_active: values.is_tracking_active,
          tagging_networks: values.tagging_networks,
          is_amazon_attribution: values.is_amazon_attribution,
          attribution_path_regex: values.attribution_path_regex,
          affiliate_path_regex: values.affiliate_path_regex,
          id,
        },
        handleRefreshInfo
      )
    );
  };

  return loading ? (
    <Box sx={styles.spinnerWrapper}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <Formik initialValues={getInitialValues()} onSubmit={handleSubmit}>
      <EmbedSettingsContent />
    </Formik>
  );
}
