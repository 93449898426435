import { usePublisher } from 'common/hooks';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import {
  selectPublisherBrandCommissions,
  selectPublisherBrandCommissionsLoading,
} from 'modules/settings/store/selectors';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrandsCommissionsTable } from './brands-commissions-table/brands-commissions-table';

export function BrandsSettings(): ReactElement {
  const dispatch = useDispatch();
  const publisher = usePublisher();
  const data = useSelector(selectPublisherBrandCommissions);
  const loading = useSelector(selectPublisherBrandCommissionsLoading);

  useEffect(() => {
    if (publisher.id) {
      dispatch(publishersSettingsActions.getPublishersBrandCommissions(+publisher.id));
    }
  }, [dispatch, publisher.id]);

  return <BrandsCommissionsTable loading={loading} data={data} />;
}
