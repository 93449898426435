import { Action } from 'common/interfaces/root';
import { recommendedAsinsActionTypes as TYPES } from '../actions';

const initialState: AffiliateGainzModule.RecommendedAsinsModule.FiltersState = {
  search: '',
  limit: 60,
  offset: 0,
  status: 'all',
  category: 'all',
  season: 'none',
  price_min: null,
  price_max: null,
  earn_min: null,
  earn_max: null,
  product_sort: null,
  price_sort: null,
};

export default (
  state: AffiliateGainzModule.RecommendedAsinsModule.FiltersState = initialState,
  { type, payload }: Action
): AffiliateGainzModule.RecommendedAsinsModule.FiltersState => {
  switch (type) {
    case TYPES.FILTERS.SET_SEARCH:
      return { ...state, search: payload };
    case TYPES.FILTERS.SET_STATUS:
      return { ...state, status: payload };
    case TYPES.FILTERS.SET_SEASON:
      return { ...state, season: payload };
    case TYPES.FILTERS.SET_CATEGORY:
      return { ...state, category: payload };
    case TYPES.FILTERS.SET_LIMIT:
      return { ...state, limit: payload };
    case TYPES.FILTERS.SET_OFFSET:
      return { ...state, offset: payload };
    case TYPES.FILTERS.SET_PRICE_MIN:
      return { ...state, price_min: payload };
    case TYPES.FILTERS.SET_PRICE_MAX:
      return { ...state, price_max: payload };
    case TYPES.FILTERS.SET_EARN_MIN:
      return { ...state, earn_min: payload };
    case TYPES.FILTERS.SET_EARN_MAX:
      return { ...state, earn_max: payload };
    case TYPES.FILTERS.SET_PRODUCT_SORT:
      return { ...state, product_sort: payload };
    case TYPES.FILTERS.SET_PRICE_SORT:
      return { ...state, price_sort: payload };
    case TYPES.FILTERS.RESET_FILTERS:
      return initialState;

    default:
      return state;
  }
};
