import { Action } from 'common/interfaces/root';
import { publishersSettingsActionTypes as T } from '../actions';

const initState: PublishersSettingsModule.PublisherGroupsStore = {
  loading: true,
  error: null,
  data: null,
};

export default (
  state: PublishersSettingsModule.PublisherGroupsStore = initState,
  { type, payload }: Action
): PublishersSettingsModule.PublisherGroupsStore => {
  switch (type) {
    case T.GET_PUBLISHER_GROUPS:
      return { ...state, loading: true };

    case T.GET_PUBLISHER_GROUPS_SUCCESS:
      return { ...state, loading: false, data: payload };

    case T.GET_PUBLISHER_GROUPS_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
