import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ReactElement } from 'react';
import { Styles } from 'common/types';

const styles: Styles = {
  uploadDetails: {},
  uploadDetailsItem: {
    padding: '10px',
    border: '1px solid #ccc',
    whiteSpace: 'pre-line',
    marginBottom: '5px',
  },
  errorInfoContainer: {
    marginTop: '10px',
  },
  title: {
    fontWeight: 600,
    color: '#060F14',
  },
};

interface Props {
  items: AsinsConversionsBoostModule.UploadDetailsItem;
}

export function UploadDetails({ items }: Props): ReactElement {
  return (
    <Box sx={styles.uploadDetails}>
      {Array.isArray(items) ? (
        items.map(item => (
          <Box key={item.asin} sx={styles.uploadDetailsItem}>
            <Typography sx={styles.title}>Change log</Typography>
            <Typography>ASIN: {item.asin}</Typography>
            <Typography>Publisher: {item.publisher}</Typography>
            <Typography>Brand: {item.brand}</Typography>
            <Typography>Boost: {item.boost}</Typography>
            {item.message !== undefined && (
              <Box sx={styles.errorInfoContainer}>
                <Typography sx={styles.title}>Error info</Typography>
                <Typography>{item.message}</Typography>
              </Box>
            )}
          </Box>
        ))
      ) : (
        <Box sx={styles.uploadDetailsItem}>
          <Typography>{items.message}</Typography>
        </Box>
      )}
    </Box>
  );
}
