import { combineReducers } from 'redux';
import { getFiltersReducer } from 'common/store/reducers/filters';
import { getDataListReducer } from 'common/store/reducers/data-list';
import { publishersParentsActionTypes, publishersSettingsFiltersActionTypes } from '../actions';
import publishers from './publishers';
import form from './form';
import tableFilters from './filters';
import blockedBrands from './blocked-brands';

export const publishersSettingsReducers = combineReducers({
  publishers,
  blockedBrands,
  form,
  filters: getFiltersReducer(publishersSettingsFiltersActionTypes),
  tableFilters,
  publishersParents: getDataListReducer<PublishersSettingsModule.PublisherParent>(publishersParentsActionTypes),
});
