import { ChangeEvent, ReactElement } from 'react';
import { Modal } from 'common/ui/modal';
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { useDispatch, useSelector } from 'react-redux';
import { conversionsSelectors } from 'modules/conversions/store/selectors';
import { convActions } from 'modules/conversions/store/actions';

const styles: Styles = {
  fieldsContainer: { display: 'flex', flexDirection: 'column', gap: 1.5 },
  fieldContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  field: { width: '70%' },
  btnContainer: { display: 'flex', justifyContent: 'flex-end', gap: 1.5 },
};

interface Props {
  open: boolean;
  onClose: () => void;
  handleApply: () => void;
  handleReset: () => void;
}

export function FiltersModal({ open, onClose, handleApply, handleReset }: Props): ReactElement {
  const filters = useSelector(conversionsSelectors.selectFilters);

  const dispatch = useDispatch();

  const handleStatusChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(convActions.filtersActions.setStatus(e.target.value));
  };

  const handleNetworkChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(convActions.filtersActions.setNetworkType(e.target.value));
  };

  const handleTrafficChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(convActions.filtersActions.setTrafficType(e.target.value));
  };

  return (
    <Modal open={open} title="Filters" maxWidth="sm" onClose={onClose}>
      <Box sx={styles.fieldsContainer}>
        <Box sx={styles.fieldContainer}>
          <Typography>Status</Typography>
          <TextField
            select
            color="primary"
            sx={styles.field}
            value={filters.status}
            size="small"
            label="Status"
            onChange={handleStatusChange}
          >
            <MenuItem value="ordered">Ordered</MenuItem>
            <MenuItem value="shipped">Shipped</MenuItem>
          </TextField>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Network Type</Typography>
          <TextField
            select
            color="primary"
            sx={styles.field}
            value={filters.networkType}
            size="small"
            label="Network Type"
            onChange={handleNetworkChange}
          >
            <MenuItem value="1">Amazon</MenuItem>
            <MenuItem value="3">Impact</MenuItem>
          </TextField>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Traffic Type</Typography>
          <TextField
            select
            color="primary"
            sx={styles.field}
            value={filters.trafficType}
            size="small"
            label="Traffic Type"
            onChange={handleTrafficChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="google">Google</MenuItem>
            <MenuItem value="bing">Bing</MenuItem>
            <MenuItem value="facebook">Facebook</MenuItem>
            <MenuItem value="apple news">Apple News</MenuItem>
          </TextField>
        </Box>
        <Box sx={styles.btnContainer}>
          <Button sx={styles.btn} variant="outlined" onClick={handleReset}>
            Reset
          </Button>
          <Button sx={styles.btn} variant="contained" onClick={handleApply}>
            Apply
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
