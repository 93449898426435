import { ReactElement } from 'react';
import { MenuItem, Select, FormControl } from '@mui/material';
import { Product } from '../../../../../../../common/ui/product';
import { IProduct } from '../../../../../../../common/ui/product/types';

const sx = {
  productContainer: {
    marginTop: 0,
    minWidth: '210px',
    width: '300px',
    height: '65px',
    maxWidth: 300,
  },
};

interface Props {
  oosProduct: IProduct;
  asinToReplace: string;
  suggestions: IProduct[];
  onSelectReplacement: (oosProduct: IProduct, productToReplace: IProduct) => void;
}

export function OosReplacementRow({
  oosProduct,
  suggestions,
  asinToReplace,
  onSelectReplacement,
}: Props): ReactElement {
  const selectReplacement = (newAsinToReplace: string) => {
    const productToReplace = suggestions.filter(el => el.asin === newAsinToReplace)[0];
    onSelectReplacement(oosProduct, productToReplace);
  };

  return (
    <>
      <Product
        product={oosProduct}
        key={oosProduct.amazon_link}
        showOrderIcon={false}
        showLastPullIcon={false}
        requestToSwapMode
      />
      <svg
        style={{
          margin: 'auto 20px',
        }}
        width="20"
        height="20"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.9358 9.04523L21.9805 13.5226L15.9358 18L14.2092 16.721L17.306 14.4262L1.22114 14.4271V12.6181H17.306L14.2092 10.3242L15.9358 9.04523ZM6.04463 0L7.77132 1.27899L4.67451 3.57286H20.7593V5.38191H4.67451L7.77132 7.67578L6.04463 8.95477L0 4.47739L6.04463 0Z"
          fill="#4288F0"
        />
      </svg>
      <FormControl>
        {oosProduct.requested_asin_to_replace &&
        suggestions.find(s => s.asin === oosProduct.requested_asin_to_replace) ? (
          <Product
            product={suggestions.find(s => s.asin === oosProduct.requested_asin_to_replace)}
            key={suggestions.find(s => s.asin === oosProduct.requested_asin_to_replace)?.amazon_link}
            showOrderIcon={false}
            showLastPullIcon={false}
            requestToSwapMode
          />
        ) : (
          <Select
            id="replacement-select__label"
            className="replacement__select"
            value={asinToReplace ?? ''}
            sx={sx.productContainer}
            onChange={e => selectReplacement(e.target.value as string)}
          >
            {suggestions.map(product => (
              <MenuItem key={product.title} value={product.asin}>
                <Product
                  key={product.amazon_link}
                  product={product}
                  isOpenLinkOnClick={false}
                  showOrderIcon={false}
                  showLastPullIcon={false}
                  showEarnIcon
                />
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </>
  );
}
