// eslint-disable-next-line import/named
import { IProduct } from 'common/ui/product/types';
import { asinFinderActionTypes as T } from './action-types';

const getAsinsList = (payload?: { limit?: number; offset?: number; search?: Array<string> }) => ({
  type: T.GET_ASINS_LIST,
  payload,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAsinsListSuccess = (
  data: Array<IProduct>,
  total: number,
  variations: Array<{ asin: string; variations: string }>
) => ({
  type: T.GET_ASINS_LIST_SUCCESS,
  payload: { data, total, variations },
});

const getAsinsListError = (error: Error) => ({
  type: T.GET_ASINS_LIST_ERROR,
  payload: error,
});

const changePageAsins = (payload: number) => ({
  type: T.CHANGE_PAGE_ASINS_LIST,
  payload,
});

const changeAmountAsins = (payload: number) => ({
  type: T.CHANGE_AMOUNT_ASINS_LIST,
  payload,
});

const changeLimitAsins = (payload: number) => ({
  type: T.CHANGE_LIMIT_ASINS_LIST,
  payload,
});

const downloadAsinsCsv = (payload: string) => ({
  type: T.DOWNLOAD_ASINS_CSV,
  payload,
});

const generateReport = (payload: AsinFinder.GenerateAosData) => ({
  type: T.GENERATE_REPORT,
  payload,
});

const generateReportSuccess = (payload: string) => ({
  type: T.GENERATE_REPORT_SUCCESS,
  payload,
});

const generateReportError = (error: Error) => ({
  type: T.GENERATE_REPORT_ERROR,
  payload: error,
});

const openReportLink = (payload: { id: string; code: string }, onStopTimer: () => void) => ({
  type: T.OPEN_REPORT_LINK,
  payload,
  meta: { onStopTimer },
});

const successReportLink = () => ({
  type: T.SUCCESS_REPORT_LINK,
});

const removeExecutionId = () => ({
  type: T.REMOVE_EXECUTION_ID,
});

const getBrands = () => ({
  type: T.GET_BRANDS,
});

const getBrandsSuccess = (payload: string) => ({
  type: T.GET_BRANDS_SUCCESS,
  payload,
});

const getBrandsError = (error: Error) => ({
  type: T.GET_BRANDS_ERROR,
  payload: error,
});

const getBrandParents = () => ({
  type: T.GET_BRAND_PARENTS,
});

const getBrandParentsSuccess = (payload: string) => ({
  type: T.GET_BRAND_PARENTS_SUCCESS,
  payload,
});

const getBrandParentsError = (error: Error) => ({
  type: T.GET_BRAND_PARENTS_ERROR,
  payload: error,
});

export const asinFinderActions = {
  getAsinsList,
  getAsinsListSuccess,
  getAsinsListError,
  changePageAsins,
  changeAmountAsins,
  changeLimitAsins,
  downloadAsinsCsv,
  generateReport,
  generateReportSuccess,
  generateReportError,
  openReportLink,
  successReportLink,
  removeExecutionId,
  getBrandParents,
  getBrandParentsSuccess,
  getBrandParentsError,
  getBrands,
  getBrandsSuccess,
  getBrandsError,
};
