import { Box, Popover } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement } from 'react';
import { theme } from 'common/constants/theme';

interface StylesProps {
  width: number;
}

const getStyles = ({ width }: StylesProps): Styles => ({
  row: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid #e5e5e5',
    height: 48,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0 16px',
    ':hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  popover: { width },
});

interface Props {
  anchorElement: HTMLElement | null;
  onClose: () => void;
  onBatchDelete: () => void;
  width: number;
}

export function UrlManagementPopover({ anchorElement, onClose, width, onBatchDelete }: Props): ReactElement {
  const styles = getStyles({ width });

  return (
    <Popover
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      PaperProps={{ sx: styles.popover }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
    >
      <Box sx={styles.row} onClick={onBatchDelete}>
        Batch Delete Searched
      </Box>
    </Popover>
  );
}
