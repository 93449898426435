import { combineReducers } from 'redux';
import correlation from './correlation';
import devices from './devices';
import statistics from './statistics';
import performance from './performance';
import top10 from './top10';
import productsStats from './products-stats';
import combined from './combined';
import top10Export from './top10-export';

export const overviewReducers = combineReducers({
  statistics,
  correlation,
  devices,
  performance,
  top10,
  productsStats,
  combined,
  top10Export,
});
