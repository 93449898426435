import { Action } from 'common/interfaces/root';
import { publishersSettingsActionTypes as T } from '../actions';

const initState: RateCardsModule.RateCardsState = {
  loading: true,
  error: null,
  data: null,
  selected: {},
};

export default (state = initState, { type, payload }: Action): RateCardsModule.RateCardsState => {
  switch (type) {
    case T.GET_RATE_CARDS:
      return { ...state, loading: true };

    case T.GET_RATE_CARDS_ERROR:
      return { ...state, loading: false, error: payload };

    case T.GET_RATE_CARDS_SUCCESS:
      return { ...state, loading: false, data: payload };

    case T.SET_RATE_CARDS_SELECTED:
      return { ...state, loading: false, selected: payload };

    default:
      return state;
  }
};
