import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import { selectTeamClientsInfo, selectTeamClientsLoading } from 'modules/settings/store/selectors';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { selectGlobalFilters } from 'common/store/selectors';
import { ClientItem } from '../client-item';
import { ParentItem } from '../parent-item';

const styles: Styles = {
  button: { maxWidth: 100, width: '100%', marginLeft: 1, ':first-child': { marginLeft: 0 } },
  buttons: { marginTop: 2, display: 'flex', justifyContent: 'flex-end' },
  brandContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: { width: '100%' },
  titleField: {
    color: '#214254',
    fontSize: '12px',
    marginTop: 2,
    marginBottom: 0.5,
  },
  clientTitle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '250px',
  },
  clientsList: {
    mb: 2,
    mt: 1,
    display: 'flex',
    flexDirection: 'column',
    height: 400,
    overflow: 'hidden',
    overflowY: 'scroll',
    padding: 2,
    boxSizing: 'border-box',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  clientType: string;
}

export function InviteModal({ open, clientType, onClose }: Props): ReactElement {
  const dispatch = useDispatch();

  const [inviteClientsIds, setInviteClientsIds] = useState<Array<number>>([]);
  const [removeClientsIds, setRemoveClientsIds] = useState<Array<number>>([]);
  const [email, setEmail] = useState<string>('');
  const [search, setSearch] = useState<string>('');

  const data = useSelector(selectTeamClientsInfo);
  const loading = useSelector(selectTeamClientsLoading);
  const globalFilters = useSelector(selectGlobalFilters);

  const allClientsList =
    data?.clients?.length || data?.parents?.length
      ? [data.clients, ...data.parents.map(parent => parent.clients)]?.flat()
      : [];
  const searchedClient = allClientsList?.filter(item => item?.name.toLowerCase().includes(search.toLowerCase()));
  const currClient = allClientsList?.find(item => Number(item?.id) === Number(globalFilters?.publisher_id));
  const publisherList = data?.clients?.filter(item => Number(item?.id) !== Number(globalFilters?.publisher_id));

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleChangeInviteClientsIds = (checked: boolean, ids: Array<number>) => {
    if (checked) {
      setInviteClientsIds([...inviteClientsIds, ...ids]);
      setRemoveClientsIds(removeClientsIds?.filter(el => !ids.includes(el)));
    } else {
      setInviteClientsIds(inviteClientsIds?.filter(el => !ids.includes(el)));
      setRemoveClientsIds([...removeClientsIds, ...ids]);
    }
  };

  const handleInviteUser = () => {
    dispatch(
      publishersSettingsActions.patchTeamUser(
        {
          email,
          invite_clients_ids: inviteClientsIds,
          remove_clients_ids: removeClientsIds,
          client_type: 'publisher',
        },
        () => {
          if (clientType === 'current') {
            dispatch(publishersSettingsActions.getTeam(globalFilters?.publisher_id));
          } else {
            dispatch(publishersSettingsActions.getTeam());
          }
        }
      )
    );
    onClose();
  };

  useEffect(() => {
    setInviteClientsIds([Number(globalFilters?.publisher_id)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilters?.publisher_id]);

  return (
    <Modal open={open} onClose={onClose} maxWidth="xs" title="Invite User">
      <TextField
        label="Email"
        InputLabelProps={{ shrink: true }}
        sx={styles.field}
        size="small"
        onChange={handleEmailChange}
        value={email}
      />
      <Typography sx={styles.titleField}>Connected Publishers:</Typography>
      <TextField
        InputLabelProps={{ shrink: true }}
        sx={styles.field}
        size="small"
        onChange={handleSearchChange}
        value={search}
        placeholder="Search..."
      />
      {search ? (
        <Box sx={styles.clientsList}>
          {!loading ? (
            <Typography sx={styles.brandContent}>
              {searchedClient?.map(item => (
                <ClientItem
                  item={item}
                  inviteClientsIds={inviteClientsIds}
                  handleChangeInviteClientsIds={handleChangeInviteClientsIds}
                />
              ))}
            </Typography>
          ) : (
            <Box sx={styles.loaderWrapper}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={styles.clientsList}>
          {!loading ? (
            <Typography sx={styles.brandContent}>
              {currClient && (
                <ClientItem
                  item={currClient}
                  inviteClientsIds={inviteClientsIds}
                  handleChangeInviteClientsIds={handleChangeInviteClientsIds}
                />
              )}
              {data?.parents?.map(item => (
                <div>
                  <ParentItem
                    item={item}
                    inviteClientsIds={inviteClientsIds}
                    handleChangeInviteClientsIds={handleChangeInviteClientsIds}
                  />
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                    {item?.clients?.map(cl => (
                      <ClientItem
                        item={cl}
                        inviteClientsIds={inviteClientsIds}
                        handleChangeInviteClientsIds={handleChangeInviteClientsIds}
                      />
                    ))}
                  </Box>
                </div>
              ))}
              {publisherList.map(item => (
                <ClientItem
                  item={item}
                  inviteClientsIds={inviteClientsIds}
                  handleChangeInviteClientsIds={handleChangeInviteClientsIds}
                />
              ))}
            </Typography>
          ) : (
            <Box sx={styles.loaderWrapper}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
      <Box sx={styles.buttons}>
        <Button color="primary" sx={styles.button} variant="contained" onClick={handleInviteUser}>
          Submit
        </Button>
        <Button color="info" variant="outlined" sx={styles.button} onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}
