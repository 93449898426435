import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import { revenueByPageApi } from 'modules/revenue-by-page/services/revenue-by-page.service';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { revenueByPageActions, revenueByPageActionTypes as T } from '../actions';

function* getRevenueByPageTableSaga({ payload }: Action) {
  try {
    const { data } = yield call(revenueByPageApi.getArticles, payload);
    yield put(
      revenueByPageActions.getRevenueByPageTableSuccess(data.articles_info.articles, data.articles_info.articles_count)
    );
  } catch (err) {
    yield put(revenueByPageActions.getRevenueByPageTableError(err as Error));
  }
}

function* getTop10PagesSaga({ payload }: Action) {
  try {
    const { data } = yield call(revenueByPageApi.getTop10Pages, payload);

    yield put(revenueByPageActions.getTop10PagesSuccess(data.top_charts));
  } catch (err) {
    yield put(revenueByPageActions.getTop10PagesError(err as Error));
  }
}

// eslint-disable-next-line consistent-return
function* checkExportStatus(id: string): SagaIterator<string> {
  let status = '';

  while (status !== 'success') {
    const { data } = yield call(revenueByPageApi.getExportStatus, id);

    switch (data.status) {
      case 'pending':
        status = data.status;
        yield delay(1000);
        break;
      case 'success':
        status = data.status;
        return data.link;
      case 'failed':
        status = data.status;
        throw new Error('export failed');
      default:
        throw new Error('export failed');
    }
  }
}

function* exportRevenueByPageTableSaga({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(revenueByPageApi.exportArticles, payload);

    const link = yield call(checkExportStatus, data.job_id);

    window.open(link);

    yield put(revenueByPageActions.exportSuccess(link));
  } catch (err) {
    yield put(revenueByPageActions.exportError(err as Error));
  }
}

function* exportTop10PagesSaga({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(revenueByPageApi.exportTop10Pages, payload);

    const link = yield call(checkExportStatus, data.job_id);

    window.open(link);

    yield put(revenueByPageActions.exportSuccess(link));
  } catch (err) {
    yield put(revenueByPageActions.exportError(err as Error));
  }
}

export function* revenueByPageSaga(): SagaIterator {
  yield all([
    takeLatest(T.GET_REVENUE_BY_PAGE_TABLE, getRevenueByPageTableSaga),
    takeLatest(T.GET_TOP10_PAGES, getTop10PagesSaga),
    takeLatest(T.EXPORT_PAGES, exportRevenueByPageTableSaga),
    takeLatest(T.EXPORT_TOP10_PAGES, exportTop10PagesSaga),
  ]);
}
