import { publisherBrandCommissionsActionTypes as T } from './action-types';

const getPublisherBrandCommissions = (payload: number) => ({
  type: T.GET_PUBLISHER_BRAND_COMMISSIONS,
  payload,
});

const getPublisherBrandCommissionsSuccess = (
  payload: PublishersBrandCommissionsModule.PublisherBrandCommissionData[]
) => ({
  type: T.GET_PUBLISHER_BRAND_COMMISSIONS_SUCCESS,
  payload,
});

const getPublishersBrandCommissionsError = (error: Error) => ({
  type: T.GET_PUBLISHER_BRAND_COMMISSIONS_ERROR,
  error,
});

const addPublisherBrandCommission = (payload: PublishersBrandCommissionsModule.AddPublisherBrandCommission) => ({
  type: T.ADD_PUBLISHER_BRAND_COMMISSION,
  payload,
});

const addPublisherBrandCommissionSuccess = (
  payload: PublishersBrandCommissionsModule.PublisherBrandCommissionData
) => ({
  type: T.ADD_PUBLISHER_BRAND_COMMISSION_SUCCESS,
  payload,
});

const addPublisherBrandCommissionError = (error: Error) => ({
  type: T.ADD_PUBLISHER_BRAND_COMMISSION_ERROR,
  payload: error,
});

const editPublisherBrandCommission = (payload: PublishersBrandCommissionsModule.EditPublisherBrandCommission) => ({
  type: T.EDIT_PUBLISHER_BRAND_COMMISSION,
  payload,
});

const editPublisherBrandCommissionSuccess = (
  payload: PublishersBrandCommissionsModule.PublisherBrandCommissionData
) => ({
  type: T.EDIT_PUBLISHER_BRAND_COMMISSION_SUCCESS,
  payload,
});

const editPublisherBrandCommissionError = (error: Error) => ({
  type: T.EDIT_PUBLISHER_BRAND_COMMISSION_ERROR,
  payload: error,
});

const deletePublisherBrandCommission = (payload: PublishersBrandCommissionsModule.DeletePublisherBrandCommission) => ({
  type: T.DELETE_PUBLISHER_BRAND_COMMISSION,
  payload,
});

const deletePublisherBrandCommissionSuccess = (payload: number) => ({
  type: T.DELETE_PUBLISHER_BRAND_COMMISSION_SUCCESS,
  payload,
});

const deletePublisherBrandCommissionError = (error: Error) => ({
  type: T.DELETE_PUBLISHER_BRAND_COMMISSION_ERROR,
  payload: error,
});

export const publisherBrandCommissionsActions = {
  getPublisherBrandCommissions,
  getPublisherBrandCommissionsSuccess,
  getPublishersBrandCommissionsError,
  addPublisherBrandCommission,
  addPublisherBrandCommissionSuccess,
  addPublisherBrandCommissionError,
  editPublisherBrandCommission,
  editPublisherBrandCommissionSuccess,
  editPublisherBrandCommissionError,
  deletePublisherBrandCommission,
  deletePublisherBrandCommissionSuccess,
  deletePublisherBrandCommissionError,
};
