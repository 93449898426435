import { Box, CircularProgress, Popover } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { ReactElement } from 'react';
import { Styles } from 'common/types';
import { theme } from 'common/constants/theme';

const styles: Styles = {
  container: {
    width: 350,
    maxWidth: 350,
    maxHeight: 600,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
  },
  urlContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: 'lightgray',
    padding: 1,
    ':hover': { backgroundColor: theme.palette.background.default, textDecoration: 'underline', cursor: 'pointer' },
  },
  url: { width: '75%' },
  noneContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    padding: 1,
  },
};

interface Props {
  onClose: () => void;
  anchorElement: HTMLElement | null;
  urls?:
    | {
        url: string;
        path: string;
      }[]
    | null;
  placements?: AffiliateGainzModule.RecommendedAsinsModule.PlacementsState | null;
  isSelected: boolean;
}

export function UrlPopover({ onClose, anchorElement, urls, placements, isSelected }: Props): ReactElement {
  return (
    <Popover
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={styles.container}>
        {urls &&
          urls.map(url => (
            <Box sx={styles.urlContainer} onClick={() => window.open(url.url, '_blank')}>
              <Box sx={styles.url} key={url.url}>
                {url.url}
              </Box>
              <LinkIcon />
            </Box>
          ))}
        {isSelected && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {placements?.loading ? (
              <Box sx={styles.noneContainer}>
                <CircularProgress size={14} />
                Retrieving suggestions...
              </Box>
            ) : (
              <Box>
                {placements?.data?.length === 0 ? (
                  <Box sx={styles.noneContainer}>No suggestions found</Box>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {placements?.data?.map(urls => (
                      <Box sx={styles.urlContainer} onClick={() => window.open(urls.url, '_blank')}>
                        <Box sx={styles.url} key={urls.url}>
                          {urls.url}
                        </Box>
                        <LinkIcon />
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
    </Popover>
  );
}
