import { MenuItem, TextField } from '@mui/material';
import { Styles } from 'common/types';
import { ChangeEvent, ReactElement } from 'react';
import { DateByValue } from '../types';

const styles: Styles = {
  field: { width: '70%' },
};

const dateByOptions = [
  {
    title: 'Retailer Clicks',
    value: DateByValue.Click,
  },
  {
    title: 'Transactions',
    value: DateByValue.Transactional,
  },
];

interface Props {
  dateBy: DateByValue;
  onChange: (dareBy: DateByValue) => void;
}

export function DateBy({ dateBy, onChange }: Props): ReactElement {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value as DateByValue);
  };

  return (
    <TextField
      select
      color="primary"
      sx={styles.field}
      onChange={handleChange}
      value={dateBy}
      size="small"
      label="Report Stats"
    >
      {dateByOptions.map(source => (
        <MenuItem key={source.value} value={source.value}>
          {source.title}
        </MenuItem>
      ))}
    </TextField>
  );
}
