import { ApiService } from 'common/services';
import { GetRequestParams } from 'common/store/actions/data-list';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { parseFilters } from 'common/utils/parse-filters';
import { UrlFilter } from './content-overview.service.types';

class ContentOverviewApiService extends ApiService {
  getPages = ({ publisherId, limit, offset, search, urls_filter }: GetRequestParams) => {
    return this.get(`/api/publishers/${publisherId}/urls`, { params: { limit, offset, search, urls_filter } });
  };

  deleteUrl = (payload: { publisher_id: string; url_id: string }) => {
    return this.delete(`/api/publishers/${payload.publisher_id}/urls/${payload.url_id}/`);
  };

  deleteFilteredUrl = (payload: { publisher_id: string; search: string; urlsFilter: UrlFilter }) => {
    return this.delete(`/api/publishers/${payload.publisher_id}/urls/`, {
      params: {
        search: payload.search,
        urls_filter: payload.urlsFilter,
      },
    });
  };

  getSitemaps = ({ publisherId, limit, offset, search }: GetRequestParams) => {
    return this.get(`/api/publishers/${publisherId}/sitemap`, { params: { limit, offset, search } });
  };

  getReplacements = ({ publisherId, limit, offset, search, inStock }: GetRequestParams) => {
    return this.get(`/api/publishers/${publisherId}/urls/products`, {
      params: { limit, offset, search, ...(inStock !== 2 && { inStock }) },
    });
  };

  exportPerformance = (filters: IStatisticsQueryString, publisherIDs: Array<number>) => {
    const parsedFilters = parseFilters(filters);

    return this.post('/api/publishers/report/performance', {
      filters: { ...parsedFilters, sort: undefined },
      sort: parsedFilters.sort,
      publishers_ids: publisherIDs,
    });
  };

  checkExportPerformance = (id: string) => {
    return this.get(`/api/publishers/report/performance/${id}`);
  };

  getPagesPerformance = (filters: IStatisticsQueryString & { brand_name?: string }) => {
    return this.get('/api/statistics/revenue/v2', { params: parseFilters(filters) });
  };

  createSitemaps = (publisherId: number, sitemaps: Array<number>, sitemap_list: number) => {
    return this.post(`/api/publishers/${publisherId}/sitemap`, { sitemaps, sitemap_list });
  };

  deleteSitemap = (payload: { publisherId: number; id: number }) => {
    return this.delete(`/api/publishers/${payload.publisherId}/sitemap/${payload.id}`);
  };

  createPages = (publisherId: number, pages: Array<number>, asins?: Array<string>, updated_at?: string) => {
    return this.post(`/api/publishers/${publisherId}/urls`, { urls: pages.map(p => ({ url: p })), asins, updated_at });
  };

  importPages = (publisherId: number, file: File) => {
    return this.postFormData(`/api/publishers/${publisherId}/urls/upload-csv`, { file });
  };

  getSuggestions = (publisherId: number, pageId: number) => {
    return this.get(`/api/publishers/${publisherId}/urls/${pageId}/suggestions`);
  };

  getSuggestionsV2 = (publisherId: number, pageId: number, withInStock: boolean) => {
    return this.get(`/api/publishers/${publisherId}/urls/${pageId}/suggestions/v2`, {
      params: { with_in_stock: withInStock },
    });
  };

  getReplacementRecommendations = (publisherId: number, asins: Array<string>) => {
    return this.get(`/api/publishers/${publisherId}/urls/product-recommendations?asins=${JSON.stringify(asins)}`);
  };

  generateReport = (payload: string) => {
    return this.post('/api/publishers/report/oos', {
      publishers_ids: [payload],
    });
  };

  openReportLink = (payload: { id: string; code: string }) => {
    return this.get(`/api/publishers/report/oos/${payload.code}`);
  };

  getProductsStats = (payload: { id: string; inStock: number; search: string }) => {
    return this.get(`/api/publishers/${payload.id}/urls/stats`, {
      params: { search: payload.search },
    });
  };

  getCrawlingStatus = ({ publisherId }: GetRequestParams) => {
    return this.get(`/api/crawling/publisher/${publisherId}/status`);
  };

  getCrawledURLs = ({
    publisherId,
    limit,
    offset,
    search,
    with_asins,
    added_founded,
    relative_paths,
  }: GetRequestParams) => {
    return this.get(`/api/crawling/publisher/${publisherId}`, {
      params: { limit, offset, search, added_founded, ...(with_asins ? { with_asins } : {}), relative_paths },
    });
  };

  postCrawling = (publisherId: number, url: string) => {
    return this.post(`/api/crawling/publisher/${publisherId}/schedule`, { url });
  };

  getProductRecommendations = (payload: { publisherId: number; asins: string; search?: string }) => {
    return this.get(
      `/api/publishers/${payload.publisherId}/urls/product-recommendations?asins=${payload.asins}&look_for_replacement=1&search=${payload.search}`
    );
  };

  getProductRecommendationsSearch = (payload: { publisherId: number; asins: string; search?: string }) => {
    return this.get(`/api/publishers/${payload.publisherId}/urls/product/search?s=${payload.search}`);
  };

  getTabScrapingSchedule = (payload: { publisher_id: number; tab: string }) => {
    return this.get(`/api/publishers/${payload.publisher_id}/${payload.tab}/schedule`);
  };

  postProductRecommendations = (payload: {
    id: number;
    product_asin: string;
    replacement_asin: string;
    score: string;
  }) => {
    return this.post(`/api/publishers/${payload.id}/urls/product-recommendations`, {
      product_asin: payload.product_asin,
      replacement_asin: payload.replacement_asin,
      score: payload.score,
    });
  };

  patchProductRecommendations = (payload: {
    id: number;
    product_asin: string;
    replacement_asin: string;
    score: string;
  }) => {
    return this.patch(`/api/publishers/${payload.id}/urls/product-recommendations`, {
      product_asin: payload.product_asin,
      replacement_asin: payload.replacement_asin,
      score: payload.score,
    });
  };

  postRescrapTab = ({ publisherId, tab }: { publisherId: number; tab: string }) => {
    return this.post(`/api/publishers/${publisherId}/${tab}/re-scrap`);
  };

  postUrlsRescrap = ({ publisherId, tab, urlsIds }: { publisherId: number; tab: string; urlsIds: string }) => {
    return this.post(`/api/publishers/${publisherId}/${tab}/make-scrap-requested`, {
      urls_ids: urlsIds,
    });
  };

  patchUpdateSchedule = ({ publisherId, tab, schedule }: { publisherId: number; tab: string; schedule: string }) => {
    return this.patch(`/api/publishers/${publisherId}/${tab}/re-schedule`, {
      cron: schedule,
    });
  };

  requestToSwapOOS = ({
    publisherId,
    url,
    asins,
  }: {
    publisherId: number;
    url: string;
    asins: { oos: string; replace: string }[];
  }) => {
    return this.post(`/api/publishers/${publisherId}/urls/request-to-swap-oos`, {
      url,
      asins,
    });
  };
}

export const contentOverviewApi = new ContentOverviewApiService(process.env.REACT_APP_API_URL);
