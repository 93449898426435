import { Box, Button, Typography } from '@mui/material';
import { paper } from 'common/constants/colors';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';
import { selectProductsStatistics, selectProductsStatisticsLoading } from 'modules/overview-v2/store/selectors';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const styles: Styles = {
  container: {
    padding: 2.5,
    backgroundColor: paper,
    borderLeft: '10px solid #E3EDFD',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 3,
  },
  text: { color: theme.palette.primary.main, width: '70%', fontSize: 14 },
};

export function RecommendedAsins(): ReactElement {
  const nav = useNavigate();

  const stats = useSelector(selectProductsStatistics);
  const loading = useSelector(selectProductsStatisticsLoading);

  const number = new Intl.NumberFormat();

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.text}>
        There are{' '}
        <Typography fontWeight={700} fontSize={14} component="span">
          {loading ? '...' : number.format(stats?.oos_articles ?? 0)} articles
        </Typography>{' '}
        that have out of stock asins, and over{' '}
        <Typography fontWeight={700} fontSize={14} component="span">
          {loading ? '...' : number.format(stats?.recommended_asins ?? 0)} ASIN recommendations
        </Typography>{' '}
        that can be added to articles to increase your revenues. You are missing out on opportunities!
      </Typography>
      <Button variant="contained" color="primary" onClick={() => nav('/content-overview/performance')}>
        View Opportunities
      </Button>
    </Box>
  );
}
