import { Box, Button, CircularProgress, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined';
import { useDebouncedCallback } from 'use-debounce';
import { Styles } from 'common/types';
import { Portal } from 'common/ui/portal';
import HeaderImg from 'common/svg/header-img.png';
import { CloudUpload } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { asinsConversionsBoostSelectors } from './store/selectors';
import { asinsConversionsBoostActions } from './store/actions';
import { ConversionsBoostTable, FormModal, FiltersModal, UploadDetailsModal } from './components';
import { ExportModal } from './components/export-modal';

const styles: Styles = {
  head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    background: `url(${HeaderImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#4288F0',
    padding: '28px',
    boxSizing: 'border-box',
    color: '#ffffff',
    fontWeight: 600,
  },
  headTitle: {
    color: '#ffffff',
    fontWeight: 600,
  },
  btnsContainer: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2 },
  btnContainer: { display: 'flex', alignItems: 'center', gap: 2 },
  upload: { width: '35%' },
  actionButton: {
    width: 'max-content',
    alignSelf: 'end',
    minWith: '160px',
  },
};

export function AsinsConversionsBoost() {
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState<boolean>(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const dispatch = useDispatch();

  const conversionsBoost = useSelector(asinsConversionsBoostSelectors.selectConversionsBoost);
  const filters = useSelector(asinsConversionsBoostSelectors.selectFilters);
  const upload = useSelector(asinsConversionsBoostSelectors.selectUpload);

  const handleFiltersModalOpen = () => setIsFiltersModalOpen(true);

  const handleFiltersModalClose = () => setIsFiltersModalOpen(false);

  const handleFormModalOpen = () => setIsFormModalOpen(true);

  const handleFormModalClose = () => setIsFormModalOpen(false);

  const handleExportModalOpen = () => setIsExportModalOpen(true);

  const handleExportModalClose = () => setIsExportModalOpen(false);

  const handleUploadModalClose = () => setIsUploadModalOpen(false);

  const conversionsBoostFilters = useMemo(
    () => ({
      ...filters,
      limit: 50,
      offset: conversionsBoost.page * 50,
    }),
    [filters, conversionsBoost.page]
  );

  const onUploadSuccess = () => {
    dispatch(asinsConversionsBoostActions.conversionsBoostActions.getConversionsBoost(filters));
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    dispatch(asinsConversionsBoostActions.uploadActions.fileUpload(file, onUploadSuccess));
    setIsUploadModalOpen(true);
  };

  const handleApply = () => {
    setIsFiltersModalOpen(false);
  };

  const handleReset = () => {
    dispatch(asinsConversionsBoostActions.filtersActions.resetFilters());
  };

  const debouncedAsinsConversionsBoost = useDebouncedCallback(filters => {
    dispatch(asinsConversionsBoostActions.conversionsBoostActions.getConversionsBoost(filters));
  }, 300);

  useEffect(() => {
    if (!isInitialized) return;

    debouncedAsinsConversionsBoost(conversionsBoostFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversionsBoostFilters, conversionsBoost.page]);

  useEffect(() => {
    setIsInitialized(true);

    dispatch(asinsConversionsBoostActions.conversionsBoostActions.getConversionsBoost(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (upload.loading) {
      toast.loading('File uploading...', { id: 'file-uploading', icon: <CircularProgress size={20} /> });
    } else {
      toast.remove('file-uploading');
    }
  }, [upload.loading]);

  return (
    <Box sx={{ backgroundColor: '#F4F9FC' }}>
      <Portal id="header-page">
        <Box sx={styles.head}>
          <Typography sx={styles.headTitle}>Conversions Boost</Typography>
        </Box>
      </Portal>

      <Box sx={styles.main}>
        <Box sx={styles.btnsContainer}>
          <Box sx={styles.upload}>
            <Button
              startIcon={<FileDownloadIcon />}
              variant="contained"
              color="primary"
              sx={styles.actionButton}
              onClick={handleExportModalOpen}
            >
              Export
            </Button>
          </Box>

          <Box sx={styles.upload}>
            <Button variant="contained" component="label" color="primary" startIcon={<CloudUpload />}>
              Upload CSV File
              <input type="file" accept="text/csv" onChange={handleUpload} style={{ display: 'none' }} />
            </Button>
          </Box>

          <Box sx={styles.btnContainer}>
            <Button
              startIcon={<UploadFileOutlined />}
              variant="contained"
              color="primary"
              sx={styles.actionButton}
              onClick={handleFormModalOpen}
            >
              Add new
            </Button>
          </Box>

          <Box sx={styles.btnContainer}>
            <Button variant="contained" color="primary" sx={styles.actionButton} onClick={handleFiltersModalOpen}>
              Filters
            </Button>
          </Box>
        </Box>

        <Box sx={styles.tableContainer}>
          <ConversionsBoostTable />
        </Box>
      </Box>

      <ExportModal onClose={handleExportModalClose} isOpen={isExportModalOpen} />
      <UploadDetailsModal onClose={handleUploadModalClose} isOpen={isUploadModalOpen} />
      <FormModal isOpen={isFormModalOpen} onClose={handleFormModalClose} />
      <FiltersModal
        onClose={handleFiltersModalClose}
        isOpen={isFiltersModalOpen}
        handleApply={handleApply}
        handleReset={handleReset}
      />
    </Box>
  );
}
