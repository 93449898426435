import { Box, Popover, TextField } from '@mui/material';
import { PERMISSIONS } from 'common/constants/permissions';
import { usePermission } from 'common/hooks';
import { selectPublishers } from 'modules/publishers-settings/store/selectors';
import { Styles } from 'common/types';
import { ChangeEvent, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { publisherGroups } from 'common/constants/publisher-groups';
import { selectCombined } from 'modules/overview-v2/store/selectors';
import { selectGlobalFilters } from 'common/store/selectors';
import { filtersActions } from 'common/store/actions/filters';
import { PublisherRow } from './publisher-row';
import { PublisherTabs } from './publisher-tabs';

const styles: Styles = {
  container: { padding: 2, width: 360, maxWidth: 320, maxHeight: 650, overflowY: 'scroll', scrollbarWidth: 'none' },
  publishers: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 2,
  },
};

interface Props {
  onClose: () => void;
  anchorElement: HTMLElement | null;
  selectedPublisher: number | null | string;
  handlePublisherChange: (publisher: number | null | string) => void;
  disableRedirect?: boolean;
  singleOption?: boolean;
}

export function PublisherPickerPopover({
  onClose,
  anchorElement,
  selectedPublisher,
  handlePublisherChange,
  disableRedirect = false,
  singleOption = false,
}: Props): ReactElement {
  const dispatch = useDispatch();

  const publishers: PublishersSettingsModule.Publisher[] = useSelector(selectPublishers);
  const combined = useSelector(selectCombined);
  const filters = useSelector(selectGlobalFilters);

  const [search, setSearch] = useState<string>('');

  const isAdmin = usePermission(PERMISSIONS.GET_PUBLISHERS);

  const handleFilter = (group: string) => {
    if (group === 'All') return publishers;
    return publishers.filter(publisher => Object.values(publisher.channels).includes(String(filters.publisher_group)));
  };

  const handleClose = () => {
    onClose();
    setSearch('');
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleGroupChange = (value: string) => {
    dispatch(filtersActions.changePublisherGroup(value));
  };

  return (
    <Popover
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={styles.container}>
        <Box>
          <TextField size="small" variant="outlined" onChange={handleSearch} value={search} fullWidth />
          {isAdmin && (
            <PublisherTabs
              group={String(filters.publisher_group)}
              setGroup={handleGroupChange}
              tabs={Object.keys(publisherGroups)}
            />
          )}
        </Box>
        <Box sx={styles.publishers}>
          {!singleOption && (
            <PublisherRow
              handlePublisherChange={handlePublisherChange}
              active={combined.isCombined}
              title="All"
              value="0"
              handlePopoverClose={onClose}
              disableRedirect={disableRedirect}
            />
          )}
          {(handleFilter(String(filters.publisher_group)) || [])
            .filter(publisher => publisher.name.toLowerCase().includes(search.toLowerCase()))
            .map(publisher => (
              <PublisherRow
                key={publisher.id}
                handlePublisherChange={handlePublisherChange}
                active={String(selectedPublisher) === String(publisher.id) && !combined.isCombined}
                title={publisher.name}
                value={publisher.id}
                handlePopoverClose={onClose}
              />
            ))}
        </Box>
      </Box>
    </Popover>
  );
}
