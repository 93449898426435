import { combineReducers } from 'redux';
import { getDataListReducer } from 'common/store/reducers/data-list';
import { PubMaverickPerformance } from 'modules/mav-plus/types';
import pubMavPerformanceExportReducer from './pub-mav-performance-export';
import { pubMaverickTableActionTypes } from '../actions';
import { pubMavPerformanceSummaryReducer } from './pub-mav-performance-summary';

export const mavPlusReducers = combineReducers({
  pubMavPerformanceExport: pubMavPerformanceExportReducer,
  pubMavPerformanceSummary: pubMavPerformanceSummaryReducer,
  pubMaverickTable: getDataListReducer<PubMaverickPerformance>(pubMaverickTableActionTypes, {
    limit: 100,
  }),
});
