import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { Styles } from 'common/types';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { pagesActions } from 'modules/content-overview/store/actions';
import { selectPages, selectPagesLimitPageAmount, selectPagesLoading } from 'modules/content-overview/store/selectors';
import { MouseEvent, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PagesTableRow } from '../pages-table-row';
import { selectRootDemo } from '../../../../../../common/store/selectors';

const styles: Styles = {
  table: { marginTop: 2 },
};

interface Props {
  handleUpdatePage: (message: string) => void;
}

export function PagesTable({ handleUpdatePage }: Props): ReactElement {
  const dispatch = useDispatch();

  const pages = useSelector(selectPages);
  const loading = useSelector(selectPagesLoading);
  const { amount, page, limit } = useSelector(selectPagesLimitPageAmount);
  const isDemoMode = useSelector(selectRootDemo);

  const handlePageChange = (_: MouseEvent<HTMLButtonElement>, page: number) => {
    dispatch(pagesActions.changePage(page));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(pagesActions.changeLimit(parseInt(event.target.value, 10)));
    dispatch(pagesActions.changePage(0));
  };

  return (
    <>
      <TableContainerWithLoading sx={styles.table} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>URL</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Last Scrape</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {pages?.map(page => (
              <PagesTableRow page={page} key={page.id} handleUpdatePage={handleUpdatePage} isDemoMode={isDemoMode} />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <TablePagination
        component="div"
        count={amount}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 25, 50, 100]}
        page={page}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}
