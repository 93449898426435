import { Box } from '@mui/material';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';
import { ReactElement } from 'react';

interface StylesProps {
  status: string;
}

const getStatusColor = ({ status }: StylesProps) => {
  switch (status) {
    case 'pending':
      return theme.palette.warning.main;
    case 'active':
      return theme.palette.primary.main;
    case 'error':
    case 'inactive':
      return theme.palette.error.main;
    case 'finished':
      return theme.palette.success.light;
    default:
      return '';
  }
};

const getStyles = (props: StylesProps): Styles => ({
  container: {
    backgroundColor: getStatusColor(props),
    padding: '2px',
    textTransform: 'uppercase',
    color: theme.palette.background.paper,
  },
});

interface Props {
  status: string;
}

export function Status({ status }: Props): ReactElement {
  const styles = getStyles({ status });

  return <Box sx={styles.container}>{status}</Box>;
}
