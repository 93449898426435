import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Modal } from 'common/ui/modal';
import { conversionsSelectors } from 'modules/conversions/store/selectors';
import { generateFilename, handleDownload } from 'modules/conversions/utils';
import moment from 'moment';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Styles } from 'common/types';
import { DownloadButton } from 'common/ui/download-button';

const styles: Styles = {
  boxLoading: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' },
  boxNA: { display: 'flex', justifyContent: 'space-between', borderTop: 'solid lightgray 1px', padding: '10px' },
  list: { margin: '10px', height: '300px', overflowY: 'scroll' },
  listItem: { display: 'flex', justifyContent: 'space-between', borderTop: 'solid lightgray 1px' },
  downloadIcon: { padding: '3px', borderRadius: '2px', cursor: 'pointer', ':hover': { backgroundColor: '#E7F2F7' } },
  table: { maxHeight: 650 },
  downloadCell: { width: 40 },
};

interface Props {
  open: boolean;
  onClose: () => void;
}

export function ReportsModal({ open, onClose }: Props): ReactElement {
  const reports = useSelector(conversionsSelectors.selectReports);

  return (
    <Modal open={open} title="Reports" maxWidth="md" onClose={onClose}>
      <Box>
        {reports.loading ? (
          <Box sx={styles.boxLoading}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <TableContainer sx={styles.table}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>File</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell sx={styles.downloadCell} />
                </TableRow>
              </TableHead>
              <TableBody>
                {reports.data.map(report => (
                  <TableRow key={reports.data.indexOf(report)}>
                    <TableCell>{generateFilename(report)}</TableCell>
                    <TableCell>{moment(report.uploaded_at).format('D MMM, YY')}</TableCell>
                    <TableCell sx={styles.downloadCell}>
                      <DownloadButton handleDownload={() => handleDownload(report)} color="dark" />
                    </TableCell>
                  </TableRow>
                ))}
                {reports.data?.length === 0 && (
                  <TableRow>
                    <TableCell>No files available</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Modal>
  );
}
