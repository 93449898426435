import { asinsConversionsBoostTypes as TYPES } from './action-types';

const getExport = (payload: AsinsConversionsBoostModule.GetConversionsBoostParams) => ({
  type: TYPES.EXPORT.GET_EXPORT,
  payload,
});

const getExportSuccess = (payload: string | null) => ({
  type: TYPES.EXPORT.GET_EXPORT_SUCCESS,
  payload,
});

const getExportError = (payload: Error) => ({
  type: TYPES.EXPORT.GET_EXPORT_ERROR,
  payload,
});

const resetExport = () => ({
  type: TYPES.EXPORT.RESET_EXPORT,
});

export const exportActions = {
  getExport,
  getExportSuccess,
  getExportError,
  resetExport,
};
