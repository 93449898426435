import { Action } from 'common/interfaces/root';
import { overviewActionTypes as T } from '../actions';

const initState: OverviewV2.CombinedStore = {
  isCombined: false,
};

export default (state: OverviewV2.CombinedStore = initState, { type, payload }: Action): OverviewV2.CombinedStore => {
  switch (type) {
    case T.SET_IS_COMBINED:
      return { ...state, isCombined: payload };

    default:
      return state;
  }
};
