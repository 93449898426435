import { Action } from 'common/interfaces/root';
import { publishersSettingsActionTypes as T } from '../actions';

const initState: PublishersSettingsModule.FormStore = {
  success: null,
  error: null,
};

export default (
  state: PublishersSettingsModule.FormStore = initState,
  { type, payload }: Action
): PublishersSettingsModule.FormStore => {
  switch (type) {
    case T.EDIT_PUBLISHER_SUCCESS:
      return { ...state, success: payload };
    case T.EDIT_PUBLISHER_ERROR:
      return { ...state, error: payload };

    default:
      return state;
  }
};
