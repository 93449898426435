import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { Styles } from 'common/types';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { TableSortField } from 'modules/revenue-by-page/constants';
import { revenueByPageActions } from 'modules/revenue-by-page/store/actions';
import {
  selectCurrentPage,
  selectTableAmount,
  selectTableRows,
  selectTableRowsLoading,
  selectTableRowsPerPage,
  selectTableSort,
} from 'modules/revenue-by-page/store/selectors';
import { MouseEvent, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RevenueTableRow } from 'modules/revenue-by-page/components/revenue-table/revenue-table-row';

const styles: Styles = {
  tableContainer: { marginTop: 2 },
};

export function RevenueTable(): ReactElement {
  const dispatch = useDispatch();

  const currentPage = useSelector(selectCurrentPage);
  const amount = useSelector(selectTableAmount);
  const rows = useSelector(selectTableRows);
  const loading = useSelector(selectTableRowsLoading);
  const sort = useSelector(selectTableSort);
  const rowsPerPage = useSelector(selectTableRowsPerPage);

  const handlePageChange = (_e: MouseEvent<HTMLButtonElement>, page: number) => {
    dispatch(revenueByPageActions.changeRevenueTablePage(page));
  };

  const getSortDirection = (field: TableSortField): 'asc' | 'desc' => {
    if (field === sort.field && sort.direction === 'asc') return 'desc';
    if (field === sort.field && sort.direction === 'desc') return 'asc';
    if (field !== sort.field) return sort.direction;
    return 'asc';
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(revenueByPageActions.changeRevenueTableRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(revenueByPageActions.changeRevenueTablePage(0));
  };

  const handleSortCreation = (field: TableSortField) => () => {
    dispatch(revenueByPageActions.changeRevenueTableSort({ field, direction: getSortDirection(field) }));
  };

  return (
    <>
      <TableContainerWithLoading sx={styles.tableContainer} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  onClick={handleSortCreation(TableSortField.title)}
                  active={sort.field === TableSortField.title}
                  direction={sort.direction}
                >
                  Article Title
                </TableSortLabel>
              </TableCell>
              <TableCell>Tag</TableCell>
              <TableCell>
                <TableSortLabel
                  onClick={handleSortCreation(TableSortField.shipments)}
                  direction={sort.direction}
                  active={sort.field === TableSortField.shipments}
                >
                  Shipments
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  onClick={handleSortCreation(TableSortField.visits)}
                  direction={sort.direction}
                  active={sort.field === TableSortField.visits}
                >
                  Visits
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  onClick={handleSortCreation(TableSortField.clicks)}
                  direction={sort.direction}
                  active={sort.field === TableSortField.clicks}
                >
                  Clicks
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  onClick={handleSortCreation(TableSortField.conversion)}
                  direction={sort.direction}
                  active={sort.field === TableSortField.conversion}
                >
                  Conversion
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  onClick={handleSortCreation(TableSortField.total_revenue)}
                  direction={sort.direction}
                  active={sort.field === TableSortField.total_revenue}
                >
                  Total GMV
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  onClick={handleSortCreation(TableSortField.total_ad_fees)}
                  direction={sort.direction}
                  active={sort.field === TableSortField.total_ad_fees}
                >
                  Total Ad Fees
                </TableSortLabel>
              </TableCell>
              {/* <TableCell>Article ASINs</TableCell>  // * Need API updates before enabling in prod  */}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <RevenueTableRow key={`${row.article_title}-${index}`} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <TablePagination
        component="div"
        count={amount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
        page={currentPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
