import { State } from 'common/store/reducers';

export const selectAllStatistics = (state: State) => state.overview.statistics.entries;

export const selectAllStatisticsLoading = (state: State) => state.overview.statistics.loading;

export const selectCorrelationStatistics = (state: State) => state.overview.correlation.entries;

export const selectCorrelationStatisticsLoading = (state: State) => state.overview.correlation.loading;

export const selectDevicesStatistics = (state: State) => state.overview.devices.entries;

export const selectDevicesStatisticsLoading = (state: State) => state.overview.devices.loading;

export const selectTopPerformers = (state: State) => state.overview.performance;
