import { ApiService } from 'common/services';

class AffiliateGainzService extends ApiService {
  // getHasAccess = (params: CommonModule.Access.HasAccessParams) => {
  //   return this.get('/api/affiliate/overview', { params });
  // };

  getAsins = (params: AffiliateGainzModule.RecommendedAsinsModule.FiltersState) => {
    return this.get(`/api/affiliate/recommended-asins/${params.publisher_id}`, { params });
  };

  getPlacements = (params: AffiliateGainzModule.RecommendedAsinsModule.PlacementParams) => {
    return this.get('/api/affiliate/recommended-asins/placements', { params });
  };

  getRevenue = (params: AffiliateGainzModule.GainzRevenueModule.RevenueModule.GetRevenueParams) => {
    return this.get('/api/affiliate/gainz-revenue', { params });
  };

  getExport = (params: AffiliateGainzModule.GainzRevenueModule.ExportModule.ExportParams) => {
    return this.get('/api/affiliate/gainz-revenue/export', { params });
  };

  getRecommendedAsinsExport = (params: AffiliateGainzModule.RecommendedAsinsModule.FiltersState) => {
    return this.get(`/api/affiliate/recommended-asins/export/${params.publisher_id}`, { params });
  };

  getCategories = () => {
    return this.get('/api/affiliate/recommended-asins/categories');
  };
}

export const affiliateGainzService = new AffiliateGainzService(process.env.REACT_APP_API_URL);
