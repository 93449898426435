import { actionTypes } from './action-types';

const getFilters = () => ({
  type: actionTypes.filters.GET_FILTERS,
});

const setStatus = (value: string) => ({
  type: actionTypes.filters.SET_STATUS,
  payload: value,
});

const setNetworkType = (value: string) => ({
  type: actionTypes.filters.SET_NETWORK_TYPE,
  payload: value,
});

const setTrafficType = (value: string) => ({
  type: actionTypes.filters.SET_TRAFFIC_TYPE,
  payload: value,
});

const setReport = (value: string) => ({
  type: actionTypes.filters.SET_REPORTS,
  payload: value,
});

const setSearch = (value: string) => ({
  type: actionTypes.filters.SET_SEARCH,
  payload: value,
});

const resetFilters = () => ({
  type: actionTypes.filters.RESET_FILTERS,
});

export const filtersActions = {
  getFilters,
  setStatus,
  setNetworkType,
  setTrafficType,
  setReport,
  setSearch,
  resetFilters,
};
