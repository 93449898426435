export enum TableSortField {
  shipments = 'shipments',
  clicks = 'clicks',
  conversion = 'conversion',
  mobile_ad_fees = 'mobile_ad_fees',
  desktop_ad_fees = 'desktop_ad_fees',
  total_ad_fees = 'total_ad_fees',
  total_revenue = 'total_revenue',
  title = 'title',
  contains_oos = 'contains_oos',
  visits = 'visits',
  tag = 'tag',
}
