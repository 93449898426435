import { ReactElement, useContext } from 'react';
import { TableCell, TableSortLabel } from '@mui/material';
import { columnsPopoverCtx, PerformanceTableColumn } from '../columns-popover';
import { PerformanceTableSortField } from '../../../../constants';
import { ITableSort } from '../../../../../../../common/ui/base-filters/types';
import { Styles } from '../../../../../../../common/types';

interface IGenerateTableHeadCellProps {
  colName: keyof typeof PerformanceTableColumn;
  title: string;
  handleSortCreation: (field: PerformanceTableSortField) => () => void;
  sort: ITableSort;
}

const styles: Styles = {
  cell: { color: '#214254', fontSize: '12px', whiteSpace: 'nowrap' },
};

export function MetricsTableHeadCell({
  colName,
  title,
  handleSortCreation,
  sort,
}: IGenerateTableHeadCellProps): ReactElement {
  const sortFieldKey = PerformanceTableColumn[colName] as unknown;
  const { columns } = useContext(columnsPopoverCtx);
  return (
    columns.includes(PerformanceTableColumn[colName]) && (
      <TableCell sx={styles.cell}>
        <TableSortLabel
          onClick={handleSortCreation(
            PerformanceTableSortField[sortFieldKey as keyof typeof PerformanceTableSortField]
          )}
          active={
            sort.field === PerformanceTableSortField[colName.toLowerCase() as keyof typeof PerformanceTableSortField]
          }
          direction={sort.direction}
        >
          {title}
        </TableSortLabel>
      </TableCell>
    )
  );
}
