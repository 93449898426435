import { asinsConversionsBoostTypes as TYPES } from './action-types';

const setBrands = (payload: Array<AsinsConversionsBoostModule.Brand>) => ({
  type: TYPES.BRANDS.SET_BRANDS,
  payload,
});

const getBrands = () => ({
  type: TYPES.BRANDS.GET_BRANDS,
});

const getBrandsSuccess = (payload: string) => ({
  type: TYPES.BRANDS.GET_BRANDS_SUCCESS,
  payload,
});

const getBrandsError = (error: Error) => ({
  type: TYPES.BRANDS.GET_BRANDS_ERROR,
  payload: error,
});

export const brandsActions = {
  setBrands,
  getBrands,
  getBrandsSuccess,
  getBrandsError,
};
