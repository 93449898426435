import { onboardingTargets } from 'common/constants/onboarding';
import { Step } from 'react-joyride';

const commonOptions: Partial<Step> = {
  disableBeacon: true,
  placement: 'right-start',
};

export const getOnboardingSteps = (isGainz: boolean): Array<Step> =>
  [
    {
      target: `.${onboardingTargets.overview}`,
      title: 'Overview',
      isGainzActive: false,
      content: `The tracking overview page enables you to easily review trends and metrics such as which sources drive the highest impressions, clicks, revenue or what types of content generate more money. 
      With this information, you will be able to make informed decisions on how best to maximize your website's potential.`,
      ...commonOptions,
    },
    {
      target: `.${onboardingTargets.trackingOverview}`,
      title: 'Tracking Overview',
      isGainzActive: false,
      content: `To help you understand how your pages compare to each other in terms of  revenue driven, products that are out of stuck on each article with suggested recommendations, the performance page gives you insight on a per article basis.
    `,
      ...commonOptions,
    },
    {
      target: `.${onboardingTargets.affiliateGainz}`,
      title: 'MavericX',
      isGainzActive: true,
      content: `Discover more earning opportunities with MaverickX. We are providing you with over 10k ASINs to increase your affiliate earnings.
    `,
      ...commonOptions,
    },
    {
      target: `.${onboardingTargets.publisherSettings}`,
      title: 'Settings',
      isGainzActive: true,
      content: 'Make changes to your profile through the publisher settings tab.',
      ...commonOptions,
    },
  ].filter(item => (isGainz ? item.isGainzActive : item.target !== `.${onboardingTargets.affiliateGainz}`));
