import { Typography } from '@mui/material';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { IProduct } from 'common/ui/product/types';
import { contentOverviewActions } from 'modules/content-overview/store/actions';
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from '../product';
import { RecommendationsModal } from '../recommendations-modal';

const styles: Styles = {
  showMore: {
    display: 'block',
    margin: '10px 0 0 20px',
    color: '#4288F0',
    fontSize: 10,
    cursor: 'pointer',
    width: 'fit-content',
  },
};

interface Props {
  replacement: IProduct;
  handleGetReplacements: () => void;
}

export function ReplacementRow({ replacement, handleGetReplacements }: Props): ReactElement {
  const dispatch = useDispatch();

  const [isOpenRecommendationsModal, setIsOpenRecommendationsModal] = useState<boolean>(false);

  const globalFilters = useSelector(selectGlobalFilters);

  const handleOpenRecommendationsModal = () => {
    setIsOpenRecommendationsModal(true);
  };

  const handleCloseRecommendationsModal = () => {
    setIsOpenRecommendationsModal(false);
  };

  const handleSetRecommendation = (value: string) => {
    dispatch(
      contentOverviewActions.getProductRecommendations({ publisherId: globalFilters.publisher_id, asins: value })
    );
    handleOpenRecommendationsModal();
  };

  return (
    <>
      <Product
        key={replacement.amazon_link}
        showInStock
        product={replacement?.saved_replacements !== null ? replacement?.saved_replacements : replacement}
      />
      <Typography onClick={() => handleSetRecommendation(replacement?.product_asin)} sx={styles.showMore}>
        Change recommendation
      </Typography>
      <RecommendationsModal
        open={isOpenRecommendationsModal}
        onClose={handleCloseRecommendationsModal}
        isNewRecommend={replacement?.saved_replacements !== null}
        handleGetReplacements={handleGetReplacements}
        productAsin={replacement?.out_of_stock_asin}
      />
    </>
  );
}
