import { IStatisticsQueryString, ITableSort } from 'common/ui/base-filters/types';
import { revenueByPageActionTypes as T } from './action-types';

const getRevenueByPageTable = (filters: IStatisticsQueryString) => ({
  type: T.GET_REVENUE_BY_PAGE_TABLE,
  payload: filters,
});

const getRevenueByPageTableSuccess = (rows: Array<RevenueByPage.TableRow>, amount: number) => ({
  type: T.GET_REVENUE_BY_PAGE_TABLE_SUCCESS,
  payload: { rows, amount },
});

const getRevenueByPageTableError = (error: Error) => ({
  type: T.GET_REVENUE_BY_PAGE_TABLE_ERROR,
  payload: error,
});

const changeRevenueTablePage = (page: number) => ({
  type: T.CHANGE_REVENUE_TABLE_PAGE,
  payload: page,
});

const changeRevenueTableSort = (sort: ITableSort) => ({
  type: T.CHANGE_REVENUE_TABLE_SORT,
  payload: sort,
});

const getTop10Pages = (filters: IStatisticsQueryString) => ({
  type: T.GET_TOP10_PAGES,
  payload: filters,
});

const getTop10PagesSuccess = (data: Record<string, RevenueByPage.Top10Entry>) => ({
  type: T.GET_TOP10_PAGES_SUCCESS,
  payload: data,
});

const getTop10PagesError = (error: Error) => ({
  type: T.GET_TOP10_PAGES_ERROR,
  payload: error,
});

const changeRevenueTableRowsPerPage = (rowsPerPage: number) => ({
  type: T.CHANGE_REVENUE_TABLE_ROWS_PER_PAGE,
  payload: rowsPerPage,
});

const exportTop10Pages = (filters: IStatisticsQueryString) => ({
  type: T.EXPORT_TOP10_PAGES,
  payload: filters,
});

const exportPages = (filters: IStatisticsQueryString) => ({
  type: T.EXPORT_PAGES,
  payload: filters,
});

const exportSuccess = (link: string) => ({
  type: T.EXPORT_SUCCESS,
  payload: link,
});

const exportError = (error: Error) => ({
  type: T.EXPORT_ERROR,
  payload: error,
});

export const revenueByPageActions = {
  getRevenueByPageTable,
  getRevenueByPageTableSuccess,
  getRevenueByPageTableError,
  changeRevenueTablePage,
  getTop10Pages,
  getTop10PagesSuccess,
  getTop10PagesError,
  changeRevenueTableSort,
  changeRevenueTableRowsPerPage,
  exportTop10Pages,
  exportPages,
  exportSuccess,
  exportError,
};
