import { MenuItem, TextField } from '@mui/material';
import { Styles } from 'common/types';
import { ChangeEvent, ReactElement } from 'react';
import { Traffic } from '../types';

const styles: Styles = {
  field: { width: '70%' },
};

const trafficSources = [
  {
    title: 'Combined',
    value: Traffic.Combined,
  },
  {
    title: 'Google Organic',
    value: Traffic.Google_Organic,
  },
  {
    title: 'Google Paid',
    value: Traffic.Google_Paid,
  },
  {
    title: 'Facebook Paid',
    value: Traffic.Facebook_Paid,
  },
  {
    title: 'Other',
    value: Traffic.Other,
  },
];

interface Props {
  trafficSource: Traffic;
  onChange: (trafficSource: Traffic) => void;
}

export function TrafficSource({ trafficSource, onChange }: Props): ReactElement {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value as Traffic);
  };

  return (
    <TextField
      sx={styles.field}
      select
      color="primary"
      onChange={handleChange}
      value={trafficSource}
      size="small"
      label="Traffic type"
    >
      {trafficSources.map(source => (
        <MenuItem key={source.value} value={source.value}>
          {source.title}
        </MenuItem>
      ))}
    </TextField>
  );
}
