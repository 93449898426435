import { Action } from 'common/interfaces/root';
import { asinsConversionsBoostTypes as TYPES } from '../actions/action-types';

const initialState: AsinsConversionsBoostModule.FormStore = {
  success: null,
  error: null,
};

export default (
  state: AsinsConversionsBoostModule.FormStore = initialState,
  { type, payload }: Action
): AsinsConversionsBoostModule.FormStore => {
  switch (type) {
    case TYPES.FORM.POST_CONVERSION_BOOST_SUCCESS:
      return { ...state, success: payload };
    case TYPES.FORM.POST_CONVERSION_BOOST_ERROR:
      return { ...state, error: payload };
    case TYPES.FORM.EDIT_CONVERSION_BOOST_SUCCESS:
      return { ...state, success: payload };
    case TYPES.FORM.EDIT_CONVERSION_BOOST_ERROR:
      return { ...state, error: payload };
    case TYPES.FORM.DELETE_CONVERSION_BOOST_SUCCESS:
      return { ...state, success: payload };
    case TYPES.FORM.DELETE_CONVERSION_BOOST_ERROR:
      return { ...state, error: payload };
    default:
      return state;
  }
};
