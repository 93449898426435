import { IStatisticsQueryString, AuxiliaryParams } from 'common/ui/base-filters/types';
import { overviewActionTypes as T } from './action-types';

const getAllStatistics = (filters: IStatisticsQueryString) => ({
  type: T.GET_ALL_STATISTICS,
  payload: filters,
});

const getAllStatisticsSuccess = (statistics: Overview.StatisticsEntries) => ({
  type: T.GET_ALL_STATISTICS_SUCCESS,
  payload: statistics,
});

const getAllStatisticsError = (error: Error) => ({
  type: T.GET_ALL_STATISTICS_ERROR,
  payload: error,
});

const getCorrelationStatistics = (filters: IStatisticsQueryString) => ({
  type: T.GET_CORRELATION_STATISTICS,
  payload: filters,
});

const getCorrelationStatisticsSuccess = (statistics: Overview.CorrelationGraphData) => ({
  type: T.GET_CORRELATION_STATISTICS_SUCCESS,
  payload: statistics,
});

const getCorrelationStatisticsError = (error: Error) => ({
  type: T.GET_CORRELATION_STATISTICS_ERROR,
  payload: error,
});

const getDevicesStatistics = (filters: IStatisticsQueryString) => ({
  type: T.GET_DEVICES_STATISTICS,
  payload: filters,
});

const getDevicesStatisticsSuccess = (data: Overview.DevicesData) => ({
  type: T.GET_DEVICES_STATISTICS_SUCCESS,
  payload: data,
});

const getDevicesStatisticsError = (error: Error) => ({
  type: T.GET_DEVICES_STATISTICS_ERROR,
  payload: error,
});

const getTopPerformers = (filters: IStatisticsQueryString) => ({
  type: T.GET_TOP_PERFORMERS,
  payload: filters,
});

const getTopAsinsSuccess = (data: Overview.Performance.Performance[]) => ({
  type: T.GET_TOP_ASINS_SUCCESS,
  payload: data,
});

const getTopCategoriesSuccess = (data: Overview.Performance.Performance[]) => ({
  type: T.GET_TOP_CATEGORIES_SUCCESS,
  payload: data,
});

const getTopPerformersError = (error: Error) => ({
  type: T.GET_TOP_PERFORMERS_ERROR,
  payload: error,
});

const getTop10Pages = (filters: IStatisticsQueryString) => ({
  type: T.GET_TOP10_PAGES,
  payload: filters,
});

const getTop10PagesSuccess = (data: Record<string, RevenueByPage.Top10Entry>) => ({
  type: T.GET_TOP10_PAGES_SUCCESS,
  payload: data,
});

const getTop10PagesError = (error: Error) => ({
  type: T.GET_TOP10_PAGES_ERROR,
  payload: error,
});

const getTop10Export = (params: AuxiliaryParams) => ({
  type: T.GET_TOP10_EXPORT,
  payload: params,
});

const getTop10ExportSuccess = (data: string | null) => ({
  type: T.GET_TOP10_EXPORT_SUCCESS,
  payload: data,
});

const getTop10ExportError = (error: Error) => ({
  type: T.GET_TOP10_EXPORT_ERROR,
  payload: error,
});

const getProductsStats = (filters: IStatisticsQueryString) => ({
  type: T.GET_PRODUCTS_STATS,
  payload: filters,
});

const getProductsStatsSuccess = (payload: OverviewV2.ProductsStatsData) => ({
  type: T.GET_PRODUCTS_STATS_SUCCESS,
  payload,
});

const getProductsStatsError = (error: Error) => ({
  type: T.GET_PRODUCTS_STATS_ERROR,
  payload: error,
});

const setIsCombined = (payload: boolean) => ({
  type: T.SET_IS_COMBINED,
  payload,
});

export const overviewActions = {
  getAllStatistics,
  getAllStatisticsSuccess,
  getAllStatisticsError,
  getCorrelationStatistics,
  getCorrelationStatisticsSuccess,
  getCorrelationStatisticsError,
  getDevicesStatistics,
  getDevicesStatisticsSuccess,
  getDevicesStatisticsError,
  getTopPerformers,
  getTopAsinsSuccess,
  getTopCategoriesSuccess,
  getTopPerformersError,
  getTop10Pages,
  getTop10PagesSuccess,
  getTop10PagesError,
  getTop10Export,
  getTop10ExportSuccess,
  getTop10ExportError,
  getProductsStats,
  getProductsStatsSuccess,
  getProductsStatsError,
  setIsCombined,
};
