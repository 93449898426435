import { Action } from 'common/interfaces/root';
import { attributionActionTypes as T } from '../actions';

const initState: Attribution.AmazonAttrStore = {
  loading: true,
  error: null,
  data: null,
  page: 0,
  amount: 0,
};

export default (
  state: Attribution.AmazonAttrStore = initState,
  { type, payload }: Action
): Attribution.AmazonAttrStore => {
  switch (type) {
    case T.GET_AMAZON_ATTRIBUTION:
      return { ...state, loading: true };

    case T.GET_AMAZON_ATTRIBUTION_SUCCESS:
      return { ...state, loading: false, data: payload.tags, amount: payload.amount };

    case T.GET_AMAZON_ATTRIBUTION_ERROR:
      return { ...state, loading: false, error: payload };

    case T.CHANGE_AMAZON_ATTR_PAGE:
      return { ...state, page: payload };

    case T.DELETE_AMAZON_ATTRIBUTION_ITEM:
      return { ...state, loading: true };

    case T.DELETE_AMAZON_ATTRIBUTION_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter(item => item.id !== payload),
        amount: state.amount - 1,
      };

    case T.DELETE_AMAZON_ATTRIBUTION_ITEM_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
};
