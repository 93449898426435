import { State } from 'common/store/reducers';

const selectGainzAccess = (state: State) => state.affiliateAccess;
const selectRecommendedAsins = (state: State) => state.recommendedGainzAsins.recommendedAsins;
const selectFeaturedAsins = (state: State) => state.recommendedGainzAsins.featuredAsins;
const selectPlacements = (state: State) => state.recommendedGainzAsins.placements;
const selectCategories = (state: State) => state.recommendedGainzAsins.recommendedAsinsCategories;
const selectRecommendedAsinsFilters = (state: State) => state.recommendedGainzAsins.recommendedAsinsFilters;
const selectGainzRevenue = (state: State) => state.gainzRevenue.gainzRevenue;
const selectGainzRevenueFilters = (state: State) => state.gainzRevenue.gainzRevenueFilters;
const selectGainzRevenueExport = (state: State) => state.gainzRevenue.gainzRevenueExport;
const selectRecommendedAsinsExport = (state: State) => state.recommendedGainzAsins.recommendedAsinsExport;

export {
  selectGainzAccess,
  selectRecommendedAsins,
  selectFeaturedAsins,
  selectPlacements,
  selectCategories,
  selectRecommendedAsinsFilters,
  selectGainzRevenue,
  selectGainzRevenueFilters,
  selectGainzRevenueExport,
  selectRecommendedAsinsExport,
};
