import {
  Box,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Popover,
} from '@mui/material';
import { paper } from 'common/constants/colors';
import { Styles } from 'common/types';
import { MouseEvent, ReactElement, useState } from 'react';

const styles: Styles = {
  container: {
    borderRadius: '20px',
    backgroundColor: paper,
    padding: 2,
    height: '770px',
  },
  imgCell: { display: 'flex', justifyContent: 'center' },
  imgSm: { height: '35px', cursor: 'pointer' },
  imgContainer: { padding: 1 },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    boxShadow: 'rgba(50, 50, 93, 0.05) 0px 2px 5px -1px, rgba(0, 0, 0, 0.1) 0px 1px 3px -1px',
  },
};

const formatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const headers = [
  { title: '', id: 'rank' },
  { title: '', id: 'img' },
  { title: 'ASIN', id: 'asin' },
  { title: 'Revenue (GMV)', id: 'rev' },
  { title: 'Ad Fees', id: 'fees' },
  { title: 'Items Shipped', id: 'shipped' },
];

interface Props {
  asins: Overview.Performance.Performance[];
}

export function AsinTable({ asins }: Props): ReactElement {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [source, setSource] = useState<string | null>(null);

  const handlePopoverClose = () => {
    setSource(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={styles.container}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <TableCell key={header.id}>{header.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {asins?.map(row => (
              <TableRow key={row.asin} sx={styles.tableRow}>
                <TableCell>{asins.indexOf(row) + 1}</TableCell>
                <TableCell sx={styles.imgCell}>
                  <Box
                    onMouseEnter={(e: MouseEvent<HTMLElement>) => {
                      setAnchorEl(e.currentTarget);
                      setSource(row.image);
                    }}
                    onMouseLeave={handlePopoverClose}
                    component="img"
                    src={row.image}
                    alt=""
                    sx={styles.imgSm}
                  />
                  <Popover
                    sx={styles.popover}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    PaperProps={{ elevation: 0, sx: styles.popoverPaper }}
                    transitionDuration={250}
                    disableRestoreFocus
                  >
                    <Box sx={styles.imgContainer}>
                      <Box component="img" src={source} alt="" />
                    </Box>
                  </Popover>
                </TableCell>
                <TableCell>
                  {row.click_url && row.click_url.includes('www.amazon.com') && (
                    <Box>
                      <Tooltip title="View amazon product page" placement="top">
                        <Link href={row.click_url} target="_blank" underline="hover">
                          {row.asin}
                        </Link>
                      </Tooltip>
                    </Box>
                  )}
                </TableCell>
                <TableCell>{formatter.format(Number(row.revenue))}</TableCell>
                <TableCell>{formatter.format(Number(row.ad_fees))}</TableCell>
                <TableCell>{row.items_shipped}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
