import { ReactElement } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { TagsModalContent } from 'modules/settings/components/publishers-settings/publisher-settings/publisher-settings-form/tags-settings/tags-modal/tags-modal-content';
import { selectGlobalFilters } from 'common/store/selectors';

const initialValues: PublishersSettingsModule.TagForm = {
  tag: '',
  used: false,
  trafficType: 'organic',
};

interface Props {
  handleModalClose: () => void;
  isModalOpen: boolean;
  handleRefreshTable: () => void;
}

export function TagsModalForm({ handleModalClose, isModalOpen, handleRefreshTable }: Props): ReactElement {
  const dispatch = useDispatch();
  const filters = useSelector(selectGlobalFilters);

  const handleSubmit = (values: PublishersSettingsModule.TagForm) => {
    dispatch(
      publishersSettingsActions.addPublisherTag(
        {
          tag: values.tag,
          used: values.used,
          traffic_type: values.trafficType,
          publisher_id: filters?.publisher_id,
        },
        handleRefreshTable
      )
    );
    handleModalClose();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <TagsModalContent handleModalClose={handleModalClose} isModalOpen={isModalOpen} />
    </Formik>
  );
}
