/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useMemo, useState } from 'react';
import moment from 'moment';
import { TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { Product } from '../product';

const styles: Styles = {
  cell: { verticalAlign: 'top' },
  link: { color: '#060F14', cursor: 'pointer', fontSize: 14 },
  showMore: {
    display: 'block',
    margin: '10px 0 0 20px',
    color: '#4288F0',
    fontSize: 10,
    cursor: 'pointer',
    width: 'fit-content',
  },
  loadingContainer: {
    minWidth: '400px',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  loadingTitle: { fontSize: 10, marginTop: 1 },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 1,
    marginBottom: 2,
    marginLeft: 1,
    fontSize: 14,
    color: '#4288F0',
    cursor: 'pointer',
  },
  title: {
    fontSize: '11px',
    ml: 1,
  },
  lastScrapedCell: {
    minWidth: '150px',
  },
};

interface Props {
  row: any;
}

export function PageRow({ row }: Props): ReactElement {
  const [showMoreProducts, setShowMoreProducts] = useState<boolean>(false);

  const toggleShowMoreProducts = () => {
    setShowMoreProducts(showMoreProducts => !showMoreProducts);
  };

  const handleOpenPage = () => {
    window.open(row.url);
  };

  const products = useMemo(() => {
    const products = [row] ?? [];
    if (showMoreProducts) return products;
    return products.slice(0, 1);
  }, [row, showMoreProducts]);

  return (
    <TableRow>
      <TableCell sx={styles.cell}>
        <Tooltip title={row.url}>
          <Typography onClick={handleOpenPage} sx={styles.link}>
            {row.url ?? '-'}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell sx={styles.cell}>
        <Typography sx={styles.lastScrapedCell}>
          {row?.last_scraped_at
            ? `${moment(row?.last_scraped_at).format('DD MMM, YY')} at ${moment(row?.last_scraped_at).format('hh:mm')}`
            : ''}
        </Typography>
      </TableCell>
      <TableCell sx={styles.cell}>
        {products.map(product => (
          <Product
            key={product.url}
            product={{
              asin: product.asin,
              img_large: product.product_info?.img_large,
              img_small: product.product_info?.img_small,
            }}
          />
        ))}
        {row.products?.entries?.length > 1 && (
          <Typography onClick={toggleShowMoreProducts} sx={styles.showMore}>
            {showMoreProducts ? '- Close' : '+ See All'}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
}
