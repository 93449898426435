import { Box, Button, CircularProgress, MenuItem, TextField, Typography } from '@mui/material';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { SearchInput } from 'common/ui/search-input';
import {
  contentOverviewActions,
  productReplacementsActions,
  productReplacementsFiltersActions,
} from 'modules/content-overview/store/actions';
import {
  selectExecutionId,
  selectGenerateLoading,
  selectReplacementsFilters,
  selectReplacementsLimitPageAmount,
} from 'modules/content-overview/store/selectors';
import { userSettingsActions } from 'modules/user-settings/store/actions';
import { ChangeEvent, ReactElement, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { ReplaceProductsStats } from './replace-products-stats';
import { ReplaceProductsTable } from './replace-products-table';

const styles: Styles = {
  head: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  button: { ml: 1, height: '37px', minWidth: '130px' },
  listFiltersWrapper: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3.5 },
  select: { marginLeft: 2, width: 200, ':first-child': { marginLeft: 0 } },
};

const filterOptions = [
  { title: 'All', value: 2 },
  { title: 'In-stock only', value: 1 },
  { title: 'Out of stock Only', value: 0 },
];

export function ReplaceProducts(): ReactElement {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { limit, page } = useSelector(selectReplacementsLimitPageAmount);
  const globalFilters = useSelector(selectGlobalFilters);
  const replacementsFilters = useSelector(selectReplacementsFilters);
  const loading = useSelector(selectGenerateLoading);
  const executionId = useSelector(selectExecutionId);

  const exportTimer = useRef(null);

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [inStock, setInStock] = useState<number>(2);

  const debouncedReplacements = useDebouncedCallback(() => {
    dispatch(
      productReplacementsActions.get({
        limit,
        offset: limit * page,
        publisherId: globalFilters.publisher_id,
        search: replacementsFilters.search,
        inStock,
      })
    );
  }, 300);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInStock(Number(e.target.value));
  };
  const handleSearchChange = useCallback(
    (search: string) => {
      dispatch(productReplacementsActions.changePage(0));
      dispatch(productReplacementsFiltersActions.changeSearch(search));
      setSearchParams({ search });
    },
    [dispatch, setSearchParams]
  );

  const handleGenerateReport = () => {
    dispatch(contentOverviewActions.generateReport(String(globalFilters.publisher_id)));
  };

  const handleStopTimer = () => {
    clearTimeout(exportTimer.current);
    dispatch(contentOverviewActions.removeExecutionId());
  };

  const handleGetReplacements = () => {
    dispatch(
      productReplacementsActions.get({
        limit,
        offset: limit * page,
        publisherId: globalFilters.publisher_id,
        search: replacementsFilters.search,
        inStock,
      })
    );
  };

  useEffect(() => {
    if (!isInitialized) return;

    debouncedReplacements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, globalFilters.publisher_id, replacementsFilters.search, inStock]);

  useEffect(() => {
    dispatch(contentOverviewActions.removeExecutionId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilters.publisher_id]);

  useEffect(() => {
    setIsInitialized(true);

    dispatch(userSettingsActions.getUserSettingsInfo());

    dispatch(
      productReplacementsActions.get({
        limit,
        offset: limit * page,
        publisherId: globalFilters.publisher_id,
        search: searchParams.get('search') ?? replacementsFilters.search,
        inStock,
      })
    );
    dispatch(
      contentOverviewActions.getProductsStats({
        id: String(globalFilters.publisher_id),
        inStock,
        search: searchParams.get('search') ?? replacementsFilters.search,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading && executionId) {
      exportTimer.current = setInterval(
        () =>
          dispatch(
            contentOverviewActions.openReportLink(
              { id: String(globalFilters.publisher_id), code: executionId },
              handleStopTimer
            )
          ),
        2000
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, executionId]);

  useLayoutEffect(() => {
    const search = searchParams.get('search');
    if (!search) return;
    handleSearchChange(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSearchChange]);

  return (
    <>
      <Box sx={styles.head}>
        <Typography variant="h4">Product Placement</Typography>
      </Box>
      <Box sx={styles.listFiltersWrapper}>
        <Box>
          <SearchInput search={replacementsFilters.search} handleSearchChange={handleSearchChange} endAdornment />
          <TextField
            select
            color="primary"
            sx={styles.select}
            onChange={handleChange}
            value={inStock}
            size="small"
            label="Filter"
          >
            {filterOptions.map(source => (
              <MenuItem key={source.value} value={source.value}>
                {source.title}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Button
          sx={styles.button}
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={handleGenerateReport}
        >
          {!loading ? 'Export Report' : <CircularProgress color="primary" size={10} />}
        </Button>
      </Box>
      <ReplaceProductsStats />
      <ReplaceProductsTable handleGetReplacements={handleGetReplacements} />
    </>
  );
}
