import { Button } from '@mui/material';
import { MouseEvent, ReactElement, useState } from 'react';
import moment, { Moment } from 'moment';
import { Styles } from 'common/types';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DatePickerCalendar } from './date-picker-calendar';

interface StylesProps {
  fullWidth: boolean;
}

const getStyles = ({ fullWidth }: StylesProps): Styles => ({
  calendarIcon: { marginLeft: 1 },
  button: {
    width: fullWidth ? '100%' : 'unset',
    color: '#000',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    ':hover': { color: '#000', borderColor: 'rgba(0, 0, 0, 0.23)', backgroundColor: 'transparent' },
  },
});

interface Props {
  date: Moment;
  onChange: (date: Moment) => void;
  fullWidth?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function SingleDatePicker({ date, onChange, fullWidth = false }: Props): ReactElement {
  const styles = getStyles({ fullWidth });

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const handleCalendarOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(e.currentTarget);
  };

  const handleCalendarClose = () => {
    setAnchorElement(null);
  };

  const handleDateChange = (date: Date) => {
    onChange(moment(date));
  };

  return (
    <>
      <Button onClick={handleCalendarOpen} variant="outlined" sx={styles.button}>
        {date.format('Do MMM, YYYY')}
        <CalendarMonthIcon sx={styles.calendarIcon} fontSize="small" />
      </Button>
      <DatePickerCalendar
        onClose={handleCalendarClose}
        anchorElement={anchorElement}
        onChange={handleDateChange}
        date={date}
      />
    </>
  );
}
