import { State } from 'common/store/reducers';

const selectConversions = (state: State) => state.conversions.conversions;
const selectReports = (state: State) => state.conversions.reports;
const selectExport = (state: State) => state.conversions.exp;
const selectFilters = (state: State) => state.conversions.filters;
const selectTable = (state: State) => state.conversions.table;

export const conversionsSelectors = {
  selectConversions,
  selectReports,
  selectExport,
  selectFilters,
  selectTable,
};
