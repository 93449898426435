import { Action } from 'common/interfaces/root';
import { contentOverviewActionTypes as T } from '../actions';

const initialState: ContentOverviewModule.CrawlingStatusStore = {
  loading: false,
  error: null,
  data: null,
};

export default (
  state: ContentOverviewModule.CrawlingStatusStore = initialState,
  { type, payload }: Action
): ContentOverviewModule.CrawlingStatusStore => {
  switch (type) {
    case T.GET_CRAWLING_STATUS:
      return { ...state, loading: true };

    case T.GET_CRAWLING_STATUS_SUCCESS:
      return { ...state, loading: false, data: payload };

    case T.GET_CRAWLING_STATUS_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
