import { Box, Button, Typography } from '@mui/material';
import { LC_ONBOARDING_FINISHED } from 'common/constants/lc';
import { usePublisher } from 'common/hooks';
import { Styles } from 'common/types';
import { ReactElement } from 'react';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  title: { fontSize: 18, fontWeight: 600, mb: 3 },
  description: { fontSize: 14, mt: 1.5, textAlign: 'center', maxWidth: '500px' },
  btns: { display: 'flex', gap: 0.5, mt: 3 },
  skipBtn: { width: 105 },
  startBtn: { width: 120 },
  contentContainer: {
    height: '80vh',
    padding: '30px 40px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

interface Props {
  handleTourStart: () => void;
  handleOnboardingClose: () => void;
}

export function OnboardingStart({ handleTourStart, handleOnboardingClose }: Props): ReactElement {
  const publisher = usePublisher();

  return (
    <Box sx={styles.contentContainer}>
      <Box sx={styles.container}>
        <img src="/assets/images/maverickx-full-logo.svg" alt="chart" />
        <Typography sx={styles.title}>Hi ‘{publisher?.name}’</Typography>
        <Typography sx={styles.description}>
          Welcome to MaverickX for publishers. Before you start, we recommend taking a quick tour to get the best
          experience and value out of our service.
        </Typography>
        <Box sx={styles.btns}>
          <Button
            variant="outlined"
            size="small"
            sx={styles.startBtn}
            onClick={() => {
              handleTourStart();
            }}
          >
            Start tour
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={styles.skipBtn}
            onClick={() => {
              localStorage.setItem(LC_ONBOARDING_FINISHED, '1');
              handleOnboardingClose();
            }}
          >
            Skip
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
