import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import { tagsApi } from 'modules/tags/services/tags.service';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { tagsActions, tagsActionTypes as T } from '../actions';

function* getTagsSaga({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(tagsApi.getTags, payload);

    yield put(tagsActions.getTagsSuccess(data.tags, data.tags_count));
  } catch (err) {
    yield put(tagsActions.getTagsError(err as Error));
  }
}

export function* tagsSaga(): SagaIterator {
  yield all([takeLatest(T.GET_TAGS, getTagsSaga)]);
}
