import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { SelectOption } from 'common/ui/select-field/select-field';
import { ChangeEvent, ReactElement, useMemo, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { ScheduleCron } from '../../../constants';

const options: Array<SelectOption> = [
  {
    title: '1 Week',
    value: ScheduleCron.ONCE_A_WEEK,
  },
  {
    title: '2 weeks',
    value: ScheduleCron.ONCE_A_TWO_WEEKS,
  },
  {
    title: 'One month',
    value: ScheduleCron.ONCE_A_MONTH,
  },
  {
    title: 'Three months',
    value: ScheduleCron.ONCE_A_THREE_MONTHS,
  },
  {
    title: 'Six months',
    value: ScheduleCron.ONCE_A_SIX_MONTHS,
  },
];

const styles: Styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
  },
  select: { marginLeft: 2, width: 150, ':first-child': { marginLeft: 0 } },
  textContainer: {
    display: 'flex',
  },
  frequency: {
    margin: 'auto 0',
  },
  editIcon: {
    height: '1.4rem',
  },
};

interface Props {
  onChange: (value: string) => void;
  value: string;
}

export function TabSchedule({ onChange, value }: Props): ReactElement {
  const [isEditing, setIsEditing] = useState(false);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    setIsEditing(false);
  };
  const scheduleTitle: string = useMemo(() => {
    return options.reduce((acc, option) => {
      if (!acc && option.value === value) {
        acc = option.title;
      }
      return acc;
    }, '');
  }, [value]);

  return (
    <Box sx={styles.container}>
      {isEditing ? (
        <TextField
          select
          color="primary"
          sx={styles.select}
          onChange={handleChange}
          value={value}
          size="small"
          label="Schedule frequency"
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.title}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <Box sx={styles.textContainer}>
          <Typography sx={styles.frequency}>Schedule frequency: {scheduleTitle}</Typography>
          <Button onClick={() => setIsEditing(true)}>
            <EditIcon sx={styles.editIcon} />
          </Button>
        </Box>
      )}
    </Box>
  );
}
