import { MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Styles } from 'common/types';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { SearchInput } from 'common/ui/search-input';
import { theme } from 'common/constants/theme';
import { selectGlobalFilters } from 'common/store/selectors';
import { Portal } from 'common/ui/portal';
import HeaderImg from 'common/svg/header-img.png';
import { asinFinderActions } from './store/actions';
import {
  selectAsinsAmount,
  selectAsinsData,
  selectAsinsLimit,
  selectAsinsLoading,
  selectAsinsPage,
  selectAsinsVariations,
  selectExecutionId,
  selectGenerateReportLoading,
} from './store/selectors';
import { PageRow } from './components';
import { AosModal } from './components/aos-modal';

const styles: Styles = {
  headTitle: {
    color: '#ffffff',
    fontWeight: 600,
  },
  head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    background: `url(${HeaderImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#4288F0',
    padding: '28px',
    boxSizing: 'border-box',
    color: '#ffffff',
    fontWeight: 600,
  },
  asinSearchWrapper: { display: 'flex', flexDirection: 'column' },
  variationItem: { display: 'flex', justifyContent: 'space-between', mb: 1 },
  variationList: { maxWidth: '135px', fontSize: '11px' },
  variation: { width: '135px' },
  searchHead: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  searchForm: {
    p: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '523px',
    marginBottom: '5px',
    margin: '0 0 10px',
  },
  searchIcon: {
    p: '10px',
  },
  searchInput: {
    ml: 1,
    flex: 1,
  },
  table: { marginTop: 4 },
  cellText: { color: '#214254', fontSize: '12px' },
  export: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  exportBttn: {
    ml: 1,
    minWidth: '89px',
    height: '37px',
  },
  exportLoadingText: { fontSize: 12, color: theme.palette.grey[800], mt: 0.5 },
};

export function AsinFinder(): ReactElement {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState<Array<string>>([]);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isOpenAosModal, setIsOpenAosModal] = useState<boolean>(false);

  const exportTimer = useRef(null);

  const rows = useSelector(selectAsinsData);
  const loading = useSelector(selectAsinsLoading);
  const amount = useSelector(selectAsinsAmount);
  const limitValue = useSelector(selectAsinsLimit);
  const page = useSelector(selectAsinsPage);
  const variations = useSelector(selectAsinsVariations);
  const globalFilters = useSelector(selectGlobalFilters);
  const loadingReport = useSelector(selectGenerateReportLoading);
  const executionId = useSelector(selectExecutionId);

  const handleAosModalOpen = () => {
    setIsOpenAosModal(true);
  };

  const handleAosModalClose = () => {
    setIsOpenAosModal(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePageChange = (_e: MouseEvent<HTMLButtonElement>, page: number) => {
    dispatch(asinFinderActions.changePageAsins(page));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(asinFinderActions.changeLimitAsins(parseInt(event.target.value, 10)));
    dispatch(asinFinderActions.changePageAsins(0));
  };

  const handleChangeSearch = (search: string) => {
    const set = new Set(search.split(/(?:,| |, |\n)+/));
    setSearchValue(Array.from(set));
  };

  const handleExportCsv = () => {
    dispatch(asinFinderActions.downloadAsinsCsv(searchValue.toString()));
  };

  const handleGenerateAos = (values: AsinFinder.GenerateAosData) => {
    dispatch(asinFinderActions.generateReport(values));
  };

  const handleStopTimer = () => {
    clearTimeout(exportTimer.current);
    dispatch(asinFinderActions.removeExecutionId());
  };

  const debouncedAsins = useDebouncedCallback(() => {
    dispatch(asinFinderActions.getAsinsList({ limit: limitValue, offset: limitValue * page, search: searchValue }));
  }, 500);

  useEffect(() => {
    setIsInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInitialized) return;

    debouncedAsins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitValue, page, searchValue]);

  useEffect(() => {
    if (loadingReport && executionId) {
      exportTimer.current = setInterval(
        () =>
          dispatch(
            asinFinderActions.openReportLink(
              { id: String(globalFilters.publisher_id), code: executionId },
              handleStopTimer
            )
          ),
        2000
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingReport, executionId]);

  return (
    <>
      <Portal id="header-page">
        <Box sx={styles.head}>
          <KeyboardArrowLeftIcon color="inherit" sx={styles.headerArrow} />
          <Typography sx={styles.headTitle}>ASIN Article Finder</Typography>
        </Box>
      </Portal>
      <Box sx={styles.searchHead}>
        <Box sx={styles.asinSearchWrapper}>
          <SearchInput search={searchValue.toString()} handleSearchChange={handleChangeSearch} />
          {Boolean(variations?.length) && (
            <>
              <Box sx={styles.variationItem}>
                <Typography>ASINS</Typography>
              </Box>
              {variations.map(item => (
                <Box sx={styles.variationItem}>
                  <Typography sx={styles.variationList}>{item.asin}</Typography>
                </Box>
              ))}
            </>
          )}
        </Box>
        <Box sx={styles.export}>
          <Button
            color="primary"
            variant="contained"
            sx={styles.exportBttn}
            onClick={!loadingReport ? handleAosModalOpen : null}
          >
            {!loadingReport ? 'Export SR' : <CircularProgress size={14} color="inherit" />}
          </Button>
          {loadingReport && <Typography sx={styles.exportLoadingText}>It takes about 3 minutes</Typography>}
        </Box>
        {Boolean(rows?.length) && (
          <Button color="primary" variant="contained" sx={styles.exportBttn} onClick={handleExportCsv}>
            Export CSV
          </Button>
        )}
      </Box>
      <TableContainerWithLoading sx={styles.table} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.cellText}>Page</TableCell>
              <TableCell sx={styles.cellText}>Last Scraped</TableCell>
              <TableCell sx={styles.cellText}>Products</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map(page => (
              <PageRow row={page} />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <TablePagination
        component="div"
        count={amount}
        rowsPerPage={limitValue}
        rowsPerPageOptions={[10, 25, 50, 100]}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <AosModal
        open={isOpenAosModal}
        onClose={handleAosModalClose}
        onConfirm={handleGenerateAos}
        defaultAsins={searchValue}
      />
    </>
  );
}
