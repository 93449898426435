import { ReactElement } from 'react';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { Styles } from 'common/types';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { CrawlingRow } from './crawling-row';

const getStyles = (): Styles => ({
  table: { marginTop: 0 },
  cell: { color: '#21425480', fontSize: '10px' },
  usedCircle: {
    width: '8px',
    height: '8px',
    backgroundColor: '#62D294',
    borderRadius: '50%',
  },
  status: {
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E3EDFD',
    color: '#4288F0',
    fontSize: '12px',
    borderRadius: '20px',
  },
  anotherStatus: {
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E7F8EF',
    color: '#62D294',
    fontSize: '12px',
    borderRadius: '20px',
  },
});

const crawlingMock = [
  {
    domain: 'test.com',
    start_date: '1 Nov, 21',
    end_date: '5 Nov, 21',
    status: 'pending',
  },
  {
    domain: 'test2.com',
    start_date: '3 Nov, 21',
    end_date: '17 Nov, 21',
    status: 'pending',
  },
  {
    domain: 'test3.com',
    start_date: '5 Nov, 21',
    end_date: '22 Nov, 21',
    status: 'finished',
  },
  {
    domain: 'test4.com',
    start_date: '11 Nov, 21',
    end_date: '29 Nov, 21',
    status: 'error',
  },
];

export function Crawling(): ReactElement {
  const styles = getStyles();
  const loading = false;

  const handlePageChange = () => {
    //
  };

  return (
    <>
      <TableContainerWithLoading sx={styles.table} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.cell}>Domain</TableCell>
              <TableCell sx={styles.cell}>Start date</TableCell>
              <TableCell sx={styles.cell}>End date</TableCell>
              <TableCell sx={styles.cell}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {crawlingMock?.map(row => (
              <CrawlingRow
                domain={row?.domain}
                start_date={row?.start_date}
                end_date={row?.end_date}
                status={row?.status}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <TablePagination
        component="div"
        count={crawlingMock?.length}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        page={1}
        onPageChange={handlePageChange}
      />
    </>
  );
}
