import React, { useState, useEffect, useCallback, useRef, ChangeEvent } from 'react';
import { TextField, List, ListItem, Box } from '@mui/material';
import { Styles } from 'common/types';

interface PublisherSearchProps {
  publishers: { id: number; name: string }[];
  onSelect: (publisherId: number, publisherName: string) => void;
  selectedPublisherName: string;
}

const styles: Styles = {
  textField: {
    width: '210px',
  },
  publisherList: {
    border: '1px solid #ccc',
    maxHeight: '200px',
    overflowY: 'auto',
    position: 'absolute',
    width: '210px',
    backgroundColor: 'white',
    zIndex: 1,
  },
};

export function PublisherSearch({ publishers, onSelect, selectedPublisherName }: PublisherSearchProps) {
  const [search, setSearch] = useState(selectedPublisherName);
  const [filteredPublishers, setFilteredPublishers] = useState(publishers);
  const [showPublisherList, setShowPublisherList] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSearch(value);

      const filtered = publishers.filter(p => p.name.toLowerCase().includes(value.toLowerCase()));
      setFilteredPublishers(filtered);
    },
    [publishers]
  );

  const handlePublisherSelect = useCallback(
    (publisherId: number, publisherName: string) => {
      onSelect(publisherId, publisherName);
      setSearch(publisherName);
      setShowPublisherList(false);
    },
    [onSelect]
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      setShowPublisherList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box position="relative" ref={inputRef}>
      <TextField
        label="Select Publisher"
        value={search}
        onChange={handleSearchChange}
        onFocus={() => setShowPublisherList(true)}
        variant="outlined"
        size="small"
        autoComplete="off"
        sx={styles.textField}
      />
      {showPublisherList && filteredPublishers.length > 0 && (
        <List sx={styles.publisherList}>
          {filteredPublishers.map(p => (
            <ListItem key={p.id} button onClick={() => handlePublisherSelect(p.id, p.name)}>
              {p.name}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
}
