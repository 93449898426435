import { Box, Fab, Typography } from '@mui/material';
import { MENU_WIDTH_XL } from 'common/constants/sizes';
import { Styles } from 'common/types';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

const getStyles = (): Styles => ({
  emptyScreen: {
    height: '100%',
    width: `calc(100% - ${MENU_WIDTH_XL}px)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    margin: '-30px -40px',
    backdropFilter: 'blur(10px)',
    zIndex: 10,
  },
  dialog: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogImg: {
    height: '80%',
    width: '80%',
  },
  dialogTitle: {
    marginTop: '-30px',
    fontWeight: '500',
  },
  fab: {
    marginTop: '20px',
  },
});

interface Props {
  username: string;
}

export function NoDataScreen({ username }: Props): ReactElement {
  const styles = getStyles();

  const navigate = useNavigate();

  return (
    <Box sx={styles.emptyScreen}>
      <Box sx={styles.dialog}>
        <Box component="img" sx={styles.dialogImg} src="/assets/images/empty_pages/dialog_graph.webp" />
        <Typography variant="h4" sx={styles.dialogTitle}>
          Hi, {username}!
        </Typography>
        <Typography>Click below to embed javascript</Typography>
        <Fab
          size="large"
          color="primary"
          variant="extended"
          onClick={() => navigate('/publisher/embed')}
          sx={styles.fab}
        >
          Get started
        </Fab>
      </Box>
    </Box>
  );
}
