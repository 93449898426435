import { Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { asinsConversionsBoostActions } from 'modules/asins-conversions-boost/store/actions';
import { asinsConversionsBoostSelectors } from 'modules/asins-conversions-boost/store/selectors';
import { ReactElement, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Styles } from 'common/types';
import { ConversionsBoostRow } from './conversions-boost-row';

const styles: Styles = {
  table: { marginTop: 2 },
};

export function ConversionsBoostTable(): ReactElement {
  const dispatch = useDispatch();

  const conversionsBoost = useSelector(asinsConversionsBoostSelectors.selectConversionsBoost);

  const handlePageChange = (_e: MouseEvent<HTMLButtonElement>, page: number) => {
    dispatch(asinsConversionsBoostActions.conversionsBoostActions.changeConversionsBoostPage(page));
  };

  return (
    <>
      <TableContainerWithLoading sx={styles.table} loading={conversionsBoost.loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ASIN</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Publisher</TableCell>
              <TableCell>Boost</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {conversionsBoost?.data?.map(conversionBoost => (
              <ConversionsBoostRow key={conversionBoost.id} conversionBoost={conversionBoost} />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <TablePagination
        component="div"
        count={-1}
        rowsPerPage={50}
        rowsPerPageOptions={[50]}
        page={conversionsBoost.page}
        nextIconButtonProps={{ disabled: conversionsBoost.data?.length < 50 }}
        onPageChange={handlePageChange}
      />
    </>
  );
}
