import { accessActionTypes as TYPES } from './action-types';

const getAccess = (params: CommonModule.AccessParams) => ({
  type: TYPES.GET_ACCESS,
  payload: params,
});

const getAccessSuccess = (data: CommonModule.AccessData) => ({
  type: TYPES.GET_ACCESS_SUCCESS,
  payload: data,
});

const getAccessError = (error: Error) => ({
  type: TYPES.GET_ACCESS_ERROR,
  payload: error,
});

export const accessActions = {
  getAccess,
  getAccessSuccess,
  getAccessError,
};
