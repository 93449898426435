import moment from 'moment';
import { Action } from '../../../../common/interfaces/root';
import { amazonAffiliateReportsTypes } from '../actions';
import { UploadAmazonAffiliateReportState } from '../../types';

export const uploadReportInitState: UploadAmazonAffiliateReportState = {
  publisher_id: null,
  date: moment(new Date().setHours(0, 0, 0, 0)),
  file: null,
  report_type: null,
  loading: false,
  error: null,
};

export const uploadAmazonAffiliateReportReducer = (state = uploadReportInitState, action: Action) => {
  switch (action.type) {
    case amazonAffiliateReportsTypes.UPLOAD_REPORT:
      return { ...state, loading: true, error: null };
    case amazonAffiliateReportsTypes.UPLOAD_REPORT_SUCCESS:
      return { ...state, loading: false };
    case amazonAffiliateReportsTypes.UPLOAD_REPORT_ERROR:
      return { ...state, loading: false, error: action.payload };
    case amazonAffiliateReportsTypes.SET_UPLOAD_REPORT_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
