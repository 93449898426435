import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Styles } from 'common/types';
import { TableRowActions } from 'common/ui/table-row-actions';
import { parentPublisherSettingsSelectors } from 'modules/parent-publisher-settings/store';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { parentPublisherSettingsActions } from 'modules/parent-publisher-settings/store/actions';
import { DeleteModal } from 'common/ui/delete-modal';

const styles: Styles = {
  container: { p: 2, mt: 2, bgcolor: 'white', borderRadius: '20px' },
  loadingContainer: { display: 'flex', justifyContent: 'center' },
};

interface Props {
  handleEditClick?: (accout: ParentPublisherSettingsModule.PublisherAccount) => void;
}

export function ParentPublisherTable({ handleEditClick }: Props): ReactElement {
  const dispatch = useDispatch();

  const accounts = useSelector(parentPublisherSettingsSelectors.selectPublisherAccountsParents);

  const [selectedRow, setSelectedRow] = useState<ParentPublisherSettingsModule.PublisherAccount | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setSelectedRow(null);
  };

  const handleDeleteClick = (row: ParentPublisherSettingsModule.PublisherAccount) => {
    setDeleteModalOpen(true);
    setSelectedRow(row);
  };

  const handleDeleteConfirm = () => {
    dispatch(parentPublisherSettingsActions.deletePublisherAccountsParents(selectedRow.id));
    handleDeleteModalClose();
  };

  return (
    <Box sx={styles.container}>
      <TableContainerWithLoading loading={accounts.loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Parent Name AOS</TableCell>
              <TableCell>Parent ID AOS</TableCell>
              <TableCell>Child Publisher Count</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts?.data?.publisher_accounts.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row?.parent_name_aos || '-'}</TableCell>
                <TableCell>{row?.parent_id_aos || '-'}</TableCell>
                <TableCell>{row?.publishers?.length || 0}</TableCell>
                <TableCell>
                  <TableRowActions
                    onEdit={() => handleEditClick(row)}
                    onDelete={() => handleDeleteClick(row)}
                    show={['edit', 'delete']}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <DeleteModal
        open={deleteModalOpen}
        entry={selectedRow?.parent_name_aos}
        onClose={handleDeleteModalClose}
        onConfirm={handleDeleteConfirm}
      />
    </Box>
  );
}
