import { combineReducers } from 'redux';
import { getDataListReducer } from 'common/store/reducers/data-list';
import { getFiltersReducer } from 'common/store/reducers/filters';
import { IProduct } from 'common/ui/product/types';
import { ANY_OOS_RANGE } from 'modules/content-overview/components/utils/generate-oos-dates';
import { ContentType } from 'modules/content-overview/components/pages/page-performance/content-type-filter/content-type-filter.types';
import {
  crawlingActionTypes,
  managementFiltersActionTypes,
  pagesPerformanceActionTypes,
  pagesPerformanceFiltersActionTypes,
  pagesActionTypes,
  productReplacementsActionTypes,
  replacementRecomendationsActionTypes,
  replacementsFiltersActionTypes,
  sitemapsActionsTypes,
} from '../actions';
import crawlingStatus from './crawling-status';
import reportReducer from './report';
import statsReducer from './stats';
import recommendationsReducer from './recommendations';
import scheduleReducer from './schedule';
import pagePerformanceArticle from './page-performance-article';
import performanceComparisons from './performance-comparisons';

export const contentOverviewReducers = combineReducers({
  pages: getDataListReducer<ContentOverviewModule.PageEntry>(pagesActionTypes),
  replacements: getDataListReducer<ContentOverviewModule.PageWithProducts>(productReplacementsActionTypes),
  sitemaps: getDataListReducer<ContentOverviewModule.Sitemap>(sitemapsActionsTypes),
  replacementsFilters: getFiltersReducer(replacementsFiltersActionTypes),
  managementFilters: getFiltersReducer(managementFiltersActionTypes),
  replacementRecomendations: getDataListReducer<IProduct>(replacementRecomendationsActionTypes),
  crawling: getDataListReducer<ContentOverviewModule.CrawledUrl>(crawlingActionTypes),
  pagesPerformance: getDataListReducer<ContentOverviewModule.PagePerformance>(pagesPerformanceActionTypes, {
    limit: 30,
  }),
  pagesPerformanceFilters: getFiltersReducer(pagesPerformanceFiltersActionTypes, {
    in_stock: 2,
    oos_range: ANY_OOS_RANGE,
    article_type: ContentType.ALL,
    oos_status: -1,
    searchType: 'url',
    saturation_operator: 'LESS_THAN_OR_EQUAL_TO',
    saturation_value: 100,
  }),
  pagePerformanceArticle,
  report: reportReducer,
  stats: statsReducer,
  recommendations: recommendationsReducer,
  crawlingStatus,
  schedule: scheduleReducer,
  performanceComparisons,
});
