import { amazonAffiliateReportsTypes as actionTypes } from './action-types';
import {
  RecoverAmazonAffiliateReportsState,
  RecoverMissingReportsRequestBody,
  UploadAmazonAffiliateReportState,
  UploadAmazonReportRequestBody,
} from '../../types';

const uploadReport = (payload: UploadAmazonReportRequestBody) => ({
  type: actionTypes.UPLOAD_REPORT,
  payload,
});

const uploadReportSuccess = () => ({
  type: actionTypes.UPLOAD_REPORT_SUCCESS,
});

const uploadReportError = (error: Error) => ({
  type: actionTypes.UPLOAD_REPORT_ERROR,
  payload: error,
});

const setUploadReportState = (state: Partial<UploadAmazonAffiliateReportState>) => {
  return {
    type: actionTypes.SET_UPLOAD_REPORT_STATE,
    payload: state,
  };
};

const recoverMissingReports = (payload: RecoverMissingReportsRequestBody) => ({
  type: actionTypes.RECOVER_MISSING_REPORTS,
  payload,
});

const recoverMissingReportsSuccess = () => ({
  type: actionTypes.RECOVER_MISSING_REPORTS_SUCCESS,
});

const recoverMissingReportsError = (error: Error) => ({
  type: actionTypes.RECOVER_MISSING_REPORTS_ERROR,
  payload: error,
});

const setRecoverMissingReportsState = (state: Partial<RecoverAmazonAffiliateReportsState>) => {
  return {
    type: actionTypes.SET_RECOVER_MISSING_REPORTS_STATE,
    payload: state,
  };
};

export const amazonAffiliateReportsActions = {
  uploadReport,
  uploadReportSuccess,
  uploadReportError,
  recoverMissingReports,
  recoverMissingReportsSuccess,
  recoverMissingReportsError,
  setRecoverMissingReportsState,
  setUploadReportState,
};
