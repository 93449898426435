import { ApiService } from '../../../common/services';
import {
  RecoverMissingReportsRequestBody,
  ReportPostProcessingRequestBody,
  UploadAmazonReportRequestBody,
} from '../types';

class AmazonAffiliateReportsApiService extends ApiService {
  uploadReport = (data: UploadAmazonReportRequestBody) =>
    this.postFormData('/api/affiliate/upload-amazon-report', data);

  reportPostProcessing = (data: ReportPostProcessingRequestBody) =>
    this.post('/api//data-workflows/report-post-processing', data);

  recoverMissingReports = (data: RecoverMissingReportsRequestBody) =>
    this.post('/api/data-workflows/recover-missing-reports', data);
}

export const amazonAffiliateReportsApiService = new AmazonAffiliateReportsApiService(process.env.REACT_APP_API_URL);
