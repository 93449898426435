import { Box } from '@mui/material';
import { paper } from 'common/constants/colors';
import { Styles } from 'common/types';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

interface StylesProps {
  active: boolean;
}

const getStyles = ({ active }: StylesProps): Styles => ({
  row: {
    backgroundColor: active ? '#f5f5f5' : paper,
    borderBottom: '1px solid #e5e5e5',
    height: 48,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0 4px',
    ':hover': {
      backgroundColor: '#f5f5f5',
    },
  },
});

interface Props {
  active: boolean;
  handlePublisherChange: (publisher: number | null | string) => void;
  title: string;
  value: number | string;
  handlePopoverClose: () => void;
  disableRedirect?: boolean;
}

export function PublisherRow({
  active,
  handlePublisherChange,
  title,
  value,
  handlePopoverClose,
  disableRedirect = false,
}: Props): ReactElement {
  const styles = getStyles({ active });
  const navigate = useNavigate();

  const handlePublisherClick = () => {
    handlePublisherChange(value);
    if (disableRedirect) navigate('/');
    handlePopoverClose();
  };

  return (
    <Box sx={styles.row} onClick={handlePublisherClick}>
      {title}
    </Box>
  );
}
