import { Modal } from 'common/ui/modal';
import { ReactElement } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { asinsConversionsBoostActions } from 'modules/asins-conversions-boost/store/actions';
import { asinsConversionsBoostSelectors } from 'modules/asins-conversions-boost/store/selectors';
import * as Yup from 'yup';
import { FormContent } from './form-content';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const validationSchema = Yup.object({
  asin: Yup.string()
    .required('Required')
    .matches(/^[A-Za-z0-9]{10}$/, 'Must be a valid ASIN'),
  brand_id: Yup.number(),
  publisher_id: Yup.number(),
  boost: Yup.number()
    .typeError('Must be a number')
    .required('Required')
    .min(0, 'Must be greater than or equal to 0')
    .max(1, 'Must be less than or equal to 1'),
});

export function FormModal({ isOpen, onClose }: Props): ReactElement {
  const dispatch = useDispatch();

  const filters = useSelector(asinsConversionsBoostSelectors.selectFilters);

  const getInitialValues = (): AsinsConversionsBoostModule.ConversionBoostForm => ({
    asin: undefined,
    brand_id: undefined,
    publisher_id: undefined,
    boost: undefined,
  });

  const onSuccess = () => {
    dispatch(asinsConversionsBoostActions.conversionsBoostActions.getConversionsBoost(filters));
    onClose();
  };

  const handleSubmit = (values: AsinsConversionsBoostModule.ConversionBoostForm) => {
    dispatch(
      asinsConversionsBoostActions.conversionsBoostActions.postConversionBoost(
        {
          ...values,
        },
        onSuccess
      )
    );
  };

  return (
    <Modal open={isOpen} title="Add conversion boost" maxWidth="sm" onClose={onClose}>
      <Formik initialValues={getInitialValues()} onSubmit={handleSubmit} validationSchema={validationSchema}>
        <FormContent onClose={onClose} isModalOpen={isOpen} />
      </Formik>
    </Modal>
  );
}
