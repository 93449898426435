import { getFiltersActions } from 'common/store/actions/filters';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { tagsActionTypes as T, tagsFiltersActionTypes } from './action-types';

const getTags = (filters: IStatisticsQueryString) => ({
  type: T.GET_TAGS,
  payload: filters,
});

const getTagsSuccess = (tags: Array<TagsModule.Tag>, count: number) => ({
  type: T.GET_TAGS_SUCCESS,
  payload: { tags, count },
});

const getTagsError = (error: Error) => ({
  type: T.GET_TAGS_ERROR,
  payload: error,
});

const changeTagsPage = (page: number) => ({
  type: T.CHANGE_TAGS_PAGE,
  payload: page,
});

export const tagsActions = {
  getTags,
  getTagsSuccess,
  getTagsError,
  changeTagsPage,
};

export const tagsFiltersActions = getFiltersActions(tagsFiltersActionTypes);
