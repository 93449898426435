import { ReactElement } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { columnHeaders, StatsRow } from 'modules/stats/mock';
import HelpIcon from '@mui/icons-material/Help';
import { StatsTableRow, StatsTablePagination } from 'modules/stats/components';
import { Styles } from 'common/types';

const styles: Styles = {
  tableSortLabel: { color: '#6F8490' },
  iconBox: {
    height: '40px',
  },
  icon: {
    fontSize: 14,
  },
  boxContainer: { backgroundColor: 'white', marginTop: 2, padding: '40px', borderRadius: '20px' },
};

interface Props {
  rows: StatsRow[];
}

export const generateHeader = (header: string, info: boolean): ReactElement => {
  if (info) {
    return (
      <TableSortLabel sx={styles.tableSortLabel}>
        {header}
        <Box sx={styles.iconBox}>
          <HelpIcon sx={styles.icon} />
        </Box>
      </TableSortLabel>
    );
  }

  return <TableSortLabel sx={styles.tableSortLabel}>{header}</TableSortLabel>;
};

export function StatsTableContainer({ rows }: Props): ReactElement {
  return (
    <Box sx={styles.boxContainer}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columnHeaders.map(header => (
                <TableCell key={columnHeaders.indexOf(header)}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <StatsTableRow key={rows.indexOf(row)} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <StatsTablePagination />
    </Box>
  );
}
