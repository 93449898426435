import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { NavItem } from 'common/constants/nav';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Styles } from 'common/types';
import { MouseEvent, SyntheticEvent, useEffect, useState } from 'react';
import { handleActivePath } from 'common/utils';
import { paper, text } from 'common/constants/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavItems } from 'root/layout/menu/nav-items';
import { theme } from 'common/constants/theme';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface StylesProps {
  open: boolean;
  hovered: boolean;
  active: boolean;
  level: number;
}

const getStyles = ({ open, hovered, level, active }: StylesProps): Styles => ({
  container: {
    boxShadow: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    ':before': { display: 'none' },
  },
  summary: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: paper,
    paddingLeft: level > 1 ? `${level * 8}px` : 0,
  },
  title: {
    fontSize: 16,
    fontWeight: 400,
    // eslint-disable-next-line no-nested-ternary
    color: hovered || active ? text : '#6F8490',
    transition: 'color 0.15s ease-in-out',
  },
  icon: { color: hovered || active ? text : '#6F8490', transition: 'color 0.15s ease-in-out' },
  arrow: {
    color: hovered ? text : '#6F8490',
    marginLeft: 'auto',
    transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
    transition: 'transform 0.15s ease-in-out, color 0.15s ease-in-out',
  },
  iconTitleContainer: { display: 'flex', alignItems: 'center' },
  customNestedDetails: { padding: '8px 0' },
  titleContainer: { position: 'relative', marginLeft: 2 },
  newVersion: {
    fontSize: 11,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    position: 'absolute',
    top: -10,
    left: 0,
  },
  newIcon: { width: 10, height: 10 },
});

interface Props {
  config: NavItem;
  level: number;
  nestedExpands?: Array<() => void>;
}

export function NavRowItem({ config, level, nestedExpands }: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [expanded, setExpanded] = useState<boolean>(false);
  const [hovered, setHovered] = useState<boolean>(false);

  const styles = getStyles({
    open: expanded,
    level,
    hovered,
    active: config.path ? handleActivePath(config.path, location) : false,
  });

  const handleExpand = (e: SyntheticEvent, expanded: boolean) => {
    if (!config.nested?.length) return;
    setExpanded(expanded);
  };

  const handleExpandOpen = () => {
    setExpanded(true);
  };

  const handleOpen = () => {
    if (config.nested?.length) return;
    navigate(config.path);
  };

  const handleHover = () => {
    setHovered(hovered => !hovered);
  };

  const handleOpenNewVersion = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    navigate(config.newVersion?.path);
  };

  useEffect(() => {
    const active = config.path ? handleActivePath(config.path, location) : false;
    if (!active) return;
    nestedExpands.forEach(expand => expand());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Accordion
      sx={styles.container}
      onChange={handleExpand}
      onClick={handleOpen}
      expanded={expanded}
      square
      disableGutters
    >
      <AccordionSummary onMouseEnter={handleHover} onMouseLeave={handleHover} className={config.target}>
        <Box sx={styles.summary}>
          <config.icon sx={styles.icon} />
          <Box sx={styles.titleContainer}>
            <Typography sx={styles.title}>{config.title}</Typography>
            {config.newVersion && (
              <Box sx={styles.newVersion} onClick={handleOpenNewVersion}>
                {config.newVersion.title} <OpenInNewIcon sx={styles.newIcon} />
              </Box>
            )}
          </Box>
          {Boolean(config.nested?.length) && <KeyboardArrowDownIcon sx={styles.arrow} />}
        </Box>
      </AccordionSummary>
      {config.nested?.length && (
        <AccordionDetails sx={config.nested ? styles.customNestedDetails : {}}>
          <NavItems level={level + 1} items={config.nested} nestedExpands={[...nestedExpands, handleExpandOpen]} />
        </AccordionDetails>
      )}
    </Accordion>
  );
}
