import { ReactElement } from 'react';
import { Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material';
import { Styles } from 'common/types';

const styles: Styles = {
  clientTitle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '250px',
  },
};

interface Props {
  item: PublishersSettingsModule.ParentTeamData;
  inviteClientsIds: number[];
  handleChangeInviteClientsIds: (checked: boolean, ids: Array<number>) => void;
}

export function ParentItem({ item, inviteClientsIds, handleChangeInviteClientsIds }: Props): ReactElement {
  return (
    <FormControlLabel
      label={
        <Tooltip title={item?.name}>
          <Typography sx={styles.clientTitle}>{item?.name}</Typography>
        </Tooltip>
      }
      control={
        <Checkbox
          checked={
            item?.clients?.length ===
            item?.clients?.filter(cl => inviteClientsIds?.find(client => Number(client) === Number(cl?.id)))?.length
          }
          onChange={(event, checked) =>
            handleChangeInviteClientsIds(
              checked,
              item?.clients?.map(cln => cln.id)
            )
          }
        />
      }
    />
  );
}
