/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, MenuItem, TextField, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { selectDatePickerComparator, selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import {
  contentOverviewActions,
  pagesPerformanceActions,
  pagesPerformanceFiltersActions,
} from 'modules/content-overview/store/actions';
import {
  selectPagesPerformanceFilters,
  selectPagesPerformanceLimitPageAmount,
} from 'modules/content-overview/store/selectors';
import { ReactElement, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { IStatisticsQueryString, ITableSort } from 'common/ui/base-filters/types';
import { publisherGroups } from 'common/constants/publisher-groups';
import { useDebouncedCallback } from 'use-debounce';
import { theme } from 'common/constants/theme';
import moment, { Moment } from 'moment';
import { contentOverviewApi } from 'modules/content-overview/services/content-overview.service';
import toast from 'react-hot-toast';
import { SearchInput } from 'common/ui/search-input';
import HeaderImg from 'common/svg/header-img.png';
import { Portal } from 'common/ui/portal';
import { FilterButton } from 'common/ui/filter-button';
import { DownloadButton } from 'common/ui/download-button';
import { selectCombined } from 'modules/overview-v2/store/selectors';
import { BaseFilters } from 'common/ui/base-filters';
import { datePickerComparatorActions } from 'common/store/actions/date-picker-comparator';
import { filtersActions } from 'common/store/actions/filters';
import { PerformanceTableSortField } from '../../constants';
import { ColumnsPopoverContext } from './page-performance-table/columns-popover/columns-popover-context';
import { FiltersModal } from './filters-modal';
import { getLast7DaysOOSRange } from '../../utils/generate-oos-dates';
import { ContentType } from './content-type-filter/content-type-filter.types';
import { PagePerformanceTable } from './page-performance-table';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', minHeight: '95vh' },
  filtersContainer: { display: 'flex', flexDirection: 'column' },
  filters: { display: 'flex', alignItems: 'center', gap: 1.5 },
  headTitle: {
    color: '#ffffff',
    fontWeight: 600,
  },
  head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: `url(${HeaderImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#4288F0',
    padding: '28px',
    boxSizing: 'border-box',
    color: '#ffffff',
    fontWeight: 600,
  },
  button: { ml: 1, height: '37px', minWidth: '130px' },
  select: { marginLeft: 2, width: 200, ':first-child': { marginLeft: 0 } },
  exportLoadingText: { fontSize: 12, color: theme.palette.grey[800], mt: 0.5 },
  field: {
    backgroundColor: theme.palette.background.default,
    '& fieldset': { border: 'none' },
    borderRadius: 12,
    minWidth: 140,
  },
  downloadAllBtn: {
    display: 'flex',
    border: '1px #fff solid',
    borderRadius: '10px',
    padding: '5px 5px',
    ':hover': {
      cursor: 'pointer',
    },
  },
  downloadAllBtnIcon: {
    marginLeft: '5px',
    marginTop: 'auto',
  },
};

const SearchTypeOptions = [
  { title: 'URL', value: 'url' },
  { title: 'Brand name', value: 'brand_name' },
];

export function PagePerformance(): ReactElement {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { limit, page } = useSelector(selectPagesPerformanceLimitPageAmount);
  const globalFilters = useSelector(selectGlobalFilters);
  const filters = useSelector(selectPagesPerformanceFilters);
  const { isCombined } = useSelector(selectCombined);
  const { dateRanges, isCompare } = useSelector(selectDatePickerComparator);

  const exportTimer = useRef(null);

  const [sort, setSort] = useState<ITableSort>({ field: 'clicks', direction: 'desc' });
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [filtersModalOpen, setFiltersModalOpen] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [exportAdvonPublishersLoading, setExportAdvonPublishersLoading] = useState(false);

  const datesToCompareParam = useMemo<string>(() => {
    if (isCompare)
      return `${moment(dateRanges?.range1?.startDate).format()}_${moment(
        dateRanges?.range1?.endDate
      ).format()},${moment(dateRanges?.range2?.startDate).format()}_${moment(dateRanges?.range2?.endDate).format()}`;
    return '';
  }, [dateRanges, isCompare]);

  const parsedGlobalFilters = useMemo(
    () => ({
      publisher_id: globalFilters.publisher_id,
      ...(isCombined ? { publisher_group: Number(publisherGroups[globalFilters.publisher_group]) } : {}),
      search: filters.search,
      ...(!isCompare
        ? {
            date_from: globalFilters.date_from.clone().startOf('day').tz('UTC', true),
          }
        : {}),
      ...(!isCompare
        ? {
            date_to: globalFilters.date_to.clone().startOf('day').tz('UTC', true),
          }
        : {}),
      offset: page * limit,
      saturation_operator: filters.saturation_operator,
      saturation_value: filters.saturation_value,
      ...(isCompare ? { dates_to_compare: datesToCompareParam } : {}),
      limit,
      sort,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filters.search,
      globalFilters.date_from,
      globalFilters.date_to,
      page,
      limit,
      sort,
      globalFilters.publisher_id,
      datesToCompareParam,
      filters.searchType,
    ]
  );

  const parsedFilters = useMemo(
    () => ({
      ...parsedGlobalFilters,
      in_stock: filters.in_stock,
      oos_range: filters.oos_range,
      ...(filters.oos_status !== -1 ? { oos_status: filters.oos_status } : {}),
      affiliate_network_type: filters.affiliate_network_type,
      traffic_type: filters.traffic_type,
      article_type: filters.article_type !== ContentType.ALL ? filters.article_type : null,
      limit,
      sort,
      searchType: filters.searchType,
      saturation_value: filters.saturation_value,
      saturation_operator: filters.saturation_operator,
    }),
    [filters, parsedGlobalFilters]
  );

  const debouncedTableStatistics = useDebouncedCallback((filters: IStatisticsQueryString) => {
    if (filters.oos_range.to === getLast7DaysOOSRange().to) {
      filters.oos_range = {
        from: undefined,
        to: filters.oos_range.from,
      };
    } else {
      filters.oos_range = {
        from: filters.oos_range.to,
        to: undefined,
      };
    }

    if (filters.article_type === ContentType.ALL) {
      delete filters.article_type;
    }

    dispatch(
      pagesPerformanceActions.get({
        ...filters,
        search: filters.searchType === 'url' ? filters.search : null,
        brand_name: filters.searchType === 'brand_name' ? filters.search : null,
      })
    );
  }, 300);

  const handleFiltersModalOpen = () => {
    setFiltersModalOpen(true);
  };

  const handleFiltersModalClose = () => {
    setFiltersModalOpen(false);
  };

  const handleSearchChange = useCallback(
    (search: string) => {
      dispatch(pagesPerformanceActions.changePage(0));
      dispatch(pagesPerformanceFiltersActions.changeSearch(search));
      setSearchParams({ search });
    },
    [dispatch, setSearchParams]
  );

  const getSortDirection = (field: PerformanceTableSortField): 'asc' | 'desc' => {
    if (field === sort.field && sort.direction === 'asc') return 'desc';
    if (field === sort.field && sort.direction === 'desc') return 'asc';
    if (field !== sort.field) return sort.direction;
    return 'asc';
  };

  const handleSortCreation = (field: PerformanceTableSortField) => () => {
    setSort({ field, direction: getSortDirection(field) });
  };

  const handleRangeChange = (from: Moment, to: Moment) => {
    if (!isCompare) dispatch(filtersActions.changeRange(from, to));
  };

  const handleSearchTypeChange = (type: string) => {
    dispatch(pagesPerformanceFiltersActions.changeSearchType(type));
  };

  const downloadAllPublishers = (stopAdvonPubsLoading = false) => {
    contentOverviewApi
      .exportPerformance(
        {
          ...parsedFilters,
          limit: filters.exportLimit ? filters.exportLimit : undefined,
          offset: undefined,
        },
        [globalFilters.publisher_id]
      )
      .then(({ data }) => {
        if (data.link) {
          window.open(data.link);
        }
      })
      .catch(err => {
        if (err instanceof AxiosError) {
          toast.error(err.response.data?.message);
          return;
        }
        toast.error('Page performance export failed!');
      })
      .finally(() => {
        if (stopAdvonPubsLoading) {
          setExportAdvonPublishersLoading(false);
        }
      });
  };

  const handleExport = () => {
    if (exportLoading) return;
    if (isCombined) {
      downloadAllPublishers();
      return;
    }

    setExportLoading(true);

    contentOverviewApi
      .exportPerformance(
        {
          ...parsedFilters,
          limit: filters.exportLimit ? filters.exportLimit : undefined,
          offset: undefined,
        },
        [globalFilters.publisher_id]
      )
      .then(({ data }) => {
        exportTimer.current = window.setInterval(async () => {
          try {
            const { data: executedData } = await contentOverviewApi.checkExportPerformance(data.execution_id);
            if (executedData.link) {
              window.open(executedData.link);
              setExportLoading(false);
              window.clearInterval(exportTimer.current);
            }
          } catch (err) {
            toast.error('Page performance export failed!');
          }
        }, 10000);
      })
      .catch(() => toast.error('Page performance export failed!'));
  };

  const handleExportAll = () => {
    if (!isCombined) {
      toast.error('Choose All publishers');
      return;
    }

    setExportAdvonPublishersLoading(true);
    downloadAllPublishers(true);
  };

  const handleApply = () => {
    dispatch(pagesPerformanceActions.get(parsedFilters));
    handleFiltersModalClose();
  };

  const handleReset = () => {
    dispatch(pagesPerformanceFiltersActions.resetFilters());
  };

  useEffect(() => {
    if (!isInitialized) return;

    debouncedTableStatistics(parsedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedGlobalFilters, isCompare]);

  useEffect(() => {
    if (dateRanges.range1 && dateRanges.range2 && isInitialized) dispatch(pagesPerformanceActions.get(parsedFilters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRanges]);

  useEffect(() => {
    dispatch(contentOverviewActions.removeExecutionId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilters.publisher_id]);

  useEffect(() => {
    setIsInitialized(true);
    dispatch(pagesPerformanceActions.get(parsedFilters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    const search = searchParams.get('search');
    if (!search) return;
    handleSearchChange(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSearchChange]);

  useEffect(() => {
    if (isInitialized) {
      const numberOfDays = moment(dateRanges?.range1?.endDate).diff(dateRanges?.range1?.startDate, 'days');
      dispatch(
        datePickerComparatorActions.changeDateRanges({
          ...dateRanges,
          range2: {
            startDate: moment(dateRanges?.range1?.startDate)
              .subtract(numberOfDays + 1, 'days')
              .toDate(),
            endDate: moment(dateRanges?.range1?.startDate).subtract(1, 'days').toDate(),
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRanges.range1]);

  useEffect(() => {
    if (isInitialized) dispatch(contentOverviewActions.changeDatesToCompare(datesToCompareParam));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datesToCompareParam]);

  return (
    <Box sx={styles.container}>
      <Portal id="header-page">
        <Box sx={styles.head}>
          <Typography sx={styles.headTitle}>Page Performance</Typography>
          <Box sx={styles.filtersContainer}>
            <Box sx={styles.filters}>
              <SearchInput search={filters.search} handleSearchChange={handleSearchChange} />
              <TextField
                select
                sx={styles.field}
                onChange={e => handleSearchTypeChange(e.target.value)}
                value={filters.searchType}
                size="small"
              >
                {SearchTypeOptions.map(source => (
                  <MenuItem key={source.value} value={source.value}>
                    {source.title}
                  </MenuItem>
                ))}
              </TextField>
              <FilterButton handleFiltersOpen={handleFiltersModalOpen} color="light" />
              <BaseFilters
                show={['date', 'compareDates']}
                date={{
                  from: isCompare ? moment(dateRanges?.range1?.startDate) : globalFilters.date_from,
                  to: isCompare ? moment(dateRanges?.range1?.endDate) : globalFilters.date_to,
                }}
                handleRangeChange={handleRangeChange}
              />
              <DownloadButton handleDownload={handleExport} loading={exportLoading} color="light" />
              <Box onClick={handleExportAll} sx={styles.downloadAllBtn}>
                <Typography>Advon publishers </Typography>
                {exportAdvonPublishersLoading ? (
                  <CircularProgress size={14} color="inherit" sx={styles.downloadAllBtnIcon} />
                ) : (
                  <FileDownloadIcon fontSize="small" sx={styles.downloadAllBtnIcon} />
                )}
              </Box>
            </Box>
            {exportLoading && (
              <Typography sx={styles.exportLoadingText}>Generating report, this could take a few minutes</Typography>
            )}
          </Box>
        </Box>
      </Portal>
      <ColumnsPopoverContext>
        <PagePerformanceTable handleSortCreation={handleSortCreation} sort={sort} />
      </ColumnsPopoverContext>
      <FiltersModal
        isOpen={filtersModalOpen}
        onClose={handleFiltersModalClose}
        handleApply={handleApply}
        handleReset={handleReset}
      />
    </Box>
  );
}
