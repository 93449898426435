import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import axios from 'axios';
import moment from 'moment';
import toast from 'react-hot-toast';
import { amazonAffiliateReportsActions, amazonAffiliateReportsTypes } from '../actions';
import { amazonAffiliateReportsApiService } from '../../services/amazon-affiliate-reports-api-service';
import { recoverAmazonMissingReportsInitState } from '../reducers/recoverAmazonAffiliateReportsReducer';
import { uploadReportInitState } from '../reducers/uploadAmazonAffiliateReportReducer';

function* uploadAmazonAffiliateReport({
  payload,
}: ReturnType<typeof amazonAffiliateReportsActions.uploadReport>): SagaIterator {
  try {
    const uploadReportResponse = yield call(amazonAffiliateReportsApiService.uploadReport, payload);
    yield put(amazonAffiliateReportsActions.uploadReportSuccess());
    toast.success('Report has been downloaded successfully!');

    if (uploadReportResponse.status === 201) {
      yield call(amazonAffiliateReportsApiService.reportPostProcessing, {
        report_type: payload.report_type,
        report_uploaded_date: moment(new Date().setHours(0, 0, 0, 0)).tz('EST', true).toISOString(),
        publisher_id: payload.publisher_id,
        report_end_date: payload.date,
        report_start_date: payload.date,
      });
      yield put(amazonAffiliateReportsActions.setUploadReportState(uploadReportInitState));
      yield put(amazonAffiliateReportsActions.uploadReportSuccess());
    } else {
      yield put(
        amazonAffiliateReportsActions.uploadReportError({
          name: 'upload report error',
          message: 'Something went wrong',
        })
      );
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(amazonAffiliateReportsActions.uploadReportError(e));
      toast.error(`${e.code} ${e.message}`);
    } else {
      toast.error((e as Error).message);
      yield put(amazonAffiliateReportsActions.uploadReportError(e as Error));
    }
  }
}

function* recoverAmazonAffiliateMissingReports({
  payload,
}: ReturnType<typeof amazonAffiliateReportsActions.recoverMissingReports>): SagaIterator {
  try {
    yield call(amazonAffiliateReportsApiService.recoverMissingReports, payload);
    yield put(amazonAffiliateReportsActions.setRecoverMissingReportsState(recoverAmazonMissingReportsInitState));
    toast.success('Request has been sent successfully!');
    yield put(amazonAffiliateReportsActions.recoverMissingReportsSuccess());
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(amazonAffiliateReportsActions.recoverMissingReportsError(e));
      toast.error(`${e.code} ${e.message}`);
    } else {
      toast.error((e as Error).message);
      yield put(amazonAffiliateReportsActions.recoverMissingReportsError(e as Error));
    }
  }
}

export function* amazonAffiliateReportsSaga(): SagaIterator {
  yield all([takeEvery(amazonAffiliateReportsTypes.UPLOAD_REPORT, uploadAmazonAffiliateReport)]);
  yield all([takeEvery(amazonAffiliateReportsTypes.RECOVER_MISSING_REPORTS, recoverAmazonAffiliateMissingReports)]);
}
