import { Grid } from '@mui/material';
import { carrickTips } from 'common/constants/tips';
import { CardContainer } from 'common/ui/card-container';
import { conversionsSelectors } from 'modules/conversions/store/selectors';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import SyncIcon from '@mui/icons-material/Sync';
import { useNavigate } from 'react-router-dom';
import { currencyFormatter } from 'modules/conversions/utils/table';
import AdUnitsIcon from '@mui/icons-material/AdUnits';

export function CardsContainer(): ReactElement {
  const nav = useNavigate();

  const table = useSelector(conversionsSelectors.selectTable);
  const conversions = useSelector(conversionsSelectors.selectConversions);

  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <CardContainer
          title="Total Conversions"
          value={table.rows.length}
          loading={conversions.loading}
          tip={carrickTips.trackingOverview.conversionsPage.totalConversions}
          icon={SyncIcon}
          shouldEmbed={!conversions?.data?.is_any_conversion_exist}
          embedText="No data yet. Please link your Amazon account or upload to view your revenue."
          embedButtonText="Connect"
          embedCallback={() => nav('/publisher/affiliate')}
        />
      </Grid>
      <Grid item xs={3}>
        <CardContainer
          title="Total Ad Fees"
          value={currencyFormatter.format(table.total)}
          loading={conversions.loading}
          icon={AdUnitsIcon}
          shouldEmbed={!conversions?.data?.is_any_conversion_exist}
          embedText="No data yet. Please link your Amazon account or upload to view your revenue."
          embedButtonText="Connect"
          embedCallback={() => nav('/publisher/affiliate')}
        />
      </Grid>
    </Grid>
  );
}
