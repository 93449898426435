import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { convActions } from 'modules/conversions/store/actions';
import { tableActions } from 'modules/conversions/store/actions/table';
import { conversionsSelectors } from 'modules/conversions/store/selectors';
import { generateRows } from 'modules/conversions/utils';
import moment from 'moment';
import { ChangeEvent, MouseEvent, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConversionsTableRow } from 'modules/conversions/components/conversions-table-row';

const styles: Styles = {
  boxContainer: { backgroundColor: 'white', padding: '20px', borderRadius: '20px', marginTop: 3 },
  boxLoading: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' },
  boxCell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '400px',
  },
  combinedRowCell1: {
    borderBottom: 'none',
    paddingBottom: 0,
  },
  combinedRowCell2: {
    borderBottom: 'none',
    paddingTop: 0,
    paddingBottom: 0,
  },
  combinedRowCell3: {
    paddingTop: 0,
  },
};

const headers: {
  [x: string]: {
    title: string;
    value: string;
    asc?: (rows: Conversions.Table.TableRow[]) => Conversions.Table.TableRow[];
    desc?: (rows: Conversions.Table.TableRow[]) => Conversions.Table.TableRow[];
  };
} = {
  pageUrl: {
    title: 'Page URL',
    value: 'pageUrl',
    asc: (rows: Conversions.Table.TableRow[]) => rows.sort((a, b) => a.pageUrl.localeCompare(b.pageUrl)),
    desc: (rows: Conversions.Table.TableRow[]) => rows.sort((a, b) => a.pageUrl.localeCompare(b.pageUrl)).reverse(),
  },
  timeOfVisit: {
    title: 'Time of visit',
    value: 'timeOfVisit',
    asc: (rows: Conversions.Table.TableRow[]) => rows.sort((a, b) => moment(a.timeOfVisit).diff(moment(b.timeOfVisit))),
    desc: (rows: Conversions.Table.TableRow[]) =>
      rows.sort((a, b) => moment(b.timeOfVisit).diff(moment(a.timeOfVisit))),
  },
  trackingId: {
    title: 'Tracking ID',
    value: 'trackingId',
    asc: (rows: Conversions.Table.TableRow[]) => rows.sort((a, b) => a.trackingId.localeCompare(b.trackingId)),
    desc: (rows: Conversions.Table.TableRow[]) =>
      rows.sort((a, b) => a.trackingId.localeCompare(b.trackingId)).reverse(),
  },
  tag: {
    title: 'Tag',
    value: 'tag',
    asc: (rows: Conversions.Table.TableRow[]) => rows.sort((a, b) => a.tag.localeCompare(b.tag)),
    desc: (rows: Conversions.Table.TableRow[]) => rows.sort((a, b) => a.tag.localeCompare(b.tag)).reverse(),
  },
  asin: {
    title: 'ASIN',
    value: 'asin',
    asc: (rows: Conversions.Table.TableRow[]) => rows.sort((a, b) => a.asin.localeCompare(b.asin)),
    desc: (rows: Conversions.Table.TableRow[]) => rows.sort((a, b) => a.asin.localeCompare(b.asin)).reverse(),
  },
  timeOfShipment: {
    title: 'Time of shipment',
    value: 'timeOfShipment',
    asc: (rows: Conversions.Table.TableRow[]) =>
      rows.sort((a, b) => moment(a.timeOfShipment).diff(moment(b.timeOfShipment))),
    desc: (rows: Conversions.Table.TableRow[]) =>
      rows.sort((a, b) => moment(b.timeOfShipment).diff(moment(a.timeOfShipment))),
  },
  dateOfUpload: {
    title: 'Date of upload',
    value: 'dateOfUpload',
    asc: (rows: Conversions.Table.TableRow[]) =>
      rows.sort((a, b) => moment(a.dateOfUpload).diff(moment(b.dateOfUpload))),
    desc: (rows: Conversions.Table.TableRow[]) =>
      rows.sort((a, b) => moment(b.dateOfUpload).diff(moment(a.dateOfUpload))),
  },
  convAdFees: {
    title: 'Conv. ad fee',
    value: 'convAdFees',
    asc: (rows: Conversions.Table.TableRow[]) =>
      rows.sort((a, b) => Number(a.convAdFees.slice(1)) - Number(b.convAdFees.slice(1))),
    desc: (rows: Conversions.Table.TableRow[]) =>
      rows.sort((a, b) => Number(a.convAdFees.slice(1)) - Number(b.convAdFees.slice(1))).reverse(),
  },
};

export function ConversionsTable(): ReactElement {
  const globalFilters = useSelector(selectGlobalFilters);
  const filters = useSelector(conversionsSelectors.selectFilters);
  const conversions = useSelector(conversionsSelectors.selectConversions);
  const table = useSelector(conversionsSelectors.selectTable);

  const dispatch = useDispatch();

  useEffect(() => {
    if (conversions.data) {
      dispatch(convActions.tableActions.setRows(generateRows(conversions.data.conversions)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversions.data]);

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(convActions.tableActions.setPage(newPage));
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(convActions.tableActions.setPageSize(Number(event.target.value)));
    dispatch(convActions.tableActions.setPage(0));
  };

  const handleSort = (direction: 'asc' | 'desc', value: string) => {
    headers[value][direction](table.rows);

    switch (direction) {
      case 'asc':
        dispatch(tableActions.setDirection('desc'));
        break;
      case 'desc':
        dispatch(tableActions.setDirection('asc'));
        break;
      default:
    }
  };

  return (
    <Box sx={styles.boxContainer}>
      {conversions.loading && (
        <Box sx={styles.boxLoading}>
          <CircularProgress />
        </Box>
      )}
      {!conversions.loading && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={styles.tableRow}>
                <TableCell sx={styles.tableCell}>
                  <TableSortLabel
                    active
                    direction={table.direction}
                    onClick={() => handleSort(table.direction, headers.pageUrl.value)}
                  >
                    {headers.pageUrl.title}
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <TableSortLabel
                    active
                    direction={table.direction}
                    onClick={() => handleSort(table.direction, headers.timeOfVisit.value)}
                  >
                    {headers.timeOfVisit.title}
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <TableSortLabel
                    active
                    direction={table.direction}
                    onClick={() => handleSort(table.direction, headers.trackingId.value)}
                  >
                    {headers.trackingId.title}
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <TableSortLabel
                    active
                    direction={table.direction}
                    onClick={() => handleSort(table.direction, headers.tag.value)}
                  >
                    {headers.tag.title}
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <TableSortLabel
                    active
                    direction={table.direction}
                    onClick={() => handleSort(table.direction, headers.asin.value)}
                  >
                    {headers.asin.title}
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <TableSortLabel
                    active
                    direction={table.direction}
                    onClick={() => handleSort(table.direction, headers.timeOfShipment.value)}
                  >
                    {headers.timeOfShipment.title}
                  </TableSortLabel>
                </TableCell>
                {(globalFilters.date_from.format('ll') !== globalFilters.date_to.format('ll') &&
                  filters.status === 'ordered' && (
                    <TableCell sx={styles.tableCell}>
                      <TableSortLabel
                        active
                        direction={table.direction}
                        onClick={() => handleSort(table.direction, headers.dateOfUpload.value)}
                      >
                        {headers.dateOfUpload.title}
                      </TableSortLabel>
                    </TableCell>
                  )) || <TableCell sx={styles.tableCell}>{headers.dateOfUpload.title}</TableCell>}
                <TableCell sx={styles.tableCell} sortDirection={table.direction || false}>
                  <TableSortLabel
                    active
                    direction={table.direction}
                    onClick={() => handleSort(table.direction, headers.convAdFees.value)}
                  >
                    {headers.convAdFees.title}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(table.pageSize > 0
                ? table.rows.slice(table.page * table.pageSize, table.page * table.pageSize + table.pageSize)
                : table.rows
              ).map((row, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ConversionsTableRow key={index} row={row} />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[50, 100, 200, 300]}
                  count={table.rows.length}
                  rowsPerPage={table.pageSize}
                  page={table.page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
