import { Action } from 'common/interfaces/root';
import { PublisherTagsActionTypes as TYPE } from '../actions/publisher-tags-action-types';

const initialState: PublishersAffiliateTagsModule.PublishersTagsStore = {
  loading: true,
  error: null,
  tags: null,
  tagsImportStatus: null,
  page: 0,
  sort: {
    field: 'tags_count',
    direction: 'desc',
  },
};

export default (
  state: PublishersAffiliateTagsModule.PublishersTagsStore = initialState,
  { type, payload }: Action
): PublishersAffiliateTagsModule.PublishersTagsStore => {
  switch (type) {
    case TYPE.GET_PUBLISHERS_TAGS:
      return { ...state, loading: true };
    case TYPE.GET_PUBLISHERS_TAGS_SUCCESS:
      return { ...state, tags: payload, loading: false };
    case TYPE.GET_PUBLISHERS_TAGS_ERROR:
      return { ...state, error: payload, loading: false };
    case TYPE.CHANGE_PUBLISHERS_TAGS_SORT:
      return { ...state, sort: payload };
    case TYPE.SCHEDULE_TAGS_IMPORT:
      return { ...state, loading: true };
    case TYPE.SCHEDULE_TAGS_IMPORT_SUCCESS:
      return {
        ...state,
        tagsImportStatus: payload,
        loading: false,
      };
    case TYPE.SCHEDULE_TAGS_IMPORT_ERROR:
      return { ...state, error: payload, loading: false };
    case TYPE.GET_TAGS_IMPORT_STATUS:
      return { ...state, loading: true };
    case TYPE.GET_TAGS_IMPORT_STATUS_SUCCESS:
      return { ...state, tagsImportStatus: payload, loading: false };
    case TYPE.GET_TAGS_IMPORT_STATUS_ERROR:
      return { ...state, error: payload, loading: false };
    case TYPE.CHANGE_PUBLISHERS_TAGS_PAGE:
      return { ...state, page: payload };
    case TYPE.RESET_TAGS_IMPORT_STATUS:
      return { ...state, tagsImportStatus: null };
    default:
      return state;
  }
};
