import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { Styles } from 'common/types';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { attributionActions } from 'modules/attribution/store/actions';
import {
  selectAmazonAttrAmount,
  selectAmazonAttrData,
  selectAmazonAttrLoading,
} from 'modules/attribution/store/selectors';
import { ReactElement, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AttributionRow } from './attribution-row';

const getStyles = (): Styles => ({
  table: { marginTop: 2, paddingBottom: '10px' },
});

interface Props {
  currentPage: number;
}

export function AttributionTable({ currentPage }: Props): ReactElement {
  const dispatch = useDispatch();
  const styles = getStyles();

  const data = useSelector(selectAmazonAttrData);
  const loading = useSelector(selectAmazonAttrLoading);
  const amount = useSelector(selectAmazonAttrAmount);

  const handlePageChange = (_e: MouseEvent<HTMLButtonElement>, page: number) => {
    dispatch(attributionActions.changeAmazonAttrPage(page));
  };

  return (
    <>
      <TableContainerWithLoading sx={styles.table} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ASIN</TableCell>
              <TableCell>Attribution publisher</TableCell>
              <TableCell>Tags</TableCell>
              <TableCell>Publisher name</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(row => (
              <AttributionRow key={row.id} attr={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <TablePagination
        component="div"
        count={amount}
        rowsPerPage={20}
        rowsPerPageOptions={[20]}
        page={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}
