import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment/moment';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { UploadFileOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { SingleDatePicker } from '../../../../common/ui/single-date-picker';
import { PublisherPicker } from '../../../../common/ui/publisher-picker';
import { amazonAffiliateReportsActions } from '../../store/actions';
import { Styles } from '../../../../common/types';
import { State } from '../../../../common/store/reducers';

const amazonReportTypeOptions = [
  { title: 'Earning', value: 'earning' },
  { title: 'Order', value: 'order' },
  { title: 'Tracking', value: 'tracking' },
];

const styles: Styles = {
  form: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: '20px',
    justifyContent: 'flex-start',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  formItemlabel: {
    color: '#6F8490',
    fontSize: '14px',
  },
  publisherPickerWrapper: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '8px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    zIndex: 1,
  },
  fileNameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginLeft: '10px',
    overflow: 'hidden',
    boxSizing: 'border-box',
    '&:after': {
      content: '" "',
      width: '15px',
      height: '100%',
      background: 'linear-gradient(45deg, transparent 100%, white 100%)',
    },
  },
  fileName: {
    fontSize: '14px',
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
  uploadField: {
    display: 'none',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  actionButton: {
    marginTop: '15px',
    width: 'max-content',
    alignSelf: 'end',
    minWith: '160px',
  },
};

const uploadReportDataSelector = (state: State) => state.amazonAffiliateReports.uploadAmazonAffiliateReport;

function UploadReportForm() {
  const { loading, date, report_type, file, publisher_id } = useSelector(uploadReportDataSelector);
  const dispatch = useDispatch();

  const uploadRef = useRef<HTMLInputElement>(null);

  const handleAddNewClick = () => {
    uploadRef.current?.click();
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    dispatch(amazonAffiliateReportsActions.setUploadReportState({ file }));
  };

  const onSubmit = () => {
    if (!publisher_id) {
      toast.error('Publisher is required');
    }

    if (!file) {
      toast.error('File is required');
    }

    if (!report_type) {
      toast.error('Report type is required');
    }

    if (date && report_type && file && publisher_id) {
      const requestBody = {
        publisher_id,
        date: moment(date).tz('EST', true).toISOString(),
        file,
        report_type,
      };
      dispatch(amazonAffiliateReportsActions.uploadReport(requestBody));
    }
  };

  useEffect(() => {
    if (loading) {
      toast.loading('File uploading...', { id: 'file-uploading', icon: <CircularProgress size={20} /> });
    } else {
      toast.remove('file-uploading');
    }
  }, [loading]);

  return (
    <Box sx={styles.form}>
      <Box sx={styles.itemsContainer}>
        <Box sx={styles.formItem}>
          <Typography sx={styles.formItemlabel}>Publisher:</Typography>
          <Box sx={styles.publisherPickerWrapper}>
            <PublisherPicker
              singleOption
              selectedPublisher={publisher_id}
              handlePublisherChange={publisher_id =>
                dispatch(amazonAffiliateReportsActions.setUploadReportState({ publisher_id: Number(publisher_id) }))
              }
              disableRedirect
              fullWidth
            />
          </Box>
        </Box>
        <Box sx={styles.formItem}>
          <Typography sx={styles.formItemlabel}>Report type:</Typography>
          <Select
            label="Report type"
            disabled={loading}
            value={report_type}
            size="small"
            onChange={e => {
              dispatch(amazonAffiliateReportsActions.setUploadReportState({ report_type: e.target.value }));
            }}
          >
            {amazonReportTypeOptions.map(elem => (
              <MenuItem key={elem.value} value={elem.value}>
                <Typography sx={styles.scheme}>{elem.title}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={styles.formItem}>
          <Typography sx={styles.formItemlabel}>Report date:</Typography>
          <SingleDatePicker
            date={moment(date)}
            onChange={(date: moment.Moment) => {
              dispatch(amazonAffiliateReportsActions.setUploadReportState({ date }));
            }}
          />
        </Box>
        <Box sx={{ ...styles.formItem, flexDirection: 'column' }}>
          <TextField
            type="file"
            inputProps={{ accept: 'text/csv' }}
            inputRef={uploadRef}
            sx={styles.uploadField}
            onChange={handleUpload}
          />
          <Typography sx={styles.formItemlabel}>Report file:</Typography>
          {!file && (
            <Button startIcon={<AddIcon />} variant="outlined" color="primary" onClick={handleAddNewClick}>
              Add Document
            </Button>
          )}
          {file && (
            <Tooltip title={file.name}>
              <Box sx={styles.fileNameContainer}>
                <FileUploadIcon />
                <Box sx={styles.fileNameWrapper}>
                  <Typography sx={styles.fileName}>{file.name}</Typography>
                </Box>
                <IconButton
                  sx={{ marginLeft: 'auto' }}
                  onClick={() => {
                    dispatch(amazonAffiliateReportsActions.setUploadReportState({ file: null }));
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Tooltip>
          )}
        </Box>
      </Box>

      <Box sx={styles.actions}>
        <Divider sx={{ width: '100%' }} />
        <Button
          startIcon={<UploadFileOutlined />}
          variant="contained"
          color="primary"
          sx={styles.actionButton}
          onClick={onSubmit}
          disabled={loading}
        >
          Upload Report
        </Button>
      </Box>
    </Box>
  );
}

export { UploadReportForm };
