import { Box, Checkbox, Popover, Typography } from '@mui/material';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Styles } from 'common/types';
import { theme } from 'common/constants/theme';

const styles: Styles = {
  container: { padding: theme.spacing(2.5, 6, 2.5, 2) },
  row: { display: 'flex', alignItems: 'center' },
  title: { fontSize: 14, color: '#214254' },
};

interface Props {
  anchorEl: HTMLElement;
  columnsData: AsinRecommendationModule.Table.Column[];
  selected: AsinRecommendationModule.Table.SelectedColumns;
  setSelectedCol: Dispatch<SetStateAction<AsinRecommendationModule.Table.SelectedColumns>>;
  onClose: () => void;
}

export function ColumnsPopover({ anchorEl, columnsData, selected, setSelectedCol, onClose }: Props): ReactElement {
  return (
    <Popover anchorEl={anchorEl} onClose={onClose} open={Boolean(anchorEl)}>
      <Box sx={styles.container}>
        {columnsData.map(c => (
          <Box sx={styles.row} key={c.value}>
            <DragIndicatorIcon color="action" fontSize="small" />
            <Checkbox
              onClick={() => setSelectedCol({ ...selected, [c.value]: !selected[c.value] })}
              checked={selected[c.value]}
            />
            <Typography sx={styles.title}>{c.title}</Typography>
          </Box>
        ))}
      </Box>
    </Popover>
  );
}
