import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Box, CircularProgress } from '@mui/material';
import { Styles } from 'common/types';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { selectPublisherAffiliateInfo, selectPublisherAffiliateLoading } from 'modules/settings/store/selectors';
import { selectGlobalFilters } from 'common/store/selectors';
import { CountryCode, amazonAffiliateRegionOptions } from 'modules/settings/constants';
import { AffiliateSettingsContent } from './affiliate-settings-content';

const getStyles = (): Styles => ({
  spinnerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '200px',
  },
});

export function AffiliateSettings(): ReactElement {
  const dispatch = useDispatch();
  const styles = getStyles();

  const info = useSelector(selectPublisherAffiliateInfo);
  const loading = useSelector(selectPublisherAffiliateLoading);
  const filters = useSelector(selectGlobalFilters);

  const id = String(filters?.publisher_id);

  const getInitialValues = (): PublishersSettingsModule.AffiliateSettings => {
    const defaultRegion = CountryCode.US;

    const selectedRegionFromUrl = info?.amazon_associates_url
      ? Object.entries(amazonAffiliateRegionOptions).find(([, option]) =>
          info.amazon_associates_url.includes(option.value)
        )?.[0]
      : null;

    const selectedRegionOption = amazonAffiliateRegionOptions[(selectedRegionFromUrl || defaultRegion) as CountryCode];
    const selectedRegionUrl = selectedRegionOption?.value || '';

    return {
      amazonLogin: info?.amazon_login ?? '',
      amazonPassword: info?.amazon_password ?? '',
      amazonAssociatesUrl: selectedRegionUrl,
      amazonAssociatesRegion: selectedRegionFromUrl || defaultRegion,
      skimlinksPublisherId: info?.skm_publisher_id ?? '',
      skimlinksClientId: info?.skm_client_id ?? '',
      skimlinksClientSecret: info?.skm_client_secret ?? '',
      impactAccountSid: info?.imp_account_sid ?? '',
      impactAccountToken: info?.imp_account_token ?? '',
    };
  };

  const handleRefreshInfo = () => {
    dispatch(publishersSettingsActions.getPublisherAffiliate(id));
  };

  const handleSubmit = (values: PublishersSettingsModule.AffiliateSettings) => {
    dispatch(
      publishersSettingsActions.patchPublisherAffiliate(
        {
          id,
          amazon_login: values.amazonLogin,
          ...(values.amazonPassword !== '' && values.amazonPassword !== info?.amazon_password
            ? { amazon_password: values.amazonPassword }
            : {}),
          amazon_associates_url: values.amazonAssociatesUrl,
          skm_publisher_id: values.skimlinksPublisherId,
          skm_client_id: values.skimlinksClientId,
          ...(values.skimlinksClientSecret !== '' && values.skimlinksClientSecret !== info?.skm_client_secret
            ? { skm_client_secret: values.skimlinksClientSecret }
            : {}),
          imp_account_sid: values.impactAccountSid,
          ...(values.impactAccountToken !== '' && values.impactAccountToken !== info?.imp_account_token
            ? { imp_account_token: values.impactAccountToken }
            : {}),
        },
        handleRefreshInfo
      )
    );
  };

  useEffect(() => {
    dispatch(publishersSettingsActions.getPublisherAffiliate(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return loading ? (
    <Box sx={styles.spinnerWrapper}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <Formik initialValues={getInitialValues()} onSubmit={handleSubmit}>
      <AffiliateSettingsContent />
    </Formik>
  );
}
