import { Box, Popover } from '@mui/material';
import { theme } from 'common/constants/theme';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { revenueByPageActions } from 'modules/revenue-by-page/store/actions';
import { selectTableSort } from 'modules/revenue-by-page/store/selectors';
import { ReactElement, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface StylesProps {
  width: number;
}

const getStyles = ({ width }: StylesProps): Styles => ({
  row: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid #e5e5e5',
    height: 48,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0 16px',
    ':hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  popover: { width },
});

interface Props {
  anchorElement: HTMLElement | null;
  onClose: () => void;
  width: number;
}

export function ExportPopover({ anchorElement, onClose, width }: Props): ReactElement {
  const dispatch = useDispatch();
  const styles = getStyles({ width });

  const filters = useSelector(selectGlobalFilters);
  const sort = useSelector(selectTableSort);

  const parsedFilters = useMemo(
    () => ({
      date_from: filters.date_from,
      date_to: filters.date_to,
      publisher_id: filters.publisher_id,
      search: filters.search,
      traffic_type: filters.traffic_type,
      affiliate_network_type: filters.affiliate_network_type,
    }),
    [filters]
  );

  const parsedFiltersForTable: IStatisticsQueryString = useMemo(
    () => ({
      sort,
      ...parsedFilters,
    }),
    [parsedFilters, sort]
  );

  const handleTop10PagesExport = () => {
    dispatch(revenueByPageActions.exportTop10Pages(parsedFilters));
    onClose();
  };

  const handlePagesTableExport = () => {
    dispatch(revenueByPageActions.exportPages(parsedFiltersForTable));
    onClose();
  };

  return (
    <Popover
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      PaperProps={{ sx: styles.popover }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
    >
      <Box sx={styles.row} onClick={handleTop10PagesExport}>
        Top 10 Pages
      </Box>
      <Box sx={styles.row} onClick={handlePagesTableExport}>
        All Pages
      </Box>
    </Popover>
  );
}
