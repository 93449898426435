import { ReactElement } from 'react';
import { useFormikContext } from 'formik';
import { Styles } from 'common/types';
import { Box, Button } from '@mui/material';
import { GeneralUserSettings } from 'modules/user-settings/components/user-settings-content/';

export const getStyles = (): Styles => ({
  content: {
    marginTop: '50px',
    backgroundColor: '#ffffff',
    width: '100%',
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 5%)',
    padding: '28px',
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    width: '100%',
    boxSizing: 'border-box',
    padding: '20px 0 0 0',
    ':first-child': { padding: 0 },
  },
  fieldWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '20px 0',
    boxSizing: 'border-box',
    borderBottom: '1px solid #C2DEEB40',
    ':last-child': { borderBottom: 0 },
  },
  titleField: {
    color: '#214254',
    fontSize: '12px',
  },
  button: { maxWidth: 100, width: '100%', marginLeft: 1, ':first-child': { marginLeft: 0 }, borderRadius: '10px' },
  buttons: { marginTop: 2, display: 'flex', justifyContent: 'flex-start' },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '405px',
    marginRight: '175px',
    boxSizing: 'border-box',
  },
});

export function UserSettingsContent(): ReactElement {
  const styles = getStyles();
  const { submitForm } = useFormikContext<UserSettingsModule.UserSettingsForm>();

  const handleCancelForm = () => {
    //
  };

  return (
    <Box sx={styles.content}>
      <GeneralUserSettings />
      <Box sx={styles.buttons}>
        <Button color="primary" onClick={submitForm} sx={styles.button} variant="contained">
          Save
        </Button>
        <Button variant="outlined" sx={styles.button} onClick={handleCancelForm}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
}
