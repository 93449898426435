import { asinsConversionsBoostTypes as TYPES } from './action-types';

const setLimit = (payload: number) => ({
  type: TYPES.FILTERS.SET_LIMIT,
  payload,
});

const setOffset = (payload: number) => ({
  type: TYPES.FILTERS.SET_OFFSET,
  payload,
});

const setFilters = (payload: AsinsConversionsBoostModule.FiltersForm) => ({
  type: TYPES.FILTERS.SET_FILTERS,
  payload,
});

const resetFilters = () => ({
  type: TYPES.FILTERS.RESET_FILTERS,
});

export const filtersActions = {
  setLimit,
  setOffset,
  setFilters,
  resetFilters,
};
