const STATISTICS_MODULE = '[overview-v2:statistics]';
const CORELLATION_MODULE = '[overview-v2:correlation]';
const DEVICES_MODULE = '[overview-v2:devices]';
const PERFORMANCE_MODULE = '[overview-v2:performance]';
const TOP10_MODULE = '[overview-v2:top-10]';
const PRODUCTS_STATS_MODULE = '[overview-v2:products-stats]';
const COMBINED_MODULE = '[overview-v2:combined]';

const GET_ALL_STATISTICS = `${STATISTICS_MODULE} GET_ALL_STATISTICS`;
const GET_ALL_STATISTICS_SUCCESS = `${STATISTICS_MODULE} GET_ALL_STATISTICS_SUCCESS`;
const GET_ALL_STATISTICS_ERROR = `${STATISTICS_MODULE} GET_ALL_STATISTICS_ERROR`;

const GET_CORRELATION_STATISTICS = `${CORELLATION_MODULE} GET_CORRELATION_STATISTICS`;
const GET_CORRELATION_STATISTICS_SUCCESS = `${CORELLATION_MODULE} GET_CORRELATION_STATISTICS_SUCCESS`;
const GET_CORRELATION_STATISTICS_ERROR = `${CORELLATION_MODULE} GET_CORRELATION_STATISTICS_ERROR`;

const GET_DEVICES_STATISTICS = `${DEVICES_MODULE} GET_DEVICES_STATISTICS`;
const GET_DEVICES_STATISTICS_SUCCESS = `${DEVICES_MODULE} GET_DEVICES_STATISTICS_SUCCESS`;
const GET_DEVICES_STATISTICS_ERROR = `${DEVICES_MODULE} GET_DEVICES_STATISTICS_ERROR`;

const GET_TOP_PERFORMERS = `${PERFORMANCE_MODULE} GET_TOP_PERFORMERS`;
const GET_TOP_ASINS_SUCCESS = `${PERFORMANCE_MODULE} GET_TOP_ASINS_SUCCESS`;
const GET_TOP_CATEGORIES_SUCCESS = `${PERFORMANCE_MODULE} GET_TOP_CATEGORIES_SUCCESS`;
const GET_TOP_PERFORMERS_ERROR = `${PERFORMANCE_MODULE} GET_TOP_PERFORMERS_ERROR`;

const GET_TOP10_PAGES = `${TOP10_MODULE} GET_TOP10_PAGES`;
const GET_TOP10_PAGES_SUCCESS = `${TOP10_MODULE} GET_TOP10_PAGES_SUCCESS`;
const GET_TOP10_PAGES_ERROR = `${TOP10_MODULE} GET_TOP10_PAGES_ERROR`;

const GET_TOP10_EXPORT = `${TOP10_MODULE} GET_TOP10_EXPORT`;
const GET_TOP10_EXPORT_SUCCESS = `${TOP10_MODULE} GET_TOP10_EXPORT_SUCCESS`;
const GET_TOP10_EXPORT_ERROR = `${TOP10_MODULE} GET_TOP10_EXPORT_ERROR`;

const GET_PRODUCTS_STATS = `${PRODUCTS_STATS_MODULE} GET_PRODUCTS_STATS`;
const GET_PRODUCTS_STATS_SUCCESS = `${PRODUCTS_STATS_MODULE} GET_PRODUCTS_STATS_SUCCESS`;
const GET_PRODUCTS_STATS_ERROR = `${PRODUCTS_STATS_MODULE} GET_PRODUCTS_STATS_ERROR`;

const SET_IS_COMBINED = `${COMBINED_MODULE} SET_IS_COMBINED`;

export const overviewActionTypes = {
  GET_ALL_STATISTICS,
  GET_ALL_STATISTICS_SUCCESS,
  GET_ALL_STATISTICS_ERROR,
  GET_CORRELATION_STATISTICS,
  GET_CORRELATION_STATISTICS_SUCCESS,
  GET_CORRELATION_STATISTICS_ERROR,
  GET_DEVICES_STATISTICS,
  GET_DEVICES_STATISTICS_SUCCESS,
  GET_DEVICES_STATISTICS_ERROR,
  GET_TOP_PERFORMERS,
  GET_TOP_ASINS_SUCCESS,
  GET_TOP_CATEGORIES_SUCCESS,
  GET_TOP_PERFORMERS_ERROR,
  GET_TOP10_PAGES,
  GET_TOP10_PAGES_SUCCESS,
  GET_TOP10_PAGES_ERROR,
  GET_TOP10_EXPORT,
  GET_TOP10_EXPORT_SUCCESS,
  GET_TOP10_EXPORT_ERROR,
  GET_PRODUCTS_STATS,
  GET_PRODUCTS_STATS_SUCCESS,
  GET_PRODUCTS_STATS_ERROR,
  SET_IS_COMBINED,
};
