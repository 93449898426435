import { ReactElement } from 'react';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  CircularProgress,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getStyles as getSettingStyles } from 'modules/user-settings/components/user-settings-content/user-settings-content';
import { Styles } from 'common/types';
import { Product } from 'common/ui/product';
import { useDebouncedCallback } from 'use-debounce';
import { SearchInput } from 'common/ui/search-input';
import { useDispatch, useSelector } from 'react-redux';
import { affiliateRateCardsActions } from '../store/actions/actions';
import { affiliateRateCardSelectors } from '../store/selectors';

const settingsStyles = getSettingStyles();

const styles: Styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'white',
    boxShadow: 1,
    p: 2,
  },
  loadingContainer: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: 551, width: '100%' },
  section: { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%', marginBottom: 3 },
  textField: { width: 150 },
  tableBox: { marginTop: 2 },
  tableContainer: { height: 500 },
  selectCell: { width: 40 },
  newRateContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 3 },
  newRateBox: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  newRateTypograhpy: { marginRight: 3 },
};

interface Props {
  open: boolean;
  onClose: () => void;
  publisherId: number;
}

export function CustomRateModal({ open, onClose, publisherId }: Props): ReactElement {
  const dispatch = useDispatch();

  const filters = useSelector(affiliateRateCardSelectors.filters);
  const { data, loading } = useSelector(affiliateRateCardSelectors.asins);

  const handleSelect = (asin: AffiliateRateCardModule.Asin) => {
    dispatch(affiliateRateCardsActions.filters.setAll(false));
    if (!filters.selected.includes(asin))
      dispatch(affiliateRateCardsActions.filters.setSelected([...filters.selected, asin]));
    if (filters.selected.includes(asin))
      dispatch(
        affiliateRateCardsActions.filters.setSelected(
          filters.selected.filter((val: AffiliateRateCardModule.Asin) => {
            return val !== asin;
          })
        )
      );
  };

  const handleAll = () => {
    if (filters.all) dispatch(affiliateRateCardsActions.filters.setSelected([]));
    if (!filters.all) dispatch(affiliateRateCardsActions.filters.setSelected([...data.rate_cards]));
    dispatch(affiliateRateCardsActions.filters.setAll(!filters.all));
  };

  const handleClose = () => {
    dispatch(affiliateRateCardsActions.filters.reset());
    onClose();
  };

  const debouncedSave = useDebouncedCallback(() => {
    handleClose();
  }, 250);

  const handleSave = () => {
    if (filters.selected.length === 0)
      dispatch(affiliateRateCardsActions.filters.setFilterError('Please select one or more ASINs'));
    if (filters.rate === '') dispatch(affiliateRateCardsActions.filters.setFilterError('Please enter new rate'));

    const selAsins: string[] = [];

    filters.selected.forEach((val: AffiliateRateCardModule.Asin) => {
      selAsins.push(val.asin);
    });

    const payload: AffiliateRateCardModule.PatchCustomRateParams = {
      publisher_id: publisherId.toString(),
      rate: filters.rate,
      asins: selAsins,
    };

    dispatch(affiliateRateCardsActions.rates.custom.patchCustomRate(payload));

    debouncedSave();
  };

  const handleRateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(affiliateRateCardsActions.filters.setRate(event.target.value));
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(affiliateRateCardsActions.filters.setOffset(newPage));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(affiliateRateCardsActions.filters.setLimit(parseInt(event.target.value, 10)));
    dispatch(affiliateRateCardsActions.filters.setOffset(0));
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={styles.modal}>
        <Box sx={styles.section}>
          <SearchInput
            search={filters.search}
            handleSearchChange={search => {
              dispatch(affiliateRateCardsActions.filters.setSearch(search));
            }}
            primaryBg
          />
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={styles.section}>
          <Typography variant="h6">Select ASINs</Typography>
        </Box>
        <Box sx={styles.tableBox}>
          {(loading && (
            <Box sx={styles.loadingContainer}>
              <CircularProgress />
            </Box>
          )) || (
            <>
              <TableContainer sx={styles.tableContainer}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={styles.selectCell}>
                        <Checkbox checked={filters.all} onChange={handleAll} />
                      </TableCell>
                      <TableCell sx={settingsStyles.titleField}>Product Name</TableCell>
                      <TableCell sx={settingsStyles.titleField}>Brand</TableCell>
                      <TableCell sx={settingsStyles.titleField}>ASIN</TableCell>
                      <TableCell sx={settingsStyles.titleField}>Affiliate Rate</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.rate_cards.map(row => (
                      <TableRow key={row.asin}>
                        <TableCell sx={styles.selectCell}>
                          <Checkbox checked={filters.selected.includes(row)} onChange={() => handleSelect(row)} />
                        </TableCell>
                        {/* <TableCell>{row.product_name}</TableCell> */}
                        <TableCell>
                          <Product
                            product={{
                              ...row,
                              title: row.product_name,
                              in_stock: !row.in_stock ? 0 : 1,
                              review_count: row.review_count?.toString(),
                            }}
                          />
                        </TableCell>
                        <TableCell>{row.brand}</TableCell>
                        <TableCell>{row.asin}</TableCell>
                        <TableCell>{`${Number(row.rate).toFixed()}%`}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer>
                <Table>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        count={data.rate_cards_count || 0}
                        rowsPerPage={filters.limit}
                        page={filters.offset}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
        <Box sx={styles.newRateContainer}>
          <Box sx={styles.newRateBox}>
            <Typography sx={styles.newRateTypograhpy} variant="h6">
              Set new rate:
            </Typography>
            <TextField variant="outlined" size="small" placeholder="%" onChange={handleRateChange} />
          </Box>
          <Box sx={styles.newRateBox}>
            <Typography sx={styles.newRateTypograhpy} color="red">
              {filters.filterError}
            </Typography>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
