import { Action } from 'common/interfaces/root';
import { affiliateRateCardActionTypes as TYPES } from '../actions/types';

const initState: AffiliateRateCardModule.RateCardsStore = {
  data: null,
  loading: false,
  error: null,
};

export default (state = initState, { type, payload }: Action): AffiliateRateCardModule.RateCardsStore => {
  switch (type) {
    case TYPES.RATES.CUSTOM.PATCH_CUSTOM_RATE:
      return { ...state, loading: true };
    case TYPES.RATES.CUSTOM.PATCH_CUSTOM_RATE_SUCCESS:
      return { ...state, loading: false, data: payload };
    case TYPES.RATES.CUSTOM.PATCH_CUSTOM_RATE_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
