/* eslint-disable react/jsx-no-comment-textnodes */
import { ReactElement, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@mui/system';
import { Styles } from 'common/types';
import { Button, TextField, Typography } from '@mui/material';
import { PublisherPicker } from 'common/ui/publisher-picker';
import { Field } from 'common/ui/field';
import toast from 'react-hot-toast';
import { BrandSelect } from '../../brand-select';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 1.5 },
  fieldContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  field: { width: '70%' },
  button: { maxWidth: 100, width: '100%', marginLeft: 1, ':first-child': { marginLeft: 0 } },
  buttons: { marginTop: 5, display: 'flex', justifyContent: 'flex-end' },
  publisherPickerWrapper: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    zIndex: 1,
  },
  errorText: { color: 'red', marginTop: '4px', fontSize: '12px' },
};

interface Props {
  onClose: () => void;
  isModalOpen: boolean;
}

export function FormContent({ onClose, isModalOpen }: Props): ReactElement {
  const { errors, values, resetForm, submitForm, setFieldValue } =
    useFormikContext<AsinsConversionsBoostModule.ConversionBoostForm>();

  const handlePublisherChange = (publisher: number | null | string) => {
    setFieldValue('publisher_id', publisher);
  };

  const handleBrandSelect = (value: number) => {
    setFieldValue('brand_id', value);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleSubmit = () => {
    if (Object.keys(errors).length === 0) {
      submitForm();
    } else {
      toast.error('Please provide correct values before submitting.');
    }
  };

  useEffect(() => {
    if (isModalOpen) return;
    resetForm();
  }, [isModalOpen, resetForm]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.fieldContainer}>
        <Typography>ASIN</Typography>
        <Box sx={styles.field}>
          <Field
            component={TextField}
            name="asin"
            fullWidth
            variant="outlined"
            label="ASIN"
            size="small"
            InputLabelProps={{ shrink: true }}
          />
          {errors.asin && <Typography sx={styles.errorText}>{errors.asin}</Typography>}
        </Box>
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Brand</Typography>
        <Box sx={styles.field}>
          <BrandSelect selectedBrand={values.brand_id} setSelectedBrand={handleBrandSelect} />
          {errors.brand_id && <Typography sx={styles.errorText}>{errors.brand_id}</Typography>}
        </Box>
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Publisher</Typography>
        <Box sx={styles.field}>
          <Box sx={styles.publisherPickerWrapper}>
            <PublisherPicker
              singleOption
              selectedPublisher={values.publisher_id}
              disableRedirect
              handlePublisherChange={handlePublisherChange}
              fullWidth
            />
          </Box>
          {errors.brand_id && <Typography sx={styles.errorText}>{errors.brand_id}</Typography>}
        </Box>
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Boost</Typography>
        <Box sx={styles.field}>
          <Field
            component={TextField}
            name="boost"
            fullWidth
            variant="outlined"
            label="Boost"
            size="small"
            InputLabelProps={{ shrink: true }}
            type="number"
            inputProps={{ step: '0.01' }}
          />
          {errors.boost && <Typography sx={styles.errorText}>{errors.boost}</Typography>}
        </Box>
      </Box>
      <Box sx={styles.buttons}>
        <Button variant="outlined" sx={styles.button} onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit} sx={styles.button} variant="contained">
          Save
        </Button>
      </Box>
    </Box>
  );
}
