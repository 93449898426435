import { Action } from 'common/interfaces/root';
import { recommendedAsinsActionTypes as TYPES } from '../actions';

const initialState: AffiliateGainzModule.RecommendedAsinsModule.CategoriesState = {
  loading: true,
  data: null,
  error: null,
};

export default (
  state: AffiliateGainzModule.RecommendedAsinsModule.CategoriesState = initialState,
  { type, payload }: Action
): AffiliateGainzModule.RecommendedAsinsModule.CategoriesState => {
  switch (type) {
    case TYPES.CATEGORIES.GET_CATEGORIES:
      return { ...state, loading: true };
    case TYPES.CATEGORIES.GET_CATEGORIES_SUCCESS:
      return { ...state, loading: false, data: payload };
    case TYPES.CATEGORIES.GET_CATEGORIES_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
