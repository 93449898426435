import { ChangeEvent, ReactElement, useState } from 'react';
import { useFormikContext } from 'formik';
import toast from 'react-hot-toast';
import { Box, Button, MenuItem, Select, SelectChangeEvent, TextField, Tooltip, Typography } from '@mui/material';
import { ReactComponent as CopyIcon } from 'common/svg/copy-icon.svg';
import { Styles } from 'common/types';
import { useDispatch, useSelector } from 'react-redux';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { selectGlobalFilters } from 'common/store/selectors';
import { getStyles as getSettingStyles } from 'modules/user-settings/components/user-settings-content/user-settings-content';
import { SelectField } from 'common/ui/select-field';
import { SelectOption } from 'common/ui/select-field/select-field';
import { usePermission } from 'common/hooks';
import { PERMISSIONS } from 'common/constants/permissions';
import { Field } from 'common/ui/field';

const enabledDisabledOptions: Array<SelectOption> = [
  {
    title: 'Enabled',
    value: true,
  },
  {
    title: 'Disabled',
    value: false,
  },
];

const taggingNetworksOptions: Array<SelectOption> = [
  {
    title: 'Amazon',
    value: 'amazon',
  },
  {
    title: 'Skim',
    value: 'skim',
  },
  {
    title: 'Impact',
    value: 'impact',
  },
  {
    title: 'Narrativ',
    value: 'narrativ',
  },
];

const getStyles = (): Styles => ({
  title: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
  },
  description: {
    color: '#214254',
    fontSize: '14px',
    lineHeight: '26px',
    maxWidth: '330px',
    marginBottom: '9px',
    marginTop: 1,
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  borderedWrapper: {
    borderBottom: '1px solid #C2DEEB40',
    padding: '20px 0',
    ':last-child': {
      borderBottom: 'none',
    },
    ':first-child': {
      paddingTop: 0,
    },
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '405px',
    marginRight: '175px',
    boxSizing: 'border-box',
    gap: 1,
  },
  copyWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  copyBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  copyText: {
    color: '#4288F0',
  },
  button: { width: '100%', borderRadius: '10px' },
  trackingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    width: '100%',
  },
});

export function EmbedSettingsContent(): ReactElement {
  const styles = getStyles();
  const settingsStyles = getSettingStyles();
  const dispatch = useDispatch();

  const { values, submitForm, setFieldValue } = useFormikContext<PublishersSettingsModule.EmbedSettingsForm>();

  const filters = useSelector(selectGlobalFilters);

  const [asin, setAsin] = useState<string>('');
  const [url, setUrl] = useState<string>('');

  const id = String(filters?.publisher_id);
  const isAdmin = usePermission(PERMISSIONS.GET_PUBLISHERS);

  const handleCopyCode = () => {
    const input = document.getElementById('code');
    input.focus();
    navigator.clipboard.writeText(values.embed);
    toast.success('Code has been copied');
  };

  const handlePluginDownload = () => {
    dispatch(publishersSettingsActions.getWordpressPlugin(id));
  };

  const handleASINChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAsin(e.target.value);
  };

  const handleURLChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  const handleTaggingNetworksChange = ({ target: { value } }: SelectChangeEvent<string[]>) => {
    setFieldValue('tagging_networks', value);
  };

  const handleGenerateTag = () => {
    if (!asin || !url) return;
    dispatch(publishersSettingsActions.generateAttrTag(filters?.publisher_id, asin, url));
  };

  return (
    <>
      <Box sx={[styles.fieldWrapper, styles.borderedWrapper]}>
        <div>
          <Typography sx={styles.title}>Embed this text</Typography>
          <Typography sx={styles.description}>
            Add the script tag to the header of your site manually or download and install the Wordpress Plugin.
          </Typography>
        </div>
        <Box sx={styles.inputWrapper}>
          <TextField
            id="code"
            value={values?.embed}
            name="embed"
            fullWidth
            variant="outlined"
            label="Code"
            InputLabelProps={{ shrink: true }}
            multiline
            minRows={5}
          />
          <Box sx={styles.copyWrapper}>
            <Box sx={styles.copyBtn} onClick={handleCopyCode}>
              <CopyIcon />
              <Typography sx={styles.copyText}>Copy Code</Typography>
            </Box>
          </Box>
          <Button color="primary" onClick={handlePluginDownload} sx={styles.button} variant="contained">
            Download Wordpress Plugin
          </Button>
        </Box>
      </Box>
      <Box sx={[styles.trackingWrapper, styles.borderedWrapper]}>
        <Box sx={styles.fieldWrapper}>
          <Typography sx={styles.title}>Analytics Tracking</Typography>
          <Box sx={settingsStyles.inputWrapper}>
            <SelectField name="is_tracking_active" options={enabledDisabledOptions} fullWidth />
          </Box>
        </Box>
        <Box sx={styles.fieldWrapper}>
          <Typography sx={styles.title}>Affiliate Tagging</Typography>
          <Box sx={settingsStyles.inputWrapper}>
            <Select
              name="tagging_networks"
              onChange={handleTaggingNetworksChange}
              value={values.tagging_networks}
              multiple
              fullWidth
            >
              {taggingNetworksOptions.map(o => (
                <MenuItem key={o.value} value={o.value}>
                  {o.title}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box sx={styles.fieldWrapper}>
          <Typography sx={styles.title}>Include Specific Path</Typography>
          <Box sx={settingsStyles.inputWrapper}>
            <Field component={TextField} name="affiliate_path_regex" fullWidth />
          </Box>
        </Box>
      </Box>
      <Box sx={[styles.trackingWrapper, styles.borderedWrapper]}>
        <Tooltip title={isAdmin ? '' : 'Please contact an Admin to Update'} placement="bottom-start">
          <Box sx={styles.fieldWrapper}>
            <Typography sx={styles.title}>Amazon Attribution Tagging</Typography>
            <Box sx={settingsStyles.inputWrapper}>
              <SelectField
                name="is_amazon_attribution"
                options={enabledDisabledOptions}
                disabled={!isAdmin}
                fullWidth
              />
            </Box>
          </Box>
        </Tooltip>
        <Box sx={styles.fieldWrapper}>
          <Typography sx={styles.title}>Include Specific Path</Typography>
          <Box sx={settingsStyles.inputWrapper}>
            <Field component={TextField} name="attribution_path_regex" fullWidth />
          </Box>
        </Box>
      </Box>
      <Box sx={[styles.fieldWrapper, styles.borderedWrapper]}>
        <div>
          <Typography sx={styles.title}>Generate attribution tag</Typography>
          <Typography sx={styles.description}>Add generated attribution params to the Amazon link</Typography>
        </div>
        <Box sx={styles.inputWrapper}>
          <TextField
            label="ASIN"
            InputLabelProps={{ shrink: true }}
            sx={styles.field}
            size="small"
            onChange={handleASINChange}
            value={asin}
          />
          <TextField
            label="URL"
            InputLabelProps={{ shrink: true }}
            sx={styles.field}
            size="small"
            onChange={handleURLChange}
            value={url}
          />
          <Button variant="contained" sx={styles.button} disabled={!asin || !url} onClick={handleGenerateTag}>
            Generate tag
          </Button>
        </Box>
      </Box>
      <Box sx={settingsStyles.buttons}>
        <Button color="primary" onClick={submitForm} sx={settingsStyles.button} variant="contained">
          Save
        </Button>
      </Box>
    </>
  );
}
