import { Action } from 'common/interfaces/root';
import { overviewActionTypes as T } from '../actions';

const initState: OverviewV2.Top10Store = {
  loading: false,
  error: null,
  data: null,
};

export default (state: OverviewV2.Top10Store = initState, { type, payload }: Action): OverviewV2.Top10Store => {
  switch (type) {
    case T.GET_TOP10_PAGES:
      return { ...state, loading: true };

    case T.GET_TOP10_PAGES_SUCCESS:
      return { ...state, loading: false, data: payload };

    case T.GET_TOP10_PAGES_ERROR:
      return { ...state, loading: false, data: payload };

    default:
      return state;
  }
};
