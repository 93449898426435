const CONVERSIONS_MODULE = '[conversions:conversions]';
const REPORTS_MODULE = '[conversions:reports]';
const EXPORT_MODULE = '[conversions:export]';
const FILTERS_MODULE = '[conversions:filters]';
const TABLE_MODULE = '[conversions:table]';

const GET_CONVERSIONS = `${CONVERSIONS_MODULE} GET_CONVERSIONS`;
const GET_CONVERSIONS_SUCCESS = `${CONVERSIONS_MODULE} GET_CONVERSIONS_SUCCESS`;
const GET_CONVERSIONS_ERROR = `${CONVERSIONS_MODULE} GET_CONVERSIONS_ERROR`;

const GET_REPORTS = `${REPORTS_MODULE} GET_REPORTS`;
const GET_REPORTS_SUCCESS = `${REPORTS_MODULE} GET_REPORTS_SUCCESS`;
const GET_REPORTS_ERROR = `${REPORTS_MODULE} GET_REPORTS_ERROR`;

const GET_EXPORT = `${EXPORT_MODULE} GET_EXPORT`;
const GET_EXPORT_SUCCESS = `${EXPORT_MODULE} GET_EXPORT_SUCCESS`;
const GET_EXPORT_ERROR = `${EXPORT_MODULE} GET_EXPORT_ERROR`;

const GET_FILTERS = `${FILTERS_MODULE} GET_FILTERS`;
const SET_NETWORK_TYPE = `${FILTERS_MODULE} SET_NETWORK_TYPE`;
const SET_TRAFFIC_TYPE = `${FILTERS_MODULE} SET_TRAFFIC_TYPE`;
const SET_STATUS = `${FILTERS_MODULE} SET_STATUS`;
const SET_REPORTS = `${FILTERS_MODULE} SET_REPORTS`;
const SET_SEARCH = `${FILTERS_MODULE} SET_SEARCH`;
const RESET_FILTERS = `${FILTERS_MODULE} RESET_FILTERS`;

const GET_TABLE = `${TABLE_MODULE} GET_TABLE`;
const SET_ROWS = `${TABLE_MODULE} SET_ROWS`;
const SET_DIRECTION = `${TABLE_MODULE} SET_DIRECTION`;
const SET_TOTAL = `${TABLE_MODULE} SET_TOTAL`;
const SET_PAGE_SIZE = `${TABLE_MODULE} SET_PAGE_SIZE`;
const SET_PAGE = `${TABLE_MODULE} SET_PAGE`;

export const actionTypes = {
  conversions: {
    GET_CONVERSIONS,
    GET_CONVERSIONS_SUCCESS,
    GET_CONVERSIONS_ERROR,
  },
  report: {
    GET_REPORTS,
    GET_REPORTS_SUCCESS,
    GET_REPORTS_ERROR,
  },
  export: {
    GET_EXPORT,
    GET_EXPORT_SUCCESS,
    GET_EXPORT_ERROR,
  },
  filters: {
    GET_FILTERS,
    SET_NETWORK_TYPE,
    SET_TRAFFIC_TYPE,
    SET_STATUS,
    SET_REPORTS,
    SET_SEARCH,
    RESET_FILTERS,
  },
  table: {
    GET_TABLE,
    SET_ROWS,
    SET_DIRECTION,
    SET_TOTAL,
    SET_PAGE_SIZE,
    SET_PAGE,
  },
};
