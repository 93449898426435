import { ContentType } from 'modules/content-overview/components/pages/page-performance/content-type-filter/content-type-filter.types';
import { Moment } from 'moment';

export interface IStatisticsQueryString {
  publisher_id?: number | null;
  publishers_ids?: Array<number>;
  traffic_type?: Traffic;
  date_from?: Moment;
  date_to?: Moment;
  limit?: number;
  offset?: number;
  search?: string;
  used?: 0 | 1 | -1;
  affiliate_network_type?: number | string;
  sort?: ITableSort;
  date_by?: DateByValue;
  with_asins?: number;
  clicks?: Click;
  in_stock?: number;
  searchType?: 'url' | 'brand_name';
  oos_range?: {
    from: string | undefined;
    to: string | undefined;
  };
  oos_status?: number;
  start_date?: Moment;
  end_date?: Moment;
  breakdown?: string;
  daily?: string;
  exportLimit?: number;
  publisher_group?: string | number;
  article_type?: ContentType;
  ad_only?: string;
  // TODO typo using ContentOverviewModuel.Condition
  saturation_operator?: string;
  saturation_value?: number;
  dates_to_compare?: string;
  publisher_name?: string;
  asins?: string[];
  brand_id?: number;
  boost?: string;
  compare_operator?: string;
}

export type Direction = 'asc' | 'desc';

export interface ITableSort {
  field: string;
  direction: Direction;
}

export enum Traffic {
  Combined = 'combined',
  Google_Organic = 'google_organic',
  Google_Paid = 'google_paid',
  Facebook_Paid = 'facebook_paid',
  Other = 'other',
}

export enum Click {
  All = 'all',
  Yes = 'yes',
  No = 'no',
}

export enum DateByValue {
  Transactional = 'transactional',
  Click = 'click',
}

export interface AuxiliaryParams extends IStatisticsQueryString {
  limit?: number;
}
