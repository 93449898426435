import { Action } from 'common/interfaces/root';
import { overviewActionTypes as T } from '../actions';

const initialState: Overview.Performance.PerformanceStore = {
  loading: true,
  error: null,
  topAsins: null,
  topCategories: null,
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case T.GET_TOP_PERFORMERS:
      return { ...state, loading: true };
    case T.GET_TOP_ASINS_SUCCESS:
      return { ...state, loading: false, topAsins: payload };
    case T.GET_TOP_CATEGORIES_SUCCESS:
      return { ...state, loading: false, topCategories: payload };
    case T.GET_TOP_PERFORMERS_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
