import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Autocomplete, Box, Button, Chip, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { Field } from 'common/ui/field';
import { Styles } from 'common/types';
import { selectPublishers } from 'modules/publishers-settings/store/selectors';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 1.5 },
  fieldContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  field: { width: '70%' },
  button: { maxWidth: 100, width: '100%', marginLeft: 1, ':first-child': { marginLeft: 0 } },
  buttons: { marginTop: 5, display: 'flex', justifyContent: 'flex-end' },
  checkboxField: { display: 'flex', alignItems: 'center' },
  inputContainer: { display: 'flex', alignItems: 'center', width: '100%' },
  clearIcon: { cursor: 'pointer', alignSelf: 'center', marginTop: 2 },
  pubField: { width: '100%', marginTop: 2 },
  checkboxContainer: { display: 'flex', gap: 4 },
};

interface Props {
  onClose?: () => void;
}

export function FormContent({ onClose }: Props): ReactElement {
  const publishers = useSelector(selectPublishers);

  const { values, setFieldValue, submitForm } = useFormikContext<ParentPublisherSettingsModule.PublisherAccountForm>();

  const handleClose = () => {
    submitForm();
    onClose();
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.fieldContainer}>
        <Typography>Parent Name AOS</Typography>
        <Field
          component={TextField}
          name="parent_name_aos"
          fullWidth
          variant="outlined"
          label="Parent Name AOS"
          size="small"
          InputLabelProps={{ shrink: true }}
          sx={styles.field}
        />
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Parent ID AOS</Typography>
        <Field
          component={TextField}
          name="parent_id_aos"
          fullWidth
          variant="outlined"
          label="Parent ID AOS"
          size="small"
          InputLabelProps={{ shrink: true }}
          sx={styles.field}
        />
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Child Publishers</Typography>
        <Autocomplete
          sx={styles.field}
          multiple
          options={publishers?.map(publisher => publisher?.name) || []}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={params => (
            <TextField
              {...params}
              label="Publishers"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
            />
          )}
          onChange={(event, value) => setFieldValue('publishers', value)}
          value={values.publishers}
        />
      </Box>
      <Box sx={styles.buttons}>
        <Button variant="outlined" sx={styles.button} onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleClose} sx={styles.button} variant="contained">
          Save
        </Button>
      </Box>
    </Box>
  );
}
