import { Action } from 'common/interfaces/root';
import { overviewActionTypes as T } from '../actions';

const initState: Overview.CorrelationStore = {
  loading: true,
  error: null,
  entries: null,
};

export default (state: Overview.CorrelationStore = initState, { type, payload }: Action): Overview.CorrelationStore => {
  switch (type) {
    case T.GET_CORRELATION_STATISTICS:
      return { ...state, loading: true };

    case T.GET_CORRELATION_STATISTICS_SUCCESS:
      return { ...state, loading: false, entries: payload };

    case T.GET_CORRELATION_STATISTICS_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
