import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import { conversionsApiService } from 'modules/conversions/services/conversions-api-service';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { convActions } from '../actions';
import { actionTypes } from '../actions/action-types';

function* getConversionsSaga({ payload }: Action) {
  try {
    const { data } = yield call(conversionsApiService.getConversions, payload);

    yield put(convActions.conversionsActions.getConversionsSuccess(data));
  } catch (error) {
    yield put(convActions.conversionsActions.getConversionsError(error as Error));
  }
}

function* getExportSaga({ payload }: Action) {
  try {
    const { data } = yield call(conversionsApiService.getExport, payload);

    yield put(convActions.exportActions.getExportSuccess(data));
  } catch (error) {
    yield put(convActions.exportActions.getExportError(error as Error));
  }
}

function* getReportsSaga({ payload }: Action) {
  try {
    const { data } = yield call(conversionsApiService.getReports, payload);
    yield put(convActions.reportsActions.getReportsSuccess(data.reports_history));
  } catch (error) {
    yield put(convActions.reportsActions.getReportsError(error as Error));
  }
}

export function* conversionsSaga(): SagaIterator {
  yield all([
    takeLatest(actionTypes.conversions.GET_CONVERSIONS, getConversionsSaga),
    takeLatest(actionTypes.export.GET_EXPORT, getExportSaga),
    takeLatest(actionTypes.report.GET_REPORTS, getReportsSaga),
  ]);
}
