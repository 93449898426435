import { Action } from 'common/interfaces/root';
import { asinRecommendationActionTypes as TYPES } from '../actions/action-types';

const initialState: AsinRecommendationModule.State.GridState = {
  limit: 20,
  offset: 0,
  featuredLimit: 40,
  featuredOffset: 0,
  viewMore: false,
  selected: null,
  modalOpen: false,
};

export default (
  state: AsinRecommendationModule.State.GridState = initialState,
  { type, payload }: Action
): AsinRecommendationModule.State.GridState => {
  switch (type) {
    case TYPES.GRID.SET_GRID_LIMIT:
      return { ...state, limit: payload };
    case TYPES.GRID.SET_GRID_OFFSET:
      return { ...state, offset: payload };
    case TYPES.GRID.SET_GRID_FEATURED_LIMIT:
      return { ...state, featuredLimit: payload };
    case TYPES.GRID.SET_GRID_FEATURED_OFFSET:
      return { ...state, featuredOffset: payload };
    case TYPES.GRID.SET_GRID_VIEW_MORE:
      return { ...state, viewMore: payload };
    case TYPES.GRID.SET_GRID_SELECTED:
      return { ...state, selected: payload };
    case TYPES.GRID.SET_GRID_MODAL_OPEN:
      return { ...state, modalOpen: payload };

    case TYPES.TABLE.RESET_TABLE:
      return initialState;

    default:
      return state;
  }
};
