import { Typography } from '@mui/material';
import { selectGlobalFilters } from 'common/store/selectors';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { UrlsTable } from './components';
import { urlsActions } from './store/actions';
import { selectCurrentUrlsPage } from './store/selectors';

export function Urls(): ReactElement {
  const dispatch = useDispatch();

  const filters = useSelector(selectGlobalFilters);
  const currentPage = useSelector(selectCurrentUrlsPage);

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const parsedFiltersForTable: IStatisticsQueryString = useMemo(
    () => ({
      limit: 10,
      offset: currentPage * 10,
      publisher_id: filters.publisher_id,
    }),
    [filters, currentPage]
  );

  const debouncedTableStatistics = useDebouncedCallback((filters: IStatisticsQueryString) => {
    dispatch(urlsActions.getUrls(filters));
  }, 300);

  useEffect(() => {
    if (!isInitialized) return;

    debouncedTableStatistics(parsedFiltersForTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedFiltersForTable]);

  useEffect(() => {
    setIsInitialized(true);

    dispatch(urlsActions.getUrls(parsedFiltersForTable));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant="h4">Urls</Typography>
      <UrlsTable />
    </>
  );
}
