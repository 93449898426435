import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { filtersActions } from 'common/store/actions/filters';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { BaseFilters } from 'common/ui/base-filters';
import { DateByValue, IStatisticsQueryString, Traffic } from 'common/ui/base-filters/types';
import { Moment } from 'moment';
import { theme } from 'common/constants/theme';
import { MouseEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchInput } from 'common/ui/search-input';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useDebouncedCallback } from 'use-debounce';
import { NoDataScreen } from 'common/ui/no-data-screen';
import { selectUserData } from 'modules/user-settings/store/selectors';
import { selectAllStatistics } from 'modules/overview/store/selectors';
import { userSettingsActions } from 'modules/user-settings/store/actions';
import { ExportPopover, RevenueTable, Top10Chart } from './components';
import { revenueByPageActions } from './store/actions';
import { selectCurrentPage, selectExportLoading, selectTableRowsPerPage, selectTableSort } from './store/selectors';

const styles: Styles = {
  filtersContainer: { marginTop: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  exportText: { marginLeft: 1 },
  exportLoading: { color: theme.palette.background.paper },
  head: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
};

export function RevenueByPage(): ReactElement {
  const dispatch = useDispatch();

  const filters = useSelector(selectGlobalFilters);
  const currentPage = useSelector(selectCurrentPage);
  const sort = useSelector(selectTableSort);
  const rowsPerPage = useSelector(selectTableRowsPerPage);
  const exportLoading = useSelector(selectExportLoading);
  const statistics = useSelector(selectAllStatistics);
  const user = useSelector(selectUserData);

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [exportButtonAnchor, setExportButtonAnchor] = useState<HTMLElement | null>(null);
  const [exportBtnWidth, setExportBtnWidth] = useState<number>(0);

  const parsedFilters = useMemo(
    () => ({
      date_from: filters.date_from,
      date_to: filters.date_to,
      publisher_id: filters.publisher_id,
      search: filters.search,
      traffic_type: filters.traffic_type,
      affiliate_network_type: filters.affiliate_network_type,
      date_by: filters.date_by,
    }),
    [filters]
  );

  const parsedFiltersForTable: IStatisticsQueryString = useMemo(
    () => ({
      limit: rowsPerPage,
      offset: currentPage * rowsPerPage,
      sort,
      ...parsedFilters,
    }),
    [currentPage, parsedFilters, sort, rowsPerPage]
  );

  const handleRangeChange = (from: Moment, to: Moment) => {
    dispatch(filtersActions.changeRange(from, to));
  };

  const handleTrafficSourceChange = (trafficSource: Traffic) => {
    dispatch(filtersActions.changeTrafficSource(trafficSource));
  };

  const handleAffiliateNetworkTypeChange = (id: number | string) => {
    dispatch(filtersActions.changeAffiliateNetworkType(id));
  };

  const handleSearchChange = (search: string) => {
    dispatch(filtersActions.changeSearch(search));
  };

  const handleDateByChange = (dateBy: DateByValue) => {
    dispatch(filtersActions.changeDateBy(dateBy));
  };

  const handleExportPopoverOpen = (e: MouseEvent<HTMLButtonElement>) => {
    if (exportLoading) return;
    setExportButtonAnchor(e.currentTarget);
  };

  const handleExportPopoverClose = () => {
    setExportButtonAnchor(null);
  };

  const debouncedTableStatistics = useDebouncedCallback((filters: IStatisticsQueryString) => {
    dispatch(revenueByPageActions.getRevenueByPageTable(filters));
  }, 300);

  const debouncedChartStatistics = useDebouncedCallback((filters: IStatisticsQueryString) => {
    dispatch(revenueByPageActions.getTop10Pages(filters));
  }, 300);

  useEffect(() => {
    if (!isInitialized) return;

    debouncedTableStatistics(parsedFiltersForTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedFiltersForTable]);

  useEffect(() => {
    if (!isInitialized) return;

    debouncedChartStatistics(parsedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedFilters]);

  useEffect(() => {
    setIsInitialized(true);
    dispatch(userSettingsActions.getUserSettingsInfo());
    dispatch(revenueByPageActions.getRevenueByPageTable(parsedFiltersForTable));
    dispatch(revenueByPageActions.getTop10Pages(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {statistics && !statistics.is_any_statistics_exist && <NoDataScreen username={user?.name} />}
      <Box sx={styles.head}>
        <Typography variant="h4">Revenue By Page</Typography>
        <SearchInput search={filters.search} handleSearchChange={handleSearchChange} />
      </Box>
      <Box sx={styles.filtersContainer}>
        <BaseFilters
          date={{ from: filters.date_from, to: filters.date_to }}
          handleRangeChange={handleRangeChange}
          trafficSource={filters.traffic_type}
          handleTrafficSourceChange={handleTrafficSourceChange}
          handleAffiliateNetworkTypeChange={handleAffiliateNetworkTypeChange}
          affiliateNetworkType={filters.affiliate_network_type}
          handleDateByChange={handleDateByChange}
          dateBy={filters.date_by}
          show={['date', 'trafficSource', 'affiliateNetwork', 'dateBy']}
        />
        <Button variant="contained" onClick={handleExportPopoverOpen} ref={ref => setExportBtnWidth(ref?.clientWidth)}>
          {exportLoading ? (
            <CircularProgress size={20} sx={styles.exportLoading} />
          ) : (
            <FileDownloadIcon fontSize="small" />
          )}{' '}
          <Typography sx={styles.exportText}>Export CSV</Typography>
        </Button>
      </Box>
      <Top10Chart />
      <RevenueTable />
      <ExportPopover onClose={handleExportPopoverClose} anchorElement={exportButtonAnchor} width={exportBtnWidth} />
    </>
  );
}
