import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { theme } from 'common/constants/theme';
import { selectRecommendedAsinsExport, selectRecommendedAsinsFilters } from 'modules/affiliate-gainz/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { selectGlobalFilters } from 'common/store/selectors';
import { recommendedAsinsActions } from 'modules/affiliate-gainz/store/recommended-asins/actions';
import { useDebouncedCallback } from 'use-debounce';
import { SearchInput } from 'common/ui/search-input';
import { selectPublisherInfo, selectPublisherInfoLoading } from 'modules/settings/store/selectors';
import { downloadFile } from 'modules/conversions/utils';
import moment from 'moment';
import HeaderImg from 'common/svg/header-img.png';
import { Portal } from 'common/ui/portal';
import { AsinsTable } from './asins-table';
import { FiltersModal } from './filters-modal';

const styles: Styles = {
  headTitle: {
    color: '#ffffff',
    fontWeight: 600,
  },
  head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: `url(${HeaderImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#4288F0',
    padding: '28px',
    boxSizing: 'border-box',
    color: '#ffffff',
    fontWeight: 600,
  },
  desc: { maxWidth: 580, lineHeight: '27px', fontSize: 14, color: '#214254', marginTop: 1 },
  container: { display: 'flex', flexDirection: 'column' },
  filters: { display: 'flex', alignItems: 'center', gap: 1.5 },
  filtersBtn: {
    width: 36,
    height: 36,
    p: 0,
    minWidth: 36,
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    ':hover': { color: theme.palette.primary.light, borderColor: theme.palette.primary.light },
  },
  squaredBtn: {
    width: 36,
    height: 36,
    p: 0,
    minWidth: 36,
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    ':hover': { color: theme.palette.primary.light, borderColor: theme.palette.primary.light },
  },
  util: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  noAccessContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' },
};

const calculateMonths = (value: string): number[] | null => {
  switch (value) {
    case 'none':
      return [];
    case 'current_month':
      return [moment().month()];
    case 'next_month':
      return [moment().add(1, 'M').month()];
    case 'next_three_months':
      return [moment().add(1, 'M').month(), moment().add(2, 'M').month(), moment().add(3, 'M').month()];
    case 'next_six_months':
      return [
        moment().add(1, 'M').month(),
        moment().add(2, 'M').month(),
        moment().add(3, 'M').month(),
        moment().add(4, 'M').month(),
        moment().add(5, 'M').month(),
        moment().add(6, 'M').month(),
      ];

    default:
      return [];
  }
};

export function RecommendedAsinsV2(): ReactElement {
  const dispatch = useDispatch();

  const filters = useSelector(selectRecommendedAsinsFilters);
  const exp = useSelector(selectRecommendedAsinsExport);
  const globalFilters = useSelector(selectGlobalFilters);
  const publisher = useSelector(selectPublisherInfo);
  const publisherLoading = useSelector(selectPublisherInfoLoading);

  const [filtersModalOpen, setFiltersModalOpen] = useState<boolean>(false);

  const filterParams = useMemo<AffiliateGainzModule.RecommendedAsinsModule.FiltersState>(
    () => ({
      publisher_id: globalFilters.publisher_id,
      search: filters.search,
      category: filters.category === 'all' ? '' : filters.category,
      status: filters.status === 'all' ? '' : filters.status,
      seasons: calculateMonths(filters.season).map(month => month + 1),
      price_min: filters.price_min,
      price_max: filters.price_max,
      earn_min: filters.earn_min,
      earn_max: filters.earn_max,
      product_sort: filters.product_sort,
      price_sort: filters.price_sort,
    }),
    [globalFilters, filters]
  );

  const debouncedAsins = useDebouncedCallback(filters => {
    dispatch(recommendedAsinsActions.getAsins(filters));
  }, 300);

  const handleAsinsModalOpen = () => {
    setFiltersModalOpen(true);
  };

  const handleAsinsModalClose = () => {
    setFiltersModalOpen(false);
  };

  const handleSearchChange = (search: string) => {
    dispatch(recommendedAsinsActions.setOffset(0));
    dispatch(recommendedAsinsActions.setSearch(search));
  };

  const handleExport = () => {
    dispatch(recommendedAsinsActions.getExport(filterParams));
  };

  const handleApply = () => {
    dispatch(
      recommendedAsinsActions.getAsins({
        ...filterParams,
        limit: filters.limit,
        offset: filters.offset * filters.limit,
      })
    );
    handleAsinsModalClose();
  };

  const handleReset = () => {
    dispatch(recommendedAsinsActions.resetFilters());
  };

  useEffect(() => {
    debouncedAsins({
      ...filterParams,
      limit: filters.limit,
      offset: filters.offset * filters.limit,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.limit, filters.offset, filterParams.search, filterParams.product_sort, filterParams.price_sort]);

  useEffect(() => {
    if (exp.data) {
      downloadFile('recommended_asins', exp.data);
      dispatch(recommendedAsinsActions.getExportSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exp.data]);

  useEffect(() => {
    dispatch(publishersSettingsActions.getMainPublisherInfo(globalFilters?.publisher_id.toString()));
    dispatch(recommendedAsinsActions.getCategories(globalFilters?.publisher_id.toString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.container}>
      {(publisher?.gainz_active && (
        <>
          <Portal id="header-page">
            <Box sx={styles.head}>
              <Typography sx={styles.headTitle}>Recommended Gainz</Typography>
              <Box sx={styles.filters}>
                <SearchInput search={filters.search} handleSearchChange={handleSearchChange} />
                <Button variant="outlined" sx={styles.filtersBtn} size="small" onClick={handleAsinsModalOpen}>
                  <TuneIcon fontSize="small" />
                </Button>
                <Button variant="outlined" sx={styles.squaredBtn} size="small" onClick={handleExport}>
                  {exp.loading ? <CircularProgress size={14} color="inherit" /> : <FileDownloadIcon fontSize="small" />}
                </Button>
              </Box>
            </Box>{' '}
          </Portal>
          <Box sx={styles.util}>
            <Typography sx={styles.desc}>
              Below are products that you can add to your articles! Adding these products provides you with additional
              earnings and payouts.
            </Typography>
          </Box>
          <AsinsTable />
          <FiltersModal
            onClose={handleAsinsModalClose}
            isOpen={filtersModalOpen}
            handleApply={handleApply}
            handleReset={handleReset}
            filters={filters}
          />
        </>
      )) || (
        <Box sx={styles.noAccessContainer}>
          {(publisherLoading && <CircularProgress />) || (
            <>
              <Typography variant="h1">Access denied</Typography>
              <Typography variant="h6">You are unauthorized to view this page</Typography>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
