import { Tooltip } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Styles } from 'common/types';
import { Severity, WarningProps } from './types';

const getStyles = (severity: Severity): Styles => {
  const defStyles: Styles = { styles: { height: '16px', ':hover': { cursor: 'pointer' } } };

  switch (severity) {
    case 'mild':
      return { warning: { color: 'orange', ...defStyles.styles } };
    case 'severe':
      return { warning: { color: 'red', ...defStyles.styles } };
    default:
      return { warning: { color: 'orange', ...defStyles.styles } };
  }
};

export function Warning({ tooltip, severity = 'mild' }: WarningProps) {
  const styles = getStyles(severity);

  if (tooltip)
    return (
      <Tooltip title={tooltip}>
        <WarningAmberIcon sx={styles.warning} />
      </Tooltip>
    );

  return <WarningAmberIcon sx={styles.warning} />;
}
