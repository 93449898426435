import { Action } from 'common/interfaces/root';
import { contentOverviewActionTypes as T } from '../actions';

const initState: ContentOverviewModule.PagePerformanceArticleStore = {
  article: null,
  isModalVisible: false,
  datesToCompare: '',
};

export default (
  state: ContentOverviewModule.PagePerformanceArticleStore = initState,
  { type, payload }: Action
): ContentOverviewModule.PagePerformanceArticleStore => {
  switch (type) {
    case T.SET_PAGE_PERFORMANCE_ACTIVE_ARTICLE:
      return { ...state, article: payload };

    case T.SET_PAGE_PERFORMANCE_ACTIVE_ARTICLE_MODAL_VISIBLE:
      return { ...state, isModalVisible: payload };

    default:
      return state;
  }
};
