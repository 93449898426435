import { SystemStyleObject } from '@mui/system';
import { Box, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';
import { ReactElement, useMemo } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import StarIcon from '@mui/icons-material/Star';
import WarningIcon from '@mui/icons-material/Warning';
import CancelIcon from '@mui/icons-material/Cancel';
import RocketIcon from '@mui/icons-material/Rocket';
import { ReactComponent as AttributionIcon } from 'common/svg/maverickx-logomark-c.svg';
import { paper } from 'common/constants/colors';
import moment from 'moment';
import { usePermission } from 'common/hooks';
import { PERMISSIONS } from 'common/constants/permissions';
import { IProduct } from './types';

interface StylesProps {
  inStock: boolean;
  image: string;
  containerSx?: SystemStyleObject;
  isRequestedToSwap?: boolean;
  requestToSwapMode?: boolean;
  swapCompletionStatus?: string;
}

const getStyles = ({
  inStock,
  image,
  isRequestedToSwap,
  requestToSwapMode,
  swapCompletionStatus,
}: StylesProps): Styles => {
  let borderColor = '#C2DEEB';
  if (requestToSwapMode && !inStock && isRequestedToSwap && swapCompletionStatus === 'completed') {
    borderColor = '#13AB2C';
  } else if (requestToSwapMode && swapCompletionStatus === 'in-progress') {
    borderColor = '#dad108';
  } else if (!inStock) {
    borderColor = '#F5BEBC';
  }
  return {
    container: {
      backgroundColor: 'transparent',
      borderRadius: '8px',
      padding: theme.spacing(0.5, 1.5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      cursor: 'pointer',
      border: `1px ${borderColor} solid`,
      width: 250,
      maxHeight: 64,
      minHeight: 64,
      position: 'relative',
    },
    title: {
      fontSize: 12,
      minWidth: '110px',
    },
    alertIconWrapper: {
      borderRadius: '50%',
      padding: '2px',
      backgroundColor: paper,
      position: 'absolute',
      left: -12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    starIcon: { color: '#FFD645', width: 18, height: 18 },
    image: { width: 30, minWidth: 30, height: 30, backgroundImage: `url(${image})`, backgroundSize: 'contain' },
    imageContainer: { display: 'flex', flexDirection: 'column', gap: '2px', alignItems: 'center' },
    titleContainer: { display: 'flex', flexDirection: 'column', gap: 0.5 },
    asinTypeContainer: { display: 'flex', alignItems: 'flex-start' },
    srIcon: { fontSize: 15, color: 'purple' },
    asin: { color: '#6F8490', fontSize: 8 },
    reviewsText: { fontSize: 10, ml: 1, lineHeight: 1, color: '#6F8490' },
    reviewsTitleText: { color: '#214254', fontSize: 10, opacity: 0.65 },
    ratingContent: { display: 'flex', alignItems: 'center', ml: '-4px' },
    avgReviewsText: { lineHeight: 1, fontSize: 10 },
    pageOrder: {
      backgroundColor: '#ededed',
      borderRadius: '43px',
      width: '14px',
      textAlign: 'center',
      fontSize: '0.6rem',
      fontWeight: 200,
      boxShadow: '0 1px 5px rgb(0 0 0 / 0.2)',
    },
    tagContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 1, height: 44 },
    orderContainer: { display: 'flex', justifyContent: 'flex-end' },
    iconContainer: { width: 16 },
    detailContainer: { display: 'flex', alignItems: 'flex-start', gap: 1.5 },
  };
};

const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
    boxShadow: theme.shadows[1],
    padding: 10,
    fontSize: 11,
    borderRadius: '12px',
    lineHeight: '15px',
  },
}));

interface Props {
  product: IProduct;
  isOpenLinkOnClick?: boolean;
  showLastPullIcon?: boolean;
  showEarnIcon?: boolean;
  showOrderIcon?: boolean;
  containerSx?: SystemStyleObject;
  requestToSwapMode?: boolean;
}

export function Product({
  product,
  isOpenLinkOnClick,
  containerSx,
  showOrderIcon,
  showEarnIcon = false,
  showLastPullIcon,
  requestToSwapMode,
}: Props): ReactElement {
  if (typeof showLastPullIcon !== 'boolean') {
    showLastPullIcon = true;
  }
  if (typeof showOrderIcon !== 'boolean') {
    showOrderIcon = true;
  }
  if (typeof isOpenLinkOnClick !== 'boolean') {
    isOpenLinkOnClick = true;
  }

  const inStock = product.in_stock === undefined ? true : Boolean(product.in_stock);
  const canReadLastUpdated = usePermission(PERMISSIONS.READ_LAST_PRODUCT_PULL);
  const showDatesPopover = product.last_seen_oos || (product.last_updated && canReadLastUpdated);

  const adminView = usePermission(PERMISSIONS.GET_PUBLISHERS);

  const shortTitle = useMemo(() => {
    return product.title?.length > 25 ? `${product.title.slice(0, 25)}...` : product.title;
  }, [product.title]);

  const styles = getStyles({
    inStock,
    isRequestedToSwap: !!product?.requested_asin_to_replace,
    image: product.image ?? product.img_medium,
    containerSx,
    requestToSwapMode,
    swapCompletionStatus: product.swapCompletionStatus,
  });

  const handleOpenProduct = () => {
    if (isOpenLinkOnClick)
      window.open(product.amazon_link ? product.amazon_link : `https://www.amazon.com/dp/${product.asin}`);
  };

  const getFormattedScrapingInfo = () => {
    if (!showLastPullIcon) return '';

    const format = 'MMM DD/YY';
    let info = '';
    if (product.last_updated && canReadLastUpdated) info += `Last Pull: ${moment(product.last_updated).format(format)}`;
    if (product.last_seen_oos)
      info += `${product.last_updated && canReadLastUpdated ? '\n' : ''}OOS Since: ${moment(
        product.last_seen_oos
      ).format(format)}`;
    if (product.oos_status === 'not found') info += '\nNot found';
    if (product.oos_status === 'temporarily out of stock')
      info += `\nTemporarily OOS ${
        product.last_seen_oos ? `since ${moment(product.last_seen_oos).format(format)}` : ''
      }`;
    if (product.requested_asin_to_replace) {
      info += `\nSwap status: ${product.swapCompletionStatus}`;
    }
    return info;
  };

  const oosStatusIcon = () => {
    if (!!product.requested_asin_to_replace && !product.in_stock && product.swapCompletionStatus === 'completed') {
      return (
        <WarningIcon
          sx={{
            fill: '#13AB2C !important',
          }}
          className="product-requested-to-swap__icon"
          fontSize="small"
        />
      );
    }
    if (!!product.requested_asin_to_replace && !product.in_stock && product.swapCompletionStatus === 'in-progress') {
      return (
        <WarningIcon
          sx={{
            fill: '#dad108 !important',
          }}
          className="product-requested-to-swap__icon"
          fontSize="small"
        />
      );
    }

    const color = inStock ? 'action' : 'error';
    switch (product?.oos_status) {
      case 'not found':
        return <CancelIcon color={color} fontSize="small" />;
      case 'temporarily out of stock':
        return <WarningIcon color={color} fontSize="small" />;
      case 'out of stock':
        return <ErrorIcon color={color} fontSize="small" />;
      default:
        return <ErrorIcon color={color} fontSize="small" />;
    }
  };

  return (
    <Box sx={styles.container} onClick={handleOpenProduct}>
      {showDatesPopover && showLastPullIcon ? (
        <ErrorTooltip title={getFormattedScrapingInfo()} components={{ Tooltip: 'pre' }}>
          <Box sx={styles.alertIconWrapper}>{oosStatusIcon()}</Box>
        </ErrorTooltip>
      ) : null}
      {showDatesPopover && showLastPullIcon ? (
        <ErrorTooltip title={inStock ? 'In stock' : 'Out of stock'} components={{ Tooltip: 'pre' }}>
          <Box sx={styles.alertIconWrapper}>{oosStatusIcon()}</Box>
        </ErrorTooltip>
      ) : null}
      <Box sx={styles.detailContainer}>
        <Box sx={styles.imageContainer}>
          <Box sx={styles.image} />
          <Typography sx={styles.asin}>{product.asin}</Typography>
          {!inStock && requestToSwapMode && !!product.requested_asin_to_replace && (
            <svg
              className="union__icon"
              width="55"
              height="47"
              viewBox="0 0 31 30"
              style={{ position: 'absolute' }}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8259 15.1248L30.3104 29.6092C30.4568 29.7556 30.6942 29.7556 30.8407 29.6092C30.9871 29.4627 30.9871 29.2253 30.8407 29.0789L16.3563 14.5944L30.3104 0.64033C30.4568 0.493883 30.4568 0.256447 30.3104 0.11C30.1639 -0.0364466 29.9265 -0.0364472 29.78 0.11L15.8259 14.0641L1.87167 0.109835C1.72522 -0.0366116 1.48778 -0.0366116 1.34134 0.109835C1.19489 0.256282 1.19489 0.493718 1.34134 0.640165L15.2956 14.5944L0.811007 29.079C0.66456 29.2255 0.66456 29.4629 0.811007 29.6093C0.957453 29.7558 1.19489 29.7558 1.34134 29.6093L15.8259 15.1248Z"
                fill="#B3B3B3"
              />
            </svg>
          )}
        </Box>
        <Box sx={styles.titleContainer}>
          <Tooltip placement="top" title={product?.title}>
            <Typography sx={styles.title}>{shortTitle}</Typography>
          </Tooltip>
          <Box sx={styles.ratingContent}>
            {product.avg_review ? <StarIcon sx={styles.starIcon} /> : null}
            <Typography sx={styles.avgReviewsText}>{product.avg_review ?? ''}</Typography>
            <Typography sx={styles.reviewsText}>
              {product.review_count ? ` (${product.review_count} reviews)` : ''}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.tagContainer}>
        <Box sx={styles.asinTypeContainer}>
          <Box sx={styles.iconContainer}>
            {(product?.type === 'sr_products' || product?.is_pubx) && adminView && <RocketIcon sx={styles.srIcon} />}
          </Box>
          <Box sx={styles.iconContainer}>
            {product?.is_attribution && adminView && <AttributionIcon width="15px" height="15px" />}
          </Box>
        </Box>
        <Box sx={styles.orderContainer}>
          {product.pageOrder && showOrderIcon && (
            <Tooltip title="ASIN placement">
              <Typography sx={styles.pageOrder}>{product.pageOrder}</Typography>
            </Tooltip>
          )}
          {showEarnIcon && (
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.35365 12.8438C3.11636 12.8438 0.492188 10.1575 0.492188 6.84375C0.492188 3.52995 3.11636 0.84375 6.35365 0.84375C9.59093 0.84375 12.2151 3.52995 12.2151 6.84375C12.2151 10.1575 9.59093 12.8438 6.35365 12.8438ZM6.35365 11.6438C7.59729 11.6438 8.79 11.138 9.66939 10.2379C10.5488 9.33769 11.0428 8.11679 11.0428 6.84375C11.0428 5.57071 10.5488 4.34981 9.66939 3.44964C8.79 2.54946 7.59729 2.04375 6.35365 2.04375C5.11 2.04375 3.91729 2.54946 3.0379 3.44964C2.15852 4.34981 1.66448 5.57071 1.66448 6.84375C1.66448 8.11679 2.15852 9.33769 3.0379 10.2379C3.91729 11.138 5.11 11.6438 6.35365 11.6438ZM4.30214 8.04375H7.52594C7.60367 8.04375 7.67821 8.01214 7.73317 7.95588C7.78813 7.89962 7.81901 7.82332 7.81901 7.74375C7.81901 7.66419 7.78813 7.58788 7.73317 7.53162C7.67821 7.47536 7.60367 7.44375 7.52594 7.44375H5.18135C4.79272 7.44375 4.41999 7.28572 4.14519 7.00441C3.87038 6.72311 3.71599 6.34158 3.71599 5.94375C3.71599 5.54593 3.87038 5.16439 4.14519 4.88309C4.41999 4.60179 4.79272 4.44375 5.18135 4.44375H5.7675V3.24375H6.93979V4.44375H8.40516V5.64375H5.18135C5.10363 5.64375 5.02908 5.67536 4.97412 5.73162C4.91916 5.78788 4.88828 5.86419 4.88828 5.94375C4.88828 6.02331 4.91916 6.09962 4.97412 6.15588C5.02908 6.21214 5.10363 6.24375 5.18135 6.24375H7.52594C7.91458 6.24375 8.2873 6.40179 8.56211 6.68309C8.83692 6.96439 8.9913 7.34593 8.9913 7.74375C8.9913 8.14157 8.83692 8.52311 8.56211 8.80441C8.2873 9.08572 7.91458 9.24375 7.52594 9.24375H6.93979V10.4438H5.7675V9.24375H4.30214V8.04375Z"
                fill="#4288F0"
              />
            </svg>
          )}
        </Box>
      </Box>
    </Box>
  );
}
