import { overviewReducers } from 'modules/overview/store';
import { overviewReducers as overviewV2Reducers } from 'modules/overview-v2/store';
import { attributionReducers } from 'modules/attribution/store';
import { publishersSettingsReducers } from 'modules/publishers-settings/store';
import { revenueByPageReducers } from 'modules/revenue-by-page/store';
import { tagsReducers } from 'modules/tags/store';
import { urlsReducers } from 'modules/urls/store';
import { settingsReducers } from 'modules/settings/store';
import { userSettingsReducers } from 'modules/user-settings/store';
import { contentOverviewReducers } from 'modules/content-overview/store';
import { affiliateRateCardReducers } from 'modules/settings/components/publishers-settings/publisher-settings/publisher-settings-form/conversion-settings/affiliate-rate-card/store/reducers';
import { combineReducers } from 'redux';
import { conversionsReducers } from 'modules/conversions/store';
import { asinFinderReducers } from 'modules/asin-finder/store';
import { recommendedAsinsReducer } from 'modules/affiliate-gainz/store/recommended-asins/reducers';
import { gainzRevenueReducer } from 'modules/affiliate-gainz/store/gainz-revenue/reducers';
import { affiliateAccessReducer } from 'modules/affiliate-gainz/store/reducers';
import { asinRecommendationReducer } from 'modules/asin-recommendation/store/reducers';
import { mavPlusReducers } from 'modules/mav-plus/store/reducers';
import { parentPublisherSettingsReducers } from 'modules/parent-publisher-settings/store';
import { publishersTagsReducer } from 'modules/publishers-affiliate-tags/store/reducers';
import { asinsConversionsBoostReducers } from 'modules/asins-conversions-boost/store/reducers';
import { publisherBrandCommissionsReducer } from 'modules/publisher-brand-commissions-settings/store';
import { filtersActionTypes } from '../actions/filters';
import { getFiltersReducer } from './filters';
import datePickerComparatorReducer from './date-picker-comparator';
import presets from './presets';
import rootReducer from './root';
import access from './access';
import { amazonAffiliateReportsReducers } from '../../../modules/amazon-affiliate-reports/store';

export const getReducers = () =>
  combineReducers({
    root: rootReducer,
    asinFinder: asinFinderReducers,
    overview: overviewReducers,
    overviewV2: overviewV2Reducers,
    revenueByPage: revenueByPageReducers,
    filters: getFiltersReducer(filtersActionTypes),
    settings: settingsReducers,
    publishersSettings: publishersSettingsReducers,
    parentPublisherSettings: parentPublisherSettingsReducers,
    userSettings: userSettingsReducers,
    tags: tagsReducers,
    urls: urlsReducers,
    attribution: attributionReducers,
    contentOverview: contentOverviewReducers,
    conversions: conversionsReducers,
    recommendedGainzAsins: recommendedAsinsReducer,
    gainzRevenue: gainzRevenueReducer,
    affiliateRateCard: affiliateRateCardReducers,
    affiliateAccess: affiliateAccessReducer,
    asinRecommendation: asinRecommendationReducer,
    mavPlus: mavPlusReducers,
    accessReducer: access,
    datePickerComparatorReducer,
    presets,
    amazonAffiliateReports: amazonAffiliateReportsReducers,
    publishersTagsReducer,
    asinsConversionsBoost: asinsConversionsBoostReducers,
    publisherBrandCommissions: publisherBrandCommissionsReducer,
  });

const reducers = getReducers();

export type State = ReturnType<typeof reducers>;
