import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { text } from 'common/constants/colors';
import { Styles } from 'common/types';
import { ReactElement, ReactNode, isValidElement } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from 'common/constants/theme';

const styles: Styles = {
  dialog: { borderRadius: '20px' },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 2, 3),
    boxSizing: 'border-box',
  },
  title: { color: text, fontSize: 21, p: 0 },
  content: { padding: 0 },
  contentWrapper: { padding: 3 },
  close: { marginLeft: 0.5 },
};

interface Props {
  title?: string | ReactElement;
  children: ReactNode;
  open: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  scrollType?: 'paper' | 'body';
  showCloseIcon?: boolean;
  onClose: () => void;
}

export function Modal({
  title,
  showCloseIcon = true,
  children,
  open,
  onClose,
  scrollType,
  maxWidth = 'sm',
}: Props): ReactElement {
  // if (typeof showCloseIcon !== 'boolean') {
  //   showCloseIcon = true;
  // }

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth
      scroll={scrollType}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: styles.dialog }}
    >
      <Box sx={styles.titleContainer}>
        {isValidElement(title) ? title : <DialogTitle sx={styles.title}>{title}</DialogTitle>}
        {showCloseIcon && (
          <IconButton sx={styles.close} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <DialogContent sx={styles.content}>
        <Box sx={styles.contentWrapper}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
}
