import { Box, Button, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 1.5 },
  primaryText: { fontSize: 14, lineHeight: '26px' },
  secondaryText: { fontSize: 13, color: '#214254', lineHeight: '24px' },
  btns: { display: 'flex', alignItems: 'center', gap: 1.5, width: '100%' },
  connectBtn: { flexGrow: 1 },
  and: { color: '#6F8490', fontSize: 10 },
  footer: { width: '100%', gap: 2, display: 'flex', justifyContent: 'flex-end', mt: 4 },
  footerBtn: { width: 120 },
};

interface Props {
  open: boolean;
  onClose: () => void;
}

export function WelcomeModal({ open, onClose }: Props): ReactElement {
  return (
    <Modal open={open} onClose={onClose} title="Welcome to MaverickX for Publishers!">
      <Box sx={styles.container}>
        <Typography sx={styles.primaryText}>
          We are so excited you are here and ready to get started. To make sure everything is set up correctly, please
          take a few minutes to complete the next two steps.
        </Typography>
        <Typography sx={styles.secondaryText}>
          Embed the javascript on your website to gain insights on clicks, visits and other user activities on your
          page. Once JS is integrated, please wait 48hrs for metrics to update.
        </Typography>
        <Typography sx={styles.secondaryText}>
          To view your revenue and ad fees, connect your affiliate accounts and access the most accurate insights
          related to your page performance. Once accounts are connected, please wait 48hrs for reports to update.
        </Typography>
        <Box sx={styles.btns}>
          <Link to="/publisher/embed" target="_blank" style={{ flexGrow: 1 }}>
            <Button sx={styles.connectBtn} variant="contained" color="primary" size="small" fullWidth>
              Download Wordpress Plug In
            </Button>
          </Link>
          <Typography sx={styles.and}>and</Typography>
          <Link to="/publisher/affiliate" target="_blank" style={{ flexGrow: 1 }}>
            <Button sx={styles.connectBtn} variant="contained" color="primary" size="small" fullWidth>
              Connect your Affilate Accounts
            </Button>
          </Link>
        </Box>
      </Box>
      <Box sx={styles.footer}>
        <Button onClick={onClose} variant="outlined" size="small" sx={styles.footerBtn}>
          Skip
        </Button>
        <Button onClick={onClose} variant="contained" size="small" sx={styles.footerBtn}>
          Done
        </Button>
      </Box>
    </Modal>
  );
}
