import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Styles } from 'common/types';
import moment from 'moment';
import { ReactNode } from 'react';

interface TagsImportInfoProps {
  publisherTagsImport: Partial<PublishersAffiliateTagsModule.TagsImportStatusPayload>;
}

const styles: Styles = {
  lastImportInfo: {
    display: 'flex',
    marginBottom: '40px',
  },
};

const importStatusToUpperCase = (status: string) => status.charAt(0).toUpperCase() + status.slice(1);

export function TagsImportInfo({ publisherTagsImport }: TagsImportInfoProps) {
  return (
    <Box sx={styles.lastImportInfo}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={4} sx={{ fontSize: '15px' }}>
              Last Tags Import
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Tag Prefix</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Completed At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{publisherTagsImport.tagPrefix}</TableCell>
            <TableCell>{publisherTagsImport.amount}</TableCell>
            <TableCell>
              {((): ReactNode => {
                if (!publisherTagsImport.status && publisherTagsImport.completedAt) return 'Finished';
                return publisherTagsImport.status ? importStatusToUpperCase(publisherTagsImport.status) : 'Idle';
              })()}
            </TableCell>
            <TableCell>
              {publisherTagsImport.completedAt
                ? moment(publisherTagsImport.completedAt).format('MMMM-DD-YYYY:HH:mm')
                : ''}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
