import { SelectOption } from 'common/ui/select-field/select-field';
import moment from 'moment-timezone';

export const ANY_OOS_RANGE = {
  from: 'any',
  to: 'any',
};
export const getLast7DaysOOSRange = () => ({
  from: moment().subtract(7, 'days').startOf('day').tz('UTC', true).toISOString(),
  to: moment().startOf('day').tz('UTC', true).toISOString(),
});

export function generateOOSDates(): Array<SelectOption> {
  const dates: Array<SelectOption> = [
    {
      title: 'Any',
      value: ANY_OOS_RANGE,
    },
    {
      title: '<= 7 days',
      value: getLast7DaysOOSRange(),
    },
    {
      title: '> 7 days',
      value: {
        from: moment().subtract(15, 'days').startOf('day').tz('UTC', true).toISOString(),
        to: moment().subtract(7, 'days').startOf('day').tz('UTC', true).toISOString(),
      },
    },
    {
      title: '> 14 days',
      value: {
        from: moment().subtract(29, 'days').startOf('day').tz('UTC', true).toISOString(),
        to: moment().subtract(14, 'days').startOf('day').tz('UTC', true).toISOString(),
      },
    },
    {
      title: '> 30 days',
      value: {
        from: moment().subtract(10, 'years').startOf('day').tz('UTC', true).toISOString(),
        to: moment().subtract(30, 'days').startOf('day').tz('UTC', true).toISOString(),
      },
    },
  ];

  return dates;
}
