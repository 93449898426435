import { ReactElement, useMemo } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'common/ui/modal';
import { parentPublisherSettingsActions } from 'modules/parent-publisher-settings/store/actions';
import { selectPublishers } from 'modules/publishers-settings/store/selectors';
import { FormContent } from './form-content';

interface Props {
  type: 'create' | 'edit';
  open: boolean;
  account?: ParentPublisherSettingsModule.PublisherAccount;
  onClose: () => void;
}

export function FormModal({ type, open, account, onClose }: Props): ReactElement {
  const dispatch = useDispatch();

  const publishers = useSelector(selectPublishers);

  const initialValues = useMemo<ParentPublisherSettingsModule.PublisherAccountForm>(
    () => ({
      parent_name_aos: account?.parent_name_aos ?? '',
      parent_id_aos: account?.parent_id_aos?.toString() ?? null,
      publishers:
        account?.publishers?.map(val => publishers?.find(publisher => publisher?.id?.toString() === val)?.name) ?? [],
    }),
    [account, publishers]
  );

  const parsePublishers = (values: string[], publishers: PublishersSettingsModule.Publisher[]): string[] => {
    return values?.map(value => publishers?.find(publisher => publisher?.name === value)?.id?.toString());
  };

  const handleSubmit = (values: ParentPublisherSettingsModule.PublisherAccountForm) => {
    switch (type) {
      case 'create':
        dispatch(
          parentPublisherSettingsActions.postPublisherAccountsParents({
            ...values,
            publishers: parsePublishers(values?.publishers, publishers),
          })
        );
        break;
      case 'edit':
        dispatch(
          parentPublisherSettingsActions.patchPublisherAccountsParents({
            account_id: account?.id,
            account: {
              ...values,
              publishers: parsePublishers(values?.publishers, publishers),
            },
          })
        );
        break;

      default:
        break;
    }
  };

  return (
    <Modal open={open} title={type === 'create' ? 'Create Entry' : 'Edit Entry'} maxWidth="sm" onClose={onClose}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <FormContent onClose={onClose} />
      </Formik>
    </Modal>
  );
}
