const ACCESS_MODULE = 'ACCESS_MODULE';

const GET_ACCESS = `${ACCESS_MODULE} GET_ACCESS`;
const GET_ACCESS_SUCCESS = `${ACCESS_MODULE} GET_ACCESS_SUCCESS`;
const GET_ACCESS_ERROR = `${ACCESS_MODULE} GET_ACCESS_ERROR`;

export const accessActionTypes = {
  GET_ACCESS,
  GET_ACCESS_SUCCESS,
  GET_ACCESS_ERROR,
};
