import { LC_SELECTED_PUBLISHER_KEY } from 'common/constants/lc';
import { Action } from 'common/interfaces/root';
import { userService } from 'common/services/user.service';
import { Click, DateByValue, IStatisticsQueryString, Traffic } from 'common/ui/base-filters/types';
import moment from 'moment';

const getPublisherId = () => {
  const cachedId = Number(localStorage.getItem(LC_SELECTED_PUBLISHER_KEY));
  const isCachedFound = userService.user?.publisher_ids.includes(cachedId);

  return isCachedFound ? cachedId : userService.user?.publisher_ids[0];
};

const getInitState = (initialValues: IStatisticsQueryString): IStatisticsQueryString => {
  return {
    ...initialValues,
    publisher_id: getPublisherId(),
    date_from: moment().subtract(8, 'days').startOf('day'),
    date_to: moment().subtract(1, 'day').endOf('day'),
    traffic_type: Traffic.Combined,
    used: -1,
    affiliate_network_type: -1,
    search: '',
    date_by: DateByValue.Click,
    clicks: Click.All,
    exportLimit: 0,
    publisher_group: 'All',
  };
};

export const getFiltersReducer =
  (actionTypes: Record<string, string>, initialValues: IStatisticsQueryString = {}) =>
  (state: IStatisticsQueryString = getInitState(initialValues), { type, payload }: Action): IStatisticsQueryString => {
    switch (type) {
      case actionTypes.CHANGE_IS_PAID:
        return { ...state, traffic_type: payload };

      case actionTypes.CHANGE_PUBLISHER:
        return { ...state, publisher_id: payload };

      case actionTypes.CHANGE_RANGE:
        return { ...state, date_from: payload.from, date_to: payload.to };

      case actionTypes.CHANGE_SEARCH:
        return { ...state, search: payload };

      case actionTypes.CHANGE_SEARCH_TYPE:
        return { ...state, searchType: payload };

      case actionTypes.CHANGE_USED:
        return { ...state, used: payload };

      case actionTypes.CHANGE_AFFILIATE_NETWORK_TYPE:
        return { ...state, affiliate_network_type: payload };

      case actionTypes.CHANGE_DATE_BY:
        return { ...state, date_by: payload };

      case actionTypes.CHANGE_TRANSACTION_CLICK:
        return { ...state, clicks: payload };

      case actionTypes.CHANGE_IN_STOCK:
        return { ...state, in_stock: payload };

      case actionTypes.CHANGE_OOS_RANGE:
        return { ...state, oos_range: payload };

      case actionTypes.CHANGE_OOS_STATUS:
        return { ...state, oos_status: payload };

      case actionTypes.CHANGE_PUBLISHER_GROUP:
        return { ...state, publisher_group: payload };

      case actionTypes.CHANGE_EXPORT_LIMIT:
        return { ...state, exportLimit: payload };

      case actionTypes.CHANGE_CONTENT_TYPE:
        return { ...state, article_type: payload };

      case actionTypes.CHANGE_SATURATION_OPERATOR:
        return { ...state, saturation_operator: payload };

      case actionTypes.CHANGE_SATURATION_VALUE:
        return { ...state, saturation_value: payload };

      case actionTypes.RESET_FILTERS:
        return getInitState(initialValues);
      default:
        return state;
    }
  };
