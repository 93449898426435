import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography, Box, Switch } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { putDemoMode } from 'common/store/actions';
import { selectGlobalFilters, selectRootDemo } from 'common/store/selectors';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';
import { publishersSettingsActions } from 'modules/publishers-settings/store/actions';
import { useDebouncedCallback } from 'use-debounce';
import { Banner } from './components/banner';
import { parentPublisherSettingsActions } from './store/actions';
import { ParentPublisherTable } from './components/parent-publisher-table';
import { FormModal } from './components/form-modal';

const styles: Styles = {
  addButton: { marginTop: 1, color: theme.palette.primary.dark, borderColor: theme.palette.primary.dark },
  bttnWrapper: { display: 'flex', justifyContent: 'space-between' },
  demoWrapper: { display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '12px' },
};

export function ParentPublisherSettings(): ReactElement {
  const dispatch = useDispatch();

  const isDemoMode = useSelector(selectRootDemo);
  const globalFilters = useSelector(selectGlobalFilters);

  const [init, setInit] = useState<boolean>(false);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [formType, setFormType] = useState<'create' | 'edit'>('create');
  const [selectedAccount, setSelectedAccount] = useState<ParentPublisherSettingsModule.PublisherAccount | null>(null);

  const publisherAccountsParams = useMemo<ParentPublisherSettingsModule.GetPublisherAccountsParams>(
    () => ({
      limit: 100,
      offset: 0,
      sort: 'name:asc',
      ...(globalFilters.search === '' ? {} : { search: globalFilters.search }),
    }),
    [globalFilters.search]
  );

  const handleFiltersModal = () => {
    setFiltersOpen(!filtersOpen);
  };

  const handleFormOpen = () => {
    setFormOpen(true);
  };

  const handleFormClose = () => {
    setFormOpen(false);
  };

  const handleCreateClick = () => {
    setFormType('create');
    handleFormOpen();
  };

  const handleEditClick = (account: ParentPublisherSettingsModule.PublisherAccount) => {
    setFormType('edit');
    setSelectedAccount(account);
    handleFormOpen();
  };

  const handleChangeDemoMode = () => {
    if (isDemoMode) {
      localStorage.setItem('demo', '0');
      dispatch(putDemoMode(false));
    } else {
      localStorage.setItem('demo', '1');
      dispatch(putDemoMode(true));
    }
  };

  const debouncedFilters = useDebouncedCallback(() => {
    dispatch(dispatch(parentPublisherSettingsActions.getPublisherAccountsParents(publisherAccountsParams)));
  }, 300);

  useEffect(() => {
    if (init) debouncedFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilters.search]);

  useEffect(() => {
    setInit(true);
    dispatch(parentPublisherSettingsActions.getPublisherAccountsParents(publisherAccountsParams));
    dispatch(publishersSettingsActions.getPublishers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Banner title="Parent Publishers" options={{ search: true }} handleFiltersOpen={handleFiltersModal} />
      <Box sx={styles.bttnWrapper}>
        <Button sx={styles.addButton} variant="outlined" onClick={handleCreateClick}>
          <AddIcon fontSize="small" />
          Add parent publisher
        </Button>
        <Box sx={styles.demoWrapper}>
          <Typography>Demo mode:</Typography>
          <Switch checked={isDemoMode} onClick={handleChangeDemoMode} />
        </Box>
      </Box>
      <ParentPublisherTable handleEditClick={handleEditClick} />
      <FormModal
        type={formType}
        open={formOpen}
        account={formType === 'edit' ? selectedAccount : {}}
        onClose={handleFormClose}
      />
    </>
  );
}
