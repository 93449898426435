import { Box, Typography } from '@mui/material';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { BaseFilters } from 'common/ui/base-filters';
import { IStatisticsQueryString, Traffic } from 'common/ui/base-filters/types';
import { UsedValue } from 'common/ui/base-filters/used-picker';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { TagsTable } from './components';
import { tagsActions, tagsFiltersActions } from './store/actions';
import { selectCurrentTagsPage, selectTagsFilters } from './store/selectors';

const styles: Styles = {
  filtersContainer: { marginTop: 3 },
};

export function Tags(): ReactElement {
  const dispatch = useDispatch();
  const location = useLocation();

  const tagsFilters = useSelector(selectTagsFilters);
  const globalFilters = useSelector(selectGlobalFilters);
  const currentPage = useSelector(selectCurrentTagsPage);

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const parsedFiltersForTable: IStatisticsQueryString = useMemo(
    () => ({
      limit: 10,
      offset: currentPage * 10,
      publisher_id: globalFilters.publisher_id,
      used: tagsFilters.used,
      traffic_type: tagsFilters.traffic_type,
    }),
    [tagsFilters, currentPage, globalFilters]
  );

  const handleTrafficSourceChange = (trafficSource: Traffic) => {
    dispatch(tagsFiltersActions.changeTrafficSource(trafficSource));
  };

  const handleUsedChange = (used: UsedValue) => {
    dispatch(tagsFiltersActions.changeUsed(used));
  };

  const debouncedTableStatistics = useDebouncedCallback((filters: IStatisticsQueryString) => {
    dispatch(tagsActions.getTags(filters));
  }, 300);

  useEffect(() => {
    if (!isInitialized) return;

    debouncedTableStatistics(parsedFiltersForTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedFiltersForTable]);

  useEffect(() => {
    setIsInitialized(true);

    dispatch(tagsActions.getTags(parsedFiltersForTable));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {location?.pathname === '/settings/tags' && <Typography variant="h4">Tags</Typography>}
      <Box sx={styles.filtersContainer}>
        <BaseFilters
          trafficSource={tagsFilters.traffic_type}
          used={tagsFilters.used}
          handleTrafficSourceChange={handleTrafficSourceChange}
          handleUsedChange={handleUsedChange}
          show={['trafficSource', 'used']}
        />
      </Box>
      <TagsTable />
    </>
  );
}
