import { ReactElement, useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { Styles } from 'common/types';
import { Field } from 'common/ui/field';
import toast from 'react-hot-toast';

const styles: Styles = {
  fieldContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  field: { width: '70%' },
  button: { maxWidth: 100, width: '100%', marginLeft: 1, ':first-child': { marginLeft: 0 } },
  buttons: { marginTop: 2, display: 'flex', justifyContent: 'flex-end' },
};

interface Props {
  defaultTag: string;
  handleClose: () => void;
}

export function DefaultTagContent({ defaultTag, handleClose }: Props): ReactElement {
  const { submitForm, resetForm, errors } = useFormikContext<PublishersSettingsModule.DefaultTagForm>();

  const handleFormClose = () => {
    resetForm();
    handleClose();
  };

  useEffect(() => {
    if (errors.default_tag) toast.error(errors.default_tag);
  }, [errors.default_tag]);

  return (
    <>
      <Box sx={styles.fieldContainer}>
        <Typography>Default Tag</Typography>
        <Field
          component={TextField}
          name="default_tag"
          fullWidth
          variant="outlined"
          label="Default tag"
          placeholder={defaultTag}
          size="small"
          InputLabelProps={{ shrink: true }}
          sx={styles.field}
        />
      </Box>
      <Box sx={styles.buttons}>
        <Button variant="outlined" onClick={handleFormClose} sx={styles.button}>
          Cancel
        </Button>
        <Button variant="contained" sx={styles.button} onClick={submitForm}>
          Save
        </Button>
      </Box>
    </>
  );
}
