import { ReactElement, useEffect, useRef } from 'react';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  BarController,
  LineController,
} from 'chart.js';
import { Box, CircularProgress } from '@mui/material';
import { Styles } from 'common/types';
import { paper } from 'common/constants/colors';
import { useSelector } from 'react-redux';
import { selectTop10Pages, selectTop10PagesLoading } from 'modules/revenue-by-page/store/selectors';
import { getTop10ChartNetworkColor } from 'modules/revenue-by-page/utils';
import { carrickTips } from 'common/constants/tips';
import { TitleTooltip } from 'common/ui/title-tooltip';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  BarController,
  LineController,
  Title,
  Tooltip,
  Legend
);

const styles: Styles = {
  container: {
    backgroundColor: paper,
    borderRadius: '20px',
    width: '100%',
    padding: 2,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 400,
  },
  title: { marginBottom: 1, fontWeight: 600, marginTop: 2 },
};

export function Top10Chart(): ReactElement {
  const chartCanvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart>();

  const top10 = useSelector(selectTop10Pages);
  const loading = useSelector(selectTop10PagesLoading);

  useEffect(() => {
    if (!top10 || loading) return;

    if (chartRef.current) chartRef.current.destroy();

    const presentedNetworks = new Set<string>();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const datasets = Object.entries(top10).reduce((acc, [key, value], index) => {
      Object.entries(value).forEach(([network, amount]) => {
        if (!acc[network])
          acc[network] = {
            label: network,
            data: Object.keys(top10).map(() => null),
            backgroundColor: getTop10ChartNetworkColor(network),
            barThickness: 16,
          };

        const networkValue = parseFloat(amount);

        if (networkValue > 0) {
          acc[network].data[index] = networkValue;
        }

        if (networkValue > 0) presentedNetworks.add(network);
      });
      return acc;
    }, {} as Record<string, { label: string; data: Array<number>; backgroundColor: string; barThickness: number }>);

    const parsedDatasets = Object.entries(datasets)
      .filter(([key]) => presentedNetworks.has(key))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([key, value]) => value);

    const money = new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' });

    const data = {
      labels: Object.keys(top10).map(label =>
        label
          .split('-')
          .map(labelWord => {
            const chars = labelWord.split('');
            chars[0] = chars[0].toUpperCase();
            return chars.join('');
          })
          .join(' ')
      ),
      datasets: parsedDatasets,
    };

    chartRef.current = new Chart(chartCanvasRef.current, {
      type: 'bar',
      data,
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: item => {
                return money.format(Number(item.raw));
              },
            },
          },
        },
        indexAxis: 'y',
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            ticks: {
              callback: (_, index) =>
                data.labels[index]?.length > 50 ? `${data.labels[index].slice(0, 50)}...` : data.labels[index],
            },
          },
        },
      },
    });

    // eslint-disable-next-line consistent-return
    return () => chartRef.current.destroy();
  }, [top10, loading]);

  return (
    <>
      <TitleTooltip
        title="Top 10 Pages"
        tooltip={carrickTips.trackingOverview.revenueByPage.topTenPages}
        typographyProps={{ sx: styles.title }}
      />
      <Box sx={styles.container}>
        {loading ? <CircularProgress color="primary" /> : <canvas ref={chartCanvasRef} />}
      </Box>
    </>
  );
}
