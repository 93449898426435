import { TableRow, TableCell, TextField, IconButton, Box, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';
import { Styles } from 'common/types';
import { useDispatch, useSelector } from 'react-redux';
import { asinsConversionsBoostActions } from 'modules/asins-conversions-boost/store/actions';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { TableRowActions } from 'common/ui/table-row-actions';
import { asinsConversionsBoostSelectors } from 'modules/asins-conversions-boost/store/selectors';

const getStyles = (): Styles => ({
  iconContainer: {
    height: 30,
    width: 30,
  },
  icon: {
    height: 20,
    width: 20,
  },
  boostField: {
    width: 75,
  },
  boostContainer: {
    width: 140,
    height: 35,
  },
  boostValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    width: '50%',
  },
});

interface Props {
  conversionBoost: AsinsConversionsBoostModule.ConversionBoost;
}

export function ConversionsBoostRow({ conversionBoost }: Props): ReactElement {
  const dispatch = useDispatch();
  const styles = getStyles();

  const filters = useSelector(asinsConversionsBoostSelectors.selectFilters);

  const [isValid, setIsValid] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [boost, setBoost] = useState(conversionBoost.boost);
  const [isHovered, setIsHovered] = useState(false);

  const onSuccess = () => {
    dispatch(asinsConversionsBoostActions.conversionsBoostActions.getConversionsBoost(filters));
  };

  const handleDelete = () => {
    dispatch(asinsConversionsBoostActions.conversionsBoostActions.deleteConversionBoost(conversionBoost.id, onSuccess));
  };

  const handleBoostChange = (value: string) => {
    setBoost(value);
    const isNumeric = /^[0-9]*\.?[0-9]+$/.test(value);
    setIsValid(isNumeric);
  };

  const handleEnterEditMode = () => {
    setIsEditMode(true);
    setIsHovered(false);
  };

  const handleSave = () => {
    if (isValid) {
      dispatch(
        asinsConversionsBoostActions.conversionsBoostActions.editConversionBoost(
          { id: conversionBoost.id, boost },
          onSuccess
        )
      );
      setIsEditMode(false);
    }
  };

  const handleCancelEdit = () => {
    setBoost(conversionBoost.boost);
    setIsEditMode(false);
  };

  return (
    <TableRow>
      <TableCell>{conversionBoost.asin}</TableCell>
      <TableCell>{conversionBoost.brand_name || 'N/A'}</TableCell>
      <TableCell>{conversionBoost.publisher_name || 'N/A'}</TableCell>
      <TableCell sx={styles.boostContainer}>
        {isEditMode ? (
          <>
            <TextField
              sx={styles.boostField}
              error={!isValid}
              helperText={isValid ? '' : 'Please provide correct value'}
              variant="standard"
              type="text"
              value={boost}
              onChange={e => handleBoostChange(e.target.value)}
              InputProps={{
                sx: { width: '100%' },
              }}
            />
            <IconButton onClick={handleSave} disabled={!isValid} sx={styles.iconContainer}>
              <CheckIcon sx={styles.icon} />
            </IconButton>
            <IconButton onClick={handleCancelEdit} sx={styles.iconContainer}>
              <ClearIcon sx={styles.icon} />
            </IconButton>
          </>
        ) : (
          <Box sx={styles.boostValue} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <Typography>{boost}</Typography>
            {isHovered && (
              <IconButton onClick={handleEnterEditMode} sx={styles.iconContainer}>
                <EditIcon sx={styles.icon} />
              </IconButton>
            )}
          </Box>
        )}
      </TableCell>
      <TableCell align="center">
        <TableRowActions onDelete={handleDelete} show={['delete']} />
      </TableCell>
    </TableRow>
  );
}
