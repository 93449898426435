import { ReactElement } from 'react';
import { useFormikContext } from 'formik';
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { getStyles as getSettingStyles } from 'modules/user-settings/components/user-settings-content/user-settings-content';
import { Field } from 'common/ui/field';
import { Styles } from 'common/types';
import { background } from 'common/constants/colors';
import ReactCountryFlag from 'react-country-flag';
import { amazonAffiliateRegionOptions, CountryCode } from 'modules/settings/constants';
import { ReportUploadContent } from './report-upload-content';

const getStyles = (): Styles => ({
  amazonField: { width: '49%' },
  skimlinkField: { width: '32%' },
  button: { maxWidth: 100, width: '100%', marginLeft: 1, ':first-child': { marginLeft: 0 }, borderRadius: '10px' },
  buttons: { marginTop: 2, display: 'flex', justifyContent: 'flex-end' },
  uploadContainer: { display: 'flex', flexDirection: 'column', alignItems: 'center', height: '55.98px' },
  amazonInput: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '580px',
    boxSizing: 'border-box',
  },
  logContainer: { marginTop: 3, width: '60%', padding: 2, backgroundColor: background, borderRadius: '10px' },
  fieldContainer: { display: 'flex', justifyContent: 'space-between', width: 600 },
  selectField: { width: '32%' },
  associatesLinkField: { width: '66%' },
});

export function AffiliateSettingsContent(): ReactElement {
  const styles = getStyles();
  const settingsStyles = getSettingStyles();
  const formik = useFormikContext<PublishersSettingsModule.AffiliateSettings>();

  const handleRegionChange = (value: string) => {
    formik.setFieldValue('amazonAssociatesRegion', value);

    const selectedRegion = value as CountryCode;
    const url = amazonAffiliateRegionOptions[selectedRegion].value;
    formik.setFieldValue('amazonAssociatesUrl', url);
  };

  return (
    <>
      <Box sx={settingsStyles.fieldWrapper}>
        <Typography sx={settingsStyles.titleField}>Amazon</Typography>
        <Box sx={styles.amazonInput}>
          <Box sx={styles.fieldContainer}>
            <Field
              component={TextField}
              fullWidth
              name="amazonLogin"
              variant="outlined"
              label="Login"
              InputLabelProps={{ shrink: true }}
              sx={styles.amazonField}
            />
            <Field
              component={TextField}
              fullWidth
              name="amazonPassword"
              variant="outlined"
              label="Password"
              type="password"
              InputLabelProps={{ shrink: true }}
              sx={styles.amazonField}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={settingsStyles.fieldWrapper}>
        <Typography sx={settingsStyles.titleField}>Amazon Associates</Typography>
        <Box sx={styles.amazonInput}>
          <Box sx={styles.fieldContainer}>
            <TextField
              name="amazonAssociatesRegion"
              label="Region"
              select
              fullWidth
              sx={styles.selectField}
              value={formik.values.amazonAssociatesRegion}
              onChange={e => handleRegionChange(e.target.value)}
            >
              {Object.entries(amazonAffiliateRegionOptions).map(([code, option]) => (
                <MenuItem key={code} value={code as CountryCode}>
                  <ReactCountryFlag
                    countryCode={code as CountryCode}
                    svg
                    style={{ marginRight: 10, width: '2em', height: '1em' }}
                    aria-label={option.label}
                  />
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              name="amazonAssociatesUrl"
              label="Associates link"
              InputLabelProps={{ shrink: true }}
              sx={styles.associatesLinkField}
              value={
                formik.values.amazonAssociatesRegion
                  ? amazonAffiliateRegionOptions[formik.values.amazonAssociatesRegion as CountryCode].value
                  : ''
              }
              disabled
            />
          </Box>
        </Box>
      </Box>
      <Box sx={settingsStyles.fieldWrapper}>
        <Typography sx={settingsStyles.titleField}>Skimlink</Typography>
        <Box sx={styles.amazonInput}>
          <Box sx={styles.fieldContainer}>
            <Field
              component={TextField}
              fullWidth
              name="skimlinksPublisherId"
              variant="outlined"
              label="Publisher ID"
              InputLabelProps={{ shrink: true }}
              sx={styles.skimlinkField}
            />
            <Field
              component={TextField}
              fullWidth
              name="skimlinksClientId"
              variant="outlined"
              label="Client ID"
              InputLabelProps={{ shrink: true }}
              sx={styles.skimlinkField}
            />
            <Field
              component={TextField}
              fullWidth
              name="skimlinksClientSecret"
              variant="outlined"
              label="Client secret"
              type="password"
              InputLabelProps={{ shrink: true }}
              sx={styles.skimlinkField}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={settingsStyles.fieldWrapper}>
        <Typography sx={settingsStyles.titleField}>Impact</Typography>
        <Box sx={styles.amazonInput}>
          <Box sx={styles.fieldContainer}>
            <Field
              component={TextField}
              fullWidth
              name="impactAccountSid"
              variant="outlined"
              label="Account SID"
              InputLabelProps={{ shrink: true }}
              sx={styles.amazonField}
            />
            <Field
              component={TextField}
              fullWidth
              name="impactAccountToken"
              variant="outlined"
              label="Account token"
              type="password"
              InputLabelProps={{ shrink: true }}
              sx={styles.amazonField}
            />
          </Box>
        </Box>
      </Box>
      <ReportUploadContent />
      <Box sx={styles.buttons}>
        <Button color="primary" onClick={formik.submitForm} sx={styles.button} variant="contained">
          Save
        </Button>
      </Box>
    </>
  );
}
