import { Click, DateByValue, Traffic } from 'common/ui/base-filters/types';
import { UsedValue } from 'common/ui/base-filters/used-picker';
import { Moment } from 'moment';
import { ContentType } from 'modules/content-overview/components/pages/page-performance/content-type-filter/content-type-filter.types';
import { filtersActionTypes } from './action-types';

export const getFiltersActions = (actionType: Record<string, string>) => ({
  changeRange: (from: Moment, to: Moment) => ({
    type: actionType.CHANGE_RANGE,
    payload: { from, to },
  }),
  changePublisher: (publisherIds: number | null | string) => ({
    type: actionType.CHANGE_PUBLISHER,
    payload: publisherIds,
  }),
  changeSearch: (search: string) => ({
    type: actionType.CHANGE_SEARCH,
    payload: search,
  }),
  changeSearchType: (type: string) => ({
    type: actionType.CHANGE_SEARCH_TYPE,
    payload: type,
  }),
  changeTrafficSource: (trafficSource: Traffic) => ({
    type: actionType.CHANGE_IS_PAID,
    payload: trafficSource,
  }),
  changeUsed: (used: UsedValue) => ({
    type: actionType.CHANGE_USED,
    payload: used,
  }),
  changeAffiliateNetworkType: (id: number | string) => ({
    type: actionType.CHANGE_AFFILIATE_NETWORK_TYPE,
    payload: id,
  }),
  changeDateBy: (dateBy: DateByValue) => ({
    type: actionType.CHANGE_DATE_BY,
    payload: dateBy,
  }),
  changeClick: (click: Click) => ({
    type: actionType.CHANGE_TRANSACTION_CLICK,
    payload: click,
  }),
  changeInStock: (inStock: number) => ({
    type: actionType.CHANGE_IN_STOCK,
    payload: inStock,
  }),
  changeOOSRange: (oosGT: { from: string; to: string }) => ({
    type: actionType.CHANGE_OOS_RANGE,
    payload: oosGT,
  }),
  changeOOSStatus: (status: number) => ({
    type: actionType.CHANGE_OOS_STATUS,
    payload: status,
  }),
  changeExportLimit: (limit: number) => ({
    type: actionType.CHANGE_EXPORT_LIMIT,
    payload: limit,
  }),
  resetFilters: () => ({
    type: actionType.RESET_FILTERS,
  }),
  changePublisherGroup: (group: string) => ({
    type: actionType.CHANGE_PUBLISHER_GROUP,
    payload: group,
  }),
  changeContentType: (type: ContentType) => ({
    type: actionType.CHANGE_CONTENT_TYPE,
    payload: type,
  }),
  // TODO tyoe using ContentOverviewModule.Condition
  changeSaturationOperator: (operator: string) => ({
    type: actionType.CHANGE_SATURATION_OPERATOR,
    payload: operator,
  }),
  changeSaturationValue: (value: number) => ({
    type: actionType.CHANGE_SATURATION_VALUE,
    payload: value,
  }),
});

export const filtersActions = getFiltersActions(filtersActionTypes);
