import { Box } from '@mui/material';
import { Styles } from 'common/types';
import {
  selectAllStatistics,
  selectAllStatisticsLoading,
  selectProductsStatistics,
  selectProductsStatisticsLoading,
} from 'modules/overview-v2/store/selectors';
import { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { carrickTips } from 'common/constants/tips';
import TourIcon from '@mui/icons-material/Tour';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArticleIcon from '@mui/icons-material/Article';
import PercentIcon from '@mui/icons-material/Percent';
import InventoryIcon from '@mui/icons-material/Inventory';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { usePermission, usePublisher } from 'common/hooks';
import { CardContainer } from 'common/ui/card-container';
import { PERMISSIONS } from 'common/constants/permissions';
import { WarningProps } from 'common/ui/warning/types';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 3, marginTop: 3 },
  cardsContainer: { display: 'flex', gap: 3 },
};

export function CardsContainer(): ReactElement {
  const nav = useNavigate();

  const allStatsLoading = useSelector(selectAllStatisticsLoading);
  const allStats = useSelector(selectAllStatistics);
  const productStats = useSelector(selectProductsStatistics);
  const productStatsLoading = useSelector(selectProductsStatisticsLoading);

  const publisher = usePublisher();

  const isAdmin = usePermission(PERMISSIONS.GET_PUBLISHERS);

  const saturation = useMemo(
    () => ((Number(allStats?.asins) / Number(productStats?.articles_asins)) * 100).toFixed(1),
    [allStats, productStats]
  );

  const saturationWarning = useMemo<WarningProps>(() => {
    if (Number(saturation) < 10) return { tooltip: 'Saturation less than 10%', severity: 'severe' };
    if (Number(saturation) < 50) return { tooltip: 'Saturation less than 50%', severity: 'mild' };
    return {};
  }, [saturation]);

  const money = new Intl.NumberFormat('en', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 });
  const number = new Intl.NumberFormat();

  return (
    <Box sx={styles.container}>
      <Box sx={styles.cardsContainer}>
        <CardContainer
          title="Revenue (GMV)"
          value={money.format(allStats?.total_revenue)}
          loading={allStatsLoading}
          icon={ShoppingCartIcon}
          tip={carrickTips.overview.cards.gmv}
          shouldEmbed={!allStats?.is_any_statistics_exist}
          embedText="No data yet. Please link your Amazon account or upload to view your revenue."
          embedButtonText="Connect"
          embedCallback={() => nav('/publisher/affiliate')}
        />
        <CardContainer
          title="Total Earnings"
          value={money.format(allStats?.ad_fees ?? 0)}
          loading={allStatsLoading}
          icon={AdUnitsIcon}
          tip={carrickTips.overview.cards.adFees}
          shouldEmbed={!allStats?.is_any_statistics_exist}
          embedText="No data yet. Please link your Amazon account or upload to view fee earnings."
          embedButtonText="Connect"
          embedCallback={() => nav('/publisher/affiliate')}
        />
        <CardContainer
          title="Visits"
          value={number.format(allStats?.visits ?? 0)}
          loading={allStatsLoading}
          icon={TourIcon}
          tip={carrickTips.overview.cards.viewsOnPage}
          shouldEmbed={!allStats?.is_any_statistics_exist}
          embedText="No data yet. Please embed the JS to start collecting Visits."
          embedCallback={() => nav('/publisher/embed')}
        />
        <CardContainer
          title="Retailer Clicks"
          value={number.format(allStats?.clicks ?? 0)}
          loading={allStatsLoading}
          icon={HighlightAltIcon}
          tip={carrickTips.overview.cards.clicks}
          shouldEmbed={!allStats?.is_any_statistics_exist}
          embedText="No data yet. Please embed the JS to start collecting Clicks."
          embedCallback={() => nav('/publisher/embed')}
        />
      </Box>
      <Box sx={styles.cardsContainer}>
        <CardContainer
          title="Tracked Articles"
          value={number.format(productStats?.articles)}
          loading={productStatsLoading}
          icon={ArticleIcon}
          tip={carrickTips.contentOverview.productPlacement.articles}
        />
        {publisher?.gainz_active && (
          <CardContainer
            title="Gainz ASINs"
            value={number.format(allStats?.asins ?? 0)}
            loading={allStatsLoading}
            icon={InventoryIcon}
            tip={carrickTips.overview.cards.asins}
          />
        )}
        <CardContainer
          title="ASINs in Articles"
          value={number.format(productStats?.articles_asins)}
          loading={productStatsLoading}
          icon={QrCodeIcon}
          tip={carrickTips.contentOverview.productPlacement.asinsInArticles}
        />
        {isAdmin && publisher?.gainz_active && (
          <CardContainer
            title="Gainz Saturation"
            value={Number(saturation) ? `${saturation}%` : '-'}
            loading={allStatsLoading}
            icon={PercentIcon}
            tip={carrickTips.overview.cards.saturation}
            warning={saturationWarning.severity ? saturationWarning : undefined}
          />
        )}
        <CardContainer
          title="OOS ASINs"
          value={number.format(productStats?.oos_asins)}
          loading={productStatsLoading}
          icon={InfoIcon}
          tip={carrickTips.contentOverview.productPlacement.oosAsins}
        />
      </Box>
    </Box>
  );
}
