import { ReactElement } from 'react';
import PercentIcon from '@mui/icons-material/Percent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { primary, primaryLight, text } from 'common/constants/colors';
import { generateHeader } from 'modules/stats/components/table-container/stats-table-container';

export type StatsRow = {
  keyword: string;
  status: ReactElement;
  impressions: string;
  clicks: string;
  cost: string;
  averageCpc: string;
  addToCards: string;
  conversion: string;
  revenue: string;
  roas: string;
  acos: string;
};

export type StatsDataRow = {
  id: number;
  keyword: string;
  status: string;
  impressions: string;
  clicks: string;
  cost: string;
  averageCpc: string;
  addToCards: string;
  conversion: string;
  revenue: string;
  roas: string;
  acos: string;
};

export const data: StatsDataRow[] = [
  {
    id: 1,
    keyword: 'Amazon best deals...',
    status: 'Enabled',
    impressions: '10,5K',
    clicks: '10,5K',
    cost: '$21,6K',
    averageCpc: '$21,6K',
    addToCards: '1,6K',
    conversion: '1,6K',
    revenue: '$299',
    roas: '235%',
    acos: '29%',
  },
  {
    id: 2,
    keyword: 'Amazon best deals...',
    status: 'Enabled',
    impressions: '10,5K',
    clicks: '10,5K',
    cost: '$21,6K',
    averageCpc: '$21,6K',
    addToCards: '1,6K',
    conversion: '1,6K',
    revenue: '$299',
    roas: '235%',
    acos: '29%',
  },
  {
    id: 3,
    keyword: 'Amazon best deals...',
    status: 'Enabled',
    impressions: '10,5K',
    clicks: '10,5K',
    cost: '$21,6K',
    averageCpc: '$21,6K',
    addToCards: '1,6K',
    conversion: '1,6K',
    revenue: '$299',
    roas: '235%',
    acos: '29%',
  },
  {
    id: 4,
    keyword: 'Amazon best deals...',
    status: 'Enabled',
    impressions: '10,5K',
    clicks: '10,5K',
    cost: '$21,6K',
    averageCpc: '$21,6K',
    addToCards: '1,6K',
    conversion: '1,6K',
    revenue: '$299',
    roas: '235%',
    acos: '29%',
  },
  {
    id: 5,
    keyword: 'Amazon best deals...',
    status: 'Enabled',
    impressions: '10,5K',
    clicks: '10,5K',
    cost: '$21,6K',
    averageCpc: '$21,6K',
    addToCards: '1,6K',
    conversion: '1,6K',
    revenue: '$299',
    roas: '235%',
    acos: '29%',
  },
  {
    id: 6,
    keyword: 'Amazon best deals...',
    status: 'Enabled',
    impressions: '10,5K',
    clicks: '10,5K',
    cost: '$21,6K',
    averageCpc: '$21,6K',
    addToCards: '1,6K',
    conversion: '1,6K',
    revenue: '$299',
    roas: '235%',
    acos: '29%',
  },
  {
    id: 7,
    keyword: 'Amazon best deals...',
    status: 'Enabled',
    impressions: '10,5K',
    clicks: '10,5K',
    cost: '$21,6K',
    averageCpc: '$21,6K',
    addToCards: '1,6K',
    conversion: '1,6K',
    revenue: '$299',
    roas: '235%',
    acos: '29%',
  },
  {
    id: 8,
    keyword: 'Amazon best deals...',
    status: 'Enabled',
    impressions: '10,5K',
    clicks: '10,5K',
    cost: '$21,6K',
    averageCpc: '$21,6K',
    addToCards: '1,6K',
    conversion: '1,6K',
    revenue: '$299',
    roas: '235%',
    acos: '29%',
  },
  {
    id: 9,
    keyword: 'Amazon best deals...',
    status: 'Enabled',
    impressions: '10,5K',
    clicks: '10,5K',
    cost: '$21,6K',
    averageCpc: '$21,6K',
    addToCards: '1,6K',
    conversion: '1,6K',
    revenue: '$299',
    roas: '235%',
    acos: '29%',
  },
  {
    id: 10,
    keyword: 'Amazon best deals...',
    status: 'Enabled',
    impressions: '10,5K',
    clicks: '10,5K',
    cost: '$21,6K',
    averageCpc: '$21,6K',
    addToCards: '1,6K',
    conversion: '1,6K',
    revenue: '$299',
    roas: '235%',
    acos: '29%',
  },
];

export const scheme = {
  baseColor: primary,
  justOrane: '#F6AA47',
  yellow: '#FFE545',
  green: '#62D294',
  baseDark: text,
  baseDarkGrey: '#214254',
  baseGray: '#F8F8F9',
  lineTwo: primaryLight,
  bg2: '#F1F5F6',
  bgGrey: '#F1F5F6',
  filter: '#C2DEEB',
  cardIconFill: '#F9FCFD',
  tableHeader: '#6F8490',
};

export const cards = [
  {
    grid: {
      item: true,
      xs: 2,
    },
    card: {
      Icon: AccountBalanceWalletIcon,
      title: 'Impressions',
      value: '109.5,K',
      loading: false,
    },
  },
  {
    grid: {
      item: true,
      xs: 2,
    },
    card: {
      Icon: AdUnitsIcon,
      title: 'Clicks',
      value: '109,5K',
      loading: false,
    },
  },
  {
    grid: {
      item: true,
      xs: 2,
    },
    card: {
      Icon: AttachMoneyIcon,
      title: 'Cost',
      value: '109,5K',
      loading: false,
    },
  },
  {
    grid: {
      item: true,
      xs: 2,
    },
    card: {
      Icon: PercentIcon,
      title: 'Conversions',
      value: '109,5K',
      loading: false,
    },
  },
  {
    grid: {
      item: true,
      xs: 2,
    },
    card: {
      Icon: DataSaverOffIcon,
      title: 'Revenue',
      value: '109,5K',
      loading: false,
    },
  },
];

export const columnHeaders: ReactElement[] = [
  generateHeader('Keyword text', false),
  generateHeader('Status', false),
  generateHeader('Impressions', false),
  generateHeader('Clicks', false),
  generateHeader('Cost', false),
  generateHeader('Average CPC', true),
  generateHeader('Add to cards', false),
  generateHeader('Conversion', false),
  generateHeader('Revenue', false),
  generateHeader('ROAS', true),
  generateHeader('ACOS', true),
];

export enum STATUS {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}
