import { ReactElement, useEffect } from 'react';
import { Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Styles } from 'common/types';
import { getStyles as getSettingStyles } from 'modules/user-settings/components/user-settings-content/user-settings-content';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalFilters } from 'common/store/selectors';
import { CustomRateModal } from './custom-rate';
import { affiliateRateCardsActions } from './store/actions/actions';
import { affiliateRateCardSelectors } from './store/selectors';

const settingsStyles = getSettingStyles();

const styles: Styles = {
  rateCardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    padding: '20px 0',
    marginTop: 5,
    boxSizing: 'border-box',
    borderBottom: '1px solid #C2DEEB40',
  },
  container: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '580px' },
  loadingContainer: { display: 'flex', alignItems: 'center', height: '56px' },
  textFieldsContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 550 },
  textFieldsBox: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  btnUpdate: { width: 100, height: 36 },
  btnUpdateLoading: { width: 100, height: 36, cursor: 'progress' },
  abDefault: { marginLeft: 3 },
  btnSelect: { width: 200, marginLeft: 2 },
  btnAdd: { marginTop: 2 },
};

export function AffiliateRateCard(): ReactElement {
  const baseFilters = useSelector(selectGlobalFilters);
  const defaultRate = useSelector(affiliateRateCardSelectors.defaultRate);
  const filters = useSelector(affiliateRateCardSelectors.filters);

  const dispatch = useDispatch();

  const onClose = () => dispatch(affiliateRateCardsActions.filters.setOpen(false));

  const handleUpdate = () => {
    dispatch(
      affiliateRateCardsActions.rates.defualt.patchSRDefaultRate({
        publisher_id: baseFilters.publisher_id.toString(),
        sr_default_rate: defaultRate.srDefault,
      })
    );
    dispatch(
      affiliateRateCardsActions.rates.defualt.patchABDefaultRate({
        publisher_id: baseFilters.publisher_id.toString(),
        ab_default_rate: defaultRate.abDefault,
      })
    );
  };

  const debouncedSearch = useDebouncedCallback(() => {
    dispatch(
      affiliateRateCardsActions.asins.getRecommendedAsins({
        publisher_id: baseFilters.publisher_id,
        search: filters.search,
        limit: filters.limit,
        offset: filters.offset * filters.limit,
      })
    );
  }, 300);

  useEffect(() => {
    dispatch(affiliateRateCardsActions.rates.defualt.getSRDefaultRate({ publisher_id: baseFilters.publisher_id }));
    dispatch(affiliateRateCardsActions.rates.defualt.getABDefaultRate({ publisher_id: baseFilters.publisher_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultRate.srData)
      dispatch(affiliateRateCardsActions.rates.defualt.getSRDefaultRate({ publisher_id: baseFilters.publisher_id }));
    if (defaultRate.abData)
      dispatch(affiliateRateCardsActions.rates.defualt.getABDefaultRate({ publisher_id: baseFilters.publisher_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRate.srData, defaultRate.abData]);

  useEffect(() => {
    debouncedSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.search, filters.offset, filters.limit]);

  useEffect(() => {
    if (!filters.open)
      dispatch(
        affiliateRateCardsActions.asins.getRecommendedAsins({
          publisher_id: baseFilters.publisher_id,
          search: filters.search,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.open]);

  return (
    <Box sx={styles.rateCardContainer}>
      <Typography sx={settingsStyles.titleField}>Affiliate Gainz Rate Card</Typography>
      <Box sx={styles.container}>
        <Box sx={styles.textFieldsContainer}>
          {(defaultRate.loading && (
            <Box sx={styles.loadingContainer}>
              <CircularProgress />
            </Box>
          )) || (
            <Box sx={styles.textFieldsBox}>
              <TextField
                variant="outlined"
                placeholder="Set new rate"
                label={`SR rate: ${Number(defaultRate.srDefault).toFixed()}%`}
                onChange={e => dispatch(affiliateRateCardsActions.rates.defualt.setSRDefaultRate(e.target.value))}
              />
              <TextField
                sx={styles.abDefault}
                variant="outlined"
                placeholder="Set new rate"
                label={`Attr rate: ${Number(defaultRate.abDefault).toFixed()}%`}
                onChange={e => dispatch(affiliateRateCardsActions.rates.defualt.setABDefaultRate(e.target.value))}
              />
            </Box>
          )}
          <Button
            sx={defaultRate.loading ? styles.btnUpdateLoading : styles.btnUpdate}
            variant="contained"
            onClick={() => handleUpdate()}
          >
            Update
          </Button>
        </Box>
        <Box sx={styles.btnAdd}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => dispatch(affiliateRateCardsActions.filters.setOpen(true))}
          >
            Add Custom Rate
          </Button>
        </Box>
      </Box>
      <CustomRateModal open={filters.open} onClose={onClose} publisherId={baseFilters.publisher_id} />
    </Box>
  );
}
