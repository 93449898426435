import { ApiService } from 'common/services';

class AffiliateRateCardService extends ApiService {
  getRecommendedAsins = (publisher_id: string | number) => {
    return this.get('/api/settings/gainz-rate', { params: publisher_id });
  };

  patchCustomRate = (payload: AffiliateRateCardModule.PatchCustomRateParams) => {
    return this.patch('/api/settings/gainz-rate', payload);
  };

  getSRDefaultRate = (publisher_id: string | number) => {
    return this.get('/api/settings/sr-gainz-rate', { params: publisher_id });
  };

  getABDefaultRate = (publisher_id: string | number) => {
    return this.get('/api/settings/ab-gainz-rate', { params: publisher_id });
  };

  patchSRDefaultRate = (payload: AffiliateRateCardModule.PatchSRDefaultRateParams) => {
    return this.patch('/api/settings/sr-gainz-rate', payload);
  };

  patchABDefaultRate = (payload: AffiliateRateCardModule.PatchABDefaultRateParams) => {
    return this.patch('/api/settings/ab-gainz-rate', payload);
  };
}

export const affiliateRateCardService = new AffiliateRateCardService(process.env.REACT_APP_API_URL);
