const REVENUE_MODULE = '[affiliate-gainz:gainz-revenue:revenue]';
const FILTERS_MODULE = '[affiliate-gainz:gainz-revenue:filters]';
const EXPORT_MODULE = '[affiliate-gainz:gainz-revenue:export]';

const GET_REVENUE = `${REVENUE_MODULE} GET_REVENUE`;
const GET_REVENUE_SUCCESS = `${REVENUE_MODULE} GET_REVENUE_SUCCESS`;
const GET_REVENUE_ERROR = `${REVENUE_MODULE} GET_REVENUE_ERROR`;

const SET_SEARCH = `${FILTERS_MODULE} SET_SEARCH`;
const SET_FROM = `${FILTERS_MODULE} SET_FROM`;
const SET_TO = `${FILTERS_MODULE} SET_TO`;
const SET_LIMIT = `${FILTERS_MODULE} SET_LIMIT`;
const SET_OFFSET = `${FILTERS_MODULE} SET_OFFSET`;

const GET_EXPORT = `${EXPORT_MODULE} GET_EXPORT`;
const GET_EXPORT_SUCCESS = `${EXPORT_MODULE} GET_EXPORT_SUCCESS`;
const GET_EXPORT_ERROR = `${EXPORT_MODULE} GET_EXPORT_ERROR`;

export const gainzRevenueActionTypes = {
  REVENUE: {
    GET_REVENUE,
    GET_REVENUE_SUCCESS,
    GET_REVENUE_ERROR,
  },
  FILTERS: {
    SET_SEARCH,
    SET_FROM,
    SET_TO,
    SET_LIMIT,
    SET_OFFSET,
  },
  EXPORT: {
    GET_EXPORT,
    GET_EXPORT_SUCCESS,
    GET_EXPORT_ERROR,
  },
};
