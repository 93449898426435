import { Box, Tab, Tabs } from '@mui/material';
import { ReactElement } from 'react';

interface PGProps {
  group: string;
  setGroup: (value: string) => void;
  tabs: string[];
}

export function PublisherTabs({ group, setGroup, tabs }: PGProps): ReactElement {
  return (
    <Box>
      <Tabs centered value={group} onChange={(event, value) => setGroup(value)}>
        {tabs.map(tab => (
          <Tab key={tab} value={tab} label={tab} />
        ))}
      </Tabs>
    </Box>
  );
}
