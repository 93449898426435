import { Action } from 'common/interfaces/root';
import { affiliateRateCardActionTypes as TYPES } from '../actions/types';

const initState: AffiliateRateCardModule.CustomFormStore = {
  open: false,
  search: '',
  all: false,
  selected: [],
  rate: '',
  offset: 0,
  limit: 10,
  filterError: '',
};

export default (state = initState, { type, payload }: Action) => {
  switch (type) {
    case TYPES.FILTERS.SET_OPEN:
      return { ...state, open: payload };
    case TYPES.FILTERS.SET_SEARCH:
      return { ...state, search: payload };
    case TYPES.FILTERS.SET_ALL:
      return { ...state, all: payload };
    case TYPES.FILTERS.SET_SELECTED:
      return { ...state, selected: payload };
    case TYPES.FILTERS.SET_RATE:
      return { ...state, rate: payload };
    case TYPES.FILTERS.SET_LIMIT:
      return { ...state, limit: payload };
    case TYPES.FILTERS.SET_OFFSET:
      return { ...state, offset: payload };
    case TYPES.FILTERS.SET_FILTER_ERROR:
      return { ...state, filterError: payload };
    case TYPES.FILTERS.RESET:
      return { ...state, all: false, selected: [], rate: '', offset: 0, limit: 10, filterError: '', search: '' };

    default:
      return state;
  }
};
