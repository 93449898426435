const RECOMMENDED_ASINS_MODULE = '[affiliate-rate-card:asins]';
const FILTERS_MODULE = '[affiliate-rate-card:filters]';
const CUSTOM_RATE_MODULE = '[affiliate-rate-card:custom-rate]';
const DEFAULT_RATE_MODULE = '[affiliate-rate-card:default-rate]';

const GET_RECOMMENDED_ASINS = `${RECOMMENDED_ASINS_MODULE} GET_RECOMMENDED_ASINS`;
const GET_RECOMMENDED_ASINS_SUCCESS = `${RECOMMENDED_ASINS_MODULE} GET_RECOMMENDED_ASINS_SUCCESS`;
const GET_RECOMMENDED_ASINS_ERROR = `${RECOMMENDED_ASINS_MODULE} GET_RECOMMENDED_ASINS_ERROR`;

const SET_OPEN = `${FILTERS_MODULE} SET_OPEN`;
const SET_SEARCH = `${FILTERS_MODULE} SET_SEARCH`;
const SET_ALL = `${FILTERS_MODULE} SET_ALL`;
const SET_SELECTED = `${FILTERS_MODULE} SET_SELECTED`;
const SET_RATE = `${FILTERS_MODULE} SET_RATE`;
const SET_CUSTOM_RATE = `${FILTERS_MODULE} SET_CUSTOM_RATE`;
const SET_LIMIT = `${FILTERS_MODULE} SET_LIMIT`;
const SET_OFFSET = `${FILTERS_MODULE} SET_OFFSET`;
const SET_FILTER_ERROR = `${FILTERS_MODULE} SET_FILTER_ERROR`;
const RESET = `${FILTERS_MODULE} RESET`;

const PATCH_CUSTOM_RATE = `${CUSTOM_RATE_MODULE} PATCH_CUSTOM_RATE`;
const PATCH_CUSTOM_RATE_SUCCESS = `${CUSTOM_RATE_MODULE} PATCH_CUSTOM_RATE_SUCCESS`;
const PATCH_CUSTOM_RATE_ERROR = `${CUSTOM_RATE_MODULE} PATCH_CUSTOM_RATE_ERROR`;

const GET_SR_DEFAULT = `${DEFAULT_RATE_MODULE} GET_SR_DEFAULT`;
const GET_SR_DEFAULT_SUCCESS = `${DEFAULT_RATE_MODULE} GET_SR_DEFAULT_SUCCESS`;
const GET_SR_DEFAULT_ERROR = `${DEFAULT_RATE_MODULE} GET_SR_DEFAULT_ERROR`;

const GET_AB_DEFAULT = `${DEFAULT_RATE_MODULE} GET_AB_DEFAULT`;
const GET_AB_DEFAULT_SUCCESS = `${DEFAULT_RATE_MODULE} GET_AB_DEFAULT_SUCCESS`;
const GET_AB_DEFAULT_ERROR = `${DEFAULT_RATE_MODULE} GET_AB_DEFAULT_ERROR`;

const SET_SR_DEFAULT = `${DEFAULT_RATE_MODULE} SET_SR_DEFAULT`;
const SET_AB_DEFAULT = `${DEFAULT_RATE_MODULE} SET_AB_DEFAULT`;

const PATCH_SR_DEFAULT = `${DEFAULT_RATE_MODULE} PATCH_SR_DEFAULT`;
const PATCH_SR_DEFAULT_SUCCESS = `${DEFAULT_RATE_MODULE} PATCH_SR_DEFAULT_SUCCESS`;
const PATCH_SR_DEFAULT_ERROR = `${DEFAULT_RATE_MODULE} PATCH_SR_DEFAULT_ERROR`;

const PATCH_AB_DEFAULT = `${DEFAULT_RATE_MODULE} PATCH_AB_DEFAULT`;
const PATCH_AB_DEFAULT_SUCCESS = `${DEFAULT_RATE_MODULE} PATCH_AB_DEFAULT_SUCCESS`;
const PATCH_AB_DEFAULT_ERROR = `${DEFAULT_RATE_MODULE} PATCH_AB_DEFAULT_ERROR`;

export const affiliateRateCardActionTypes = {
  ASINS: {
    GET_RECOMMENDED_ASINS,
    GET_RECOMMENDED_ASINS_SUCCESS,
    GET_RECOMMENDED_ASINS_ERROR,
  },
  RATES: {
    DEFAULT: {
      GET_SR_DEFAULT,
      GET_SR_DEFAULT_SUCCESS,
      GET_SR_DEFAULT_ERROR,
      GET_AB_DEFAULT,
      GET_AB_DEFAULT_SUCCESS,
      GET_AB_DEFAULT_ERROR,
      SET_SR_DEFAULT,
      SET_AB_DEFAULT,
      PATCH_SR_DEFAULT,
      PATCH_SR_DEFAULT_SUCCESS,
      PATCH_SR_DEFAULT_ERROR,
      PATCH_AB_DEFAULT,
      PATCH_AB_DEFAULT_SUCCESS,
      PATCH_AB_DEFAULT_ERROR,
    },
    CUSTOM: {
      SET_CUSTOM_RATE,
      PATCH_CUSTOM_RATE,
      PATCH_CUSTOM_RATE_SUCCESS,
      PATCH_CUSTOM_RATE_ERROR,
    },
  },
  FILTERS: {
    SET_OPEN,
    SET_SEARCH,
    SET_ALL,
    SET_SELECTED,
    SET_RATE,
    SET_LIMIT,
    SET_OFFSET,
    SET_FILTER_ERROR,
    RESET,
  },
};
