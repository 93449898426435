import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import StarIcon from '@mui/icons-material/Star';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RocketIcon from '@mui/icons-material/Rocket';
import { ReactComponent as AttributionIcon } from 'common/svg/maverickx-logomark-c.svg';
import { useDispatch } from 'react-redux';
import { asinRecommendationActions } from 'modules/asin-recommendation/store/actions';
import { usePermission } from 'common/hooks';
import { PERMISSIONS } from 'common/constants/permissions';

interface StylesProps {
  image: string;
  inStock: boolean;
}

const getStyles = ({ image, inStock }: StylesProps): Styles => ({
  stockStatus: {
    borderRadius: '10px',
    padding: '2px 10px',
    boxSizing: 'border-box',
    color: inStock ? '#62d295' : '#214254',
    backgroundColor: inStock ? '#62d29530' : '#21425430',
    fontWeight: 300,
  },
  gridItem: {
    width: '100%',
    cursor: 'pointer',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    alignItems: 'center',
    marginBottom: '5px',
    borderBottom: '1px solid #F2F7FA',
  },
  image: {
    width: 100,
    minWidth: 100,
    height: 100,
    backgroundImage: `url(${image})`,
    backgroundSize: 'contain',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  topContent: { width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
  starIcon: { color: '#FFD645', width: 18, height: 18 },
  ratingContent: { display: 'flex', alignItems: 'center', ml: '-4px', fontSize: 16 },
  costContent: { display: 'flex', alignItems: 'center', ml: '-4px', fontSize: 16, fontWeight: 600 },
  avgReviewsText: { lineHeight: 1, fontSize: 16 },
  reviewsText: { fontSize: 10, ml: 1, lineHeight: 1, color: '#6F8490' },
  affiliateRate: {
    color: '#13AB2C',
    fontSize: 16,
    fontWeight: 600,
  },
  title: { color: '#060F14', fontWeight: 600, textAlign: 'start', minHeight: '50px' },
  bottomContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '10px 0 10px 0',
    padding: '0 10px',
    boxSizing: 'border-box',
  },
  articleWrapper: {
    borderTop: '1px solid #F2F7FA',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0',
    padding: '0 20px',
    boxSizing: 'border-box',
  },
  articleTitle: {
    padding: '10px 0',
    color: '#58717F',
    fontSize: 16,
  },
  articleValue: {
    color: '#465A6680',
    fontSize: 16,
  },
  checkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
    boxSizing: 'border-box',
    borderRadius: '50%',
    backgroundColor: '#62d29536',
  },
  noCheckWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
    boxSizing: 'border-box',
    borderRadius: '50%',
    backgroundColor: '#21425411',
  },
  asinContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' },
  contentWrapper: { padding: '0 20px', boxSizing: 'border-box' },
  reviewsCount: {
    fontSize: '10px',
    marginLeft: '5px',
    fontWeight: 600,
  },
  titleContainer: { display: 'flex', justifyContent: 'space-between', pt: 1 },
  srIcon: { fontSize: 15, color: 'purple' },
  variations: { fontSize: 11, height: 16 },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  padding: '20px 0px 0px 0px',
  boxSizing: 'border-box',
}));

interface Props {
  asin?: AsinRecommendationModule.State.Asin;
}

export function RecommendItem({ asin }: Props): ReactElement {
  const dispatch = useDispatch();

  const adminView = usePermission(PERMISSIONS.GET_PUBLISHERS);

  const money = new Intl.NumberFormat('en', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });

  const handleAsinSelect = () => {
    dispatch(asinRecommendationActions.grid.setSelected(asin));
  };

  const styles = getStyles({
    image: asin?.extended_product?.image ?? asin?.extended_product?.img_medium,
    inStock: asin?.in_stock,
  });

  const shortTitle = useMemo(() => {
    return asin?.extended_product?.title?.length > 35
      ? `${asin?.extended_product?.title.slice(0, 35)}...`
      : asin?.extended_product?.title;
  }, [asin?.extended_product?.title]);

  return (
    <Grid sx={styles.gridItem} item xs={2} onClick={handleAsinSelect}>
      <Item>
        <Box sx={styles.imageContainer}>
          <Box sx={styles.image} />
          <Typography>{asin?.asin ?? ''}</Typography>
          <Typography sx={styles.variations}>{asin?.variations ? `${asin?.variations} VARIATIONS` : ''}</Typography>
        </Box>
        <Box sx={styles.content}>
          <Box sx={styles.contentWrapper}>
            <Box sx={styles.topContent}>
              <Box sx={styles.ratingContent}>
                {asin?.extended_product?.avg_review ? <StarIcon sx={styles.starIcon} /> : null}
                <Typography sx={styles.avgReviewsText}>{asin?.extended_product?.avg_review ?? ''}</Typography>
                <Typography sx={styles.reviewsCount}>{`(${
                  asin?.extended_product?.review_count ?? 0
                } REVIEWS)`}</Typography>
              </Box>
              <Box sx={styles.stockStatus}>{asin?.in_stock ? 'In Stock' : 'Out of Stock'}</Box>
            </Box>
            <Box sx={styles.titleContainer}>
              <Tooltip placement="top" title={asin?.extended_product?.title}>
                <Typography sx={styles.title}>{shortTitle}</Typography>
              </Tooltip>
              {adminView && (
                <Typography>
                  {asin.type === 'sr_products' ? (
                    <RocketIcon sx={styles.srIcon} />
                  ) : (
                    <AttributionIcon width="15px" height="15px" />
                  )}
                </Typography>
              )}
            </Box>
            <Box sx={styles.bottomContent}>
              <Box sx={styles.costContent}>
                {asin.extended_product?.price ? money.format(asin.extended_product?.price) : '-'}
              </Box>
              <Typography sx={styles.affiliateRate}>{asin.affiliate_rate ? `${asin.affiliate_rate}%` : ''}</Typography>
            </Box>
          </Box>
          <Box sx={styles.articleWrapper}>
            <Box sx={styles.articleTitle}>In Articles:</Box>
            <Box sx={styles.articleValue}>
              {asin?.asin_status === 'In Article' ? (
                <Box sx={styles.checkWrapper}>
                  <CheckIcon fontSize="small" color="inherit" />
                </Box>
              ) : (
                <Box sx={styles.noCheckWrapper}>
                  <CloseIcon fontSize="small" color="inherit" />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Item>
    </Grid>
  );
}
