import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { selectTeamInfo, selectTeamLoading } from 'modules/settings/store/selectors';
import { TableContainerWithLoadingGrow } from 'common/ui/table-container-with-loading/table-container-with-loading-grow';
import { TeamTableRow } from '../team-table-row';

interface Props {
  clientType: string;
}

export function TeamTable({ clientType }: Props): ReactElement {
  const data = useSelector(selectTeamInfo);
  const loading = useSelector(selectTeamLoading);

  return (
    <TableContainerWithLoadingGrow loading={loading}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            {/* <TableCell>User Role</TableCell> */}
            <TableCell>Connected Publishers</TableCell>
            <TableCell>Invitation Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{!loading && data?.map(item => <TeamTableRow item={item} clientType={clientType} />)}</TableBody>
      </Table>
    </TableContainerWithLoadingGrow>
  );
}
