const PARENT_PUBLISHER_SETTINGS_MODULE = '[parent-publisher-settings]';

const GET_PUBLISHER_ACCOUNTS = `${PARENT_PUBLISHER_SETTINGS_MODULE} GET_PUBLISHER_ACCOUNTS`;
const GET_PUBLISHER_ACCOUNTS_SUCCESS = `${PARENT_PUBLISHER_SETTINGS_MODULE} GET_PUBLISHER_ACCOUNTS_SUCCESS`;
const GET_PUBLISHER_ACCOUNTS_ERROR = `${PARENT_PUBLISHER_SETTINGS_MODULE} GET_PUBLISHER_ACCOUNTS_ERROR`;

const GET_PUBLISHER_ACCOUNTS_PARENTS = `${PARENT_PUBLISHER_SETTINGS_MODULE} GET_PUBLISHER_ACCOUNTS_PARENTS`;
const GET_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS = `${PARENT_PUBLISHER_SETTINGS_MODULE} GET_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS`;
const GET_PUBLISHER_ACCOUNTS_PARENTS_ERROR = `${PARENT_PUBLISHER_SETTINGS_MODULE} GET_PUBLISHER_ACCOUNTS_PARENTS_ERROR`;

const POST_PUBLISHER_ACCOUNTS_PARENTS = `${PARENT_PUBLISHER_SETTINGS_MODULE} POST_PUBLISHER_ACCOUNTS_PARENTS`;
const POST_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS = `${PARENT_PUBLISHER_SETTINGS_MODULE} POST_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS`;
const POST_PUBLISHER_ACCOUNTS_PARENTS_ERROR = `${PARENT_PUBLISHER_SETTINGS_MODULE} POST_PUBLISHER_ACCOUNTS_PARENTS_ERROR`;

const PATCH_PUBLISHER_ACCOUNTS_PARENTS = `${PARENT_PUBLISHER_SETTINGS_MODULE} PATCH_PUBLISHER_ACCOUNTS_PARENTS`;
const PATCH_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS = `${PARENT_PUBLISHER_SETTINGS_MODULE} PATCH_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS`;
const PATCH_PUBLISHER_ACCOUNTS_PARENTS_ERROR = `${PARENT_PUBLISHER_SETTINGS_MODULE} PATCH_PUBLISHER_ACCOUNTS_PARENTS_ERROR`;

const DELETE_PUBLISHER_ACCOUNTS_PARENTS = `${PARENT_PUBLISHER_SETTINGS_MODULE} DELETE_PUBLISHER_ACCOUNTS_PARENTS`;
const DELETE_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS = `${PARENT_PUBLISHER_SETTINGS_MODULE} DELETE_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS`;
const DELETE_PUBLISHER_ACCOUNTS_PARENTS_ERROR = `${PARENT_PUBLISHER_SETTINGS_MODULE} DELETE_PUBLISHER_ACCOUNTS_PARENTS_ERROR`;

export const parentPublisherSettingsActionTypes = {
  GET_PUBLISHER_ACCOUNTS,
  GET_PUBLISHER_ACCOUNTS_SUCCESS,
  GET_PUBLISHER_ACCOUNTS_ERROR,
  GET_PUBLISHER_ACCOUNTS_PARENTS,
  GET_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS,
  GET_PUBLISHER_ACCOUNTS_PARENTS_ERROR,
  POST_PUBLISHER_ACCOUNTS_PARENTS,
  POST_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS,
  POST_PUBLISHER_ACCOUNTS_PARENTS_ERROR,
  PATCH_PUBLISHER_ACCOUNTS_PARENTS,
  PATCH_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS,
  PATCH_PUBLISHER_ACCOUNTS_PARENTS_ERROR,
  DELETE_PUBLISHER_ACCOUNTS_PARENTS,
  DELETE_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS,
  DELETE_PUBLISHER_ACCOUNTS_PARENTS_ERROR,
};
