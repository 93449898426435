import { PubMaverickPerformanceSummary } from '../../types';
import { mavPlusActionTypes as T } from '../actions';
import { Action } from '../../../../common/interfaces/root';

export interface PublisherMavPerformanceSummaryStore {
  loading: boolean;
  error: Error | null;
  summary: PubMaverickPerformanceSummary;
}

const initialState: PublisherMavPerformanceSummaryStore = {
  loading: false,
  error: null,
  summary: undefined,
};

export const pubMavPerformanceSummaryReducer = (
  state: PublisherMavPerformanceSummaryStore = initialState,
  { type, payload }: Action
) => {
  switch (type) {
    case T.GET_PUB_MAV_PERFORMANCE_SUMMARY:
      return { ...state, loading: true, error: null };
    case T.GET_PUB_MAV_PERFORMANCE_SUMMARY_SUCCESS:
      return { ...state, loading: false, summary: payload };
    case T.GET_PUB_MAV_PERFORMANCE_SUMMARY_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
