import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { Styles } from 'common/types';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { tagsActions } from 'modules/tags/store/actions';
import { selectCurrentTagsPage, selectTags, selectTagsAmount, selectTagsLoading } from 'modules/tags/store/selectors';
import { MouseEvent, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TagRow } from './tag-row';

const styles: Styles = {
  table: { marginTop: 2 },
};

export function TagsTable(): ReactElement {
  const dispatch = useDispatch();

  const currentPage = useSelector(selectCurrentTagsPage);
  const amount = useSelector(selectTagsAmount);
  const rows = useSelector(selectTags);
  const loading = useSelector(selectTagsLoading);

  const handlePageChange = (_e: MouseEvent<HTMLButtonElement>, page: number) => {
    dispatch(tagsActions.changeTagsPage(page));
  };

  return (
    <>
      <TableContainerWithLoading sx={styles.table} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Publisher</TableCell>
              <TableCell>Tag</TableCell>
              <TableCell>Used</TableCell>
              <TableCell>Traffic type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.tags?.map(row => (
              <TagRow key={row.id} tag={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <TablePagination
        component="div"
        count={amount}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        page={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}
