import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import { settingsApi } from 'modules/settings/service/settings.service';
import { tagsApi } from 'modules/tags/services/tags.service';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { publishersSettingsActions, publishersSettingsActionTypes as T } from '../actions';

function* getPublisherSettingsInfo({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.getPublisherInfo, payload);
    yield put(publishersSettingsActions.getMainPublisherInfoSuccess(data));
  } catch (err) {
    yield put(publishersSettingsActions.getMainPublisherInfoError(err as Error));
  }
}

function* patchUserSettingsInfo({ payload, meta }: Action): SagaIterator {
  try {
    yield call(settingsApi.patchPublisherInfo, payload);
    yield put(publishersSettingsActions.patchMainPublisherInfoSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(publishersSettingsActions.patchMainPublisherInfoError(err as Error));
  }
}

function* patchUserSettingsCategories({ payload, meta }: Action): SagaIterator {
  try {
    yield call(settingsApi.patchPublisherCategories, payload);
    yield put(publishersSettingsActions.patchMainPublisherCategoriesSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(publishersSettingsActions.patchMainPublisherCategoriesError(err as Error));
  }
}

function* getPublisherTags({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(tagsApi.getTags, payload);
    yield put(publishersSettingsActions.getPublisherTagsSuccess(data));
  } catch (err) {
    yield put(publishersSettingsActions.getPublisherTagsError(err as Error));
  }
}

function* getPublisherUrls({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.getUrls, payload);
    yield put(publishersSettingsActions.getPublisherUrlsSuccess(data.urls, data.urls_count));
  } catch (err) {
    yield put(publishersSettingsActions.getPublisherUrlsError(err as Error));
  }
}

function* postPublisherCrawler({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.postUrlDailyScraping, payload);
    yield put(publishersSettingsActions.getMainPublisherInfoSuccess(data));
  } catch (err) {
    yield put(publishersSettingsActions.getMainPublisherInfoError(err as Error));
  }
}

function* getPublisherAffiliate({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.getPublisherAffiliate, payload);
    yield put(publishersSettingsActions.getPublisherAffiliateSuccess(data));
  } catch (err) {
    yield put(publishersSettingsActions.getPublisherAffiliateError(err as Error));
  }
}

function* patchPublisherAffiliate({ payload, meta }: Action): SagaIterator {
  try {
    yield call(settingsApi.patchPublisherAffiliate, payload);
    yield put(publishersSettingsActions.patchPublisherAffiliateSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(publishersSettingsActions.patchPublisherAffiliateError(err as Error));
  }
}

function* getPublisherTeam({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.getPublisherTeam, payload);
    yield put(publishersSettingsActions.getPublisherTeamSuccess(data));
  } catch (err) {
    yield put(publishersSettingsActions.getPublisherTeamError(err as Error));
  }
}

function* getWordpressPlugin({ payload }: Action): SagaIterator {
  try {
    yield call(settingsApi.getWordpressPlugin, payload);
  } catch (err) {
    yield put(publishersSettingsActions.getWordpressPluginError(err as Error));
  }
}

function* addPublisherTag({ payload, meta }: Action): SagaIterator {
  try {
    yield call(settingsApi.addPublisherTag, payload);
    meta?.onSuccess();
  } catch (err) {
    //
  }
}

function* patchDefaultTag({ payload, meta }: Action): SagaIterator {
  try {
    yield call(settingsApi.patchDefaulTag, payload);
    meta?.onSuccess();
  } catch (error) {
    meta?.onError();
  }
}

function* deletePublisherTag({ payload, meta }: Action): SagaIterator {
  try {
    yield call(settingsApi.deletePublisherTag, payload);
    meta?.onSuccess();
  } catch (err) {
    //
  }
}

function* getIntegrationLink({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.getIntegrationLink, payload);
    window.location.replace(data.link);
  } catch (err) {
    yield put(publishersSettingsActions.getIntegrationLinkError(err as Error));
  }
}

function* getIntegrationsList({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.getIntegrationsList, payload);
    yield put(publishersSettingsActions.getIntegrationsListSuccess(data?.integrations));
  } catch (err) {
    yield put(publishersSettingsActions.getIntegrationsListError(err as Error));
  }
}

function* deleteIntegrationFromList({ payload, meta }: Action): SagaIterator {
  try {
    yield call(settingsApi.deleteIntegrationsList, payload);
    yield put(publishersSettingsActions.deleteIntegrationFromListSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(publishersSettingsActions.deleteIntegrationFromListError(err as Error));
  }
}

function* getRateCardsSaga({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.getRateCards, payload);
    yield put(publishersSettingsActions.getRateCardsSuccess(data));
  } catch (err) {
    yield put(publishersSettingsActions.getRateCardsError(err as Error));
  }
}

function* patchRateCardsSaga({ payload, meta }: Action): SagaIterator {
  try {
    yield call(settingsApi.patchRateCards, payload);
    yield put(publishersSettingsActions.patchRateCardsSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(publishersSettingsActions.patchRateCardsError(err as Error));
  }
}

function* postAffiliateReport({ payload, meta }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.postAffiliateReport, payload);
    yield put(publishersSettingsActions.postAffiliateReportSuccess(data.message));
    meta.onSuccess();
  } catch (err) {
    yield put(publishersSettingsActions.postAffiliateReportError(err as Error));
  }
}

function* getAffiliateReportDownload({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.getAffiliateReportDownload, payload);
    yield put(publishersSettingsActions.getAffiliateReportDownloadSuccess({ data, fileName: payload.file_name }));
  } catch (err) {
    yield put(publishersSettingsActions.getAffiliateReportDownloadError(err as Error));
  }
}

function* getAffiliateReportHistory({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.getAffiliateReportHistory, payload);
    yield put(publishersSettingsActions.getAffiliateReportHistorySuccess(data));
  } catch (err) {
    yield put(publishersSettingsActions.getAffiliateReportHistoryError(err as Error));
  }
}

function* sendInvitation({ payload, meta }: Action): SagaIterator {
  try {
    yield call(settingsApi.sendInvitation, payload);
    yield put(publishersSettingsActions.sendInvitationSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(publishersSettingsActions.sendInvitationError(err as Error));
    meta.onError();
  }
}

function* deleteTeamMember({ payload, meta }: Action): SagaIterator {
  try {
    yield call(settingsApi.deleteTeamMember, payload);
    yield put(publishersSettingsActions.deleteTeamMemberSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(publishersSettingsActions.deleteTeamMemberError(err as Error));
  }
}

function* generateAttrTag({ payload }: Action) {
  try {
    const { data } = yield call(settingsApi.generateTag, payload.publisherId, payload.asin, payload.url);
    if (!data.tag) throw new Error();
    navigator.clipboard.writeText(data.tag);
    toast.success('Attribution tag copied to the clipboard!');
    yield put(publishersSettingsActions.generateAttrTagSuccess());
  } catch (err: unknown) {
    const msg = (err as AxiosError<{ message: string }>).response?.data?.message;
    toast.error(msg || 'Something went wrong');
    yield put(publishersSettingsActions.generateAttrTagError());
  }
}

function* getPendingInvitations({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.getPendingInvitations, payload);
    yield put(publishersSettingsActions.getPendingInvitationsSuccess(data));
  } catch (err) {
    yield put(publishersSettingsActions.getPendingInvitationsError(err as Error));
  }
}

function* getPublisherGroupsSaga(): SagaIterator {
  try {
    const { data } = yield call(settingsApi.getPublisherGroups);
    yield put(publishersSettingsActions.getPublisherGroupsSuccess(data));
  } catch (error) {
    yield put(publishersSettingsActions.getPublisherGroupsError(error as Error));
  }
}

function* getTeam({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.getTeam, payload);
    yield put(publishersSettingsActions.getTeamSuccess(data));
  } catch (err) {
    yield put(publishersSettingsActions.getTeamError(err as Error));
  }
}

function* getClients(): SagaIterator {
  try {
    const { data } = yield call(settingsApi.getClients);
    yield put(publishersSettingsActions.getClientsSuccess(data));
  } catch (err) {
    yield put(publishersSettingsActions.getClientsError(err as Error));
  }
}

function* patchTeamUser({ payload, meta }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.patchTeamUser, payload);
    yield put(publishersSettingsActions.patchTeamUserSuccess(data));
    meta.onSuccess();
  } catch (err) {
    yield put(publishersSettingsActions.patchTeamUserError(err as Error));
    meta.onSuccess();
  }
}

function* cancelTeamUser({ payload, meta }: Action): SagaIterator {
  try {
    yield call(settingsApi.cancelTeamUser, payload);
    yield put(publishersSettingsActions.cancelTeamUserSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(publishersSettingsActions.cancelTeamUserError(err as Error));
    meta.onSuccess();
  }
}

function* resendTeamUser({ payload, meta }: Action): SagaIterator {
  try {
    yield call(settingsApi.resendTeamUser, payload);
    yield put(publishersSettingsActions.resendTeamUserSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(publishersSettingsActions.resendTeamUserError(err as Error));
    meta.onSuccess();
  }
}

function* getPublisherBrandCommissions({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.getPublisherBrandCommissions, payload);
    yield put(publishersSettingsActions.getPublishersBrandCommissionsSuccess(data));
  } catch (err) {
    yield put(publishersSettingsActions.getPublishersBrandCommissionsError(err as Error));
  }
}

export function* settingsSaga(): SagaIterator {
  yield all([
    takeLatest(T.GET_MAIN_PUBLISHER_INFO, getPublisherSettingsInfo),
    takeLatest(T.PATCH_MAIN_PUBLISHER_INFO, patchUserSettingsInfo),
    takeLatest(T.PATCH_MAIN_PUBLISHER_CATEGORIES, patchUserSettingsCategories),
    takeLatest(T.GET_PUBLISHER_TAGS, getPublisherTags),
    takeLatest(T.ADD_PUBLISHER_TAG, addPublisherTag),
    takeLatest(T.PATCH_DEFAULT_TAG, patchDefaultTag),
    takeLatest(T.GET_PUBLISHER_URLS, getPublisherUrls),
    takeLatest(T.MAIN_CHANGE_PUBLISHER_CRAWLER, postPublisherCrawler),
    takeLatest(T.GET_PUBLISHER_AFFILIATE, getPublisherAffiliate),
    takeLatest(T.PATCH_PUBLISHER_AFFILIATE, patchPublisherAffiliate),
    takeLatest(T.GET_PUBLISHER_TEAM, getPublisherTeam),
    takeLatest(T.GET_WORDPRESS_PLUGIN, getWordpressPlugin),
    takeLatest(T.DELETE_PUBLISHER_TAG, deletePublisherTag),
    takeLatest(T.GET_INTEGRATION_LINK, getIntegrationLink),
    takeLatest(T.GET_INTEGRATIONS_LIST, getIntegrationsList),
    takeLatest(T.DELETE_INTEGRATION_FROM_LIST, deleteIntegrationFromList),
    takeLatest(T.DELETE_TEAM_MEMBER, deleteTeamMember),
    takeLatest(T.GET_RATE_CARDS, getRateCardsSaga),
    takeLatest(T.PATCH_RATE_CARDS, patchRateCardsSaga),
    takeLatest(T.POST_AFFILIATE_REPORT, postAffiliateReport),
    takeLatest(T.GET_AFFILIATE_REPORT_HISTORY, getAffiliateReportHistory),
    takeLatest(T.GET_AFFILIATE_REPORT_DOWNLOAD, getAffiliateReportDownload),
    takeLatest(T.SEND_INVITATION, sendInvitation),
    takeLatest(T.GENERATE_ATTR_TAG, generateAttrTag),
    takeLatest(T.GET_PENDING_INVITATIONS, getPendingInvitations),
    takeLatest(T.GET_PUBLISHER_GROUPS, getPublisherGroupsSaga),
    takeLatest(T.GET_TEAM, getTeam),
    takeLatest(T.GET_CLIENTS, getClients),
    takeLatest(T.PATCH_TEAM_USER, patchTeamUser),
    takeLatest(T.CANCEL_TEAM_USER, cancelTeamUser),
    takeLatest(T.RESEND_TEAM_USER, resendTeamUser),
    takeLatest(T.GET_PUBLISHER_BRAND_COMMISSIONS, getPublisherBrandCommissions),
  ]);
}
