import { ChangeEvent, MouseEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { Styles } from 'common/types';
import { Box, Button, CircularProgress, Popover, TextField, Typography } from '@mui/material';
import { theme } from 'common/constants/theme';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { selectTeamClientsInfo, selectTeamClientsLoading } from 'modules/settings/store/selectors';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { selectGlobalFilters } from 'common/store/selectors';
import { ClientItem } from '../client-item';
import { ParentItem } from '../parent-item';

const getStyles = (): Styles => ({
  inviteWrapper: {
    border: '1px solid #C9E2ED',
    borderRadius: '12px',
    width: '100%',
  },
  inviteHeader: {
    color: '#214254',
    width: '100%',
    borderBottom: '1px solid #C9E2ED',
    padding: '10px 20px',
    boxSizing: 'border-box',
    fontSize: '12px',
  },
  inviteContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '15px 20px',
    boxSizing: 'border-box',
  },
  inviteAvatar: {
    width: '42px',
    height: '42px',
    borderRadius: '50%',
    backgroundColor: '#E3E3E333',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  teamHeader: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '125%',
    color: '#060F14',
    marginTop: '35px',
    marginBottom: '15px',
  },
  teamInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bttnInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'right',
    width: '100%',
  },
  teamSelect: {
    width: '100%',
  },
  btn: {
    borderColor: theme.palette.primary.light,
    color: '#6F8490',
    ':hover': { borderColor: theme.palette.primary.light, color: '#6F8490' },
    width: '129px',
    mr: 1,
  },
  info: {
    marginLeft: 1,
  },
  email: {
    color: '#214254',
    fontSize: '12px',
  },
  brandSelect: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
  brandPopover: {
    display: 'flex',
    flexDirection: 'column',
    p: 2,
  },
  loaderWrapper: {
    width: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
  },
  brandText: {
    fontSize: '14px',
  },
  clientTitle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '250px',
  },
  clientsList: {
    mb: 2,
    display: 'flex',
    flexDirection: 'column',
    height: 400,
    overflow: 'hidden',
    overflowY: 'scroll',
    padding: 2,
    boxSizing: 'border-box',
  },
  clientWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  field: {
    width: '100%',
    p: 2,
    boxSizing: 'border-box',
  },
});

interface Props {
  user: PublishersSettingsModule.UserTeamData;
  clientType: string;
}

export function ParentBrandPopover({ user, clientType }: Props): ReactElement {
  const styles = getStyles();
  const dispatch = useDispatch();

  const [inviteClientsIds, setInviteClientsIds] = useState<Array<number>>([]);
  const [removeClientsIds, setRemoveClientsIds] = useState<Array<number>>([]);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [search, setSearch] = useState<string>('');

  const data = useSelector(selectTeamClientsInfo);
  const loading = useSelector(selectTeamClientsLoading);
  const globalFilters = useSelector(selectGlobalFilters);

  const userClientsIds = user?.clients?.map(client => client.client_id);

  const sortedParents = useMemo(() => {
    return data?.parents?.sort(
      (a, b) =>
        b.clients.filter(cl => inviteClientsIds?.find(client => Number(client) === Number(cl?.id))).length -
        a.clients.filter(cl => inviteClientsIds?.find(client => Number(client) === Number(cl?.id))).length
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorElement]);

  const sortedClients = useMemo(() => {
    return data?.clients?.sort(
      (a, b) =>
        inviteClientsIds.filter(cl => Number(cl) === Number(b.id)).length -
        inviteClientsIds.filter(cl => Number(cl) === Number(a.id)).length
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorElement]);

  const allClientsList =
    data?.clients?.length || data?.parents?.length
      ? [data.clients, ...data.parents.map(parent => parent.clients)]?.flat()
      : [];
  const searchedClient = allClientsList?.filter(item => item?.name.toLowerCase().includes(search.toLowerCase()));

  const handleBrandPickerOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(e.currentTarget);
  };

  const handleBrandPickerClose = () => {
    setAnchorElement(null);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleChangeInviteClientsIds = (checked: boolean, ids: Array<number>) => {
    if (checked) {
      setInviteClientsIds([...inviteClientsIds, ...ids]);
      setRemoveClientsIds(removeClientsIds?.filter(el => !ids.includes(el)));
    } else {
      setInviteClientsIds(inviteClientsIds?.filter(el => !ids.includes(el)));
      setRemoveClientsIds([...removeClientsIds, ...ids]);
    }
  };

  const handlePatchTeamUser = () => {
    dispatch(
      publishersSettingsActions.patchTeamUser(
        {
          email: user?.email,
          invite_clients_ids: inviteClientsIds?.filter(el => !userClientsIds.includes(el)),
          remove_clients_ids: removeClientsIds?.filter(el => userClientsIds.includes(el)),
          client_type: 'publisher',
        },
        () => {
          if (clientType === 'current') {
            dispatch(publishersSettingsActions.getTeam(globalFilters?.publisher_id));
          } else {
            dispatch(publishersSettingsActions.getTeam());
          }
        }
      )
    );
    handleBrandPickerClose();
  };

  useEffect(() => {
    setInviteClientsIds(user?.clients?.map(client => client.client_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography sx={styles.brandSelect} onClick={handleBrandPickerOpen}>
        <Typography sx={styles.brandText}>{inviteClientsIds?.length ?? 0} selected</Typography>
        <ArrowDropDownIcon />
      </Typography>
      <Popover
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={handleBrandPickerClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <TextField
          InputLabelProps={{ shrink: true }}
          sx={styles.field}
          size="small"
          onChange={handleSearchChange}
          value={search}
          placeholder="Search..."
        />
        {!loading ? (
          <Typography sx={styles.brandPopover}>
            {search ? (
              <Box sx={styles.clientsList}>
                {searchedClient?.map(item => (
                  <ClientItem
                    item={item}
                    inviteClientsIds={inviteClientsIds}
                    handleChangeInviteClientsIds={handleChangeInviteClientsIds}
                  />
                ))}
              </Box>
            ) : (
              <Box sx={styles.clientsList}>
                <>
                  {sortedParents?.map(item => (
                    <div>
                      <ParentItem
                        item={item}
                        inviteClientsIds={inviteClientsIds}
                        handleChangeInviteClientsIds={handleChangeInviteClientsIds}
                      />
                      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                        {item?.clients?.map(cl => (
                          <ClientItem
                            item={cl}
                            inviteClientsIds={inviteClientsIds}
                            handleChangeInviteClientsIds={handleChangeInviteClientsIds}
                          />
                        ))}
                      </Box>
                    </div>
                  ))}
                  {sortedClients?.map(item => (
                    <ClientItem
                      item={item}
                      inviteClientsIds={inviteClientsIds}
                      handleChangeInviteClientsIds={handleChangeInviteClientsIds}
                    />
                  ))}
                </>
              </Box>
            )}
            <Button variant="contained" onClick={handlePatchTeamUser}>
              Submit
            </Button>
          </Typography>
        ) : (
          <Box sx={styles.loaderWrapper}>
            <CircularProgress />
          </Box>
        )}
      </Popover>
    </>
  );
}
