/* eslint-disable react-hooks/exhaustive-deps */
import { MouseEvent, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import moment from 'moment';
import LinkIcon from '@mui/icons-material/Link';
import { useSelector } from 'react-redux';
import { Styles } from 'common/types';
import { contentOverviewApi } from 'modules/content-overview/services/content-overview.service';
import { useInView, usePermission } from 'common/hooks';
import { Product } from 'common/ui/product';
import { IProduct } from 'common/ui/product/types';
import { PERMISSIONS } from 'common/constants/permissions';
import { WarningProps } from 'common/ui/warning/types';
import { Warning } from 'common/ui/warning';
import { DynamicArrow } from 'common/ui/dynamics-arrow';
import { columnsPopoverCtx, PerformanceTableColumn } from '../columns-popover';
import { TagsPopover } from '../tags-popover';
import { selectGlobalFilters } from '../../../../../../../common/store/selectors';

const getStyles = ({ isDemoMode }: { isDemoMode: boolean }): Styles => ({
  titleCell: {
    verticalAlign: 'top',
    filter: isDemoMode ? 'blur(3px)' : 0,
  },
  cell: {
    position: 'relative',
    verticalAlign: 'top',
    padding: '16px 20px',
  },
  cellContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    padding: '0 15px 0 0',
  },
  nowrapCell: { whiteSpace: 'nowrap' },
  showMore: {
    display: 'block',
    margin: '10px 0 0 20px',
    color: '#4288F0',
    fontSize: 10,
    cursor: 'pointer',
    width: 'fit-content',
  },
  loadingContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  loadingTitle: { fontSize: 10, marginTop: 1 },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 1,
    marginBottom: 2,
    marginLeft: 1,
    fontSize: 14,
    color: '#4288F0',
    cursor: 'pointer',
  },
  title: {
    fontSize: '14px',
    color: '#060F14',
    cursor: 'pointer',
    width: 'fit-content',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: '#4288F0',
    cursor: 'pointer',
    fontSize: 11,
    gap: 0.5,
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    marginTop: 1,
  },
  titleDatesContainer: {
    marginTop: '10px',
  },
  titleCellDate: {
    fontSize: '0.8rem',
  },
  productsAccordion: {
    display: 'flex',
  },
  oosExclamation: {
    color: 'red',
    height: '16px',
    margin: '9px 0 0 5px',
  },
  tagsCount: {
    cursor: 'pointer',
    color: '#4288F0',
  },
  productContainer: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1.5 },
  saturationRatio: { display: 'flex', flexDirection: 'column' },
  saturationWarning: { color: 'orange', height: '16px', ':hover': { cursor: 'pointer' } },
  dynamics: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ml: 2,
  },
});

interface Props {
  row: ContentOverviewModule.PagePerformance;
  isDemoMode?: boolean;
  openArticle: (url: string) => void;
  isCompare?: boolean;
}

export function PageRow({ row, isDemoMode, isCompare, openArticle }: Props): ReactElement {
  const styles = getStyles({ isDemoMode });
  const globalFilters = useSelector(selectGlobalFilters);
  const [showMoreProducts, setShowMoreProducts] = useState<boolean>(false);
  const [suggestionsTriggered, setSuggestionsTriggered] = useState<boolean>(
    !!(row && row?.suggested_products && row?.suggested_products.length)
  );
  const [suggestionsLoading, setSuggestionsLoading] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Array<IProduct>>(row?.suggested_products ?? []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [suggestionsError, setSuggestionsError] = useState<boolean>(false);
  const [titleRef, setTitleRef] = useState<HTMLSpanElement>();
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const isAdmin = usePermission(PERMISSIONS.GET_PUBLISHERS);

  const { columns } = useContext(columnsPopoverCtx);

  const money = new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' });

  const inView = useInView(titleRef);

  const title = useMemo(() => {
    const title = row.title ?? row.alternative_title;
    return title?.length > 50 ? `${title.slice(0, 50)}...` : title;
  }, [row.alternative_title, row.title]);

  const path = useMemo(() => {
    const path = new URL(row.url ?? row.alternative_url).pathname ?? '-';
    return path.length > 50 ? `${path.slice(0, 50)}...` : path;
  }, [row.alternative_url, row.url]);

  const handlePopoverOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElement(null);
  };

  const toggleShowMoreProducts = () => {
    setShowMoreProducts(showMoreProducts => !showMoreProducts);
  };

  const handleOpenPage = () => {
    window.open(row.url);
  };

  const getFormattedOOSDate = () => {
    let oosText = '';
    const diff = moment().diff(moment(row.first_seen_oos), 'days') || 1;
    const unit = diff > 1 ? 'days' : 'day';

    if (diff <= 7) {
      oosText += `${diff} ${unit}`;
    } else if (diff > 7 && diff <= 14) {
      oosText += '> 7 days';
    } else if (diff > 14 && diff <= 30) {
      oosText += '> 14 days';
    } else {
      oosText += '> 30 days';
    }

    return oosText;
  };

  const isOOSExists = useMemo(() => (row.products?.entries ?? []).filter(p => !p.in_stock).length, [row]);

  const products = useMemo(() => {
    const inStock = (row.products?.entries ?? []).filter(p => p.in_stock);

    const oos = (row.products?.entries ?? [])
      .filter(p => !p.in_stock)
      .sort((a, b) => moment(a.last_seen_oos).valueOf() - moment(b.last_seen_oos).valueOf());

    const allProducts = [...oos, ...inStock].map(p => ({
      ...p,
      short_title: p.title?.length > 30 ? `${p.title.slice(0, 30)}...` : p.title,
    }));

    const orderedProducts = allProducts.filter(p => p.pageOrder).sort((a, b) => (a.pageOrder >= b.pageOrder ? 1 : -1));
    const productsWithoutOrder = allProducts.filter(p => !p.pageOrder);
    const products = [...orderedProducts, ...productsWithoutOrder];

    return products.filter(item => item?.title !== null);
  }, [row]);

  const rationWarning = useMemo(() => {
    return products.slice(0, 3).filter(p => p.is_attribution || p.is_pubx).length > 0;
  }, [products]);

  const parsedSuggestions = useMemo(() => {
    const products = (suggestions ?? [])
      .sort((a, b) => b.in_stock - a.in_stock)
      .map(p => ({
        ...p,
        short_title: p.title?.length > 30 ? `${p.title.slice(0, 30)}...` : p.title,
      }));
    if (showMoreProducts) return products.filter(item => item?.title !== null);
    return products.slice(0, 1);
  }, [suggestions, showMoreProducts]);

  const loadSuggestions = useCallback(
    (withInStock: boolean) => {
      if (!inView || suggestionsTriggered || !row.publisher_urls_id) return;

      setSuggestionsTriggered(true);
      setSuggestionsLoading(true);

      contentOverviewApi
        .getSuggestionsV2(row.publisher_id, row.publisher_urls_id, withInStock)
        .then(({ data }) => {
          row.suggested_products = data;
          setSuggestions(data);
          setSuggestionsLoading(false);
        })
        .catch(() => {
          setSuggestionsError(true);
          setSuggestionsLoading(false);
        });
    },
    [globalFilters.publisher_id, row, row.publisher_id, inView, row.publisher_urls_id, suggestionsTriggered]
  );

  const saturationPercentWarning = useMemo<WarningProps>(() => {
    if (row.saturation_percent < 10) return { tooltip: 'Saturation less than 10%', severity: 'severe' };
    if (row.saturation_percent < 50) return { tooltip: 'Saturation less than 50%', severity: 'mild' };
    return {};
  }, [row]);

  useEffect(() => {
    const isOOS = row.products?.entries.filter(p => !p.in_stock).length > 0;
    if (!isOOS) return;
    loadSuggestions(false);
  }, [row, loadSuggestions]);

  const analyticsStats = useMemo<Record<string, number>>(() => {
    if (!isCompare) return {};
    if (!row.analytics_to_compare || !row.analytics_to_compare.length) return {};

    const analyticsToCompare: Record<string, number>[] = row.analytics_to_compare
      .filter(el => el.date_from !== globalFilters.date_from.toDate().toString())
      .map(el => el.analytics);

    if (analyticsToCompare.length < 1) return {};

    return analyticsToCompare[0];
  }, [row, isCompare, globalFilters]);

  const getPercentDiff = (a: number, b: number) => 100 * Math.abs((a - b) / ((a + b) / 2));

  const generateMetricsCell = (
    colName: keyof typeof PerformanceTableColumn,
    type: 'default' | 'percent' | 'money' = 'default'
  ) => {
    if (!columns.includes(PerformanceTableColumn[colName])) return null;

    const valueName = PerformanceTableColumn[
      colName
    ].toLowerCase() as unknown as keyof ContentOverviewModule.PagePerformance;

    const value = row[valueName] as string | number;

    if (type === 'percent') {
      return (
        <TableCell sx={styles.cell}>
          <Box sx={styles.cellContainer}>
            {value && !Number.isNaN(value) ? `${(value as number).toFixed(2)}%` : '-'}
            {isCompare && analyticsStats[valueName] && +value !== +analyticsStats[valueName] && (
              <Box sx={styles.dynamics}>
                <DynamicArrow dynamic={+value > +analyticsStats[valueName] ? 'up' : 'down'} />
                <Typography
                  sx={{
                    color: +value > +analyticsStats[valueName] ? '#62D294' : '#E15953',
                  }}
                >
                  {getPercentDiff(+value, analyticsStats[valueName]).toFixed(2)}%
                </Typography>
              </Box>
            )}
          </Box>
        </TableCell>
      );
    }
    if (type === 'money') {
      return (
        <TableCell sx={styles.cell}>
          <Box sx={styles.cellContainer}>
            {value && !Number.isNaN(value) ? money.format(Number(value)) : '-'}
            {isCompare && analyticsStats[valueName] && +value !== +analyticsStats[valueName] && (
              <Box sx={styles.dynamics}>
                <DynamicArrow dynamic={+value > +analyticsStats[valueName] ? 'up' : 'down'} />
                <Typography
                  sx={{
                    color: +value > +analyticsStats[valueName] ? '#62D294' : '#E15953',
                  }}
                >
                  {getPercentDiff(+value, analyticsStats[valueName]).toFixed(2)}%
                </Typography>
              </Box>
            )}
          </Box>
        </TableCell>
      );
    }

    return (
      <TableCell sx={styles.cell}>
        <Box sx={styles.cellContainer}>
          {value ?? '-'}
          {isCompare && analyticsStats[valueName] && +value !== +analyticsStats[valueName] && (
            <Box sx={styles.dynamics}>
              <DynamicArrow dynamic={+value > +analyticsStats[valueName] ? 'up' : 'down'} />
              <Typography
                sx={{
                  color: +value > +analyticsStats[valueName] ? '#62D294' : '#E15953',
                }}
              >
                {getPercentDiff(+value, analyticsStats[valueName]).toFixed(2)}%
              </Typography>
            </Box>
          )}
        </Box>
      </TableCell>
    );
  };

  return (
    <>
      <TableRow>
        {columns.includes(PerformanceTableColumn.Publisher) && <TableCell sx={styles.cell}>{row.name}</TableCell>}
        <TableCell sx={styles.titleCell}>
          <Tooltip title={row.title ?? row.alternative_title} placement="top">
            <Typography onClick={handleOpenPage} sx={styles.title} ref={ref => setTitleRef(ref)}>
              {title ?? '-'}
            </Typography>
          </Tooltip>
          <Box sx={styles.link} onClick={() => window.open(row.url ? `${row.url}/` : `${row.alternative_url}/`)}>
            <LinkIcon color="inherit" fontSize="small" />
            {`${path}/`}
          </Box>
          {row.last_scraped_at && (
            <Box sx={styles.titleDatesContainer}>
              {row.published_date && (
                <Typography sx={styles.titleCellDate}>
                  Published date: {moment(row.published_date).format('ddd MMM D, YYYY')}
                </Typography>
              )}
              <Typography sx={styles.titleCellDate}>
                Last scrape: {moment(row.last_scraped_at).format('ddd MMM D, YYYY')}
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell sx={styles.cell}>
          <Box sx={styles.productContainer}>
            {!products?.length && '-'}
            {showMoreProducts &&
              products
                .filter(item => item?.title !== null)
                ?.map(product => <Product key={product.amazon_link} product={product} requestToSwapMode />)}
            {!showMoreProducts &&
              products.length &&
              products
                .filter(item => item?.title !== null)
                .slice(0, 1)
                ?.map(product => <Product key={product.amazon_link} product={product} requestToSwapMode />)}
          </Box>
          {row.products?.entries?.length > 1 && (
            <Box sx={styles.productsAccordion}>
              <Typography onClick={toggleShowMoreProducts} sx={styles.showMore}>
                {showMoreProducts ? '- Close' : '+ See All'}
              </Typography>
              {!showMoreProducts && isOOSExists ? (
                <Tooltip title="One or more ASINs out of stock">
                  <WarningAmberIcon sx={styles.oosExclamation} />
                </Tooltip>
              ) : null}
            </Box>
          )}
        </TableCell>
        <TableCell>
          {row.products?.entries?.length >= 1 && (
            <svg
              className="swap__icon"
              style={{
                zIndex: '1',
              }}
              onClick={() => {
                openArticle(row.url);
              }}
              width="20"
              height="20"
              viewBox="0 0 22 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.9358 9.04523L21.9805 13.5226L15.9358 18L14.2092 16.721L17.306 14.4262L1.22114 14.4271V12.6181H17.306L14.2092 10.3242L15.9358 9.04523ZM6.04463 0L7.77132 1.27899L4.67451 3.57286H20.7593V5.38191H4.67451L7.77132 7.67578L6.04463 8.95477L0 4.47739L6.04463 0Z"
                fill="#4288F0"
              />
            </svg>
          )}
        </TableCell>
        {columns.includes(PerformanceTableColumn.RecommendedAsins) && (
          <TableCell sx={styles.cell}>
            <Box sx={styles.productContainer}>
              {!suggestionsLoading && !suggestions.length && suggestionsTriggered && '-'}
              {!suggestionsLoading && !suggestions.length && !suggestionsTriggered && (
                <Button size="small" onClick={() => loadSuggestions(true)}>
                  View Recommendations
                </Button>
              )}
              {suggestionsLoading && (
                <Box sx={styles.loadingContainer}>
                  <CircularProgress size={20} color="primary" />
                  <Typography sx={styles.loadingTitle}>Loading suggestions...</Typography>
                </Box>
              )}
              {suggestionsLoading
                ? []
                : parsedSuggestions
                    ?.filter(item => item?.title !== null)
                    ?.map(product => <Product key={product.amazon_link} product={product} />)}
              {suggestions?.length > 1 && (
                <Typography onClick={toggleShowMoreProducts} sx={styles.showMore}>
                  {showMoreProducts ? '- Close' : '+ See All'}
                </Typography>
              )}
            </Box>
          </TableCell>
        )}
        {columns.includes(PerformanceTableColumn.FirstSeenOOS) && (
          <TableCell sx={styles.nowrapCell}>{row.first_seen_oos ? getFormattedOOSDate() : '-'}</TableCell>
        )}
        {columns.includes(PerformanceTableColumn.Tag) && (
          <TableCell sx={styles.nowrapCell}>
            <Typography sx={styles.tagsCount} onClick={row.tags && handlePopoverOpen}>
              {row.tags ? row.tags?.split(', ')?.length : 0}
            </Typography>
          </TableCell>
        )}
        {columns.includes(PerformanceTableColumn.Saturation) && isAdmin && (
          <TableCell sx={styles.cell}>
            {products?.length > 0 ? (
              <Box sx={styles.saturationRatio}>
                {row.saturation || '-'}
                {!rationWarning && <Warning tooltip="No Gainz products in 1st three positions" severity="mild" />}
              </Box>
            ) : (
              '-'
            )}
          </TableCell>
        )}
        {columns.includes(PerformanceTableColumn.SaturationPercent) && isAdmin && (
          <TableCell sx={styles.cell}>
            {products?.length > 0 ? (
              <Box sx={styles.saturationRatio}>
                {`${row.saturation_percent.toFixed(1)}%`}
                {saturationPercentWarning.severity && (
                  <Warning tooltip={saturationPercentWarning.tooltip} severity={saturationPercentWarning.severity} />
                )}
              </Box>
            ) : (
              '-'
            )}
          </TableCell>
        )}
        {columns.includes(PerformanceTableColumn.Visits) && (
          <TableCell sx={styles.cell}>
            <Box sx={styles.cellContainer}>
              {row.visits ?? '-'}
              {isCompare && analyticsStats.visits && Number(row.visits) !== analyticsStats.visits && (
                <Box sx={styles.dynamics}>
                  <DynamicArrow dynamic={+row.visits > analyticsStats.visits ? 'up' : 'down'} />
                  <Typography
                    sx={{
                      color: +row.visits > analyticsStats.visits ? '#62D294' : '#E15953',
                    }}
                  >
                    {getPercentDiff(+row.visits, analyticsStats.visits).toFixed(2)}%
                  </Typography>
                </Box>
              )}
            </Box>
          </TableCell>
        )}
        {columns.includes(PerformanceTableColumn.Clicks) && (
          <TableCell sx={styles.cell}>
            <Box sx={styles.cellContainer}>
              {row.clicks ?? '-'}
              {isCompare && analyticsStats.clicks && Number(row.clicks) !== analyticsStats.clicks && (
                <Box sx={styles.dynamics}>
                  <DynamicArrow dynamic={+row.clicks > analyticsStats.clicks ? 'up' : 'down'} />
                  <Typography
                    sx={{
                      color: +row.clicks > analyticsStats.clicks ? '#62D294' : '#E15953',
                    }}
                  >
                    {getPercentDiff(+row.clicks, analyticsStats.clicks).toFixed(2)}%
                  </Typography>
                </Box>
              )}
            </Box>
          </TableCell>
        )}
        {columns.includes(PerformanceTableColumn.Conversion) && (
          <TableCell sx={styles.cell}>
            <Box sx={styles.cellContainer}>
              {row.conversion ? `${row.conversion.toFixed(2)}%` : '-'}
              {isCompare && analyticsStats.conversion && row.conversion !== analyticsStats.conversion && (
                <Box sx={styles.dynamics}>
                  <DynamicArrow dynamic={+row.conversion > analyticsStats.conversion ? 'up' : 'down'} />
                  <Typography>{getPercentDiff(+row.conversion, analyticsStats.conversion).toFixed(2)}%</Typography>
                </Box>
              )}
            </Box>
          </TableCell>
        )}
        {columns.includes(PerformanceTableColumn.GMV) && (
          <TableCell sx={styles.cell}>
            <Box sx={styles.cellContainer}>
              {row.total_revenue ? money.format(Number(row.total_revenue)) : '-'}
              {isCompare &&
                analyticsStats.total_revenue &&
                Number(row.total_revenue) !== analyticsStats.total_revenue && (
                  <Box sx={styles.dynamics}>
                    <DynamicArrow dynamic={+row.total_revenue > analyticsStats.total_revenue ? 'up' : 'down'} />
                    <Typography
                      sx={{
                        color: +row.total_revenue > analyticsStats.total_revenue ? '#62D294' : '#E15953',
                      }}
                    >
                      {getPercentDiff(+row.total_revenue, analyticsStats.total_revenue).toFixed(2)}%
                    </Typography>
                  </Box>
                )}
            </Box>
          </TableCell>
        )}
        {generateMetricsCell('RevenuePerVisit', 'money')}
        {generateMetricsCell('AdFees', 'money')}

        {generateMetricsCell('MobileVisits')}
        {generateMetricsCell('MobileClicks')}
        {generateMetricsCell('MobileShipments')}
        {generateMetricsCell('MobileRevenue', 'money')}
        {generateMetricsCell('MobileConversion', 'percent')}
        {generateMetricsCell('MobileAdFees', 'money')}

        {generateMetricsCell('DesktopVisits')}
        {generateMetricsCell('DesktopClicks')}
        {generateMetricsCell('DesktopShipments')}
        {generateMetricsCell('DesktopRevenue', 'money')}
        {generateMetricsCell('DesktopConversion', 'percent')}
        {generateMetricsCell('DesktopAdFees', 'money')}
        <TableCell />
      </TableRow>
      <TagsPopover anchorEl={anchorElement} onClose={handlePopoverClose} tags={row.tags ? row.tags?.split(', ') : []} />
    </>
  );
}
