import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  CircularProgress,
} from '@mui/material';
import { getStyles as getSettingStyles } from 'modules/user-settings/components/user-settings-content/user-settings-content';
import { Styles } from 'common/types';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalFilters } from 'common/store/selectors';
import {
  selectAffiliateReportDownloadData,
  selectAffiliateReportDownloadFileName,
  selectAffiliateReportHistoryData,
  selectAffiliateReportHistoryLoading,
} from 'modules/settings/store/selectors';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import moment from 'moment';
import { downloadFile } from 'modules/conversions/utils';

const styles: Styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    overflow: 'hidden',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px',
    width: '100%',
  },
  tableContainer: { maxHeight: '400px' },
  uploadCol: { width: '30%' },
  fileName: { width: 'fit-content', textDecoration: 'underline', ':hover': { cursor: 'pointer' } },
};

export function ReportHistoryTable(): ReactElement {
  const filters = useSelector(selectGlobalFilters);
  const rows = useSelector(selectAffiliateReportHistoryData);
  const loading = useSelector(selectAffiliateReportHistoryLoading);
  const text = useSelector(selectAffiliateReportDownloadData);
  const fileName = useSelector(selectAffiliateReportDownloadFileName);

  const dispatch = useDispatch();

  const settingsStyles = getSettingStyles();

  const handleDownload = (fileName: string) => {
    dispatch(
      publishersSettingsActions.getAffiliateReportDownload({ publisher_id: filters.publisher_id, file_name: fileName })
    );
  };

  const splitFileName = (fileName: string) => {
    const splitName = fileName.includes('_') ? fileName.split('_') : [fileName];
    if (splitName.length === 2) return splitName[1];
    return splitName.slice(1).join('-'); // In case of multiple underscores
  };

  useEffect(() => {
    if (text && fileName) {
      downloadFile(fileName, text);
    }
  }, [text, fileName]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Box sx={styles.container}>
      {(loading && (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )) || (
        <TableContainer sx={styles.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography sx={settingsStyles.titleField}>Name</Typography>
                </TableCell>
                <TableCell sx={styles.uploadCol}>
                  <Typography sx={settingsStyles.titleField}>Date</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={rows.indexOf(row)}>
                  <TableCell>
                    <Typography sx={styles.fileName} onClick={() => handleDownload(row.file_name)}>
                      {splitFileName(row.file_name)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={styles.uploadCol}>{moment(row.created_at).format('lll')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
