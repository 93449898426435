import { ChangeEvent, ReactElement, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  BarController,
  LineController,
  TooltipItem,
} from 'chart.js';
import { Box, Button, CircularProgress, MenuItem, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { paper } from 'common/constants/colors';
import { useDispatch, useSelector } from 'react-redux';
import { htmlLegendPlugin } from 'common/plugins/chartjs';
import { nanoid } from 'nanoid';
import { getTop10ChartNetworkColor } from 'modules/overview-v2/utils';
import {
  selectAllStatistics,
  selectCombined,
  selectTop10Export,
  selectTop10Pages,
  selectTop10PagesLoading,
} from 'modules/overview-v2/store/selectors';
import { theme } from 'common/constants/theme';
import { usePermission } from 'common/hooks';
import { PERMISSIONS } from 'common/constants/permissions';
import { overviewActions } from 'modules/overview-v2/store/actions';
import { selectGlobalFilters } from 'common/store/selectors';
import { downloadFile } from 'modules/conversions/utils';
import { publisherGroups } from 'common/constants/publisher-groups';
import { EmptyChart } from 'common/ui/empty-chart';
import { useNavigate } from 'react-router-dom';
import { DownloadButton } from 'common/ui/download-button';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  BarController,
  LineController,
  Title,
  Tooltip,
  Legend
);

const styles: Styles = {
  container: {
    backgroundColor: paper,
    borderRadius: '20px',
    width: '100%',
    padding: 3,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: { fontWeight: 600, fontSize: 18 },
  chart: { flexGrow: 1, minHeight: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' },
  head: { display: 'flex', justifyContent: 'space-between', mb: 1 },
  squaredBtn: {
    width: 36,
    height: 36,
    p: 0,
    minWidth: 36,
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    ':hover': { color: theme.palette.primary.dark, borderColor: theme.palette.primary.dark },
  },
  exportContainer: { display: 'flex', alignItems: 'center', gap: 1 },
  limitSelect: { width: 150 },
  warningBtn: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.primary,
    ':hover': { backgroundColor: theme.palette.grey[200] },
  },
  warningContainer: { display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' },
  warnButtonsContainer: { display: 'flex', alignItems: 'center', gap: 1 },
};

const parseLabel = (label: string, publisher: string, isCombined: boolean) => {
  return label
    .trim()
    .split('-')
    .map(labelWord => {
      const chars = labelWord.split('');
      chars[0] = chars[0].toUpperCase();
      return chars.join('');
    })
    .join(' ')
    .concat(isCombined ? ` - ${publisher}` : '');
};

export function Top10Chart(): ReactElement {
  const [limit, setLimit] = useState<string>('10');

  const chartCanvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart>();

  const dispatch = useDispatch();
  const nav = useNavigate();

  const top10 = useSelector(selectTop10Pages);
  const top10Export = useSelector(selectTop10Export);
  const loading = useSelector(selectTop10PagesLoading);
  const globalFilters = useSelector(selectGlobalFilters);
  const { isCombined } = useSelector(selectCombined);
  const allStats = useSelector(selectAllStatistics);

  const isAdmin = usePermission(PERMISSIONS.GET_PUBLISHERS);

  const legendId = useMemo(() => {
    return nanoid();
  }, []);

  const handleLimitChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLimit(e.target.value);
  };

  // eslint-disable-next-line consistent-return
  const renderChart = () => {
    if (loading) return <CircularProgress color="primary" />;
    if (!allStats?.is_any_statistics_exist)
      return (
        <EmptyChart
          description={
            <Box sx={styles.warningContainer}>
              This area tracks your top earning pages.
              <Box sx={styles.warnButtonsContainer}>
                <Button sx={styles.warningBtn} onClick={() => nav('/publisher/embed')}>
                  Embed JS
                </Button>
                <Typography fontSize={12}>and</Typography>
                <Button sx={styles.warningBtn} onClick={() => nav('/publisher/affiliate')}>
                  Add Amazon Earn
                </Button>
              </Box>
            </Box>
          }
          image="/assets/images/no-earnings-chart.svg"
        />
      );
    return <canvas ref={chartCanvasRef} />;
  };

  const handleExport = () => {
    dispatch(
      overviewActions.getTop10Export({
        publisher_id: globalFilters.publisher_id,
        limit: Number(limit),
        publisher_group: isCombined ? Number(publisherGroups[globalFilters.publisher_group]) : '',
        date_by: globalFilters.date_by,
        date_from: globalFilters.date_from,
        date_to: globalFilters.date_to,
      })
    );
  };

  useEffect(() => {
    if (top10Export.data) {
      downloadFile(`top_${limit}_performers`, top10Export.data);
      dispatch(overviewActions.getTop10ExportSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [top10Export.data]);

  useLayoutEffect(() => {
    if (!top10 || loading || !allStats?.is_any_statistics_exist) return;

    if (chartRef.current) chartRef.current.destroy();

    const presentedNetworks = new Set<string>();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const datasets = Object.entries(top10).reduce((acc, [key, value], index) => {
      Object.entries(value.revenue).forEach(([network, amount]) => {
        if (!acc[network])
          acc[network] = {
            label: network,
            data: Object.keys(top10).map(() => null),
            backgroundColor: getTop10ChartNetworkColor(network),
            barThickness: 16,
          };

        const networkValue = parseFloat(amount);

        if (networkValue > 0) {
          acc[network].data[index] = networkValue;
        }

        if (networkValue > 0) presentedNetworks.add(network);
      });
      return acc;
    }, {} as Record<string, { label: string; data: Array<number>; backgroundColor: string; barThickness: number }>);

    const parsedDatasets = Object.entries(datasets)
      .filter(([key]) => presentedNetworks.has(key))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([key, value]) => value);

    const money = new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' });

    const data = {
      labels: Object.keys(top10).map(label => parseLabel(label, top10[label].publisher, isCombined)),
      datasets: parsedDatasets,
    };

    chartRef.current = new Chart(chartCanvasRef.current, {
      type: 'bar',
      data,
      plugins: [htmlLegendPlugin],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          htmlLegend: {
            containerID: legendId,
          },
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (item: TooltipItem<'bar'>) => {
                return money.format(Number(item.raw));
              },
            },
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
        indexAxis: 'y',
        datasets: {
          bar: {
            borderRadius: 1,
            barThickness: 8,
            maxBarThickness: 8,
            barPercentage: 1,
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            stacked: true,
            grid: {
              color: '#C2DEEB',
              drawBorder: false,
            },
            ticks: {
              crossAlign: 'far',
              callback: (_, index) =>
                data.labels[index]?.length > 50 ? `${data.labels[index].slice(0, 50)}...` : data.labels[index],
            },
          },
        },
      },
    });

    // eslint-disable-next-line consistent-return
    return () => chartRef.current.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [top10, loading, legendId, allStats?.is_any_statistics_exist]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.head}>
        <Typography sx={styles.title}>Top 10 earning pages</Typography>
        <Box id={legendId} />
        {isAdmin && allStats?.is_any_statistics_exist && (
          <Box sx={styles.exportContainer}>
            <TextField
              sx={styles.limitSelect}
              select
              size="small"
              label="Select limit"
              onChange={handleLimitChange}
              value={limit}
            >
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="100">100</MenuItem>
              <MenuItem value="500">500</MenuItem>
            </TextField>
            <DownloadButton handleDownload={handleExport} loading={top10Export?.loading} color="dark" />
          </Box>
        )}
      </Box>
      <Box sx={styles.chart}>{renderChart()}</Box>
    </Box>
  );
}
