import { Action } from 'common/interfaces/root';
import { actionTypes } from '../actions/action-types';

const initialState: Conversions.Reports.ReportsState = {
  loading: false,
  error: null,
  data: [],
};

export default (
  state: Conversions.Reports.ReportsState = initialState,
  { type, payload }: Action
): Conversions.Reports.ReportsState => {
  switch (type) {
    case actionTypes.report.GET_REPORTS:
      return { ...state, loading: true };
    case actionTypes.report.GET_REPORTS_SUCCESS:
      return { ...state, loading: false, data: payload };
    case actionTypes.report.GET_REPORTS_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
