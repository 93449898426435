import { Action } from 'common/interfaces/root';
import { revenueByPageActionTypes as T } from '../actions';

const initState: RevenueByPage.TableStore = {
  loading: false,
  error: null,
  rows: null,
  page: 0,
  rowsPerPage: 10,
  amount: 0,
  sort: {
    field: 'total_revenue',
    direction: 'desc',
  },
};

export default (state: RevenueByPage.TableStore = initState, { type, payload }: Action): RevenueByPage.TableStore => {
  switch (type) {
    case T.GET_REVENUE_BY_PAGE_TABLE:
      return { ...state, loading: true };

    case T.GET_REVENUE_BY_PAGE_TABLE_SUCCESS:
      return { ...state, loading: false, amount: payload.amount, rows: payload.rows };

    case T.GET_REVENUE_BY_PAGE_TABLE_ERROR:
      return { ...state, loading: false, error: payload };

    case T.CHANGE_REVENUE_TABLE_PAGE:
      return { ...state, page: payload };

    case T.CHANGE_REVENUE_TABLE_SORT:
      return { ...state, sort: payload };

    case T.CHANGE_REVENUE_TABLE_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: payload };

    default:
      return state;
  }
};
