import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, IconButton } from '@mui/material';
import { Styles } from 'common/types';
import { theme } from 'common/constants/theme';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DeleteModal } from 'common/ui/delete-modal';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { selectGlobalFilters } from 'common/store/selectors';
import toast from 'react-hot-toast';

interface StylesProps {
  image: string;
}

const getStyles = ({ image }: StylesProps): Styles => ({
  avatar: {
    backgroundImage: `url(${image})`,
    borderRadius: '50%',
    backgroundSize: 'cover',
    width: 65,
    height: 65,
    backgroundColor: theme.palette.primary.light,
  },
  userItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  userInfo: {
    marginLeft: '15px',
  },
  userWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userMail: {
    color: '#214254',
    fontSize: '12px',
  },
});

interface Props {
  name?: string;
  avatar?: string;
  email: string;
  id?: number;
}

export function TeamMemberRow({ name, avatar, email, id }: Props): ReactElement {
  const dispatch = useDispatch();
  const styles = getStyles({ image: avatar ?? '' });

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const globalFilters = useSelector(selectGlobalFilters);

  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleRemoveMember = () => {
    dispatch(
      publishersSettingsActions.deleteTeamMember(globalFilters?.publisher_id, String(id), () => {
        handleDeleteModalClose();
        dispatch(publishersSettingsActions.getPublisherTeam(String(globalFilters?.publisher_id)));
      })
    );
  };

  const handleSuccessInvite = () => {
    toast.success('Invitation has been resended');
  };

  const handleErrorInvite = () => {
    toast.error('Resending invitation error');
  };

  const handleResendInvitation = () => {
    dispatch(
      publishersSettingsActions.sendInvitation(
        email,
        String(globalFilters?.publisher_id),
        handleSuccessInvite,
        handleErrorInvite
      )
    );
  };

  return (
    <>
      <Box sx={styles.userItem}>
        <Box sx={styles.userWrapper}>
          <Box sx={styles.avatar} />
          <Box sx={styles.userInfo}>
            <Typography>{name ?? ''}</Typography>
            <Typography sx={styles.userMail}>{email}</Typography>
          </Box>
        </Box>
        {id ? (
          <IconButton onClick={handleDeleteModalOpen}>
            <CloseIcon />
          </IconButton>
        ) : (
          <IconButton onClick={handleResendInvitation}>
            <RefreshIcon />
          </IconButton>
        )}
      </Box>
      <DeleteModal open={deleteModalOpen} onClose={handleDeleteModalClose} onConfirm={handleRemoveMember} />
    </>
  );
}
