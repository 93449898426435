import { ReactElement } from 'react';
import { Button } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { Styles } from 'common/types';
import { theme } from 'common/constants/theme';

const getStyles = (color: 'light' | 'dark'): Styles => ({
  filtersBtn: {
    width: 36,
    height: 36,
    p: 0,
    minWidth: 36,
    color: color === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
    borderColor: color === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
    ':hover': {
      color: color === 'dark' ? theme.palette.primary.light : theme.palette.primary.light,
      borderColor: color === 'dark' ? theme.palette.primary.light : theme.palette.primary.light,
    },
  },
});

interface Props {
  handleFiltersOpen: () => void;
  color?: 'light' | 'dark';
}

export function FilterButton({ handleFiltersOpen, color = 'dark' }: Props): ReactElement {
  return (
    <Button variant="outlined" sx={getStyles(color).filtersBtn} size="small" onClick={handleFiltersOpen}>
      <TuneIcon fontSize="small" />
    </Button>
  );
}
