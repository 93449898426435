import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Action } from 'common/interfaces/root';
import { asinsConversionsBoostService } from 'modules/asins-conversions-boost/services/asins-conversions-boost.service';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import { asinsConversionsBoostTypes as TYPES } from '../actions/action-types';
import { asinsConversionsBoostActions } from '../actions';

function* getConversionsBoostSaga({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(asinsConversionsBoostService.getConversionsBoost, payload);

    yield put(asinsConversionsBoostActions.conversionsBoostActions.getConversionsBoostSuccess(data));
  } catch (error) {
    yield put(asinsConversionsBoostActions.conversionsBoostActions.getConversionsBoostError(error as Error));
  }
}

function* postConversionBoostSaga({ payload, meta }: Action): SagaIterator {
  try {
    yield call(asinsConversionsBoostService.postConversionBoost, payload);

    toast.success('Entry created');
    yield put(asinsConversionsBoostActions.conversionsBoostActions.postConversionBoostSuccess());
    meta.onSuccess();
  } catch (error) {
    const message: string = error instanceof AxiosError ? error.response?.data?.message : (error as Error).message;
    toast.error(message);

    yield put(asinsConversionsBoostActions.conversionsBoostActions.postConversionBoostError(error as Error));
  }
}

function* editConversionBoostSaga({ payload, meta }: Action): SagaIterator {
  try {
    yield call(asinsConversionsBoostService.editConversionBoost, payload);

    toast.success('Entry updated');
    yield put(asinsConversionsBoostActions.conversionsBoostActions.editConversionBoostSuccess());
    meta.onSuccess();
  } catch (error) {
    const message: string = error instanceof Error ? error.message : (error as string);
    toast.error(message);

    yield put(asinsConversionsBoostActions.conversionsBoostActions.editConversionBoostError(error as Error));
  }
}

function* deleteConversionBoostSaga({ payload, meta }: Action): SagaIterator {
  try {
    yield call(asinsConversionsBoostService.deleteConversionBoost, payload);

    toast.success('Entry deleted');
    yield put(asinsConversionsBoostActions.conversionsBoostActions.deleteConversionBoostSuccess());
    meta.onSuccess();
  } catch (error) {
    const message: string = error instanceof Error ? error.message : (error as string);
    toast.error(message);

    yield put(asinsConversionsBoostActions.conversionsBoostActions.deleteConversionBoostError(error as Error));
  }
}

function* getExportSaga({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(asinsConversionsBoostService.getExport, payload);

    yield put(asinsConversionsBoostActions.exportActions.getExportSuccess(data.link));
  } catch (error) {
    const message: string = error instanceof Error ? error.message : (error as string);
    toast.error(message);

    yield put(asinsConversionsBoostActions.exportActions.getExportError(error as Error));
  }
}

function* uploadFileSaga({ payload, meta }: Action): SagaIterator {
  try {
    const { data } = yield call(asinsConversionsBoostService.uploadFile, payload);

    yield put(asinsConversionsBoostActions.uploadActions.uploadFileSuccess(data));
    meta.onSuccess();
  } catch (error) {
    const message = error instanceof AxiosError ? error.response?.data?.message : (error as Error).message;
    yield put(asinsConversionsBoostActions.uploadActions.uploadFileError(message));
  }
}

function* getBrandsSaga(): SagaIterator {
  try {
    const { data } = yield call(asinsConversionsBoostService.getBrands);

    yield put(asinsConversionsBoostActions.brandsActions.getBrandsSuccess(data.brands));
  } catch (error) {
    yield put(asinsConversionsBoostActions.brandsActions.getBrandsError(error as Error));
  }
}

export function* asinsConversionsBoostSaga(): SagaIterator {
  yield all([
    takeLatest(TYPES.CONVERSIONS_BOOST.GET_CONVERSIONS_BOOST, getConversionsBoostSaga),
    takeLatest(TYPES.FORM.POST_CONVERSION_BOOST, postConversionBoostSaga),
    takeLatest(TYPES.FORM.EDIT_CONVERSION_BOOST, editConversionBoostSaga),
    takeLatest(TYPES.FORM.DELETE_CONVERSION_BOOST, deleteConversionBoostSaga),
    takeLatest(TYPES.EXPORT.GET_EXPORT, getExportSaga),
    takeLatest(TYPES.UPLOAD_FILE.UPLOAD_FILE, uploadFileSaga),
    takeLatest(TYPES.BRANDS.GET_BRANDS, getBrandsSaga),
  ]);
}
