import { ApiService } from 'common/services';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { parseFilters } from 'common/utils/parse-filters';

class AttributionApiService extends ApiService {
  getAsinsAmazonAttr = (filters: IStatisticsQueryString) => {
    return this.get('/api/amazon-attribution/tags/asins', { params: parseFilters(filters) });
  };

  postCsvForAsinsAmazonAttr = (file: File) => {
    return this.postFormData('/api/amazon-attribution/tags/asins/upload', { file });
  };

  deleteAsinsAmazonAttr = (id: number) => {
    return this.delete(`/api/amazon-attribution/tags/asins/${id}`);
  };

  getBrandsAmazonAttr = (filters: IStatisticsQueryString) => {
    return this.get('/api/amazon-attribution/tags/brands', { params: parseFilters(filters) });
  };

  postCsvForBrandsAmazonAttr = (file: File) => {
    return this.postFormData('/api/amazon-attribution/tags/brands/upload', { file });
  };

  deleteBrandsAmazonAttr = (id: number) => {
    return this.delete(`/api/amazon-attribution/tags/brands/${id}`);
  };
}

export const attributionApi = new AttributionApiService(process.env.REACT_APP_API_URL);
