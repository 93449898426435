import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import RefreshIcon from '@mui/icons-material/Refresh';
import { PublisherPicker } from '../../../../common/ui/publisher-picker';
import { Styles } from '../../../../common/types';
import { DatePicker } from '../../../../common/ui/date-picker';
import { amazonAffiliateReportsActions } from '../../store/actions';
import { State } from '../../../../common/store/reducers';

const styles: Styles = {
  form: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: '20px',
    justifyContent: 'flex-start',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  formItemlabel: {
    color: '#6F8490',
    fontSize: '14px',
  },
  publisherPickerWrapper: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '8px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    zIndex: 1,
  },
  fileNameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginLeft: '10px',
    overflow: 'hidden',
    boxSizing: 'border-box',
    '&:after': {
      content: '" "',
      width: '15px',
      height: '100%',
      background: 'linear-gradient(45deg, transparent 100%, white 100%)',
    },
  },
  fileName: {
    fontSize: '14px',
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
  uploadField: {
    display: 'none',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  actionButton: {
    marginTop: '15px',
    width: 'max-content',
    alignSelf: 'end',
    minWith: '160px',
  },
  datePicker: {
    color: 'rgba(0, 0, 0)',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    ':hover': {
      color: 'rgba(0, 0, 0)',
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
  },
};

const recoverAmazonAffiliateReports = (state: State) => state.amazonAffiliateReports.recoverAmazonAffiliateReports;

function RecoverReportsForm() {
  const dispatch = useDispatch();
  const { start_date, end_date, publisher_id, loading } = useSelector(recoverAmazonAffiliateReports);
  const { setRecoverMissingReportsState } = amazonAffiliateReportsActions;

  const onSubmit = () => {
    if (!publisher_id) {
      toast.error('Publisher is required');
    }

    if (start_date && end_date && publisher_id) {
      const requestBody = {
        publisher_id,
        start_date: moment(start_date).tz('EST', true).toISOString(),
        end_date: moment(end_date).tz('EST', true).toISOString(),
      };
      dispatch(amazonAffiliateReportsActions.recoverMissingReports(requestBody));
    }
  };

  useEffect(() => {
    if (loading) {
      toast.loading('Sending request...', { id: 'recover-reports', icon: <CircularProgress size={20} /> });
    } else {
      toast.remove('recover-reports');
    }
  }, [loading]);
  return (
    <Box sx={styles.form}>
      <Box sx={styles.itemsContainer}>
        <Box sx={styles.formItem}>
          <Typography sx={styles.formItemlabel}>Publisher:</Typography>
          <Box sx={styles.publisherPickerWrapper}>
            <PublisherPicker
              singleOption
              selectedPublisher={publisher_id}
              handlePublisherChange={publisher_id =>
                dispatch(setRecoverMissingReportsState({ publisher_id: Number(publisher_id) }))
              }
              disableRedirect
              fullWidth
            />
          </Box>
        </Box>
        <Box sx={styles.formItem}>
          <Typography sx={styles.formItemlabel}>Reports dates range:</Typography>
          <DatePicker
            classes={styles.datePicker}
            from={start_date}
            to={end_date}
            onChange={(start_date, end_date) => {
              dispatch(setRecoverMissingReportsState({ start_date, end_date }));
            }}
          />
        </Box>
      </Box>
      <Box sx={styles.actions}>
        <Divider sx={{ width: '100%' }} />
        <Button
          startIcon={<RefreshIcon />}
          variant="contained"
          color="primary"
          sx={styles.actionButton}
          onClick={onSubmit}
          disabled={loading}
        >
          Recover Reports
        </Button>
      </Box>
    </Box>
  );
}

export { RecoverReportsForm };
