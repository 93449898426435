import { PresetsStore } from 'common/interfaces/presets';
import { Action } from 'common/interfaces/root';
import { presetsActionsTypes as T } from '../actions/presets';

const initialState: PresetsStore = {
  loading: true,
  error: null,
  filters: null,
};

export default (state: PresetsStore = initialState, { type, payload }: Action): PresetsStore => {
  switch (type) {
    case T.GET_FILTERS_PRESETS:
      return { ...state, loading: true };

    case T.GET_FILTERS_PRESETS_SUCCESS:
      return { ...state, filters: { ...(state.filters ?? {}), ...payload }, loading: false };

    case T.GET_FILTERS_PRESETS_ERROR:
      return { ...state, loading: false, error: payload };

    case T.GET_TIME_INFO_SUCCESS:
      return { ...state, filters: { ...(state.filters ?? {}), dateInfo: payload } };

    default:
      return state;
  }
};
