const ASIN_FINDER_MODULE = '[asin-finder:search]';

const GET_ASINS_LIST = `${ASIN_FINDER_MODULE} GET_ASINS_LIST`;
const GET_ASINS_LIST_SUCCESS = `${ASIN_FINDER_MODULE} GET_ASINS_LIST_SUCCESS`;
const GET_ASINS_LIST_ERROR = `${ASIN_FINDER_MODULE} GET_ASINS_LIST_ERROR`;

const CHANGE_PAGE_ASINS_LIST = `${ASIN_FINDER_MODULE} CHANGE_PAGE_ASINS_LIST`;
const CHANGE_AMOUNT_ASINS_LIST = `${ASIN_FINDER_MODULE} CHANGE_AMOUNT_ASINS_LIST`;
const CHANGE_LIMIT_ASINS_LIST = `${ASIN_FINDER_MODULE} CHANGE_LIMIT_ASINS_LIST`;

const GENERATE_REPORT = `${ASIN_FINDER_MODULE} GENERATE_REPORT`;
const GENERATE_REPORT_SUCCESS = `${ASIN_FINDER_MODULE} GENERATE_REPORT_SUCCESS`;
const GENERATE_REPORT_ERROR = `${ASIN_FINDER_MODULE} GENERATE_REPORT_ERROR`;

const OPEN_REPORT_LINK = `${ASIN_FINDER_MODULE} OPEN_REPORT_LINK`;
const SUCCESS_REPORT_LINK = `${ASIN_FINDER_MODULE} SUCCESS_REPORT_LINK`;
const REMOVE_EXECUTION_ID = `${ASIN_FINDER_MODULE} REMOVE_EXECUTION_ID`;

const DOWNLOAD_ASINS_CSV = `${ASIN_FINDER_MODULE} DOWNLOAD_ASINS_CSV`;

const GET_BRANDS = `${ASIN_FINDER_MODULE} GET_BRANDS`;
const GET_BRANDS_SUCCESS = `${ASIN_FINDER_MODULE} GET_BRANDS_SUCCESS`;
const GET_BRANDS_ERROR = `${ASIN_FINDER_MODULE} GET_BRANDS_ERROR`;

const GET_BRAND_PARENTS = `${ASIN_FINDER_MODULE} GET_BRAND_PARENTS`;
const GET_BRAND_PARENTS_SUCCESS = `${ASIN_FINDER_MODULE} GET_BRAND_PARENTS_SUCCESS`;
const GET_BRAND_PARENTS_ERROR = `${ASIN_FINDER_MODULE} GET_BRAND_PARENTS_ERROR`;

export const asinFinderActionTypes = {
  GET_ASINS_LIST,
  GET_ASINS_LIST_SUCCESS,
  GET_ASINS_LIST_ERROR,
  CHANGE_PAGE_ASINS_LIST,
  CHANGE_AMOUNT_ASINS_LIST,
  CHANGE_LIMIT_ASINS_LIST,
  DOWNLOAD_ASINS_CSV,
  GENERATE_REPORT,
  GENERATE_REPORT_SUCCESS,
  GENERATE_REPORT_ERROR,
  OPEN_REPORT_LINK,
  SUCCESS_REPORT_LINK,
  REMOVE_EXECUTION_ID,
  GET_BRAND_PARENTS,
  GET_BRAND_PARENTS_SUCCESS,
  GET_BRAND_PARENTS_ERROR,
  GET_BRANDS,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_ERROR,
};
