/* eslint-disable react/jsx-no-useless-fragment */
import { TableCell, TableRow } from '@mui/material';
import { TableRowActions } from 'common/ui/table-row-actions';
import { ReactElement, useState } from 'react';
import { Styles } from 'common/types';
import { useDispatch } from 'react-redux';
import { publishersSettingsActions } from 'modules/publishers-settings/store/actions';
import { BlockBrandsModal } from '../../block-brands-modal';

const styles: Styles = {
  blockedBrandsRow: {
    maxWidth: '100px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
};

interface Props {
  publisher: PublishersSettingsModule.Publisher;
  onEdit: (publisherId: number) => void;
  onDelete: (publisherId: number) => void;
}

export function PublisherRow({ publisher, onEdit, onDelete }: Props): ReactElement {
  const dispatch = useDispatch();
  const [openBlockedBrandsModal, setOpenBlockedBrandsModal] = useState(false);

  const handleEditClick = () => {
    onEdit(publisher.id);
  };

  const handleBlockedBrandsClick = () => {
    setOpenBlockedBrandsModal(true);
  };

  const handleDeleteClick = () => {
    onDelete(publisher.id);
  };

  return (
    <>
      <TableRow>
        <TableCell>{publisher?.id}</TableCell>
        <TableCell>{publisher?.name}</TableCell>
        <TableCell>{publisher?.parent_name_aos || '-'}</TableCell>
        <TableCell>{publisher?.slug}</TableCell>
        <TableCell>{publisher?.hash}</TableCell>
        <TableCell>{publisher?.channels ? Object.values(publisher?.channels).join(', ') : '-'}</TableCell>
        <TableCell sx={styles.blockedBrandsRow} onClick={handleBlockedBrandsClick}>
          {publisher?.blocked_brands?.length ? Object.values(publisher.blocked_brands).join(', ') : '-'}
        </TableCell>
        <TableCell>{publisher?.gainz_active ? 'Active' : 'Inactive'}</TableCell>
        <TableCell>{publisher?.commission_rate}</TableCell>
        <TableCell>{publisher?.display_commission ? 'Yes' : 'No'}</TableCell>
        <TableCell align="center">
          <TableRowActions onEdit={handleEditClick} onDelete={handleDeleteClick} show={['delete', 'edit']} />
        </TableCell>
      </TableRow>
      {openBlockedBrandsModal ? (
        <BlockBrandsModal
          publisherID={publisher?.id}
          open={openBlockedBrandsModal}
          onClose={() => setOpenBlockedBrandsModal(false)}
          onSuccess={() => {
            dispatch(publishersSettingsActions.getPublishers());
            setOpenBlockedBrandsModal(false);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}
