import { ReactElement, useEffect, useMemo, useRef } from 'react';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  BarController,
  LineController,
} from 'chart.js';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { paper } from 'common/constants/colors';
import { useSelector } from 'react-redux';
import { theme } from 'common/constants/theme';
import { nanoid } from 'nanoid';
import { htmlLegendPlugin } from 'common/plugins/chartjs';
import { selectCorrelationStatistics, selectCorrelationStatisticsLoading } from 'modules/overview-v2/store/selectors';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  BarController,
  LineController,
  Title,
  Tooltip,
  Legend
);

const styles: Styles = {
  container: {
    backgroundColor: paper,
    borderRadius: '20px',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    height: 450,
    position: 'relative',
    flexDirection: 'column',
    padding: theme.spacing(3, 4),
  },
  head: { display: 'flex', justifyContent: 'space-between' },
  title: { fontSize: 18, fontWeight: 600 },
  chart: { flexGrow: 1, width: '100%', marginTop: 2 },
};

export function CorrelationChart(): ReactElement {
  const chartCanvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart>();

  const loading = useSelector(selectCorrelationStatisticsLoading);
  const data = useSelector(selectCorrelationStatistics);

  const legendId = useMemo(() => {
    return nanoid();
  }, []);

  useEffect(() => {
    if (loading || !data || !data.ad_fees || !data.clicks || !data.total_revenue) return;

    if (chartRef.current) chartRef.current.destroy();

    const labels = data.ad_fees.map(entry => entry.created_at);
    const revenue = data.total_revenue.map(entry => Number(entry.value));
    const spend = data.ad_fees.map(entry => Number(entry.value));
    const clicks = data.clicks.map(entry => Number(entry.value));

    chartRef.current = new Chart(chartCanvasRef.current, {
      type: 'bar',
      data: {
        datasets: [
          {
            label: 'Clicks',
            data: clicks,
            borderColor: '#FFAE3C',
            backgroundColor: '#FFAE3C',
            type: 'line',
          },
          {
            label: 'Affiliate GMV',
            data: revenue,
            borderColor: '#4288F0',
            backgroundColor: '#4288F0',
          },
          {
            label: 'Ad Fees',
            data: spend,
            borderColor: '#D1E7F1',
            backgroundColor: '#D1E7F1',
          },
        ],
        labels,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        datasets: {
          bar: {
            barPercentage: 1,
          },
        },
        scales: {
          y: {
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          htmlLegend: {
            containerID: legendId,
          },
          legend: {
            display: false,
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
      },
      plugins: [htmlLegendPlugin],
    });

    // eslint-disable-next-line consistent-return
    return () => chartRef.current.destroy();
  }, [loading, data, legendId]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.head}>
        <Typography sx={styles.title}>Summary</Typography>
        <Box id={legendId} />
      </Box>
      <Box sx={styles.chart}>{loading ? <CircularProgress color="primary" /> : <canvas ref={chartCanvasRef} />}</Box>
    </Box>
  );
}
