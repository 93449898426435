import { ReactElement } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Styles } from 'common/types';

interface IProps {
  dynamic: 'up' | 'down';
}

const getStyles = (dynamic: 'up' | 'down'): Styles => ({
  arrowIcon: {
    height: '14px',
    width: '14px',
    color: dynamic === 'up' ? '#62D294' : '#E15953',
  },
});

export function DynamicArrow({ dynamic }: IProps): ReactElement {
  const styles = getStyles(dynamic);

  return dynamic === 'up' ? <ArrowUpwardIcon sx={styles.arrowIcon} /> : <ArrowDownwardIcon sx={styles.arrowIcon} />;
}
