const RECOMMENDED_ASINS_MODULE = '[affiliate-gainz:recommended-asins:asins]';
const RECOMMENDED_ASINS_FILTERS_MODULE = '[affiliate-gainz:recommended-asins:filters]';

const GET_ASINS = `${RECOMMENDED_ASINS_MODULE} GET_ASINS`;
const GET_ASINS_SUCCESS = `${RECOMMENDED_ASINS_MODULE} GET_ASINS_SUCCESS`;
const GET_ASINS_ERROR = `${RECOMMENDED_ASINS_MODULE} GET_ASINS_ERROR`;

const GET_NEW_ASINS = `${RECOMMENDED_ASINS_MODULE} GET_NEW_ASINS`;
const GET_NEW_ASINS_SUCCESS = `${RECOMMENDED_ASINS_MODULE} GET_NEW_ASINS_SUCCESS`;
const GET_NEW_ASINS_ERROR = `${RECOMMENDED_ASINS_MODULE} GET_NEW_ASINS_ERROR`;

const GET_FEATURED_ASINS = `${RECOMMENDED_ASINS_MODULE} GET_FEATURED_ASINS`;
const GET_FEATURED_ASINS_SUCCESS = `${RECOMMENDED_ASINS_MODULE} GET_FEATURED_ASINS_SUCCESS`;
const GET_FEATURED_ASINS_ERROR = `${RECOMMENDED_ASINS_MODULE} GET_FEATURED_ASINS_ERROR`;

const GET_PLACEMENTS = `${RECOMMENDED_ASINS_MODULE} GET_PLACEMENTS`;
const GET_PLACEMENTS_SUCCESS = `${RECOMMENDED_ASINS_MODULE} GET_PLACEMENTS_SUCCESS`;
const GET_PLACEMENTS_ERROR = `${RECOMMENDED_ASINS_MODULE} GET_PLACEMENTS_ERROR`;

const GET_CATEGORIES = `${RECOMMENDED_ASINS_MODULE} GET_CATEGORIES`;
const GET_CATEGORIES_SUCCESS = `${RECOMMENDED_ASINS_MODULE} GET_CATEGORIES_SUCCESS`;
const GET_CATEGORIES_ERROR = `${RECOMMENDED_ASINS_MODULE} GET_CATEGORIES_ERROR`;

const GET_EXPORT = `${RECOMMENDED_ASINS_MODULE} GET_EXPORT`;
const GET_EXPORT_SECCUESS = `${RECOMMENDED_ASINS_MODULE} GET_EXPORT_SECCUESS`;
const GET_EXPORT_ERROR = `${RECOMMENDED_ASINS_MODULE} GET_EXPORT_ERROR`;

const SET_SEARCH = `${RECOMMENDED_ASINS_FILTERS_MODULE} SET_SEARCH`;
const SET_LIMIT = `${RECOMMENDED_ASINS_FILTERS_MODULE} SET_LIMIT`;
const SET_OFFSET = `${RECOMMENDED_ASINS_FILTERS_MODULE} SET_OFFSET`;
const SET_STATUS = `${RECOMMENDED_ASINS_FILTERS_MODULE} SET_STATUS`;
const SET_SEASON = `${RECOMMENDED_ASINS_FILTERS_MODULE} SET_SEASON`;
const SET_CATEGORY = `${RECOMMENDED_ASINS_FILTERS_MODULE} SET_CATEGORY`;
const SET_PRICE_MAX = `${RECOMMENDED_ASINS_FILTERS_MODULE} SET_PRICE_MAX`;
const SET_PRICE_MIN = `${RECOMMENDED_ASINS_FILTERS_MODULE} SET_PRICE_MIN`;
const SET_EARN_MAX = `${RECOMMENDED_ASINS_FILTERS_MODULE} SET_EARN_MAX`;
const SET_EARN_MIN = `${RECOMMENDED_ASINS_FILTERS_MODULE} SET_EARN_MIN`;
const SET_PRODUCT_SORT = `${RECOMMENDED_ASINS_FILTERS_MODULE} SET_PRODUCT_SORT`;
const SET_PRICE_SORT = `${RECOMMENDED_ASINS_FILTERS_MODULE} SET_PRICE_SORT`;
const SET_EARN_SORT = `${RECOMMENDED_ASINS_FILTERS_MODULE} SET_EARN_SORT`;

const RESET_FILTERS = `${RECOMMENDED_ASINS_FILTERS_MODULE} RESET_FILTERS`;

export const recommendedAsinsActionTypes = {
  ASINS: {
    GET_ASINS,
    GET_ASINS_SUCCESS,
    GET_ASINS_ERROR,
    GET_NEW_ASINS,
    GET_NEW_ASINS_SUCCESS,
    GET_NEW_ASINS_ERROR,
    GET_FEATURED_ASINS,
    GET_FEATURED_ASINS_SUCCESS,
    GET_FEATURED_ASINS_ERROR,
  },
  PLACEMENTS: {
    GET_PLACEMENTS,
    GET_PLACEMENTS_SUCCESS,
    GET_PLACEMENTS_ERROR,
  },
  CATEGORIES: {
    GET_CATEGORIES,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_ERROR,
  },
  FILTERS: {
    SET_SEARCH,
    SET_LIMIT,
    SET_OFFSET,
    SET_STATUS,
    SET_SEASON,
    SET_CATEGORY,
    SET_PRICE_MAX,
    SET_PRICE_MIN,
    SET_EARN_MAX,
    SET_EARN_MIN,
    SET_PRODUCT_SORT,
    SET_PRICE_SORT,
    SET_EARN_SORT,
    RESET_FILTERS,
  },
  EXPORT: {
    GET_EXPORT,
    GET_EXPORT_SECCUESS,
    GET_EXPORT_ERROR,
  },
};
