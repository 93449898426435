import { Box, Button, Typography } from '@mui/material';
import { primaryLight } from 'common/constants/colors';
import { Styles } from 'common/types';
import { ReactElement } from 'react';
import { Modal } from '../modal';

const styles: Styles = {
  button: { maxWidth: 100, width: '100%', marginLeft: 1, ':first-child': { marginLeft: 0 } },
  buttons: { marginTop: 2, display: 'flex', justifyContent: 'flex-end' },
  entryContainer: { m: 1.5, p: 1.5, bgcolor: primaryLight, width: '50%', borderRadius: '5px' },
};

interface Props {
  open: boolean;
  entry?: string;
  onClose: () => void;
  onConfirm: () => void;
}

export function DeleteModal({ open, entry, onClose, onConfirm }: Props): ReactElement {
  return (
    <Modal open={open} onClose={onClose} maxWidth="xs" title="Confirm action">
      <Typography>Are you sure you want to delete this entry?</Typography>
      {entry && (
        <Box sx={styles.entryContainer}>
          <Typography variant="h5">{entry}</Typography>
        </Box>
      )}
      <Box sx={styles.buttons}>
        <Button color="error" sx={styles.button} variant="contained" onClick={onConfirm}>
          Delete
        </Button>
        <Button color="info" variant="outlined" sx={styles.button} onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}
