import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { overviewActionTypes as T } from './action-types';

const getAllStatistics = (filters: IStatisticsQueryString) => ({
  type: T.GET_ALL_STATISTICS,
  payload: filters,
});

const getAllStatisticsSuccess = (statistics: Overview.StatisticsEntries) => ({
  type: T.GET_ALL_STATISTICS_SUCCESS,
  payload: statistics,
});

const getAllStatisticsError = (error: Error) => ({
  type: T.GET_ALL_STATISTICS_ERROR,
  payload: error,
});

const getCorrelationStatistics = (filters: IStatisticsQueryString) => ({
  type: T.GET_CORRELATION_STATISTICS,
  payload: filters,
});

const getCorrelationStatisticsSuccess = (statistics: Overview.CorrelationGraphData) => ({
  type: T.GET_CORRELATION_STATISTICS_SUCCESS,
  payload: statistics,
});

const getCorrelationStatisticsError = (error: Error) => ({
  type: T.GET_CORRELATION_STATISTICS_ERROR,
  payload: error,
});

const getDevicesStatistics = (filters: IStatisticsQueryString) => ({
  type: T.GET_DEVICES_STATISTICS,
  payload: filters,
});

const getDevicesStatisticsSuccess = (data: Overview.DevicesData) => ({
  type: T.GET_DEVICES_STATISTICS_SUCCESS,
  payload: data,
});

const getDevicesStatisticsError = (error: Error) => ({
  type: T.GET_DEVICES_STATISTICS_ERROR,
  payload: error,
});

const getTopPerformers = (filters: IStatisticsQueryString) => ({
  type: T.GET_TOP_PERFORMERS,
  payload: filters,
});

const getTopAsinsSuccess = (data: Overview.Performance.Performance[]) => ({
  type: T.GET_TOP_ASINS_SUCCESS,
  payload: data,
});

const getTopCategoriesSuccess = (data: Overview.Performance.Performance[]) => ({
  type: T.GET_TOP_CATEGORIES_SUCCESS,
  payload: data,
});

const getTopPerformersError = (error: Error) => ({
  type: T.GET_TOP_PERFORMERS_ERROR,
  payload: error,
});

export const overviewActions = {
  getAllStatistics,
  getAllStatisticsSuccess,
  getAllStatisticsError,
  getCorrelationStatistics,
  getCorrelationStatisticsSuccess,
  getCorrelationStatisticsError,
  getDevicesStatistics,
  getDevicesStatisticsSuccess,
  getDevicesStatisticsError,
  getTopPerformers,
  getTopAsinsSuccess,
  getTopCategoriesSuccess,
  getTopPerformersError,
};
