import { State } from 'common/store/reducers';

export const selectPublishers = (state: State) => state.publishersSettings.publishers.data;

export const selectPublishersLoading = (state: State) => state.publishersSettings.publishers.loading;

export const selectPublishersSettingsForm = (state: State) => state.publishersSettings.form;

export const selectPublishersSettingsFilters = (state: State) => state.publishersSettings.filters;

export const selectPublishersSettingsTableFilters = (state: State) => state.publishersSettings.tableFilters;

export const selectPublishersParents = (state: State) => state.publishersSettings.publishersParents.data;

export const selectPublisherBlockedBrands = (state: State) => state.publishersSettings.blockedBrands.data;
