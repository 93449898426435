import { Action } from 'common/interfaces/root';
import { publishersSettingsActionTypes as T } from '../actions';

const initState: PublishersSettingsModule.PublishersStore = {
  loading: true,
  error: null,
  data: [],
};

export default (
  state: PublishersSettingsModule.PublishersStore = initState,
  { type, payload }: Action
): PublishersSettingsModule.PublishersStore => {
  switch (type) {
    case T.GET_PUBLISHERS:
      return { ...state, loading: true };

    case T.GET_PUBLISHERS_SUCCESS:
      return { ...state, loading: false, data: payload };

    case T.GET_PUBLISHERS_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
