import { Box, Typography } from '@mui/material';
import { ReactElement } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Styles } from 'common/types';
import { scheme } from 'modules/stats/mock';

interface Props {
  enabled?: boolean;
}

const styles: Styles = {
  boxContainer: { height: '24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  boxNA: {
    width: '78px',
    backgroundColor: scheme.bg2,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '20px',
    padding: '5px 10px',
  },
  boxEnabled: {
    width: '78px',
    backgroundColor: '#E7F8EF',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '20px',
    padding: '5px 10px',
  },
  typoEnabled: { color: '#62D294' },
  boxDisabled: {
    width: '78px',
    backgroundColor: '#ffa4a2',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '20px',
    padding: '5px 10px',
  },
  typoDisabled: { color: '#ab000d' },
};

export function StatusLabel({ enabled }: Props): ReactElement {
  if (enabled === undefined)
    return (
      <Box sx={styles.boxContainer}>
        <Box sx={styles.boxNA}>
          <Typography>N/A</Typography>
        </Box>
        <ExpandMoreIcon fontSize="small" />
      </Box>
    );

  return (
    <Box sx={styles.boxContainer}>
      {enabled ? (
        <Box sx={styles.boxEnabled}>
          <Typography sx={styles.typoEnabled}>Enabled</Typography>
        </Box>
      ) : (
        <Box sx={styles.boxDisabled}>
          <Typography sx={styles.typoDisabled}>Disabled</Typography>
        </Box>
      )}
      <ExpandMoreIcon fontSize="small" />
    </Box>
  );
}
