import { TableCell, TableRow } from '@mui/material';
import { TableRowActions } from 'common/ui/table-row-actions';
import { ReactElement } from 'react';

interface Props {
  url: UrlsModule.Url;
}

export function UrlRow({ url }: Props): ReactElement {
  return (
    <TableRow>
      <TableCell>{url?.publisher_name}</TableCell>
      <TableCell>{url?.url_path}</TableCell>
      <TableCell>
        <TableRowActions show={['delete', 'edit']} />
      </TableCell>
    </TableRow>
  );
}
