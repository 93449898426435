import { State } from 'common/store/reducers';

export const selectCurrentPage = (state: State) => state.revenueByPage.table.page;

export const selectTableRows = (state: State) => state.revenueByPage.table.rows;

export const selectTableAmount = (state: State) => state.revenueByPage.table.amount;

export const selectTableRowsLoading = (state: State) => state.revenueByPage.table.loading;

export const selectTop10Pages = (state: State) => state.revenueByPage.top10.data;

export const selectTop10PagesLoading = (state: State) => state.revenueByPage.top10.loading;

export const selectTableSort = (state: State) => state.revenueByPage.table.sort;

export const selectTableRowsPerPage = (state: State) => state.revenueByPage.table.rowsPerPage;

export const selectExportLoading = (state: State) => state.revenueByPage.export.loading;
