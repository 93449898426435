import { useEffect, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { TagsInput } from 'react-tag-input-component';
import { Styles } from '../../../../../../../common/types';
import { theme } from '../../../../../../../common/constants/theme';
import './styles.css';

const styles: Styles = {
  container: {
    marginTop: 2,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  publisherDomainContainer: {
    display: 'flex',
  },
  publisherUrlsInputContainer: {},
  statusHeaders: { fontSize: 12, lineHeight: '16px', display: 'flex', alignItems: 'center' },
};

interface Props {
  editMode: boolean;
  initedDomainUrls: string[];
  onUpdate: (domainUrls: string[]) => void;
  onUpdateEditMode: (mode: boolean) => void;
}

export function PublisherDomainUrls({ editMode, initedDomainUrls, onUpdate, onUpdateEditMode }: Props) {
  const [domainUrls, setDomainUrls] = useState([...initedDomainUrls]);
  const onChange = (urls: string[]) => {
    setDomainUrls(urls);
  };
  const stopEditing = () => {
    onUpdate(domainUrls);
    onUpdateEditMode(false);
  };
  const startEditing = () => {
    onUpdateEditMode(true);
  };

  useEffect(() => {
    setDomainUrls(initedDomainUrls);
  }, [initedDomainUrls]);

  return (
    <div>
      {editMode ? (
        <Box sx={styles.publisherDomainContainer}>
          <Box>
            <TagsInput value={domainUrls} onChange={onChange} name="Urls" placeHolder="Enter urls" />
          </Box>
          <Button onClick={stopEditing}>Stop editing</Button>
        </Box>
      ) : (
        <Box sx={styles.publisherDomainContainer}>
          <Typography sx={styles.statusHeaders}>Publisher domain urls: {domainUrls.join(', ')}</Typography>
          <Button onClick={startEditing}>Edit</Button>
        </Box>
      )}
    </div>
  );
}
