/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { MouseEvent, ReactElement } from 'react';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { TableCell, TableHead, Table, TableRow, TableBody, TablePagination, TableSortLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { publishersTagsActions } from 'modules/publishers-affiliate-tags/store/actions/publisher-tags-action';
import {
  selectCurrentPublishersTagsPage,
  selectPublishersTags,
  selectPublishersTagsLoading,
  selectPublishersTagsSort,
} from 'modules/publishers-affiliate-tags/store/selectors';
import { TableSortField } from 'modules/publishers-affiliate-tags/constants';
import { PublisherTagsRow } from './publisher-tags-row/publisher-tags-row';

interface Props {
  onTagsImportClick: (publisher: PublishersAffiliateTagsModule.ExtendedPublisherTags) => void;
  handleRefreshTable: () => void;
}

export function PublishersTagsTable({ onTagsImportClick, handleRefreshTable }: Props): ReactElement {
  const dispatch = useDispatch();

  const publishers = useSelector(selectPublishersTags);
  const loading = useSelector(selectPublishersTagsLoading);
  const currentPage = useSelector(selectCurrentPublishersTagsPage);
  const sort = useSelector(selectPublishersTagsSort);

  const handlePageChange = (_e: MouseEvent<HTMLButtonElement>, page: number) => {
    dispatch(publishersTagsActions.changePublishersTagsPage(page));
  };

  const handleSortChange = (field: TableSortField, direction: 'asc' | 'desc') => {
    switch (direction) {
      case 'asc':
        dispatch(publishersTagsActions.changePublishersTagsSort({ field, direction: 'desc' }));
        break;
      case 'desc':
        dispatch(publishersTagsActions.changePublishersTagsSort({ field, direction: 'asc' }));
        break;
      default:
    }
  };

  return (
    <TableContainerWithLoading loading={loading}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Slug</TableCell>
            <TableCell>Tag</TableCell>
            <TableCell>
              <TableSortLabel
                onClick={() => handleSortChange(TableSortField.TotalTags, sort.direction)}
                active={sort.field === TableSortField.TotalTags}
                direction={sort.direction}
              >
                Total Tags
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                onClick={() => handleSortChange(TableSortField.UsedPaid, sort.direction)}
                active={sort.field === TableSortField.UsedPaid}
                direction={sort.direction}
              >
                Used Paid
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                onClick={() => handleSortChange(TableSortField.UsedOrganic, sort.direction)}
                active={sort.field === TableSortField.UsedOrganic}
                direction={sort.direction}
              >
                Used Organic
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                onClick={() => handleSortChange(TableSortField.Available, sort.direction)}
                active={sort.field === TableSortField.Available}
                direction={sort.direction}
              >
                Available
              </TableSortLabel>
            </TableCell>
            <TableCell>Assigned %</TableCell>
            <TableCell>Free tags %</TableCell>
            <TableCell>Tags Import</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {publishers?.map((p, i) => (
            <PublisherTagsRow
              onTagsImport={onTagsImportClick}
              key={i}
              publisherTags={p}
              handleRefreshTable={handleRefreshTable}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={-1}
        rowsPerPage={30}
        rowsPerPageOptions={[30]}
        page={currentPage}
        nextIconButtonProps={{ disabled: publishers?.length < 30 }}
        onPageChange={handlePageChange}
      />
    </TableContainerWithLoading>
  );
}
