import { Action } from 'common/interfaces/root';
import { contentOverviewActionTypes as T } from '../actions';

const initState: ContentOverviewModule.RecommendationsStore = {
  data: null,
  loading: true,
  error: null,
  asins: null,
};

export default (
  state: ContentOverviewModule.RecommendationsStore = initState,
  { type, payload }: Action
): ContentOverviewModule.RecommendationsStore => {
  switch (type) {
    case T.GET_PRODUCT_RECOMMENDATIONS:
      return { ...state, loading: true };

    case T.GET_PRODUCT_RECOMMENDATIONS_SUCCESS:
      return { ...state, loading: false, data: payload.data, asins: payload.asins };

    case T.GET_PRODUCT_RECOMMENDATIONS_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
