import { Action } from 'common/interfaces/root';
import { asinsConversionsBoostTypes as TYPES } from '../actions/action-types';

const initState: AsinsConversionsBoostModule.BrandsStore = {
  brands: null,
  loading: false,
};

export default (
  state: AsinsConversionsBoostModule.BrandsStore = initState,
  { type, payload }: Action
): AsinsConversionsBoostModule.BrandsStore => {
  switch (type) {
    case TYPES.BRANDS.SET_BRANDS:
      return { ...state, brands: payload };
    case TYPES.BRANDS.GET_BRANDS:
      return { ...state, loading: true };
    case TYPES.BRANDS.GET_BRANDS_SUCCESS:
      return { ...state, loading: false, brands: payload };
    case TYPES.BRANDS.GET_BRANDS_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
};
