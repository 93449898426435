import { Modal } from 'common/ui/modal';
import { Formik } from 'formik';
import { asinsConversionsBoostActions } from 'modules/asins-conversions-boost/store/actions';
import { asinsConversionsBoostSelectors } from 'modules/asins-conversions-boost/store/selectors';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Box, Button, CircularProgress } from '@mui/material';
import { Styles } from 'common/types';
import { FiltersForm } from '../filters-form';

const getStyles = (): Styles => ({
  form: { display: 'flex', flexDirection: 'column', gap: '30px' },
  loading: {
    textAlign: 'center',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    marginBottom: '20px',
  },
  btn: {
    width: '50%',
    alignSelf: 'center',
  },
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const validationSchema = Yup.object().shape({
  asins: Yup.array().of(Yup.string().test('isValidASIN', 'Invalid Amazon ASIN', value => /^[A-Z0-9]{10}$/.test(value))),
  brand_id: Yup.number().nullable().integer().positive(),
  publisher_id: Yup.number().nullable().positive(),
  boost: Yup.string().test('isNumericInRange', 'Boost must be a numeric value between 0 and 1', value => {
    if (!value) {
      return true;
    }
    const numericValue = Number(value);
    return !Number.isNaN(numericValue) && numericValue >= 0 && numericValue <= 1;
  }),
  compare_operator: Yup.string(),
});

export function ExportModal({ isOpen, onClose }: Props): ReactElement {
  const dispatch = useDispatch();
  const styles = getStyles();

  const { loading, data: link } = useSelector(asinsConversionsBoostSelectors.selectExport);

  const getInitialValues = (): AsinsConversionsBoostModule.FiltersForm => ({
    asins: [],
    brand_id: null,
    publisher_id: null,
    boost: '',
    compare_operator: '=',
  });

  const handleSubmit = (values: AsinsConversionsBoostModule.FiltersForm) => {
    dispatch(
      asinsConversionsBoostActions.exportActions.getExport(
        values as AsinsConversionsBoostModule.GetConversionsBoostParams
      )
    );
  };

  const handleClose = () => {
    onClose();
    dispatch(asinsConversionsBoostActions.exportActions.resetExport());
  };

  useEffect(() => {
    if (link) {
      window.open(link, '_blank');
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  if (!isOpen) return null;

  return (
    <Modal open={isOpen} onClose={handleClose} title="Export" maxWidth="sm">
      {!loading && !link && (
        <Formik
          initialValues={getInitialValues()}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ submitForm }) => (
            <Box sx={styles.form}>
              <FiltersForm />
              <Button sx={styles.btn} variant="contained" onClick={submitForm}>
                Export
              </Button>
            </Box>
          )}
        </Formik>
      )}
      {loading && (
        <Box sx={styles.loading}>
          <CircularProgress size={80} />
        </Box>
      )}
    </Modal>
  );
}
