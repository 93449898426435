import { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { LC_ONBOARDING_FINISHED } from 'common/constants/lc';
import { getRoutes } from 'common/constants/routes';
import { usePublisher } from 'common/hooks';
import { putDemoMode, putLanguage } from 'common/store/actions';
import { presetsActions } from 'common/store/actions/presets';
import { selectFiltersPresets, selectGlobalFilters, selectRootLanguage } from 'common/store/selectors';
import { Layout } from 'root/layout';
import { Onboarding } from 'root/onboarding';
import { OnboardingStart } from 'root/onboarding/onboarding-start';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { Styles } from 'common/types';
import { Box } from '@mui/material';
import { selectCombined } from 'modules/overview-v2/store/selectors';

interface StylesProps {
  isGainzActive: boolean;
}

const getStyles = ({ isGainzActive }: StylesProps): Styles => ({
  container: { ...(isGainzActive ? { display: 'none' } : {}) },
});

export function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const publisher = usePublisher();

  const [onboardingOpen, setOnboardingOpen] = useState<boolean>(false);
  const [tourRunning, setTourRunning] = useState<boolean>(false);

  const language = useSelector(selectRootLanguage);
  const globalFilters = useSelector(selectGlobalFilters);
  const presets = useSelector(selectFiltersPresets);
  const { isCombined } = useSelector(selectCombined);

  const isGainzActive = onboardingOpen && !isCombined;

  const styles = getStyles({ isGainzActive });

  const routes = getRoutes().filter(route => route.isAvailable);

  const handleChangeTourStart = (value: boolean) => {
    setTourRunning(value);
  };

  const handleTourStart = () => {
    setTourRunning(true);
  };

  const handleOnboardingClose = () => {
    setOnboardingOpen(false);
  };

  useEffect(() => {
    if (!language) return;
    i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    const lang = localStorage.getItem('lang');
    dispatch(putLanguage(lang || i18n.language));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n]);

  useEffect(() => {
    if (presets) return;

    dispatch(presetsActions.getFiltersPresets());
  }, [presets, dispatch]);

  useEffect(() => {
    if (!globalFilters?.publisher_id) return;
    dispatch(presetsActions.getTimeInfo(globalFilters.publisher_id));
    dispatch(publishersSettingsActions.getMainPublisherInfo(globalFilters.publisher_id.toString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilters.publisher_id]);

  useEffect(() => {
    if (!publisher || !publisher?.gainz_active) {
      setOnboardingOpen(false);
    } else {
      setOnboardingOpen(localStorage.getItem(LC_ONBOARDING_FINISHED) !== '1');
    }
  }, [publisher]);

  useEffect(() => {
    const isDemoModeLS = localStorage.getItem('demo') === '1';
    dispatch(putDemoMode(isDemoModeLS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense>
      <Layout>
        <Onboarding
          handleChangeTourStart={handleChangeTourStart}
          tourRunning={tourRunning}
          handleOnboardingGainzClose={handleOnboardingClose}
        >
          <>
            {onboardingOpen && !isCombined && (
              <OnboardingStart handleTourStart={handleTourStart} handleOnboardingClose={handleOnboardingClose} />
            )}
            <Box sx={styles.container}>
              {publisher && (
                <Routes>
                  {routes
                    .filter(route => route.isAvailable)
                    .map(route => (
                      <Route path={route.path} key={route.path} element={<route.component />} />
                    ))}
                </Routes>
              )}
            </Box>
          </>
        </Onboarding>
      </Layout>
    </Suspense>
  );
}
