import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import { pagesPerformanceFiltersActions } from 'modules/content-overview/store/actions';
import { selectPagesPerformanceFilters } from 'modules/content-overview/store/selectors';
import { ChangeEvent, ReactElement, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateOOSDates } from 'modules/content-overview/components/utils/generate-oos-dates';
import { AffiliateNetworkTypePicker } from 'common/ui/base-filters/affiliate-network-type-picker';
import { TrafficSource } from 'common/ui/base-filters/traffic-source';
import { Traffic } from 'common/ui/base-filters/types';
import { OosStatus } from '../oos-status/oos-status';
import { ContentTypeFilter } from '../content-type-filter';
import { SaturationPercentFilter } from '../saturation-percent-filter';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 1.5 },
  fieldContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  field: { width: '70%' },
  btnContainer: { display: 'flex', justifyContent: 'flex-end', gap: 1.5 },
};

const oosFilterOptions = [
  { title: 'All', value: 2 },
  { title: 'In-stock only', value: 1 },
  { title: 'Out of stock Only', value: 0 },
];

const exportLimitOptions = [
  { title: 'No limit', value: 0 },
  { title: '200', value: 200 },
  { title: '400', value: 400 },
];

interface Props {
  isOpen: boolean;
  handleApply: () => void;
  handleReset: () => void;
  onClose: () => void;
}

export function FiltersModal({ isOpen, onClose, handleApply, handleReset }: Props): ReactElement {
  const dispatch = useDispatch();

  const filters = useSelector(selectPagesPerformanceFilters);

  const oosDatesOptions = useMemo(() => {
    return generateOOSDates();
  }, []);

  const onExportLimitChange = (limit: number) => {
    dispatch(pagesPerformanceFiltersActions.changeExportLimit(limit));
  };

  const onInStockChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(pagesPerformanceFiltersActions.changeInStock(Number(e.target.value)));
    if (!e.target.value) {
      onExportLimitChange(200);
    }
  };

  const onOOSGTChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(pagesPerformanceFiltersActions.changeInStock(Number(0)));
    const changeRange = e.target.value as unknown as { from: string; to: string };
    dispatch(pagesPerformanceFiltersActions.changeOOSRange(changeRange));
  };

  const onAffiliateNetworkTypeChange = (id: number | string) => {
    dispatch(pagesPerformanceFiltersActions.changeAffiliateNetworkType(id));
  };

  const onTrafficSourceChange = (trafficSource: Traffic) => {
    dispatch(pagesPerformanceFiltersActions.changeTrafficSource(trafficSource));
  };

  return (
    <Modal open={isOpen} onClose={onClose} title="Filters" maxWidth="sm">
      <Box sx={styles.container}>
        <Box sx={styles.fieldContainer}>
          <Typography>Traffic Type</Typography>
          <TrafficSource trafficSource={filters.traffic_type} onChange={onTrafficSourceChange} />
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Network Type</Typography>
          <AffiliateNetworkTypePicker
            affiliateNetworkType={filters?.affiliate_network_type}
            onChange={onAffiliateNetworkTypeChange}
          />
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>ASINs</Typography>
          <TextField
            select
            color="primary"
            sx={styles.field}
            onChange={onInStockChange}
            value={filters.in_stock}
            size="small"
            label="ASINs"
          >
            {oosFilterOptions.map(source => (
              <MenuItem key={source.value} value={source.value}>
                {source.title}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Out of Stock for _</Typography>
          <TextField
            select
            color="primary"
            sx={styles.field}
            onChange={onOOSGTChange}
            value={filters.oos_range}
            size="small"
            label="Out of Stock for _"
          >
            {oosDatesOptions.map(date => (
              <MenuItem key={`${date.value.from}-${date.value.to}`} value={date.value}>
                {date.title}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Limit URLs</Typography>
          <TextField
            select
            color="primary"
            sx={styles.field}
            onChange={el => onExportLimitChange(+el.target.value)}
            value={filters.exportLimit}
            size="small"
            label="Limit URLs"
          >
            {exportLimitOptions.map(source => (
              <MenuItem key={source.value} value={source.value}>
                {source.title}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>OOS Status</Typography>
          <OosStatus />
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Content Type</Typography>
          <ContentTypeFilter />
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Saturation</Typography>
          <SaturationPercentFilter />
        </Box>
        <Box sx={styles.btnContainer}>
          <Button variant="outlined" onClick={handleReset}>
            Reset
          </Button>
          <Button variant="contained" onClick={handleApply}>
            Apply
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
