import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import { accessService } from 'common/services/access-service';
import { accessActions } from 'common/store/actions/access/actions';
import { statisticsApi } from 'modules/overview-v2/services/statistics.service';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { accessActionTypes } from 'common/store/actions/access/action-types';
import { overviewActions, overviewActionTypes as T } from '../actions';

function* getAllStatisticsSaga({ payload }: Action) {
  try {
    const { data } = yield call(statisticsApi.getAllStatistics, payload);

    yield put(overviewActions.getAllStatisticsSuccess(data.statistics));
  } catch (err) {
    yield put(overviewActions.getAllStatisticsError(err as Error));
  }
}

function* getCorrelationStatisticsSaga({ payload }: Action) {
  try {
    const { data } = yield call(statisticsApi.getCorrelationStatistics, payload);

    yield put(overviewActions.getCorrelationStatisticsSuccess(data.statistics));
  } catch (err) {
    yield put(overviewActions.getCorrelationStatisticsError(err as Error));
  }
}

function* getDevicesStatisticsSaga({ payload }: Action) {
  try {
    const { data } = yield call(statisticsApi.getDevicesStatistics, payload);

    yield put(overviewActions.getDevicesStatisticsSuccess(data.devices));
  } catch (err) {
    yield put(overviewActions.getDevicesStatisticsError(err as Error));
  }
}

function* getTopAsinsSaga({ payload }: Action) {
  try {
    const { data } = yield call(statisticsApi.getTopAsins, payload);
    yield put(overviewActions.getTopAsinsSuccess(data.performers));
  } catch (err) {
    yield put(overviewActions.getTopPerformersError(err as Error));
  }
}

function* getTopCategoriesSaga({ payload }: Action) {
  try {
    const { data } = yield call(statisticsApi.getTopCategories, payload);
    yield put(overviewActions.getTopCategoriesSuccess(data.performers));
  } catch (err) {
    yield put(overviewActions.getTopPerformersError(err as Error));
  }
}

function* getTop10PagesSaga({ payload }: Action) {
  try {
    const { data } = yield call(statisticsApi.getTop10Pages, payload);

    yield put(overviewActions.getTop10PagesSuccess(data.top_charts));
  } catch (err) {
    yield put(overviewActions.getTop10PagesError(err as Error));
  }
}

function* getTop10ExportSaga({ payload }: Action) {
  try {
    const { data } = yield call(statisticsApi.getTop10Export, payload);

    yield put(overviewActions.getTop10ExportSuccess(data));
  } catch (error) {
    yield put(overviewActions.getTop10ExportError(error as Error));
  }
}

function* getProductsStats({ payload }: Action) {
  try {
    const [{ data: productsStatsData }, { data: recommendedAsinsData }] = yield all([
      call(statisticsApi.getProductsStats, payload),
      call(statisticsApi.getRecommendedAsins, payload),
    ]);

    yield put(
      overviewActions.getProductsStatsSuccess({ ...productsStatsData, recommended_asins: recommendedAsinsData.total })
    );
  } catch (err) {
    yield put(overviewActions.getProductsStatsError(err as Error));
  }
}

function* getHasAccessSaga({ payload }: Action) {
  try {
    const { data } = yield call(accessService.getHasAccess, payload);

    yield put(accessActions.getAccessSuccess(data));
  } catch (error) {
    yield put(accessActions.getAccessError(error as Error));
  }
}

export function* overviewSaga(): SagaIterator {
  yield all([
    takeLatest(T.GET_ALL_STATISTICS, getAllStatisticsSaga),
    takeLatest(T.GET_CORRELATION_STATISTICS, getCorrelationStatisticsSaga),
    takeLatest(T.GET_DEVICES_STATISTICS, getDevicesStatisticsSaga),
    takeLatest(T.GET_TOP_PERFORMERS, getTopAsinsSaga),
    takeLatest(T.GET_TOP_PERFORMERS, getTopCategoriesSaga),
    takeLatest(T.GET_TOP10_PAGES, getTop10PagesSaga),
    takeLatest(T.GET_TOP10_EXPORT, getTop10ExportSaga),
    takeLatest(T.GET_PRODUCTS_STATS, getProductsStats),
    takeLatest(accessActionTypes.GET_ACCESS, getHasAccessSaga),
  ]);
}
