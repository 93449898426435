import moment from 'moment';

export const currencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const checkDate = (date: string) => {
  if (!date) return 'Pending';
  return moment(date).format('D MMM YYYY');
};

export const generateRows = (conversions: Conversions.Conv.ConversionData[]) => {
  const rows: Conversions.Table.TableRow[] = [];

  conversions.forEach(conversion => {
    rows.push({
      pageUrl: conversion.publisher_url,
      timeOfVisit: moment(conversion.track_date).format('D MMM YYYY LT'),
      trackingId: conversion.clid,
      tag: conversion.tag,
      asin: conversion.asin,
      timeOfShipment: moment(conversion.shipment_time).format('D MMM YYYY LT'),
      dateOfUpload: conversion.order_con_submit_at
        ? checkDate(conversion.order_con_submit_at)
        : checkDate(conversion.ship_con_submit_at),
      convAdFees: currencyFormatter.format(Number(conversion.ad_fees)),
      click_url: conversion.click_url,
    });
  });

  return rows;
};
