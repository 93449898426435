import { combineReducers } from 'redux';
import table from './table';
import top10 from './top10';
import exportReducer from './export';

export const revenueByPageReducers = combineReducers({
  export: exportReducer,
  table,
  top10,
});
