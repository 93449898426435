interface CarrickTips {
  overview: {
    dashboard: string;

    cards: {
      viewsOnPage: string;
      clicks: string;
      conversion: string;
      adFees: string;
      gmv: string;
      asins: string;
      saturation: string;
    };
    topPerformers: {
      asins: string;
      categories: string;
    };
  };
  trackingOverview: {
    revenueByPage: {
      topTenPages: string;
    };
    conversionsPage: {
      totalConversions: string;

      download: string;
    };
  };
  contentOverview: {
    productPlacement: {
      articles: string;
      articlesWithAsin: string;
      articlesWithOosAsin: string;
      asinsInArticles: string;
      oosAsins: string;
    };
  };
}

export const carrickTips: CarrickTips = {
  overview: {
    dashboard:
      'The overview dashboard displays the summary of your article page(s) statistics based on clicks, conversion, ad fees and GMV.',
    cards: {
      viewsOnPage: 'This is the views on the publisher pages from different traffic sources.',
      clicks:
        'The clicks card displays the clicks to retailer sites from selected traffic sources. For example, selecting amazon as a network type and google as a traffic source surfaces clicks of ads (either paid or organic) tracked by the carrick JS from your website to amazon.',
      conversion:
        'The conversion card displays the rate at which the clicks on your articles are converting to actual sales on the retailer sites.',
      adFees:
        'The ad fees card displays the total affiliate fees accumulated from driving sales on the retailer sites.',
      gmv: 'The GMV cards show the total sales revenue generated from orders on publisher  article ASINs and the ASIN family.',
      asins: 'This is the total number of Gainz ASINs which are currently in your articles.',
      saturation: 'This percentage is the ratio of Gainz ASINs to the total number of ASINs within all articles.',
    },
    topPerformers: {
      asins:
        'The top performing ASIN table on the overview page filters and displays the ASINs with high conversion from all the articles for a given publisher.',
      categories: 'The top performing category table shows the categories on amazon driving higher revenue.',
    },
  },
  trackingOverview: {
    revenueByPage: {
      topTenPages: 'Displays top ten pages by revenue',
    },
    conversionsPage: {
      totalConversions:
        'The conversion page displays the conversions of traffic from google to specific retailer sites as well as the data being sent to google.',
      download:
        'Download the reports that are provided by Amazon directly from carrick as a CSV. This includes the "earnings report", "order report" and the "tracking report".',
    },
  },
  contentOverview: {
    productPlacement: {
      articles: 'This is the total number of articles identified by crawling the publisher website.',
      articlesWithAsin: 'This highlights the articles with at least one ASIN.',
      articlesWithOosAsin: 'This highlights the articles with at least one out of stock ASIN.',
      asinsInArticles: 'This is the total number of ASINs identified in the articles',
      oosAsins: 'This is the fraction of out of stock ASINs contained in all the articles.',
    },
  },
};
