import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { Styles } from 'common/types';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { attributionActions } from 'modules/attribution/store/actions';
import {
  selectBrandsAttrData,
  selectBrandsAttrLimitPageAmount,
  selectBrandsAttrLoading,
} from 'modules/attribution/store/selectors';
import { ReactElement, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AttributionRow } from './attribution-row';

const getStyles = (): Styles => ({
  table: { marginTop: 2, paddingBottom: '10px' },
});

export function BrandsAttributionTable(): ReactElement {
  const dispatch = useDispatch();
  const styles = getStyles();

  const { limit, page, amount } = useSelector(selectBrandsAttrLimitPageAmount);

  const data = useSelector(selectBrandsAttrData);
  const loading = useSelector(selectBrandsAttrLoading);

  const handlePageChange = (_e: MouseEvent<HTMLButtonElement>, page: number) => {
    dispatch(attributionActions.changeAmazonAttrPage(page));
  };

  return (
    <>
      <TableContainerWithLoading sx={styles.table} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Brand ID</TableCell>
              <TableCell>Attribution publisher</TableCell>
              <TableCell>Tags</TableCell>
              <TableCell>Publisher name</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(row => (
              <AttributionRow key={row.id} attr={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <TablePagination
        component="div"
        count={amount}
        rowsPerPage={limit}
        rowsPerPageOptions={[20]}
        page={page}
        onPageChange={handlePageChange}
      />
    </>
  );
}
