import { ApiService } from 'common/services';

class StatisticsApiService extends ApiService {
  getPublishers = (payload?: { name?: string; search?: string }) => {
    return this.get('/api/publishers', { params: payload });
  };

  createPublisher = (publisher: PublishersSettingsModule.PublisherForm) => {
    return this.post('/api/publishers', publisher);
  };

  editPublisher = (publisher: PublishersSettingsModule.PublisherForm, id: number) => {
    return this.put(`/api/publishers/${id}`, publisher);
  };

  deletePublisher = (id: number) => {
    return this.delete(`/api/publishers/${id}`);
  };

  getPublishersParents = () => {
    return this.get('/api/publishers-parents');
  };

  addPublishersParents = (payload: string) => {
    return this.post('/api/publishers-parents', { name: payload });
  };

  deletePublishersParents = (payload: number) => {
    return this.delete(`/api/publishers-parents/${payload}`);
  };

  getPublisherBlockedBrands = (publisherID: number) => {
    return this.get(`/api/publishers/${publisherID}/blocked-brands`);
  };

  blockPublisherBrand = (payload: PublishersSettingsModule.BlockPublisherBrandPayload) => {
    const { publisher_id, brand_ids } = payload;

    return this.post(`/api/publishers/${publisher_id}/blocked-brands`, { brand_ids });
  };

  unblockPublisherBrand = (payload: PublishersSettingsModule.BlockPublisherBrandPayload) => {
    const { publisher_id, brand_ids } = payload;

    return this.delete(`/api/publishers/${publisher_id}/blocked-brands`, { brand_ids });
  };
}

export const publishersSettingsApi = new StatisticsApiService(process.env.REACT_APP_API_URL);
