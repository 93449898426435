import { getFiltersActionTypes } from 'common/store/actions/filters';

const URLS_TABLE_MODULE = '[tags:table]';

const GET_URLS = `${URLS_TABLE_MODULE} GET_URLS`;
const GET_URLS_SUCCESS = `${URLS_TABLE_MODULE} GET_URLS_SUCCESS`;
const GET_URLS_ERROR = `${URLS_TABLE_MODULE} GET_URLS_ERROR`;

const CHANGE_URLS_PAGE = `${URLS_TABLE_MODULE} CHANGE_TAGS_PAGE`;

export const tagsActionTypes = {
  GET_URLS,
  GET_URLS_SUCCESS,
  GET_URLS_ERROR,
  CHANGE_URLS_PAGE,
};

export const urlsFiltersActionTypes = getFiltersActionTypes(URLS_TABLE_MODULE);
