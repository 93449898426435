import { SagaIterator } from '@redux-saga/types';
import toast from 'react-hot-toast';
import { Action } from 'common/interfaces/root';
import { publishersSettingsApi } from 'modules/publishers-settings/services/publishers-settings.service';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  publishersParentsActions,
  publishersParentsActionTypes,
  publishersSettingsActions,
  publishersSettingsActionTypes as T,
} from '../actions';

function* getPublishersSaga({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(publishersSettingsApi.getPublishers, payload);

    yield put(publishersSettingsActions.getPublishersSuccess(data.publishers));
  } catch (err) {
    yield put(publishersSettingsActions.getPublishersError(err as Error));
  }
}

function* createPublisherSaga({ payload }: Action): SagaIterator {
  try {
    yield call(publishersSettingsApi.createPublisher, payload);
    yield put(publishersSettingsActions.getPublishers());
    yield put(publishersSettingsActions.createPublisherSuccess());
  } catch (err) {
    toast.error('Error while creating publisher!');
    yield put(publishersSettingsActions.createPublisherError(err as Error));
  }
}

function* editPublisherSaga({ payload }: Action): SagaIterator {
  try {
    yield call(publishersSettingsApi.editPublisher, payload.publisher, payload.id);
    yield put(publishersSettingsActions.getPublishers());
    yield put(publishersSettingsActions.editPublisherSuccess('Success'));
  } catch (err) {
    toast.error('Error while editing publisher!');
    yield put(publishersSettingsActions.editPublisherError(err as Error));
  }
}

function* deletePublisherSaga({ payload }: Action): SagaIterator {
  try {
    yield call(publishersSettingsApi.deletePublisher, payload);
    yield put(publishersSettingsActions.getPublishers());
    yield put(publishersSettingsActions.deletePublisherSuccess());
  } catch (err) {
    toast.error('Error while deleting publisher!');
    yield put(publishersSettingsActions.deletePublisherError(err as Error));
  }
}

function* getPublishersParents(): SagaIterator {
  try {
    const { data } = yield call(publishersSettingsApi.getPublishersParents);
    yield put(publishersParentsActions.getSuccess(data.publishers_parents, data.publishers_parents_count));
  } catch (err) {
    yield put(publishersParentsActions.getError(err as Error));
  }
}

function* addPublishersParents({ payload, meta }: Action): SagaIterator {
  try {
    const { data } = yield call(publishersSettingsApi.addPublishersParents, payload.name);
    if (data?.publishers_parents?.id) {
      meta?.onSuccess({ ...payload.publisher, publishers_parents_id: data?.publishers_parents?.id });
      meta.onRefresh();
    }
  } catch (err) {
    toast.error('Error while adding publisher parent!');
  }
}

function* deletePublishersParents({ payload, meta }: Action): SagaIterator {
  try {
    yield call(publishersSettingsApi.deletePublishersParents, payload);
    meta?.onSuccess();
  } catch (err) {
    toast.error('Error while deleting publisher parent!');
  }
}

function* getPublisherBlockedBrands({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(publishersSettingsApi.getPublisherBlockedBrands, payload);
    yield put(publishersSettingsActions.getPublisherBlockedBrandsSuccess(data));
  } catch (err) {
    toast.error('Failed fetch publisher blocked brands list');
    yield put(publishersSettingsActions.getPublisherBlockedBrandsError(err as Error));
  }
}

function* blockPublisherBrand({ payload }: Action): SagaIterator {
  try {
    yield call(publishersSettingsApi.blockPublisherBrand, payload);
    toast.success('Brand blocked from publisher');
  } catch (err) {
    toast.error('Failed to block brand');
  }
}
function* unblockPublisherBrand({ payload }: Action): SagaIterator {
  try {
    yield call(publishersSettingsApi.unblockPublisherBrand, payload);
    toast.success('Brand unblocked from publisher');
  } catch (err) {
    toast.error('Failed to block brand');
  }
}

export function* publishersSettingsSaga(): SagaIterator {
  yield all([
    takeLatest(T.GET_PUBLISHERS, getPublishersSaga),
    takeEvery(T.CREATE_PUBLISHER, createPublisherSaga),
    takeEvery(T.EDIT_PUBLISHER, editPublisherSaga),
    takeEvery(T.DELETE_PUBLISHER, deletePublisherSaga),
    takeEvery(publishersParentsActionTypes.GET, getPublishersParents),
    takeEvery(T.ADD_PUBLISHERS_PARENTS, addPublishersParents),
    takeEvery(T.DELETE_PUBLISHERS_PARENTS, deletePublishersParents),
    takeEvery(T.GET_PUBLISHER_BLOCKED_BRANDS, getPublisherBlockedBrands),
    takeEvery(T.BLOCK_PUBLISHER_BRAND, blockPublisherBrand),
    takeEvery(T.UNBLOCK_PUBLISHER_BRAND, unblockPublisherBrand),
  ]);
}
