import { ReactElement } from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { Styles } from 'common/types';

interface StylesProps {
  color: string;
  backgroundColor: string;
}

const getStyles = ({ backgroundColor, color }: StylesProps): Styles => ({
  table: { marginTop: 0 },
  cell: { color: '#21425480', fontSize: '10px' },
  usedCircle: {
    width: '8px',
    height: '8px',
    backgroundColor: '#62D294',
    borderRadius: '50%',
  },
  status: {
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor,
    color,
    fontSize: '12px',
    borderRadius: '20px',
  },
});

const statusColor = (value: string): { color: string; backgroundColor: string } => {
  switch (value) {
    case 'pending':
      return { backgroundColor: '#D6645C', color: '#ffffff' };
    case 'error':
      return { backgroundColor: '#fc4d67', color: '#ffffff' };
    case 'finished':
      return { backgroundColor: '#CEE6DB', color: '#ffffff' };
    default:
      return { backgroundColor: '#D6645C', color: '#ffffff' };
  }
};

interface Props {
  domain: string;
  start_date: string;
  end_date: string;
  status: string;
}

export function CrawlingRow({ domain, start_date, end_date, status }: Props): ReactElement {
  const styles = getStyles({ color: statusColor(status).color, backgroundColor: statusColor(status).backgroundColor });

  return (
    <TableRow>
      <TableCell>
        <Typography sx={styles.link}>{domain}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{start_date}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{end_date}</Typography>
      </TableCell>
      <TableCell>
        <Typography sx={styles.status}>{status}</Typography>
      </TableCell>
    </TableRow>
  );
}
