import { getDataListActionTypes } from 'common/store/actions/data-list';
import { getFiltersActionTypes } from 'common/store/actions/filters';

const ATTRIBUTION_AMAZON_MODULE = '[attribution-amazon]';
const ATTRIBUTION_AMAZON_TABLE_MODULE = '[attribution-amazon:table]';
const ATTRIBUTION_AMAZON_BRANDS_TABLE_MODULE = '[attribution-amazon-brands:table]';

const GET_AMAZON_ATTRIBUTION = `${ATTRIBUTION_AMAZON_TABLE_MODULE} GET_AMAZON_ATTRIBUTION`;
const GET_AMAZON_ATTRIBUTION_SUCCESS = `${ATTRIBUTION_AMAZON_TABLE_MODULE} GET_AMAZON_ATTRIBUTION_SUCCESS`;
const GET_AMAZON_ATTRIBUTION_ERROR = `${ATTRIBUTION_AMAZON_TABLE_MODULE} GET_AMAZON_ATTRIBUTION_ERROR`;

const CHANGE_AMAZON_ATTR_PAGE = `${ATTRIBUTION_AMAZON_TABLE_MODULE} CHANGE_AMAZON_ATTR_PAGE`;

const POST_CSV_FOR_BRANDS_AMAZON_ATTRIBUTION = `${ATTRIBUTION_AMAZON_TABLE_MODULE} POST_CSV_FOR_BRANDS_AMAZON_ATTRIBUTION`;
const POST_CSV_FOR_BRANDS_AMAZON_ATTRIBUTION_SUCCESS = `${ATTRIBUTION_AMAZON_TABLE_MODULE} POST_CSV_FOR_BRANDS_AMAZON_ATTRIBUTION_SUCCESS`;
const POST_CSV_FOR_BRANDS_AMAZON_ATTRIBUTION_ERROR = `${ATTRIBUTION_AMAZON_TABLE_MODULE} POST_CSV_FOR_BRANDS_AMAZON_ATTRIBUTION_ERROR`;

const POST_CSV_FOR_ASINS_AMAZON_ATTRIBUTION = `${ATTRIBUTION_AMAZON_TABLE_MODULE} POST_CSV_FOR_ASINS_AMAZON_ATTRIBUTION`;
const POST_CSV_FOR_ASINS_AMAZON_ATTRIBUTION_SUCCESS = `${ATTRIBUTION_AMAZON_TABLE_MODULE} POST_CSV_FOR_ASINS_AMAZON_ATTRIBUTION_SUCCESS`;
const POST_CSV_FOR_ASINS_AMAZON_ATTRIBUTION_ERROR = `${ATTRIBUTION_AMAZON_TABLE_MODULE} POST_CSV_FOR_ASINS_AMAZON_ATTRIBUTION_ERROR`;

const DELETE_AMAZON_ATTRIBUTION_ITEM = `${ATTRIBUTION_AMAZON_TABLE_MODULE} DELETE_AMAZON_ATTRIBUTION_ITEM`;
const DELETE_AMAZON_ATTRIBUTION_ITEM_SUCCESS = `${ATTRIBUTION_AMAZON_TABLE_MODULE} DELETE_AMAZON_ATTRIBUTION_ITEM_SUCCESS`;
const DELETE_AMAZON_ATTRIBUTION_ITEM_ERROR = `${ATTRIBUTION_AMAZON_TABLE_MODULE} DELETE_AMAZON_ATTRIBUTION_ITEM_ERROR`;

export const brandsAttributionActionTypes = getDataListActionTypes(ATTRIBUTION_AMAZON_BRANDS_TABLE_MODULE);
export const attributionFiltersActionTypes = getFiltersActionTypes(ATTRIBUTION_AMAZON_MODULE);

export const attributionActionTypes = {
  GET_AMAZON_ATTRIBUTION,
  GET_AMAZON_ATTRIBUTION_SUCCESS,
  GET_AMAZON_ATTRIBUTION_ERROR,
  CHANGE_AMAZON_ATTR_PAGE,
  POST_CSV_FOR_BRANDS_AMAZON_ATTRIBUTION,
  POST_CSV_FOR_BRANDS_AMAZON_ATTRIBUTION_SUCCESS,
  POST_CSV_FOR_BRANDS_AMAZON_ATTRIBUTION_ERROR,
  DELETE_AMAZON_ATTRIBUTION_ITEM,
  DELETE_AMAZON_ATTRIBUTION_ITEM_SUCCESS,
  DELETE_AMAZON_ATTRIBUTION_ITEM_ERROR,
  POST_CSV_FOR_ASINS_AMAZON_ATTRIBUTION,
  POST_CSV_FOR_ASINS_AMAZON_ATTRIBUTION_SUCCESS,
  POST_CSV_FOR_ASINS_AMAZON_ATTRIBUTION_ERROR,
};
