import { getDataListActionTypes } from 'common/store/actions/data-list';
import { getFiltersActionTypes } from 'common/store/actions/filters';

const MANANAGEMENT_MODULE = '[content-overview:management]';
const SITEMAPS_MODULE = '[content-overview:sitemaps]';
const PAGES_MODULE = '[content-overview:pages]';
const PRODUCT_REPLACEMENTS_MODULE = '[content-overview:product-replacements]';
const PAGE_PERFORMANCE_MODULE = '[content-overview:page-performance]';
const PRODUCT_REPLACEMENT_RECONEBDATIONS_MODULE = '[content-overview:product-replacement-recomendations]';
const REPORT_MODULE = '[content-overview:report]';
const STATS_MODULE = '[content-overview:stats]';
const CRAWLING_MODULE = '[content-overview:crawling]';
const RECOMMENDATIONS_MODULE = '[content-overview:recommendations]';
const RE_SCRAP_MODULE = '[content-overview:re-scrap]';
const SCHEDULE_MODULE = '[content-overview:schedule]';

const CREATE_SITEMAPS = `${SITEMAPS_MODULE} CREATE_SITEMAPS`;
const CREATE_SITEMAPS_SUCCESS = `${SITEMAPS_MODULE} CREATE_SITEMAPS_SUCCESS`;
const CREATE_SITEMAPS_ERROR = `${SITEMAPS_MODULE} CREATE_SITEMAPS_ERROR`;

const DELETE_SITEMAPS = `${SITEMAPS_MODULE} DELETE_SITEMAPS`;
const DELETE_SITEMAPS_SUCCESS = `${SITEMAPS_MODULE} DELETE_SITEMAPS_SUCCESS`;
const DELETE_SITEMAPS_ERROR = `${SITEMAPS_MODULE} DELETE_SITEMAPS_ERROR`;

const CREATE_PAGES = `${PAGES_MODULE} CREATE_PAGES`;
const CREATE_PAGES_SUCCESS = `${PAGES_MODULE} CREATE_PAGES_SUCCESS`;
const CREATE_PAGES_ERROR = `${PAGES_MODULE} CREATE_PAGES_ERROR`;

const DELETE_URL = `${PAGES_MODULE} DELETE_URL`;
const DELETE_FILTERED_URLS = `${PAGES_MODULE} DELETE_FILTERED_URLS`;
const DELETE_URL_SUCCESS = `${PAGES_MODULE} DELETE_URL_SUCCESS`;
const DELETE_URL_ERROR = `${PAGES_MODULE} DELETE_URL_ERROR`;

const IMPORT_PAGES = `${PAGES_MODULE} IMPORT_PAGES`;
const IMPORT_PAGES_SUCCESS = `${PAGES_MODULE} IMPORT_PAGES_SUCCESS`;
const IMPORT_PAGES_ERROR = `${PAGES_MODULE} IMPORT_PAGES_ERROR`;

const GENERATE_REPORT = `${REPORT_MODULE} GENERATE_REPORT`;
const GENERATE_REPORT_SUCCESS = `${REPORT_MODULE} GENERATE_REPORT_SUCCESS`;
const GENERATE_REPORT_ERROR = `${REPORT_MODULE} GENERATE_REPORT_ERROR`;

const OPEN_REPORT_LINK = `${REPORT_MODULE} OPEN_REPORT_LINK`;
const SUCCESS_REPORT_LINK = `${REPORT_MODULE} SUCCESS_REPORT_LINK`;
const REMOVE_EXECUTION_ID = `${REPORT_MODULE} REMOVE_EXECUTION_ID`;

const GET_PRODUCTS_STATS = `${STATS_MODULE} GET_PRODUCTS_STATS`;
const GET_PRODUCTS_STATS_SUCCESS = `${STATS_MODULE} GET_PRODUCTS_STATS_SUCCESS`;
const GET_PRODUCTS_STATS_ERROR = `${STATS_MODULE} GET_PRODUCTS_STATS_ERROR`;

const GET_CRAWLING_STATUS = `${CRAWLING_MODULE} GET_CRAWLING_STATUS`;
const GET_CRAWLING_STATUS_SUCCESS = `${CRAWLING_MODULE} GET_CRAWLING_STATUS_SUCCESS`;
const GET_CRAWLING_STATUS_ERROR = `${CRAWLING_MODULE} GET_CRAWLING_STATUS_ERROR`;

const POST_CRAWLING = `${CRAWLING_MODULE} POST_CRAWLING`;
const POST_CRAWLING_SUCCESS = `${CRAWLING_MODULE} POST_CRAWLING_SUCCESS`;
const POST_CRAWLING_ERROR = `${CRAWLING_MODULE} POST_CRAWLING_ERROR`;

const SYNC_PAGE = `${CRAWLING_MODULE} SYNC_PAGE`;
const SYNC_PAGE_SUCCESS = `${CRAWLING_MODULE} SYNC_PAGE_SUCCESS`;
const SYNC_PAGE_ERROR = `${CRAWLING_MODULE} SYNC_PAGE_ERROR`;

const GET_PRODUCT_RECOMMENDATIONS = `${RECOMMENDATIONS_MODULE} GET_PRODUCT_RECOMMENDATIONS`;
const GET_PRODUCT_RECOMMENDATIONS_SUCCESS = `${RECOMMENDATIONS_MODULE} GET_PRODUCT_RECOMMENDATIONS_SUCCESS`;
const GET_PRODUCT_RECOMMENDATIONS_ERROR = `${RECOMMENDATIONS_MODULE} GET_PRODUCT_RECOMMENDATIONS_ERROR`;

const POST_PRODUCT_RECOMMENDATIONS = `${RECOMMENDATIONS_MODULE} POST_PRODUCT_RECOMMENDATIONS`;
const POST_PRODUCT_RECOMMENDATIONS_SUCCESS = `${RECOMMENDATIONS_MODULE} POST_PRODUCT_RECOMMENDATIONS_SUCCESS`;
const POST_PRODUCT_RECOMMENDATIONS_ERROR = `${RECOMMENDATIONS_MODULE} POST_PRODUCT_RECOMMENDATIONS_ERROR`;

const PATCH_PRODUCT_RECOMMENDATIONS = `${RECOMMENDATIONS_MODULE} PATCH_PRODUCT_RECOMMENDATIONS`;
const PATCH_PRODUCT_RECOMMENDATIONS_SUCCESS = `${RECOMMENDATIONS_MODULE} PATCH_PRODUCT_RECOMMENDATIONS_SUCCESS`;
const PATCH_PRODUCT_RECOMMENDATIONS_ERROR = `${RECOMMENDATIONS_MODULE} PATCH_PRODUCT_RECOMMENDATIONS_ERROR`;

const POST_RESCRAP_TAB = `${RE_SCRAP_MODULE} POST_RESCRAP_TAB`;

const POST_URLS_RESCRAP = `${RE_SCRAP_MODULE} POST_URLS_RESCRAP`;

const GET_TAB_SCHEDULE = `${SCHEDULE_MODULE} GET_TAB_SCHEDULE`;
const PATCH_UPDATE_SCHEDULE = `${SCHEDULE_MODULE} POST_UPDATE_SCHEDULE`;
const SET_CRAWLER_SCHEDULE = `${SCHEDULE_MODULE} SET_CRAWLER_SCHEDULE`;
const SET_URLS_SCRAPPING_SCHEDULE = `${SCHEDULE_MODULE} SET_URLS_SCRAPPING_SCHEDULE`;
const SET_SITEMAP_SCRAPPING_SCHEDULE = `${SCHEDULE_MODULE} SET_SITEMAP_SCRAPPING_SCHEDULE`;

const SET_PAGE_PERFORMANCE_ACTIVE_ARTICLE = `${PAGE_PERFORMANCE_MODULE} SET_PAGE_PERFORMANCE_ACTIVE_ARTICLE`;
const SET_PAGE_PERFORMANCE_ACTIVE_ARTICLE_MODAL_VISIBLE = `${PAGE_PERFORMANCE_MODULE} SET_PAGE_PERFORMANCE_ACTIVE_ARTICLE_MODAL_VISIBLE`;

const REQUEST_TO_SWAP_OOS = `${PAGE_PERFORMANCE_MODULE} REQUEST_TO_SWAP_OOS`;
const UPDATE_PAGE_PERFORMANCE_PAGES = `${PAGE_PERFORMANCE_MODULE} UPDATE_PAGE_PERFORMANCE_PAGES`;
const CHANGE_DATES_TO_COMPARE = `${PAGE_PERFORMANCE_MODULE} CHANGE_DATES_TO_COMPARE`;

export const productReplacementsActionTypes = getDataListActionTypes(PRODUCT_REPLACEMENTS_MODULE);
export const pagesPerformanceActionTypes = getDataListActionTypes(PAGE_PERFORMANCE_MODULE);
export const pagesPerformanceFiltersActionTypes = getFiltersActionTypes(PAGE_PERFORMANCE_MODULE);
export const replacementRecomendationsActionTypes = getDataListActionTypes(PRODUCT_REPLACEMENT_RECONEBDATIONS_MODULE);
export const replacementsFiltersActionTypes = getFiltersActionTypes(PRODUCT_REPLACEMENTS_MODULE);
export const sitemapsActionsTypes = getDataListActionTypes(SITEMAPS_MODULE);
export const pagesActionTypes = getDataListActionTypes(PAGES_MODULE);
export const crawlingActionTypes = getDataListActionTypes(CRAWLING_MODULE);
export const managementFiltersActionTypes = getFiltersActionTypes(MANANAGEMENT_MODULE);

export const contentOverviewActionTypes = {
  CREATE_SITEMAPS,
  CREATE_SITEMAPS_SUCCESS,
  CREATE_SITEMAPS_ERROR,
  DELETE_SITEMAPS,
  DELETE_SITEMAPS_SUCCESS,
  DELETE_SITEMAPS_ERROR,
  CREATE_PAGES,
  CREATE_PAGES_SUCCESS,
  CREATE_PAGES_ERROR,
  DELETE_URL,
  DELETE_FILTERED_URLS,
  DELETE_URL_SUCCESS,
  DELETE_URL_ERROR,
  IMPORT_PAGES,
  IMPORT_PAGES_SUCCESS,
  IMPORT_PAGES_ERROR,
  GENERATE_REPORT,
  GENERATE_REPORT_SUCCESS,
  GENERATE_REPORT_ERROR,
  OPEN_REPORT_LINK,
  REMOVE_EXECUTION_ID,
  SUCCESS_REPORT_LINK,
  GET_PRODUCTS_STATS,
  GET_PRODUCTS_STATS_SUCCESS,
  GET_PRODUCTS_STATS_ERROR,
  GET_CRAWLING_STATUS,
  GET_CRAWLING_STATUS_SUCCESS,
  GET_CRAWLING_STATUS_ERROR,
  POST_CRAWLING,
  POST_CRAWLING_SUCCESS,
  POST_CRAWLING_ERROR,
  SYNC_PAGE,
  SYNC_PAGE_SUCCESS,
  SYNC_PAGE_ERROR,
  GET_PRODUCT_RECOMMENDATIONS,
  GET_PRODUCT_RECOMMENDATIONS_SUCCESS,
  GET_PRODUCT_RECOMMENDATIONS_ERROR,
  POST_PRODUCT_RECOMMENDATIONS,
  POST_PRODUCT_RECOMMENDATIONS_SUCCESS,
  POST_PRODUCT_RECOMMENDATIONS_ERROR,
  PATCH_PRODUCT_RECOMMENDATIONS,
  PATCH_PRODUCT_RECOMMENDATIONS_SUCCESS,
  PATCH_PRODUCT_RECOMMENDATIONS_ERROR,
  POST_RESCRAP_TAB,
  POST_URLS_RESCRAP,
  PATCH_UPDATE_SCHEDULE,
  GET_TAB_SCHEDULE,
  SET_CRAWLER_SCHEDULE,
  SET_URLS_SCRAPPING_SCHEDULE,
  SET_SITEMAP_SCRAPPING_SCHEDULE,
  SET_PAGE_PERFORMANCE_ACTIVE_ARTICLE,
  SET_PAGE_PERFORMANCE_ACTIVE_ARTICLE_MODAL_VISIBLE,
  REQUEST_TO_SWAP_OOS,
  UPDATE_PAGE_PERFORMANCE_PAGES,
  CHANGE_DATES_TO_COMPARE,
};
