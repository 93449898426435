import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Styles } from 'common/types';
import { Box, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { selectPublisherInfo, selectPublisherInfoLoading } from 'modules/settings/store/selectors';
import { selectGlobalFilters, selectRootDemo } from 'common/store/selectors';
import HeaderImg from 'common/svg/header-img.png';
import { Portal } from 'common/ui/portal';
import { PublisherSettingsList } from './publisher-settings-form';

const getStyles = ({ isDemoMode }: { isDemoMode: boolean }): Styles => ({
  headTitle: {
    color: '#ffffff',
    fontWeight: 600,
  },
  head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    background: `url(${HeaderImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#4288F0',
    padding: '28px',
    boxSizing: 'border-box',
    color: '#ffffff',
    fontWeight: 600,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
  },
  headerArrow: {
    marginRight: '5px',
  },
  headerTitle: {
    filter: isDemoMode ? 'blur(10px)' : 0,
  },
});

export function PublisherSettings(): ReactElement {
  const dispatch = useDispatch();

  const publisher = useSelector(selectPublisherInfo);
  const loading = useSelector(selectPublisherInfoLoading);
  const filters = useSelector(selectGlobalFilters);
  const isDemoMode = useSelector(selectRootDemo);

  const styles = getStyles({ isDemoMode });

  const id = String(filters?.publisher_id);

  useEffect(() => {
    dispatch(publishersSettingsActions.getMainPublisherInfo(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <>
      <Portal id="header-page">
        <Box sx={styles.head}>
          <KeyboardArrowLeftIcon color="inherit" sx={styles.headerArrow} />
          <Typography sx={styles.headTitle}>{publisher?.name && !loading ? publisher?.name : 'Publisher'}</Typography>
        </Box>
      </Portal>
      <PublisherSettingsList />
    </>
  );
}
