import { Box, CircularProgress, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useInView } from 'common/hooks';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { IProduct } from 'common/ui/product/types';
import { contentOverviewApi } from 'modules/content-overview/services/content-overview.service';
import { contentOverviewActions } from 'modules/content-overview/store/actions';
import {
  selectReplacementRecomendations,
  selectReplacementRecomendationsLoading,
} from 'modules/content-overview/store/selectors';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Product } from '../product';
import { ProductSuggestion } from '../product-suggestion';
import { RecommendationsModal } from '../recommendations-modal';
import { ReplacementRow } from '../replacement-row';

const styles: Styles = {
  cell: { verticalAlign: 'top' },
  link: { color: '#060F14', cursor: 'pointer', fontSize: 14 },
  showMore: {
    display: 'block',
    margin: '10px 0 0 20px',
    color: '#4288F0',
    fontSize: 10,
    cursor: 'pointer',
    width: 'fit-content',
  },
  loadingContainer: {
    minWidth: '400px',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  loadingTitle: { fontSize: 10, marginTop: 1 },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 1,
    marginBottom: 2,
    marginLeft: 1,
    fontSize: 14,
    color: '#4288F0',
    cursor: 'pointer',
  },
  title: {
    fontSize: '11px',
    ml: 1,
  },
};

interface Props {
  row: ContentOverviewModule.PageWithProducts;
  handleGetReplacements: () => void;
}

export function PageRow({ row, handleGetReplacements }: Props): ReactElement {
  const dispatch = useDispatch();

  const [showMoreProducts, setShowMoreProducts] = useState<boolean>(false);
  const [suggestionsTriggered, setSuggestionsTriggered] = useState<boolean>(false);
  const [suggestionsLoading, setSuggestionsLoading] = useState<boolean>(true);
  const [suggestions, setSuggestions] = useState<Array<IProduct>>([]);
  const [suggestionsError, setSuggestionsError] = useState<boolean>(false);
  const [titleRef, setTitleRef] = useState<HTMLSpanElement>();
  const [isOpenRecommendationsModal, setIsOpenRecommendationsModal] = useState<boolean>(false);
  const [productAsin, setProductAsin] = useState<string>('');

  const globalFilters = useSelector(selectGlobalFilters);
  const replacementRecomendations = useSelector(selectReplacementRecomendations);
  const replacementRecomendationsLoading = useSelector(selectReplacementRecomendationsLoading);

  const inView = useInView(titleRef);

  const toggleShowMoreProducts = () => {
    setShowMoreProducts(showMoreProducts => !showMoreProducts);
  };

  const handleOpenPage = () => {
    window.open(row.url);
  };

  const handleOpenRecommendationsModal = () => {
    setIsOpenRecommendationsModal(true);
  };

  const handleCloseRecommendationsModal = () => {
    setIsOpenRecommendationsModal(false);
  };

  const handleSetRecommendation = (value: string) => {
    dispatch(
      contentOverviewActions.getProductRecommendations({ publisherId: globalFilters.publisher_id, asins: value })
    );
    setProductAsin(value);
    handleOpenRecommendationsModal();
  };

  // const isProductReplacements = useMemo(() => {
  //   return row.products?.entries?.some(p => !p.in_stock);
  // }, [row]);

  const products = useMemo(() => {
    const products = (row.products?.entries ?? [])
      .sort((a, b) => {
        if (a.pageOrder) {
          return a.pageOrder > b.pageOrder ? 1 : -1;
        }
        return (a.in_stock ? 1 : 0) - (b.in_stock ? 1 : 0);
      })
      .map(p => ({ ...p, short_title: p.title?.length > 15 ? `${p.title.slice(0, 15)}...` : p.title }));
    if (showMoreProducts) return products.filter(item => item?.title !== null);
    return products.slice(0, 1);
  }, [row, showMoreProducts]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const replacements = useMemo(() => {
    const replacements = products
      .map(product =>
        product.in_stock ? null : replacementRecomendations?.find(rr => rr?.out_of_stock_asin === product.asin) ?? null
      )
      .filter(Boolean)
      .map(p => ({ ...p, short_title: p.title?.length > 15 ? `${p.title.slice(0, 15)}...` : p.title }));
    if (showMoreProducts) return replacements;
    return replacements;
  }, [products, replacementRecomendations, showMoreProducts]);

  useEffect(() => {
    if (!inView || suggestionsTriggered) return;

    setSuggestionsTriggered(true);

    const getSuggestions = () => {
      contentOverviewApi
        .getSuggestions(globalFilters.publisher_id, row.id)
        .then(({ data }) => {
          setSuggestions(data);
          setSuggestionsLoading(false);
        })
        .catch(() => {
          setSuggestionsError(true);
          setSuggestionsLoading(false);
        });
    };

    getSuggestions();
  }, [inView, row, suggestionsTriggered, globalFilters.publisher_id]);

  return (
    <>
      <TableRow>
        <TableCell sx={styles.cell}>
          <Tooltip title={row.url}>
            <Typography onClick={handleOpenPage} sx={styles.link} ref={ref => setTitleRef(ref)}>
              {row.title ?? '-'}
            </Typography>
          </Tooltip>
          <ProductSuggestion
            suggestions={suggestions}
            suggestionsLoading={suggestionsLoading}
            suggestionsError={suggestionsError}
          />
        </TableCell>
        <TableCell sx={styles.cell}>{parseInt(row?.page_visits, 10) || '-'}</TableCell>
        {/* <TableCell align="center" sx={styles.cell}>
        {row.url_traffic ?? '-'}
      </TableCell> */}
        <TableCell sx={styles.cell}>
          {products
            .filter(item => item?.title !== null)
            ?.map(product => (
              <Product key={product.amazon_link} showInStock product={product} />
            ))}
          {row.products?.entries?.length > 1 && (
            <Typography onClick={toggleShowMoreProducts} sx={styles.showMore}>
              {showMoreProducts ? '- Close' : '+ See All'}
            </Typography>
          )}
        </TableCell>
        <TableCell sx={styles.cell}>
          {replacementRecomendationsLoading && (
            <Box sx={styles.loadingContainer}>
              <CircularProgress size={20} color="primary" />
              <Typography sx={styles.loadingTitle}>Loading replacements...</Typography>
            </Box>
          )}
          {!replacementRecomendationsLoading && (
            <Box>
              {replacements?.filter(item => item.asin)?.length ||
              replacements?.filter(item => item.saved_replacements !== null)?.length ? (
                <>
                  {replacements.map(replacement => (
                    <ReplacementRow replacement={replacement} handleGetReplacements={handleGetReplacements} />
                  ))}
                </>
              ) : (
                <Typography sx={styles.showMore} onClick={() => handleSetRecommendation(products[0]?.asin)}>
                  + Add Recommendation
                </Typography>
              )}
            </Box>
          )}
        </TableCell>
      </TableRow>
      <RecommendationsModal
        open={isOpenRecommendationsModal}
        onClose={handleCloseRecommendationsModal}
        isNewRecommend={false}
        handleGetReplacements={handleGetReplacements}
        productAsin={productAsin}
      />
    </>
  );
}
