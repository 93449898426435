import { SagaIterator } from 'redux-saga';
import { Action } from 'common/interfaces/root';
import { call, put, all, takeLatest } from 'redux-saga/effects';
import { settingsApi } from 'modules/settings/service/settings.service';
import { publisherBrandCommissionsActions } from '../actions/actions';
import { publisherBrandCommissionsActionTypes as T } from '../actions/action-types';

function* getPublisherBrandCommissions({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.getPublisherBrandCommissions, payload);
    yield put(publisherBrandCommissionsActions.getPublisherBrandCommissionsSuccess(data));
  } catch (err) {
    yield put(publisherBrandCommissionsActions.getPublishersBrandCommissionsError(err as Error));
  }
}

function* addPublisherBrandCommission({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.addPublisherBrandCommission, payload);
    yield put(publisherBrandCommissionsActions.addPublisherBrandCommissionSuccess(data));
  } catch (err) {
    yield put(publisherBrandCommissionsActions.addPublisherBrandCommissionError(err as Error));
  }
}

function* editPublisherBrandCommission({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.editPublisherBrandCommission, payload);
    yield put(publisherBrandCommissionsActions.editPublisherBrandCommissionSuccess(data));
  } catch (err) {
    yield put(publisherBrandCommissionsActions.editPublisherBrandCommissionError(err as Error));
  }
}

function* deletePublisherBrandCommission({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(settingsApi.deletePublisherBrandCommission, payload);
    yield put(publisherBrandCommissionsActions.deletePublisherBrandCommissionSuccess(data));
  } catch (err) {
    yield put(publisherBrandCommissionsActions.deletePublisherBrandCommissionError(err as Error));
  }
}

export function* publisherBrandCommissionsSaga(): SagaIterator {
  yield all([
    takeLatest(T.GET_PUBLISHER_BRAND_COMMISSIONS, getPublisherBrandCommissions),
    takeLatest(T.ADD_PUBLISHER_BRAND_COMMISSION, addPublisherBrandCommission),
    takeLatest(T.EDIT_PUBLISHER_BRAND_COMMISSION, editPublisherBrandCommission),
    takeLatest(T.DELETE_PUBLISHER_BRAND_COMMISSION, deletePublisherBrandCommission),
  ]);
}
