import { combineReducers } from 'redux';
import gainzRevenueFilters from './filters';
import gainzRevenue from './revenue';
import gainzRevenueExport from './exports';

export const gainzRevenueReducer = combineReducers({
  gainzRevenue,
  gainzRevenueFilters,
  gainzRevenueExport,
});
