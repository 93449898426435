import { RangeKeyDict } from 'react-date-range';
import { datePickerComparatorActionTypes as TYPES } from './action-types';

const changeIsCompare = (isCompare: boolean) => ({
  type: TYPES.CHANGE_IS_COMPARE,
  payload: isCompare,
});

const changeDateRanges = (dateRanges: RangeKeyDict) => ({
  type: TYPES.CHANGE_DATE_RANGES,
  payload: dateRanges,
});

export const datePickerComparatorActions = {
  changeIsCompare,
  changeDateRanges,
};
