import { combineReducers } from 'redux';
import correlation from './correlation';
import devices from './devices';
import statistics from './statistics';
import performance from './performance';

export const overviewReducers = combineReducers({
  statistics,
  correlation,
  devices,
  performance,
});
