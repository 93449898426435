import { Box, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement, useMemo, useState } from 'react';
import SyncIcon from '@mui/icons-material/Sync';
import { useDispatch, useSelector } from 'react-redux';
import { contentOverviewActions, crawlingActions } from 'modules/content-overview/store/actions';
import { selectGlobalFilters } from 'common/store/selectors';
import moment from 'moment';

const getStyles = ({ isDemoMode }: { isDemoMode: boolean }): Styles => ({
  cell: {
    verticalAlign: 'top',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    lineHeight: 1.5,
    minHeight: '40px',
  },

  syncWrapper: { width: '100%', display: 'flex', justifyContent: 'center' },
  sync: {
    backgroundColor: '#FEF9ED',
    minWidth: '32px',
    width: 32,
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  showMore: {
    display: 'block',
    color: '#4288F0',
    fontSize: 10,
    cursor: 'pointer',
    width: 'fit-content',
  },
  urlCell: {
    filter: isDemoMode ? 'blur(3px)' : 0,
  },
});

interface Props {
  url: ContentOverviewModule.CrawledUrl;
  withAsins: number;
  isDemoMode?: boolean;
}

export function CrawlingRow({ url, withAsins, isDemoMode }: Props): ReactElement {
  const styles = getStyles({ isDemoMode });
  const dispatch = useDispatch();
  const [showMoreAsins, setShowMoreAsins] = useState<boolean>(false);

  const globalFilters = useSelector(selectGlobalFilters);

  const getFoundCrawledDate = () => {
    if (!url.found_at) return '-';
    return moment(url.found_at).format('ddd MMM D, YYYY @h:mma');
  };

  const handleSync = () => {
    dispatch(crawlingActions.deleteSuccess(url.id, 'id'));
    dispatch(
      contentOverviewActions.syncPage(url.url, globalFilters.publisher_id, withAsins, url.asins, url.updated_at)
    );
  };

  const toggleShowMoreAsins = () => {
    setShowMoreAsins(showMoreAsins => !showMoreAsins);
  };

  const asins = useMemo(() => {
    const asins = url.asins ?? [];
    if (showMoreAsins) return asins;
    return asins.slice(0, 1);
  }, [url, showMoreAsins]);

  return (
    <TableRow>
      <TableCell sx={styles.urlCell}>{url.url}</TableCell>
      <TableCell sx={styles.cell} align="center">
        {asins?.map(asin => (
          <span>{asin}</span>
        ))}
        {url.asins.length && (
          <Typography onClick={toggleShowMoreAsins} sx={styles.showMore}>
            {showMoreAsins ? '- Close' : '+ See All'}
          </Typography>
        )}
      </TableCell>

      <TableCell align="center">{getFoundCrawledDate()}</TableCell>
      <TableCell align="center">
        {!url.synced_with_urls && (
          <Box sx={styles.syncWrapper}>
            <Tooltip title="Sync with URLs" placement="top">
              <Box sx={styles.sync} onClick={handleSync}>
                <SyncIcon fontSize="small" />
              </Box>
            </Tooltip>
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
}
