import { Box, Button, Typography } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { getStyles as getSettingStyles } from 'modules/user-settings/components/user-settings-content/user-settings-content';
import { Styles } from 'common/types';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalFilters } from 'common/store/selectors';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { selectAffiliateReportHistoryData } from 'modules/settings/store/selectors';
import { ReportHistoryTable } from './report-upload-history-table';

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '20px 0',
    boxSizing: 'border-box',
    borderBottom: '1px solid #C2DEEB40',
    ':last-child': { borderBottom: 0 },
  },
  tableContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '580px',
  },
  uploadCol: { width: '100px' },
  fieldWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    padding: '20px 0',
    boxSizing: 'border-box',
  },
  button: { width: '200px', borderRadius: '10px' },
  buttonContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '580px' },
};

export function ReportUploadContent(): ReactElement {
  const filters = useSelector(selectGlobalFilters);
  const rows = useSelector(selectAffiliateReportHistoryData);

  const dispatch = useDispatch();
  const settingsStyles = getSettingStyles();

  useEffect(() => {
    dispatch(publishersSettingsActions.getAffiliateReportHistory({ publisher_id: filters.publisher_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = () => {
    dispatch(publishersSettingsActions.getAffiliateReportHistory({ publisher_id: filters.publisher_id }));
  };

  const handleUpload = (file: File) => {
    const payload: AffiliateReportModule.UploadPost = { publisher_id: filters.publisher_id, file };
    dispatch(publishersSettingsActions.postAffiliateReport(payload, onSuccess));
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.fieldWrapper}>
        <Typography sx={settingsStyles.titleField}>Amazon</Typography>
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.button} variant="contained" component="label">
            <FileUploadIcon fontSize="small" />
            Upload
            <input accept="text/csv" type="file" hidden onChange={e => handleUpload(e.target.files[0])} />
          </Button>
        </Box>
      </Box>
      {rows.length > 0 && (
        <Box sx={styles.fieldWrapper}>
          <Typography sx={settingsStyles.titleField}>Already Uploaded</Typography>
          <Box sx={styles.tableContainer}>
            <ReportHistoryTable />
          </Box>
        </Box>
      )}
    </Box>
  );
}
