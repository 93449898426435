import { ApiService } from 'common/services';

class ConversionsApiService extends ApiService {
  getConversions = (params: Conversions.Conv.ConversionsParams) => {
    return this.get('/api/statistics/conversions', { params });
  };

  getExport = (params: Conversions.Conv.ConversionsParams) => {
    return this.get('/api/statistics/conversions', { params: { ...params, is_download: true } });
  };

  getReports = (params: Conversions.Reports.ReportsParams) => {
    return this.get('/api/statistics/reports', { params });
  };

  getDownloadContent = (path: string) => {
    return this.post('/api/statistics/reports', {}, { params: { path } });
  };
}

export const conversionsApiService = new ConversionsApiService(process.env.REACT_APP_API_URL);
