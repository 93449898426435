import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { Styles } from 'common/types';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { sitemapsActions } from 'modules/content-overview/store/actions';
import {
  selectSitemaps,
  selectSitemapsLimitPageAmount,
  selectSitemapsLoading,
} from 'modules/content-overview/store/selectors';
import { MouseEvent, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SitemapsTableRow } from '../sitemaps-table-row';
import { selectRootDemo } from '../../../../../../common/store/selectors';

const styles: Styles = {
  table: { marginTop: 2 },
};

interface Props {
  handleUpdateSitemaps: () => void;
}

export function SitemapsTable({ handleUpdateSitemaps }: Props): ReactElement {
  const dispatch = useDispatch();
  const isDemoMode = useSelector(selectRootDemo);
  const sitemaps = useSelector(selectSitemaps);
  const loading = useSelector(selectSitemapsLoading);
  const { amount, page, limit } = useSelector(selectSitemapsLimitPageAmount);

  const handlePageChange = (_: MouseEvent<HTMLButtonElement>, page: number) => {
    dispatch(sitemapsActions.changePage(page));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(sitemapsActions.changeLimit(parseInt(event.target.value, 10)));
    dispatch(sitemapsActions.changePage(0));
  };

  return (
    <>
      <TableContainerWithLoading sx={styles.table} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Source</TableCell>
              <TableCell>Last Scrape</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sitemaps?.map(sitemap => (
              <SitemapsTableRow
                isDemoMode={isDemoMode}
                key={sitemap.id}
                sitemap={sitemap}
                handleUpdateSitemaps={handleUpdateSitemaps}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <TablePagination
        component="div"
        count={amount}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 25, 50, 100]}
        page={page}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}
