import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { PERMISSIONS } from 'common/constants/permissions';
import { userService } from 'common/services/user.service';
import { Styles } from 'common/types';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { tagsActions } from 'modules/tags/store/actions';
import { selectCurrentUrlsPage, selectUrls, selectUrlsAmount, selectUrlsLoading } from 'modules/urls/store/selectors';
import { MouseEvent, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UrlRow } from './url-row';

const getStyles = (): Styles => ({
  table: { marginTop: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS]) ? 2 : 0 },
});

export function UrlsTable(): ReactElement {
  const dispatch = useDispatch();
  const styles = getStyles();

  const currentPage = useSelector(selectCurrentUrlsPage);
  const amount = useSelector(selectUrlsAmount);
  const rows = useSelector(selectUrls);
  const loading = useSelector(selectUrlsLoading);

  const handlePageChange = (_e: MouseEvent<HTMLButtonElement>, page: number) => {
    dispatch(tagsActions.changeTagsPage(page));
  };

  return (
    <>
      <TableContainerWithLoading sx={styles.table} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Publisher</TableCell>
              <TableCell>Url path</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map(row => (
              <UrlRow key={row.id} url={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <TablePagination
        component="div"
        count={amount}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        page={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}
