import { getDataListActions } from 'common/store/actions/data-list';
import { getFiltersActions } from 'common/store/actions/filters';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import {
  attributionActionTypes as T,
  attributionFiltersActionTypes,
  brandsAttributionActionTypes,
} from './action-types';

const getAmazonAttribution = (filters: IStatisticsQueryString) => ({
  type: T.GET_AMAZON_ATTRIBUTION,
  payload: filters,
});

const getAmazonAttributionSuccess = (payload: { tags: Array<Attribution.AmazonAttrData>; amount: number }) => ({
  type: T.GET_AMAZON_ATTRIBUTION_SUCCESS,
  payload,
});

const getAmazonAttributionError = (error: Error) => ({
  type: T.GET_AMAZON_ATTRIBUTION_ERROR,
  payload: error,
});

const changeAmazonAttrPage = (page: number) => ({
  type: T.CHANGE_AMAZON_ATTR_PAGE,
  payload: page,
});

const postCsvForAsinsAmazonAttr = (payload: File, onSuccess: () => void, onError: () => void) => ({
  type: T.POST_CSV_FOR_ASINS_AMAZON_ATTRIBUTION,
  payload,
  meta: { onSuccess, onError },
});

const postCsvForAsinsAmazonAttrSuccess = () => ({
  type: T.POST_CSV_FOR_ASINS_AMAZON_ATTRIBUTION_SUCCESS,
});

const postCsvForAsinsAmazonAttrError = (error: Error) => ({
  type: T.POST_CSV_FOR_ASINS_AMAZON_ATTRIBUTION_ERROR,
  payload: { error },
});

const postCsvForBrandsAmazonAttr = (payload: File, onSuccess: () => void, onError: () => void) => ({
  type: T.POST_CSV_FOR_BRANDS_AMAZON_ATTRIBUTION,
  payload,
  meta: { onSuccess, onError },
});

const postCsvForBrandsAmazonAttrSuccess = () => ({
  type: T.POST_CSV_FOR_BRANDS_AMAZON_ATTRIBUTION_SUCCESS,
});

const postCsvForBrandsAmazonAttrError = (error: Error) => ({
  type: T.POST_CSV_FOR_BRANDS_AMAZON_ATTRIBUTION_ERROR,
  payload: { error },
});

const deleteAmazonAttrItem = (payload: number) => ({
  type: T.DELETE_AMAZON_ATTRIBUTION_ITEM,
  payload,
});

const deleteAmazonAttrItemSuccess = (payload: number) => ({
  type: T.DELETE_AMAZON_ATTRIBUTION_ITEM_SUCCESS,
  payload,
});

const deleteAmazonAttrItemError = (error: Error) => ({
  type: T.DELETE_AMAZON_ATTRIBUTION_ITEM_ERROR,
  payload: { error },
});

export const brandsAttributionActions =
  getDataListActions<Attribution.AmazonBrandsAttrData>(brandsAttributionActionTypes);
export const attributionFiltersActions = getFiltersActions(attributionFiltersActionTypes);

export const attributionActions = {
  getAmazonAttribution,
  getAmazonAttributionSuccess,
  getAmazonAttributionError,
  changeAmazonAttrPage,
  postCsvForAsinsAmazonAttr,
  postCsvForAsinsAmazonAttrSuccess,
  postCsvForAsinsAmazonAttrError,
  deleteAmazonAttrItem,
  deleteAmazonAttrItemSuccess,
  deleteAmazonAttrItemError,
  postCsvForBrandsAmazonAttr,
  postCsvForBrandsAmazonAttrSuccess,
  postCsvForBrandsAmazonAttrError,
};
