import { combineReducers } from 'redux';
import conversions from './conversions';
import reports from './reports';
import exp from './export';
import filters from './filters';
import table from './table';

export const conversionsReducers = combineReducers({
  conversions,
  reports,
  exp,
  filters,
  table,
});
