import { Action } from 'common/interfaces/root';
import { recommendedAsinsActionTypes as TYPES } from '../actions';

const initialState: AffiliateGainzModule.RecommendedAsinsModule.AsinsState = {
  loading: true,
  data: null,
  error: null,
};

export default (
  state: AffiliateGainzModule.RecommendedAsinsModule.AsinsState = initialState,
  { type, payload }: Action
): AffiliateGainzModule.RecommendedAsinsModule.AsinsState => {
  switch (type) {
    case TYPES.ASINS.GET_ASINS:
      return { ...state, loading: true };
    case TYPES.ASINS.GET_ASINS_SUCCESS:
      return { ...state, loading: false, data: payload };
    case TYPES.ASINS.GET_ASINS_ERROR:
      return { ...state, loading: false, error: payload };
    case TYPES.ASINS.GET_NEW_ASINS:
      return { ...state, loading: true };
    case TYPES.ASINS.GET_NEW_ASINS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          recommended_asins: [...state.data.recommended_asins, ...payload.recommended_asins],
          total: state.data.total,
        },
      };
    case TYPES.ASINS.GET_NEW_ASINS_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
