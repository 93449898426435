import { Modal } from 'common/ui/modal';
import { ReactElement } from 'react';
import { PublisherForm } from 'modules/publishers-settings/components/publisher-modal/publisher-form';

export enum PublisherAction {
  edit = 'edit',
  create = 'create',
}

interface Props {
  open: boolean;
  onClose: () => void;
  action: PublisherAction;
  publisher?: PublishersSettingsModule.Publisher;
}

export function PublisherModal({ open, onClose, action, publisher }: Props): ReactElement {
  return (
    <Modal
      open={open}
      title={action === PublisherAction.edit ? 'Edit publisher' : 'Create publisher'}
      maxWidth="sm"
      onClose={onClose}
    >
      <PublisherForm action={action} handleModalClose={onClose} isModalOpen={open} publisher={publisher} />
    </Modal>
  );
}
