import { Box, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement, ReactNode } from 'react';

const styles: Styles = {
  title: { fontWeight: 600, fontSize: 16, marginTop: 4 },
  description: {
    marginTop: 1.5,
    fontSize: 14,
  },
  container: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

interface Props {
  description: ReactNode;
  image: string;
}

export function EmptyChart({ description, image }: Props): ReactElement {
  return (
    <Box sx={styles.container}>
      <img src={image} alt="no-data" />
      <Typography sx={styles.title}>No data yet</Typography>
      <Typography sx={styles.description}>{description}</Typography>
    </Box>
  );
}
