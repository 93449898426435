import { ReactElement } from 'react';
import { Calendar } from 'react-date-range';
import { Popover } from '@mui/material';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { primary } from 'common/constants/colors';
import moment, { Moment } from 'moment';

interface Props {
  onClose: () => void;
  anchorElement: HTMLElement | null;
  date: Moment;
  // Problems with onChange props types for this library
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (range: Date) => void;
}

export function DatePickerCalendar({ onChange, onClose, anchorElement, date }: Props): ReactElement {
  return (
    <Popover
      onClose={onClose}
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Calendar maxDate={moment().toDate()} color={primary} date={date.toDate()} onChange={onChange} />
    </Popover>
  );
}
