import { Box, Typography } from '@mui/material';
import { filtersActions } from 'common/store/actions/filters';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { BaseFilters } from 'common/ui/base-filters';
import { Moment } from 'moment';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { Portal } from 'common/ui/portal';
import HeaderImg from 'common/svg/header-img.png';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { publisherGroups } from 'common/constants/publisher-groups';
import { accessActions } from 'common/store/actions/access/actions';
import { FilterButton } from 'common/ui/filter-button';
import { ArticlesContainer, CardsContainer, ChartsContainer, FiltersModal, RecommendedAsins } from './components';
import { overviewActions } from './store/actions';
import { selectCombined } from './store/selectors';

const styles: Styles = {
  headTitle: {
    color: '#ffffff',
    fontWeight: 600,
  },
  head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: `url(${HeaderImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#4288F0',
    padding: '28px',
    boxSizing: 'border-box',
    color: '#ffffff',
    fontWeight: 600,
  },
  filtersContainer: { display: 'flex', alignItems: 'center', gap: 1.5 },
};

export function Overview(): ReactElement {
  const [filtersModalOpen, setFiltersModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const filters = useSelector(selectGlobalFilters);
  const { isCombined } = useSelector(selectCombined);

  const parsedFilters: IStatisticsQueryString = useMemo(
    () => ({
      date_from: filters.date_from,
      date_to: filters.date_to,
      publisher_id: filters.publisher_id,
      traffic_type: filters.traffic_type,
      affiliate_network_type: filters.affiliate_network_type,
      search: filters.search,
      date_by: filters.date_by,
      clicks: filters.clicks,
      publisher_group: isCombined ? Number(publisherGroups[filters.publisher_group]) : '',
    }),
    [filters, isCombined]
  );

  const handleFiltersModalOpen = () => {
    setFiltersModalOpen(true);
  };

  const handleFiltersModalClose = () => {
    setFiltersModalOpen(false);
  };

  const handleRangeChange = (from: Moment, to: Moment) => {
    dispatch(filtersActions.changeRange(from, to));
  };

  const getAll = (parsedFilters: IStatisticsQueryString) => {
    dispatch(accessActions.getAccess({ publisher_id: parsedFilters.publisher_id }));
    dispatch(overviewActions.getAllStatistics(parsedFilters));
    dispatch(overviewActions.getCorrelationStatistics(parsedFilters));
    dispatch(overviewActions.getDevicesStatistics(parsedFilters));
    dispatch(overviewActions.getTop10Pages(parsedFilters));
    dispatch(overviewActions.getProductsStats(parsedFilters));
    dispatch(overviewActions.getTopPerformers(parsedFilters));
    dispatch(publishersSettingsActions.getMainPublisherInfo(filters.publisher_id.toString()));
  };

  const debouncedStatistics = useDebouncedCallback(() => {
    getAll(parsedFilters);
  }, 300);

  const handleApply = () => {
    setFiltersModalOpen(false);
    getAll(parsedFilters);
  };

  const handleReset = () => {
    dispatch(filtersActions.resetFilters());
  };

  useEffect(() => {
    debouncedStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    parsedFilters.publisher_id,
    parsedFilters.search,
    parsedFilters.date_from,
    parsedFilters.date_to,
    parsedFilters.publisher_group,
    isCombined,
  ]);

  return (
    <>
      <Portal id="header-page">
        <Box sx={styles.head}>
          <Typography sx={styles.headTitle}>Dashboard Overview</Typography>
          <Box sx={styles.filtersContainer}>
            <FilterButton handleFiltersOpen={handleFiltersModalOpen} color="light" />
            <BaseFilters
              date={{ from: filters.date_from, to: filters.date_to }}
              handleRangeChange={handleRangeChange}
              show={['date']}
            />
          </Box>
        </Box>
      </Portal>
      <RecommendedAsins />
      <CardsContainer />
      <ChartsContainer />
      <ArticlesContainer />
      <FiltersModal
        isOpen={filtersModalOpen}
        onClose={handleFiltersModalClose}
        handleApply={handleApply}
        handleReset={handleReset}
      />
    </>
  );
}
