import { Action } from 'common/interfaces/root';
import { gainzRevenueActionTypes as TYPES } from '../actions/action-types';

const initialState: AffiliateGainzModule.GainzRevenueModule.ExportModule.ExportState = {
  loading: false,
  error: null,
  data: null,
};

export default (
  state: AffiliateGainzModule.GainzRevenueModule.ExportModule.ExportState = initialState,
  { type, payload }: Action
): AffiliateGainzModule.GainzRevenueModule.ExportModule.ExportState => {
  switch (type) {
    case TYPES.EXPORT.GET_EXPORT:
      return { ...state, loading: true };
    case TYPES.EXPORT.GET_EXPORT_SUCCESS:
      return { ...state, loading: false, data: payload };
    case TYPES.EXPORT.GET_EXPORT_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
