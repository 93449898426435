export const getDataListActionTypes = (module: string) => ({
  GET: `${module} GET`,
  GET_SUCCESS: `${module} GET_SUCCESS`,
  GET_LAZY_SUCCESS: `${module} GET_LAZY_SUCCESS`,
  RESET: `${module} RESET`,
  CAN_LOAD_MORE: `${module} CAN_LOAD_MORE`,
  GET_ERROR: `${module} GET_ERROR`,
  DELETE: `${module} DELETE`,
  DELETE_SUCCESS: `${module} DELETE_SUCCESS`,
  DELETE_ERROR: `${module} DELETE_ERROR`,
  CHANGE_PAGE: `${module} CHANGE_PAGE`,
  CHANGE_LIMIT: `${module} CHANGE_LIMIT`,
});
