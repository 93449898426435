import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Box, CircularProgress } from '@mui/material';
import { Styles } from 'common/types';
import { selectPublisherInfoLoading } from 'modules/settings/store/selectors';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { selectGlobalFilters } from 'common/store/selectors';
import { PERMISSIONS } from 'common/constants/permissions';
import { usePermission, usePublisher } from 'common/hooks';
import { ConversionSettingsContent } from './conversion-settings-content';
import { RateCardSettings } from './rate-card-settings';
import { AffiliateRateCard } from './affiliate-rate-card';

interface ConversionSettingsFields {
  orderedСonversions: string;
  shippedСonversions: string;
}

const getStyles = (): Styles => ({
  spinnerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '200px',
  },
});

export function ConversionSettings(): ReactElement {
  const permissions = usePermission(PERMISSIONS.GET_PUBLISHERS);

  const dispatch = useDispatch();
  const styles = getStyles();

  const loading = useSelector(selectPublisherInfoLoading);
  const filters = useSelector(selectGlobalFilters);

  const publisher = usePublisher();

  const id = String(filters?.publisher_id);

  const getInitialValues = (): ConversionSettingsFields => ({
    orderedСonversions: publisher?.ordered_conversions_name ?? '',
    shippedСonversions: publisher?.shipped_conversions_name ?? '',
  });

  const handleRefreshInfo = () => {
    dispatch(publishersSettingsActions.getMainPublisherInfo(id));
  };

  const handleSubmit = (values: ConversionSettingsFields) => {
    dispatch(
      publishersSettingsActions.patchMainPublisherInfo(
        {
          ordered_conversions_name: values?.orderedСonversions,
          shipped_conversions_name: values?.shippedСonversions,
          isAttributed: true,
          id,
        },
        handleRefreshInfo
      )
    );
  };

  return loading ? (
    <Box sx={styles.spinnerWrapper}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <>
      <Formik initialValues={getInitialValues()} onSubmit={handleSubmit}>
        <ConversionSettingsContent />
      </Formik>
      {permissions && (
        <>
          <RateCardSettings />
          {publisher?.gainz_active && <AffiliateRateCard />}
        </>
      )}
    </>
  );
}
