import { combineReducers } from 'redux';
import mainPublisherSettings from './main-settings';
import tags from './tags';
import tagsFilters from './tags-filters';
import urls from './urls';
import affiliate from './affiliate';
import team from './team';
import newTeam from './new-team';
import integrations from './integrations';
import rateCards from './rate-card';
import affiliateReports from './affiliate-reports';
import affiliateReportHistory from './affiliate-report-history';
import affiliateReportDownload from './affiliate-report-download';
import pendingInvitations from './pending-invitations';
import publisherGroups from './publisher-groups';
import publisherBrandCommissions from './publisherBrandCommissions';

export const settingsReducers = combineReducers({
  mainPublisherSettings,
  tagsSettings: tags,
  tagsFilters,
  urlsSettings: urls,
  affiliateSettings: affiliate,
  teamSettings: team,
  newTeamSettings: newTeam,
  integrationsSettings: integrations,
  rateCards,
  affiliateReports,
  affiliateReportHistory,
  affiliateReportDownload,
  pendingInvitations,
  publisherGroups,
  publisherBrandCommissions,
});
