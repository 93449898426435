import { State } from 'common/store/reducers';

const selectAsins = (state: State) => state.asinRecommendation.asins;
const selectFeaturedAsins = (state: State) => state.asinRecommendation.featuredAsins;
const selectCategories = (state: State) => state.asinRecommendation.categories;
const selectPlacements = (state: State) => state.asinRecommendation.placements;
const selectExports = (state: State) => state.asinRecommendation.exports;
const selectFilters = (state: State) => state.asinRecommendation.filters;
const selectTable = (state: State) => state.asinRecommendation.table;
const selectGrid = (state: State) => state.asinRecommendation.grid;
const selectBrands = (state: State) => state.asinRecommendation.brands;
const selectTypes = (state: State) => state.asinRecommendation.types;
const selectVariations = (state: State) => state.asinRecommendation.variations;

export const asinRecommendationSelectors = {
  selectAsins,
  selectFeaturedAsins,
  selectCategories,
  selectPlacements,
  selectExports,
  selectFilters,
  selectTable,
  selectGrid,
  selectBrands,
  selectTypes,
  selectVariations,
};
