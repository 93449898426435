const STATISTICS_MODULE = '[overview:statistics]';
const CORELLATION_MODULE = '[overview:correlation]';
const DEVICES_MODULE = '[overview:devices]';
const PERFORMANCE_MODULE = '[overview:performance]';

const GET_ALL_STATISTICS = `${STATISTICS_MODULE} GET_ALL_STATISTICS`;
const GET_ALL_STATISTICS_SUCCESS = `${STATISTICS_MODULE} GET_ALL_STATISTICS_SUCCESS`;
const GET_ALL_STATISTICS_ERROR = `${STATISTICS_MODULE} GET_ALL_STATISTICS_ERROR`;

const GET_CORRELATION_STATISTICS = `${CORELLATION_MODULE} GET_CORRELATION_STATISTICS`;
const GET_CORRELATION_STATISTICS_SUCCESS = `${CORELLATION_MODULE} GET_CORRELATION_STATISTICS_SUCCESS`;
const GET_CORRELATION_STATISTICS_ERROR = `${CORELLATION_MODULE} GET_CORRELATION_STATISTICS_ERROR`;

const GET_DEVICES_STATISTICS = `${DEVICES_MODULE} GET_DEVICES_STATISTICS`;
const GET_DEVICES_STATISTICS_SUCCESS = `${DEVICES_MODULE} GET_DEVICES_STATISTICS_SUCCESS`;
const GET_DEVICES_STATISTICS_ERROR = `${DEVICES_MODULE} GET_DEVICES_STATISTICS_ERROR`;

const GET_TOP_PERFORMERS = `${PERFORMANCE_MODULE} GET_TOP_PERFORMERS`;
const GET_TOP_ASINS_SUCCESS = `${PERFORMANCE_MODULE} GET_TOP_ASINS_SUCCESS`;
const GET_TOP_CATEGORIES_SUCCESS = `${PERFORMANCE_MODULE} GET_TOP_CATEGORIES_SUCCESS`;
const GET_TOP_PERFORMERS_ERROR = `${PERFORMANCE_MODULE} GET_TOP_PERFORMERS_ERROR`;

export const overviewActionTypes = {
  GET_ALL_STATISTICS,
  GET_ALL_STATISTICS_SUCCESS,
  GET_ALL_STATISTICS_ERROR,
  GET_CORRELATION_STATISTICS,
  GET_CORRELATION_STATISTICS_SUCCESS,
  GET_CORRELATION_STATISTICS_ERROR,
  GET_DEVICES_STATISTICS,
  GET_DEVICES_STATISTICS_SUCCESS,
  GET_DEVICES_STATISTICS_ERROR,
  GET_TOP_PERFORMERS,
  GET_TOP_ASINS_SUCCESS,
  GET_TOP_CATEGORIES_SUCCESS,
  GET_TOP_PERFORMERS_ERROR,
};
