import { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import { Styles } from 'common/types';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalFilters } from 'common/store/selectors';
import { filtersActions } from 'common/store/actions/filters';
import { SearchInput } from 'common/ui/search-input';
import { BaseFilters } from 'common/ui/base-filters';
import HeaderImg from 'common/svg/header-img.png';
import { Portal } from 'common/ui/portal';
import { FilterButton } from 'common/ui/filter-button';
import { DownloadButton } from 'common/ui/download-button';
import { CardsContainer, ConversionsTable } from './components';
import { conversionsSelectors } from './store/selectors';
import { convActions } from './store/actions';
import { FiltersModal } from './components/filters-modal';
import { downloadFile } from './utils';
import { ReportsModal } from './components/reports-modal';

const styles: Styles = {
  headTitle: {
    color: '#ffffff',
    fontWeight: 600,
  },
  head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: `url(${HeaderImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#4288F0',
    padding: '28px',
    boxSizing: 'border-box',
    color: '#ffffff',
    fontWeight: 600,
  },
  filtersContainer: { display: 'flex', alignItems: 'center', gap: 1.5 },
  reportsContainer: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1.5 },
  field: { width: 200 },
};

export function Conversions(): ReactElement {
  const [isInit, setIsInit] = useState<boolean>(false);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [reportsOpen, setReportsOpen] = useState<boolean>(false);

  const globalFilters = useSelector(selectGlobalFilters);
  const filters = useSelector(conversionsSelectors.selectFilters);
  const exp = useSelector(conversionsSelectors.selectExport);
  const conversions = useSelector(conversionsSelectors.selectConversions);

  const dispatch = useDispatch();

  const globalParams = useMemo(
    () => ({
      publisher_id: globalFilters.publisher_id,
      date_from: globalFilters.date_from.toISOString(),
      date_to: globalFilters.date_to.toISOString(),
      search: filters.search,
    }),
    [globalFilters, filters.search]
  );

  const filtersParams = useMemo(
    () => ({
      ...globalParams,
      type: filters.status,
      network_type: filters.networkType,
      traffic_type: filters.trafficType,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters.status, filters.networkType, filters.trafficType, globalParams]
  );

  const reportsParams = useMemo(
    () => ({
      publisher_id: globalFilters.publisher_id,
      date_from: globalFilters.date_from.toISOString(),
      date_to: globalFilters.date_to.toISOString(),
      type: filters.report,
    }),
    [globalFilters, filters.report]
  );

  const handleSearchChange = (search: string) => {
    dispatch(convActions.filtersActions.setSearch(search));
  };

  const handleRangeChange = (from: Moment, to: Moment) => {
    dispatch(filtersActions.changeRange(from, to));
  };

  const handleReportsChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(convActions.filtersActions.setReport(e.target.value));
  };

  const onFiltersOpen = () => setFiltersOpen(true);
  const onFiltersClose = () => setFiltersOpen(false);

  const onReportsOpen = () => setReportsOpen(true);
  const onReportsClose = () => setReportsOpen(false);

  const handleApply = () => {
    setFiltersOpen(false);
    dispatch(convActions.tableActions.setPage(0));
    dispatch(convActions.conversionsActions.getConversions(filtersParams));
  };

  const handleReset = () => {
    dispatch(convActions.filtersActions.resetFilters());
  };

  const handleExport = () => {
    dispatch(convActions.exportActions.getExport(filtersParams));
  };

  const generateTotalAll = (conversions: Conversions.Conv.ConversionData[]) => {
    let total = 0;
    conversions.forEach(conversion => {
      total += Number(conversion.ad_fees);
    });

    dispatch(convActions.tableActions.setTotal(total));
  };

  const debouncedFilters = useDebouncedCallback(() => {
    dispatch(convActions.conversionsActions.getConversions(filtersParams));
  }, 300);

  useEffect(() => {
    if (isInit) {
      debouncedFilters();
      dispatch(convActions.tableActions.setPage(0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalParams]);

  useEffect(() => {
    if (isInit) dispatch(convActions.reportsActions.getReports(reportsParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsParams.type]);

  useEffect(() => {
    if (conversions.data) generateTotalAll(conversions.data.conversions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversions.data]);

  useEffect(() => {
    setIsInit(true);
    dispatch(convActions.conversionsActions.getConversions(filtersParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (exp.data) {
      downloadFile(
        `conversions_${filters.status}_${filters.networkType}_${filters.trafficType}_${moment(
          globalFilters.date_from
        ).format('ll')}_to_${moment(globalFilters.date_to).format('ll')}.csv`,
        exp.data
      );
      dispatch(convActions.exportActions.getExportSuccess(null));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exp.data]);

  return (
    <>
      <Portal id="header-page">
        <Box sx={styles.head}>
          <Typography sx={styles.headTitle}>Conversions</Typography>
          <Box sx={styles.filtersContainer}>
            <SearchInput search={filters.search} handleSearchChange={handleSearchChange} />
            <FilterButton handleFiltersOpen={onFiltersOpen} color="light" />
            <BaseFilters
              date={{ from: globalFilters.date_from, to: globalFilters.date_to }}
              handleRangeChange={handleRangeChange}
              show={['date']}
            />
            <DownloadButton handleDownload={handleExport} loading={exp?.loading} color="light" />
          </Box>
        </Box>
      </Portal>
      <Box sx={styles.box}>
        <CardsContainer />
      </Box>
      <Box sx={styles.reportsContainer}>
        <TextField
          select
          color="primary"
          sx={styles.field}
          value={filters.report}
          size="small"
          label="Fee Report Downloads"
          onChange={handleReportsChange}
        >
          <MenuItem value="earning" onClick={onReportsOpen}>
            Fee Earnings
          </MenuItem>
          <MenuItem value="order" onClick={onReportsOpen}>
            Fee Orders
          </MenuItem>
          <MenuItem value="tracking" onClick={onReportsOpen}>
            Fee Tracking
          </MenuItem>
        </TextField>
      </Box>
      <ConversionsTable />
      <FiltersModal open={filtersOpen} onClose={onFiltersClose} handleApply={handleApply} handleReset={handleReset} />
      <ReportsModal open={reportsOpen} onClose={onReportsClose} />
    </>
  );
}
