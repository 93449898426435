import { Box, CircularProgress, Typography } from '@mui/material';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';
import { ReactElement, useMemo, useState } from 'react';
import { IProduct } from 'common/ui/product/types';
import { ReactComponent as ShowSuggestionsIcon } from 'common/svg/show-suggestions.svg';
import { Product } from '../product';

const styles: Styles = {
  titleContent: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 1,
    marginBottom: 2,
    ml: 1,
    fontSize: 14,
    color: '#4288F0',
    cursor: 'pointer',
  },
  title: {
    fontSize: '11px',
    ml: 1,
  },
  loadingContainer: { width: '100%', alignItems: 'center', flexDirection: 'column', display: 'flex' },
  loadingTitle: { fontSize: 10, marginTop: 1 },
  error: { marginTop: 1, fontSize: 10, color: theme.palette.error.main },
  showMore: {
    textAlign: 'center',
    borderBottom: '1px dotted #6F8490',
    display: 'block',
    margin: '16px auto 0',
    color: '#6F8490',
    fontSize: 10,
    cursor: 'pointer',
    width: 'fit-content',
  },
};

interface Props {
  suggestions: Array<IProduct>;
  suggestionsLoading: boolean;
  suggestionsError: boolean;
}

export function ProductSuggestion({ suggestions, suggestionsLoading, suggestionsError }: Props): ReactElement {
  const [showMoreProducts, setShowMoreProducts] = useState<boolean>(false);
  const [isShowSuggestions, setIsShowSuggestions] = useState<boolean>(false);

  const products = useMemo(() => {
    const products = suggestions.map(p => ({
      ...p,
      short_title: p.title?.length > 15 ? `${p.title?.slice(0, 15)}...` : p?.title,
    }));

    if (showMoreProducts) return products;

    return products.slice(0, 3);
  }, [suggestions, showMoreProducts]);

  const toggleShowMoreProducts = () => {
    setShowMoreProducts(showMoreProducts => !showMoreProducts);
  };

  return (
    <>
      {(suggestionsLoading || products.length || suggestionsError) && (
        <Typography sx={styles.titleContent} onClick={() => setIsShowSuggestions(!isShowSuggestions)}>
          <ShowSuggestionsIcon />
          <Typography sx={styles.title}>{!isShowSuggestions ? 'Show Suggestions' : 'Hide Suggestions'}</Typography>
        </Typography>
      )}
      {isShowSuggestions && suggestionsLoading && (
        <Box sx={styles.loadingContainer}>
          <CircularProgress size={20} color="primary" />
          <Typography sx={styles.loadingTitle}>Loading suggestions...</Typography>
        </Box>
      )}
      {suggestionsError && <Typography sx={styles.error}>Suggestions request failed!</Typography>}
      {isShowSuggestions &&
        products?.map(product => <Product key={product.amazon_link} product={product} showInStock={false} />)}
      {isShowSuggestions && suggestions?.length > 3 && (
        <Typography onClick={toggleShowMoreProducts} sx={styles.showMore}>
          {showMoreProducts ? 'Show less products' : 'Show more products'}
        </Typography>
      )}
    </>
  );
}
