import { State } from 'common/store/reducers';

export const selectPubMaverickTable = (state: State) => state.mavPlus.pubMaverickTable.data;

export const selectPubMaverickTableLoading = (state: State) => state.mavPlus.pubMaverickTable.loading;

export const selectPubMaverickTableAmount = (state: State) => state.mavPlus.pubMaverickTable.amount;

export const selectPubMaverickTablePage = (state: State) => state.mavPlus.pubMaverickTable.page;

export const selectPubMaverickTableLimit = (state: State) => state.mavPlus.pubMaverickTable.limit;

export const selectPubMavPerformanceExportLoading = (state: State) => state.mavPlus.pubMavPerformanceExport.loading;

export const selectPubMavPerformanceSummary = (state: State) => state.mavPlus.pubMavPerformanceSummary.summary;

export const selectPubMavPerformanceSummaryLoading = (state: State) => state.mavPlus.pubMavPerformanceSummary.loading;

export const selectPubMavPerformanceSummaryError = (state: State) => state.mavPlus.pubMavPerformanceSummary.error;
