import { Box, Tooltip, Typography } from '@mui/material';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';
import { ReactElement } from 'react';
import StarIcon from '@mui/icons-material/Star';
import { IProduct } from 'common/ui/product/types';

interface StylesProps {
  inStock: boolean;
  image: string;
}

const getStyles = ({ inStock, image }: StylesProps): Styles => ({
  container: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: theme.spacing(0.5, 2),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    marginTop: 1,
    cursor: 'pointer',
    border: inStock ? '1px solid #C2DEEB' : '1px solid #F5BEBC',

    ':first-child': {
      marginTop: 0,
    },
  },
  title: {
    fontSize: 14,
  },
  alertIcon: { marginLeft: 1, color: '#F65147' },
  inStockIcon: { marginLeft: 1, color: '#62D294' },
  starIcon: { color: '#FFD645' },
  image: { width: 128, minWidth: 64, height: 128, backgroundImage: `url(${image})`, backgroundSize: 'contain' },
  textWrapper: { display: 'flex', flexDirection: 'row', width: '100%' },
  textContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 2,
    width: '100%',
  },
  titleContainer: { display: 'flex', flexDirection: 'column', flexBasis: '66%' },
  asin: { color: '#6F8490', fontSize: '12px', justifySelf: 'start', width: '100%' },
  reviewsText: { fontSize: 10, ml: 1, lineHeight: 1, color: '#6F8490' },
  reviewsTitleText: { color: '#214254', fontSize: 10, opacity: 0.65 },
  ratingContent: { display: 'flex', alignItems: 'center', flexBasis: '33%' },
  avgReviewsText: { lineHeight: 1 },
});

interface Props {
  product: IProduct;
}

export function ProductSuggestion({ product }: Props): ReactElement {
  const styles = getStyles({
    inStock: product.in_stock === undefined ? true : Boolean(product.in_stock),
    image: product.image ?? product.img_medium,
  });

  const handleOpenProduct = () => {
    window.open(product.amazon_link);
  };

  return (
    <Box sx={styles.container} onClick={handleOpenProduct}>
      <Box sx={styles.image} />
      <Box sx={styles.textContent}>
        <Box sx={styles.textWrapper}>
          <Box sx={styles.titleContainer}>
            <Tooltip placement="top" title={product?.title}>
              <Typography sx={styles.title}>{product?.title}</Typography>
            </Tooltip>
            <Typography sx={styles.asin}>{product.asin}</Typography>
          </Box>
          <Box sx={styles.ratingContent}>
            {product.avg_review ? <StarIcon sx={styles.starIcon} /> : null}
            <Typography sx={styles.avgReviewsText}>{product.avg_review ?? ''}</Typography>
            <Typography sx={styles.reviewsText}>
              {product.review_count ? ` (${product.review_count} reviews)` : ''}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
