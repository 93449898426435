import { ChangeEvent, ReactElement, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { SearchInput } from 'common/ui/search-input';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useDebouncedCallback } from 'use-debounce';
import { TabPanel } from 'common/ui/tab-panel';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { AttributionTable, BrandsAttributionTable } from './components';
import { selectAttributionFilters, selectBrandsAttrLimitPageAmount, selectCurrentTagsPage } from './store/selectors';
import { attributionActions, attributionFiltersActions, brandsAttributionActions } from './store/actions';

enum TAB {
  ASINS = 0,
  BRANDS,
}

const styles: Styles = {
  head: { display: 'flex', justifyContent: 'space-between', width: '100%' },
  hiddenInput: { display: 'none' },
  importBtn: { marginLeft: 2 },
};

export function Attribution(): ReactElement {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [tab, setTab] = useState<TAB>(TAB.ASINS);

  const filters = useSelector(selectAttributionFilters);
  const currentPage = useSelector(selectCurrentTagsPage);
  const { limit: brandsLimit, page: brandsPage } = useSelector(selectBrandsAttrLimitPageAmount);

  const parsedFiltersForAsins: IStatisticsQueryString = useMemo(
    () => ({
      limit: 20,
      offset: currentPage * 20,
      search: filters.search,
    }),
    [currentPage, filters.search]
  );

  const parsedFiltersForBrands: IStatisticsQueryString = useMemo(
    () => ({
      limit: brandsLimit,
      offset: brandsPage * brandsLimit,
      search: filters.search,
    }),
    [brandsLimit, brandsPage, filters.search]
  );

  const handleImportCsv = () => {
    inputRef.current?.click();
  };

  const handleSearchChange = (search: string) => {
    dispatch(attributionFiltersActions.changeSearch(search));
  };

  const handleRefreshAsinsAfterUpload = () => {
    dispatch(attributionActions.getAmazonAttribution(parsedFiltersForAsins));
  };

  const handleRefreshBrandsAfterUpload = () => {
    dispatch(brandsAttributionActions.get(parsedFiltersForBrands));
  };

  const handleCsvUploadError = () => {
    toast.error('Incorrect document!');
  };

  const handleCsvUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;

    switch (tab) {
      case TAB.ASINS:
        dispatch(
          attributionActions.postCsvForAsinsAmazonAttr(file, handleRefreshAsinsAfterUpload, handleCsvUploadError)
        );
        break;
      case TAB.BRANDS:
        dispatch(
          attributionActions.postCsvForBrandsAmazonAttr(file, handleRefreshBrandsAfterUpload, handleCsvUploadError)
        );
        break;
      default:
        break;
    }
  };

  const handleTabChange = (_: SyntheticEvent, tab: number) => {
    setTab(tab as TAB);
  };

  const debouncedAsinsTable = useDebouncedCallback((filters: IStatisticsQueryString) => {
    dispatch(attributionActions.getAmazonAttribution(filters));
  }, 300);

  const debouncedBrandsTable = useDebouncedCallback((filters: IStatisticsQueryString) => {
    dispatch(brandsAttributionActions.get(filters));
  }, 300);

  useEffect(() => {
    if (!isInitialized) return;

    debouncedAsinsTable(parsedFiltersForAsins);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedFiltersForAsins]);

  useEffect(() => {
    if (!isInitialized) return;

    debouncedBrandsTable(parsedFiltersForBrands);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedFiltersForBrands]);

  useEffect(() => {
    setIsInitialized(true);

    dispatch(attributionActions.getAmazonAttribution(parsedFiltersForAsins));
    dispatch(brandsAttributionActions.get(parsedFiltersForBrands));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={styles.head}>
        <Typography variant="h4">Amazon Attribution Tags</Typography>
        <Box>
          <SearchInput search={filters.search} handleSearchChange={handleSearchChange} />
          <Button variant="contained" onClick={handleImportCsv} sx={styles.importBtn}>
            <FileDownloadIcon fontSize="small" />
            <Typography>Import {tab === TAB.ASINS ? 'Asins' : 'Brands'} CSV</Typography>
          </Button>
        </Box>
      </Box>
      <Box sx={styles.hiddenInput}>
        <input type="file" ref={inputRef} onChange={handleCsvUpload} />
      </Box>
      <Tabs onChange={handleTabChange} value={tab} sx={styles.tabs}>
        <Tab label="Amazon ASINs" value={TAB.ASINS} />
        <Tab label="Amazon Brands" value={TAB.BRANDS} />
      </Tabs>
      <TabPanel index={TAB.ASINS} value={tab}>
        <AttributionTable currentPage={currentPage} />
      </TabPanel>
      <TabPanel index={TAB.BRANDS} value={tab}>
        <BrandsAttributionTable />
      </TabPanel>
    </>
  );
}
