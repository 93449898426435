import { ReactElement, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Styles } from 'common/types';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { PUBLISHER_SETTINGS_NAV } from 'modules/settings/constants';
import { userService } from 'common/services/user.service';
import { usePublisher } from 'common/hooks';
import { selectPublishersSettingsForm } from 'modules/publishers-settings/store/selectors';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { ConversionSettings } from './conversion-settings';
import { AffiliateSettings } from './affiliate-settings';
import { EmbedSettings } from './embed-settings';
import { UrlsSettings } from './urls-settings';
import { TagsSettings } from './tags-settings';
import { MainSettings } from './main-settings';
import { IntegrationsSettings } from './integrations-settings';
import { NewTeam } from './team-v2';
import { BrandsSettings } from './brands-settings/brands-settings';

export const getStyles = (): Styles => ({
  content: { display: 'flex', flexDirection: 'column', height: '100%', flexGrow: 1 },
  contentContainer: {
    backgroundColor: '#fff',
    borderRadius: '12px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  steps: {
    padding: '28px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  title: { marginBottom: 3 },
  tabs: {
    borderBottom: '1px solid #C2DEEB40',
  },
  tab: {
    boxSizing: 'border-box',
    padding: '25px 16px',
    ':first-child': { borderRadius: '10px 0 0 0' },
  },
});

export function PublisherSettingsList(): ReactElement {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const styles = getStyles();

  const publisher = usePublisher();
  const publisherEdit = useSelector(selectPublishersSettingsForm);

  const params = useParams<{ nav: PUBLISHER_SETTINGS_NAV; id: string }>();
  const id = params?.id ?? String(userService.user?.publisher_id);

  const handleChangePath = (e: React.SyntheticEvent<Element, Event>, value: PUBLISHER_SETTINGS_NAV) => {
    navigate(`/publisher/${value}`);
  };

  const handleStepRender = () => {
    switch (params.nav) {
      case PUBLISHER_SETTINGS_NAV.settings:
        return <MainSettings />;

      case PUBLISHER_SETTINGS_NAV.tags:
        return <TagsSettings />;

      case PUBLISHER_SETTINGS_NAV.urls:
        return <UrlsSettings />;

      case PUBLISHER_SETTINGS_NAV.embed:
        return <EmbedSettings />;

      case PUBLISHER_SETTINGS_NAV.affiliate:
        return <AffiliateSettings />;

      case PUBLISHER_SETTINGS_NAV.conversion:
        return <ConversionSettings />;

      case PUBLISHER_SETTINGS_NAV.team:
        return <NewTeam />;

      case PUBLISHER_SETTINGS_NAV.integrations:
        return <IntegrationsSettings />;

      case PUBLISHER_SETTINGS_NAV.brands:
        return <BrandsSettings />;

      default:
        return <MainSettings />;
    }
  };

  useEffect(() => {
    const foundCurrentStep = Object.values(PUBLISHER_SETTINGS_NAV).includes(params.nav as PUBLISHER_SETTINGS_NAV);

    if (foundCurrentStep) return;

    navigate(`/publisher/${PUBLISHER_SETTINGS_NAV.settings}`);
  }, [id, params.nav, navigate]);

  useEffect(() => {
    if (publisherEdit.success) dispatch(publishersSettingsActions.getMainPublisherInfo(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisherEdit]);

  return (
    <Box sx={styles.content}>
      <Typography sx={styles.title} variant="h4">
        Settings
      </Typography>
      <Box sx={styles.contentContainer}>
        <Tabs sx={styles.tabs} value={params.nav} onChange={handleChangePath}>
          <Tab sx={styles.tab} label="Settings" value={PUBLISHER_SETTINGS_NAV.settings} />
          {!publisher?.gainz_only && <Tab sx={styles.tab} label="Tags" value={PUBLISHER_SETTINGS_NAV.tags} />}
          {!publisher?.gainz_only && <Tab sx={styles.tab} label="URLs" value={PUBLISHER_SETTINGS_NAV.urls} />}
          {!publisher?.gainz_only && <Tab sx={styles.tab} label="Embed" value={PUBLISHER_SETTINGS_NAV.embed} />}
          {!publisher?.gainz_only && <Tab sx={styles.tab} label="Affiliate" value={PUBLISHER_SETTINGS_NAV.affiliate} />}
          {!publisher?.gainz_only && (
            <Tab sx={styles.tab} label="Conversion" value={PUBLISHER_SETTINGS_NAV.conversion} />
          )}
          <Tab sx={styles.tab} label="Team" value={PUBLISHER_SETTINGS_NAV.team} />
          {!publisher?.gainz_only && (
            <Tab sx={styles.tab} label="Integrations" value={PUBLISHER_SETTINGS_NAV.integrations} />
          )}
          {publisher?.display_commission && (
            <Tab sx={styles.tab} label="Brand Commissions" value={PUBLISHER_SETTINGS_NAV.brands} />
          )}
        </Tabs>
        <Box sx={styles.steps}>{handleStepRender()}</Box>
      </Box>
    </Box>
  );
}
