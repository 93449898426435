const AMAZON_AFFILIATE_REPORTS_MODULE = '[amazon_affiliate_report]';

const UPLOAD_REPORT = `${AMAZON_AFFILIATE_REPORTS_MODULE} UPLOAD_REPORT`;
const UPLOAD_REPORT_SUCCESS = `${AMAZON_AFFILIATE_REPORTS_MODULE} UPLOAD_REPORT_SUCCESS`;
const UPLOAD_REPORT_ERROR = `${AMAZON_AFFILIATE_REPORTS_MODULE} UPLOAD_REPORT_ERROR`;
const SET_UPLOAD_REPORT_STATE = `${AMAZON_AFFILIATE_REPORTS_MODULE} SET_UPLOAD_REPORT_STATE`;

const RECOVER_MISSING_REPORTS = `${AMAZON_AFFILIATE_REPORTS_MODULE} RECOVER_MISSING_REPORTS`;
const RECOVER_MISSING_REPORTS_SUCCESS = `${AMAZON_AFFILIATE_REPORTS_MODULE} RECOVER_MISSING_REPORTS_SUCCESS`;
const RECOVER_MISSING_REPORTS_ERROR = `${AMAZON_AFFILIATE_REPORTS_MODULE} RECOVER_MISSING_REPORTS_ERROR`;
const SET_RECOVER_MISSING_REPORTS_STATE = `${AMAZON_AFFILIATE_REPORTS_MODULE} SET_RECOVER_MISSING_REPORTS_STATE`;

export const amazonAffiliateReportsTypes = {
  UPLOAD_REPORT,
  UPLOAD_REPORT_SUCCESS,
  UPLOAD_REPORT_ERROR,
  RECOVER_MISSING_REPORTS,
  RECOVER_MISSING_REPORTS_SUCCESS,
  RECOVER_MISSING_REPORTS_ERROR,
  SET_RECOVER_MISSING_REPORTS_STATE,
  SET_UPLOAD_REPORT_STATE,
};
