import { Action } from 'common/interfaces/root';
import { publishersSettingsActionTypes as T } from '../actions';

const initState: AffiliateReportModule.AffiliateReportDownloadState = {
  loading: true,
  error: null,
  data: null,
  fileName: null,
};

export default (
  state: AffiliateReportModule.AffiliateReportDownloadState = initState,
  { type, payload }: Action
): AffiliateReportModule.AffiliateReportDownloadState => {
  switch (type) {
    case T.GET_AFFILIATE_REPORT_DOWNLOAD:
      return { ...state, loading: true, data: null, fileName: null };

    case T.GET_AFFILIATE_REPORT_DOWNLOAD_SUCCESS:
      return { ...state, loading: false, data: payload.data, fileName: payload.fileName };

    case T.GET_AFFILIATE_REPORT_DOWNLOAD_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
