import { ApiService } from 'common/services';

export class ParentPublisherSettingsService extends ApiService {
  getPublisherAccounts = () => {
    return this.get('/api/publishers/accounts');
  };

  getPublisherAccountsParents = (payload: ParentPublisherSettingsModule.GetPublisherAccountsParams) => {
    return this.get('/api/publisher-accounts', { params: payload });
  };

  postPublisherAccountParents = (payload: ParentPublisherSettingsModule.PublisherAccountForm) => {
    return this.post('/api/publisher-accounts', payload);
  };

  patchPublisherAccountParents = (payload: ParentPublisherSettingsModule.EditPublisherAccountParams) => {
    return this.patch(`/api/publisher-accounts/${payload.account_id}`, payload.account);
  };

  deletePublisherAccountParents = (account_id: number) => {
    return this.delete(`/api/publisher-accounts/${account_id}`);
  };
}

export const parentPublisherSettingsService = new ParentPublisherSettingsService(process.env.REACT_APP_API_URL);
