import { Box, CircularProgress } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement } from 'react';

const styles: Styles = {
  container: { width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' },
};

export function LoadingContainer(): ReactElement {
  return (
    <Box sx={styles.container}>
      <CircularProgress color="primary" />
    </Box>
  );
}
