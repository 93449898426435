import { ReactElement } from 'react';
import { useFormikContext } from 'formik';
import { Box, Button, OutlinedInput, Typography } from '@mui/material';
import { getStyles as getSettingStyles } from 'modules/user-settings/components/user-settings-content/user-settings-content';
import { Field } from 'common/ui/field';
import { Styles } from 'common/types';

const getStyles = (): Styles => ({
  button: { maxWidth: 100, width: '100%', marginLeft: 1, ':first-child': { marginLeft: 0 }, borderRadius: '10px' },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    padding: '20px 0',
    boxSizing: 'border-box',
    borderBottom: '1px solid #C2DEEB40',
    ':last-child': { borderBottom: 0 },
  },
  field: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '20px 0',
    boxSizing: 'border-box',
  },
});

export function ConversionSettingsContent(): ReactElement {
  const styles = getStyles();
  const settingsStyles = getSettingStyles();
  const { submitForm } = useFormikContext<PublishersSettingsModule.ConversionSettingsForm>();

  return (
    <>
      <Box sx={styles.field}>
        <Typography sx={settingsStyles.titleField}>Ordered Сonversions</Typography>
        <Box sx={settingsStyles.inputWrapper}>
          <Field component={OutlinedInput} fullWidth name="orderedСonversions" />
        </Box>
      </Box>
      <Box sx={styles.field}>
        <Typography sx={settingsStyles.titleField}>Shipped Сonversions</Typography>
        <Box sx={settingsStyles.inputWrapper}>
          <Field component={OutlinedInput} fullWidth name="shippedСonversions" />
        </Box>
      </Box>
      <Box sx={styles.buttons}>
        <Button color="primary" onClick={submitForm} sx={styles.button} variant="contained">
          Save
        </Button>
      </Box>
    </>
  );
}
