import { Box, Typography, Grid, Button, TablePagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Styles } from 'common/types';
import { asinRecommendationSelectors } from 'modules/asin-recommendation/store/selectors';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { asinRecommendationActions } from 'modules/asin-recommendation/store/actions/actions';
import { RecommendItem } from '..';

const styles: Styles = {
  featTitle: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    color: '#060F14',
  },
  cardContainer: { width: '100%', mt: 2 },
  btnContainer: { display: 'flex', justifyContent: 'center', mt: 2 },
};

interface ProductListProps {
  asinParams?: AsinRecommendationModule.Params.GetAsinsParams;
  data?: AsinRecommendationModule.State.AsinsState;
}

const renderData = (
  viewMore: boolean,
  data: AsinRecommendationModule.State.AsinsState
): AsinRecommendationModule.State.Asin[] => {
  return viewMore ? data?.data?.recommended_asins : data?.data?.recommended_asins.slice(0, 20);
};

const scrollTop: ScrollToOptions = { top: 0, behavior: 'smooth' };

export function FeaturedList({ asinParams, data }: ProductListProps): ReactElement {
  const dispatch = useDispatch();

  const [init, setInit] = useState<boolean>(false);

  const grid = useSelector(asinRecommendationSelectors.selectGrid);

  const getAsinsParams = useMemo<AsinRecommendationModule.Params.GetAsinsParams>(
    () => ({
      ...asinParams,
      limit: grid.featuredLimit,
      offset: grid.featuredOffset,
      featured: true,
    }),
    [asinParams, grid]
  );

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(asinRecommendationActions.grid.setFeaturedLimit(parseInt(event.target.value, 10)));
    dispatch(asinRecommendationActions.grid.setFeaturedOffset(0));
    window.scrollTo(scrollTop);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(asinRecommendationActions.grid.setFeaturedOffset(newPage));
    window.scrollTo(scrollTop);
  };

  const handleViewMore = () => {
    dispatch(asinRecommendationActions.grid.setViewMore(!grid.viewMore));
  };

  useEffect(() => {
    if (!grid.viewMore) window.scrollTo(scrollTop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grid.viewMore]);

  useEffect(() => {
    if (init)
      dispatch(
        asinRecommendationActions.featuredAsins.getFeaturedAsins({
          ...getAsinsParams,
          offset: grid.featuredOffset * grid.featuredLimit,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAsinsParams.limit, getAsinsParams.offset]);

  useEffect(() => {
    setInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography sx={styles.featTitle}>Featured Products</Typography>
      <Grid sx={styles.cardContainer} container justifyContent="center" spacing={1} columns={10}>
        {renderData(grid.viewMore, data)?.map((asin, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <RecommendItem key={index} asin={asin} />
        ))}
      </Grid>
      {grid.viewMore && (
        <TablePagination
          count={Number(data?.data?.total) || 0}
          rowsPerPage={grid.featuredLimit}
          page={grid.featuredOffset}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          rowsPerPageOptions={[40, 80, 120]}
        />
      )}
      <Box sx={styles.btnContainer}>
        <Button variant="outlined" onClick={handleViewMore}>
          {grid.viewMore ? 'View less' : 'View more'}
        </Button>
      </Box>
    </>
  );
}
