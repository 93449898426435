import { FiltersPresets } from 'common/interfaces/presets';
import { presetsActionsTypes as T } from './action-types';

const getFiltersPresets = () => ({
  type: T.GET_FILTERS_PRESETS,
});

const getFiltersPresetsSuccess = (presets: FiltersPresets) => ({
  type: T.GET_FILTERS_PRESETS_SUCCESS,
  payload: presets,
});

const getFiltersPresetsError = (error: Error) => ({
  type: T.GET_FILTERS_PRESETS_ERROR,
  payload: error,
});

const getTimeInfo = (publisherId: number) => ({
  type: T.GET_TIME_INFO,
  payload: publisherId,
});

const getTimeInfoSuccess = (date: string) => ({
  type: T.GET_TIME_INFO_SUCCESS,
  payload: date,
});

const getTimeInfoError = (error: Error) => ({
  type: T.GET_TIME_INFO_ERROR,
  payload: error,
});

export const presetsActions = {
  getFiltersPresets,
  getFiltersPresetsSuccess,
  getFiltersPresetsError,
  getTimeInfo,
  getTimeInfoSuccess,
  getTimeInfoError,
};
