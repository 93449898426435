import { Box } from '@mui/material';
import { Range, Styles } from 'common/types';
import { Moment } from 'moment';
import { ReactElement } from 'react';
import { DatePicker } from '../date-picker';
import { TrafficSource } from './traffic-source';
import { Click, DateByValue, Traffic } from './types';
import { UsedPicker, UsedValue } from './used-picker';
import { AffiliateNetworkTypePicker } from './affiliate-network-type-picker';
import { DateBy } from './date-by';
import { TransactionClick } from './transaction-click';

enum FilterType {
  date = 'date',
  compareDates = 'compareDates',
  trafficSource = 'trafficSource',
  used = 'used',
  affiliateNetwork = 'affiliateNetwork',
  dateBy = 'dateBy',
  transactionClick = 'transactionClick',
}

const styles: Styles = {
  container: { display: 'flex', alignItems: 'center' },
};

interface Props {
  handleRangeChange?: (from: Moment, to: Moment) => void;
  date?: Range;
  handleTrafficSourceChange?: (trafficSource: Traffic) => void;
  trafficSource?: Traffic;
  used?: UsedValue;
  handleUsedChange?: (used: UsedValue) => void;
  affiliateNetworkType?: number | string;
  handleAffiliateNetworkTypeChange?: (id: number | string) => void;
  dateBy?: DateByValue;
  handleDateByChange?: (dateBy: DateByValue) => void;
  transactionClick?: Click;
  clicksEnabled?: boolean;
  hanldeTransactionClickChange?: (click: Click) => void;
  show: Array<keyof typeof FilterType> | string[];
}

export function BaseFilters({
  handleRangeChange,
  date,
  trafficSource,
  handleTrafficSourceChange,
  show,
  used,
  handleUsedChange,
  affiliateNetworkType,
  handleAffiliateNetworkTypeChange,
  handleDateByChange,
  dateBy,
  transactionClick,
  clicksEnabled,
  hanldeTransactionClickChange,
}: Props): ReactElement {
  return (
    <Box sx={styles.container}>
      {show.includes(FilterType.date) && (
        <DatePicker
          from={date.from}
          to={date.to}
          onChange={handleRangeChange}
          showCompare={show.includes(FilterType.compareDates)}
        />
      )}
      {show.includes(FilterType.dateBy) && <DateBy dateBy={dateBy} onChange={handleDateByChange} />}
      {show.includes(FilterType.affiliateNetwork) && (
        <AffiliateNetworkTypePicker
          onChange={handleAffiliateNetworkTypeChange}
          affiliateNetworkType={affiliateNetworkType}
        />
      )}
      {show.includes(FilterType.used) && <UsedPicker used={used} onChange={handleUsedChange} />}
      {show.includes(FilterType.trafficSource) && (
        <TrafficSource trafficSource={trafficSource} onChange={handleTrafficSourceChange} />
      )}
      {show.includes(FilterType.transactionClick) && (
        <TransactionClick
          clicksEnabled={clicksEnabled}
          transactionClick={transactionClick}
          onChange={hanldeTransactionClickChange}
        />
      )}
    </Box>
  );
}
