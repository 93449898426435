import { ChangeEvent, ReactElement } from 'react';
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import { selectPublisherTagsFilters } from 'modules/settings/store/selectors';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 1.5 },
  fieldContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  field: { width: '70%' },
  btnContainer: { display: 'flex', justifyContent: 'flex-end', gap: 1.5 },
};

interface Props {
  open: boolean;
  handleApply: () => void;
  handleReset: () => void;
  onClose: () => void;
}

export function TagsFiltersModal({ open, handleApply, handleReset, onClose }: Props): ReactElement {
  const dispatch = useDispatch();

  const filters = useSelector(selectPublisherTagsFilters);

  const handleTrafficTypeChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(publishersSettingsActions.setTrafficType(e.target.value));
  };

  const handleUsedChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(publishersSettingsActions.setUsed(e.target.value));
  };

  return (
    <Modal open={open} onClose={onClose} title="Filters" maxWidth="sm">
      <Box sx={styles.container}>
        <Box sx={styles.fieldContainer}>
          <Typography>Traffic Type</Typography>
          <TextField
            select
            color="primary"
            sx={styles.field}
            value={filters.traffic_type}
            size="small"
            label="Traffic Type"
            onChange={handleTrafficTypeChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="organic">Organic</MenuItem>
            <MenuItem value="paid">Paid</MenuItem>
          </TextField>
        </Box>

        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.title}>Usage</Typography>
          <TextField
            select
            color="primary"
            sx={styles.field}
            value={filters.used}
            size="small"
            label="Usage"
            onChange={handleUsedChanged}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="true">Used</MenuItem>
            <MenuItem value="false">Not Used</MenuItem>
          </TextField>
        </Box>
        <Box sx={styles.btnContainer}>
          <Button sx={styles.btn} variant="outlined" onClick={handleReset}>
            Reset
          </Button>
          <Button sx={styles.btn} variant="contained" onClick={handleApply}>
            Apply
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
