import { Box, Typography } from '@mui/material';
import './oos-replacements-modal-header.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectPagesPerformance } from '../../../../../store/selectors';
import { contentOverviewActions } from '../../../../../store/actions';

const sx = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  articleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  articleActionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    width: '200px',
  },
  nextArticleTitle: {
    margin: '0 15px',
  },
};

interface Props {
  article: Pick<ContentOverviewModule.PagePerformance, 'url' | 'title'>;
  isLoading: boolean;
}

export function OOSReplacementsModalHeader({ article, isLoading }: Props) {
  const dispatch = useDispatch();
  const pagesPerformance = useSelector(selectPagesPerformance);
  const changeActiveArticle = (step: number) => {
    const articleIdx = pagesPerformance.reduce((acc, p, idx) => {
      if (p.url === article.url) acc = idx;
      return acc;
    }, -1);

    if (articleIdx === -1) return;

    const nextArticleIdx = articleIdx + step;
    if (nextArticleIdx >= 0 && nextArticleIdx <= pagesPerformance.length - 1) {
      dispatch(contentOverviewActions.setPagePerformanceActiveArticle(pagesPerformance[nextArticleIdx]));
    }
  };

  return (
    <Box sx={sx.container} className="oos-replacements-modal__header">
      <Typography>Replace Out of stock</Typography>
      <Box sx={sx.articleContainer}>
        <Typography className="link" onClick={() => window.open(article.url)} sx={{ color: '#4288F0' }}>
          {article.title}
        </Typography>
        <Box sx={sx.articleActionsContainer}>
          {/* arrow left */}
          {!isLoading && (
            <svg
              onClick={() => changeActiveArticle(-1)}
              height="20"
              width="20"
              viewBox="0 0 13 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.1195 5.93404L2.89391 6.32604L6.50862 9.69682L5.48563 10.7938L0.000473648 5.67886L5.11546 0.193709L6.21249 1.21671L2.84171 4.83142L14.0673 4.43942L14.1195 5.93404Z"
                fill="black"
              />
            </svg>
          )}

          <Typography sx={sx.nextArticleTitle}>Next Article</Typography>
          {/* arrow right */}
          {!isLoading && (
            <svg
              onClick={() => changeActiveArticle(1)}
              height="20"
              width="20"
              viewBox="0 0 15 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.153981 5.24467L11.3795 4.85267L7.76481 1.48189L8.78781 0.384862L14.273 5.49985L9.15798 10.985L8.06095 9.962L11.4317 6.34729L0.206174 6.73929L0.153981 5.24467Z"
                fill="black"
              />
            </svg>
          )}
        </Box>
      </Box>
    </Box>
  );
}
