import { Action } from 'common/interfaces/root';
import { publishersSettingsActionTypes as T } from '../actions';

const initState: PublishersSettingsModule.TeamStore = {
  loading: true,
  error: null,
  data: null,
  loadingClients: true,
  clientsData: null,
};

export default (
  state: PublishersSettingsModule.TeamStore = initState,
  { type, payload }: Action
): PublishersSettingsModule.TeamStore => {
  switch (type) {
    case T.GET_TEAM:
      return { ...state, loading: true };

    case T.GET_TEAM_SUCCESS:
      return { ...state, loading: false, data: payload.data };

    case T.GET_TEAM_ERROR:
      return { ...state, loading: false, error: payload };

    case T.GET_CLIENTS:
      return { ...state, loadingClients: true };

    case T.GET_CLIENTS_SUCCESS:
      return { ...state, loadingClients: false, clientsData: payload.data };

    case T.GET_CLIENTS_ERROR:
      return { ...state, loadingClients: false, error: payload };

    default:
      return state;
  }
};
