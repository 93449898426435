import moment from 'moment';
import { Box, TableCell, TableRow } from '@mui/material';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { TableRowActions } from 'common/ui/table-row-actions';
import { contentOverviewActions } from 'modules/content-overview/store/actions';
import { ReactElement } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { theme } from 'common/constants/theme';

const getStyles = ({ isDemoMode }: { isDemoMode: boolean }): Styles => ({
  successCircle: {
    width: '8px',
    height: '8px',
    backgroundColor: '#62D294',
    borderRadius: '50%',
  },
  sitemapCell: {
    filter: isDemoMode ? 'blur(3px)' : 0,
  },
});

interface Props {
  sitemap: ContentOverviewModule.Sitemap;
  handleUpdateSitemaps: () => void;
  isDemoMode?: boolean;
}

export function SitemapsTableRow({ sitemap, handleUpdateSitemaps, isDemoMode }: Props): ReactElement {
  const styles = getStyles({ isDemoMode });
  const dispatch = useDispatch();
  const filters = useSelector(selectGlobalFilters);

  const handleSuccessDelete = () => {
    toast.success('Publisher sitemap was deleted');
    handleUpdateSitemaps();
  };

  const handleDeleteRow = () => {
    dispatch(
      contentOverviewActions.deleteSitemap(
        {
          publisherId: filters?.publisher_id,
          id: sitemap?.id,
        },
        handleSuccessDelete
      )
    );
  };

  const getStatusColor = () => {
    switch (sitemap.scraping_status) {
      case 'in-progress':
        return theme.palette.warning.light;
      case 'pending':
        switch (sitemap.last_scraped_result) {
          case 'success':
            return theme.palette.success.light;
          case 'failed':
            return theme.palette.error.light;
          default:
            return theme.palette.primary.light;
        }
      default:
        return theme.palette.primary.light;
    }
  };

  return (
    <TableRow>
      <TableCell sx={styles.sitemapCell}>{sitemap.sitemap_url}</TableCell>
      <TableCell>
        {sitemap?.last_scraped_at
          ? `${moment(sitemap?.last_scraped_at).format('DD MMM, YY')} at ${moment(sitemap?.last_scraped_at).format(
              'hh:mm'
            )}`
          : ''}
      </TableCell>
      <TableCell>
        <Box sx={{ ...styles.successCircle, backgroundColor: getStatusColor() }} />
      </TableCell>
      <TableCell>
        <TableRowActions show={['delete']} onDelete={handleDeleteRow} />
      </TableCell>
    </TableRow>
  );
}
