import { ReactElement, useMemo } from 'react';
import {
  DateRangePicker,
  Range,
  RangeKeyDict,
  createStaticRanges,
  defaultStaticRanges,
  StaticRange,
} from 'react-date-range';
import { Box, Checkbox, Popover } from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { primary } from 'common/constants/colors';
import moment from 'moment';
import { selectDatePickerComparator, selectFiltersPresets } from 'common/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Styles } from 'common/types';
import { datePickerComparatorActions } from 'common/store/actions/date-picker-comparator';
import { DatePickerComparator } from '../date-picker-comparator';

const styles: Styles = {
  options: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    p: 1,
    borderTop: '1px solid #C2DEEB40',
  },
};

interface Props {
  onClose: () => void;
  anchorElement: HTMLElement | null;
  date: Array<Range>;
  onChange: (range: RangeKeyDict) => void;
  showCompare?: boolean;
}

export function DatePickerCalendar({ onChange, onClose, anchorElement, date, showCompare }: Props): ReactElement {
  const dispatch = useDispatch();

  const presets = useSelector(selectFiltersPresets);
  const { isCompare } = useSelector(selectDatePickerComparator);

  const minDate = useMemo(() => {
    const dates = Object.values(presets?.dateInfo ?? {})
      .filter(Boolean)
      .map(v => moment(v));
    return moment.min(dates).toDate();
  }, [presets?.dateInfo]);

  const onCompareChange = () => {
    dispatch(datePickerComparatorActions.changeIsCompare(!isCompare));
  };

  const ranges: StaticRange[] = [
    {
      label: 'Lifetime',
      range: () => ({
        startDate: minDate,
        endDate: new Date(),
      }),
      isSelected: () => false,
    },
  ];

  return (
    <Popover
      onClose={onClose}
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box>
        {isCompare ? (
          <DatePickerComparator />
        ) : (
          <DateRangePicker
            maxDate={moment().toDate()}
            color={primary}
            ranges={date}
            onChange={onChange}
            staticRanges={[...defaultStaticRanges, ...createStaticRanges(ranges)]}
          />
        )}
        {showCompare && (
          <Box sx={styles.options}>
            Compare:
            <Checkbox value={isCompare} checked={isCompare} onChange={onCompareChange} size="small" />
          </Box>
        )}
      </Box>
    </Popover>
  );
}
