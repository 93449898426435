import { actionTypes } from './action-types';

const getTable = () => ({
  type: actionTypes.table.GET_TABLE,
});

const setRows = (rows: Conversions.Table.TableRow[]) => ({
  type: actionTypes.table.SET_ROWS,
  payload: rows,
});

const setDirection = (direction: 'asc' | 'desc') => ({
  type: actionTypes.table.SET_DIRECTION,
  payload: direction,
});

const setTotal = (total: number) => ({
  type: actionTypes.table.SET_TOTAL,
  payload: total,
});

const setPageSize = (pageSize: number) => ({
  type: actionTypes.table.SET_PAGE_SIZE,
  payload: pageSize,
});

const setPage = (page: number) => ({
  type: actionTypes.table.SET_PAGE,
  payload: page,
});

export const tableActions = {
  getTable,
  setRows,
  setDirection,
  setTotal,
  setPageSize,
  setPage,
};
