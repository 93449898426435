import { Box, Button, CircularProgress, SvgIconTypeMap, Tooltip, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { paper, text } from 'common/constants/colors';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';
import { TitleTooltip } from 'common/ui/title-tooltip';
import { ReactElement } from 'react';
import { Warning } from '../warning';
import { WarningProps } from '../warning/types';

interface StylesProps {
  fullheight: boolean;
}

const getStyles = ({ fullheight }: StylesProps): Styles => ({
  card: {
    width: '100%',
    height: fullheight ? '100%' : 90,
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0 2px 5px rgb(0 0 0 / 5%)',
    borderRadius: '20px',
    backgroundColor: paper,
    padding: theme.spacing(2, 2.5),
    boxSizing: 'border-box',
  },
  valueContainer: { display: 'flex', flexDirection: 'column' },
  value: { display: 'flex', alignItems: 'center', gap: 1 },
  cardTitle: { fontSize: 14, fontWeight: 400 },
  cardValue: { color: text, fontSize: 27 },
  progress: { marginTop: 1 },
  noData: {
    backgroundColor: theme.palette.grey['400'],
    color: paper,
    alignSelf: 'flex-start',
    p: theme.spacing(0.5, 1),
    fontSize: 12,
    borderRadius: '6px',
    mt: 1,
  },
  iconWrapper: {
    minWidth: 36,
    minHeight: 36,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  warningBtn: {
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.primary,
    mt: 1,
    ':hover': { backgroundColor: theme.palette.grey[200] },
  },
});

interface Props {
  title: string;
  value: string | number;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  embedText?: string;
  shouldEmbed?: boolean;
  embedCallback?: () => void;
  tip?: string;
  fullheight?: boolean;
  embedButtonText?: string;
  warning?: WarningProps;
}

export function CardContainer({
  title,
  value,
  loading,
  icon,
  fullheight = false,
  tip,
  embedText,
  shouldEmbed,
  embedButtonText,
  embedCallback,
  warning,
}: Props): ReactElement {
  const styles = getStyles({ fullheight });
  if (!embedButtonText) embedButtonText = 'Embed';

  const Icon = icon;

  return (
    <Box sx={styles.card}>
      <Box sx={styles.valueContainer}>
        {tip ? (
          <TitleTooltip title={title} tooltip={tip} typographyProps={{ sx: styles.cardTitle }} />
        ) : (
          <Typography sx={styles.cardTitle}>{title}</Typography>
        )}
        {loading && <CircularProgress size={30} color="primary" sx={styles.progress} />}
        {!loading && Boolean(value) && !shouldEmbed && (
          <Box sx={styles.value}>
            <Typography sx={styles.cardValue}>{value}</Typography>
            {warning && <Warning tooltip={warning?.tooltip} severity={warning?.severity} />}
          </Box>
        )}
        {!loading && !value && !shouldEmbed && <Box sx={styles.noData}>No Data</Box>}
        {!loading && shouldEmbed && (
          <Tooltip title={embedText} placement="top">
            <Button size="small" variant="contained" sx={styles.warningBtn} onClick={embedCallback}>
              {embedButtonText}
            </Button>
          </Tooltip>
        )}
      </Box>
      <Box sx={styles.iconWrapper}>
        <Icon fontSize="small" />
      </Box>
    </Box>
  );
}
