import { ReactElement } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { useSelector } from 'react-redux';
import { selectStatsData, selectStatsLoading } from 'modules/content-overview/store/selectors';
import { formatNum } from 'common/utils/format-num';
import { carrickTips } from 'common/constants/tips';
import { TitleTooltip } from 'common/ui/title-tooltip';

const getStyles = (): Styles => ({
  stats: {
    width: '100%',
    marginTop: 2,
    display: 'grid',
    gridTemplateColumns: { md: '1fr 1fr 1fr 1fr 1fr' },
    gap: 2,
  },
  stat: {
    minHeight: '100px',
    height: '100%',
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    boxShadow: '0px 5px 20px rgba(21, 30, 34, 0.03)',
    p: '28px 26px',
    boxSizing: 'border-box',
  },
  statTitle: {
    color: '#214254',
    fontSize: '13px',
    lineHeight: '24px',
  },
  statValue: {
    color: '#060F14',
    fontSize: '27px',
    lineHeight: 0,
  },
  statValueContainer: {
    display: 'flex',
    flexDirection: 'row',
    mt: 2,
  },
  statPercents: {
    color: '#6F8490',
    ml: 1,
    lineHeight: 0,
  },
});

export function ReplaceProductsStats(): ReactElement {
  const styles = getStyles();
  const stats = useSelector(selectStatsData);
  const loading = useSelector(selectStatsLoading);

  return (
    <Box sx={styles.stats}>
      <Box sx={styles.stat}>
        <TitleTooltip
          title="Articles"
          tooltip={carrickTips.contentOverview.productPlacement.articles}
          typographyProps={{ sx: styles.statTitle }}
        />
        <Box sx={styles.statValueContainer}>
          {!loading ? (
            <Typography sx={styles.statValue}>{stats?.articles ? formatNum(stats?.articles) : '-'}</Typography>
          ) : (
            <CircularProgress size={14} />
          )}
        </Box>
      </Box>
      <Box sx={styles.stat}>
        <TitleTooltip
          title="Articles with an ASIN"
          tooltip={carrickTips.contentOverview.productPlacement.articlesWithAsin}
          typographyProps={{ sx: styles.statTitle }}
        />
        <Box sx={styles.statValueContainer}>
          {!loading ? (
            <Typography sx={styles.statValue}>
              {stats?.articles_with_asin ? formatNum(stats?.articles_with_asin) : '-'}
            </Typography>
          ) : (
            <CircularProgress size={14} />
          )}
        </Box>
      </Box>
      <Box sx={styles.stat}>
        <TitleTooltip
          title="Articles with at least one OOS"
          tooltip={carrickTips.contentOverview.productPlacement.articlesWithOosAsin}
          typographyProps={{ sx: styles.statTitle }}
        />
        <Box sx={styles.statValueContainer}>
          {!loading ? (
            <>
              <Typography sx={styles.statValue}>
                {stats?.oos_articles ? formatNum(stats?.oos_articles) : '-'}
              </Typography>
              <Typography sx={styles.statPercents}>
                {stats?.oos_articles_percent ? `${stats?.oos_articles_percent}%` : ''}
              </Typography>
            </>
          ) : (
            <CircularProgress size={14} />
          )}
        </Box>
      </Box>
      <Box sx={styles.stat}>
        <TitleTooltip
          title="ASINs in Articles"
          tooltip={carrickTips.contentOverview.productPlacement.asinsInArticles}
          typographyProps={{ sx: styles.statTitle }}
        />
        <Box sx={styles.statValueContainer}>
          {!loading ? (
            <Typography sx={styles.statValue}>
              {stats?.articles_asins ? formatNum(stats?.articles_asins) : '-'}
            </Typography>
          ) : (
            <CircularProgress size={14} />
          )}
        </Box>
      </Box>
      <Box sx={styles.stat}>
        <TitleTooltip
          title="OOS ASINs"
          tooltip={carrickTips.contentOverview.productPlacement.oosAsins}
          typographyProps={{ sx: styles.statTitle }}
        />
        <Box sx={styles.statValueContainer}>
          {!loading ? (
            <>
              <Typography sx={styles.statValue}>{stats?.oos_asins ? formatNum(stats?.oos_asins) : '-'}</Typography>
              <Typography sx={styles.statPercents}>
                {stats?.oos_asins_percent ? `${stats?.oos_asins_percent}%` : ''}
              </Typography>
            </>
          ) : (
            <CircularProgress size={14} />
          )}
        </Box>
      </Box>
    </Box>
  );
}
