import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Styles } from 'common/types';
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { selectGlobalFilters } from 'common/store/selectors';
import { InviteModal } from './invite-modal';
import { TeamTable } from './team-table';

const getStyles = (): Styles => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  inviteWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  inviteButton: {
    width: '100%',
    maxWidth: '200px',
  },
  teamHeader: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '125%',
    color: '#060F14',
    marginTop: '35px',
    marginBottom: '15px',
  },
  select: {
    width: '150px',
  },
});

export function NewTeam(): ReactElement {
  const dispatch = useDispatch();
  const styles = getStyles();

  const globalFilters = useSelector(selectGlobalFilters);

  const [isOpenInviteModal, setIsOpenInviteModal] = useState<boolean>();
  const [clientType, setClientType] = useState<string>('current');

  const handleOpenInviteModal = () => {
    setIsOpenInviteModal(true);
  };

  const handleCloseInviteModal = () => {
    setIsOpenInviteModal(false);
  };

  const handleChangeClientType = (e: ChangeEvent<HTMLInputElement>) => {
    setClientType(e.target.value);
  };

  useEffect(() => {
    dispatch(publishersSettingsActions.getClients());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clientType === 'current') {
      dispatch(publishersSettingsActions.getTeam(globalFilters?.publisher_id));
    } else {
      dispatch(publishersSettingsActions.getTeam());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientType, globalFilters?.publisher_id]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.inviteWrapper}>
        <TextField
          sx={styles.select}
          color="primary"
          onChange={handleChangeClientType}
          size="small"
          select
          value={clientType}
        >
          <MenuItem value="current">
            <Typography>Current client</Typography>
          </MenuItem>
          <MenuItem value="all">
            <Typography>All clients</Typography>
          </MenuItem>
        </TextField>
        <Button sx={styles.inviteButton} variant="contained" onClick={handleOpenInviteModal}>
          Invite User
        </Button>
      </Box>
      <Typography sx={styles.teamHeader}>Our team</Typography>
      <TeamTable clientType={clientType} />
      <InviteModal clientType={clientType} open={isOpenInviteModal} onClose={handleCloseInviteModal} />
    </Box>
  );
}
