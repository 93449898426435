export enum ManagementTab {
  SITEMAPS = 0,
  URLS,
  CRAWLING,
}

export enum PerformanceTableSortField {
  clicks = 'clicks',
  conversion = 'conversion',
  total_ad_fees = 'total_ad_fees',
  total_revenue = 'total_revenue',
  title = 'title',
  visits = 'visits',
  revenue_per_visit = 'revenue_per_visit',
  desktop_ad_fees = 'desktop_ad_fees',
  desktop_clicks = 'desktop_clicks',
  desktop_conversion = 'desktop_conversion',
  desktop_revenue = 'desktop_revenue',
  desktop_shipments = 'desktop_shipments',
  desktop_visits = 'desktop_visits',
  mobile_ad_fees = 'mobile_ad_fees',
  mobile_clicks = 'mobile_clicks',
  mobile_conversion = 'mobile_conversion',
  mobile_revenue = 'mobile_revenue',
  mobile_shipments = 'mobile_shipments',
  mobile_visits = 'mobile_visits',
}

export const COLUMNS_LC_KEY = 'pages_performance_columns';

export enum ScheduleCron {
  ONCE_A_WEEK = 'once a week',
  ONCE_A_TWO_WEEKS = 'once a two weeks',
  ONCE_A_MONTH = 'once a month',
  ONCE_A_THREE_MONTHS = 'once a three months',
  ONCE_A_SIX_MONTHS = 'once a six months',
}
