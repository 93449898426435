const TABLE_MODULE = '[revenue-by-page:statistics]';
const TOP10_MODULE = '[revenue-by-page:top10]';
const EXPORT_MODULE = '[revenue-by-page:export]';

const GET_REVENUE_BY_PAGE_TABLE = `${TABLE_MODULE} GET_REVENUE_BY_PAGE_TABLE`;
const GET_REVENUE_BY_PAGE_TABLE_SUCCESS = `${TABLE_MODULE} GET_REVENUE_BY_PAGE_TABLE_SUCCESS`;
const GET_REVENUE_BY_PAGE_TABLE_ERROR = `${TABLE_MODULE} GET_REVENUE_BY_PAGE_TABLE_ERROR`;

const CHANGE_REVENUE_TABLE_PAGE = `${TABLE_MODULE} CHANGE_REVENUE_TABLE_PAGE`;
const CHANGE_REVENUE_TABLE_SORT = `${TABLE_MODULE} CHANGE_REVENUE_TABLE_SORT`;
const CHANGE_REVENUE_TABLE_ROWS_PER_PAGE = `${TABLE_MODULE} CHANGE_REVENUE_TABLE_ROWS_PER_PAGE`;

const GET_TOP10_PAGES = `${TOP10_MODULE} GET_TOP10_PAGES`;
const GET_TOP10_PAGES_SUCCESS = `${TOP10_MODULE} GET_TOP10_PAGES_SUCCESS`;
const GET_TOP10_PAGES_ERROR = `${TOP10_MODULE} GET_TOP10_PAGES_ERROR`;

const EXPORT_TOP10_PAGES = `${EXPORT_MODULE} EXPORT_TOP10_PAGES`;
const EXPORT_PAGES = `${EXPORT_MODULE} EXPORT_PAGES`;
const EXPORT_SUCCESS = `${EXPORT_MODULE} EXPORT_SUCCESS`;
const EXPORT_ERROR = `${EXPORT_MODULE} EXPORT_ERROR`;

export const revenueByPageActionTypes = {
  GET_REVENUE_BY_PAGE_TABLE,
  GET_REVENUE_BY_PAGE_TABLE_SUCCESS,
  GET_REVENUE_BY_PAGE_TABLE_ERROR,
  CHANGE_REVENUE_TABLE_PAGE,
  GET_TOP10_PAGES,
  GET_TOP10_PAGES_SUCCESS,
  GET_TOP10_PAGES_ERROR,
  CHANGE_REVENUE_TABLE_SORT,
  CHANGE_REVENUE_TABLE_ROWS_PER_PAGE,
  EXPORT_TOP10_PAGES,
  EXPORT_PAGES,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
};
