import { Box, Button, TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';
import { Product } from 'common/ui/product';
import { StatusDropdown } from 'common/ui/status-dropdown';
import { Dispatch, MouseEvent, ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPlacements } from 'modules/affiliate-gainz/store/selectors';
import { UrlPopover } from './url-popover';

const styles: Styles = {
  previewContent: {
    fontSize: 12,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    ':hover': { textDecoration: 'underline' },
  },
  urlsContainer: { display: 'flex', flexDirection: 'column', gap: 0.5 },
  url: {
    display: 'flex',
    alignItems: 'center',
    color: '#4288F0',
    cursor: 'pointer',
    fontSize: 11,
    gap: 0.5,
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  productContainer: { maxWidth: 250, width: '100%' },
  btn: { color: 'black' },
};

interface Props {
  row: AffiliateGainzModule.RecommendedAsinsModule.TableRow;
  selected: AffiliateGainzModule.RecommendedAsinsModule.TableRow | null;
  selectedCol: AffiliateGainzModule.RecommendedAsinsModule.SelectedColumns;
  setSelected: Dispatch<SetStateAction<AffiliateGainzModule.RecommendedAsinsModule.TableRow>>;
}

export function AsinsRow({ row, selected, selectedCol, setSelected }: Props): ReactElement {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const placements = useSelector(selectPlacements);

  const money = new Intl.NumberFormat('en', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 });

  const urls = useMemo(() => {
    const splitted = row.urls?.split(',') ?? [];
    return splitted.map(url => {
      const path = new URL(url).pathname ?? '-';
      return { url, path: path.length > 50 ? `${path.slice(0, 50)}...` : path };
    });
  }, [row.urls]);

  const handleUrlsOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(e.currentTarget);
  };

  const handlePlacementsOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setSelected(row);
    setAnchorElement(e.currentTarget);
  };

  const handleUrlsClose = () => {
    setAnchorElement(null);
  };

  useEffect(() => {
    setIsSelected(selected?.asin === row?.asin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <TableRow>
      {selectedCol.product && (
        <TableCell>
          <Box sx={styles.productContainer}>
            <Product product={{ ...row.extended_product, is_attribution: row.is_attribution, type: row.type }} />
          </Box>
        </TableCell>
      )}
      {selectedCol.brand && <TableCell>{row.brand}</TableCell>}
      {/* {selectedCol.preview && (
        <TableCell>
          <Typography sx={styles.previewContent} onClick={() => handlePreview()}>
            Preview content
          </Typography>
        </TableCell>
      )} */}
      {selectedCol.product_price && (
        <TableCell>{row.extended_product?.price ? money.format(row.extended_product?.price) : '-'}</TableCell>
      )}
      {selectedCol.opportunity_earn && (
        <TableCell>{row.affiliate_rate ? `${row.affiliate_rate}% / sale` : '-'}</TableCell>
      )}
      {selectedCol.urls && (
        <TableCell>
          {urls.length && row.asin_status === 'In Article' ? (
            <>
              <Button
                sx={styles.btn}
                variant="text"
                endIcon={<KeyboardArrowDownIcon fontSize="small" sx={styles.arrowIcon} />}
                onClick={handleUrlsOpen}
              >
                Urls
              </Button>
              <UrlPopover urls={urls} onClose={handleUrlsClose} anchorElement={anchorElement} isSelected={isSelected} />
            </>
          ) : (
            '-'
          )}
        </TableCell>
      )}
      {selectedCol.suggested_articles && (
        <TableCell>
          {row.asin_status === 'Not In Article' ? (
            <>
              <Button
                sx={styles.btn}
                variant="text"
                endIcon={<KeyboardArrowDownIcon fontSize="small" sx={styles.arrowIcon} />}
                onClick={handlePlacementsOpen}
              >
                Suggested Articles
              </Button>
              <UrlPopover
                placements={placements}
                onClose={handleUrlsClose}
                anchorElement={anchorElement}
                isSelected={isSelected}
              />
            </>
          ) : (
            '-'
          )}
        </TableCell>
      )}
      {selectedCol.status && (
        <TableCell>
          <StatusDropdown value={row.asin_status} showExpand={false} />
        </TableCell>
      )}
      <TableCell />
    </TableRow>
  );
}
