import { MouseEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Styles } from 'common/types';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { TableRowActions } from 'common/ui/table-row-actions';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentPublisherUrlsPage,
  selectPublisherInfo,
  selectPublisherUrls,
  selectPublisherUrlsAmount,
  selectPublisherUrlsLoading,
} from 'modules/settings/store/selectors';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { useDebouncedCallback } from 'use-debounce';
import { selectGlobalFilters, selectRootDemo } from 'common/store/selectors';

const getStyles = ({ isDemoMode }: { isDemoMode: boolean }): Styles => ({
  table: { marginTop: 0 },
  cell: { color: '#21425480', fontSize: '10px' },
  link: {
    color: '#4288F0',
    fontSize: '14px',
    filter: isDemoMode ? 'blur(3px)' : 0,
  },
  title: { fontWeight: 600 },
  tabsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '.MuiCheckbox-root': {
      padding: '0px 9px',
    },
  },
});

export function UrlsSettings(): ReactElement {
  const dispatch = useDispatch();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const loading = useSelector(selectPublisherUrlsLoading);
  const amount = useSelector(selectPublisherUrlsAmount);
  const currentPage = useSelector(selectCurrentPublisherUrlsPage);
  const rows = useSelector(selectPublisherUrls);
  const filters = useSelector(selectGlobalFilters);
  const publisher = useSelector(selectPublisherInfo);
  const isDemoMode = useSelector(selectRootDemo);

  const styles = getStyles({ isDemoMode });
  const id = String(filters?.publisher_id);

  const handlePageChange = (_e: MouseEvent<HTMLButtonElement>, page: number) => {
    dispatch(publishersSettingsActions.changePublisherUrlsPage(page));
  };

  const parsedFiltersForTable: IStatisticsQueryString = useMemo(
    () => ({
      limit: 10,
      offset: currentPage * 10,
      publisher_id: Number(id),
    }),
    [currentPage, id]
  );

  const debouncedTableStatistics = useDebouncedCallback((filters: IStatisticsQueryString) => {
    dispatch(publishersSettingsActions.getPublisherUrls(filters));
  }, 300);

  const handleUpdateCrawlerCheck = () => {
    dispatch(publishersSettingsActions.changePublisherCrawler(!publisher.url_scheduled_scraping, +publisher.id));
  };

  useEffect(() => {
    if (!isInitialized) return;

    debouncedTableStatistics(parsedFiltersForTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedFiltersForTable]);

  useEffect(() => {
    setIsInitialized(true);

    dispatch(publishersSettingsActions.getPublisherUrls(parsedFiltersForTable));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={styles.tabsWrapper}>
        <Typography sx={styles.title}>These are the URLs identified by running the JS on your website</Typography>
        <FormControlLabel
          control={<Switch checked={publisher?.url_scheduled_scraping} onClick={handleUpdateCrawlerCheck} />}
          label="Daily scraping"
        />
      </Box>
      <TableContainerWithLoading sx={styles.table} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.cell}>URL/Sitemap</TableCell>
              <TableCell sx={styles.cell}>Last Modified</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map(row => (
              <TableRow>
                <TableCell>
                  <Typography sx={styles.link}>{row?.url_path}</Typography>
                </TableCell>
                <TableCell>
                  {row?.updated_at
                    ? `${moment(row?.updated_at).format('DD MMM, YY')} at ${moment(row?.updated_at).format('hh:mm')}`
                    : ''}
                </TableCell>
                <TableCell>
                  <TableRowActions show={['edit']} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <TablePagination
        component="div"
        count={amount}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        page={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}
