import { Action } from 'common/interfaces/root';
import { actionTypes } from '../actions/action-types';

const initialState: Conversions.Table.TableState = {
  rows: [],
  direction: 'desc',
  total: 0,
  pageSize: 50,
  page: 0,
};

export default (
  state: Conversions.Table.TableState = initialState,
  { type, payload }: Action
): Conversions.Table.TableState => {
  switch (type) {
    case actionTypes.table.GET_TABLE:
      return { ...state };
    case actionTypes.table.SET_ROWS:
      return { ...state, rows: payload };
    case actionTypes.table.SET_DIRECTION:
      return { ...state, direction: payload };
    case actionTypes.table.SET_TOTAL:
      return { ...state, total: payload };
    case actionTypes.table.SET_PAGE_SIZE:
      return { ...state, pageSize: payload };
    case actionTypes.table.SET_PAGE:
      return { ...state, page: payload };
    default:
      return state;
  }
};
