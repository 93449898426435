import { DateByValue, IStatisticsQueryString, Traffic } from 'common/ui/base-filters/types';
import moment from 'moment-timezone';

const getBiggestDateRange = (dateRangesStr: string) => {
  const dateRanges = dateRangesStr.split(',');

  const dateRange = dateRanges.reduce((acc, el: string) => {
    if (!acc) return el;
    const [dateFrom] = el.split('_');
    const [accDateFrom] = acc.split('_');

    if (new Date(dateFrom).getTime() > new Date(accDateFrom).getTime()) {
      return el;
    }

    return acc;
  }, null);

  const [date_from, date_to] = dateRange.split('_');
  return { date_from, date_to };
};
export const parseFilters = (filters: IStatisticsQueryString & { brand_name?: string }) => {
  const dateFrom = moment(filters.date_from).tz('EST', true);
  const dateTo = moment(filters.date_to).tz('EST', true);
  const startDate = moment(filters.start_date).tz('EST', true);
  const endDate = moment(filters.end_date).tz('EST', true);

  const dateFormat = 'YYYY-MM-DD';

  const parsedFilters = {
    publisher_id: filters.publisher_id,
    limit: filters.limit,
    offset: filters.offset,
    date_by: filters.date_by,
    oos_status: filters.oos_status,
    ...(filters.publishers_ids ? { publishers_ids: filters.publishers_ids } : {}),
    ...(filters.date_by === DateByValue.Transactional ? { clicks: filters.clicks } : {}),
    ...(filters.search ? { search: filters.search } : {}),
    ...(filters.in_stock !== undefined ? { in_stock: filters.in_stock } : {}),
    ...(filters.in_stock === 0 && filters?.oos_range?.from !== 'any'
      ? { oos_range_from: filters?.oos_range?.from }
      : {}),
    ...(filters.in_stock === 0 && filters?.oos_range?.to !== 'any' ? { oos_range_to: filters?.oos_range?.to } : {}),
    ...(filters.sort ? { sort: `${filters.sort.field}:${filters.sort.direction}` } : {}),
    ...(filters.traffic_type === Traffic.Combined ? {} : { traffic_type: filters.traffic_type }),
    ...(Number(filters.used) === -1 ? {} : { used: filters.used }),
    ...(Number(filters.affiliate_network_type) === -1
      ? {}
      : { affiliate_network_type: filters.affiliate_network_type }),
    ...(filters.date_from ? { date_from: dateFrom.toISOString() } : {}),
    ...(filters.date_to
      ? { date_to: dateFrom.isSame(dateTo) ? dateTo.endOf('day').toISOString() : dateTo.toISOString() }
      : {}),
    ...(filters.start_date ? { start_date: startDate.format(dateFormat) } : {}),
    ...(filters.end_date
      ? {
          end_date: startDate.isSame(endDate) ? endDate.endOf('day').format(dateFormat) : endDate.format(dateFormat),
        }
      : {}),
    ...(filters.breakdown ? { breakdown: filters.breakdown } : {}),
    ...(filters.daily !== null || filters.daily !== undefined ? { daily: filters.daily } : {}),
    publisher_group: filters.publisher_group,
    article_type: filters.article_type,
    ad_only: filters.ad_only,
    brand_name: filters.brand_name,
    saturation_percent_condition: filters.saturation_operator,
    saturation_percent_value: filters.saturation_value,
    ...(filters.dates_to_compare === '' ? {} : { dates_to_compare: filters.dates_to_compare }),
    publisher_name: filters.publisher_name,
    ...(filters.brand_id ? { brand_id: filters.brand_id } : {}),
    ...(filters.asins ? { asins: filters.asins } : {}),
    ...(filters.boost ? { boost: filters.boost } : {}),
    ...(filters.compare_operator ? { compare_operator: filters.compare_operator } : {}),
  };

  if (!filters.dates_to_compare || !filters.dates_to_compare.length) {
    return parsedFilters;
  }

  const { date_from, date_to } = getBiggestDateRange(filters.dates_to_compare);
  if (!parsedFilters.date_from) {
    parsedFilters.date_from = date_from;
  }
  if (!parsedFilters.date_to) {
    parsedFilters.date_to = date_to;
  }
  return parsedFilters;
};
