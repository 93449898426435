import { combineReducers } from 'redux';
import asins from './asins';
import filters from './filters';
import exports from './export';
import categories from './categories';
import placements from './placements';
import featuredAsins from './featured-asins';
import table from './table';
import grid from './grid';
import brands from './brands';
import types from './types';
import variations from './variations';

export const asinRecommendationReducer = combineReducers({
  filters,
  asins,
  exports,
  categories,
  placements,
  featuredAsins,
  table,
  grid,
  brands,
  types,
  variations,
});
