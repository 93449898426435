import { IStatisticsQueryString, ITableSort } from 'common/ui/base-filters/types';
import { PublisherTagsActionTypes as TYPE } from './publisher-tags-action-types';

const getPublishersTags = (filters?: IStatisticsQueryString) => ({
  type: TYPE.GET_PUBLISHERS_TAGS,
  payload: filters,
});

const getPublishersTagsSuccess = (data: PublishersAffiliateTagsModule.PublisherTags[]) => ({
  type: TYPE.GET_PUBLISHERS_TAGS_SUCCESS,
  payload: data,
});

const getPublishersTagsError = (error: Error) => ({
  type: TYPE.GET_PUBLISHERS_TAGS_ERROR,
  payload: error,
});

const changePublishersTagsSort = (sort: ITableSort) => ({
  type: TYPE.CHANGE_PUBLISHERS_TAGS_SORT,
  payload: sort,
});

const changePublishersTagsPage = (page: number) => ({
  type: TYPE.CHANGE_PUBLISHERS_TAGS_PAGE,
  payload: page,
});

const editPublishersTags = (publisher: PublishersSettingsModule.PublisherForm, id: number, onSuccess: () => void) => ({
  type: TYPE.EDIT_PUBLISHERS_TAGS,
  payload: { publisher, id },
  meta: { onSuccess },
});

const editPublishersTagsSuccess = () => ({
  type: TYPE.EDIT_PUBLISHERS_TAGS_SUCCESS,
});

const editPublishersTagsError = (error: Error) => ({
  type: TYPE.EDIT_PUBLISHERS_TAGS_ERROR,
  payload: error,
});

const scheduleTagsImport = (
  payload: PublishersAffiliateTagsModule.ScheduleTagsImportPayload & { publisher_name: string }
) => ({
  type: TYPE.SCHEDULE_TAGS_IMPORT,
  payload,
});

const scheduleTagsImportSuccess = (data: PublishersAffiliateTagsModule.TagsImportStatusPayload) => ({
  type: TYPE.SCHEDULE_TAGS_IMPORT_SUCCESS,
  payload: data,
});

const scheduleTagsImportError = (error: Error) => ({
  type: TYPE.SCHEDULE_TAGS_IMPORT_ERROR,
  payload: error,
});

const getTagsImportStatus = (publisherName: string, slug: string) => ({
  type: TYPE.GET_TAGS_IMPORT_STATUS,
  payload: { publisherName, slug },
});

const getTagsImportStatusSuccess = (data: PublishersAffiliateTagsModule.TagsImportStatusPayload) => ({
  type: TYPE.GET_TAGS_IMPORT_STATUS_SUCCESS,
  payload: data,
});

const getTagsImportStatusError = (error: Error) => ({
  type: TYPE.GET_TAGS_IMPORT_STATUS_ERROR,
  payload: error,
});

const resetTagsImportStatus = () => ({
  type: TYPE.RESET_TAGS_IMPORT_STATUS,
});

export const publishersTagsActions = {
  getPublishersTags,
  getPublishersTagsSuccess,
  getPublishersTagsError,
  changePublishersTagsSort,
  changePublishersTagsPage,
  editPublishersTags,
  editPublishersTagsSuccess,
  editPublishersTagsError,
  scheduleTagsImport,
  scheduleTagsImportSuccess,
  scheduleTagsImportError,
  getTagsImportStatus,
  getTagsImportStatusSuccess,
  getTagsImportStatusError,
  resetTagsImportStatus,
};
