import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Styles } from 'common/types';
import { StatusDropdown } from 'common/ui/status-dropdown';
import { selectGlobalFilters } from 'common/store/selectors';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { Box, IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ParentBrandPopover } from '../parent-brand-popover';

const getStyles = (): Styles => ({
  teamInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  info: {
    marginLeft: 1,
  },
  email: {
    color: '#214254',
    fontSize: '12px',
  },
  icon: {
    marginLeft: 1,
    color: '#739AAC',
    cursor: 'pointer',
  },
  activeStatus: {
    borderRadius: '12px',
    width: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    padding: '5px 10px',
    backgroundColor: '#62D294',
    color: '#ffffff',
  },
});

interface Props {
  item: PublishersSettingsModule.UserTeamData;
  clientType: string;
}

export function TeamTableRow({ item, clientType }: Props): ReactElement {
  const dispatch = useDispatch();
  const styles = getStyles();
  const globalFilters = useSelector(selectGlobalFilters);

  const handleCancelInvite = (email: string) => {
    dispatch(
      publishersSettingsActions.cancelTeamUser(email, () => {
        if (clientType === 'current') {
          dispatch(publishersSettingsActions.getTeam(globalFilters?.publisher_id));
        } else {
          dispatch(publishersSettingsActions.getTeam());
        }
      })
    );
  };

  const handleResendInvite = (email: string) => {
    dispatch(
      publishersSettingsActions.resendTeamUser(email, () => {
        if (clientType === 'current') {
          dispatch(publishersSettingsActions.getTeam(globalFilters?.publisher_id));
        } else {
          dispatch(publishersSettingsActions.getTeam());
        }
      })
    );
  };

  return (
    <TableRow>
      <TableCell>
        <Box sx={styles.teamInfo}>
          <Box sx={styles.inviteAvatar}>
            <PermIdentityIcon color="disabled" />
          </Box>
          <Box sx={styles.info}>
            <Typography>{item?.name ?? '-'}</Typography>
            <Typography sx={styles.email}>{item?.email ?? '-'}</Typography>
          </Box>
        </Box>
      </TableCell>
      {/* <TableCell>{item?.roles?.map(role => role.role_name)?.join(', ')}</TableCell> */}
      <TableCell>
        <ParentBrandPopover clientType={clientType} user={item} />
      </TableCell>
      <TableCell>
        {item?.clients?.filter(cl => cl.status === 'pending')?.length === 0 ? (
          <StatusDropdown value="accepted" showExpand={false} />
        ) : (
          <>
            <Tooltip title="Cancel Invitation" placement="top">
              <IconButton onClick={() => handleCancelInvite(item?.email)} size="small">
                <CloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Resend Invitation" placement="top">
              <IconButton onClick={() => handleResendInvite(item?.email)} size="small">
                <RefreshIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </TableCell>
    </TableRow>
  );
}
