import { State } from 'common/store/reducers';

export const selectCurrentTagsPage = (state: State) => state.attribution.amazon.page;

export const selectAmazonAttrData = (state: State) => state.attribution.amazon.data;

export const selectAmazonAttrAmount = (state: State) => state.attribution.amazon.amount;

export const selectAmazonAttrLoading = (state: State) => state.attribution.amazon.loading;

export const selectBrandsAttrLimitPageAmount = (state: State) => ({
  limit: state.attribution.amazonBrands.limit,
  page: state.attribution.amazonBrands.page,
  amount: state.attribution.amazonBrands.amount,
});

export const selectBrandsAttrData = (state: State) => state.attribution.amazonBrands.data;

export const selectBrandsAttrLoading = (state: State) => state.attribution.amazonBrands.loading;

export const selectAttributionFilters = (state: State) => state.attribution.filters;
