import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';
import ReportIcon from '@mui/icons-material/Report';
import { Styles } from 'common/types';

const styles: Styles = {
  icon: { cursor: 'pointer' },
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'red',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}));

export function OOSIcon(): ReactElement {
  return (
    <LightTooltip title="Contains out of stock ASINs">
      <ReportIcon sx={styles.icon} fontSize="small" color="error" />
    </LightTooltip>
  );
}
