import { ApiService } from 'common/services';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { parseFilters } from 'common/utils/parse-filters';

class StatisticsApiService extends ApiService {
  getAllStatistics = (filters: IStatisticsQueryString) => {
    return this.get('/api/statistics', { params: parseFilters(filters) });
  };

  getCorrelationStatistics = (filters: IStatisticsQueryString) => {
    return this.get('/api/statistics/correlation', { params: parseFilters(filters) });
  };

  getDevicesStatistics = (filters: IStatisticsQueryString) => {
    return this.get('/api/statistics/devices', { params: parseFilters(filters) });
  };

  getTopAsins = (filters: IStatisticsQueryString) => {
    return this.get('/api/statistics/top-performers', {
      params: { ...parseFilters(filters), performer_type: 'asin' },
    });
  };

  getTopCategories = (filters: IStatisticsQueryString) => {
    return this.get('/api/statistics/top-performers', {
      params: { ...parseFilters(filters), performer_type: 'category' },
    });
  };
}

export const statisticsApi = new StatisticsApiService(process.env.REACT_APP_API_URL);
