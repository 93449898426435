import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { Direction } from 'common/ui/base-filters/types';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { recommendedAsinsActions } from 'modules/affiliate-gainz/store/recommended-asins/actions';
import { selectRecommendedAsins, selectRecommendedAsinsFilters } from 'modules/affiliate-gainz/store/selectors';
import { MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsinsRow } from './asins-row';
import { ColumnsPopover } from './columns-popover/columns-popover';

const styles: Styles = {
  table: { marginTop: 2.5 },
};

export enum TableColumns {
  Product = 'product',
  Brand = 'brand',
  // Preview = 'preview',
  'Product Price' = 'product_price',
  'Opportunity Earn' = 'opportunity_earn',
  URLs = 'urls',
  'Suggested Articles' = 'suggested_articles',
  Status = 'status',
}

const columnsData: AffiliateGainzModule.RecommendedAsinsModule.Column[] = [
  { title: 'Product', value: TableColumns.Product },
  { title: 'Brand', value: TableColumns.Brand },
  // { title: 'Preview', value: TableColumns.Preview },
  { title: 'Product Price', value: TableColumns['Product Price'] },
  { title: 'Opportunity Earn', value: TableColumns['Opportunity Earn'] },
  { title: 'URLs', value: TableColumns.URLs },
  { title: 'Suggested Articles', value: TableColumns['Suggested Articles'] },
  { title: 'Status', value: TableColumns.Status },
];

export function AsinsTable(): ReactElement {
  const [sortField, setSortField] = useState<TableColumns>(TableColumns.Product);
  const [sortDirection, setSortDirection] = useState<Direction>('desc');
  const [selected, setSelected] = useState<AffiliateGainzModule.RecommendedAsinsModule.TableRow | null>(null);
  const [colsButtonAnchor, setColsButtonAnchor] = useState<HTMLElement | null>(null);
  const [selectedCol, setSelectedCol] = useState<AffiliateGainzModule.RecommendedAsinsModule.SelectedColumns>({
    product: true,
    brand: true,
    preview: false,
    product_price: true,
    opportunity_earn: true,
    urls: true,
    suggested_articles: true,
    status: true,
  });

  const dispatch = useDispatch();

  const filters = useSelector(selectRecommendedAsinsFilters);
  const globalFilters = useSelector(selectGlobalFilters);
  const asins = useSelector(selectRecommendedAsins);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(recommendedAsinsActions.setLimit(parseInt(event.target.value, 10)));
    dispatch(recommendedAsinsActions.setOffset(0));
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(recommendedAsinsActions.setOffset(newPage));
  };

  const getDirection = (direction: 'asc' | 'desc') => {
    switch (direction) {
      case 'asc':
        return 'desc';
      case 'desc':
        return 'asc';
      default:
        return 'desc';
    }
  };

  const handleSortChange = (field: TableColumns) => {
    setSortField(field);
    setSortDirection(getDirection(sortDirection));
    switch (field) {
      case TableColumns.Product:
        dispatch(recommendedAsinsActions.setPriceSort(null));
        dispatch(recommendedAsinsActions.setProductSort(sortDirection));
        break;
      case TableColumns['Product Price']:
        dispatch(recommendedAsinsActions.setProductSort(null));
        dispatch(recommendedAsinsActions.setPriceSort(sortDirection));
        break;

      default:
        break;
    }
  };

  const handleColsPopoverOpen = (e: MouseEvent<HTMLElement>) => {
    setColsButtonAnchor(e.currentTarget);
  };

  const handleColsPopoverClose = () => {
    setColsButtonAnchor(null);
  };

  useEffect(() => {
    if (selected)
      dispatch(
        recommendedAsinsActions.getPlacements({
          publisher_id: globalFilters.publisher_id,
          asin: selected.asin,
          title: selected.extended_product.title,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <>
      <TableContainerWithLoading loading={asins.loading} sx={styles.table}>
        <Table>
          <TableHead>
            <TableRow>
              {selectedCol.product && (
                <TableCell>
                  <TableSortLabel
                    onClick={() => handleSortChange(TableColumns.Product)}
                    active={sortField === TableColumns.Product}
                    direction={filters.product_sort || 'asc'}
                  >
                    Product
                  </TableSortLabel>
                </TableCell>
              )}
              {selectedCol.brand && <TableCell>Brand</TableCell>}
              {/* {selectedCol.preview && <TableCell>Preview</TableCell>} */}
              {selectedCol.product_price && (
                <TableCell>
                  <TableSortLabel
                    onClick={() => handleSortChange(TableColumns['Product Price'])}
                    active={sortField === TableColumns['Product Price']}
                    direction={filters.price_sort || 'asc'}
                  >
                    Product Price
                  </TableSortLabel>
                </TableCell>
              )}
              {selectedCol.opportunity_earn && <TableCell>Opportunity Earn</TableCell>}
              {selectedCol.urls && <TableCell>URLs</TableCell>}
              {selectedCol.suggested_articles && <TableCell>Suggested Articles</TableCell>}
              {selectedCol.status && <TableCell>Status</TableCell>}
              <TableCell>
                <Tooltip title="Add columns" placement="top">
                  <IconButton size="small" onClick={handleColsPopoverOpen}>
                    <FormatListBulletedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {asins?.data?.recommended_asins?.map(row => (
              <AsinsRow
                row={row}
                key={row.asin}
                selected={selected}
                selectedCol={selectedCol}
                setSelected={setSelected}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 20, 50]}
        count={asins.data ? asins.data.total : 0}
        rowsPerPage={filters.limit}
        page={filters.offset}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ColumnsPopover
        anchorEl={colsButtonAnchor}
        columnsData={columnsData}
        onClose={handleColsPopoverClose}
        selected={selectedCol}
        setSelectedCol={setSelectedCol}
      />
    </>
  );
}
