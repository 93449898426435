import { Action } from 'common/interfaces/root';
import { recommendedAsinsActionTypes as TYPES } from '../actions';

const initialState: AffiliateGainzModule.RecommendedAsinsModule.AsinsState = {
  loading: true,
  data: null,
  error: null,
};

export default (
  state: AffiliateGainzModule.RecommendedAsinsModule.AsinsState = initialState,
  { type, payload }: Action
): AffiliateGainzModule.RecommendedAsinsModule.AsinsState => {
  switch (type) {
    case TYPES.ASINS.GET_FEATURED_ASINS:
      return { ...state, loading: true };
    case TYPES.ASINS.GET_FEATURED_ASINS_SUCCESS:
      return { ...state, loading: false, data: payload };
    case TYPES.ASINS.GET_FEATURED_ASINS_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
