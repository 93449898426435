import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import { SxProps } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { IProduct } from '../../../../../../common/ui/product/types';
import { Product } from '../../../../../../common/ui/product';
import { OOSReplacementsModalHeader } from './oos-replacements-modal-header';
import './oos-replacement-modal.css';
import {
  selectPagesPerformance,
  selectPagesPerformanceLimitPageAmount,
  selectPerformancePageArticle,
  selectPerformancePageArticleModalVisible,
} from '../../../../store/selectors';
import { contentOverviewActions } from '../../../../store/actions';
import { OosReplacementRow } from './oos-replacement-row';
import { selectGlobalFilters } from '../../../../../../common/store/selectors';

const sx: Styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  titleContainer: {
    display: 'flex',
    marginBottom: '14px',
  },
  title: {
    fontWeight: '700',
    lineHeight: '150%',
    color: '#060F14',
  },
  oosHeaderOOSTitle: {
    margin: '0 0 0 25px',
  },
  oosHeaderOOSTitleRecommended: {
    margin: '0 0 0 257px',
  },
  oosRowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    flexBasis: 0,
    marginLeft: '20px',
    maxHeight: '65px',
  },
  selectedContainer: {
    minWidth: '369px',
    width: '45%',
  },
  selectedPlacementsContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '84%',
    width: '100%',
    border: '2px solid #4288F0',
    flexGrow: 1,
    flexBasis: 0,
  },
  replacementArrowRightIcon: {
    width: '40px',
    height: '40px',
    margin: 'auto 12px',
  },
  divider: {
    height: '374px',
    border: '1px solid rgba(66, 136, 240, 0.3)',
    margin: '0 36px',
  },
  productContainer: {
    marginTop: 0,
    minWidth: '210px',
    width: '240px',
    maxWidth: 300,
  },
  closeIcon: {
    margin: 'auto 20px auto 5px',
  },
  submitBtn: {
    margin: 'auto 30px 12px auto',
  },
};

interface Placement {
  oosProduct: IProduct;
  productToReplace: IProduct;
}

export function OOSReplacementsModal(): ReactElement {
  const dispatch = useDispatch();
  const pagesPerformance = useSelector(selectPagesPerformance);
  const pagesPerformanceAmount = useSelector(selectPagesPerformanceLimitPageAmount);

  const globalFilters = useSelector(selectGlobalFilters);
  const isOpen = useSelector(selectPerformancePageArticleModalVisible);
  const article = useSelector(selectPerformancePageArticle);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedAsins, setSelectedAsins] = useState<Placement[]>(
    article && article.products.entries.length
      ? article.products.entries
          .filter(p => !p.in_stock)
          .map(el => ({
            oosProduct: el,
            productToReplace: null,
          }))
      : []
  );

  useEffect(() => {
    if (article) {
      setSelectedAsins(
        article.products.entries
          .filter(p => !p.in_stock)
          .map(el => ({
            oosProduct: el,
            productToReplace: null,
          }))
      );
    }
  }, [article]);
  const onSelectAsinReplacement = (oosProduct: IProduct, productToReplace: IProduct) => {
    setSelectedAsins(prev =>
      prev.map(el => {
        if (el.oosProduct.asin === oosProduct.asin) {
          el.productToReplace = productToReplace;
        }
        return el;
      })
    );
  };

  const closeModal = () => {
    dispatch(contentOverviewActions.setPagePerformanceActiveArticleModalVisible(false));
  };
  const removeReplacement = (oosAsin: string) => {
    setSelectedAsins(prev =>
      prev.map(el => {
        if (el.oosProduct.asin === oosAsin) {
          el.productToReplace = null;
        }
        return el;
      })
    );
  };

  const getSelectedAsinContainerStyles = (idx: number, arrLen: number): SxProps => {
    if (!idx) {
      return { ...sx.oosRowContainer, marginTop: '18px' };
    }
    if (idx !== arrLen - 1) {
      return { ...sx.oosRowContainer, marginTop: '65px' };
    }
    return { ...sx.oosRowContainer, marginBottom: '20px' };
  };

  const submit = () => {
    setIsSubmitted(true);
    dispatch(
      contentOverviewActions.requestToSwapOOS(
        {
          publisherId: globalFilters.publisher_id as number,
          url: article?.url,
          asins: selectedAsins
            .filter(el => !!el.productToReplace)
            .map(el => ({
              oos: el.oosProduct.asin,
              replace: el.productToReplace.asin,
            })),
        },
        (data: { requested: boolean }) => {
          setIsSubmitted(false);
          toast.success('Products have been submitted to Editors for Swap');
          if (data.requested) {
            dispatch(contentOverviewActions.setPagePerformanceActiveArticleModalVisible(false));

            const updatedPagesPerformance = pagesPerformance.map(el => {
              if (el?.url === article?.url) {
                el.products.entries = el.products.entries.map(p => {
                  const asinToReplace = selectedAsins.filter(
                    pair => pair.oosProduct.asin === p.asin && !!pair.productToReplace
                  );
                  if (asinToReplace.length) {
                    p.requested_asin_to_replace = asinToReplace[0].productToReplace.asin;
                    p.swapCompletionStatus = 'in-progress';
                  }

                  return p;
                });
              }
              return el;
            });
            dispatch(
              contentOverviewActions.updatePerformancePages({
                articles: updatedPagesPerformance,
                articles_count: pagesPerformanceAmount.amount,
              })
            );
          }
        },
        () => {
          setIsSubmitted(false);
        }
      )
    );
  };
  const isLoading = useMemo(
    () => !!(isSubmitted && selectedAsins?.filter(el => !!el.productToReplace).length),
    [isSubmitted, selectedAsins]
  );
  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      title={<OOSReplacementsModalHeader isLoading={isLoading} article={article} />}
      maxWidth="xl"
      showCloseIcon={false}
    >
      <Box sx={sx.container}>
        {/* OOS Recs list */}
        <Box sx={{ width: '45%' }}>
          <Box sx={sx.titleContainer}>
            <Typography sx={{ ...sx.oosHeaderOOSTitle, ...sx.title }}>Out of stock</Typography>
            <Typography sx={{ ...sx.title, ...sx.oosHeaderOOSTitleRecommended }}>Recommended</Typography>
          </Box>
          {selectedAsins?.map((el, idx) => (
            <Box sx={!idx ? sx.oosRowContainer : { ...sx.oosRowContainer, marginTop: '65px' }}>
              <OosReplacementRow
                oosProduct={el.oosProduct}
                suggestions={article.suggested_products}
                asinToReplace={el?.productToReplace?.asin ?? ''}
                onSelectReplacement={onSelectAsinReplacement}
              />
            </Box>
          ))}
        </Box>
        <Divider orientation="vertical" sx={sx.divider} />
        {/* Selected asins */}
        <Box sx={sx.selectedContainer}>
          <Typography sx={{ ...sx.title, marginBottom: '14px' }}>Selected ASINs</Typography>
          <Box sx={sx.selectedPlacementsContainer}>
            {selectedAsins
              ?.filter(el => !!el.productToReplace)
              .map((selectedAsin, idx) => (
                <Box sx={getSelectedAsinContainerStyles(idx, selectedAsins.length)}>
                  <Product
                    product={selectedAsin.oosProduct}
                    key={selectedAsin.oosProduct.amazon_link}
                    containerSx={sx.productContainer}
                    showOrderIcon={false}
                    showLastPullIcon={false}
                    requestToSwapMode
                  />
                  {/* arrow right */}
                  <svg
                    style={{
                      margin: 'auto 15px',
                    }}
                    width="20"
                    height="20"
                    viewBox="0 0 15 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.153981 5.24467L11.3795 4.85267L7.76481 1.48189L8.78781 0.384862L14.273 5.49985L9.15798 10.985L8.06095 9.962L11.4317 6.34729L0.206174 6.73929L0.153981 5.24467Z"
                      fill="black"
                    />
                  </svg>
                  <Product
                    product={selectedAsin.productToReplace}
                    key={selectedAsin.productToReplace.amazon_link}
                    containerSx={sx.productContainer}
                    showOrderIcon={false}
                    showLastPullIcon={false}
                  />
                  <CloseIcon sx={sx.closeIcon} onClick={() => removeReplacement(selectedAsin.oosProduct.asin)} />
                </Box>
              ))}
            {isLoading && <CircularProgress sx={sx.submitBtn} />}
            {selectedAsins?.filter(el => !!el.productToReplace).length && !isLoading && (
              <Button onClick={submit} variant="contained" sx={sx.submitBtn}>
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
