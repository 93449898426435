import { ReactElement, useMemo } from 'react';
import { Box } from '@mui/material';
import { getAdminNavConfig, getDemoAppAdminNavConfig } from 'common/constants/nav';
import { Styles } from 'common/types';
import { useSelector } from 'react-redux';
import { NavItems } from '../nav-items';
import { selectRootDemo } from '../../../../common/store/selectors';

const getStyles = (isDemo: boolean): Styles => ({
  border: { mt: 5, mr: 3, ml: 3, borderTop: '1px solid lightgray' },
  container: { pl: 3, opacity: isDemo ? 0 : 100 },
});

export function AdminPanel(): ReactElement {
  const isDemoApp = useSelector(selectRootDemo);
  const styles = getStyles(isDemoApp);
  const navItems = useMemo(() => (isDemoApp ? getDemoAppAdminNavConfig() : getAdminNavConfig()), [isDemoApp]);

  return (
    <>
      <Box sx={styles.border} />
      <Box sx={styles.container}>
        <NavItems items={navItems} />
      </Box>
    </>
  );
}
