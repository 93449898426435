import { ApiService } from 'common/services';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { parseFilters } from 'common/utils/parse-filters';

class UrlsApiService extends ApiService {
  getUrls = (filters: IStatisticsQueryString) => {
    return this.get('/api/urls', { params: parseFilters(filters) });
  };
}

export const urlsApi = new UrlsApiService(process.env.REACT_APP_API_URL);
