import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import { ChangeEvent, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPublisherGroups } from 'modules/settings/store/selectors';
import { selectPublishersSettingsTableFilters } from 'modules/publishers-settings/store/selectors';
import { publishersSettingsActions } from 'modules/publishers-settings/store/actions';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 1.5 },
  fieldContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  field: { width: '70%' },
  btnContainer: { display: 'flex', justifyContent: 'flex-end', gap: 1.5 },
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleApply: () => void;
  handleReset: () => void;
}

export function FiltersModal({ isOpen, onClose, handleApply, handleReset }: Props): ReactElement {
  const publisherGroups = useSelector(selectPublisherGroups);
  const filters = useSelector(selectPublishersSettingsTableFilters);

  const dispatch = useDispatch();

  const handleGroupChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(publishersSettingsActions.setGroup(e.target.value));
  };

  const handleGainzChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(publishersSettingsActions.setGainz(e.target.value === 'true'));
  };

  const handleDisplayCommissionChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    if (value === 'All') {
      dispatch(publishersSettingsActions.setDisplayCommission(undefined));
    } else {
      dispatch(publishersSettingsActions.setDisplayCommission(value === 'true'));
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} title="Filters" maxWidth="sm">
      <Box sx={styles.container}>
        <Box sx={styles.fieldContainer}>
          <Typography>Groups</Typography>
          <TextField
            sx={styles.field}
            select
            label="Groups"
            size="small"
            value={filters.group}
            onChange={handleGroupChange}
            InputLabelProps={{ shrink: true }}
          >
            {publisherGroups?.publisher_groups.map(group => (
              <MenuItem key={group.id} value={group.group_name}>
                {group.group_name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Gainz</Typography>
          <TextField
            sx={styles.field}
            select
            label="Gainz"
            size="small"
            value={filters.gainz.toString()}
            onChange={handleGainzChange}
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value="true">Active</MenuItem>
            <MenuItem value="false">Inactive</MenuItem>
          </TextField>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Can display commission</Typography>
          <TextField
            sx={styles.field}
            select
            label="Display commission"
            size="small"
            value={filters.display_commission === undefined ? 'All' : filters.display_commission.toString()}
            onChange={handleDisplayCommissionChange}
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </TextField>
        </Box>
        <Box sx={styles.btnContainer}>
          <Button variant="outlined" onClick={handleReset}>
            Reset
          </Button>
          <Button variant="contained" onClick={handleApply}>
            Apply
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
