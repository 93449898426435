import { Box, Popover, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement } from 'react';

const styles: Styles = {
  customer: {
    color: 'rgb(46, 95, 168)',
    cursor: 'pointer',
    marginBottom: 1,
  },
  container: {
    width: 250,
    display: 'flex',
    justifyContent: 'center',
    p: 3,
    boxSizing: 'border-box',
    flexDirection: 'column',
    gap: 1,
  },
  rowContainer: { display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%' },
  title: { width: '70%', color: '#6F8490', fontSize: 12 },
  value: { fontSize: 18 },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #798e9a3e',
    padding: '10px 0',
    boxSizing: 'border-box',
  },
  ending: { fontSize: 16, color: '#060F14' },
  description: { fontSize: 14, color: '#214254' },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

interface Props {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  tags: Array<string>;
}

export function TagsPopover({ anchorEl, onClose, tags }: Props): ReactElement {
  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <Box sx={styles.container}>
        <Box sx={styles.rowContainer}>
          <Typography sx={styles.customer}>Tags</Typography>
          {tags?.length ? (
            tags?.map(tag => (
              <Box sx={styles.infoContainer}>
                <Typography sx={styles.ending}>{tag}</Typography>
              </Box>
            ))
          ) : (
            <Box sx={styles.loadingContainer}>
              <Typography sx={styles.ending}>No tags</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Popover>
  );
}
