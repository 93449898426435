import { ApiService } from 'common/services';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { parseFilters } from 'common/utils/parse-filters';

class TagsApiService extends ApiService {
  getTags = (filters: IStatisticsQueryString) => {
    return this.get('/api/tags', { params: parseFilters(filters) });
  };

  getPublishersTags = (filters: IStatisticsQueryString) => {
    return this.get('/api/tags/publishers', { params: parseFilters(filters) });
  };

  scheduleTagsImport = (paylaod: PublishersAffiliateTagsModule.ScheduleTagsImportPayload) => {
    return this.post('/api/tags/import/schedule', paylaod);
  };

  getTagsImportStatus = (slug: string) => {
    return this.get('/api/tags/import/status', { params: { slug } });
  };
}

export const tagsApi = new TagsApiService(process.env.REACT_APP_API_URL);
