import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import { theme } from 'common/constants/theme';
import moment from 'moment';
import { selectGlobalFilters, selectRootDemo } from 'common/store/selectors';
import { Styles } from 'common/types';
import { Status } from 'common/ui/status';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { contentOverviewActions, crawlingActions } from 'modules/content-overview/store/actions';
import {
  selectCrawling,
  selectCrawlingCanLoadMore,
  selectCrawlingLimitPageAmount,
  selectCrawlingLoading,
  selectCrawlingStatus,
  selectManagementFilters,
} from 'modules/content-overview/store/selectors';
import { selectPublisherInfo } from 'modules/settings/store/selectors';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { CrawlingRow } from '../crawling-row';
import { PublisherDomainUrls } from './publisher-domain-urls/publisher-domain-urls';

const styles: Styles = {
  container: {
    marginTop: 2,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  publisherDomainContainer: {
    display: 'flex',
  },
  publisherUrlsInputContainer: {},
  statusContainer: { margin: 2 },
  statusHeaders: { fontSize: 12, lineHeight: '16px', display: 'flex', alignItems: 'center' },
  statusBtn: { marginTop: 1 },
  loading: { marginLeft: 0.5 },
};

interface Props {
  withAsins: number;
  addedFounded: number;
}

export function CrawlingTable({ withAsins, addedFounded }: Props): ReactElement {
  const dispatch = useDispatch();

  const loading = useSelector(selectCrawlingLoading);
  const canLoadMore = useSelector(selectCrawlingCanLoadMore);
  const globalFilters = useSelector(selectGlobalFilters);
  const publisherInfo = useSelector(selectPublisherInfo);
  const rows = useSelector(selectCrawling);
  const crawlingStatus = useSelector(selectCrawlingStatus);
  const { limit: crawlingLimit, page: crawlingPage } = useSelector(selectCrawlingLimitPageAmount);
  const managementFilters = useSelector(selectManagementFilters);

  const statusInterval = useRef<number>();
  const crawledURLsInterval = useRef<number>();
  const [editDomainRelativePathsMode, setEditDomainRelativePathsMode] = useState(false);
  const [domainUrls, setDomainUrls] = useState([]);
  const [isBaseDomainSetuped, setIsBaseDomainSetuped] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0,
  });
  const isDemoMode = useSelector(selectRootDemo);

  const handlePageChange = (page: number) => {
    dispatch(crawlingActions.changePage(page + 1));
    dispatch(
      crawlingActions.get({
        limit: crawlingLimit,
        offset: crawlingLimit * page,
        publisherId: globalFilters.publisher_id,
        search: managementFilters?.search,
        with_asins: withAsins,
        added_founded: addedFounded,
      })
    );
  };

  const handleStartCrawling = () => {
    if (!publisherInfo.domain) return;

    const withProtocol = publisherInfo.domain.includes('https://') || publisherInfo.domain.includes('http://');
    const domain = withProtocol ? publisherInfo.domain : `https://${publisherInfo.domain}`;

    dispatch(contentOverviewActions.postCrawling(globalFilters.publisher_id, domain));
  };

  const getLastCrawledDate = () => {
    if (!crawlingStatus.data?.last_crawled) return '';
    return moment(crawlingStatus.data?.last_crawled).format('ddd MMM D, YYYY @h:mma');
  };

  const toStartChange = () => {
    dispatch(crawlingActions.getReset());
    dispatch(crawlingActions.changePage(0));
  };

  const updateActiveStatus = () => {
    toStartChange();
    const relativePaths = domainUrls.map(url => {
      url = url.replace(/(https|http):\/\//g, '');
      const urlInstance = new URL(`http://${url.replace(/\/$/g, '')}`);
      return urlInstance.pathname;
    });
    dispatch(
      crawlingActions.get({
        limit: crawlingLimit,
        offset: 0,
        publisherId: globalFilters.publisher_id,
        search: managementFilters?.search,
        with_asins: withAsins,
        added_founded: addedFounded,
        relative_paths: relativePaths.join(','),
      })
    );
  };

  const setDefaultDomainUrls = useCallback(() => {
    if (publisherInfo.domain) {
      setDomainUrls([`${publisherInfo?.domain}`]);
    }
  }, [publisherInfo?.domain]);

  const updateDomainUrls = (updatedDomainUrls: string[]) => {
    if (!updatedDomainUrls.length) {
      setDefaultDomainUrls();
    } else {
      updatedDomainUrls = updatedDomainUrls.filter((value, index, array) => array.indexOf(value) === index);
      setDomainUrls(updatedDomainUrls);
    }
  };

  useEffect(() => {
    const isDomainUrlJustSetuped = !domainUrls.length && publisherInfo?.domain;
    if (isDomainUrlJustSetuped) {
      setDefaultDomainUrls();
      setIsBaseDomainSetuped(true);
    }
  }, [domainUrls, publisherInfo?.domain, isBaseDomainSetuped, setDefaultDomainUrls]);

  useEffect(() => {
    window.clearInterval(crawledURLsInterval.current);

    if (['error', 'finished'].includes(crawlingStatus.data?.status) || !crawlingStatus.data?.status) return;

    updateActiveStatus();

    crawledURLsInterval.current = window.setInterval(() => {
      updateActiveStatus();
    }, 5000);

    // eslint-disable-next-line consistent-return
    return () => {
      window.clearInterval(crawledURLsInterval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    crawlingLimit,
    crawlingPage,
    dispatch,
    globalFilters.publisher_id,
    crawlingStatus,
    withAsins,
    addedFounded,
    domainUrls,
  ]);

  useEffect(() => {
    window.clearInterval(statusInterval.current);

    statusInterval.current = window.setInterval(() => {
      dispatch(contentOverviewActions.getCrawlingStatus({ publisherId: globalFilters.publisher_id }));
    }, 60000);

    return () => {
      window.clearInterval(statusInterval.current);
    };
  }, [dispatch, globalFilters.publisher_id]);

  useEffect(() => {
    if (
      inView &&
      !loading &&
      !managementFilters?.search &&
      canLoadMore &&
      crawlingStatus.data?.status !== 'active' &&
      crawlingStatus.data?.status !== 'pending'
    ) {
      if (rows.length && !crawlingPage) toStartChange();
      handlePageChange(crawlingPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, loading, canLoadMore]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.statusContainer}>
        <PublisherDomainUrls
          editMode={editDomainRelativePathsMode}
          initedDomainUrls={domainUrls}
          onUpdate={updateDomainUrls}
          onUpdateEditMode={mode => setEditDomainRelativePathsMode(mode)}
        />
        <Box sx={styles.statusHeaders}>
          Last crawled at:{' '}
          {crawlingStatus.loading ? <CircularProgress size={12} sx={styles.loading} /> : getLastCrawledDate() || '-'}
        </Box>
        <Box sx={styles.statusHeaders}>
          Status:{' '}
          {crawlingStatus.loading ? (
            <CircularProgress size={12} sx={styles.loading} />
          ) : (
            <Status status={crawlingStatus.data?.status} /> ?? '-'
          )}
        </Box>
        <Button
          size="small"
          variant="contained"
          disabled={
            crawlingStatus.loading ||
            (crawlingStatus.data?.status && !['finished', 'error'].includes(crawlingStatus.data?.status))
          }
          sx={styles.statusBtn}
          onClick={handleStartCrawling}
        >
          Start crawling
        </Button>
      </Box>
      <Divider />
      <TableContainerWithLoading sx={styles.table} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Source</TableCell>
              <TableCell align="center">Asins</TableCell>
              <TableCell align="center">Found date</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map(row => (
              <CrawlingRow key={row.id} url={row} withAsins={withAsins} isDemoMode={isDemoMode} />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <div ref={ref} />
    </Box>
  );
}
