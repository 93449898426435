import { ReactElement } from 'react';
import { Box } from '@mui/material';
import { Styles } from 'common/types';
import { useSelector } from 'react-redux';
import { selectPubMaverickTable, selectPubMaverickTableLoading } from 'modules/mav-plus/store/selectors';
import { MavDailyChart } from '../mav-daily-chart';

const getStyles = (): Styles => ({
  chartArea: {
    width: '100%',
    borderRadius: '20px',
    boxSizing: 'border-box',
    margin: '10px 0',
  },
});

export function MavChartArea(): ReactElement {
  const styles = getStyles();

  const loading = useSelector(selectPubMaverickTableLoading);
  const pubMaverickPerformance = useSelector(selectPubMaverickTable);

  const sortedChartData = pubMaverickPerformance?.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const chartData = sortedChartData?.map(item => ({
    label: item.date,
    revenue: item.amz_product_sales,
    asin: item.asin,
  }));

  const uniqueLabels = [...new Set(chartData.map(item => item.label))];

  const uniqueAsins = [...new Set(chartData.map(item => item.asin))];

  const sumChartData = {
    labels: uniqueLabels,
    dataset: uniqueAsins.map(asin => {
      const backgroundColor = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)}, 0.3)`;
      const chart = uniqueLabels.map(label => {
        const item = chartData.find(dataItem => dataItem.asin === asin && dataItem.label === label);
        return item ? item.revenue : 0;
      });
      return { label: asin, backgroundColor, data: chart };
    }),
  };

  return (
    <Box sx={styles.chartArea}>
      <MavDailyChart datasets={sumChartData?.dataset} labels={sumChartData?.labels} loading={loading} />
    </Box>
  );
}
