import { Grid } from '@mui/material';
import { carrickTips } from 'common/constants/tips';
import { Styles } from 'common/types';
import { TitleTooltip } from 'common/ui/title-tooltip';
import { selectAllStatistics, selectTopPerformers } from 'modules/overview-v2/store/selectors';
import { AsinTable, CategoryTable } from 'modules/overview/components/performance-container/tables';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Top10Chart } from '../top10-chart';

const styles: Styles = {
  title: { fontWeight: 600 },
  titleContainer: { marginBottom: 1 },
};

export function ArticlesContainer(): ReactElement {
  const performance: Overview.Performance.PerformanceStore = useSelector(selectTopPerformers);
  const allStats = useSelector(selectAllStatistics);

  return (
    <Grid container spacing={3} mt={1}>
      <Grid xs={12} item>
        <Top10Chart />
      </Grid>
      {allStats?.is_any_statistics_exist && (
        <>
          <Grid item xs={6}>
            <TitleTooltip
              title="Top Performing ASINs"
              tooltip={carrickTips.overview.topPerformers.asins}
              typographyProps={{ sx: styles.title }}
              sx={styles.titleContainer}
            />
            <AsinTable asins={performance?.topAsins} />
          </Grid>
          <Grid item xs={6}>
            <TitleTooltip
              title="Top Performing Categories"
              tooltip={carrickTips.overview.topPerformers.categories}
              typographyProps={{ sx: styles.title }}
              sx={styles.titleContainer}
            />
            <CategoryTable categories={performance?.topCategories} />
          </Grid>
        </>
      )}
    </Grid>
  );
}
