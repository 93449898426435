import { Action } from 'common/interfaces/root';
import { contentOverviewActionTypes as TYPES } from '../actions';

const initialState: ContentOverviewModule.PerformanceComparisonsStore = {
  datesToCompare: '',
};

export default (
  state: ContentOverviewModule.PerformanceComparisonsStore = initialState,
  { type, payload }: Action
): ContentOverviewModule.PerformanceComparisonsStore => {
  switch (type) {
    case TYPES.CHANGE_DATES_TO_COMPARE:
      return { ...state, datesToCompare: payload };

    default:
      return state;
  }
};
