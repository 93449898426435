import { ITableSort } from 'common/ui/base-filters/types';
import { ContentType } from 'modules/content-overview/components/pages/page-performance/content-type-filter/content-type-filter.types';
import { Moment } from 'moment';

export interface GetRequestParams {
  ids?: Array<number | string>;
  publisherId?: number;
  limit?: number;
  offset?: number;
  search?: string;
  inStock?: number;
  with_asins?: number;
  added_founded?: number;
  relative_paths?: string; // ex: domain.com/path1,domain.com/path2,..etc
  urls_filter?: number; // just for urls tab, see content-overview.service.types
  sort?: ITableSort;
  start_date?: Moment;
  end_date?: Moment;
  breakdown?: string;
  article_type?: ContentType;
  brand_name?: string;
  saturation_operator?: string;
  saturation_value?: number;
  dates_to_compare?: string;
}

export const getDataListActions = <D>(actionTypes: Record<string, string>) => ({
  get: (params: GetRequestParams) => ({
    type: actionTypes.GET,
    payload: params,
  }),

  getSuccess: (data: D, amount?: number) => ({
    type: actionTypes.GET_SUCCESS,
    payload: { data, amount },
  }),

  getLazySuccess: (data: D, amount?: number) => ({
    type: actionTypes.GET_LAZY_SUCCESS,
    payload: { data, amount },
  }),

  getReset: () => ({
    type: actionTypes.RESET,
  }),

  canLoadMore: (canLoadMore: boolean) => ({
    type: actionTypes.CAN_LOAD_MORE,
    payload: canLoadMore,
  }),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete: (id: any) => ({
    type: actionTypes.DELETE,
    payload: id,
  }),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteSuccess: (id: any, field: string) => ({
    type: actionTypes.DELETE_SUCCESS,
    payload: { id, field },
  }),

  deleteError: (error: Error) => ({
    type: actionTypes.DELETE_ERROR,
    payload: error,
  }),

  getError: (error: Error) => ({
    type: actionTypes.GET_ERROR,
    payload: JSON.stringify(error),
  }),

  changePage: (page: number) => ({
    type: actionTypes.CHANGE_PAGE,
    payload: page,
  }),

  changeLimit: (limit: number) => ({
    type: actionTypes.CHANGE_LIMIT,
    payload: limit,
  }),
});
