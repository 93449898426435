import { getFiltersActionTypes } from 'common/store/actions/filters';

const TAGS_TABLE_MODULE = '[tags:table]';

const GET_TAGS = `${TAGS_TABLE_MODULE} GET_TAGS`;
const GET_TAGS_SUCCESS = `${TAGS_TABLE_MODULE} GET_TAGS_SUCCESS`;
const GET_TAGS_ERROR = `${TAGS_TABLE_MODULE} GET_TAGS_ERROR`;

const CHANGE_TAGS_PAGE = `${TAGS_TABLE_MODULE} CHANGE_TAGS_PAGE`;

export const tagsActionTypes = {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_ERROR,
  CHANGE_TAGS_PAGE,
};

export const tagsFiltersActionTypes = getFiltersActionTypes(TAGS_TABLE_MODULE);
