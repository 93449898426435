import { ChangeEvent, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, MenuItem, TextField } from '@mui/material';
import { selectPagesPerformanceFilters } from '../../../../store/selectors';
import { pagesPerformanceFiltersActions } from '../../../../store/actions';

const styles = {
  container: {
    display: 'flex',
    gap: '10px',
    width: '70%',
    justifyContent: 'space-between',
  },
  selectContainer: {
    width: '100%',
  },
};

const conditionOptions = [
  { title: '=', value: 'EQUAL_TO' },
  { title: '!=', value: 'NOT_EQUAL_TO' },
  { title: '>', value: 'GREATER_THAN' },
  { title: '<', value: 'LESS_THAN' },
  { title: '>=', value: 'GREATER_THAN_OR_EQUAL_TO' },
  { title: '<=', value: 'LESS_THAN_OR_EQUAL_TO' },
];

const valueOptions = [{ title: '0%', value: 0 }];
for (let i = 10; i <= 100; i += 10)
  valueOptions.push({
    title: `${i}%`,
    value: i,
  });

export function SaturationPercentFilter(): ReactElement {
  const dispatch = useDispatch();

  const filters = useSelector(selectPagesPerformanceFilters);

  const handleConditionChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(pagesPerformanceFiltersActions.changeSaturationOperator(e.target.value));
  };
  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(pagesPerformanceFiltersActions.changeSaturationValue(Number(e.target.value)));
  };

  return (
    <Box sx={styles.container}>
      <TextField
        select
        color="primary"
        style={styles.selectContainer}
        onChange={handleConditionChange}
        value={filters.saturation_operator}
        size="small"
        label="Operators"
      >
        {conditionOptions.map(source => (
          <MenuItem key={source.value} value={source.value}>
            {source.title}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        color="primary"
        style={styles.selectContainer}
        onChange={handleValueChange}
        value={filters.saturation_value}
        size="small"
        label="Value (0-100)"
      >
        {valueOptions.map(source => (
          <MenuItem key={source.value} value={source.value}>
            {source.title}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}
