import { actionTypes } from './action-types';

const getExport = (params: Conversions.Conv.ConversionsParams) => ({
  type: actionTypes.export.GET_EXPORT,
  payload: params,
});

const getExportSuccess = (data: string | null) => ({
  type: actionTypes.export.GET_EXPORT_SUCCESS,
  payload: data,
});

const getExportError = (error: Error) => ({
  type: actionTypes.export.GET_EXPORT_ERROR,
  payload: error,
});

export const exportActions = {
  getExport,
  getExportSuccess,
  getExportError,
};
