import { ApiService } from 'common/services';

class PubxReportsService extends ApiService {
  getReport = (params: PubXReportsModule.ExportsParams) => {
    return this.get('/api/export/recommendations', { params });
  };

  getReportStatus = (params: PubXReportsModule.StatusParams) => {
    return this.get(`/api/export/${params.job_id}/status`, { params });
  };
}

export const pubxReportsService = new PubxReportsService(process.env.REACT_APP_API_URL);
