import { Action } from 'common/interfaces/root';
import { actionTypes } from '../actions/action-types';

const initialState: Conversions.Filters.FilterState = {
  status: 'ordered',
  networkType: '1',
  trafficType: 'google',
  report: '',
  search: '',
};

export default (
  state: Conversions.Filters.FilterState = initialState,
  { type, payload }: Action
): Conversions.Filters.FilterState => {
  switch (type) {
    case actionTypes.filters.GET_FILTERS:
      return { ...state };
    case actionTypes.filters.SET_STATUS:
      return { ...state, status: payload };
    case actionTypes.filters.SET_NETWORK_TYPE:
      return { ...state, networkType: payload };
    case actionTypes.filters.SET_TRAFFIC_TYPE:
      return { ...state, trafficType: payload };
    case actionTypes.filters.SET_REPORTS:
      return { ...state, report: payload };
    case actionTypes.filters.SET_SEARCH:
      return { ...state, search: payload };
    case actionTypes.filters.RESET_FILTERS:
      return initialState;

    default:
      return state;
  }
};
