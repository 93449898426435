import { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Box, TextField, Tooltip, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { asinsConversionsBoostSelectors } from 'modules/asins-conversions-boost/store/selectors';
import { theme } from 'common/constants/theme';
import { asinsConversionsBoostActions } from 'modules/asins-conversions-boost/store/actions';

const styles: Styles = {
  name: {
    cursor: 'pointer',
    fontSize: 16,
    mr: '10px',
    width: '90%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  select: {
    '.MuiOutlinedInput-root': {
      padding: '1px',
    },
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #e5e5e5',
    height: '48px',
    margin: '0px 16px',
  },
  icon: {
    color: theme.palette.primary.dark,
  },
};

interface Props {
  selectedBrand: number;
  setSelectedBrand: (value: number) => void;
  disabled?: boolean;
}

export function BrandSelect({ selectedBrand, setSelectedBrand, disabled = false }: Props): ReactElement {
  const dispatch = useDispatch();
  const { brands, loading } = useSelector(asinsConversionsBoostSelectors.selectBrands);

  const autocompleteOptions = useMemo(() => {
    if (brands) {
      return brands.map(brand => ({
        label: brand?.name,
        id: brand?.id,
      }));
    }

    return [];
  }, [brands]);

  const handleSelectedBrandChange = (_event: unknown, value: { id: number; label: string }) => {
    setSelectedBrand(value?.id);
  };

  useEffect(() => {
    const cachedBrands = localStorage.getItem('brands');
    if (cachedBrands) {
      dispatch(asinsConversionsBoostActions.brandsActions.setBrands(JSON.parse(cachedBrands)));
    } else {
      dispatch(asinsConversionsBoostActions.brandsActions.getBrands());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (brands) {
      localStorage.setItem('brands', JSON.stringify(brands));
    }
  }, [brands]);

  return (
    <Autocomplete
      options={autocompleteOptions}
      disabled={disabled}
      loading={loading}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={handleSelectedBrandChange as any}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value={(autocompleteOptions.find(o => o?.id === selectedBrand) || null) as any}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderOption={(props, option) => (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <Box {...(props as any)} sx={styles.option} key={option.id}>
          <Tooltip title={option.label} placement="top" disableHoverListener={option.label.length <= 23}>
            <Typography sx={styles.name}>{option.label}</Typography>
          </Tooltip>
        </Box>
      )}
      sx={styles.select}
      renderInput={params => (
        <TextField {...params} sx={styles.input} label="Brand" InputLabelProps={{ shrink: true }} />
      )}
    />
  );
}
