import { Action } from 'common/interfaces/root';
import { overviewActionTypes as T } from '../actions';

const initState: OverviewV2.Top10ExportStore = {
  loading: false,
  error: null,
  data: null,
};

export default (
  state: OverviewV2.Top10ExportStore = initState,
  { type, payload }: Action
): OverviewV2.Top10ExportStore => {
  switch (type) {
    case T.GET_TOP10_EXPORT:
      return { ...state, loading: true };
    case T.GET_TOP10_EXPORT_SUCCESS:
      return { ...state, loading: false, data: payload };
    case T.GET_TOP10_EXPORT_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
