import { ApiService } from 'common/services';
import { saveAs } from 'file-saver';

class AsinFinderApiService extends ApiService {
  generateReport = (payload: AsinFinder.GenerateAosData) => {
    return this.post('/api/publishers/report/aos', {
      ...(payload.brands_parents_ids ? { brand_parent_ids: payload.brands_parents_ids } : {}),
      ...(payload.brands_ids ? { brands_ids: payload.brands_ids } : {}),
      ...(payload.asins ? { asins: payload.asins } : {}),
    });
  };

  getBrands = () => {
    return this.get('/api/seller-rocket/brands');
  };

  getBrandParents = () => {
    return this.get('/api/seller-rocket/brands-parents');
  };

  openReportLink = (payload: { id: string; code: string }) => {
    return this.get(`/api/publishers/report/aos/${payload.code}`);
  };

  getAsinsList = (payload: { limit: number; offset: number; search: Array<string> }) => {
    return this.get('/api/ext/publisher/articles', {
      params: {
        ...(payload.limit ? { limit: payload.limit } : {}),
        ...(payload.offset ? { offset: payload.offset } : {}),
        ...(payload.search ? { asins: payload.search } : {}),
      },
    });
  };

  downloadAsinsCsv = (payload: string) => {
    return this.get(`/api/publishers/urls/download-csv${payload ? `?search=${payload}` : ''}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => {
      const blob = new Blob([response.data], {
        type: 'application/csv',
      });
      const filename = 'asin.csv';
      saveAs(blob, filename);
    });
  };
}

export const asinFinderApiService = new AsinFinderApiService(process.env.REACT_APP_API_URL);
