import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Popover, TextField, Typography } from '@mui/material';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { asinRecommendationActions } from 'modules/asin-recommendation/store/actions';
import { asinRecommendationSelectors } from 'modules/asin-recommendation/store/selectors';

const styles: Styles = {
  container: {
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '300px',
    width: '100%',
    boxSizing: 'border-box',
  },
  title: { fontWeight: 600, fontSize: '16px' },
  field: { display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2 },
  fieldTitle: { mr: 2 },
  bttnWrapper: { mt: 7, display: 'flex', justifyContent: 'flex-end', width: '100%' },
};

interface Props {
  onClose: () => void;
  anchorElement: HTMLElement | null;
  asin: string;
  handleAddNewTag: (value: string) => void;
}

export function TagsPopover({ onClose, anchorElement, asin, handleAddNewTag }: Props): ReactElement {
  const dispatch = useDispatch();

  const [url, setUrl] = useState<string>('');

  const globalFilters = useSelector(selectGlobalFilters);
  const placements = useSelector(asinRecommendationSelectors.selectPlacements);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value as string);
  };

  const handleGenerate = () => {
    dispatch(
      asinRecommendationActions.attrTag.generateAttrTag(globalFilters?.publisher_id, asin, url, handleAddNewTag)
    );
    onClose();
  };

  useEffect(() => {
    if (placements?.data?.length) {
      setUrl(placements?.data[0]?.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placements?.data?.length]);

  return (
    <Popover
      onClose={onClose}
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={styles.container}>
        <Typography sx={styles.title}>Generate attribution tags</Typography>
        <Box sx={styles.field}>
          <Typography sx={styles.fieldTitle}>URL</Typography>
          <TextField value={url} onChange={handleChange} />
        </Box>
        <Box sx={styles.bttnWrapper}>
          <Button color="primary" variant="contained" onClick={handleGenerate}>
            Generate
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}
