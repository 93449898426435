import { ReactElement, useEffect, useRef } from 'react';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  BarController,
} from 'chart.js';
import { Box, CircularProgress } from '@mui/material';
import { Styles } from 'common/types';
import { paper } from 'common/constants/colors';
import { EmptyChart } from 'common/ui/empty-chart';

Chart.register(CategoryScale, LinearScale, PointElement, BarElement, BarController, Title, Tooltip, Legend);

const chartsStyles: Styles = {
  container: {
    backgroundColor: paper,
    borderRadius: '20px',
    width: '100%',
    padding: 2,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 365,
    position: 'relative',
  },
  chart: { flexGrow: 1, width: '100%' },
};

interface Props {
  datasets: Array<{ label: string; data: Array<number>; backgroundColor: string }>;
  labels: Array<string>;
  loading: boolean;
}

export function MavDailyChart({ datasets, labels, loading }: Props): ReactElement {
  const chartCanvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart>();

  const isEmpty = !labels?.length;

  // eslint-disable-next-line consistent-return
  const renderChart = () => {
    if (loading) return <CircularProgress color="primary" />;
    if (isEmpty)
      return <EmptyChart description="Your revenue will appear here" image="/assets/images/no-earnings-chart.svg" />;
    return (
      <Box sx={chartsStyles.chart}>
        <canvas ref={chartCanvasRef} />
      </Box>
    );
  };

  useEffect(() => {
    if (chartRef.current) chartRef.current.destroy();

    chartRef.current = new Chart(chartCanvasRef.current, {
      type: 'bar',
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: 'Date',
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: 'Total GMV',
            },
          },
        },
        plugins: {
          legend: {
            // eslint-disable-next-line object-shorthand
            onClick: function (evt, item) {
              window.open(`https://amazon.com/dp/${item.text}`);
            },
            position: 'bottom',
          },
        },
      },
      data: {
        datasets,
        labels,
      },
    });

    // eslint-disable-next-line consistent-return
    return () => chartRef.current.destroy();
  }, [labels, loading, datasets]);

  return <Box sx={chartsStyles.container}>{renderChart()}</Box>;
}
