import moment from 'moment/moment';
import { Action } from '../../../../common/interfaces/root';
import { amazonAffiliateReportsTypes } from '../actions';
import { RecoverAmazonAffiliateReportsState } from '../../types';

export const recoverAmazonMissingReportsInitState: RecoverAmazonAffiliateReportsState = {
  publisher_id: null,
  start_date: moment(new Date().setHours(0, 0, 0, 0)).add(-1, 'days'),
  end_date: moment(new Date().setHours(0, 0, 0, 0)),
  loading: false,
  error: null,
};

export const recoverAmazonAffiliateReportsReducer = (state = recoverAmazonMissingReportsInitState, action: Action) => {
  switch (action.type) {
    case amazonAffiliateReportsTypes.RECOVER_MISSING_REPORTS:
      return { ...state, loading: true, error: null };
    case amazonAffiliateReportsTypes.RECOVER_MISSING_REPORTS_SUCCESS:
      return { ...state, loading: false };
    case amazonAffiliateReportsTypes.RECOVER_MISSING_REPORTS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case amazonAffiliateReportsTypes.SET_RECOVER_MISSING_REPORTS_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
