const MODULE = '[presets]';

const GET_FILTERS_PRESETS = `${MODULE} GET_FILTERS_PRESETS`;
const GET_FILTERS_PRESETS_SUCCESS = `${MODULE} GET_FILTERS_PRESETS_SUCCESS`;
const GET_FILTERS_PRESETS_ERROR = `${MODULE} GET_FILTERS_PRESETS_ERROR`;

const GET_TIME_INFO = `${MODULE} GET_TIME_INFO`;
const GET_TIME_INFO_SUCCESS = `${MODULE} GET_TIME_INFO_SUCCESS`;
const GET_TIME_INFO_ERROR = `${MODULE} GET_TIME_INFO_ERROR`;

export const presetsActionsTypes = {
  GET_FILTERS_PRESETS,
  GET_FILTERS_PRESETS_SUCCESS,
  GET_FILTERS_PRESETS_ERROR,
  GET_TIME_INFO_SUCCESS,
  GET_TIME_INFO,
  GET_TIME_INFO_ERROR,
};
