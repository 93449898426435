export const getTop10ChartNetworkColor = (network: string) => {
  switch (network) {
    case 'Google search':
      return '#214254';
    case 'Google':
      return '#F4B400';
    case 'Google paid':
      return '#4288F0';
    case 'Bing search':
      return '#008373';
    case 'Bing':
      return '#034a41';
    case 'Bing paid':
      return '#0ec4ad';
    case 'Facebook':
      return '#4267B2';
    case 'Facebook paid':
      return '#898F9C';
    case 'Instagram':
      return '#833AB4';
    case 'Twitter':
      return '#1DA1F2';
    case 'Pinterest':
      return '#E60023';
    case 'Mobile':
      return '#a4c639';
    case 'Direct':
      return '#eb8de3';
    default:
      return '#D1E7F1';
  }
};
