import { Button, Box } from '@mui/material';
import { ReactElement } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import { Styles } from 'common/types';
import { scheme } from 'modules/stats/mock';

const styles: Styles = {
  button: { padding: '5px 25px 5px 25px', borderRadius: '12px', color: scheme.baseDark, borderColor: scheme.filter },
};

export function StatsFilter(): ReactElement {
  return (
    <Box>
      <Button variant="outlined" sx={styles.button}>
        <TuneIcon />
        Filter
      </Button>
    </Box>
  );
}
