import { getDataListActions } from 'common/store/actions/data-list';
import { publishersSettingsActionTypes as T, publishersParentsActionTypes } from './action-types';

const getPublishers = (params?: PublishersSettingsModule.GetPublishersParams) => ({
  type: T.GET_PUBLISHERS,
  payload: params,
});

const getPublishersSuccess = (publishers: Array<PublishersSettingsModule.Publisher>) => ({
  type: T.GET_PUBLISHERS_SUCCESS,
  payload: publishers,
});

const getPublishersError = (error: Error) => ({
  type: T.GET_PUBLISHERS_ERROR,
  payload: error,
});

const getPublisherBlockedBrands = (publisherID: number) => ({
  type: T.GET_PUBLISHER_BLOCKED_BRANDS,
  payload: publisherID,
});

const getPublisherBlockedBrandsSuccess = (data: Array<PublishersSettingsModule.PublisherBlockedBrand>) => ({
  type: T.GET_PUBLISHER_BLOCKED_BRANDS_SUCCESS,
  payload: data,
});

const getPublisherBlockedBrandsError = (error: Error) => ({
  type: T.GET_PUBLISHER_BLOCKED_BRANDS_ERROR,
  payload: error,
});

const blockPublisherBrand = (payload: PublishersSettingsModule.BlockPublisherBrandPayload) => ({
  type: T.BLOCK_PUBLISHER_BRAND,
  payload,
});

const unblockPublisherBrand = (payload: PublishersSettingsModule.BlockPublisherBrandPayload) => ({
  type: T.UNBLOCK_PUBLISHER_BRAND,
  payload,
});

const createPublisher = (publisher: PublishersSettingsModule.PublisherForm) => ({
  type: T.CREATE_PUBLISHER,
  payload: publisher,
});

const createPublisherSuccess = () => ({
  type: T.CREATE_PUBLISHER_SUCCESS,
});

const createPublisherError = (error: Error) => ({
  type: T.CREATE_PUBLISHER_ERROR,
  payload: error,
});

const editPublisher = (publisher: PublishersSettingsModule.PublisherForm, id: number) => ({
  type: T.EDIT_PUBLISHER,
  payload: { publisher, id },
});

const editPublisherSuccess = (message: string) => ({
  type: T.EDIT_PUBLISHER_SUCCESS,
  payload: message,
});

const editPublisherError = (error: Error) => ({
  type: T.EDIT_PUBLISHER_ERROR,
  payload: error,
});

const deletePublisher = (id: number) => ({
  type: T.DELETE_PUBLISHER,
  payload: id,
});

const deletePublisherSuccess = () => ({
  type: T.DELETE_PUBLISHER_SUCCESS,
});

const deletePublisherError = (error: Error) => ({
  type: T.DELETE_PUBLISHER_ERROR,
  payload: error,
});

const setSearch = (search: string) => ({
  type: T.SET_SEARCH,
  payload: search,
});

const setGroup = (group: string) => ({
  type: T.SET_GROUP,
  payload: group,
});

const resetFilters = () => ({
  type: T.RESET_FILTERS,
});

const setGainz = (gainz: string | boolean) => ({
  type: T.SET_GAINZ,
  payload: gainz,
});

const setDisplayCommission = (display_commission?: boolean) => ({
  type: T.SET_DISPLAY_COMMISSION,
  payload: display_commission,
});

const addPublishersParents = (
  payload: { name: string; publisher: PublishersSettingsModule.PublisherForm },
  onSuccess: (value: PublishersSettingsModule.PublisherForm) => void,
  onRefresh: () => void
) => ({
  type: T.ADD_PUBLISHERS_PARENTS,
  payload,
  meta: { onSuccess, onRefresh },
});

const deletePublishersParents = (payload: string, onSuccess: () => void) => ({
  type: T.DELETE_PUBLISHERS_PARENTS,
  payload,
  meta: { onSuccess },
});

export const publishersParentsActions = getDataListActions(publishersParentsActionTypes);

export const publishersSettingsActions = {
  getPublishers,
  getPublishersSuccess,
  getPublishersError,
  getPublisherBlockedBrands,
  getPublisherBlockedBrandsSuccess,
  getPublisherBlockedBrandsError,
  blockPublisherBrand,
  unblockPublisherBrand,
  createPublisher,
  createPublisherSuccess,
  createPublisherError,
  editPublisher,
  editPublisherSuccess,
  editPublisherError,
  deletePublisher,
  deletePublisherSuccess,
  deletePublisherError,
  setSearch,
  setGroup,
  setGainz,
  setDisplayCommission,
  resetFilters,
  addPublishersParents,
  deletePublishersParents,
};
