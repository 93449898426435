import { Action } from 'common/interfaces/root';
import { publisherBrandCommissionsActionTypes as T } from '../actions/action-types';

const initState: PublishersBrandCommissionsModule.PublisherBrandCommissionsStore = {
  data: [],
  loading: false,
  error: null,
};

export default (
  state: PublishersBrandCommissionsModule.PublisherBrandCommissionsStore = initState,
  { type, payload }: Action
): PublishersBrandCommissionsModule.PublisherBrandCommissionsStore => {
  switch (type) {
    case T.GET_PUBLISHER_BRAND_COMMISSIONS: {
      return { ...state, loading: true };
    }
    case T.GET_PUBLISHER_BRAND_COMMISSIONS_SUCCESS: {
      return { ...state, loading: false, data: payload };
    }
    case T.GET_PUBLISHER_BRAND_COMMISSIONS_ERROR: {
      return { ...state, loading: false, error: payload };
    }
    case T.ADD_PUBLISHER_BRAND_COMMISSION_SUCCESS: {
      return { ...state, loading: false, data: [payload, ...state.data] };
    }
    case T.EDIT_PUBLISHER_BRAND_COMMISSION_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: state.data.map(record => (record.brand_id === payload.brand_id ? payload : record)),
      };
    }
    case T.DELETE_PUBLISHER_BRAND_COMMISSION_SUCCESS: {
      return { ...state, loading: false, data: state.data.filter(data => data.brand_id !== +payload) };
    }
    default: {
      return state;
    }
  }
};
