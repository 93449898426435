import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';
import { Styles } from 'common/types';
import { ITableSort } from 'common/ui/base-filters/types';
import { PerformanceTableSortField } from 'modules/content-overview/components/constants';
import { contentOverviewActions, pagesPerformanceActions } from 'modules/content-overview/store/actions';
import {
  selectPagesPerformance,
  selectPagesPerformanceLimitPageAmount,
  selectPagesPerformanceLoading,
} from 'modules/content-overview/store/selectors';
import { MouseEvent, ReactElement, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { usePermission } from 'common/hooks';
import { PERMISSIONS } from 'common/constants/permissions';
import { PageRow } from './page-row';
import { ColumnsPopover, columnsPopoverCtx, PerformanceTableColumn } from './columns-popover';
import { selectDatePickerComparator, selectRootDemo } from '../../../../../../common/store/selectors';
import { TableContainerWithLoadingGrow } from '../../../../../../common/ui/table-container-with-loading/table-container-with-loading-grow';
import './page-performance-table.css';
import { OOSReplacementsModal } from '../oos-replacements-modal';
import { MetricsTableHeadCell } from './metrics-table-head-cell';

const styles: Styles = {
  container: {
    backgroundColor: '#fff',
    borderRadius: '20px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '75vw',
  },
  cell: { color: '#214254', fontSize: '12px', whiteSpace: 'nowrap' },
};

interface Props {
  handleSortCreation: (field: PerformanceTableSortField) => () => void;
  sort: ITableSort;
}

export function PagePerformanceTable({ handleSortCreation, sort }: Props): ReactElement {
  const dispatch = useDispatch();

  const pagesPerformance = useSelector(selectPagesPerformance);
  const loading = useSelector(selectPagesPerformanceLoading);

  const { amount, page, limit } = useSelector(selectPagesPerformanceLimitPageAmount);
  const { isCompare } = useSelector(selectDatePickerComparator);

  const [colsButtonAnchor, setColsButtonAnchor] = useState<HTMLElement | null>(null);
  const { columns } = useContext(columnsPopoverCtx);

  const isDemoMode = useSelector(selectRootDemo);

  const isAdmin = usePermission(PERMISSIONS.GET_PUBLISHERS);

  const handlePageChange = (_: MouseEvent<HTMLButtonElement>, page: number) => {
    dispatch(pagesPerformanceActions.changePage(page));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(pagesPerformanceActions.changeLimit(parseInt(event.target.value, 10)));
    dispatch(pagesPerformanceActions.changePage(0));
  };

  const handleColsPopoverOpen = (e: MouseEvent<HTMLElement>) => {
    setColsButtonAnchor(e.currentTarget);
  };

  const handleColsPopoverClose = () => {
    setColsButtonAnchor(null);
  };

  const openArticle = (url: string) => {
    const article = pagesPerformance.filter(a => a.url === url)[0];
    dispatch(contentOverviewActions.setPagePerformanceActiveArticle(article));
    dispatch(contentOverviewActions.setPagePerformanceActiveArticleModalVisible(true));
  };

  return (
    <Box sx={styles.container}>
      <TableContainerWithLoadingGrow loading={loading} round={false}>
        <Table stickyHeader className="page-performance__table">
          <TableHead>
            <TableRow>
              {columns.includes(PerformanceTableColumn.Publisher) && <TableCell>Publisher</TableCell>}
              <TableCell>Article</TableCell>
              <TableCell sx={styles.cell}>Found on URL</TableCell>
              <TableCell />
              {columns.includes(PerformanceTableColumn.RecommendedAsins) && (
                <TableCell sx={styles.cell}>Recommended ASINs to Add</TableCell>
              )}
              {columns.includes(PerformanceTableColumn.FirstSeenOOS) && (
                <TableCell sx={styles.cell}>OOS Date</TableCell>
              )}
              {columns.includes(PerformanceTableColumn.Tag) && <TableCell sx={styles.cell}>Tag</TableCell>}
              {columns.includes(PerformanceTableColumn.Saturation) && isAdmin && (
                <TableCell sx={styles.cell}>Saturation</TableCell>
              )}
              {columns.includes(PerformanceTableColumn.SaturationPercent) && isAdmin && (
                <TableCell sx={styles.cell}>Saturation Percent</TableCell>
              )}
              <MetricsTableHeadCell
                colName="Visits"
                title="Visits"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell
                colName="Clicks"
                title="Clicks"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell
                colName="Conversion"
                title="Conversion"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell colName="GMV" title="GMV" sort={sort} handleSortCreation={handleSortCreation} />
              <MetricsTableHeadCell
                colName="RevenuePerVisit"
                title="GMV / Visit"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell
                colName="AdFees"
                title="Ad Fees"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell
                colName="MobileVisits"
                title="Mobile Visits"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell
                colName="MobileClicks"
                title="Mobile Clicks"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell
                colName="MobileShipments"
                title="Mobile Shipments"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell
                colName="MobileRevenue"
                title="Mobile Revenue"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell
                colName="MobileConversion"
                title="Mobile Conversion"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell
                colName="MobileAdFees"
                title="Mobile Ad Fees"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell
                colName="DesktopVisits"
                title="Desktop Visits"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell
                colName="DesktopClicks"
                title="Desktop Clicks"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell
                colName="DesktopShipments"
                title="Desktop Shipments"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell
                colName="DesktopRevenue"
                title="Desktop Revenue"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell
                colName="DesktopConversion"
                title="Desktop Conversion"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <MetricsTableHeadCell
                colName="DesktopAdFees"
                title="Desktop Ad Fees"
                sort={sort}
                handleSortCreation={handleSortCreation}
              />
              <TableCell>
                <Tooltip title="Add columns" placement="top">
                  <IconButton size="small" onClick={handleColsPopoverOpen}>
                    <FormatListBulletedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagesPerformance?.map(page => (
              <PageRow
                key={page.publisher_urls_id}
                row={page}
                isDemoMode={isDemoMode}
                openArticle={openArticle}
                isCompare={isCompare}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoadingGrow>
      <TablePagination
        component="div"
        count={Number(amount)}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 30, 50, 100]}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ColumnsPopover anchorEl={colsButtonAnchor} onClose={handleColsPopoverClose} isAdmin={isAdmin} />
      <OOSReplacementsModal />
    </Box>
  );
}
