import { Action } from 'common/interfaces/root';
import { asinsConversionsBoostTypes as TYPES } from '../actions/action-types';

const initialState: AsinsConversionsBoostModule.ExportStore = {
  loading: false,
  error: null,
  data: null,
};

export default (
  state: AsinsConversionsBoostModule.ExportStore = initialState,
  { type, payload }: Action
): AsinsConversionsBoostModule.ExportStore => {
  switch (type) {
    case TYPES.EXPORT.GET_EXPORT:
      return { ...state, loading: true };
    case TYPES.EXPORT.GET_EXPORT_SUCCESS:
      return { ...state, loading: false, data: payload };
    case TYPES.EXPORT.GET_EXPORT_ERROR:
      return { ...state, loading: false, error: payload };
    case TYPES.EXPORT.RESET_EXPORT:
      return initialState;
    default:
      return state;
  }
};
