import { Box, Link, TableCell, TableRow, Tooltip, Zoom } from '@mui/material';
import { Styles } from 'common/types';
import { conversionsSelectors } from 'modules/conversions/store/selectors';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

const styles: Styles = {
  boxCell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '200px',
  },
};

interface Props {
  row: Conversions.Table.TableRow;
}

export function ConversionsTableRow({ row }: Props): ReactElement {
  const table = useSelector(conversionsSelectors.selectTable);

  const getStyles = (row: Conversions.Table.TableRow) => {
    const index = table.rows.indexOf(row);
    const previous = table.rows[index - 1];
    const next = table.rows[index + 1];

    const isValidRow = row.trackingId !== null && previous && previous.trackingId !== null;

    const matchesPrev = previous && row.trackingId === previous.trackingId;
    const matchesNext = next && row.trackingId === next.trackingId;

    if (!isValidRow) return styles.tableCell;

    if (index % table.pageSize === 0 && !matchesNext) return styles.tableCell;
    if (index % table.pageSize === 0 && matchesNext) return styles.combinedRowCell1;
    if (matchesPrev && matchesNext) return styles.combinedRowCell2;
    if (!matchesPrev && matchesNext) return styles.combinedRowCell1;
    if (matchesPrev) return styles.combinedRowCell3;

    return styles.tableCell;
  };

  const getTitle = (row: Conversions.Table.TableRow, title: string) => {
    const index = table.rows.indexOf(row);
    const previous = table.rows[index - 1];

    const isValidRow = row.trackingId !== null && previous && previous.trackingId !== null;

    const matchesPrev = isValidRow && row.trackingId === previous.trackingId;

    if (index % table.pageSize === 0) return title;
    if (matchesPrev) return '';

    return title;
  };

  return (
    <TableRow sx={styles.tableRow} key={table.rows.indexOf(row)}>
      <TableCell sx={getStyles(row)}>
        <Tooltip title={row.pageUrl} TransitionComponent={Zoom}>
          <Box sx={styles.boxCell}>{getTitle(row, row.pageUrl)}</Box>
        </Tooltip>
      </TableCell>
      <TableCell sx={getStyles(row)}>{getTitle(row, row.timeOfVisit)}</TableCell>
      <TableCell sx={getStyles(row)}>
        <Tooltip title={row.trackingId} TransitionComponent={Zoom}>
          <Box sx={styles.boxCell}>{getTitle(row, row.trackingId)}</Box>
        </Tooltip>
      </TableCell>
      <TableCell sx={getStyles(row)}>{getTitle(row, row.tag)}</TableCell>
      <TableCell sx={getStyles(row)}>
        <Link href={row.click_url} target="_blank" underline="hover">
          {row.asin}
        </Link>
      </TableCell>
      <TableCell sx={getStyles(row)}>{row.timeOfShipment}</TableCell>
      <TableCell sx={getStyles(row)}>{row.dateOfUpload}</TableCell>
      <TableCell sx={getStyles(row)}>{row.convAdFees}</TableCell>
    </TableRow>
  );
}
