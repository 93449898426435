import { Action } from 'common/interfaces/root';
import { affiliateRateCardActionTypes as TYPES } from '../actions/types';

const initState: AffiliateRateCardModule.DefaultsStore = {
  loading: false,
  srDefault: null,
  srData: null,
  abDefault: null,
  abData: null,
  error: null,
};

export default (state = initState, { type, payload }: Action): AffiliateRateCardModule.DefaultsStore => {
  switch (type) {
    case TYPES.RATES.DEFAULT.GET_SR_DEFAULT:
      return { ...state, loading: true };
    case TYPES.RATES.DEFAULT.GET_SR_DEFAULT_SUCCESS:
      return { ...state, loading: false, srDefault: payload };
    case TYPES.RATES.DEFAULT.GET_SR_DEFAULT_ERROR:
      return { ...state, loading: false, error: payload };

    case TYPES.RATES.DEFAULT.GET_AB_DEFAULT:
      return { ...state, loading: true };
    case TYPES.RATES.DEFAULT.GET_AB_DEFAULT_SUCCESS:
      return { ...state, loading: false, abDefault: payload };
    case TYPES.RATES.DEFAULT.GET_AB_DEFAULT_ERROR:
      return { ...state, loading: false, error: payload };

    case TYPES.RATES.DEFAULT.SET_SR_DEFAULT:
      return { ...state, srDefault: payload };
    case TYPES.RATES.DEFAULT.SET_AB_DEFAULT:
      return { ...state, abDefault: payload };

    case TYPES.RATES.DEFAULT.PATCH_SR_DEFAULT:
      return { ...state, loading: true };
    case TYPES.RATES.DEFAULT.PATCH_SR_DEFAULT_SUCCESS:
      return { ...state, loading: false, srData: payload };
    case TYPES.RATES.DEFAULT.PATCH_SR_DEFAULT_ERROR:
      return { ...state, loading: false, error: payload };

    case TYPES.RATES.DEFAULT.PATCH_AB_DEFAULT:
      return { ...state, loading: true };
    case TYPES.RATES.DEFAULT.PATCH_AB_DEFAULT_SUCCESS:
      return { ...state, loading: false, abData: payload };
    case TYPES.RATES.DEFAULT.PATCH_AB_DEFAULT_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
