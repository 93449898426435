import { Action } from 'common/interfaces/root';
import { asinRecommendationActionTypes as TYPES } from '../actions/action-types';

const initialState: AsinRecommendationModule.State.TypesState = {
  loading: false,
  data: null,
  error: null,
};

export default (
  state: AsinRecommendationModule.State.TypesState = initialState,
  { type, payload }: Action
): AsinRecommendationModule.State.TypesState => {
  switch (type) {
    case TYPES.TYPES.GET_TYPES:
      return { ...state, loading: true };
    case TYPES.TYPES.GET_TYPES_SUCCESS:
      return { ...state, loading: false, data: payload };
    case TYPES.TYPES.GET_TYPES_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
