import { MouseEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { Styles } from 'common/types';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { useDispatch, useSelector } from 'react-redux';
import { SearchInput } from 'common/ui/search-input';
import {
  selectCurrentPublisherTagsPage,
  selectPublisherTags,
  selectPublisherTagsAmount,
  selectPublisherTagsLoading,
  selectPublisherTagsFilters,
} from 'modules/settings/store/selectors';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { useDebouncedCallback } from 'use-debounce';
import { selectGlobalFilters, selectRootDemo } from 'common/store/selectors';
import { TableRowActions } from 'common/ui/table-row-actions';
import { background, text } from 'common/constants/colors';
import { theme } from 'common/constants/theme';
import { usePermission } from 'common/hooks';
import { PERMISSIONS } from 'common/constants/permissions';
import { TagsModal } from './tags-modal';
import { TagsFiltersModal } from './filters-modal';
import { DefaultTagModal } from './default-tag-modal';

const getStyles = ({ isDemoMode }: { isDemoMode: boolean }): Styles => ({
  table: { marginTop: 0 },
  cell: { color: '#21425480', fontSize: '10px' },
  usedCircle: {
    width: '8px',
    height: '8px',
    backgroundColor: '#62D294',
    borderRadius: '50%',
  },
  status: {
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E3EDFD',
    color: '#4288F0',
    fontSize: '12px',
    borderRadius: '20px',
  },
  anotherStatus: {
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E7F8EF',
    color: '#62D294',
    fontSize: '12px',
    borderRadius: '20px',
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0 10px 0',
    boxSizing: 'border-box',
  },
  searchForm: {
    p: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '300px',
    marginBottom: '5px',
  },
  searchInput: {
    ml: 1,
    flex: 1,
    width: '100%',
  },
  publisherName: {
    filter: isDemoMode ? 'blur(3px)' : 0,
  },
  cardsContainer: { display: 'flex', justifyContent: 'flex-start', gap: 5, m: 3 },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 1,
    p: 2,
    width: 200,
    borderRadius: 5,
    bgcolor: background,
  },
  cardTitle: { fontSize: 14, fontWeight: 400 },
  cardValue: { color: text, fontSize: 27 },
  filtersBtn: {
    width: 36,
    height: 36,
    p: 0,
    minWidth: 36,
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    ':hover': { color: theme.palette.primary.dark, borderColor: theme.palette.primary.dark },
  },
  filtersWrapper: { display: 'flex', alignItems: 'center', gap: 1.5 },
});

export function TagsSettings(): ReactElement {
  const dispatch = useDispatch();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isOpenAddTags, setOpenAddTags] = useState<boolean>(false);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [defaultTagOpen, setDefaultTagOpen] = useState<boolean>(false);

  const loading = useSelector(selectPublisherTagsLoading);
  const amount = useSelector(selectPublisherTagsAmount);
  const currentPage = useSelector(selectCurrentPublisherTagsPage);
  const rows = useSelector(selectPublisherTags);
  const filters = useSelector(selectGlobalFilters);
  const tagsFilters = useSelector(selectPublisherTagsFilters);
  const isDemoMode = useSelector(selectRootDemo);

  const styles = getStyles({ isDemoMode });
  const [searchValue, setSearchValue] = useState<string>('');

  const isAdmin = usePermission(PERMISSIONS.GET_PUBLISHERS);

  const id = String(filters?.publisher_id);

  const parsedFiltersForTable: TagsModule.GetTagsParams = useMemo(
    () => ({
      limit: 10,
      offset: currentPage * 10,
      publisher_id: Number(id),
      ...(searchValue !== ''
        ? {
            search: searchValue,
          }
        : {}),
      ...(tagsFilters.traffic_type === 'all' ? {} : { traffic_type: tagsFilters.traffic_type }),
      ...(tagsFilters.used === 'all' ? {} : { used: tagsFilters.used === 'true' }),
    }),
    [currentPage, id, searchValue, tagsFilters]
  );

  const handleOpenAddTagsModal = () => {
    setOpenAddTags(true);
  };

  const handleCloseAddTagsModal = () => {
    setOpenAddTags(false);
  };

  const handleDefaultTagOpen = () => {
    setDefaultTagOpen(true);
  };

  const handleDefaultTagClose = () => {
    setDefaultTagOpen(false);
  };

  const handleFiltersOpen = () => {
    setFiltersOpen(true);
  };

  const handleFiltersClose = () => {
    setFiltersOpen(false);
  };

  const debouncedTableStatistics = useDebouncedCallback((filters: TagsModule.GetTagsParams) => {
    dispatch(publishersSettingsActions.getPublisherTags(filters));
  }, 300);

  useEffect(() => {
    if (!isInitialized) return;

    debouncedTableStatistics(parsedFiltersForTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, parsedFiltersForTable.limit, parsedFiltersForTable.offset]);

  useEffect(() => {
    setIsInitialized(true);

    dispatch(publishersSettingsActions.getPublisherTags(parsedFiltersForTable));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (_e: MouseEvent<HTMLButtonElement>, page: number) => {
    dispatch(publishersSettingsActions.changePublisherTagsPage(page));
  };

  const handleRefreshTable = () => {
    dispatch(publishersSettingsActions.getPublisherTags(parsedFiltersForTable));
  };

  const handleDeleteTag = (id: number) => {
    dispatch(publishersSettingsActions.deletePublisherTag(String(id), handleRefreshTable));
  };

  const debouncedSearch = useDebouncedCallback(() => {
    dispatch(publishersSettingsActions.getPublisherTags(parsedFiltersForTable));
  }, 500);

  const handleChangeSearch = (search: string) => {
    dispatch(publishersSettingsActions.changePublisherTagsPage(0));
    setSearchValue(search);
    debouncedSearch();
  };

  const handleApplyFilters = () => {
    dispatch(publishersSettingsActions.getPublisherTags(parsedFiltersForTable));
    dispatch(publishersSettingsActions.changePublisherTagsPage(0));
    handleFiltersClose();
  };

  const handleResetFilters = () => {
    dispatch(publishersSettingsActions.resetFilters());
  };

  const cards = [
    { title: 'Total Tags', value: rows?.tags_count },
    { title: 'Used Organic', value: rows?.organic_used },
    { title: 'Used Paid', value: rows?.paid_used },
    { title: 'Available Tags', value: rows?.free_tags },
  ];

  return (
    <>
      <Box sx={styles.btnWrapper}>
        <Box sx={styles.filtersWrapper}>
          <SearchInput search={searchValue} handleSearchChange={handleChangeSearch} primaryBg />
          <Button variant="outlined" sx={styles.filtersBtn} size="small" onClick={handleFiltersOpen}>
            <TuneIcon fontSize="small" />
          </Button>
        </Box>
        <Box sx={styles.filtersWrapper}>
          {isAdmin && (
            <Button color="primary" variant="contained" onClick={handleDefaultTagOpen}>
              Default tag
            </Button>
          )}
          <Button color="primary" variant="contained" onClick={handleOpenAddTagsModal}>
            Add tag
          </Button>
        </Box>
      </Box>
      <Box sx={styles.cardsContainer}>
        {cards.map(card => (
          <Box sx={styles.card}>
            <Typography sx={styles.cardTitle}>{card.title}</Typography>
            <Typography sx={styles.cardValue}>
              {loading ? <CircularProgress size={14} /> : card.value?.toLocaleString()}
            </Typography>
          </Box>
        ))}
      </Box>
      <TableContainerWithLoading sx={styles.table} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.cell}>Publisher</TableCell>
              <TableCell sx={styles.cell}>Tag</TableCell>
              <TableCell sx={styles.cell}>Used</TableCell>
              <TableCell sx={styles.cell}>Traffic Type</TableCell>
              <TableCell sx={styles.cell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.tags
              ?.filter(row => !row?.deleted_at)
              .map(row => (
                <TableRow>
                  <TableCell sx={styles.publisherName}>{row?.publisher_name}</TableCell>
                  <TableCell>{row?.tag}</TableCell>
                  <TableCell>{row?.used ? <Box sx={styles.usedCircle} /> : null}</TableCell>
                  <TableCell>
                    {row?.traffic_type ? (
                      <Box sx={styles.anotherStatus}>
                        {row.traffic_type.charAt(0).toUpperCase() + row.traffic_type.slice(1)}
                      </Box>
                    ) : (
                      <Box sx={styles.status}>Organic</Box>
                    )}
                  </TableCell>
                  <TableCell>
                    <TableRowActions show={['delete']} onDelete={() => handleDeleteTag(row.id)} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <TablePagination
        component="div"
        count={amount}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        page={currentPage}
        onPageChange={handlePageChange}
      />
      <TagsModal open={isOpenAddTags} onClose={handleCloseAddTagsModal} handleRefreshTable={handleRefreshTable} />
      {isAdmin && <DefaultTagModal open={defaultTagOpen} onClose={handleDefaultTagClose} />}
      <TagsFiltersModal
        open={filtersOpen}
        handleApply={handleApplyFilters}
        handleReset={handleResetFilters}
        onClose={handleFiltersClose}
      />
    </>
  );
}
