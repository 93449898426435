import { Box, Drawer, Typography } from '@mui/material';
import { text } from 'common/constants/colors';
import { getNavConfig } from 'common/constants/nav';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'common/constants/theme';
import { MENU_WIDTH_XL } from 'common/constants/sizes';
import { Styles } from 'common/types';
import { userService } from 'common/services/user.service';
import { PublisherPicker } from 'common/ui/publisher-picker';
import { ReactComponent as MenuSquaresIcon } from 'common/svg/menu-squares.svg';
import { useTranslation } from 'react-i18next';
import { LC_SELECTED_PUBLISHER_KEY } from 'common/constants/lc';
import { useDispatch, useSelector } from 'react-redux';
import { filtersActions } from 'common/store/actions/filters';
import { selectGlobalFilters, selectRootDemo } from 'common/store/selectors';
import { ReactComponent as SettingsIcon } from 'common/svg/settings-icon.svg';
import { ReactComponent as LogoutIcon } from 'common/svg/logout-icon.svg';
import { selectUserData } from 'modules/user-settings/store/selectors';
import { usePermission, usePublisher } from 'common/hooks';
import { PERMISSIONS } from 'common/constants/permissions';
import { publishersSettingsActions as adminPublisherSettingsActions } from 'modules/publishers-settings/store/actions';
import { overviewActions } from 'modules/overview-v2/store/actions';
import { selectPublishersSettingsForm } from 'modules/publishers-settings/store/selectors';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { NavItems } from './nav-items';
import { AdminPanel } from './admin-panel';

interface StylesProps {
  image: string;
  isDemoMode: boolean;
}

const getStyles = ({ image, isDemoMode }: StylesProps): Styles => ({
  menuContainer: {
    width: MENU_WIDTH_XL,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: theme.palette.primary.light,
    overflowY: 'auto !important',
    scrollbarWidth: 'none',
  },
  brandContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 90,
    minHeight: 90,
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 30px',
    filter: isDemoMode ? 'blur(3px)' : 0,
  },
  brand: { alignSelf: 'center', color: theme.palette.background.paper, fontWeight: 700 },
  navItemsWrapper: { marginTop: 5, paddingLeft: 3 },
  drawer: { border: 'none' },
  userName: { fontSize: 18, color: text, marginTop: 1.5 },
  userWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 'auto',
    marginBottom: 5,
  },
  userImage: {
    backgroundImage: `url(${image})`,
    borderRadius: '50%',
    backgroundSize: 'cover',
    width: 65,
    height: 65,
    backgroundColor: theme.palette.primary.light,
  },
  userImageWrapper: {
    borderRadius: '50%',
    width: 80,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.background.default}`,
    cursor: 'pointer',
  },
  userBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 2,
    minWidth: '36px',
    height: '36px',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    cursor: 'pointer',
    m: '0 5px',
  },
  userInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  borderBottom: {
    display: 'flex',
    flexDirection: 'row',
  },
});

interface Props {
  open: boolean;
}

export function Menu({ open }: Props) {
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const filters = useSelector(selectGlobalFilters);
  const user = useSelector(selectUserData);
  const publisherEdit = useSelector(selectPublishersSettingsForm);

  const publisher = usePublisher();
  const permissions = usePermission(PERMISSIONS.GET_PUBLISHERS);

  const [isUserChanged, setUserChanged] = useState<boolean>(false);

  const isDemoMode = useSelector(selectRootDemo);
  const styles = getStyles({ image: isUserChanged ? user?.avatar : userService.user.avatar, isDemoMode });

  const handlePublisherChange = (publisher: number | null | string) => {
    if (publisher === '0') {
      dispatch(overviewActions.setIsCombined(true));
    } else {
      localStorage.setItem(LC_SELECTED_PUBLISHER_KEY, String(publisher));
      dispatch(overviewActions.setIsCombined(false));
      dispatch(filtersActions.changePublisher(publisher));
    }
  };

  const handleOpenUserSettings = () => {
    navigate('settings/user');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    if (user) {
      setUserChanged(true);
    }
  }, [user]);

  useEffect(() => {
    if (publisherEdit.success)
      dispatch(publishersSettingsActions.getMainPublisherInfo(filters.publisher_id.toString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisherEdit]);

  useEffect(() => {
    dispatch(adminPublisherSettingsActions.getPublishers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer PaperProps={{ sx: styles.drawer }} anchor="left" variant="persistent" open={open}>
      <Box sx={styles.menuContainer}>
        <Box sx={styles.brandContainer}>
          <PublisherPicker selectedPublisher={filters.publisher_id} handlePublisherChange={handlePublisherChange} />
          <MenuSquaresIcon />
        </Box>
        <Box sx={styles.navItemsWrapper}>
          <NavItems items={getNavConfig({ t, isGainz: publisher?.gainz_active, isGainzOnly: publisher?.gainz_only })} />
        </Box>
        {permissions && <AdminPanel />}
        <Box sx={styles.userWrapper}>
          <Box sx={styles.userInfoWrapper}>
            <Box sx={styles.userImageWrapper}>
              <Box sx={styles.userImage} />
            </Box>
            <Typography sx={styles.userName}>{user?.name ?? userService.user.name}</Typography>
          </Box>
          <Box sx={styles.borderBottom}>
            <Box sx={styles.userBtn} onClick={handleOpenUserSettings}>
              <SettingsIcon />
            </Box>
            <Box sx={styles.userBtn} onClick={handleLogout}>
              <LogoutIcon />
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
