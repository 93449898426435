import { ReactElement } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

interface BrandsCommissionsTableProps {
  loading: boolean;
  data?: PublishersBrandCommissionsModule.PublisherBrandCommissionData[];
}

export function BrandsCommissionsTable({ loading, data }: BrandsCommissionsTableProps): ReactElement {
  const renderRow = (data: PublishersBrandCommissionsModule.PublisherBrandCommissionData) => {
    return (
      <TableRow>
        <TableCell>{data?.brand_name || '-'}</TableCell>
        <TableCell>{`${data?.commission_rate}%` || '-'}</TableCell>
      </TableRow>
    );
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Brand</TableCell>
          <TableCell>Commission rate</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{!loading && data?.map(item => renderRow(item))}</TableBody>
    </Table>
  );
}
