import { Action } from 'common/interfaces/root';
import { asinsConversionsBoostTypes as TYPES } from '../actions/action-types';

const initialState: AsinsConversionsBoostModule.ConversionsBoostStore = {
  loading: false,
  error: null,
  data: null,
  amount: 0,
  page: 0,
};

export default (
  state: AsinsConversionsBoostModule.ConversionsBoostStore = initialState,
  { type, payload }: Action
): AsinsConversionsBoostModule.ConversionsBoostStore => {
  switch (type) {
    case TYPES.CONVERSIONS_BOOST.GET_CONVERSIONS_BOOST:
      return { ...state, loading: true };
    case TYPES.CONVERSIONS_BOOST.GET_CONVERSIONS_BOOST_SUCCESS:
      return { ...state, loading: false, data: payload, amount: payload.length };
    case TYPES.CONVERSIONS_BOOST.GET_CONVERSIONS_BOOST_ERROR:
      return { ...state, loading: false, error: payload };
    case TYPES.CONVERSIONS_BOOST.CHANGE_CONVERSIONS_BOOST_PAGE:
      return { ...state, page: payload };
    default:
      return state;
  }
};
