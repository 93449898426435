const PUBLISHER_TAGS_MODULE = 'PUBLISHER_AFFILIATE_TAGS';

const GET_PUBLISHERS_TAGS = `${PUBLISHER_TAGS_MODULE} GET_TAGS`;
const GET_PUBLISHERS_TAGS_SUCCESS = `${PUBLISHER_TAGS_MODULE} GET_TAGS_SUCCESS`;
const GET_PUBLISHERS_TAGS_ERROR = `${PUBLISHER_TAGS_MODULE} GET_TAGS_ERROR`;
const CHANGE_PUBLISHERS_TAGS_SORT = `${PUBLISHER_TAGS_MODULE} CHANGE_SORT`;
const CHANGE_PUBLISHERS_TAGS_PAGE = `${PUBLISHER_TAGS_MODULE} CHANGE_PAGE`;
const EDIT_PUBLISHERS_TAGS = `${PUBLISHER_TAGS_MODULE} EDIT_PUBLISHERS_TAGS`;
const EDIT_PUBLISHERS_TAGS_SUCCESS = `${PUBLISHER_TAGS_MODULE} EDIT_PUBLISHERS_TAGS_SUCCESS`;
const EDIT_PUBLISHERS_TAGS_ERROR = `${PUBLISHER_TAGS_MODULE} EDIT_PUBLISHERS_TAGS_ERROR`;

const SCHEDULE_TAGS_IMPORT = `${PUBLISHER_TAGS_MODULE} SCHEDULE_TAGS_IMPORT`;
const SCHEDULE_TAGS_IMPORT_SUCCESS = `${PUBLISHER_TAGS_MODULE} SCHEDULE_TAGS_IMPORT_SUCCESS`;
const SCHEDULE_TAGS_IMPORT_ERROR = `${PUBLISHER_TAGS_MODULE} SCHEDULE_TAGS_IMPORT_ERROR`;
const GET_TAGS_IMPORT_STATUS = `${PUBLISHER_TAGS_MODULE} GET_TAGS_IMPORT`;
const GET_TAGS_IMPORT_STATUS_SUCCESS = `${PUBLISHER_TAGS_MODULE} GET_TAGS_IMPORT_SUCCESS`;
const GET_TAGS_IMPORT_STATUS_ERROR = `${PUBLISHER_TAGS_MODULE} GET_TAGS_IMPORT_ERROR`;
const RESET_TAGS_IMPORT_STATUS = `${PUBLISHER_TAGS_MODULE} RESET_TAGS_IMPORT`;

export const PublisherTagsActionTypes = {
  GET_PUBLISHERS_TAGS,
  GET_PUBLISHERS_TAGS_SUCCESS,
  GET_PUBLISHERS_TAGS_ERROR,
  CHANGE_PUBLISHERS_TAGS_SORT,
  CHANGE_PUBLISHERS_TAGS_PAGE,
  EDIT_PUBLISHERS_TAGS,
  EDIT_PUBLISHERS_TAGS_SUCCESS,
  EDIT_PUBLISHERS_TAGS_ERROR,
  SCHEDULE_TAGS_IMPORT,
  GET_TAGS_IMPORT_STATUS,
  SCHEDULE_TAGS_IMPORT_SUCCESS,
  SCHEDULE_TAGS_IMPORT_ERROR,
  GET_TAGS_IMPORT_STATUS_SUCCESS,
  GET_TAGS_IMPORT_STATUS_ERROR,
  RESET_TAGS_IMPORT_STATUS,
};
