/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { COLUMNS_LC_KEY } from 'modules/content-overview/components/constants';
import { createContext, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import { PerformanceTableColumn } from './columns-popover';

export const columnsPopoverCtx = createContext({
  columns: [],
  addCol: (col: PerformanceTableColumn) => {},
  removeCol: (col: PerformanceTableColumn) => {},
});

interface Props {
  children: ReactNode;
}

export function ColumnsPopoverContext({ children }: Props): ReactElement {
  const storedColumns = localStorage.getItem(COLUMNS_LC_KEY);

  const [columns, setColumns] = useState<Array<PerformanceTableColumn>>(
    storedColumns ? JSON.parse(storedColumns) : [PerformanceTableColumn.Clicks, PerformanceTableColumn.Visits]
  );

  const addCol = useCallback((col: PerformanceTableColumn) => {
    setColumns(columns => [...columns, col]);
  }, []);

  const removeCol = useCallback((col: PerformanceTableColumn) => {
    setColumns(columns => columns.filter(c => c !== col));
  }, []);

  useEffect(() => {
    localStorage.setItem(COLUMNS_LC_KEY, JSON.stringify(columns));
  }, [columns]);

  const values = useMemo(() => {
    return { columns, addCol, removeCol };
  }, [addCol, columns, removeCol]);

  return <columnsPopoverCtx.Provider value={values}>{children}</columnsPopoverCtx.Provider>;
}
