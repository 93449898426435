import { Box } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement } from 'react';
import { CorrelationChart } from '../correlation-chart';

const styles: Styles = {
  container: { marginTop: 3 },
  title: { marginBottom: 1, fontWeight: 600 },
};

export function ChartsContainer(): ReactElement {
  return (
    <Box sx={styles.container}>
      <CorrelationChart />
    </Box>
  );
}
