import { Action } from 'common/interfaces/root';
import { tagsActionTypes as T } from '../actions';

const initState: TagsModule.TagsListStore = {
  loading: true,
  error: null,
  data: null,
  page: 0,
  amount: 0,
};

export default (state: TagsModule.TagsListStore = initState, { type, payload }: Action): TagsModule.TagsListStore => {
  switch (type) {
    case T.GET_TAGS:
      return { ...state, loading: true };

    case T.GET_TAGS_SUCCESS:
      return { ...state, loading: false, data: payload.tags, amount: payload.count };

    case T.GET_TAGS_ERROR:
      return { ...state, loading: false, error: payload };

    case T.CHANGE_TAGS_PAGE:
      return { ...state, page: payload };

    default:
      return state;
  }
};
