import { useEffect, useRef, useState } from 'react';

const isElementInViewport = (el: HTMLElement) => {
  if (!el) return false;

  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const useInView = (el: HTMLElement) => {
  const [inView, setInView] = useState<boolean>(isElementInViewport(el));
  const inViewRef = useRef<boolean>(isElementInViewport(el));
  useEffect(() => {
    let intervalRef: ReturnType<typeof setInterval> = null;
    const handler = () => {
      const visible = isElementInViewport(el);

      if (visible !== inViewRef.current) {
        setInView(visible);
        inViewRef.current = visible;
      }
    };

    clearInterval(intervalRef);
    if (el) {
      intervalRef = setInterval(handler, 300);
      handler();
    }

    return () => {
      clearInterval(intervalRef);
    };
  }, [el]);

  return inView;
};
