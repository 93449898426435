import { Box, Button, TextField, Typography } from '@mui/material';
import { Modal } from 'common/ui/modal';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import { Styles } from 'common/types';
import { theme } from 'common/constants/theme';
import { useDispatch, useSelector } from 'react-redux';
import { contentOverviewActions } from 'modules/content-overview/store/actions';
import { selectGlobalFilters } from 'common/store/selectors';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import Checkbox from '@mui/material/Checkbox';

const styles: Styles = {
  fieldContainer: { width: '100%' },
  inputContainer: { display: 'flex', alignItems: 'center' },
  input: { flexGrow: 1 },
  label: { fontSize: 12, marginBottom: 1 },
  uploadIconContainer: {
    display: 'flex',
    width: 40,
    height: 40,
    backgroundColor: '#DDE8ED',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    marginLeft: 1.5,
    cursor: 'pointer',
  },
  uploadedLinksTitle: { marginTop: 4, fontSize: 12 },
  uploadedLinksContainer: { display: 'flex', flexDirection: 'column' },
  uploadedLink: {
    fontSize: 14,
    padding: theme.spacing(1, 0),
    borderBottom: '1px solid #C2DEEB',
    color: '#4288F0',
  },
  uploadBtn: { marginTop: 3.5 },
};

interface Props {
  open: boolean;
  onClose: () => void;
}

export function AddModal({ open, onClose }: Props): ReactElement {
  const dispatch = useDispatch();

  const [pagesValue, setPagesValue] = useState<string>('');
  const [sitemapsValue, setSitemapsValue] = useState<string>('');
  const [sitemapsCheck, setSitemapsCheck] = useState<boolean>(false);

  const globalFilters = useSelector(selectGlobalFilters);

  const handlePageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPagesValue(e.target.value);
  };

  const handleSitemapChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSitemapsValue(e.target.value);
  };

  const handleUpdateSitemapCheck = () => {
    setSitemapsCheck(!sitemapsCheck);
  };

  const handleLinkError = async (link: string) => {
    let valid = true;

    try {
      await Yup.string().url().validate(link);
    } catch (e) {
      toast.error('Invalid url(s) format!');
      valid = false;
    }

    return valid;
  };

  const handleUpload = async () => {
    const pages = pagesValue
      .split('\n')
      .map(p => p.trim())
      .filter(Boolean);
    const sitemaps = sitemapsValue
      .split('\n')
      .map(s => s.trim())
      .filter(Boolean);

    const urls = [...pages, ...sitemaps];
    let valid = true;

    // eslint-disable-next-line no-restricted-syntax
    for (const url of urls) {
      if (!valid) return;
      // eslint-disable-next-line no-await-in-loop
      valid = await handleLinkError(url);
    }

    if (!valid) return;

    if (pages?.length) {
      dispatch(contentOverviewActions.createPages(pages, globalFilters.publisher_id, Number(sitemapsCheck)));
    }
    if (sitemaps?.length) {
      dispatch(contentOverviewActions.createSitemaps(sitemaps, globalFilters.publisher_id, Number(sitemapsCheck)));
    }

    onClose();
  };

  useEffect(() => {
    if (open) return;

    setPagesValue('');
    setSitemapsValue('');
  }, [open]);

  return (
    <Modal title="Source" open={open} onClose={onClose} maxWidth="xs">
      <Box sx={styles.fieldContainer}>
        <Typography sx={styles.label}>Add Sitemap</Typography>
        <Box sx={styles.inputContainer}>
          <TextField
            size="small"
            sx={styles.input}
            onChange={handleSitemapChange}
            value={sitemapsValue}
            maxRows={4}
            minRows={4}
            multiline
          />
        </Box>
      </Box>
      <Box sx={styles.fieldContainer} marginTop={3}>
        <Typography sx={styles.label}>Add URLs</Typography>
        <Box sx={styles.inputContainer}>
          <TextField
            size="small"
            sx={styles.input}
            onChange={handlePageChange}
            value={pagesValue}
            maxRows={4}
            minRows={4}
            multiline
          />
        </Box>
      </Box>
      <Box sx={styles.fieldContainer} marginTop={3}>
        <Typography sx={styles.label}>Sitemaps List</Typography>
        <Checkbox checked={sitemapsCheck} onClick={handleUpdateSitemapCheck} />
      </Box>
      <Button variant="contained" color="primary" sx={styles.uploadBtn} onClick={handleUpload} fullWidth>
        <UploadIcon />
        Upload
      </Button>
    </Modal>
  );
}
