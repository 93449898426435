import { Action } from 'common/interfaces/root';
import { asinFinderActionTypes as T } from '../actions';

const initState: AsinFinder.AsinFinderStore = {
  loading: false,
  error: null,
  data: null,
  amount: 0,
  page: 0,
  limit: 10,
  variations: null,
  execution_id: null,
  loadingReport: false,
  isGenerating: false,
  brandParents: null,
  brands: null,
  loadingBrands: false,
};

export default (
  state: AsinFinder.AsinFinderStore = initState,
  { type, payload }: Action
): AsinFinder.AsinFinderStore => {
  switch (type) {
    case T.GET_ASINS_LIST:
      return { ...state, loading: true };

    case T.GET_ASINS_LIST_SUCCESS:
      return { ...state, loading: false, data: payload.data, amount: payload.total, variations: payload.variations };

    case T.GET_ASINS_LIST_ERROR:
      return { ...state, loading: false, error: payload };

    case T.CHANGE_LIMIT_ASINS_LIST:
      return { ...state, loading: false, limit: payload };

    case T.CHANGE_PAGE_ASINS_LIST:
      return { ...state, loading: false, page: payload };

    case T.GENERATE_REPORT:
      return { ...state, isGenerating: true, loadingReport: true };

    case T.GENERATE_REPORT_SUCCESS:
      return { ...state, isGenerating: false, execution_id: payload };

    case T.GENERATE_REPORT_ERROR:
      return { ...state, isGenerating: false, loadingReport: false };

    case T.SUCCESS_REPORT_LINK:
      return { ...state, loadingReport: false };

    case T.REMOVE_EXECUTION_ID:
      return { ...state, isGenerating: false, execution_id: null };

    case T.GET_BRAND_PARENTS:
      return { ...state, loadingBrands: true };

    case T.GET_BRAND_PARENTS_SUCCESS:
      return { ...state, loadingBrands: false, brandParents: payload };

    case T.GET_BRAND_PARENTS_ERROR:
      return { ...state, loadingBrands: false };

    case T.GET_BRANDS:
      return { ...state, loadingBrands: true };

    case T.GET_BRANDS_SUCCESS:
      return { ...state, loadingBrands: false, brands: payload };

    case T.GET_BRANDS_ERROR:
      return { ...state, loadingBrands: false };

    default:
      return state;
  }
};
