import { parentPublisherSettingsActionTypes as TYPES } from './action-types';

const getPublisherAccounts = () => ({
  type: TYPES.GET_PUBLISHER_ACCOUNTS,
});

const getPublisherAccountsSuccess = (data: ParentPublisherSettingsModule.PublisherAccountsData) => ({
  type: TYPES.GET_PUBLISHER_ACCOUNTS_SUCCESS,
  payload: data,
});

const getPublisherAccountsError = (error: Error) => ({
  type: TYPES.GET_PUBLISHER_ACCOUNTS_ERROR,
  payload: error,
});

const getPublisherAccountsParents = (payload?: ParentPublisherSettingsModule.GetPublisherAccountsParams) => ({
  type: TYPES.GET_PUBLISHER_ACCOUNTS_PARENTS,
  payload,
});

const getPublisherAccountsParentsSuccess = (data: ParentPublisherSettingsModule.PublisherAccountsData) => ({
  type: TYPES.GET_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS,
  payload: data,
});

const getPublisherAccountsParentsError = (error: Error) => ({
  type: TYPES.GET_PUBLISHER_ACCOUNTS_PARENTS_ERROR,
  payload: error,
});

const postPublisherAccountsParents = (payload: ParentPublisherSettingsModule.PublisherAccountForm) => ({
  type: TYPES.POST_PUBLISHER_ACCOUNTS_PARENTS,
  payload,
});

const postPublisherAccountsParentsSuccess = (payload: string) => ({
  type: TYPES.POST_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS,
  payload,
});

const postPublisherAccountsParentsError = (error: Error) => ({
  type: TYPES.POST_PUBLISHER_ACCOUNTS_PARENTS_ERROR,
  payload: error,
});

const patchPublisherAccountsParents = (payload: ParentPublisherSettingsModule.EditPublisherAccountParams) => ({
  type: TYPES.PATCH_PUBLISHER_ACCOUNTS_PARENTS,
  payload,
});

const patchPublisherAccountsParentsSuccess = (payload: string) => ({
  type: TYPES.PATCH_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS,
  payload,
});

const patchPublisherAccountsParentsError = (error: Error) => ({
  type: TYPES.PATCH_PUBLISHER_ACCOUNTS_PARENTS_ERROR,
  payload: error,
});

const deletePublisherAccountsParents = (payload: number) => ({
  type: TYPES.DELETE_PUBLISHER_ACCOUNTS_PARENTS,
  payload,
});

const deletePublisherAccountsParentsSuccess = (payload: string) => ({
  type: TYPES.DELETE_PUBLISHER_ACCOUNTS_PARENTS_SUCCESS,
  payload,
});

const deletePublisherAccountsParentsError = (error: Error) => ({
  type: TYPES.DELETE_PUBLISHER_ACCOUNTS_PARENTS_ERROR,
  payload: error,
});

export const parentPublisherSettingsActions = {
  getPublisherAccounts,
  getPublisherAccountsSuccess,
  getPublisherAccountsError,
  getPublisherAccountsParents,
  getPublisherAccountsParentsSuccess,
  getPublisherAccountsParentsError,
  postPublisherAccountsParents,
  postPublisherAccountsParentsSuccess,
  postPublisherAccountsParentsError,
  patchPublisherAccountsParents,
  patchPublisherAccountsParentsSuccess,
  patchPublisherAccountsParentsError,
  deletePublisherAccountsParents,
  deletePublisherAccountsParentsSuccess,
  deletePublisherAccountsParentsError,
};
