import { Grid, TablePagination, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { asinRecommendationSelectors } from 'modules/asin-recommendation/store/selectors';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { asinRecommendationActions } from 'modules/asin-recommendation/store/actions/actions';
import { Styles } from 'common/types';
import { RecommendItem } from '..';

const styles: Styles = {
  featTitle: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    color: '#060F14',
  },
  cardContainer: { width: '100%', mt: 2 },
};

export interface ProductListProps {
  asinParams?: AsinRecommendationModule.Params.GetAsinsParams;
  data?: AsinRecommendationModule.State.AsinsState;
}

export function ProductList({ asinParams, data }: ProductListProps): ReactElement {
  const dispatch = useDispatch();

  const [init, setInit] = useState<boolean>(false);

  const grid = useSelector(asinRecommendationSelectors.selectGrid);

  const getAsinsParams = useMemo<AsinRecommendationModule.Params.GetAsinsParams>(
    () => ({
      ...asinParams,
      limit: grid.limit,
      offset: grid.offset,
    }),
    [asinParams, grid]
  );

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(asinRecommendationActions.grid.setGridLimit(parseInt(event.target.value, 10)));
    dispatch(asinRecommendationActions.grid.setGridOffset(0));
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(asinRecommendationActions.grid.setGridOffset(newPage));
  };

  useEffect(() => {
    if (init)
      dispatch(asinRecommendationActions.asins.getAsins({ ...getAsinsParams, offset: grid.offset * grid.limit }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAsinsParams.limit, getAsinsParams.offset]);

  useEffect(() => {
    setInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <Typography sx={styles.featTitle}>All Products</Typography>
      <Grid sx={styles.cardContainer} container justifyContent="center" spacing={1} columns={10}>
        {data?.data?.recommended_asins?.map((asin, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <RecommendItem key={index} asin={asin} />
        ))}
      </Grid>
      <TablePagination
        count={Number(data?.data?.total) || 0}
        rowsPerPage={grid.limit}
        page={grid.offset}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        rowsPerPageOptions={[20, 40, 80, 120]}
      />
    </>
  );
}
