import { conversionsApiService } from 'modules/conversions/services/conversions-api-service';

export const downloadFile = (filename: string, text: string) => {
  const link = document.createElement('a');
  link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`);
  link.setAttribute('download', filename);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const generateFilename = (report: Conversions.Reports.ReportsData) => {
  return report.path.split('/').pop();
};

export const handleDownload = async (report: Conversions.Reports.ReportsData) => {
  const response = await conversionsApiService.getDownloadContent(report.path);
  const filename = generateFilename(report);
  downloadFile(filename, response.data);
};
