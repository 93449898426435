import { getDataListActions, GetRequestParams } from 'common/store/actions/data-list';
import { getFiltersActions } from 'common/store/actions/filters';
import { IProduct } from 'common/ui/product/types';
import {
  contentOverviewActionTypes as T,
  crawlingActionTypes,
  managementFiltersActionTypes,
  pagesPerformanceActionTypes,
  pagesPerformanceFiltersActionTypes,
  pagesActionTypes,
  productReplacementsActionTypes,
  replacementRecomendationsActionTypes,
  replacementsFiltersActionTypes,
  sitemapsActionsTypes,
} from './action-types';
import { UrlFilter } from '../../services/content-overview.service.types';

const createSitemaps = (sitemaps: Array<string>, publisherId: number, sitemap_list: number) => ({
  type: T.CREATE_SITEMAPS,
  payload: { sitemaps, publisherId, sitemap_list },
});

const createSitemapsSuccess = () => ({
  type: T.CREATE_SITEMAPS_SUCCESS,
});

const createSitemapsError = (error: Error) => ({
  type: T.CREATE_SITEMAPS_ERROR,
  payload: error,
});

const deleteSitemap = (payload: { publisherId: number; id: number }, onSuccess: () => void) => ({
  type: T.DELETE_SITEMAPS,
  payload,
  meta: { onSuccess },
});

const deleteSitemapSuccess = () => ({
  type: T.DELETE_SITEMAPS_SUCCESS,
});

const deleteSitemapError = (error: Error) => ({
  type: T.DELETE_SITEMAPS_ERROR,
  payload: error,
});

const createPages = (pages: Array<string>, publisherId: number, sitemap_list: number) => ({
  type: T.CREATE_PAGES,
  payload: { pages, publisherId, sitemap_list },
});

const createPagesSuccess = () => ({
  type: T.CREATE_PAGES_SUCCESS,
});

const createPagesError = (error: Error) => ({
  type: T.CREATE_PAGES_ERROR,
  payload: error,
});

const deleteUrl = (payload: { publisher_id: string; url_id: string }, onSuccess: () => void) => ({
  type: T.DELETE_URL,
  payload,
  meta: { onSuccess },
});

const deleteFilteredUrls = (
  payload: { publisher_id: string; search: string; urlsFilter: UrlFilter },
  onSuccess: () => void
) => ({
  type: T.DELETE_FILTERED_URLS,
  payload,
  meta: { onSuccess },
});

const deleteUrlSuccess = () => ({
  type: T.DELETE_URL_SUCCESS,
});

const deleteUrlError = (error: Error) => ({
  type: T.DELETE_URL_ERROR,
  payload: error,
});

const importPages = (file: File, publisherId: number) => ({
  type: T.IMPORT_PAGES,
  payload: { file, publisherId },
});

const importPagesSuccess = () => ({
  type: T.IMPORT_PAGES_SUCCESS,
});

const importPagesError = (error: Error) => ({
  type: T.IMPORT_PAGES_ERROR,
  payload: error,
});

const generateReport = (payload: string) => ({
  type: T.GENERATE_REPORT,
  payload,
});

const generateReportSuccess = (payload: string) => ({
  type: T.GENERATE_REPORT_SUCCESS,
  payload,
});

const generateReportError = (error: Error) => ({
  type: T.GENERATE_REPORT_ERROR,
  payload: error,
});

const openReportLink = (payload: { id: string; code: string }, onStopTimer: () => void) => ({
  type: T.OPEN_REPORT_LINK,
  payload,
  meta: { onStopTimer },
});

const successReportLink = () => ({
  type: T.SUCCESS_REPORT_LINK,
});

const removeExecutionId = () => ({
  type: T.REMOVE_EXECUTION_ID,
});

const getProductsStats = (payload: { id: string; inStock: number; search: string }) => ({
  type: T.GET_PRODUCTS_STATS,
  payload,
});

const getProductsStatsSuccess = (payload: ContentOverviewModule.StatsData) => ({
  type: T.GET_PRODUCTS_STATS_SUCCESS,
  payload,
});

const getProductsStatsError = (error: Error) => ({
  type: T.GET_PRODUCTS_STATS_ERROR,
  payload: error,
});

const getCrawlingStatus = (params: GetRequestParams) => ({
  type: T.GET_CRAWLING_STATUS,
  payload: params,
});

const getCrawlingStatusSuccess = (data: ContentOverviewModule.CrawlingStatus) => ({
  type: T.GET_CRAWLING_STATUS_SUCCESS,
  payload: data,
});

const getCrawlingStatusError = (error: Error) => ({
  type: T.GET_CRAWLING_STATUS_ERROR,
  payload: error,
});

const postCrawling = (publisherId: number, domain: string) => ({
  type: T.POST_CRAWLING,
  payload: { publisherId, domain },
});

const postCrawlingSuccess = () => ({
  type: T.POST_CRAWLING_SUCCESS,
});

const postCrawlingError = (error: Error) => ({
  type: T.POST_CRAWLING_ERROR,
  payload: error,
});

const syncPage = (page: string, publisherId: number, withAsins: number, asins: Array<string>, updated_at: string) => ({
  type: T.SYNC_PAGE,
  payload: { page, publisherId, withAsins, asins, updated_at },
});

const syncPageSuccess = () => ({
  type: T.SYNC_PAGE_SUCCESS,
});

const syncPageError = (error: Error) => ({
  type: T.SYNC_PAGE_ERROR,
  payload: error,
});

const getProductRecommendations = (payload: { publisherId: number; asins: string; search?: string }) => ({
  type: T.GET_PRODUCT_RECOMMENDATIONS,
  payload,
});

const getProductRecommendationsSuccess = (data: Array<IProduct>, asins: string) => ({
  type: T.GET_PRODUCT_RECOMMENDATIONS_SUCCESS,
  payload: { data, asins },
});

const getProductRecommendationsError = (error: Error) => ({
  type: T.GET_PRODUCT_RECOMMENDATIONS_ERROR,
  payload: error,
});

const postProductRecommendations = (
  payload: { id: number; product_asin: string; replacement_asin: string; score: number },
  onSuccess: () => void
) => ({
  type: T.POST_PRODUCT_RECOMMENDATIONS,
  payload,
  meta: { onSuccess },
});

const postProductRecommendationsSuccess = () => ({
  type: T.POST_PRODUCT_RECOMMENDATIONS_SUCCESS,
});

const postProductRecommendationsError = (error: Error) => ({
  type: T.POST_PRODUCT_RECOMMENDATIONS_ERROR,
  payload: error,
});

const patchProductRecommendations = (
  payload: { id: number; product_asin: string; replacement_asin: string; score: number },
  onSuccess: () => void
) => ({
  type: T.PATCH_PRODUCT_RECOMMENDATIONS,
  payload,
  meta: { onSuccess },
});

const patchProductRecommendationsSuccess = () => ({
  type: T.PATCH_PRODUCT_RECOMMENDATIONS_SUCCESS,
});

const patchProductRecommendationsError = (error: Error) => ({
  type: T.PATCH_PRODUCT_RECOMMENDATIONS_ERROR,
  payload: error,
});

const postRescrapTab = (publisherId: number, tab: string, onSuccess: () => void) => ({
  type: T.POST_RESCRAP_TAB,
  payload: { publisherId, tab },
  meta: { onSuccess },
});

const postUrlsRescrap = (publisherId: number, tab: string, urlsIds: string, onSuccess: () => void) => ({
  type: T.POST_URLS_RESCRAP,
  payload: { publisherId, tab, urlsIds },
  meta: { onSuccess },
});

const patchUpdateSchedule = (
  publisherId: number,
  tab: string,
  schedule: string,
  onSuccess: (schedule: string) => void
) => ({
  type: T.PATCH_UPDATE_SCHEDULE,
  payload: { publisherId, tab, schedule },
  meta: { onSuccess },
});

const getScheduleByTab = (data: { tab: string; publisher_id: number }, onSuccess: (schedule: string) => void) => {
  return {
    type: T.GET_TAB_SCHEDULE,
    payload: {
      data,
    },
    meta: { onSuccess },
  };
};

const setCrawlerSchedule = (schedule: string) => ({
  type: T.SET_CRAWLER_SCHEDULE,
  payload: schedule,
});

const setSitemapScrapingSchedule = (schedule: string) => ({
  type: T.SET_SITEMAP_SCRAPPING_SCHEDULE,
  payload: schedule,
});

const setUrlsScrapingSchedule = (schedule: string) => ({
  type: T.SET_URLS_SCRAPPING_SCHEDULE,
  payload: schedule,
});

const setPagePerformanceActiveArticle = (article: ContentOverviewModule.PagePerformance) => ({
  type: T.SET_PAGE_PERFORMANCE_ACTIVE_ARTICLE,
  payload: article,
});
const setPagePerformanceActiveArticleModalVisible = (visible: boolean) => ({
  type: T.SET_PAGE_PERFORMANCE_ACTIVE_ARTICLE_MODAL_VISIBLE,
  payload: visible,
});

const requestToSwapOOS = (data: unknown, onSuccess, onError) => ({
  type: T.REQUEST_TO_SWAP_OOS,
  payload: {
    data,
  },
  meta: { onSuccess, onError },
});

const updatePerformancePages = ({ articles, articles_count }: { articles_count: number; articles: unknown[] }) => ({
  type: T.UPDATE_PAGE_PERFORMANCE_PAGES,
  payload: { articles, articles_count },
});

const changeDatesToCompare = (datesToCompare: string) => ({
  type: T.CHANGE_DATES_TO_COMPARE,
  payload: datesToCompare,
});

export const pagesActions = getDataListActions<ContentOverviewModule.PageEntry>(pagesActionTypes);
export const sitemapsActions = getDataListActions<ContentOverviewModule.Sitemap>(sitemapsActionsTypes);
export const replacementRecomendationsActions = getDataListActions<ContentOverviewModule.Sitemap>(
  replacementRecomendationsActionTypes
);
export const crawlingActions = getDataListActions<ContentOverviewModule.CrawledUrl>(crawlingActionTypes);
export const productReplacementsActions =
  getDataListActions<ContentOverviewModule.PageWithProducts>(productReplacementsActionTypes);
export const pagesPerformanceActions =
  getDataListActions<ContentOverviewModule.PagePerformance>(pagesPerformanceActionTypes);
export const pagesPerformanceFiltersActions = getFiltersActions(pagesPerformanceFiltersActionTypes);
export const productReplacementsFiltersActions = getFiltersActions(replacementsFiltersActionTypes);
export const managementFiltersActions = getFiltersActions(managementFiltersActionTypes);

export const contentOverviewActions = {
  createSitemaps,
  createSitemapsSuccess,
  createSitemapsError,
  deleteSitemap,
  deleteSitemapSuccess,
  deleteSitemapError,
  createPages,
  createPagesSuccess,
  createPagesError,
  deleteUrl,
  deleteFilteredUrls,
  deleteUrlSuccess,
  deleteUrlError,
  importPages,
  importPagesSuccess,
  importPagesError,
  generateReport,
  generateReportSuccess,
  generateReportError,
  openReportLink,
  removeExecutionId,
  successReportLink,
  getProductsStats,
  getProductsStatsSuccess,
  getProductsStatsError,
  getCrawlingStatus,
  getCrawlingStatusSuccess,
  getCrawlingStatusError,
  postCrawling,
  postCrawlingSuccess,
  postCrawlingError,
  syncPage,
  syncPageSuccess,
  syncPageError,
  getProductRecommendations,
  getProductRecommendationsSuccess,
  getProductRecommendationsError,
  postProductRecommendations,
  postProductRecommendationsSuccess,
  postProductRecommendationsError,
  patchProductRecommendations,
  patchProductRecommendationsSuccess,
  patchProductRecommendationsError,
  postRescrapTab,
  postUrlsRescrap,
  patchUpdateSchedule,
  setCrawlerSchedule,
  setSitemapScrapingSchedule,
  getScheduleByTab,
  setUrlsScrapingSchedule,
  setPagePerformanceActiveArticle,
  setPagePerformanceActiveArticleModalVisible,
  requestToSwapOOS,
  updatePerformancePages,
  changeDatesToCompare,
};
