import { getFiltersActions } from 'common/store/actions/filters';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { tagsActionTypes as T, urlsFiltersActionTypes } from './action-types';

const getUrls = (filters: IStatisticsQueryString) => ({
  type: T.GET_URLS,
  payload: filters,
});

const getUrlsSuccess = (urls: Array<UrlsModule.Url>, count: number) => ({
  type: T.GET_URLS_SUCCESS,
  payload: { urls, count },
});

const getUrlsError = (error: Error) => ({
  type: T.GET_URLS_ERROR,
  payload: error,
});

const changeUrlsPage = (page: number) => ({
  type: T.CHANGE_URLS_PAGE,
  payload: page,
});

export const urlsActions = {
  getUrls,
  getUrlsSuccess,
  getUrlsError,
  changeUrlsPage,
};

export const urlsFiltersActions = getFiltersActions(urlsFiltersActionTypes);
