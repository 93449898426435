import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import toast from 'react-hot-toast';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { affiliateRateCardService } from '../../service/affiliate-rate-card-service';
import { affiliateRateCardsActions } from '../actions/actions';
import { affiliateRateCardActionTypes as TYPES } from '../actions/types';

function* getRecommendedAsinsSaga({ payload }: Action) {
  try {
    const { data } = yield call(affiliateRateCardService.getRecommendedAsins, payload);

    yield put(affiliateRateCardsActions.asins.getRecommendedAsinsSuccess(data));
  } catch (error) {
    yield put(affiliateRateCardsActions.asins.getRecommendedAsinsError(error as Error));
  }
}

function* getABDefaultRateSaga({ payload }: Action) {
  try {
    const { data } = yield call(affiliateRateCardService.getABDefaultRate, payload);

    yield put(affiliateRateCardsActions.rates.defualt.getABDefaultRateSuccess(data.attr_gainz_commision));
  } catch (error) {
    yield put(affiliateRateCardsActions.rates.defualt.getABDefaultRateError(error as Error));
  }
}

function* getSRDefaultRateSaga({ payload }: Action) {
  try {
    const { data } = yield call(affiliateRateCardService.getSRDefaultRate, payload);

    yield put(affiliateRateCardsActions.rates.defualt.getSRDefaultRateSuccess(data.sr_gainz_commision));
  } catch (error) {
    yield put(affiliateRateCardsActions.rates.defualt.getSRDefaultRateError(error as Error));
  }
}

function* patchSRDefaultRateSaga({ payload }: Action) {
  try {
    const { data } = yield call(affiliateRateCardService.patchSRDefaultRate, payload);

    yield toast.success('Updated');
    yield put(affiliateRateCardsActions.rates.defualt.patchSRDefaultRateSuccess(data));
  } catch (error) {
    yield toast.error('Update failed');
    yield put(affiliateRateCardsActions.rates.defualt.patchSRDefaultRateError(error as Error));
  }
}

function* patchABDefaultRateSaga({ payload }: Action) {
  try {
    const { data } = yield call(affiliateRateCardService.patchABDefaultRate, payload);

    yield toast.success('Updated');
    yield put(affiliateRateCardsActions.rates.defualt.patchABDefaultRateSuccess(data));
  } catch (error) {
    yield toast.error('Update failed');
    yield put(affiliateRateCardsActions.rates.defualt.patchABDefaultRateError(error as Error));
  }
}

function* patchCustomRateSaga({ payload }: Action) {
  try {
    const { data } = yield call(affiliateRateCardService.patchCustomRate, payload);

    yield toast.success('Saved');
    yield put(affiliateRateCardsActions.rates.custom.patchCustomRateSuccess(data.message));
  } catch (error) {
    yield toast.error('Save failed');
    yield put(affiliateRateCardsActions.rates.custom.patchCustomRateError(error as Error));
  }
}

export function* affiliateRateCardSaga(): SagaIterator {
  yield all([
    takeLatest(TYPES.ASINS.GET_RECOMMENDED_ASINS, getRecommendedAsinsSaga),
    takeLatest(TYPES.RATES.DEFAULT.GET_AB_DEFAULT, getABDefaultRateSaga),
    takeLatest(TYPES.RATES.DEFAULT.GET_SR_DEFAULT, getSRDefaultRateSaga),
    takeLatest(TYPES.RATES.DEFAULT.PATCH_SR_DEFAULT, patchSRDefaultRateSaga),
    takeLatest(TYPES.RATES.DEFAULT.PATCH_AB_DEFAULT, patchABDefaultRateSaga),
    takeLatest(TYPES.RATES.CUSTOM.PATCH_CUSTOM_RATE, patchCustomRateSaga),
  ]);
}
