import { Box, Popover } from '@mui/material';
import { Styles } from 'common/types';
import { ChangeEvent, ReactElement, useRef } from 'react';
import { theme } from 'common/constants/theme';
import { useDispatch, useSelector } from 'react-redux';
import { contentOverviewActions } from 'modules/content-overview/store/actions';
import { selectGlobalFilters } from 'common/store/selectors';

interface StylesProps {
  width: number;
}

const getStyles = ({ width }: StylesProps): Styles => ({
  row: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid #e5e5e5',
    height: 48,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0 16px',
    ':hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  popover: { width },
  hiddenInput: { display: 'none' },
});

interface Props {
  anchorElement: HTMLElement | null;
  onClose: () => void;
  width: number;
  handleAddSourceModalOpen: () => void;
}

export function UploadPopover({ anchorElement, onClose, width, handleAddSourceModalOpen }: Props): ReactElement {
  const dispatch = useDispatch();

  const styles = getStyles({ width });

  const inputRef = useRef<HTMLInputElement>();

  const globalFilters = useSelector(selectGlobalFilters);

  const handleTriggerImportCSVURLs = () => {
    inputRef.current.click();
    onClose();
  };

  const handleImportCSVURLs = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    dispatch(contentOverviewActions.importPages(file, globalFilters.publisher_id));
  };

  return (
    <>
      <Popover
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        PaperProps={{ sx: styles.popover }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClose}
      >
        <Box sx={styles.row} onClick={handleAddSourceModalOpen}>
          Add source
        </Box>
        <Box sx={styles.row} onClick={handleTriggerImportCSVURLs}>
          Import CSV (URLs)
        </Box>
      </Popover>
      <Box sx={styles.hiddenInput}>
        <input type="file" ref={inputRef} onChange={handleImportCSVURLs} />
      </Box>
    </>
  );
}
