import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Styles } from 'common/types';
import { getStyles as getSettingStyles } from 'modules/user-settings/components/user-settings-content/user-settings-content';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import {
  selectPublisherRateCards,
  selectPublisherRateCardsLoading,
  selectPublisherRateCardsSelected,
} from 'modules/settings/store/selectors';
import { selectGlobalFilters } from 'common/store/selectors';

const settingsStyles = getSettingStyles();

const styles: Styles = {
  rateCardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    padding: '20px 0',
    marginTop: 5,
    boxSizing: 'border-box',
    borderBottom: '1px solid #C2DEEB40',
  },
  loadingContainer: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px', width: '100%' },
  // * unused
  // inputWrapper: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   boxSizing: 'border-box',
  //   flexDirection: 'column',
  //   alignItems: 'flex-start',
  //   minWidth: '580px',
  //   marginRight: '0',
  // },
  container: { display: 'flex', alignItems: 'flex-start', width: '580px' },
  tableContainer: { maxHeight: '500px' },
  categoryCol: { width: '50%' },
  earningCol: { width: '30%' },
  editCol: { width: '20%' },
};

export function RateCardSettings(): ReactElement {
  const [isValid, setIsValid] = useState(true);

  const selected = useSelector(selectPublisherRateCardsSelected);
  const rows = useSelector(selectPublisherRateCards);
  const loading = useSelector(selectPublisherRateCardsLoading);
  const filters = useSelector(selectGlobalFilters);

  const dispatch = useDispatch();

  const params: RateCardsModule.Params = { publisher_id: filters.publisher_id };

  const sortRows = (rows: RateCardsModule.RateCard[]) => {
    return rows.sort((a, b) => {
      if (a.category < b.category) return -1;
      if (a.category > b.category) return 1;
      return 0;
    });
  };

  const reset = () => {
    dispatch(publishersSettingsActions.setRateCardsSelected({}));
    setIsValid(true);
  };

  const handleGet = () => {
    dispatch(publishersSettingsActions.getRateCards(params));
    reset();
  };

  const handleSelect = (row: RateCardsModule.RateCard) => {
    if (!selected[row.id])
      dispatch(
        publishersSettingsActions.setRateCardsSelected({
          [row.id]: {
            id: row.id,
            category: row.category,
            commission_rate: row.commission_rate,
          },
        })
      );
  };

  const handleEdit = (row: RateCardsModule.RateCard, change: RateCardsModule.RateCard) => {
    dispatch(
      publishersSettingsActions.setRateCardsSelected({
        [row.id]: change,
      })
    );
  };

  const handleSave = (row: RateCardsModule.RateCard) => {
    if (row.commission_rate === selected[row.id].commission_rate && row.category === selected[row.id].category) {
      setIsValid(false);
    } else {
      dispatch(publishersSettingsActions.patchRateCards({ ...params, rate_cards: [selected[row.id]] }, handleGet));
      reset();
    }
  };

  const handleCancel = () => {
    reset();
  };

  useEffect(() => {
    dispatch(publishersSettingsActions.getRateCards(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createEarningInputProps = (row: RateCardsModule.RateCard) => {
    return {
      inputProps: {
        max: 100,
        min: 0,
        step: 0.01,
        defaultValue: row.commission_rate,
        placeholder: '%',
      },
    };
  };

  const createCategoryInputProps = (row: RateCardsModule.RateCard) => {
    return {
      inputProps: {
        defaultValue: row.category,
      },
    };
  };

  return (
    <Box sx={styles.rateCardContainer}>
      <Typography sx={settingsStyles.titleField}>Rate Card</Typography>
      <Box sx={styles.container}>
        <TableContainer sx={styles.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={styles.categoryCol}>
                  <Typography sx={settingsStyles.titleField}>Name of category</Typography>
                </TableCell>
                <TableCell sx={styles.earningCol}>
                  <Typography sx={settingsStyles.titleField}>Earning %</Typography>
                </TableCell>
                <TableCell sx={styles.editCol} />
              </TableRow>
            </TableHead>
            {(loading && (
              <Box sx={styles.loadingContainer}>
                <CircularProgress />
              </Box>
            )) || (
              <TableBody>
                {sortRows(rows).map((row: RateCardsModule.RateCard) => (
                  <TableRow key={rows.indexOf(row)}>
                    {(selected[row.id] && (
                      <>
                        <TableCell sx={styles.categoryCol}>
                          <TextField
                            error={!isValid}
                            helperText={isValid ? '' : 'Please set new value'}
                            variant="standard"
                            type="text"
                            InputProps={createCategoryInputProps(row)}
                            onChange={e =>
                              handleEdit(row, {
                                id: row.id,
                                category: e.target.value,
                                commission_rate: selected[row.id].commission_rate,
                              })
                            }
                          />
                        </TableCell>
                        <TableCell sx={styles.earningCol}>
                          <TextField
                            error={!isValid}
                            helperText={isValid ? '' : 'Please set new value'}
                            variant="standard"
                            type="number"
                            InputProps={createEarningInputProps(row)}
                            onChange={e =>
                              handleEdit(row, {
                                id: row.id,
                                category: selected[row.id].category,
                                commission_rate: e.target.value,
                              })
                            }
                          />
                        </TableCell>
                        <TableCell sx={styles.editCol}>
                          <IconButton id={row.id.toString()} onClick={() => handleSave(row)}>
                            <CheckIcon fontSize="small" />
                          </IconButton>
                          <IconButton id={row.id.toString()} onClick={() => handleCancel()}>
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </>
                    )) || (
                      <>
                        <TableCell sx={styles.categoryCol}>{row.category}</TableCell>
                        <TableCell sx={styles.earningCol}>{row.commission_rate}</TableCell>
                        <TableCell sx={styles.editCol}>
                          <IconButton id={row.id.toString()} onClick={() => handleSelect(row)}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
