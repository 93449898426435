import { Action } from 'common/interfaces/root';
import { contentOverviewActionTypes as T } from '../actions';

const initState: ContentOverviewModule.StatsStore = {
  data: null,
  loading: false,
  error: null,
};

export default (
  state: ContentOverviewModule.StatsStore = initState,
  { type, payload }: Action
): ContentOverviewModule.StatsStore => {
  switch (type) {
    case T.GET_PRODUCTS_STATS:
      return { ...state, loading: true };

    case T.GET_PRODUCTS_STATS_SUCCESS:
      return { ...state, loading: false, data: payload };

    case T.GET_PRODUCTS_STATS_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
