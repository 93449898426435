import { actionTypes } from './action-types';

const getConversions = (params: Conversions.Conv.ConversionsParams) => ({
  type: actionTypes.conversions.GET_CONVERSIONS,
  payload: params,
});

const getConversionsSuccess = (data: Conversions.Conv.ConversionData[]) => ({
  type: actionTypes.conversions.GET_CONVERSIONS_SUCCESS,
  payload: data,
});

const getConversionsError = (error: Error) => ({
  type: actionTypes.conversions.GET_CONVERSIONS_ERROR,
  payload: error,
});

export const conversionsActions = {
  getConversions,
  getConversionsSuccess,
  getConversionsError,
};
