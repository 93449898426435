/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { Field } from 'common/ui/field';
import { SelectField } from 'common/ui/select-field';
import { useFormikContext } from 'formik';
import { ReactElement, useEffect } from 'react';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 1.5 },
  fieldContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  field: { width: '70%' },
  button: { maxWidth: 100, width: '100%', marginLeft: 1, ':first-child': { marginLeft: 0 } },
  buttons: { marginTop: 2, display: 'flex', justifyContent: 'flex-end' },
  checkboxField: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    marginLeft: 0,
  },
  checkbox: { marginLeft: 0 },
};

const trafficTypes = [
  { title: 'Organic', value: 'organic' },
  { title: 'Paid', value: 'paid' },
];

interface Props {
  handleModalClose: () => void;
  isModalOpen: boolean;
}

export function TagsModalContent({ handleModalClose, isModalOpen }: Props): ReactElement {
  const { submitForm, resetForm, values, setFieldValue } = useFormikContext<PublishersSettingsModule.TagForm>();

  const handleChangeUsedCheckbox = (value: boolean) => {
    setFieldValue('used', !value);
  };

  useEffect(() => {
    if (isModalOpen) return;
    resetForm();
  }, [isModalOpen, resetForm]);

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.fieldContainer}>
          <Typography>Tag</Typography>
          <Field
            component={TextField}
            name="tag"
            fullWidth
            variant="outlined"
            label="Tag"
            size="small"
            InputLabelProps={{ shrink: true }}
            sx={styles.field}
          />
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Traffic Type</Typography>
          <SelectField
            name="trafficType"
            fullWidth
            label="Traffic Type"
            size="small"
            InputLabelProps={{ shrink: true }}
            options={trafficTypes}
            sx={styles.field}
          />
        </Box>
        <Box sx={styles.checkboxField}>
          <FormControlLabel
            value="Used"
            sx={styles.checkbox}
            checked={values.used}
            onChange={() => handleChangeUsedCheckbox(values.used)}
            control={<Checkbox />}
            label="Used"
            labelPlacement="start"
          />
        </Box>
      </Box>
      <Box sx={styles.buttons}>
        <Button variant="outlined" sx={styles.button} onClick={handleModalClose}>
          Cancel
        </Button>
        <Button onClick={submitForm} sx={styles.button} variant="contained">
          Save
        </Button>
      </Box>
    </>
  );
}
