import { State } from 'common/store/reducers';

export const selectPagesLimitPageAmount = (state: State) => ({
  limit: state.contentOverview.pages.limit,
  page: state.contentOverview.pages.page,
  amount: state.contentOverview.pages.amount,
});

export const selectPagesLoading = (state: State) => state.contentOverview.pages.loading;

export const selectPages = (state: State) => state.contentOverview.pages.data;

export const selectPagesPerformanceLimitPageAmount = (state: State) => ({
  limit: state.contentOverview.pagesPerformance.limit,
  page: state.contentOverview.pagesPerformance.page,
  amount: state.contentOverview.pagesPerformance.amount,
});

export const selectPagesPerformanceLoading = (state: State) => state.contentOverview.pagesPerformance.loading;

export const selectPagesPerformance = (state: State) => state.contentOverview.pagesPerformance.data;

export const selectPagesPerformanceFilters = (state: State) => state.contentOverview.pagesPerformanceFilters;

export const selectSitemapsLimitPageAmount = (state: State) => ({
  limit: state.contentOverview.sitemaps.limit,
  page: state.contentOverview.sitemaps.page,
  amount: state.contentOverview.sitemaps.amount,
});

export const selectSitemapsLoading = (state: State) => state.contentOverview.sitemaps.loading;

export const selectSitemaps = (state: State) => state.contentOverview.sitemaps.data;

export const selectReplacementsLimitPageAmount = (state: State) => ({
  limit: state.contentOverview.replacements.limit,
  page: state.contentOverview.replacements.page,
  amount: state.contentOverview.replacements.amount,
});

export const selectReplacementsLoading = (state: State) => state.contentOverview.replacements.loading;

export const selectReplacements = (state: State) => state.contentOverview.replacements.data;

export const selectCrawlingLoading = (state: State) => state.contentOverview.crawling.loading;

export const selectCrawlingCanLoadMore = (state: State) => state.contentOverview.crawling.canLoadMore;

export const selectCrawling = (state: State) => state.contentOverview.crawling.data;

export const selectCrawlingLimitPageAmount = (state: State) => ({
  limit: state.contentOverview.crawling.limit,
  page: state.contentOverview.crawling.page,
  amount: state.contentOverview.crawling.amount,
});

export const selectReplacementsFilters = (state: State) => state.contentOverview.replacementsFilters;

export const selectReplacementRecomendations = (state: State) => state.contentOverview.replacementRecomendations.data;

export const selectReplacementRecomendationsLoading = (state: State) =>
  state.contentOverview.replacementRecomendations.loading;

export const selectManagementFilters = (state: State) => state.contentOverview.managementFilters;

export const selectExecutionId = (state: State) => state.contentOverview.report.execution_id;

export const selectGenerateLoading = (state: State) => state.contentOverview.report.loading;

export const selectIsGenerating = (state: State) => state.contentOverview.report.isGenerating;

export const selectStatsData = (state: State) => state.contentOverview.stats.data;

export const selectStatsLoading = (state: State) => state.contentOverview.stats.loading;

export const selectCrawlingStatus = (state: State) => state.contentOverview.crawlingStatus;

export const selectRecommendationsData = (state: State) => state.contentOverview.recommendations.data;

export const selectRecommendationAsins = (state: State) => state.contentOverview.recommendations.asins;

export const selectRecommendationsLoading = (state: State) => state.contentOverview.recommendations.loading;

export const selectCrawlerSchedule = (state: State) => state.contentOverview.schedule.crawlerSchedule;
export const selectUrlsScrapingSchedule = (state: State) => state.contentOverview.schedule.urlScrapingSchedule;
export const selectSitemapScrapingSchedule = (state: State) => state.contentOverview.schedule.sitemapScrapingSchedule;

export const selectPerformancePageArticle = (state: State) => state.contentOverview.pagePerformanceArticle.article;
export const selectPerformancePageArticleModalVisible = (state: State) =>
  state.contentOverview.pagePerformanceArticle.isModalVisible;
export const selectPerformanceComparisons = (state: State) => state.contentOverview.performanceComparisons;
