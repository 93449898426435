import { Button } from '@mui/material';
import { MouseEvent, ReactElement, useState } from 'react';
import moment, { Moment } from 'moment';
import { RangeKeyDict } from 'react-date-range';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { SystemStyleObject } from '@mui/system';
import { DatePickerCalendar } from './date-picker-calendar';

const styles: Styles = {
  calendarIcon: { marginLeft: 1 },
  button: {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    ':hover': { color: theme.palette.primary.light, borderColor: theme.palette.primary.light },
  },
};

interface Props {
  from: Moment;
  to: Moment;
  onChange: (from: Moment, to: Moment) => void;
  showCompare?: boolean;
  classes?: SystemStyleObject;
}

export function DatePicker({ from, to, onChange, showCompare, classes }: Props): ReactElement {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const handleCalendarOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(e.currentTarget);
  };

  const handleCalendarClose = () => {
    setAnchorElement(null);
  };

  const handleDateChange = (data: RangeKeyDict) => {
    const date = data[Object.keys(data)[0]];

    if (!date) return;

    onChange(moment(date.startDate), moment(date.endDate));
  };

  const getRangePreview = () => {
    const formatedStartDate = from.format('D MMM');
    const formatedEndDate = to.format('D MMM');

    return from.startOf('day').diff(to.startOf('day'))
      ? `${formatedStartDate} - ${formatedEndDate}`
      : formatedStartDate;
  };

  return (
    <>
      <Button onClick={handleCalendarOpen} variant="outlined" sx={{ ...styles.button, ...classes }}>
        {getRangePreview()}
        <CalendarMonthIcon sx={styles.calendarIcon} fontSize="small" />
      </Button>
      <DatePickerCalendar
        onClose={handleCalendarClose}
        anchorElement={anchorElement}
        onChange={handleDateChange}
        date={[{ startDate: from.toDate(), endDate: to.toDate() }]}
        showCompare={showCompare}
      />
    </>
  );
}
