import { Action } from 'common/interfaces/root';
import { asinRecommendationActionTypes as TYPES } from '../actions/action-types';

const initialState: AsinRecommendationModule.State.AsinsState = {
  loading: false,
  data: null,
  error: null,
};

export default (
  state: AsinRecommendationModule.State.AsinsState = initialState,
  { type, payload }: Action
): AsinRecommendationModule.State.AsinsState => {
  switch (type) {
    case TYPES.ASINS.GET_ASINS:
      return { ...state, loading: true };
    case TYPES.ASINS.GET_ASINS_SUCCESS:
      return { ...state, loading: false, data: payload };
    case TYPES.ASINS.GET_ASINS_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
