import { ReactElement, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import { asinsConversionsBoostSelectors } from 'modules/asins-conversions-boost/store/selectors';
import { theme } from 'common/constants/theme';
import { userService } from 'common/services/user.service';
import { UploadDetails } from './upload-details';

const styles: Styles = {
  infoContainer: {
    borderBottom: '1px solid #a6a6a6',
    paddingBottom: '20px',
    marginBottom: '20px',
  },
  uploadStatsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '20px',
    marginBottom: '20px',
    borderBottom: '1px solid #a6a6a6',
  },
  text: {
    fontSize: '18px',
    cursor: 'pointer',
    textAlign: 'center',
    padding: '20px 30px',
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
  },
  value: {
    fontSize: '35px',
    fontWeight: 600,
  },
  detailsContainer: {
    minHeight: '321px',
  },
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function UploadDetailsModal({ isOpen, onClose }: Props): ReactElement {
  const { result, file, error } = useSelector(asinsConversionsBoostSelectors.selectUpload);

  const uploadDate = new Date();

  const formattedDate = uploadDate.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  });

  const defaultDetails = { message: `Total ${result.inserted || 0} changes made by uploading file.` };
  const emptyDuplicates = { message: 'This file was uploaded without duplicates.' };
  const emptyErrors = { message: 'This file was uploaded without errors.' };
  const fatalError = { message: `Error occured while file upload\n${error}` };

  const [selectedTab, setSelectedTab] = useState<string>('changes');

  const handleItemClick = (tab: string) => setSelectedTab(tab);

  return (
    <Modal open={isOpen} onClose={onClose} title="Upload Details" maxWidth="sm">
      <Box sx={styles.infoContainer}>
        {userService.user.name && <Typography>User: {userService.user.name}</Typography>}
        <Typography>Date & time: {formattedDate}</Typography>
        {file && file.name && <Typography>Filename: {file.name}</Typography>}
      </Box>

      {!error ? (
        <>
          <Box sx={styles.uploadStatsContainer}>
            <Box
              sx={selectedTab === 'changes' ? { ...styles.text, ...styles.selected } : styles.text}
              onClick={() => handleItemClick('changes')}
            >
              <Typography>Changes</Typography>
              <Typography sx={styles.value}>{result.inserted}</Typography>
            </Box>
            <Box
              sx={selectedTab === 'duplicates' ? { ...styles.text, ...styles.selected } : styles.text}
              onClick={() => handleItemClick('duplicates')}
            >
              <Typography>Duplicates</Typography>
              <Typography sx={styles.value}>{result.duplicates?.length}</Typography>
            </Box>
            <Box
              sx={selectedTab === 'errors' ? { ...styles.text, ...styles.selected } : styles.text}
              onClick={() => handleItemClick('errors')}
            >
              <Typography>Errors</Typography>
              <Typography sx={styles.value}>{result.withErrors?.length}</Typography>
            </Box>
          </Box>
          <Box sx={styles.detailsContainer}>
            {selectedTab === 'changes' && <UploadDetails items={defaultDetails} />}
            {selectedTab === 'duplicates' &&
              (result.duplicates?.length > 0 ? (
                <UploadDetails items={result.duplicates} />
              ) : (
                <UploadDetails items={emptyDuplicates} />
              ))}
            {selectedTab === 'errors' &&
              (result.withErrors?.length > 0 ? (
                <UploadDetails items={result.withErrors} />
              ) : (
                <UploadDetails items={emptyErrors} />
              ))}
          </Box>
        </>
      ) : (
        <Box sx={styles.detailsContainer}>
          <UploadDetails items={fatalError} />
        </Box>
      )}
    </Modal>
  );
}
