import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import SettingsIcon from '@mui/icons-material/Settings';
import { Styles } from 'common/types';
import { SearchInput } from 'common/ui/search-input';
import { URL_MANAGEMENT_NAV } from 'modules/settings/constants';
import HeaderImg from 'common/svg/header-img.png';
import { Portal } from 'common/ui/portal';
import { PagesTable } from './pages-table';
import { SitemapsTable } from './sitemaps-table';
import { UploadPopover } from './uploads-popover';
import { AddModal } from './add-modal';
import { CrawlingTable } from './crawling-table';
import { WithAsinsSelect } from './with-asins-select';
import { UrlManagementPopover } from './urls-management-popover';
import { AddedFoundedSelect } from './added-founded-select';
import { UrlsFiltersSelect } from './urls-filters-select';
import { Modal } from '../../../../../common/ui/modal';
import { TabSchedule } from './tab-schedule';
import * as managementHelpers from '../../../helpers/management.helpers';
import { useManagementsHook } from './useManagements.hook';

const styles: Styles = {
  headTitle: {
    color: '#ffffff',
    fontWeight: 600,
  },
  head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: `url(${HeaderImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#4288F0',
    padding: '28px',
    boxSizing: 'border-box',
    color: '#ffffff',
    fontWeight: 600,
  },
  rightSideContainer: { display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'flex-end' },
  uploadsBtn: { maxWidth: 180, width: '100%', marginLeft: 2 },
  tabs: { marginTop: 2 },
  tabsWrapper: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
  rescrap: { height: '48px' },
  urlManageBtn: { marginLeft: '10px' },
  actionBtnsContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 auto',
  },
};

export function Management(): ReactElement {
  const {
    params,
    uploadsBtnWidth,
    scheduleTabValue,
    uploadsAnchor,
    urlManagementAnchor,
    isAddSourceModalOpen,
    isConfirmDeleteBatchedModalVisible,
    pagesAmount,
    withAsins,
    addedFounded,
    managementFilters,
    urlsFilter,
    setIsConfirmDeleteBatchedModalVisible,
    handleSearchChange,
    handleUpdatePage,
    debouncedSitemaps,
    setUrlsFilter,
    handleUploadsPopoverOpen,
    handleUrlManagementPopoverClose,
    handleUrlManagementPopoverOpen,
    handleAddSourceModalOpen,
    handleAddSourceModalClose,
    handleAddedFoundedChange,
    handleWithAsinsChange,
    handleRescrapTab,
    handleUploadsPopoverClose,
    onUpdateSchedule,
    handleBatchDelete,
    handleChangePath,
    setUploadsBtnWidth,
  } = useManagementsHook();

  const handleStepRender = () => {
    switch (params.nav) {
      case URL_MANAGEMENT_NAV.sitemaps:
        return <SitemapsTable handleUpdateSitemaps={debouncedSitemaps} />;

      case URL_MANAGEMENT_NAV.urls:
        return <PagesTable handleUpdatePage={handleUpdatePage} />;

      case URL_MANAGEMENT_NAV.crawler:
        return <CrawlingTable withAsins={withAsins} addedFounded={addedFounded} />;

      default:
        return <SitemapsTable handleUpdateSitemaps={debouncedSitemaps} />;
    }
  };
  return (
    <>
      <Portal id="header-page">
        <Box sx={styles.head}>
          <Typography sx={styles.headTitle}>URL Management</Typography>
          <Box sx={styles.rightSideContainer}>
            <SearchInput search={managementFilters.search} handleSearchChange={handleSearchChange} />
            {params.nav === URL_MANAGEMENT_NAV.urls && (
              <Button variant="contained" sx={styles.urlManageBtn} onClick={handleUrlManagementPopoverOpen}>
                <SettingsIcon />
              </Button>
            )}
            {params.nav === URL_MANAGEMENT_NAV.urls && (
              <UrlsFiltersSelect onChange={setUrlsFilter} value={urlsFilter} />
            )}
            {params.nav === URL_MANAGEMENT_NAV.crawler && (
              <div>
                <WithAsinsSelect value={withAsins} onChange={handleWithAsinsChange} />
                <AddedFoundedSelect value={addedFounded} onChange={handleAddedFoundedChange} />
              </div>
            )}
            <Button
              variant="contained"
              sx={styles.uploadsBtn}
              ref={ref => setUploadsBtnWidth(ref?.clientWidth)}
              onClick={handleUploadsPopoverOpen}
            >
              <DriveFolderUploadIcon />
              Uploads
            </Button>
          </Box>
        </Box>
      </Portal>
      <Box sx={styles.tabsWrapper}>
        <Tabs onChange={handleChangePath} value={params.nav} sx={styles.tabs}>
          <Tab label="Sitemaps" value={URL_MANAGEMENT_NAV.sitemaps} />
          <Tab label="URLs" value={URL_MANAGEMENT_NAV.urls} />
          <Tab label="Crawler" value={URL_MANAGEMENT_NAV.crawler} />
        </Tabs>
        <Box sx={styles.actionBtnsContainer}>
          <TabSchedule onChange={onUpdateSchedule} value={scheduleTabValue} />
          <Button color="primary" variant="contained" sx={styles.rescrap} onClick={handleRescrapTab}>
            {managementHelpers.getRescrapValue(params.nav)}
          </Button>
        </Box>
      </Box>
      {handleStepRender()}
      <UrlManagementPopover
        width={200}
        onBatchDelete={() => {
          setIsConfirmDeleteBatchedModalVisible(true);
          handleUrlManagementPopoverClose();
        }}
        onClose={handleUrlManagementPopoverClose}
        anchorElement={urlManagementAnchor}
      />
      <UploadPopover
        width={uploadsBtnWidth}
        onClose={handleUploadsPopoverClose}
        anchorElement={uploadsAnchor}
        handleAddSourceModalOpen={handleAddSourceModalOpen}
      />
      <AddModal onClose={handleAddSourceModalClose} open={isAddSourceModalOpen} />
      <Modal
        open={isConfirmDeleteBatchedModalVisible}
        onClose={() => setIsConfirmDeleteBatchedModalVisible(false)}
        title={`Are you sure to delete ${pagesAmount} urls?`}
      >
        <Button variant="contained" color="error" onClick={handleBatchDelete}>
          Delete
        </Button>
      </Modal>
    </>
  );
}
