import { Box, Checkbox, Popover, Typography } from '@mui/material';
import { ReactElement, useContext, useMemo } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Styles } from 'common/types';
import { theme } from 'common/constants/theme';
// eslint-disable-next-line import/no-cycle
import { columnsPopoverCtx } from './columns-popover-context';

const styles: Styles = {
  container: { padding: theme.spacing(2.5, 6, 2.5, 2) },
  row: { display: 'flex', alignItems: 'center' },
  title: { fontSize: 14, color: '#214254' },
};

export enum PerformanceTableColumn {
  Tag = 'tag',
  Publisher = 'publisher',
  Saturation = 'saturation',
  SaturationPercent = 'saturation_percent',
  Visits = 'visits',
  Clicks = 'clicks',
  Conversion = 'conversion',
  GMV = 'total_revenue',
  AdFees = 'total_ad_fees',
  RevenuePerVisit = 'revenue_per_visit',
  FirstSeenOOS = 'first_seen_oos',
  RecommendedAsins = 'recommended_asins',
  DesktopAdFees = 'desktop_ad_fees',
  DesktopClicks = 'desktop_clicks',
  DesktopConversion = 'desktop_conversion',
  DesktopRevenue = 'desktop_revenue',
  DesktopShipments = 'desktop_shipments',
  DesktopVisits = 'desktop_visits',
  MobileAdFees = 'mobile_ad_fees',
  MobileClicks = 'mobile_clicks',
  MobileConversion = 'mobile_conversion',
  MobileRevenue = 'mobile_revenue',
  MobileShipments = 'mobile_shipments',
  MobileVisits = 'mobile_visits',
}

const columnsData = [
  { title: 'Recommended ASINs', value: PerformanceTableColumn.RecommendedAsins, view: 'all' },
  { title: 'Publisher', value: PerformanceTableColumn.Publisher, view: 'all' },
  { title: 'Tag', value: PerformanceTableColumn.Tag, view: 'all' },
  { title: 'Saturation', value: PerformanceTableColumn.Saturation, view: 'admin' },
  { title: 'Saturation Percent', value: PerformanceTableColumn.SaturationPercent, view: 'admin' },
  { title: 'Visits', value: PerformanceTableColumn.Visits, view: 'all' },
  { title: 'Clicks', value: PerformanceTableColumn.Clicks, view: 'all' },
  { title: 'Conversion', value: PerformanceTableColumn.Conversion, view: 'all' },
  { title: 'GMV', value: PerformanceTableColumn.GMV, view: 'all' },
  { title: 'GMV / Visit', value: PerformanceTableColumn.RevenuePerVisit, view: 'all' },
  { title: 'Ad Fees', value: PerformanceTableColumn.AdFees, view: 'all' },
  { title: 'OOS Date', value: PerformanceTableColumn.FirstSeenOOS, view: 'all' },
  { title: 'Desktop Ad Fees', value: PerformanceTableColumn.DesktopAdFees, view: 'all' },
  { title: 'Desktop Clicks', value: PerformanceTableColumn.DesktopClicks, view: 'all' },
  { title: 'Desktop Conversion', value: PerformanceTableColumn.DesktopConversion, view: 'all' },
  { title: 'Desktop Revenue', value: PerformanceTableColumn.DesktopRevenue, view: 'all' },
  { title: 'Desktop Shipments', value: PerformanceTableColumn.DesktopShipments, view: 'all' },
  { title: 'Desktop Visits', value: PerformanceTableColumn.DesktopVisits, view: 'all' },
  { title: 'Mobile Ad Fees', value: PerformanceTableColumn.MobileAdFees, view: 'all' },
  { title: 'Mobile Clicks', value: PerformanceTableColumn.MobileClicks, view: 'all' },
  { title: 'Mobile Conversion', value: PerformanceTableColumn.MobileConversion, view: 'all' },
  { title: 'Mobile Revenue', value: PerformanceTableColumn.MobileRevenue, view: 'all' },
  { title: 'Mobile Shipments', value: PerformanceTableColumn.MobileShipments, view: 'all' },
  { title: 'Mobile Visits', value: PerformanceTableColumn.MobileVisits, view: 'all' },
];

interface Props {
  anchorEl: HTMLElement;
  isAdmin?: boolean;
  onClose: () => void;
}

export function ColumnsPopover({ anchorEl, isAdmin, onClose }: Props): ReactElement {
  const { columns, addCol, removeCol } = useContext(columnsPopoverCtx);

  const parsedColumns = useMemo(() => (isAdmin ? columnsData : columnsData.filter(c => c.view === 'all')), [isAdmin]);

  const handleClick = (col: PerformanceTableColumn) => {
    if (columns.includes(col)) removeCol(col);
    else addCol(col);
  };

  return (
    <Popover anchorEl={anchorEl} onClose={onClose} open={Boolean(anchorEl)}>
      <Box sx={styles.container}>
        {parsedColumns.map(c => (
          <Box sx={styles.row} key={c.value}>
            <DragIndicatorIcon color="action" fontSize="small" />
            <Checkbox onClick={() => handleClick(c.value)} checked={columns.includes(c.value)} />
            <Typography sx={styles.title}>{c.title}</Typography>
          </Box>
        ))}
      </Box>
    </Popover>
  );
}
