import { getDataListReducer } from 'common/store/reducers/data-list';
import { getFiltersReducer } from 'common/store/reducers/filters';
import { combineReducers } from 'redux';
import { attributionFiltersActionTypes, brandsAttributionActionTypes } from '../actions';
import amazonAttrReducer from './amazon-attribution';

export const attributionReducers = combineReducers({
  amazon: amazonAttrReducer,
  amazonBrands: getDataListReducer<Attribution.AmazonBrandsAttrData>(brandsAttributionActionTypes),
  filters: getFiltersReducer(attributionFiltersActionTypes),
});
