import { ApiService } from 'common/services';

class PresetsApiService extends ApiService {
  getPublishers = () => {
    return this.get('/api/presets/filters/publishers');
  };

  getAffiliateNetworks = () => {
    return this.get('/api/presets/filters/affiliate-networks');
  };

  getTimeInfo = (publisherId: number) => {
    return this.get(`/api/presets/filters/publishers/${publisherId}/time-info`);
  };
}

export const presetsApi = new PresetsApiService(process.env.REACT_APP_API_URL);
