import { Autocomplete, Box, Button, Checkbox, Chip, MenuItem, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { Field } from 'common/ui/field';
import { useFormikContext } from 'formik';
import { selectCategories } from 'modules/affiliate-gainz/store/selectors';
import { selectPublisherGroups, selectPublisherGroupsLoading } from 'modules/settings/store/selectors';
import { ChangeEvent, ReactElement, SyntheticEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { parentPublisherSettingsSelectors } from 'modules/parent-publisher-settings/store';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 1.5 },
  fieldContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  field: { width: '70%' },
  button: { maxWidth: 100, width: '100%', marginLeft: 1, ':first-child': { marginLeft: 0 } },
  buttons: { marginTop: 5, display: 'flex', justifyContent: 'flex-end' },
  checkboxField: { display: 'flex', alignItems: 'center' },
  inputContainer: { display: 'flex', alignItems: 'center', width: '100%' },
  clearIcon: { cursor: 'pointer', alignSelf: 'center', marginTop: 2 },
  pubField: { width: '100%', marginTop: 2 },
  checkboxContainer: { display: 'flex', gap: 4 },
};

interface Props {
  handleModalClose: () => void;
  isModalOpen: boolean;
}

export function PublisherFormContent({ handleModalClose, isModalOpen }: Props): ReactElement {
  const { values, resetForm, setFieldValue, submitForm } = useFormikContext<PublishersSettingsModule.PublisherForm>();

  const categories = useSelector(selectCategories);
  const publisherGroups = useSelector(selectPublisherGroups);
  const publisherGroupsLoading = useSelector(selectPublisherGroupsLoading);
  const publisherAccounts = useSelector(parentPublisherSettingsSelectors.selectPublisherAccounts);

  const handlePublisherAccountIdChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFieldValue('publisher_account_id', e.target.value);
  };

  const handleCategoriesChange = (_event: SyntheticEvent<Element, Event>, value: string[]) => {
    setFieldValue('categories', value);
  };

  const handleGroupsChange = (_event: SyntheticEvent<Element, Event>, value: string[]) => {
    setFieldValue('channels', value);
  };

  const handlePublisherIdAosChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFieldValue('publisher_id_aos', Number(e.target.value) || null);
  };

  useEffect(() => {
    if (isModalOpen) return;
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, resetForm]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.fieldContainer}>
        <Typography>Name</Typography>
        <Field
          component={TextField}
          name="name"
          fullWidth
          variant="outlined"
          label="Name"
          size="small"
          InputLabelProps={{ shrink: true }}
          sx={styles.field}
        />
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Domain</Typography>
        <Field
          component={TextField}
          name="domain"
          fullWidth
          variant="outlined"
          label="Domain"
          size="small"
          InputLabelProps={{ shrink: true }}
          sx={styles.field}
        />
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Categories</Typography>
        <Autocomplete
          sx={styles.field}
          multiple
          options={categories.data || []}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={params => (
            <TextField {...params} variant="outlined" label="Categories" size="small" placeholder="Categories" />
          )}
          onChange={handleCategoriesChange}
          value={values.categories}
          loading={categories.loading}
        />
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Groups</Typography>
        <Autocomplete
          sx={styles.field}
          multiple
          options={publisherGroups.publisher_groups.map(group => group.group_name) || []}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={params => (
            <TextField {...params} variant="outlined" label="Groups" size="small" placeholder="Groups" />
          )}
          onChange={handleGroupsChange}
          value={values.channels}
          loading={publisherGroupsLoading}
        />
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Skm Tag Prefix</Typography>
        <Field
          component={TextField}
          name="skm_tag_prefix"
          fullWidth
          variant="outlined"
          label="Skm Tag Prefix"
          size="small"
          InputLabelProps={{ shrink: true }}
          sx={styles.field}
        />
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Imp Tag Prefix</Typography>
        <Field
          component={TextField}
          name="imp_tag_prefix"
          fullWidth
          variant="outlined"
          label="Imp Tag Prefix"
          size="small"
          InputLabelProps={{ shrink: true }}
          sx={styles.field}
        />
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Nrv Tag Prefix</Typography>
        <Field
          component={TextField}
          name="nrv_tag_prefix"
          fullWidth
          variant="outlined"
          label="Nrv Tag Prefix"
          size="small"
          InputLabelProps={{ shrink: true }}
          sx={styles.field}
        />
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Publisher ID AOS</Typography>
        <Field
          component={TextField}
          name="publisher_id_aos"
          fullWidth
          variant="outlined"
          label="Publisher ID AOS"
          size="small"
          InputLabelProps={{ shrink: true }}
          sx={styles.field}
          onChange={handlePublisherIdAosChange}
        />
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Parent Publisher</Typography>
        <TextField
          select
          sx={styles.field}
          variant="outlined"
          label="Parent Publisher"
          size="small"
          placeholder="Parent Publisher"
          InputLabelProps={{ shrink: true }}
          value={values.publisher_account_id}
          onChange={handlePublisherAccountIdChange}
        >
          {publisherAccounts?.data?.publisher_accounts?.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.parent_name_aos}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box sx={styles.checkboxContainer}>
        <Box sx={styles.checkboxField}>
          <Field component={Checkbox} name="gainz_active" checked={values.gainz_active} />
          Gainz
        </Box>
        <Box sx={styles.checkboxField}>
          <Field component={Checkbox} name="gainz_only" checked={values.gainz_only} disabled={!values.gainz_active} />
          Gainz Only
        </Box>
        <Box sx={styles.checkboxField}>
          <Field component={Checkbox} name="display_commission" checked={values.display_commission} />
          Can display commission
        </Box>
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Commission rate</Typography>
        <Field
          component={TextField}
          name="commission_rate"
          fullWidth
          variant="outlined"
          label="Comission Rate"
          size="small"
          InputLabelProps={{ shrink: true }}
          sx={styles.field}
        />
      </Box>
      <Box sx={styles.buttons}>
        <Button variant="outlined" sx={styles.button} onClick={handleModalClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={submitForm} sx={styles.button} variant="contained">
          Save
        </Button>
      </Box>
    </Box>
  );
}
