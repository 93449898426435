import { Box, Button, Typography } from '@mui/material';
import { filtersActions } from 'common/store/actions/filters';
import { selectGlobalFilters } from 'common/store/selectors';
import { ReactElement } from 'react';
import { Styles } from 'common/types';
import { AffiliateNetworkTypePicker } from 'common/ui/base-filters/affiliate-network-type-picker';
import { DateBy } from 'common/ui/base-filters/date-by';
import { TrafficSource } from 'common/ui/base-filters/traffic-source';
import { TransactionClick } from 'common/ui/base-filters/transaction-click';
import { Click, DateByValue, Traffic } from 'common/ui/base-filters/types';
import { Modal } from 'common/ui/modal';
import { useDispatch, useSelector } from 'react-redux';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 1.5 },
  fieldContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  btnContainer: { display: 'flex', justifyContent: 'flex-end', gap: 1.5 },
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleApply: () => void;
  handleReset: () => void;
}

export function FiltersModal({ isOpen, onClose, handleApply, handleReset }: Props): ReactElement {
  const dispatch = useDispatch();

  const filters = useSelector(selectGlobalFilters);

  const handleTrafficSourceChange = (trafficSource: Traffic) => {
    dispatch(filtersActions.changeTrafficSource(trafficSource));
  };

  const handleAffiliateNetworkTypeChange = (id: number | string) => {
    dispatch(filtersActions.changeAffiliateNetworkType(id));
  };

  const handleDateByChange = (dateBy: DateByValue) => {
    dispatch(filtersActions.changeDateBy(dateBy));
  };

  const hanldeTransactionClickChange = (click: Click) => {
    dispatch(filtersActions.changeClick(click));
  };

  return (
    <Modal open={isOpen} onClose={onClose} title="Filters" maxWidth="sm">
      <Box sx={styles.container}>
        <Box sx={styles.fieldContainer}>
          <Typography>Traffic Type</Typography>
          <TrafficSource onChange={handleTrafficSourceChange} trafficSource={filters.traffic_type} />
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Network Type</Typography>
          <AffiliateNetworkTypePicker
            onChange={handleAffiliateNetworkTypeChange}
            affiliateNetworkType={filters.affiliate_network_type}
          />
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Report Stats</Typography>
          <DateBy onChange={handleDateByChange} dateBy={filters.date_by} />
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography>Click</Typography>
          <TransactionClick
            onChange={hanldeTransactionClickChange}
            transactionClick={filters.clicks}
            clicksEnabled={filters.date_by === DateByValue.Transactional}
          />
        </Box>
        <Box sx={styles.btnContainer}>
          <Button variant="outlined" onClick={handleReset}>
            Reset
          </Button>
          <Button variant="contained" onClick={handleApply}>
            Apply
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
