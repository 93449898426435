import { ReactElement } from 'react';
import { Modal } from 'common/ui/modal';
import { asinsConversionsBoostSelectors } from 'modules/asins-conversions-boost/store/selectors';
import * as Yup from 'yup';
import { Formik, FormikState } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { asinsConversionsBoostActions } from 'modules/asins-conversions-boost/store/actions';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { Styles } from 'common/types';
import { FiltersForm } from '../filters-form';

const getStyles = (): Styles => ({
  btnsContainer: { display: 'flex', justifyContent: 'flex-end', gap: 1.5, marginTop: '20px' },
});

interface Props {
  isOpen: boolean;
  handleApply: () => void;
  handleReset: () => void;
  onClose: () => void;
}

const validationSchema = Yup.object().shape({
  asins: Yup.array().of(Yup.string().test('isValidASIN', 'Invalid Amazon ASIN', value => /^[A-Z0-9]{10}$/.test(value))),
  brand_id: Yup.number().nullable().integer().positive(),
  publisher_id: Yup.number().nullable().positive(),
  boost: Yup.string().test('isNumericInRange', 'Boost must be a numeric value between 0 and 1', value => {
    if (!value) {
      return true;
    }
    const numericValue = Number(value);
    return !Number.isNaN(numericValue) && numericValue >= 0 && numericValue <= 1;
  }),
  compare_operator: Yup.string(),
});

export function FiltersModal({ isOpen, handleApply, handleReset, onClose }: Props): ReactElement {
  const dispatch = useDispatch();
  const filters = useSelector(asinsConversionsBoostSelectors.selectFilters);

  const styles = getStyles();

  const getInitialValues = (): AsinsConversionsBoostModule.FiltersForm => ({
    asins: filters?.asins || [],
    brand_id: filters?.brand_id || null,
    publisher_id: filters?.publisher_id || null,
    boost: filters?.boost || '',
    compare_operator: filters?.compare_operator || '=',
  });

  const handleSubmit = (values: AsinsConversionsBoostModule.FiltersForm) => {
    dispatch(asinsConversionsBoostActions.filtersActions.setFilters(values));
    handleApply();
  };

  return (
    <Modal open={isOpen} onClose={onClose} title="Filters" maxWidth="sm">
      <Formik
        initialValues={getInitialValues()}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ initialValues, resetForm, submitForm }) => (
          <>
            <FiltersForm />
            <Box sx={styles.btnsContainer}>
              <Button
                variant="outlined"
                onClick={() => {
                  resetForm(initialValues as Partial<FormikState<AsinsConversionsBoostModule.FiltersForm>>);
                  handleReset();
                }}
              >
                Reset
              </Button>
              <Button variant="contained" onClick={submitForm}>
                Apply
              </Button>
            </Box>
          </>
        )}
      </Formik>
    </Modal>
  );
}
