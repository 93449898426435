import { State } from 'common/store/reducers';

export const selectAllStatistics = (state: State) => state.overviewV2.statistics.entries;

export const selectAllStatisticsLoading = (state: State) => state.overviewV2.statistics.loading;

export const selectProductsStatistics = (state: State) => state.overviewV2.productsStats.entries;

export const selectProductsStatisticsLoading = (state: State) => state.overviewV2.productsStats.loading;

export const selectCorrelationStatistics = (state: State) => state.overviewV2.correlation.entries;

export const selectCorrelationStatisticsLoading = (state: State) => state.overviewV2.correlation.loading;

export const selectDevicesStatistics = (state: State) => state.overviewV2.devices.entries;

export const selectDevicesStatisticsLoading = (state: State) => state.overviewV2.devices.loading;

export const selectTopPerformers = (state: State) => state.overviewV2.performance;

export const selectTop10Pages = (state: State) => state.overviewV2.top10.data;

export const selectTop10PagesLoading = (state: State) => state.overviewV2.top10.loading;

export const selectCombined = (state: State) => state.overviewV2.combined;

export const selectTop10Export = (state: State) => state.overviewV2.top10Export;
