import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { Styles } from 'common/types';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { productReplacementsActions } from 'modules/content-overview/store/actions';
import {
  selectReplacements,
  selectReplacementsLimitPageAmount,
  selectReplacementsLoading,
} from 'modules/content-overview/store/selectors';
import { MouseEvent, ReactElement, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageRow } from './page-row';

const styles: Styles = {
  table: { marginTop: 4 },
  cellText: { color: '#214254', fontSize: '12px' },
};

interface Props {
  handleGetReplacements: () => void;
}

export function ReplaceProductsTable({ handleGetReplacements }: Props): ReactElement {
  const dispatch = useDispatch();

  const replacements = useSelector(selectReplacements);
  const loading = useSelector(selectReplacementsLoading);
  const { amount, page, limit } = useSelector(selectReplacementsLimitPageAmount);

  const scrollAnchor = useRef<HTMLDivElement>();

  const handlePageChange = (_: MouseEvent<HTMLButtonElement>, page: number) => {
    dispatch(productReplacementsActions.changePage(page));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(productReplacementsActions.changeLimit(parseInt(event.target.value, 10)));
    dispatch(productReplacementsActions.changePage(0));
  };

  useEffect(() => {
    scrollAnchor.current.scrollIntoView({ behavior: 'smooth' });
  }, [page]);

  return (
    <>
      <div ref={scrollAnchor} />
      <TableContainerWithLoading sx={styles.table} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.cellText}>Page</TableCell>
              <TableCell sx={styles.cellText}>Page Visits</TableCell>
              {/* <TableCell align="center">Article Performance Traffic</TableCell> */}
              <TableCell sx={styles.cellText}>Products</TableCell>
              <TableCell sx={styles.cellText}>Recommended replacement</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {replacements?.map(page => (
              <PageRow key={page.title} row={page} handleGetReplacements={handleGetReplacements} />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <TablePagination
        component="div"
        count={amount}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 25, 50, 100]}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
