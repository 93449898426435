import { Box, Button, Typography } from '@mui/material';
import { LC_ONBOARDING_FINISHED } from 'common/constants/lc';
import { usePublisher } from 'common/hooks';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import { ReactElement } from 'react';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  title: { fontSize: 18, fontWeight: 600 },
  description: { fontSize: 14, mt: 1.5, textAlign: 'center' },
  btns: { display: 'flex', gap: 0.5, mt: 3 },
  skipBtn: { width: 105 },
  startBtn: { width: 120 },
};

interface Props {
  open: boolean;
  onClose: () => void;
  handleTourStart: () => void;
}

export function OnboardingModal({ open, onClose, handleTourStart }: Props): ReactElement {
  const publisher = usePublisher();

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles.container}>
        <img src="/assets/images/maverickx-full-logo.svg" alt="chart" />
        <Typography sx={styles.title}>Hi ‘{publisher?.name}’</Typography>
        <Typography sx={styles.description}>
          Before you start, we recommend taking a quick tour to get the best experience and value out of our service.
        </Typography>
        <Box sx={styles.btns}>
          <Button
            variant="outlined"
            size="small"
            sx={styles.startBtn}
            onClick={() => {
              handleTourStart();
              onClose();
            }}
          >
            Start tour
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={styles.skipBtn}
            onClick={() => {
              localStorage.setItem(LC_ONBOARDING_FINISHED, '1');
              onClose();
            }}
          >
            Skip
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
