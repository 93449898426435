import { Action } from 'common/interfaces/root';
import { revenueByPageActionTypes as T } from '../actions';

const initState: RevenueByPage.ExportStore = {
  loading: false,
  error: null,
  link: '',
};

export default (state: RevenueByPage.ExportStore = initState, { type, payload }: Action): RevenueByPage.ExportStore => {
  switch (type) {
    case T.EXPORT_PAGES:
    case T.EXPORT_TOP10_PAGES:
      return { ...state, loading: true, link: '', error: null };

    case T.EXPORT_SUCCESS:
      return { ...state, loading: false, link: payload };

    case T.EXPORT_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
