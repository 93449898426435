import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { Direction } from 'common/ui/base-filters/types';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { recommendedAsinsActions } from 'modules/affiliate-gainz/store/recommended-asins/actions';
import { MouseEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { asinRecommendationSelectors } from 'modules/asin-recommendation/store/selectors';
import { asinRecommendationActions } from 'modules/asin-recommendation/store/actions/actions';
import { useDebouncedCallback } from 'use-debounce';
import { ColumnsPopover } from './columns-popover/columns-popover';
import { AsinTableRow } from './asin-table-row';
import { FiltersModal } from '../../filters-modal';

const styles: Styles = {
  table: { marginTop: 1 },
};

export enum TableColumns {
  Product = 'product',
  Brand = 'brand',
  'Product Price' = 'product_price',
  'Opportunity Earn' = 'opportunity_earn',
  URLs = 'urls',
  'Suggested Articles' = 'suggested_articles',
  Status = 'status',
}

const columnsData: AsinRecommendationModule.Table.Column[] = [
  { title: 'Product', value: TableColumns.Product },
  { title: 'Brand', value: TableColumns.Brand },
  { title: 'Product Price', value: TableColumns['Product Price'] },
  { title: 'Opportunity Earn', value: TableColumns['Opportunity Earn'] },
  { title: 'URLs', value: TableColumns.URLs },
  { title: 'Suggested Articles', value: TableColumns['Suggested Articles'] },
  { title: 'Status', value: TableColumns.Status },
];

interface Props {
  view: string;
  filtersModalOpen: boolean;
  handleFiltersClose: () => void;
  getAsinParams: AsinRecommendationModule.Params.GetAsinsParams;
}

export function AsinsTable({ view, filtersModalOpen, handleFiltersClose, getAsinParams }: Props): ReactElement {
  const dispatch = useDispatch();

  const [sortField, setSortField] = useState<TableColumns>(TableColumns.Product);
  const [sortDirection, setSortDirection] = useState<Direction>('desc');
  const [selected, setSelected] = useState<AsinRecommendationModule.State.Asin | null>(null);
  const [colsButtonAnchor, setColsButtonAnchor] = useState<HTMLElement | null>(null);
  const [selectedCol, setSelectedCol] = useState<AsinRecommendationModule.Table.SelectedColumns>({
    product: true,
    brand: true,
    preview: false,
    product_price: true,
    opportunity_earn: true,
    urls: true,
    suggested_articles: true,
    status: true,
  });

  const global = useSelector(selectGlobalFilters);
  const filters = useSelector(asinRecommendationSelectors.selectFilters);
  const table = useSelector(asinRecommendationSelectors.selectTable);
  const asins = useSelector(asinRecommendationSelectors.selectAsins);

  const scrollTo: ScrollToOptions = { top: 0, behavior: 'smooth' };

  const getRecommendedAsinsParams = useMemo<AsinRecommendationModule.Params.GetAsinsParams>(
    () => ({
      ...getAsinParams,
      product_sort: table.product_sort,
      price_sort: table.price_sort,
      limit: table.limit,
      offset: table.offset,
    }),
    [getAsinParams, table]
  );

  const getPlacementsParams = useMemo<AsinRecommendationModule.Params.GetPlacementParams>(
    () => ({
      publisher_id: global?.publisher_id,
      asin: selected?.asin,
      title: selected?.extended_product.title,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  );

  const getDirection = (direction: 'asc' | 'desc') => {
    return direction === 'asc' ? 'desc' : 'asc';
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(asinRecommendationActions.table.setLimit(parseInt(event.target.value, 10)));
    dispatch(asinRecommendationActions.table.setOffset(0));
    window.scrollTo(scrollTo);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(asinRecommendationActions.table.setOffset(newPage));
    window.scrollTo(scrollTo);
  };

  const handleSortChange = (field: TableColumns) => {
    setSortField(field);
    setSortDirection(getDirection(sortDirection));
    switch (field) {
      case TableColumns.Product:
        dispatch(asinRecommendationActions.table.setPriceSort(null));
        dispatch(asinRecommendationActions.table.setProductSort(sortDirection));
        break;
      case TableColumns['Product Price']:
        dispatch(asinRecommendationActions.table.setProductSort(null));
        dispatch(asinRecommendationActions.table.setPriceSort(sortDirection));
        break;

      default:
        break;
    }
  };

  const handleColsPopoverOpen = (e: MouseEvent<HTMLElement>) => {
    setColsButtonAnchor(e.currentTarget);
  };

  const handleColsPopoverClose = () => {
    setColsButtonAnchor(null);
  };

  const handleApply = () => {
    dispatch(
      asinRecommendationActions.asins.getAsins({
        ...getRecommendedAsinsParams,
        offset: 0,
      })
    );
    dispatch(recommendedAsinsActions.setOffset(0));
    handleFiltersClose();
  };

  const handleReset = () => {
    dispatch(asinRecommendationActions.filters.resetFilters());
  };

  const debouncedRecommendedAsins = useDebouncedCallback((params: AsinRecommendationModule.Params.GetAsinsParams) => {
    dispatch(asinRecommendationActions.asins.getAsins(params));
  }, 300);

  useEffect(() => {
    if (selected) dispatch(asinRecommendationActions.placements.getPlacements(getPlacementsParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPlacementsParams]);

  useEffect(() => {
    if (view === 'list') {
      dispatch(
        asinRecommendationActions.asins.getAsins({
          ...getRecommendedAsinsParams,
          offset: table.offset * table.limit,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, view]);

  useEffect(() => {
    if (view === 'list') {
      debouncedRecommendedAsins(getRecommendedAsinsParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.search, view]);

  useEffect(() => {
    if (view === 'list') {
      dispatch(asinRecommendationActions.asins.getAsins(getRecommendedAsinsParams));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  return (
    <>
      <TableContainerWithLoading loading={asins.loading} sx={styles.table}>
        <Table>
          <TableHead>
            <TableRow>
              {selectedCol.product && (
                <TableCell>
                  <TableSortLabel
                    onClick={() => handleSortChange(TableColumns.Product)}
                    active={sortField === TableColumns.Product}
                    direction={table.product_sort || 'asc'}
                  >
                    Product
                  </TableSortLabel>
                </TableCell>
              )}
              {selectedCol.brand && <TableCell>Brand</TableCell>}
              {selectedCol.product_price && (
                <TableCell>
                  <TableSortLabel
                    onClick={() => handleSortChange(TableColumns['Product Price'])}
                    active={sortField === TableColumns['Product Price']}
                    direction={table.price_sort || 'asc'}
                  >
                    Product Price
                  </TableSortLabel>
                </TableCell>
              )}
              {selectedCol.opportunity_earn && <TableCell>Opportunity Earn</TableCell>}
              {selectedCol.urls && <TableCell>URLs</TableCell>}
              {selectedCol.suggested_articles && <TableCell>Suggested Articles</TableCell>}
              {selectedCol.status && <TableCell>Status</TableCell>}
              <TableCell>
                <Tooltip title="Add columns" placement="top">
                  <IconButton size="small" onClick={handleColsPopoverOpen}>
                    <FormatListBulletedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {asins?.data?.recommended_asins?.map(asin => (
              <AsinTableRow
                asin={asin}
                key={asin.asin}
                selected={selected}
                selectedCol={selectedCol}
                setSelected={setSelected}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      {!asins.loading && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[20, 40, 80, 120]}
          count={Number(asins?.data?.total)}
          rowsPerPage={table.limit}
          page={table.offset}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <ColumnsPopover
        anchorEl={colsButtonAnchor}
        columnsData={columnsData}
        onClose={handleColsPopoverClose}
        selected={selectedCol}
        setSelectedCol={setSelectedCol}
      />
      <FiltersModal
        onClose={handleFiltersClose}
        isOpen={filtersModalOpen}
        handleApply={handleApply}
        handleReset={handleReset}
        type="list"
      />
    </>
  );
}
