import { Action } from 'common/interfaces/root';
import { contentOverviewActionTypes as T } from '../actions';

const initState: ContentOverviewModule.ScheduleStore = {
  sitemapScrapingSchedule: '',
  urlScrapingSchedule: '',
  crawlerSchedule: '',
};

export default (
  state: ContentOverviewModule.ScheduleStore = initState,
  { type, payload }: Action
): ContentOverviewModule.ScheduleStore => {
  switch (type) {
    case T.SET_URLS_SCRAPPING_SCHEDULE:
      return { ...state, urlScrapingSchedule: payload };

    case T.SET_CRAWLER_SCHEDULE:
      return { ...state, crawlerSchedule: payload };

    case T.SET_SITEMAP_SCRAPPING_SCHEDULE:
      return { ...state, sitemapScrapingSchedule: payload };

    default:
      return state;
  }
};
