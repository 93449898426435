import { TextField } from '@mui/material';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types';
import { ChangeEvent, ReactElement } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const getStyles = (primaryBg: boolean, fullWidth: boolean): Styles => ({
  input: {
    marginLeft: 2,
    backgroundColor: primaryBg ? theme.palette.background.default : theme.palette.background.paper,
    borderRadius: 12,
    ':first-child': { marginLeft: 0 },
    '& fieldset': { border: 'none' },
    width: fullWidth ? '100%' : 'auto',
  },

  startAdornment: { color: '#739AAC', marginRight: 0.5 },
  endAdornment: { color: '#739AAC', marginRight: 0.5, cursor: 'pointer' },
});

interface Props {
  search: string;
  handleSearchChange: (search: string) => void;
  endAdornment?: boolean;
  primaryBg?: boolean;
  fullWidth?: boolean;
}

export function SearchInput({ search, handleSearchChange, endAdornment, primaryBg, fullWidth }: Props): ReactElement {
  const styles = getStyles(primaryBg, fullWidth);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleSearchChange(e.target.value);
  };

  const handleEnter = (e: { key: string; preventDefault: () => void }) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleClear = () => {
    handleSearchChange('');
  };

  return (
    <TextField
      multiline
      maxRows={4}
      size="small"
      color="primary"
      sx={styles.input}
      value={search}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        startAdornment: <SearchIcon fontSize="small" sx={styles.startAdornment} />,
        ...(endAdornment
          ? { endAdornment: <CloseIcon onClick={handleClear} fontSize="small" sx={styles.endAdornment} /> }
          : {}),
      }}
      onChange={handleChange}
      onKeyDown={handleEnter}
      placeholder="Search ..."
    />
  );
}
