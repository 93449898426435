import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { getStyles as getSettingStyles } from 'modules/user-settings/components/user-settings-content/user-settings-content';
import {
  selectPendingInvitations,
  selectPendingInvitationsLoading,
  selectPublisherTeamInfo,
  selectPublisherTeamLoading,
} from 'modules/settings/store/selectors';
import { Box, Button, CircularProgress, OutlinedInput, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { selectGlobalFilters } from 'common/store/selectors';
import { TeamMemberRow } from './team-member-row';

const getStyles = (): Styles => ({
  memberInput: { maxWidth: '290px', width: '100%' },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '405px',
    marginRight: '175px',
    boxSizing: 'border-box',
  },
  fieldWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '20px 0',
    boxSizing: 'border-box',
    borderBottom: '1px solid #C2DEEB40',
    ':last-child': { borderBottom: 0 },
  },
  spinnerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '200px',
  },
});

export function TeamSettings(): ReactElement {
  const dispatch = useDispatch();

  const styles = getStyles();
  const settingsStyles = getSettingStyles();

  const [inviteValue, setInviteValue] = useState<string>('');
  const [invitedList, setInvitedList] = useState<Array<{ name?: string; avatar?: string; email: string; id?: number }>>(
    []
  );

  const members = useSelector(selectPublisherTeamInfo);
  const loading = useSelector(selectPublisherTeamLoading);
  const pendingInvitations = useSelector(selectPendingInvitations);
  const pendingInvitationsLoading = useSelector(selectPendingInvitationsLoading);
  const filters = useSelector(selectGlobalFilters);
  const globalFilters = useSelector(selectGlobalFilters);

  const id = String(filters?.publisher_id);

  const handleChangeInviteValue = (e: ChangeEvent<HTMLInputElement>) => {
    setInviteValue(e?.target?.value);
  };

  const handleSuccessInvite = () => {
    toast.success('User has been invited');
    setInvitedList([...invitedList, { email: inviteValue, name: '', avatar: '' }]);
    setInviteValue('');
  };

  const handleErrorInvite = () => {
    toast.error('Invite user error');
    setInviteValue('');
  };

  const handleInviteMember = () => {
    dispatch(
      publishersSettingsActions.sendInvitation(
        inviteValue,
        String(globalFilters?.publisher_id),
        handleSuccessInvite,
        handleErrorInvite
      )
    );
  };

  useEffect(() => {
    dispatch(publishersSettingsActions.getPublisherTeam(id));
    dispatch(publishersSettingsActions.getPendingInvitations(String(globalFilters?.publisher_id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  if (loading || pendingInvitationsLoading) {
    return (
      <Box sx={styles.spinnerWrapper}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <>
      <Box sx={settingsStyles.fieldWrapper}>
        <Typography sx={settingsStyles.titleField}>Invite New</Typography>
        <Box sx={settingsStyles.inputWrapper}>
          <OutlinedInput sx={styles.memberInput} value={inviteValue} onChange={handleChangeInviteValue} />
          <Button color="primary" onClick={handleInviteMember} sx={settingsStyles.button} variant="contained">
            Invite
          </Button>
        </Box>
      </Box>
      <Box sx={styles.fieldWrapper}>
        <Typography sx={settingsStyles.titleField}>Our Team</Typography>
        <Box sx={styles.inputWrapper}>
          {members.map(member => (
            <TeamMemberRow name={member.name} avatar={member.avatar} email={member.email} id={Number(member.id)} />
          ))}
        </Box>
      </Box>
      <Box sx={styles.fieldWrapper}>
        <Typography sx={settingsStyles.titleField}>Pending invitations</Typography>
        <Box sx={styles.inputWrapper}>
          {[...pendingInvitations, ...invitedList].map(member => (
            <TeamMemberRow email={member.email} />
          ))}
        </Box>
      </Box>
    </>
  );
}
