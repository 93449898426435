import { ReactElement } from 'react';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { asinRecommendationSelectors } from 'modules/asin-recommendation/store/selectors';
import { Styles } from 'common/types';
import { usePermission } from 'common/hooks';
import { PERMISSIONS } from 'common/constants/permissions';

const styles: Styles = {
  container: {
    width: '100%',
    mt: 3,
    padding: '15px 0',
    boxSizing: 'border-box',
  },
  noneContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    padding: 1,
  },
  fieldTitle: {
    fontSize: '14px',
    color: '#58717F',
    lineHeight: '15px',
    letterSpacing: '1px',
  },
  url: { width: 'fit-content', ':hover': { textDecoration: 'underline', cursor: 'pointer' } },
  table: { maxHeight: 300 },
  header: { color: 'white' },
};

interface Props {
  handleUrlClick: (url: string) => void;
}

export function PlacementsList({ handleUrlClick }: Props): ReactElement {
  const placements = useSelector(asinRecommendationSelectors.selectPlacements);
  const isAdmin = usePermission(PERMISSIONS.GET_PUBLISHERS);

  return (
    <Box sx={styles.container}>
      {placements.loading ? (
        <Box sx={styles.noneContainer}>
          <CircularProgress size={14} />
          Retrieving suggestions...
        </Box>
      ) : (
        <TableContainer sx={styles.table}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow sx={styles.header}>
                <TableCell>
                  <Typography sx={styles.fieldTitle}>SUGGESTED ARTICLES</Typography>
                </TableCell>
                {isAdmin && (
                  <TableCell>
                    <Typography sx={styles.fieldTitle}>PUBLISHER</Typography>
                  </TableCell>
                )}
                <TableCell>
                  <Typography sx={styles.fieldTitle}># OF VISITS</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {placements?.data?.map(item => (
                <TableRow>
                  <TableCell>
                    <Box sx={styles.url} onClick={() => handleUrlClick(item.url)}>
                      {item.url}
                    </Box>
                  </TableCell>
                  {isAdmin && <TableCell>{item.name}</TableCell>}
                  <TableCell>{item.visits}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
