/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, SyntheticEvent } from 'react';
import { Autocomplete, Box, Chip, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { PublisherPicker } from 'common/ui/publisher-picker';
import { useFormikContext } from 'formik';
import { Field } from 'common/ui/field';
import { BrandSelect } from '../brand-select';

const styles: Styles = {
  fieldsContainer: { display: 'flex', flexDirection: 'column', gap: 1.5 },
  fieldContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  field: { width: '70%' },
  publisherPickerWrapper: {
    width: '70%',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    zIndex: 1,
  },
  errorText: { color: 'red', marginTop: '4px', fontSize: '12px' },
};

export function FiltersForm(): ReactElement {
  const { errors, values, setFieldValue } = useFormikContext<AsinsConversionsBoostModule.FiltersForm>();

  const handleAsinsChange = (_e: SyntheticEvent<Element, Event>, value: string[]) => {
    const inputValue = _e ? (_e as any).inputValue : '';

    if (typeof inputValue !== 'undefined' && inputValue.trim() !== '') {
      const updatedAsins = [...value, inputValue.trim()];
      setFieldValue('asins', updatedAsins);
    } else {
      setFieldValue('asins', []);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      const inputValue = (event.target as HTMLInputElement)?.value?.trim();
      if (inputValue !== '') {
        const updatedAsins = [...values.asins, inputValue];
        setFieldValue('asins', updatedAsins);
        (event.target as HTMLInputElement).value = '';
      }
    } else if (
      event.key === 'Backspace' &&
      (event.target as HTMLInputElement).value === '' &&
      values.asins.length > 0
    ) {
      const updatedAsins = [...values.asins];
      updatedAsins.pop();
      setFieldValue('asins', updatedAsins);
    }
  };

  const handlePublisherChange = (value: string | number) => {
    const numericValue = typeof value === 'string' ? parseInt(value, 10) : value;
    setFieldValue('publisher_id', numericValue);
  };

  const handleBrandSelect = (value: number) => {
    setFieldValue('brand_id', value);
  };

  return (
    <Box sx={styles.fieldsContainer}>
      <Box sx={styles.fieldContainer}>
        <Typography>ASINs</Typography>
        <Box sx={styles.field}>
          <Autocomplete
            multiple
            id="asins"
            options={[]}
            freeSolo
            value={values.asins}
            onChange={handleAsinsChange}
            onKeyDown={handleKeyDown}
            clearOnBlur
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                  onDelete={() => {
                    const updatedAsins = [...values.asins];
                    updatedAsins.splice(index, 1);
                    setFieldValue('asins', updatedAsins);
                  }}
                />
              ))
            }
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label="ASINs"
                variant="outlined"
                size="small"
                color="primary"
              />
            )}
          />
          {errors.asins && <Typography sx={styles.errorText}>{errors.asins}</Typography>}
        </Box>
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Brand</Typography>
        <Box sx={styles.field}>
          <BrandSelect selectedBrand={values.brand_id} setSelectedBrand={handleBrandSelect as any} />
        </Box>
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Publisher</Typography>
        <Box sx={styles.publisherPickerWrapper}>
          <PublisherPicker
            singleOption
            selectedPublisher={values.publisher_id}
            disableRedirect
            handlePublisherChange={handlePublisherChange}
            fullWidth
          />
        </Box>
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Boost</Typography>
        <Box sx={styles.field}>
          <Field
            component={TextField}
            InputLabelProps={{ shrink: true }}
            label="Boost"
            name="boost"
            type="string"
            size="small"
            sx={{ width: '100%' }}
          />
          {errors.boost && <Typography sx={styles.errorText}>{errors.boost}</Typography>}
        </Box>
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography>Compare Type</Typography>
        <Select
          variant="outlined"
          size="small"
          value={values.compare_operator}
          onChange={event => setFieldValue('compare_operator', event.target.value)}
          sx={styles.field}
        >
          <MenuItem value="=">Equal</MenuItem>
          <MenuItem value=">">Greater Than</MenuItem>
          <MenuItem value="<">Less Than</MenuItem>
          <MenuItem value=">=">Greater Than or Equal</MenuItem>
          <MenuItem value="<=">Less Than or Equal</MenuItem>
        </Select>
      </Box>
    </Box>
  );
}
