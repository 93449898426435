import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { MouseEvent, ReactElement, useMemo, useState } from 'react';
import { theme } from 'common/constants/theme';
import GroupIcon from '@mui/icons-material/Group';
import { Styles } from 'common/types';
import { selectFiltersPresets, selectPresetsLoading } from 'common/store/selectors';
import { useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { selectCombined } from 'modules/overview-v2/store/selectors';
import { PublisherPickerPopover } from './publisher-picker-popover';

const styles: Styles = {
  publisherIcon: { marginRight: 1 },
  arrowIcon: { marginLeft: 1 },
  button: {
    color: theme.palette.primary.dark,
    borderRadius: 12,
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    ':hover': { backgroundColor: theme.palette.background.paper, border: 'none' },
  },
};

interface Props {
  selectedPublisher: number | null | string;
  handlePublisherChange: (publisher: number | null | string) => void;
  disableRedirect?: boolean;
  fullWidth?: boolean;
  singleOption?: boolean;
}

export function PublisherPicker({
  selectedPublisher,
  handlePublisherChange,
  disableRedirect = false,
  fullWidth = false,
  singleOption,
}: Props): ReactElement {
  const filtersPresets = useSelector(selectFiltersPresets);
  const presetsLoading = useSelector(selectPresetsLoading);
  const combined = useSelector(selectCombined);

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const selectedPublisherName = useMemo(() => {
    if (combined.isCombined) return 'All';
    return (
      filtersPresets?.publishers?.find(publisher => Number(publisher.id) === Number(selectedPublisher))?.name ?? ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersPresets?.publishers, selectedPublisher, combined.isCombined]);

  const publisherName =
    selectedPublisherName?.length <= 8 ? selectedPublisherName : `${selectedPublisherName.slice(0, 8)}...`;

  const handlePublisherPickerOpen = (e: MouseEvent<HTMLButtonElement>) => {
    if (filtersPresets?.publishers?.length <= 1) return;
    setAnchorElement(e.currentTarget);
  };

  const handlePublisherPickerClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <Button variant="outlined" onClick={handlePublisherPickerOpen} fullWidth={fullWidth} sx={styles.button}>
        <GroupIcon fontSize="small" sx={styles.publisherIcon} />
        {presetsLoading ? (
          <CircularProgress size={14} />
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography sx={{ width: 'max-content', overflow: 'hidden' }}>{publisherName}</Typography>
          </Box>
        )}
        <KeyboardArrowDownIcon fontSize="small" sx={styles.arrowIcon} />
      </Button>
      <PublisherPickerPopover
        singleOption={singleOption}
        onClose={handlePublisherPickerClose}
        anchorElement={anchorElement}
        selectedPublisher={selectedPublisher}
        handlePublisherChange={handlePublisherChange}
        disableRedirect={disableRedirect}
      />
    </>
  );
}
