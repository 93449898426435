import { State } from 'common/store/reducers';

export const selectAsinsLoading = (state: State) => state.asinFinder.asins.loading;

export const selectAsinsData = (state: State) => state.asinFinder.asins.data;

export const selectAsinsAmount = (state: State) => state.asinFinder.asins.amount;

export const selectAsinsLimit = (state: State) => state.asinFinder.asins.limit;

export const selectAsinsPage = (state: State) => state.asinFinder.asins.page;

export const selectAsinsVariations = (state: State) => state.asinFinder.asins.variations;

export const selectExecutionId = (state: State) => state.asinFinder.asins.execution_id;

export const selectGenerateReportLoading = (state: State) => state.asinFinder.asins.loadingReport;

export const selectBrandParents = (state: State) => state.asinFinder.asins.brandParents;

export const selectBrands = (state: State) => state.asinFinder.asins.brands;

export const selectBrandParentsLoading = (state: State) => state.asinFinder.asins.loadingBrands;

export const selectGeneratingReport = (state: State) => state.asinFinder.asins.isGenerating;
