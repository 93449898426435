import { MenuItem, TextField } from '@mui/material';
import { Styles } from 'common/types';
import { SelectOption } from 'common/ui/select-field/select-field';
import { ChangeEvent, ReactElement } from 'react';

const options: Array<SelectOption> = [
  {
    title: 'ALL',
    value: 0,
  },
  {
    title: 'ASINS only',
    value: 1,
  },
];

const styles: Styles = {
  select: { marginLeft: 2, width: 150, ':first-child': { marginLeft: 0 } },
};

interface Props {
  onChange: (value: number) => void;
  value: number;
}

export function WithAsinsSelect({ onChange, value }: Props): ReactElement {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(parseInt(e.target.value, 10));
  };

  return (
    <TextField
      select
      color="primary"
      sx={styles.select}
      onChange={handleChange}
      value={value}
      size="small"
      label="With asins"
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.title}
        </MenuItem>
      ))}
    </TextField>
  );
}
