import { Action } from 'common/interfaces/root';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { tagsApi } from 'modules/tags/services/tags.service';
import toast from 'react-hot-toast';
import axios from 'axios';
import { publishersSettingsApi } from 'modules/publishers-settings/services/publishers-settings.service';
import { publishersTagsActions } from '../actions/publisher-tags-action';
import { PublisherTagsActionTypes as TYPES } from '../actions';

function* getPublishersTags({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(tagsApi.getPublishersTags, payload);

    yield put(publishersTagsActions.getPublishersTagsSuccess(data));
  } catch (err) {
    yield put(publishersTagsActions.getPublishersTagsError(err as Error));
  }
}

function* editPublishersTags({ payload, meta }: Action): SagaIterator {
  try {
    yield call(publishersSettingsApi.editPublisher, payload.publisher, payload.id);
    yield put(publishersTagsActions.editPublishersTagsSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(publishersTagsActions.editPublishersTagsError(err as Error));
  }
}

function* scheduleTagsImport({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(tagsApi.scheduleTagsImport, {
      publisher_id: payload.publisher_id,
      tag_prefix: payload.tag_prefix,
      tags_amount: payload.tags_amount,
    });
    yield put(publishersTagsActions.scheduleTagsImportSuccess(data));
  } catch (err) {
    if (axios.isAxiosError(err) && err.response.status === 409) {
      toast.error('This tags import task already running');
    }

    yield put(publishersTagsActions.scheduleTagsImportError(err as Error));
  }
}

function* getTagsImportStatus({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(tagsApi.getTagsImportStatus, payload.slug);
    yield put(publishersTagsActions.getTagsImportStatusSuccess(data));
  } catch (err) {
    yield put(publishersTagsActions.getTagsImportStatusError(err as Error));
  }
}

export function* publishersAffiliateTagsSaga(): SagaIterator {
  yield all([
    takeLatest(TYPES.GET_PUBLISHERS_TAGS, getPublishersTags),
    takeEvery(TYPES.EDIT_PUBLISHERS_TAGS, editPublishersTags),
    takeEvery(TYPES.SCHEDULE_TAGS_IMPORT, scheduleTagsImport),
    takeEvery(TYPES.GET_TAGS_IMPORT_STATUS, getTagsImportStatus),
  ]);
}
