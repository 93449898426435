import { Action, UserData } from 'common/interfaces/root';
import { GET_ME, GET_ME_ERROR, GET_ME_SUCCESS, PUT_DEMO_MODE, PUT_LANGUAGE } from './actions-types';

export const putLanguage = (lang: string): Action => ({
  type: PUT_LANGUAGE,
  payload: lang,
});

export const putDemoMode = (payload: boolean): Action => ({
  type: PUT_DEMO_MODE,
  payload,
});

export const getMe = () => ({
  type: GET_ME,
});

export const getMeSuccess = (data: UserData) => ({
  type: GET_ME_SUCCESS,
  payload: data,
});

export const getMeError = (error: Error) => ({
  type: GET_ME_ERROR,
  payload: error,
});
