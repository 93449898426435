import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import { attributionApi } from 'modules/attribution/services/attribution.service';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  attributionActions,
  attributionActionTypes as T,
  brandsAttributionActions,
  brandsAttributionActionTypes,
} from '../actions';

function* getAsinsAmazonAttribution({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(attributionApi.getAsinsAmazonAttr, payload);

    yield put(attributionActions.getAmazonAttributionSuccess(data));
  } catch (err) {
    yield put(attributionActions.getAmazonAttributionError(err as Error));
  }
}

function* postCsvForAsinsAmazonAttr({ payload, meta }: Action): SagaIterator {
  try {
    yield call(attributionApi.postCsvForAsinsAmazonAttr, payload);
    yield put(attributionActions.postCsvForAsinsAmazonAttrSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(attributionActions.postCsvForAsinsAmazonAttrError(err as Error));
    meta.onError();
  }
}

function* deleteAsinsAmazonAttributionItem({ payload }: Action): SagaIterator {
  try {
    yield call(attributionApi.deleteAsinsAmazonAttr, payload);
    yield put(attributionActions.deleteAmazonAttrItemSuccess(payload));
  } catch (err) {
    yield put(attributionActions.deleteAmazonAttrItemError(err as Error));
  }
}

function* getBrandsAmazonAttribution({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(attributionApi.getBrandsAmazonAttr, payload);

    yield put(brandsAttributionActions.getSuccess(data.tags, data.amount));
  } catch (err) {
    yield put(attributionActions.getAmazonAttributionError(err as Error));
  }
}

function* postCsvForBrandsAmazonAttr({ payload, meta }: Action): SagaIterator {
  try {
    yield call(attributionApi.postCsvForBrandsAmazonAttr, payload);
    yield put(attributionActions.postCsvForBrandsAmazonAttrSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(attributionActions.postCsvForBrandsAmazonAttrError(err as Error));
    meta.onError();
  }
}

function* deleteBrandsAmazonAttributionItem({ payload }: Action): SagaIterator {
  try {
    yield call(attributionApi.deleteBrandsAmazonAttr, payload);
    yield put(attributionActions.deleteAmazonAttrItemSuccess(payload));
  } catch (err) {
    yield put(attributionActions.deleteAmazonAttrItemError(err as Error));
  }
}

export function* attrSaga(): SagaIterator {
  yield all([
    takeLatest(T.GET_AMAZON_ATTRIBUTION, getAsinsAmazonAttribution),
    takeEvery(T.POST_CSV_FOR_ASINS_AMAZON_ATTRIBUTION, postCsvForAsinsAmazonAttr),
    takeEvery(T.DELETE_AMAZON_ATTRIBUTION_ITEM, deleteAsinsAmazonAttributionItem),
    takeEvery(brandsAttributionActionTypes.GET, getBrandsAmazonAttribution),
    takeEvery(brandsAttributionActionTypes.DELETE, deleteBrandsAmazonAttributionItem),
    takeEvery(T.POST_CSV_FOR_BRANDS_AMAZON_ATTRIBUTION, postCsvForBrandsAmazonAttr),
  ]);
}
