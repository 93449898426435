import { Action } from 'common/interfaces/root';
import { publishersSettingsActionTypes as T } from '../actions';

const initState: PublishersSettingsModule.PublisherBlockedBrandsStore = {
  loading: false,
  error: null,
  data: [],
};

export default (
  state: PublishersSettingsModule.PublisherBlockedBrandsStore = initState,
  { type, payload }: Action
): PublishersSettingsModule.PublisherBlockedBrandsStore => {
  switch (type) {
    case T.GET_PUBLISHER_BLOCKED_BRANDS:
      return { ...state, loading: true };
    case T.GET_PUBLISHER_BLOCKED_BRANDS_SUCCESS:
      return { ...state, loading: false, data: payload };
    case T.GET_PUBLISHER_BLOCKED_BRANDS_ERROR:
      return { ...state, loading: false, data: [], error: payload };

    default:
      return state;
  }
};
