import { Box, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactElement } from 'react';
import { Styles } from 'common/types';

const styles: Styles = {
  icon: { marginLeft: 0.5, cursor: 'pointer', ':first-child': { marginLeft: 0 } },
};

enum ActionType {
  preview = 'preview',
  edit = 'edit',
  delete = 'delete',
}

interface Props {
  show: Array<keyof typeof ActionType>;
  onPreview?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

export function TableRowActions({ show, onPreview, onEdit, onDelete }: Props): ReactElement {
  return (
    <Box>
      {show.includes(ActionType.preview) && (
        <Tooltip title="Preview">
          <PreviewIcon sx={styles.icon} fontSize="small" color="action" onClick={onPreview} />
        </Tooltip>
      )}
      {show.includes(ActionType.edit) && (
        <Tooltip title="Edit">
          <EditIcon sx={styles.icon} fontSize="small" color="action" onClick={onEdit} />
        </Tooltip>
      )}
      {show.includes(ActionType.delete) && (
        <Tooltip title="Delete">
          <DeleteIcon sx={styles.icon} fontSize="small" color="action" onClick={onDelete} />
        </Tooltip>
      )}
    </Box>
  );
}
