import { Action } from 'common/interfaces/root';
import { parentPublisherSettingsActionTypes as T } from '../actions';

const initState: ParentPublisherSettingsModule.PublisherAccountsState = {
  loading: false,
  data: null,
  error: null,
};

export default (
  state: ParentPublisherSettingsModule.PublisherAccountsState = initState,
  { type, payload }: Action
): ParentPublisherSettingsModule.PublisherAccountsState => {
  switch (type) {
    case T.GET_PUBLISHER_ACCOUNTS:
      return { ...state, loading: true };
    case T.GET_PUBLISHER_ACCOUNTS_SUCCESS:
      return { ...state, loading: false, data: payload };
    case T.GET_PUBLISHER_ACCOUNTS_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
