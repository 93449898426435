import { MenuItem, TextField } from '@mui/material';
import { Styles } from 'common/types';
import { pagesPerformanceFiltersActions } from 'modules/content-overview/store/actions';
import { selectPagesPerformanceFilters } from 'modules/content-overview/store/selectors';
import { ReactElement, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const exportLimitOptions = [
  { title: '-', value: -1 },
  { title: 'Not found', value: 0 },
  { title: 'Temporarily out of stock', value: 1 },
  { title: 'Out of stock', value: 2 },
];

const styles: Styles = {
  container: { width: '70%' },
};

export function OosStatus(): ReactElement {
  const dispatch = useDispatch();

  const filters = useSelector(selectPagesPerformanceFilters);

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(pagesPerformanceFiltersActions.changeOOSStatus(Number(e.target.value)));
  };

  return (
    <TextField
      select
      color="primary"
      sx={styles.container}
      onChange={handleStatusChange}
      value={filters.oos_status}
      size="small"
      label="OOS status"
    >
      {exportLimitOptions.map(source => (
        <MenuItem key={source.value} value={source.value}>
          {source.title}
        </MenuItem>
      ))}
    </TextField>
  );
}
