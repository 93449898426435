import { State } from 'common/store/reducers';

export const selectPublisherInfo = (state: State) => state.settings.mainPublisherSettings.data;

export const selectPublisherInfoLoading = (state: State) => state.settings.mainPublisherSettings.loading;

export const selectCurrentPublisherTagsPage = (state: State) => state.settings.tagsSettings.page;

export const selectPublisherTags = (state: State) => state.settings.tagsSettings.data;

export const selectPublisherTagsLoading = (state: State) => state.settings.tagsSettings.loading;

export const selectPublisherTagsAmount = (state: State) => state.settings.tagsSettings.amount;

export const selectPublisherTagsFilters = (state: State) => state.settings.tagsFilters;

export const selectCurrentPublisherUrlsPage = (state: State) => state.settings.urlsSettings.page;

export const selectPublisherUrls = (state: State) => state.settings.urlsSettings.data;

export const selectPublisherUrlsLoading = (state: State) => state.settings.urlsSettings.loading;

export const selectPublisherUrlsAmount = (state: State) => state.settings.urlsSettings.amount;

export const selectPublisherAffiliateInfo = (state: State) => state.settings.affiliateSettings.data;

export const selectPublisherAffiliateLoading = (state: State) => state.settings.affiliateSettings.loading;

export const selectPublisherTeamInfo = (state: State) => state.settings.teamSettings.data;

export const selectPublisherTeamLoading = (state: State) => state.settings.teamSettings.loading;

export const selectPendingInvitations = (state: State) => state.settings.pendingInvitations.data;

export const selectPendingInvitationsLoading = (state: State) => state.settings.pendingInvitations.loading;

export const selectPublisherIntegrationsInfo = (state: State) => state.settings.integrationsSettings.data;

export const selectPublisherIntegrationsLoading = (state: State) => state.settings.integrationsSettings.loading;

export const selectPublisherRateCards = (state: State) => state.settings.rateCards.data;

export const selectPublisherRateCardsLoading = (state: State) => state.settings.rateCards.loading;

export const selectPublisherRateCardsSelected = (state: State) => state.settings.rateCards.selected;

export const selectAffiliateReportData = (state: State) => state.settings.affiliateReports.data;

export const selectAffiliateReportLoading = (state: State) => state.settings.affiliateReports.loading;

export const selectAffiliateReportError = (state: State) => state.settings.affiliateReports.error;

export const selectAffiliateReportHistoryData = (state: State) => state.settings.affiliateReportHistory.data;

export const selectAffiliateReportHistoryLoading = (state: State) => state.settings.affiliateReportHistory.loading;

export const selectAffiliateReportHistoryError = (state: State) => state.settings.affiliateReportHistory.error;

export const selectAffiliateReportDownloadData = (state: State) => state.settings.affiliateReportDownload.data;

export const selectAffiliateReportDownloadFileName = (state: State) => state.settings.affiliateReportDownload.fileName;

export const selectAffiliateReportDownloadLoading = (state: State) => state.settings.affiliateReportDownload.loading;

export const selectAffiliateReportDownloadError = (state: State) => state.settings.affiliateReportDownload.error;

export const selectPublisherGroups = (state: State) => state.settings.publisherGroups.data;

export const selectPublisherGroupsLoading = (state: State) => state.settings.publisherGroups.loading;

export const selectTeamInfo = (state: State) => state.settings.newTeamSettings.data;

export const selectTeamLoading = (state: State) => state.settings.newTeamSettings.loading;

export const selectTeamClientsInfo = (state: State) => state.settings.newTeamSettings.clientsData;

export const selectTeamClientsLoading = (state: State) => state.settings.newTeamSettings.loadingClients;

export const selectPublisherBrandCommissions = (state: State) => state.settings.publisherBrandCommissions.data;

export const selectPublisherBrandCommissionsLoading = (state: State) =>
  state.settings.publisherBrandCommissions.loading;

export const selectPublishersBrandCommissionsError = (state: State) => state.settings.publisherBrandCommissions.error;
