import { ReactElement } from 'react';
import { Button, CircularProgress } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Styles } from 'common/types';
import { theme } from 'common/constants/theme';

const getStyles = (color: 'light' | 'dark'): Styles => ({
  downloadBtn: {
    width: 36,
    height: 36,
    p: 0,
    minWidth: 36,
    color: color === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
    borderColor: color === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
    ':hover': {
      color: color === 'dark' ? theme.palette.primary.main : theme.palette.primary.dark,
      borderColor: color === 'dark' ? theme.palette.primary.main : theme.palette.primary.dark,
    },
  },
});

interface Props {
  handleDownload: () => void;
  loading?: boolean;
  color?: 'light' | 'dark';
}

export function DownloadButton({ handleDownload, loading, color = 'dark' }: Props): ReactElement {
  return (
    <Button variant="outlined" sx={getStyles(color).downloadBtn} size="small" onClick={handleDownload}>
      {loading ? <CircularProgress size={14} color="inherit" /> : <FileDownloadIcon fontSize="small" />}
    </Button>
  );
}
