import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { selectGlobalFilters } from 'common/store/selectors';
import HeaderImg from 'common/svg/header-img.png';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import moment from 'moment';
import { Banner } from 'modules/parent-publisher-settings/components/banner';
import { RecommendedGrid } from './components/recommended-grid';
import { asinRecommendationSelectors } from './store/selectors';
import { asinRecommendationActions } from './store/actions/actions';
import { AsinsTable } from './components/recommend-list/table';

const styles: Styles = {
  headTitle: {
    color: '#ffffff',
    fontWeight: 600,
  },
  head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: `url(${HeaderImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#4288F0',
    padding: '28px',
    boxSizing: 'border-box',
    color: '#ffffff',
    fontWeight: 600,
  },
  desc: { lineHeight: '27px', fontSize: 14, color: '#214254', marginTop: 1 },
  container: { display: 'flex', flexDirection: 'column' },
  filters: { display: 'flex', alignItems: 'center', gap: 1.5 },
  util: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  gainzHead: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 2,
  },
  gainzHeadTitle: {
    fontSize: '28px',
    fontWeight: 600,
  },
};

export const calculateMonths = (value: string): number[] | null => {
  switch (value) {
    case 'none':
      return [];
    case 'current_month':
      return [moment().month()];
    case 'next_month':
      return [0].map((_month, index) =>
        moment()
          .add(index + 1, 'M')
          .month()
      );
    case 'next_three_months':
      return [0, 0, 0].map((_month, index) =>
        moment()
          .add(index + 1, 'M')
          .month()
      );
    case 'next_six_months':
      return [0, 0, 0, 0, 0, 0].map((_month, index) =>
        moment()
          .add(index + 1, 'M')
          .month()
      );

    default:
      return [];
  }
};

export function AsinRecommendation(): ReactElement {
  const dispatch = useDispatch();

  const [view, setView] = useState<string>('grid');
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  const global = useSelector(selectGlobalFilters);
  const filters = useSelector(asinRecommendationSelectors.selectFilters);
  const exports = useSelector(asinRecommendationSelectors.selectExports);

  const getRecommendedAsinsParams = useMemo<AsinRecommendationModule.Params.GetAsinsParams>(
    () => ({
      publisher_id: global.publisher_id,
      ...(filters.search === '' ? {} : { search: filters.search }),
      ...(filters.type === 'all' ? {} : { type: filters.type }),
      ...(filters.status === 'all' ? {} : { status: filters.status }),
      ...(filters.seasons === 'none' ? {} : { seasons: calculateMonths(filters.seasons).map(month => month + 1) }),
      ...(filters.in_stock === 'all' ? {} : { in_stock: filters.in_stock === 'true' }),
      category: filters.category,
      brand: filters.brand,
      price_min: filters.price_min,
      price_max: filters.price_max,
      earn_min: filters.earn_min,
      earn_max: filters.earn_max,
    }),
    [global, filters]
  );

  const handleFiltersOpen = () => {
    setFiltersOpen(true);
  };

  const handleFiltersClose = () => {
    setFiltersOpen(false);
  };

  const handleChangeView = (event: React.MouseEvent<HTMLElement>, newView: string | null) => {
    setView(newView);
  };

  const handleSearchChange = (search: string) => {
    dispatch(asinRecommendationActions.filters.setSearch(search));
  };

  const handleExport = () => {
    dispatch(asinRecommendationActions.export.getExport(getRecommendedAsinsParams));
  };

  useEffect(() => {
    dispatch(publishersSettingsActions.getMainPublisherInfo(global.publisher_id.toString()));
    dispatch(asinRecommendationActions.categories.getCategories(global.publisher_id));
    dispatch(asinRecommendationActions.brands.getBrands());
    dispatch(asinRecommendationActions.types.getTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.container}>
      <>
        <Banner
          title="Recommended ASINs"
          options={{ search: true, filters: true, exp: true }}
          exportLoading={exports.loading}
          searchValue={filters.search}
          handleFiltersOpen={handleFiltersOpen}
          handleExport={handleExport}
          handleSearchChange={handleSearchChange}
        />
        <Box sx={styles.util}>
          <Typography sx={styles.desc}>
            Below are products that you can add to your articles! Adding these products provides you with additional
            earnings and payouts.
          </Typography>
          <ToggleButtonGroup value={view} exclusive onChange={handleChangeView} aria-label="text alignment">
            <ToggleButton size="small" value="grid">
              <GridViewIcon />
            </ToggleButton>
            <ToggleButton size="small" value="list">
              <ListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {view === 'list' ? (
          <AsinsTable
            view={view}
            filtersModalOpen={filtersOpen}
            handleFiltersClose={handleFiltersClose}
            getAsinParams={getRecommendedAsinsParams}
          />
        ) : (
          <RecommendedGrid
            view={view}
            filtersModalOpen={filtersOpen}
            handleFiltersClose={handleFiltersClose}
            getAsinParams={getRecommendedAsinsParams}
          />
        )}
      </>
    </Box>
  );
}
