import { Box, TableCell, TableRow } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement } from 'react';

const getStyles = (): Styles => ({
  usedCircle: {
    width: '8px',
    height: '8px',
    backgroundColor: '#62D294',
    borderRadius: '50%',
  },
  status: {
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E3EDFD',
    color: '#4288F0',
    fontSize: '12px',
    borderRadius: '20px',
  },
  anotherStatus: {
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E7F8EF',
    color: '#62D294',
    fontSize: '12px',
    borderRadius: '20px',
  },
});

interface Props {
  tag: TagsModule.Tag;
}

export function TagRow({ tag }: Props): ReactElement {
  const styles = getStyles();

  return (
    <TableRow>
      <TableCell>{tag.publisher_name}</TableCell>
      <TableCell>{tag.tag}</TableCell>
      <TableCell>{tag.used ? <Box sx={styles.usedCircle} /> : null}</TableCell>
      <TableCell>
        {tag.traffic_type ? (
          <Box sx={styles.anotherStatus}>{tag.traffic_type.charAt(0).toUpperCase() + tag.traffic_type.slice(1)}</Box>
        ) : (
          <Box sx={styles.status}>Organic</Box>
        )}
      </TableCell>
    </TableRow>
  );
}
