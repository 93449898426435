import { MenuItem, TextField } from '@mui/material';
import { Styles } from 'common/types';
import { ChangeEvent, ReactElement } from 'react';

const usedOptions = [
  {
    title: 'Combined',
    value: -1,
  },
  {
    title: 'Used',
    value: 1,
  },
  {
    title: 'Unused',
    value: 0,
  },
];

const styles: Styles = {
  select: { marginLeft: 2, width: 150, ':first-child': { marginLeft: 0 } },
};

export type UsedValue = 0 | 1 | -1;

interface Props {
  used: UsedValue;
  onChange: (used: UsedValue) => void;
}

export function UsedPicker({ used, onChange }: Props): ReactElement {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(parseInt(e.target.value, 10) as UsedValue);
  };

  return (
    <TextField
      select
      color="primary"
      sx={styles.select}
      onChange={handleChange}
      value={used}
      size="small"
      label="Status"
    >
      {usedOptions.map(used => (
        <MenuItem key={used.value} value={used.value}>
          {used.title}
        </MenuItem>
      ))}
    </TextField>
  );
}
